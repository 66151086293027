import { useCallback, useEffect, useMemo } from 'react';

import { PAY_MEAN_CODE } from '@ecp/common/src/const/interface/payMeanCode';

const usePaymentMethodInformation = (
  paymentMethod,
  handleChangePaymentMethod,
  paymentOptions,
  ordererInformation,
  couponList
) => {
  const methodComponent = useMemo(
    () => paymentOptions.find(({ code }) => code === paymentMethod?.payMeanCode)?.component,
    [paymentOptions, paymentMethod?.payMeanCode]
  );

  const cardBenefitText = useMemo(() => {
    const cartCoupon = couponList?.find((coupon) => '022' === coupon.promotionTypeCode && coupon.isSelect);
    if (cartCoupon) {
      const { applyCreditCardNameList: cards } = cartCoupon;
      if (Array.isArray(cards) && cards.length > 0) {
        if (cards.length > 3) {
          const carts = cards.slice(0, 3).join(', ');
          return `카드사 할인 적용을 위해 대상 신용카드로 결제해주세요. (${carts} 등)`;
        } else {
          const carts = cards.join(', ');
          return `카드사 할인 적용을 위해 대상 신용카드로 결제해주세요. (${carts})`;
        }
      }
    }
    return '';
  }, [couponList]);

  useEffect(() => {
    if (!!paymentMethod.payMeanCode && !paymentOptions.map(({ code }) => code).includes(paymentMethod.payMeanCode)) {
      handleChangePaymentMethod({ payMeanSaveYn: false });
    }
  }, [handleChangePaymentMethod, paymentMethod, paymentOptions]);

  const handlePaymentOptionsClick = useCallback(
    (item) => {
      handleChangePaymentMethod({
        payMeanSaveYn: paymentMethod.payMeanSaveYn,
        payMeanCode: item.code,
        depositName: item.code === PAY_MEAN_CODE.V_BANK && ordererInformation.ordererName,
      });
    },
    [handleChangePaymentMethod, ordererInformation, paymentMethod?.payMeanSaveYn]
  );

  const handleCheckSavePaymentMethod = useCallback(
    ({ target: { checked } }) => {
      handleChangePaymentMethod({ ...paymentMethod, payMeanSaveYn: checked });
    },
    [handleChangePaymentMethod, paymentMethod]
  );

  return {
    methodComponent,
    handlePaymentOptionsClick,
    handleCheckSavePaymentMethod,
    cardBenefitText,
  };
};

export default usePaymentMethodInformation;
