import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import InformationLimitPop from '@mo-components/good/InformationLimitPop';

const OrderAdultVerifyPopup = ({ error }) => {
  const [adultPopupOpen, setAdultPopupOpen] = useState(false);

  useEffect(() => {
    if (error?.adultVerifyRequired) {
      setAdultPopupOpen(true);
    }
  }, [error]);

  return (
    <React.Fragment>
      {adultPopupOpen && (
        <InformationLimitPop
          open={adultPopupOpen}
          onClose={() => setAdultPopupOpen(false)}
          onConfirm={() => setAdultPopupOpen(false)}
        />
      )}
    </React.Fragment>
  );
};

OrderAdultVerifyPopup.propTypes = {
  error: PropTypes.object,
};

export default OrderAdultVerifyPopup;
