import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import clsx from 'clsx';

import useScrollDirection from '@ecp/common/src/hooks/useScrollDirection';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

import useSiteInfo from '@mo-hooks/common/useSiteInfo';
import Cart from '@mo-components/common/gnb/Cart';
import TopUtilityLogo from '@mo-components/common/gnb/TopUtilityLogo';
import TopUtilitySearch from '@mo-components/common/gnb/TopUtilitySearch';

const Container = styled(FlexBox)`
  width: 100%;
  height: 46px;
  box-sizing: border-box;
  background-color: ${theme.color.background.white};
  z-index: 3;

  position: relative;
`;

const TopUtility = ({ linkSiteList, searchPhraseList, searchPraseListIndex }) => {
  const {
    systemInformation: { mobileGnbImgFilePath },
  } = useSiteInfo();
  const scrollDirection = useScrollDirection();
  const scrollDirectionStyle = clsx(scrollDirection);
  const isShowSite = useMemo(() => {
    return linkSiteList?.length > 0;
  }, [linkSiteList?.length]);

  return (
    <Container className={scrollDirectionStyle} onClick={(e) => e.stopPropagation()}>
      <Spacing right={20} />
      <TopUtilityLogo linkSiteList={linkSiteList} isShowSite={isShowSite} mobileGnbImgFilePath={mobileGnbImgFilePath} />
      <Spacing left={14} />
      <TopUtilitySearch searchPhraseList={searchPhraseList} isShowSite={isShowSite} searchPraseListIndex={searchPraseListIndex}/>
      <Spacing left={14} />
      <FlexBox flex-grow={'1'}>
        <Cart />
      </FlexBox>
    </Container>
  );
};

export default TopUtility;

TopUtility.propTypes = {
  linkSiteList: PropTypes.array,
  searchPhraseList: PropTypes.array,
  searchPraseListIndex: PropTypes.number,
};
