import { useEffect } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { showFooterState } from '@mo-recoil/common/footer/atom';

export const useHideFooter = () => {
  const showFooter = useSetRecoilState(showFooterState);
  const resetShowFooter = useResetRecoilState(showFooterState);

  useEffect(() => {
    showFooter(false);
    return () => resetShowFooter();
  }, [showFooter, resetShowFooter]);
};
