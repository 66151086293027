import React, { useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { flexCenter } from '@ecp/common/src/style/theme/defaultStyled';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { paddingPropTypes, sizePropTypes } from '@ecp/common/src/style/propTypes';
import boTheme from '@ecp/common/src/style/theme/bo';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import ButtonUnstyled from '@ecp/common/src/components/unstyled/button/ButtonUnstyled';
import { ReactComponent as MoveIcon } from '@ecp/common/src/assets/icon/icon__bo__arrow--right.svg';

const buttonDefaultSizeValues = {
  width: 'fit-content',
  height: 'fit-content',
  minWidth: undefined,
};

export const StyledButton = styled.button`
  ${flexCenter};
  border-radius: 3px;
  text-align: center;
  color: ${boTheme.color.sub10};
  border: none;
  font-weight: ${boTheme.font.weight.regular};
  font-size: ${boTheme.font.size.small};
  ${overrideProperties({ ...sizePropTypes, minWidth: PropTypes.string }, buttonDefaultSizeValues)};
  ${overrideProperties(paddingPropTypes, { padding: 0 })};

  cursor: pointer;

  &:disabled {
    border: 1px solid ${boTheme.color.sub6};
    background-color: ${boTheme.color.sub8};
    color: ${boTheme.color.sub5};
    cursor: not-allowed;

    :hover {
      border: 1px solid ${boTheme.color.page5};
      background-color: ${boTheme.color.hover3};
      color: ${boTheme.color.sub3};
    }
  }
`;

const StyledPrimaryButton = styled(StyledButton)`
  background-color: ${boTheme.color.main};

  &:hover:not(:disabled) {
    background-color: ${boTheme.color.hover};
  }
`;

const StyledSecondaryButton = styled(StyledButton)`
  background-color: ${boTheme.color.menu1};

  &:hover:not(:disabled) {
    background-color: ${boTheme.color.hover4};
  }
`;

const StyledThirdButton = styled(StyledButton)`
  border: 1px solid ${boTheme.color.sub6};
  background-color: ${boTheme.color.sub10};
  color: ${boTheme.color.sub1};

  &:hover:not(:disabled) {
    border: 1px solid ${boTheme.color.page5};
    background-color: ${boTheme.color.sub6};
  }
`;

const styledButtonComponents = {
  Primary: StyledPrimaryButton,
  Secondary: StyledSecondaryButton,
  Third: StyledThirdButton,
};

export const Button = React.forwardRef(({ type = 'Primary', move, children, ...props }, ref) => {
  const buttonRef = useRef(null);

  useImperativeHandle(ref, () => buttonRef.current);
  const RootComponent = useMemo(() => styledButtonComponents[type], [type]);
  return (
    <ButtonUnstyled ref={buttonRef} rootComponent={RootComponent} {...props}>
      <FlexBox gap="10px">
        {children}
        {move && <MoveIcon />}
      </FlexBox>
    </ButtonUnstyled>
  );
});

Button.displayName = 'Button';

Button.propTypes = {
  type: PropTypes.oneOf(['Primary', 'Secondary', 'Third']),
  move: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  ...sizePropTypes,
  minWidth: PropTypes.string,
};

Button.defaultProps = {
  type: 'Primary',
  move: false,
  disabled: false,
  width: '90px',
  height: '28px',
};

export default Button;
