import { useCallback, useEffect, useMemo, useState } from 'react';

function useGiftiShowReceiverInformation({
  itemTotalCount,
  goodList,
  orderDeliveryList,
  handleChangeOrderDeliveryList,
  goodListWithZeroDeliveryQuantity,
  getGoodListWithZeroQuantity,
  getGoodListWithMaxQuantity,
}) {
  const [deliveryNumber, setDeliveryNumber] = useState(1);
  const [selectedItem, setSelectedItem] = useState({});

  const dropdownList = useMemo(
    () =>
      [...Array(itemTotalCount > 40 ? 40 : itemTotalCount)].map((data, index) => ({
        label: `${index + 1}분`,
        value: `${index + 1}`,
        key: `${index + 1}`,
      })),

    [itemTotalCount]
  );

  useEffect(() => {
    setSelectedItem(dropdownList[0]);
  }, [dropdownList]);

  useEffect(() => {
    if (deliveryNumber === orderDeliveryList.length) return;
    if (deliveryNumber > orderDeliveryList.length) {
      const newOrderDeliveryList = [...new Array(deliveryNumber)].map((item, idx) => {
        if (idx < orderDeliveryList.length) {
          return { ...orderDeliveryList[idx] };
        }
        return {
          deliveryPlace: { cellPhoneNumber: '' },
          goodList: goodListWithZeroDeliveryQuantity(goodList),
        };
      });
      handleChangeOrderDeliveryList(
        orderDeliveryList.length === 1 ? getGoodListWithZeroQuantity(newOrderDeliveryList) : newOrderDeliveryList
      );
    } else {
      const newOrderDeliveryList = orderDeliveryList.filter((item, idx) => idx < deliveryNumber);
      handleChangeOrderDeliveryList(
        deliveryNumber === 1 ? getGoodListWithMaxQuantity(newOrderDeliveryList) : newOrderDeliveryList
      );
    }
  }, [
    deliveryNumber,
    getGoodListWithMaxQuantity,
    getGoodListWithZeroQuantity,
    goodList,
    goodListWithZeroDeliveryQuantity,
    handleChangeOrderDeliveryList,
    orderDeliveryList,
  ]);

  const handleChangeDeliveryNumber = useCallback((data) => {
    setSelectedItem(data);
    setDeliveryNumber(typeof data.value === 'string' ? Number(data.value) : data.value);
  }, []);

  const canMultiDelivery = useMemo(() => {
    const isTicket = goodList.some(({ goodTypeCode }) => goodTypeCode === '50');
    const isGiftiShow = goodList.some(({ goodTypeCode }) => goodTypeCode === '80');
    if (isGiftiShow) {
      return itemTotalCount > 1;
    }
    if (isTicket) {
      return goodList.every(({ ticketNumberUse }) => ticketNumberUse) && itemTotalCount > 1;
    }
    return false;
  }, [goodList, itemTotalCount]);

  return {
    selectedItem,
    dropdownList,
    handleChangeDeliveryNumber,
    canMultiDelivery,
  };
}

export default useGiftiShowReceiverInformation;
