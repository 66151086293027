import { useMemo } from 'react';

import { PD_GOOD_TYPE } from '@ecp/common/src/const/good';

export default function usePresentOrderPage(orderPageInfo) {
  const { goodList } = orderPageInfo;

  const decorationImageList = useMemo(() => {
    return orderPageInfo.presentDecorationList || [];
  }, [orderPageInfo]);

  const orderGoodType = useMemo(() => {
    return goodList?.reduce(
      ({ directForeignGood, alcohol, ticket, pointGood, rental, giftCertificate }, good) => ({
        directForeignGood: directForeignGood || good.goodTypeCode === PD_GOOD_TYPE.OVERSEAS,
        alcohol: alcohol || good.goodTypeCode === PD_GOOD_TYPE.LIQUOR,
        ticket: ticket || good.goodTypeCode === PD_GOOD_TYPE.TICKET,
        pointGood: pointGood || good.goodTypeCode === PD_GOOD_TYPE.PARK_POINT,
        rental: rental || good.goodTypeCode === PD_GOOD_TYPE.RENTAL,
        giftCertificate: giftCertificate || good.goodTypeCode === PD_GOOD_TYPE.GIFT_CARD,
      }),
      {}
    );
  }, [goodList]);

  return {
    orderGoodType,
    decorationImageList,
  };
}
