import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';

import { openWindow } from '@ecp/common/src/utils/utils';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import ContextMenu from '@ecp/common/src/components/contextmenu/mo/ContextMenu';
import StyledImg from '@ecp/common/src/components/image/StyledImg';

import { withMobileStatus } from '@mo-recoil/system/selector';
import downArrow from '@mo-assets/icon/icon__arrow--down.svg';

const LinkSiteItems = ({ linkMenus, clickLinkMenu }) => {
  return (
    <FlexBoxColumn align-items="flex-start" data-ds-label2={'g_af_bar'}>
      {linkMenus.map(({ label, url, target }, idx) => (
        <FlexBoxColumn key={idx}>
          {idx !== 0 && <Spacing top={14} />}
          <TextBox
            clickable
            size={moTheme.font.size.$14}
            height={'20px'}
            hoverColor={theme.color.text.selected}
            hoverDecoration={'underline'}
            onClick={() => clickLinkMenu(url, target)}
          >
            {label}
          </TextBox>
        </FlexBoxColumn>
      ))}
    </FlexBoxColumn>
  );
};

LinkSiteItems.propTypes = {
  linkMenus: PropTypes.array,
  clickLinkMenu: PropTypes.func,
};

const Logo = ({ mobileGnbImgFilePath }) => {
  const navigate = useNavigate();
  return (
    <button onClick={() => navigate('/')}>
      <StyledImg src={mobileGnbImgFilePath} width={'22px'} height={'18px'} alt={'WellStory'} />
    </button>
  );
};

Logo.propTypes = {
  mobileGnbImgFilePath: PropTypes.string,
};

const TopUtilityLogo = ({ linkSiteList, isShowSite, mobileGnbImgFilePath }) => {
  const [isShowLinkSiteContext, setIsShowLinkSiteContext] = useState(false);
  const { isMobileApp, agentType } = useRecoilValue(withMobileStatus);

  const handleClickLinkSite = useCallback(
    (url, target) => {
      if (url) {
        openWindow(isMobileApp, url, target, agentType);
      }
    },
    [agentType, isMobileApp]
  );

  return (
    <>
      {isShowSite ? (
        <ContextMenu
          placement="bottom-start"
          offset={5}
          openWhenClicked
          closeWhenOutside
          value={<LinkSiteItems linkMenus={linkSiteList} clickLinkMenu={handleClickLinkSite} />}
          onOpenChange={(val) => setIsShowLinkSiteContext(val)}
        >
          <FlexBox padding={'2px 0 0'}>
            <Logo mobileGnbImgFilePath={mobileGnbImgFilePath} />
            <Spacing left={6} />
            <StyledImg src={downArrow} width={'20px'} height={'20px'} alt={'downArrow'} $flip={isShowLinkSiteContext} />
          </FlexBox>
        </ContextMenu>
      ) : (
        <Logo mobileGnbImgFilePath={mobileGnbImgFilePath} />
      )}
    </>
  );
};

export default TopUtilityLogo;

TopUtilityLogo.propTypes = {
  linkSiteList: PropTypes.array,
  isShowSite: PropTypes.bool,
  mobileGnbImgFilePath: PropTypes.string,
};
