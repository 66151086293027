import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { MAIN_FUNCTION_CODE } from '@ecp/common/src/const/display/mainTemplateConst';

import {
  hideNavigationMenuState,
  navigationOffsetState,
  showBenefitState,
  showNavigationMenuState,
} from '@mo-recoil/common/navigation/atom';
import { withCommonMainFunctionUseQuery } from '@mo-recoil/main/selector';

export const useNavigationMenu = () => {
  const showNavigationMenu = useSetRecoilState(showNavigationMenuState);
  const hideNavigationMenu = useRecoilValue(hideNavigationMenuState);
  const resetNavigationMenu = useResetRecoilState(showNavigationMenuState);

  useEffect(() => {
    !hideNavigationMenu && showNavigationMenu(true);
    return () => resetNavigationMenu();
  }, [showNavigationMenu, resetNavigationMenu, hideNavigationMenu]);
};

export const useBenefitCard = () => {
  const setShowBenefit = useSetRecoilState(showBenefitState);
  const showEmployeeBenefit = useRecoilValue(withCommonMainFunctionUseQuery(MAIN_FUNCTION_CODE.EMPLOYEE_BENEFIT));

  useEffect(() => {
    if (showEmployeeBenefit) {
      setShowBenefit(true);
    }
  }, [setShowBenefit, showEmployeeBenefit]);
};

export const useHideNavigation = (navigationOffset = 0) => {
  const setNavigationOffset = useSetRecoilState(navigationOffsetState);
  const resetNavigationOffset = useResetRecoilState(navigationOffsetState);
  const setHideNavigationMenu = useSetRecoilState(hideNavigationMenuState);
  const resetHideNavigationMenu = useResetRecoilState(hideNavigationMenuState);

  useEffect(() => {
    setNavigationOffset(navigationOffset);
    setHideNavigationMenu(true);

    return () => {
      resetNavigationOffset();
      resetHideNavigationMenu();
    };
  }, [navigationOffset, resetHideNavigationMenu, resetNavigationOffset, setHideNavigationMenu, setNavigationOffset]);
};
