import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Paper from '@ecp/common/src/components/paper/Paper';
import LayerPopup from '@ecp/common/src/components/modal/mo/popup/LayerPopup';

const DirectForeignGoodsAgreePopup = ({ open, onClose }) => {
  return (
    <LayerPopup
      open={open}
      onClose={onClose}
      onConfirmClick={onClose}
      header={'해외직구 개인정보 수집 이용동의'}
      useCancelButton={false}
    >
      <Container>
        <BodyContainer>
          <TitleTextBox>1. 개인정보 수집 및 이용 목적 : 해외 구매대행 상품의 통관(수입신고)</TitleTextBox>
          <TitleTextBox>2. 개인정보 수집목적 : 물품배송</TitleTextBox>
          <TitleTextBox>3. 개인정보 보유기한 : 배송완료 시까지</TitleTextBox>
          <TitleTextBox>4. 수집하는 개인정보의 항목 : 구매자, 이름, 통관고유부호</TitleTextBox>
        </BodyContainer>
        <PaperContainer>
          <PaperTextBox>※ 귀하는 동의를 거부할 권리가 있으며, 동의 거부 시 물품배송이 불가합니다.</PaperTextBox>
        </PaperContainer>
      </Container>
    </LayerPopup>
  );
};

DirectForeignGoodsAgreePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
  gap: 14px;
`;

const BodyContainer = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
  gap: 10px;
`;

const TitleTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 350;
`;

const PaperContainer = styled(Paper)`
  width: 100%;
  padding: 14px;
  background-color: #f7f7f7;
`;

const PaperTextBox = styled(TextBox)`
  font-size: 12px;
  font-weight: 350;
  color: #5a5a5a;
`;
export default DirectForeignGoodsAgreePopup;
