import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useIgnorableError from '@ecp/common/src/hooks/useIgnorableError';
import theme from '@ecp/common/src/style/theme/default';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import InputUnstyled, { inputClassNames } from '@ecp/common/src/components/unstyled/input/InputUnstyled';
import { Count } from '@ecp/common/src/components/input/Input';

import { StyledErrorMessage, StyledInputRoot } from './Input';

const StyledTextareaRoot = styled(StyledInputRoot)`
  padding: 10px 6px 10px 10px;
  flex-direction: column;
`;

const StyledTextarea = styled.textarea`
  padding: 1px 14px 1px 1px;
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-sizing: border-box;
  user-select: auto;

  &.${inputClassNames.disabled} {
    color: ${theme.color.secondary};
  }
  &.${inputClassNames.empty} {
    color: #919191;
  }
  &::placeholder {
    color: #919191;
  }
`;

const Wrapper = styled(FlexBox)`
  font-weight: ${moTheme.font.weight.demiLight};
  font-size: ${moTheme.font.size.$12};
  line-height: ${moTheme.font.lineHeight.bold};
  //color: ${theme.color.text.placeholder};
  color: #919191;
  width: max-content;
  align-items: flex-end;
  padding-right: 4px;
`;

const Textarea = React.forwardRef(
  ({ width, height, error, countCheck, countCheckComponent: CountCheckComponent, ...props }, ref) => {
    const { value, errorMessage, maxLength } = props;

    const textareaRef = useRef(null);
    useImperativeHandle(ref, () => textareaRef.current);

    const { ignoreError } = useIgnorableError({ error, value });

    return (
      <FlexBoxColumn width={width} align-items={'flex-start'}>
        <InputUnstyled
          rootComponent={StyledTextareaRoot}
          rootProps={{ width, height }}
          inputComponent={StyledTextarea}
          error={!ignoreError && error}
          ref={textareaRef}
          endAdornment={
            <FlexBox width="100%" justify-content="flex-end" flex="0">
              {countCheck && <CountCheckComponent value={value} maxLength={maxLength} wrapperComponent={Wrapper} />}
            </FlexBox>
          }
          {...props}
        />
        {!ignoreError && error && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
      </FlexBoxColumn>
    );
  }
);

Textarea.displayName = 'Textarea';

Textarea.propTypes = {
  ...sizePropTypes,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

Textarea.defaultProps = {
  placeholder: '내용을 입력하세요.',
  width: '200px',
  height: '150px',
  countCheckComponent: Count,
  onFocus: () => {},
};

export default Textarea;
