import { DefaultTabButton } from '@ecp/common/src/components/tab/mo/Tab';
import { TabGroup, InputTabGroup } from '@ecp/common/src/components/tab/TabGroup';

TabGroup.defaultProps = {
  defaultValue: 0,
  tabSlot: DefaultTabButton,
};
export default TabGroup;

InputTabGroup.defaultProps = {
  defaultValue: 0,
  tabSlot: DefaultTabButton,
};
export { InputTabGroup };
