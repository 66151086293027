import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { styleMap } from '@ecp/common/src/components/accordion/AccordionTag';

const Wrapper = styled(FlexBox)`
  min-width: fit-content;
  width: fit-content;
  height: 20px;
  box-sizing: border-box;
  padding: 0 4px;

  font-size: ${moTheme.font.size.$12};
  font-weight: 350;

  ${({ type }) => styleMap[type]};
`;

export const AccordionTag = (props) => {
  const { type, children } = props;

  return <Wrapper type={type}>{children}</Wrapper>;
};

AccordionTag.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(['primary', 'basic', 'active']),
};

AccordionTag.defaultProps = {
  type: 'basic',
};
