import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { InnerButton } from '@ecp/common/src/components/button/mo/Button';
import LabeledCheckBox from '@ecp/common/src/components/checkbox/mo/LabeledCheckBox';

import CouponChangePopup from '@mo-components/order/payment/popup/CouponChangePopup';
import theme from '@ecp/common/src/style/theme/default';

const DiscountBenefitInformation = ({
  paymentAmount,
  goodCouponList,
  cartCouponList,
  couponList,
  goodPriceList,
  onChange,
  deliveryCostCoupon,
  paymentMethod,
  onClickSimpleApply,
  simpleCouponApply,
}) => {
  const [couponChangePopupOpen, setCouponChangePopupOpen] = useState(false);

  const handleClickApplySimpleDiscount = useCallback(
    (e) => {
      onClickSimpleApply(e.target.checked);
    },
    [onClickSimpleApply]
  );

  const usableCouponCount = useMemo(
    () =>
      Array.isArray(couponList)
        ? couponList.filter((coupon) => '021' === coupon.promotionTypeCode || '022' === coupon.promotionTypeCode).length
        : 0,
    [couponList]
  );
  const applyCouponCount = useMemo(
    () => (Array.isArray(couponList) ? couponList.filter((coupon) => coupon.isSelect).length : 0),
    [couponList]
  );
  return (
    <FlexBoxColumn width="100%">
      <FlexBoxSpaceBetween width="100%">
        <TextBox size="16px" weight="700">
          할인혜택
        </TextBox>
        <FlexBox font-size="14px" font-weight="350">
          <TextBox>적용중</TextBox>
          <TextBox weight="700">{applyCouponCount}</TextBox>
          <TextBox weight="700"> / </TextBox>
          <TextBox>사용가능</TextBox>
          <TextBox weight="700">{usableCouponCount}</TextBox>
        </FlexBox>
      </FlexBoxSpaceBetween>
      <Spacing top="10px" />
      <HorizontalDivider color="#141415" />
      <Spacing top="20px" />
      <FlexBoxSpaceBetween width="100%">
        <LabeledCheckBox
          labelText="간편 할인 적용"
          checked={simpleCouponApply}
          onChange={handleClickApplySimpleDiscount}
          disabled={!couponList?.length}
        />
        <InnerButton
          width="68px"
          onClick={() => {
            setCouponChangePopupOpen(true);
          }}
          disabled={!couponList?.length}
        >
          쿠폰변경
        </InnerButton>
      </FlexBoxSpaceBetween>
      <TextBox width="100%" padding="0 0 0 34px" color={theme.color.primary} size="13">
        <TextBox weight="700">{NumberFormat((paymentAmount?.discountAmount?.total || 0) * -1)}</TextBox>
        <TextBox weight="350">원 할인 적용</TextBox>
      </TextBox>
      <CouponChangePopup
        open={couponChangePopupOpen}
        onClose={() => {
          setCouponChangePopupOpen(false);
        }}
        goodCouponList={goodCouponList}
        cartCouponList={cartCouponList}
        couponList={couponList}
        goodPriceList={goodPriceList}
        applyMaxDiscount={simpleCouponApply}
        onChange={onChange}
        paymentAmount={paymentAmount}
        deliveryCostCoupon={deliveryCostCoupon}
        paymentMethod={paymentMethod}
        simpleCouponApply={simpleCouponApply}
      />
    </FlexBoxColumn>
  );
};

export default DiscountBenefitInformation;

DiscountBenefitInformation.propTypes = {
  paymentAmount: PropTypes.object,
  goodCouponList: PropTypes.array,
  cartCouponList: PropTypes.array,
  couponList: PropTypes.array,
  goodPriceList: PropTypes.array,
  onChange: PropTypes.func,
  deliveryCostCoupon: PropTypes.object,
  paymentMethod: PropTypes.object,
  onClickSimpleApply: PropTypes.func,
  simpleCouponApply: PropTypes.bool,
};
