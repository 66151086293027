export const FILTER_TYPE = {
  RESEARCH: 'RESEARCH',
  CATEGORY: 'CATEGORY',
  BRAND: 'BRAND',
  PRICE: 'PRICE',
};

export const FILTER_OPTIONS = [
  { key: FILTER_TYPE.RESEARCH, label: '결과 내 재검색', 'data-ds-label2': 're_search' },
  { key: FILTER_TYPE.CATEGORY, label: '카테고리', 'data-ds-label2': 'ctg_filter' },
  { key: FILTER_TYPE.BRAND, label: '브랜드', 'data-ds-label2': 'brand_filter' },
  { key: FILTER_TYPE.PRICE, label: '가격', 'data-ds-label2': 'price_filter' },
];

export default {
  FILTER_TYPE,
  FILTER_OPTIONS,
};
