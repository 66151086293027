import { selector } from 'recoil';

import { gnbMobileDefault } from '@ecp/common/src/const/home/gnbConst';

import { menuListState } from './atom';

export const withGnbMenuList = selector({
  key: 'withGnbMenuList',
  get: ({ get }) => {
    const menuList = get(menuListState);
    return [{ ...gnbMobileDefault, title: '홈', link: '/' }, ...menuList];
  },
});
