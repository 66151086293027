import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn, FlexBoxSpaceBetween, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { DELIVERY_METHOD } from '@ecp/common/src/const/footer';
import { PRIVACY_POLICY_DESCRIPTION } from '@ecp/common/src/const/mo/footer';

import FormField, { RenderEmail } from '@mo-components/common/FormField';

const BulkOrderApplicationForm = ({ formValue, onChangeFormValues, onChangeDropdownValues }) => {
  const { title, company, mobile, emailValue, emailDomain, emailDirectDomain, delivery, deliveryDate, inquiry, agree } =
    formValue;

  const fields = useMemo(
    () => [
      {
        key: 'title',
        value: title,
        type: 'input',
        label: '제목',
        onChange: onChangeFormValues,
        fieldProps: { placeholder: '제목을 입력해 주세요.', maxLength: 20 },
      },
      {
        key: 'company',
        value: company,
        type: 'input',
        label: '소속(회사명)',
        onChange: onChangeFormValues,
        fieldProps: { placeholder: '소속을 입력해 주세요.', maxLength: 20 },
      },
      {
        key: 'mobile',
        value: mobile,
        type: 'input',
        label: '휴대전화번호',
        onChange: onChangeFormValues,
        fieldProps: { placeholder: '-없이 숫자만 입력해 주세요.', maxLength: 11, onlyNumber: true },
      },
      {
        key: 'emailValue',
        value: emailValue,
        type: 'custom',
        label: '이메일',
        customRender: RenderEmail,
        emailDomainKey: 'emailDomain',
        emailDomain,
        emailDirectDomainKey: 'emailDirectDomain',
        emailDirectDomain,
        onChange: onChangeFormValues,
        onChangeDropdownValues,
      },
      {
        key: 'delivery',
        value: delivery,
        type: 'dropdown',
        label: '배송방법',
        item: DELIVERY_METHOD,
        onChange: onChangeDropdownValues,
        fieldProps: { placeholder: '선택하세요' },
      },
      {
        key: 'deliveryDate',
        value: deliveryDate,
        type: 'calendar',
        onChange: onChangeDropdownValues,
        label: '희망배송일',
        fieldProps: { dateFormat: 'yyyy/MM/dd', placeholderText: '년/월/일', minDate: new Date() },
      },
      {
        key: 'inquiry',
        value: inquiry,
        type: 'textarea',
        label: '대량구매 문의내용',
        onChange: onChangeFormValues,
        fieldProps: { height: '90px', placeholder: '문의 내용을 입력해주세요', maxLength: 200 },
      },
      {
        key: 'description',
        value: '',
        required: false,
        type: 'textarea',
        label: '개인정보를 위한 이용자 동의사항',
        fieldProps: { height: '123px', placeholder: PRIVACY_POLICY_DESCRIPTION },
      },
      {
        key: 'agree',
        type: 'radio',
        required: false,
        label: '위 내용에 동의 하십니까?',
        value: agree,
        item: [
          { value: '1', label: '동의합니다.' },
          { value: '0', label: '동의하지 않습니다.' },
        ],
        onChange: onChangeFormValues,
      },
    ],
    [
      agree,
      company,
      delivery,
      deliveryDate,
      emailDirectDomain,
      emailDomain,
      emailValue,
      inquiry,
      mobile,
      onChangeDropdownValues,
      onChangeFormValues,
      title,
    ]
  );

  return (
    <FlexBoxColumn width={'100%'}>
      <FlexBoxSpaceBetween width={'100%'}>
        <TextBox size={moTheme.font.size.$16} weight={moTheme.font.weight.bold}>
          대량 구매 문의 상담 신청
        </TextBox>
        <TextBox size={moTheme.font.size.$12} weight={moTheme.font.weight.demiLight} required asteriskPosition={'left'}>
          필수 입력 항목
        </TextBox>
      </FlexBoxSpaceBetween>
      <Spacing top={'10px'} />
      <HorizontalDivider width={'100%'} color={theme.color.text.basic} />
      {fields.map((field) => (
        <FormField width={'100%'} key={field.key} field={field} />
      ))}
    </FlexBoxColumn>
  );
};

BulkOrderApplicationForm.propTypes = {
  formValue: PropTypes.object,
  onChangeFormValues: PropTypes.func,
  onChangeDropdownValues: PropTypes.func,
};

export default BulkOrderApplicationForm;
