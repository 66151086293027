import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxCenter, ImageBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

import NoResultImage from '@mo-assets/icon/icon__search-noresult.svg';

const NoResultFoundList = ({ message }) => {
  return (
    <NoResultsFoundBox>
      <ImageBox imageSrc={NoResultImage} width={'53px'} height={'56px'} />
      <Spacing top={20} />
      <TextBox weight={moTheme.font.weight.medium} size={moTheme.font.size.$16} color={theme.color.text.basic}>
        {message}
      </TextBox>
    </NoResultsFoundBox>
  );
};

export default NoResultFoundList;

NoResultFoundList.propTypes = {
  message: PropTypes.string,
};

NoResultFoundList.defaultProps = {
  message: '조회된 내역이 없습니다.',
};

const NoResultsFoundBox = styled(FlexBoxCenter)`
  flex-direction: column;
  width: 100%;
  height: 270px;
`;
