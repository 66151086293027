import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import debounce from 'lodash-es/debounce';

import useRegularDeliveryOrderPage from '@ecp/common/src/hooks/order/payment/useRegularDeliveryOrderPage';
import useOrderValidation from '@ecp/common/src/hooks/order/payment/useOrderValidation';
import useCommonOrderPage from '@ecp/common/src/hooks/order/payment/useCommonOrderPage';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { RectanglePageButton } from '@ecp/common/src/components/button/mo/Button';
import Floating from '@ecp/common/src/components/floating/mo/Floating';

import { setDeliveryListWithDeliveryCost } from '@mo-utils/orderUtils';
import { withAdultCertification } from '@mo-recoil/common/user/selector';
import { systemInformationState } from '@mo-recoil/system/atom';
import { useGnbTitle } from '@mo-hooks/common/useGnb';
import { useAlertDialog } from '@mo-hooks/common/useModal';
import memberApi from '@mo-apis/member/memberApi';
import orderApi from '@mo-apis/order/orderApi';
import OrderDeliveryHopeDate from '@mo-components/order/payment/OrderDeliveryHopeDate';
import OrdererInformation from '@mo-components/order/payment/OrdererInformation';
import OrderPayment from '@mo-components/order/payment/OrderPayment';
import OrderPaymentStatement from '@mo-components/order/payment/OrderPaymentStatement';
import OrderShippingInformation from '@mo-components/order/payment/OrderShippingInformation';
import OrderSummary from '@mo-components/order/payment/OrderSummary';
import PaymentMethodInformation from '@mo-components/order/payment/PaymentMethodInformation';
import AgreeTermsConditions from '@mo-components/order/payment/AgreeTermsConditions';
import OrderAdultVerifyPopup from '@mo-components/order/payment/OrderAdultVerifyPopup';
import useSiteInfo from '@mo-hooks/common/useSiteInfo';

const RegularDeliveryOrderPage = ({ orderPageInfo, orderType }) => {
  useGnbTitle('주문결제');
  const { isPriceOpen } = useSiteInfo();
  const { showAlertDialog } = useAlertDialog();
  const isAdultVerified = useRecoilValue(withAdultCertification);
  const systemInformation = useRecoilValue(systemInformationState);
  const [orderPaymentData, setOrderPaymentData] = useState({});
  const [isMultiDelivery, setIsMultiDelivery] = useState(false);
  const [cashReceiptInfo, setCashReceiptInfo] = useState({ cashReceiptType: 'none' });

  const { validate, error, setError } = useOrderValidation({ isAdultVerified, showAlertDialog, orderApi });

  const {
    ordererInformation,
    setOrdererInformation, //TODO: handleChange..로 wrap 하기
    paymentMethod,
    handleChangePaymentMethod,
    itemTotalCount,
    orderItemCount,
    hasDeliveryHopeDate,
    paymentAmount,
    orderDeliveryList,
    standardResetOrderDeliveryList,
    usablePaymentList,
    onChangeDeliveryHopeDate,
    agreeTermsChecked,
    handleAgreeTermsCheckChange,
    isProcessPayment,
    handlePaymentStart,
    handlePaymentComplete,
  } = useCommonOrderPage({
    orderType,
    orderPageInfo,
    setDeliveryListWithDeliveryCost,
    getBaseDeliveryLocation: memberApi.getBaseDeliveryLocation,
    systemInformation,
    setError,
  });
  const { orderGoodType } = useRegularDeliveryOrderPage(orderPageInfo);

  const debouncedClick = useMemo(
    () =>
      debounce(async () => {
        if (!agreeTermsChecked) {
          showAlertDialog('개인정보동의 확인 해주세요');
          return;
        }

        const data = {
          orderTypeCode: '81', // OD0010 81 정기배송
          present: false,
          orderGoodType,
          paymentMethod,
          isMultiDelivery,
          orderDeliveryList,
          ordererInformation,
          paymentAmount,
          cashReceiptInfo,
          isPriceOpen,
        };

        if (!(await validate(data))) return;
        if (isProcessPayment) return;
        handlePaymentStart();

        setOrderPaymentData(data);
      }, 200),
    [
      agreeTermsChecked,
      orderGoodType,
      paymentMethod,
      isMultiDelivery,
      orderDeliveryList,
      ordererInformation,
      paymentAmount,
      cashReceiptInfo,
      validate,
      isProcessPayment,
      handlePaymentStart,
      showAlertDialog,
      isPriceOpen,
    ]
  );

  const handlePaymentButtonClick = useCallback(debouncedClick, [debouncedClick]);

  return (
    <Container>
      <Spacing top={'20px'} />
      <OrdererInformation
        goodList={orderPageInfo.goodList}
        ordererInformation={ordererInformation}
        handleChangeOrdererInformation={setOrdererInformation}
      />
      <HorizontalDivider height="10px" />
      <Spacing top={'20px'} />
      <InnerContainer>
        <OrderShippingInformation
          itemTotalCount={itemTotalCount || 0}
          goodList={orderPageInfo.goodList}
          ordererInformation={ordererInformation}
          orderDeliveryList={orderDeliveryList}
          handleChangeOrderDeliveryList={standardResetOrderDeliveryList}
          isMultiDelivery={isMultiDelivery}
          handleChangeMultiDelivery={setIsMultiDelivery}
          orderGoodType={orderGoodType}
          orderType={orderType}
          regularDelivery={orderPageInfo.regularDelivery}
          error={error}
        />
        {hasDeliveryHopeDate && (
          <OrderDeliveryHopeDate onChange={onChangeDeliveryHopeDate} orderGoodType={orderGoodType} />
        )}
        {isPriceOpen && (
          <>
            <OrderPaymentStatement paymentAmount={paymentAmount} />
            <PaymentMethodInformation
              handleChangePaymentMethod={handleChangePaymentMethod}
              ordererInformation={ordererInformation}
              paymentMethod={paymentMethod}
              isDirectForeignGood={orderGoodType?.directForeignGood}
              cashReceiptInfo={cashReceiptInfo}
              handleChangeCashReceiptInfo={setCashReceiptInfo}
              usablePaymentList={usablePaymentList}
              orderType={orderType}
            />
          </>
        )}
      </InnerContainer>
      <Spacing top={'20px'} />
      <HorizontalDivider height="10px" />
      <Spacing top={'20px'} />
      <AgreeTermsConditions
        checked={agreeTermsChecked}
        onChange={handleAgreeTermsCheckChange}
        orderGoodType={orderGoodType}
        orderType={orderType}
      />
      <Spacing top={'68px'} />
      <HorizontalDivider />
      <OrderSummary orderItemCount={orderItemCount} paymentAmount={paymentAmount.paymentTotalAmount} />
      <Floating
        buttonContent={() => (
          <PayButtonWrapper>
            <RectanglePageButton onClick={handlePaymentButtonClick}>결제하기</RectanglePageButton>
          </PayButtonWrapper>
        )}
      />
      <Spacing top={'56px'} />
      <OrderPayment orderType={orderType} data={orderPaymentData} onComplete={handlePaymentComplete} />
      <OrderAdultVerifyPopup error={error} />
    </Container>
  );
};
export default RegularDeliveryOrderPage;

RegularDeliveryOrderPage.propTypes = {
  orderPageInfo: PropTypes.object,
  orderType: PropTypes.string,
};

const Container = styled(FlexBoxColumn)`
  align-self: stretch;
  align-items: center;
`;
const InnerContainer = styled(FlexBoxColumn)`
  width: 100%;
  gap: 40px;
  padding: 0 20px;
`;
const PayButtonWrapper = styled(FlexBox)`
  width: 100%;
`;
