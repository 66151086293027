import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Swiper from '@ecp/common/src/components/swiper/mo/Swiper';
import StyledImg from '@ecp/common/src/components/image/StyledImg';
import LayerPopup from '@ecp/common/src/components/modal/mo/popup/LayerPopup';
import { InnerButton } from '@ecp/common/src/components/button/mo/Button';

import NoticeBulletGuideBox from '@mo-components/common/NoticeBulletGuideBox';
import DecoBaseImage from '@mo-assets/image/order/giftishow_base_image.svg';

const GiftiShowDecoPopup = ({ open, onClose, decorationImageList, handleChangeDecoration, giftiShowInformation }) => {
  const [selectedBanner, setSelectedBanner] = useState({});
  const [selectedCard, setSelectedCard] = useState({});
  const [decoType, setDecoType] = useState('B');

  const handleConfirmClick = useCallback(() => {
    handleChangeDecoration({ banner: selectedBanner, card: selectedCard });
    onClose();
  }, [handleChangeDecoration, onClose, selectedBanner, selectedCard]);

  useEffect(() => {
    setSelectedBanner(giftiShowInformation.banner);
    setSelectedCard(giftiShowInformation.card);
  }, [giftiShowInformation]);

  useEffect(() => {
    setDecoType('B');
  }, [open]);

  const bannerList = ({ item }) => (
    <BannerImage
      src={item.imageFilePath}
      alt={item.decorationName}
      key={item.decorationId}
      onClick={() => setSelectedBanner({ ...item })}
      selected={selectedBanner?.decorationId === item.decorationId}
    />
  );
  const cardList = ({ item }) => (
    <CardImage
      src={item.imageFilePath}
      alt={item.decorationName}
      key={item.decorationId}
      onClick={() => setSelectedCard({ ...item })}
      selected={selectedCard?.decorationId === item.decorationId}
    />
  );

  return (
    <LayerPopup
      open={open}
      onClose={onClose}
      header={'메시지 꾸미기'}
      onConfirmClick={handleConfirmClick}
      onCancelClick={onClose}
      bodyPadding={'0'}
      bodyMargin={'0'}
      buttonPadding={'20px'}
    >
      <Container>
        <DecorationContainer gap={8}>
          <BannerContainer>
            <StyledImg width={'280px'} height={'112px'} src={selectedBanner?.imageFilePath} alt={selectedBanner?.decorationName} />
          </BannerContainer>
          <CardContainer>
            <BaseImageCard />
            <StyledImg src={selectedCard?.imageFilePath} width={'134px'} height={'134px'} alt={selectedCard?.decorationName} />
          </CardContainer>
        </DecorationContainer>
        <Spacing top="14px" />
        <SelectionContainer>
          <ButtonContainer gap={6}>
            <DecoButton onClick={() => setDecoType('B')} selected={decoType === 'B'}>
              <ButtonText>배너</ButtonText>
            </DecoButton>
            <DecoButton onClick={() => setDecoType('C')} selected={decoType === 'C'}>
              <ButtonText>카드</ButtonText>
            </DecoButton>
          </ButtonContainer>
          <VerticalDivider />
          <DecorationImageContainer>
            <Swiper
              width={'100%'}
              height="66px"
              itemList={decorationImageList.filter((item) => item.decorationTypeCode === decoType)}
              itemProps={{ width: decoType === 'B' ? '161px' : '62px', height: '62px' }}
              itemComponent={decoType === 'B' ? bannerList : cardList}
              carouselProps={{
                slidesPerView: 'auto',
                loop: false,
                spaceBetween: 6,
                slidesOffsetBefore: 20,
                slidesOffsetAfter: 20,
                style: { margin: '0 0 0 -20px' },
              }}
              tagGb={'button'}
              title={'선택하기'}
            />
          </DecorationImageContainer>
        </SelectionContainer>
        <NoticeBulletGuideBox
          title="사용안내"
          textList={[
            '기프티쇼 발송 시, 문자 메시지와 함께 배너/카드 이미지를 포함하여 전송할 수 있습니다.',
            '배너 이미지나 카드 이미지를 변경 후, "저장" 버튼을 클릭해서 저장하면, 기프티쇼 주문 시 변경한 배너/카드 이미지로 지정됩니다.',
          ]}
          outerPadding="20px 20px 0 20px"
        />
      </Container>
    </LayerPopup>
  );
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
`;
const DecorationContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
  padding: 0 20px;
`;
const VerticalDivider = styled.div`
  margin: 0 10px;
  width: 1px;
  height: 32px;
  background-color: #d3d3d3;
`;
const BannerContainer = styled(FlexBox)`
  align-items: flex-start;
  width: 100%;
`;
const CardContainer = styled(FlexBoxSpaceBetween)`
  align-items: center;
  width: 100%;
`;
const BaseImageCard = styled.div`
  width: 134px;
  height: 134px;
  background: url(${DecoBaseImage}) no-repeat center;
`;
const SelectionContainer = styled(FlexBox)`
  height: 94px;
  width: 100%;
  background-color: #f7f7f7;
  padding: 14px 0 14px 20px;
  display: flex;
  align-items: center;
`;
const DecoButton = styled(InnerButton).attrs(({ selected }) => ({
  style: {
    borderColor: selected ? theme.color.primary : '#EAEAEA',
    color: selected ? theme.color.primary : '#141415',
  },
}))`
  width: 44px;
  height: 30px;
`;

const ButtonContainer = styled(FlexBoxColumn)``;

const ButtonText = styled(TextBox)`
  font-weight: 500;
  font-size: 13px;
`;

const DecorationImageContainer = styled.div`
  width: 234px;
  height: 66px;
  padding: 0 2px;
  overflow-x: scroll;
`;

const BannerImage = styled(StyledImg).attrs(({ selected }) => ({
  style: {
    border: selected ? `2px solid ${theme.color.primary}` : '1px solid #EAEAEA',
  },
}))`
  box-sizing: border-box;
  width: 161px;
  height: 62px;
`;

const CardImage = styled(StyledImg).attrs(({ selected }) => ({
  style: {
    border: selected ? `2px solid ${theme.color.primary}` : '1px solid #EAEAEA',
  },
}))`
  box-sizing: border-box;
  width: 62px;
  height: 62px;
`;

GiftiShowDecoPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  decorationImageList: PropTypes.array,
  handleChangeDecoration: PropTypes.func,
  giftiShowInformation: PropTypes.object,
};

export default GiftiShowDecoPopup;
