import React, { useCallback } from 'react';
import { Spacing } from "@ecp/common/src/layouts/Spacing";
import styled from "styled-components";

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Input from '@ecp/common/src/components/input/mo/Input';
import PropTypes from "prop-types";
const TaxInvoice = ({taxInvoiceInfo, handlerChangeTaxInvoice}) => {
  const handlerChangeBizNumber = useCallback(
    ({ target: { value } }) => {
      handlerChangeTaxInvoice({ bizNumber: value.replace(/[^0-9.]/g, '') });
    },
    [handlerChangeTaxInvoice]
  );
  const handlerChangeBizName = useCallback(
    ({ target: { value } }) => {
      handlerChangeTaxInvoice({ bizName: value });
    },
    [handlerChangeTaxInvoice]
  );
  const handlerChangeBizEmail = useCallback(
    ({ target: { value } }) => {
      handlerChangeTaxInvoice({ bizEmail: value });
    },
    [handlerChangeTaxInvoice]
  );

  return (
    <Container>
      <TitleTextBox required>사업자명</TitleTextBox>
      <Input
        name={"bizName"}
        minWidth={"200px"}
        maxLength={100}
        value={taxInvoiceInfo?.bizName || ''}
        clearable={false}
        onChange={handlerChangeBizName}
      />
      <TitleTextBox required>사업자번호</TitleTextBox>
      <Input
        name={"bizNumber"}
        minWidth={"200px"}
        maxLength={10}
        placeholder="-를 제외한 숫자 10자리 입력"
        value={taxInvoiceInfo?.bizNumber || ''}
        clearable={false}
        onChange={handlerChangeBizNumber}
      />
      <TitleTextBox required>수신메일</TitleTextBox>
      <Input
        name={"bizEmail"}
        minWidth={"200px"}
        maxLength={100}
        value={taxInvoiceInfo?.bizEmail || ''}
        clearable={true}
        onChange={handlerChangeBizEmail}
      />
      <Spacing top={10} />
      <TitleTextBox>세금계산서발행 이용안내</TitleTextBox>
      <Spacing top={6} />
      <Guide>
        <ListTextBox>입력하신 발행 정보 수정이 필요할 경우, 고객센터로 연락바랍니다.</ListTextBox>
      </Guide>
    </Container>
  );
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const TitleTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 700;
`;

const ListTextBox = styled(TextBox)`
  display: list-item;
  list-style-type: disc;
  font-size: 14px;
  font-weight: 350;
`;

const Guide = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
  padding: 0 0 0 14px;
`;

TaxInvoice.propTypes = {
  taxInvoiceInfo: PropTypes.object,
  handlerChangeTaxInvoice: PropTypes.func,
  ordererInformation: PropTypes.object,
};

export default TaxInvoice;
