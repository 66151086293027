import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useIgnorableError from '@ecp/common/src/hooks/useIgnorableError';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';

import { TextBox } from '../../text/TextBox';
import CheckboxUnstyled from '../unstyled/checkbox/CheckboxUnstyled';
import { TextBoxButton } from '@ecp/common/src/text/TextBoxButton';

const StyledErrorMessage = styled.div`
  font-weight: ${theme.font.weight.demiLight};
  font-size: ${theme.font.size.error};
  color: ${theme.color.text.error};
  line-height: 19px;
  height: 19px;
`;

const LabelContent = ({ children, ...labelProps }) => <TextBox {...labelProps}>{children}</TextBox>;
const LabelButtonContent = ({ children, ...labelProps }) => <TextBoxButton {...labelProps}>{children}</TextBoxButton>;

LabelContent.propTypes = {
  labelText: PropTypes.string,
  labelProps: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
};

LabelButtonContent.propTypes = {
  labelText: PropTypes.string,
  labelProps: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
};

const LabeledCheckBox = ({ labelText, labelProps, error, errorMessage, ...props }) => {
  const customLabelProps = { children: labelText, ...labelProps };

  const { ignoreError } = useIgnorableError({ error, value: props.checked });

  return (
    <FlexBoxColumn align-items={'flex-start'} gap={'6px'}>
      <CheckboxUnstyled {...props} labelProps={customLabelProps} label={props?.buttonGb === 'Y' ? LabelButtonContent : LabelContent} />
      {!ignoreError && error && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </FlexBoxColumn>
  );
};

LabeledCheckBox.displayName = 'LabeledCheckBox';
export default LabeledCheckBox;

LabeledCheckBox.propTypes = {
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelProps: PropTypes.object,
  checked: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  buttonGb: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
