import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useOrdererChangePopup from '@ecp/common/src/hooks/order/payment/useOrdererChangePopup';
import { FlexBoxColumn, HorizontalDivider, SpanTitle } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Input from '@ecp/common/src/components/input/Input';
import FullPagePopup from '@ecp/common/src/components/modal/mo/popup/FullPagePopup';

const OrdererChangePopup = ({ open, onClose, ordererInformation, handleChangeOrdererInformation }) => {
  const { formValue, error, handleInputChange, handleClickConfirm } = useOrdererChangePopup({
    open,
    onClose,
    ordererInformation,
    handleChangeOrdererInformation,
  });

  return (
    <FullPagePopup
      open={open}
      onClose={onClose}
      onCancelClick={onClose}
      onConfirmClick={handleClickConfirm}
      header={'주문자 정보 변경'}
      confirmButtonText={'변경'}
      useCancelButton
    >
      <Container>
        <RequireText required width="100%" justify-content="flex-end">
          필수 입력 항목
        </RequireText>

        <Spacing top={'10px'} />
        <HorizontalDivider color="#141415" />
        <Spacing top={'20px'} />
        <TitleTextBox required><SpanTitle>필수 입력 항목</SpanTitle>주문자명</TitleTextBox>
        <Spacing top={'10px'} />
        <Input
          value={formValue.ordererName}
          error={!!error.ordererName}
          errorMessage={error.ordererName}
          name={'ordererName'}
          onChange={handleInputChange}
          width={'100%'}
          clearable={false}
        />
        <Spacing top={'20px'} />

        <TitleTextBox required><SpanTitle>필수 입력 항목</SpanTitle>휴대전화번호</TitleTextBox>
        <Spacing top={'10px'} />
        <Input
          value={formValue.ordererCellphoneNumber}
          error={!!error.ordererCellphoneNumber}
          errorMessage={error.ordererCellphoneNumber}
          name={'ordererCellphoneNumber'}
          onChange={handleInputChange}
          width={'100%'}
          clearable={false}
          onlyNumber
          maxLength={11}
        />
        <Spacing top={'20px'} />

        <TitleTextBox required><SpanTitle>필수 입력 항목</SpanTitle>이메일</TitleTextBox>
        <Spacing top={'10px'} />
        <Input
          value={formValue.ordererEmail}
          error={!!error.ordererEmail}
          errorMessage={error.ordererEmail}
          name={'ordererEmail'}
          onChange={handleInputChange}
          width={'100%'}
          clearable={false}
          maxLength={300}
        />
      </Container>
    </FullPagePopup>
  );
};

OrdererChangePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  ordererInformation: PropTypes.object,
  handleChangeOrdererInformation: PropTypes.func,
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const RequireText = styled(TextBox)`
  font-weight: 500;
  font-size: 12px;
`;

const TitleTextBox = styled(TextBox)`
  font-weight: 500;
  font-size: 14px;
`;

export default OrdererChangePopup;
