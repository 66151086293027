import isEmpty from 'lodash-es/isEmpty';
import { format } from 'date-fns';

export const getPhoneNumbers = (phoneNumber) => {
  if (!phoneNumber) return [null, null, null];
  const target = `${phoneNumber}`;
  const length = target.length;

  if (length === 0) {
    return [null, null, null];
  } else if (length <= 4) {
    return [null, null, target];
  } else if (length <= 8) {
    return [null, target.slice(0, length - 4), target.slice(length - 4, length)];
  } else if (length === 9) {
    return [target.slice(0, 2), target.slice(2, 5), target.slice(5, 9)];
  } else if (length === 10 && target.startsWith('02')) {
    return [target.slice(0, 2), target.slice(2, 6), target.slice(6, 10)];
  } else if (length === 10) {
    return [target.slice(0, 3), target.slice(3, 6), target.slice(6, 10)];
  } else {
    return [target.slice(0, 3), target.slice(3, 7), target.slice(7, length)];
  }
};

export const changeToNonCountryCodeCellNo = (cellNo, splitCode = '-', cellDiv = ',') => {
  const kreanPhonReg = /^820|^82/;
  const phoneSymbolReg = /[+-]/g;

  if (!cellNo) return cellNo;

  cellNo = cellNo.replace(phoneSymbolReg, '');

  //해외 주재원인 경우가 있어 국내 번호 우선 채택
  if (cellNo.indexOf(cellDiv)) {
    const cellNos = cellNo.split(cellDiv);
    const finedCellNos = cellNos.find((cell) => kreanPhonReg.test(cell));

    cellNo = finedCellNos ? finedCellNos : cellNos[0];
  }

  // if (cellNo.startsWith('+')) cellNo = cellNo.substring(cellNo.indexOf(splitCode) + 1);

  // 국내 번호 처리 (서버단에서 국내번호만 010으로 인식)
  if (kreanPhonReg.test(cellNo)) {
    cellNo = cellNo.replace(kreanPhonReg, '');
    if (!cellNo.startsWith('0')) cellNo = '0' + cellNo;
  }

  return cellNo;
};

export const replaceString = (target, options) => {
  let replaced = target;

  let regex = '';
  if (options?.allowNumber) {
    regex = regex.concat('\\d');
  }
  if (options?.allowAlphabet) {
    regex = regex.concat('a-zA-Z');
  }
  if (options?.allowKorean) {
    regex = regex.concat('ㄱ-ㅎ가-힣');
  }

  if (regex) {
    replaced = target.replace(new RegExp(`[^\\s${regex}]`, 'g'), '');
  }

  if (options?.clearSpace) {
    return replaced.replace(/\s/g, '');
  }
  return replaced;
};

export const dotFormattedDate = (dateString) => {
  const date = new Date(dateString);

  return format(date, 'yyyy.MM.dd');
};

const maskingId = (id) => {
  let originStr = id;
  let maskingStr;
  let strLength;

  if (isEmpty(originStr)) {
    return originStr;
  }

  strLength = originStr.length;

  if (strLength < 4) {
    maskingStr = originStr.replace(/(^[^@])?./gi, '$1*');
  } else {
    maskingStr = originStr.replace(/(^[^@]{3})?./gi, '$1*');
  }

  return maskingStr;
};

export const refinePhoneNumber = (phoneNumber) =>
  phoneNumber
    ?.split('-')
    ?.filter((item) => item?.length > 0)
    ?.join('-');

export const masking = {
  id: maskingId,
};

export const toBusinessNumberForamt = (number) => {
  if (number && number.length === 10) {
    return number.substr(0, 3) + '-' + number.substr(3, 2) + '-' + number.substr(5);
  } else return number;
};
