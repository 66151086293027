import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useOrderShippingInformation from '@ecp/common/src/hooks/order/payment/useOrderShippingInformation';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Accordion from '@ecp/common/src/components/accordion/mo/Accordion';
import LabeledCheckBox from '@ecp/common/src/components/checkbox/mo/LabeledCheckBox';
import DropdownDevice from '@ecp/common/src/components/dropdown/mo/DropdownDevice';
import { ORDER_TYPE } from '@ecp/common/src/const/order/orderConst';
import theme from '@ecp/common/src/style/theme/default';

import {
  getGoodListWithMaxQuantity,
  getGoodListWithZeroQuantity,
  goodListWithZeroDeliveryQuantity,
} from '@mo-utils/orderUtils';
import MultiDeliveryPlace from '@mo-components/order/payment/MultiDeliveryPlace';
import SingleDeliveryPlace from '@mo-components/order/payment/SingleDeliveryPlace';
import OrderValidContext from '@mo-components/order/payment/OrderValidContext';

const OrderShippingInformation = ({
  orderDeliveryList,
  handleChangeOrderDeliveryList,
  isMultiDelivery,
  handleChangeMultiDelivery,
  goodList,
  itemTotalCount,
  orderGoodType,
  orderType,
  regularDelivery,
  error,
  isBulkpOrder = false,
}) => {
  const {
    selectedItem,
    dropdownList,
    handleClickMultiDelivery,
    handleChangeDeliveryNumber,
    handleChangeDeliveryPlaceInfo,
  } = useOrderShippingInformation({
    orderDeliveryList,
    handleChangeOrderDeliveryList,
    handleChangeMultiDelivery,
    goodList,
    itemTotalCount,
    getGoodListWithMaxQuantity,
    getGoodListWithZeroQuantity,
    goodListWithZeroDeliveryQuantity,
  });
  return (
    <Container>
      <Accordion initialOpen>
        <Accordion.Header>
          <HeaderTextBox>배송 정보</HeaderTextBox>
          <FlexBox>
            {!isMultiDelivery && (
              <DeliveryPlaceNameTextBox ellipsis>
                {orderDeliveryList[0]?.deliveryPlace.deliveryLocationName}
              </DeliveryPlaceNameTextBox>
            )}
            <Spacing left={13} />
            <FlexBox>
              <Accordion.Control buttonPadding="5px 0 0 0" headerTitle="배송 정보"/>
            </FlexBox>
          </FlexBox>
        </Accordion.Header>
        <Spacing top="10px" />
        <HorizontalDivider color="#141415" />

        <Accordion.Body undead>
          <FlexBoxColumn width="100%" align-items="flex-start">
            {orderType === ORDER_TYPE.REGULAR_DELIVERY && (
              <>
                <Spacing top={14} bottom={14}>
                  <RegularDeliveryTextBox>정기배송 상품은 다중 배송지 설정이 불가합니다.</RegularDeliveryTextBox>
                </Spacing>
                <HorizontalDivider color={'#EAEAEA'} />
              </>
            )}
            {orderType !== ORDER_TYPE.REGULAR_DELIVERY && itemTotalCount > 1 && (
              <>
                <Spacing top="20px" />
                  {isBulkpOrder ? (
                  <BulkpDeliveryNotice width="100%">
                    <BulkpDeliveryNoticeText>※ 2곳 이상의 배송지 설정은</BulkpDeliveryNoticeText>
                    <BulkpDeliveryNoticeText><HighlightText>PC에서 파일 업로드</HighlightText>로만 가능합니다.
                    </BulkpDeliveryNoticeText>
                  </BulkpDeliveryNotice>
                  ) : (
                  <>
                    <FlexBoxSpaceBetween width="100%">
                      <LabeledCheckBox
                        labelText="여러 곳으로 받기"
                        checked={isMultiDelivery}
                        onChange={handleClickMultiDelivery}
                      />
                      {isMultiDelivery && (
                        <FlexBox width="90px">
                          <DropdownDevice
                            items={dropdownList}
                            onChange={handleChangeDeliveryNumber}
                            defaultValue={dropdownList[1]}
                            value={selectedItem}
                          />
                        </FlexBox>
                      )}
                  </FlexBoxSpaceBetween>

                  <Spacing top="14px" />
                  <HorizontalDivider color="#EAEAEA" />
                  </>
                )}
              </>
            )}

            <Spacing top="20px" />
            <OrderValidContext.Provider value={error}>
              {isMultiDelivery ? (
                <MultiDeliveryPlace
                  orderDeliveryList={orderDeliveryList}
                  handleChangeOrderDeliveryList={handleChangeOrderDeliveryList}
                  handleChangeDeliveryPlaceInfo={handleChangeDeliveryPlaceInfo}
                />
              ) : (
                <>
                  <SingleDeliveryPlace
                    orderDeliveryList={orderDeliveryList}
                    isDirectForeignGood={orderGoodType?.directForeignGood}
                    handleChangeDeliveryPlaceInfo={handleChangeDeliveryPlaceInfo}
                    regularDelivery={regularDelivery}
                  />
                </>
              )}
            </OrderValidContext.Provider>
          </FlexBoxColumn>
        </Accordion.Body>
      </Accordion>
    </Container>
  );
};

export default OrderShippingInformation;

OrderShippingInformation.propTypes = {
  itemTotalCount: PropTypes.number,
  isMultiDelivery: PropTypes.bool,
  onMultiDeliveryClick: PropTypes.func,
  goodList: PropTypes.array,
  orderDeliveryList: PropTypes.array,
  handleChangeOrderDeliveryList: PropTypes.func,
  handleChangeMultiDelivery: PropTypes.func,
  hasDeliveryHopeDate: PropTypes.bool,
  orderGoodType: PropTypes.object,
  orderType: PropTypes.string,
  regularDelivery: PropTypes.object,
  error: PropTypes.object,
  isBulkpOrder: PropTypes.bool,
};

const Container = styled(FlexBox)`
  width: 100%;
`;

const HeaderTextBox = styled(TextBox)`
  font-size: 16px;
  font-weight: 700;
`;

const DeliveryPlaceNameTextBox = styled(TextBox)`
  font-size: 14px;
  max-width: 200px;
`;

const RegularDeliveryTextBox = styled(TextBox)`
  font-weight: 350;
  font-size: 12px;
  color: #727272;
`;

const BulkpDeliveryNotice = styled(FlexBoxColumn)`
  justify-content: center;
  width: 320px;
  height: 56px;
  padding-top: 2px;
  background: #f4f4f4;
  border-radius: 4px;
  text-align: center;
  line-height: 16.8px;
`;

const BulkpDeliveryNoticeText = styled(TextBox)`
  font-weight: 400;
  font-size: 12px;
`;

const HighlightText = styled(TextBox)`
  font-weight: 400;
  font-size: 1em;
  color: ${theme.color.primary};
`;