import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import LayerPopup from '@ecp/common/src/components/modal/mo/popup/LayerPopup';

const OrderAgreePopup = ({ open, onClose }) => {
  return (
    <LayerPopup open={open} onClose={onClose} onConfirmClick={onClose} header={'주문동의'} useCancelButton={false}>
      <Container>
        <TitleTextBox>주문 상품의 상품명, 상품가격, 배송정보를 확인 하였으며, 구매에 동의하시겠습니까?</TitleTextBox>
        <Spacing top={'14px'} />
        <DetailTextBox>(근거 전자상거래법 8조 2항)</DetailTextBox>
      </Container>
    </LayerPopup>
  );
};

OrderAgreePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const TitleTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 350;
`;

const DetailTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 350;
  color: #727272;
`;

export default OrderAgreePopup;
