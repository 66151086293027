import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RadioGroupUnstyled from '@ecp/common/src/components/unstyled/input/RadioGroupUnstyled';

const AddressCardRadioGroup = ({ children, ...props }) => {
  return (
    <RadioGroupUnstyled rootComponent={AddressContainer} {...props}>
      {children}
    </RadioGroupUnstyled>
  );
};

AddressCardRadioGroup.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
};

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default AddressCardRadioGroup;
