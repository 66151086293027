import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { usePromiseTracker } from 'react-promise-tracker';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { GOOD_SORT_TYPE } from '@ecp/common/src/const/good';

import useIntersection from '@mo-hooks/common/useIntersection';
import GoodCard from '@mo-components/good/GoodCard';
import GoodSort from '@mo-components/good/GoodSort';
import GoodViewType from '@mo-components/good/GoodViewType';

const GoodTableCardList = ({
  storedGoodCardRef,
  pageProps,
  goodList,
  onSortChange,
  fetchNextPage,
  cardType,
  onViewChange,
  onCardClick,
  titleType,
  maxCount,
  handleSubDisplayCategoryGoodSoldOutChangeList,
}) => {
  return (
    <FlexBoxColumn>
      <TopArea
        pageProps={pageProps}
        onSortChange={onSortChange}
        cardType={cardType}
        onViewChange={onViewChange}
        titleType={titleType}
        maxCount={maxCount}
      />
      <Spacing top={4} />
      <ContentsList
        storedGoodCardRef={storedGoodCardRef}
        goodList={goodList}
        totalCount={pageProps.totalCount}
        fetchNext={fetchNextPage}
        cardType={cardType}
        onCardClick={onCardClick}
        handleSubDisplayCategoryGoodSoldOutChangeList={handleSubDisplayCategoryGoodSoldOutChangeList}
      />
      <Spacing top={20} />
    </FlexBoxColumn>
  );
};

GoodTableCardList.propTypes = {
  storedGoodCardRef: PropTypes.object,
  goodList: PropTypes.array,
  pageProps: PropTypes.shape({
    totalCount: PropTypes.number,
    sort: PropTypes.object,
  }),
  onSortChange: PropTypes.func,
  fetchNextPage: PropTypes.func,
  noDataMessage: PropTypes.string,
  cardType: PropTypes.oneOf(['horizontalSmall', 'horizontalMedium', 'medium']),
  onViewChange: PropTypes.func,
  onCardClick: PropTypes.func,
  titleType: PropTypes.oneOf(['primary', 'secondary']),
  maxCount: PropTypes.number,
  handleSubDisplayCategoryGoodSoldOutChangeList: PropTypes.func,
};
GoodTableCardList.defaultProps = {
  maxCount: 999,
};

const TopArea = ({ pageProps, onSortChange, cardType, onViewChange, titleType, maxCount }) => {
  const { sort, totalCount } = pageProps;
  const [selectedSortItem, setSelectedSortItem] = useState(sort ? sort : GOOD_SORT_TYPE[0]);

  const handleChangeSortDropDown = useCallback(
    (sort) => {
      if (selectedSortItem === sort) {
        return;
      }
      onSortChange(sort);
      setSelectedSortItem(sort);
    },
    [selectedSortItem, onSortChange]
  );

  const handleChangeView = useCallback(() => {
    onViewChange && onViewChange();
  }, [onViewChange]);

  useEffect(() => {
    setSelectedSortItem(sort);
  }, [sort]);

  return (
    <FlexBoxSpaceBetween width={'100%'}>
      {totalCount === undefined && <FlexBox> </FlexBox>}
      {titleType === 'primary' && totalCount !== undefined && (
        <FlexBox>
          <TextBox size={moTheme.font.size.$14} weight={moTheme.font.weight.demiLight}>
            전체
          </TextBox>
          <Spacing left={3} />
          <TextBox size={moTheme.font.size.$14} weight={moTheme.font.weight.medium}>
            {totalCount ? (totalCount <= maxCount ? NumberFormat(totalCount) : NumberFormat(maxCount) + '+') : 0}
          </TextBox>
          <TextBox size={moTheme.font.size.$14} weight={moTheme.font.weight.demiLight}>
            개
          </TextBox>
        </FlexBox>
      )}
      {titleType === 'secondary' && totalCount !== undefined && (
        <FlexBox>
          <TextBox size={moTheme.font.size.$14} weight={moTheme.font.weight.bold} color={theme.color.primary}>
            {totalCount ? (totalCount <= maxCount ? NumberFormat(totalCount) : NumberFormat(maxCount) + '+') : 0}
          </TextBox>
          <TextBox size={moTheme.font.size.$16} weight={moTheme.font.weight.bold}>
            개 상품이 있습니다.
          </TextBox>
        </FlexBox>
      )}
      <FlexBox>
        <GoodSort
          sortLabel={selectedSortItem?.label}
          itemList={GOOD_SORT_TYPE}
          handleSortChange={(val) => handleChangeSortDropDown(val)}
        />
        <GoodViewType
          isCardType={cardType === 'horizontalMedium'}
          isListType={cardType === 'medium'}
          onClick={handleChangeView}
        />
      </FlexBox>
    </FlexBoxSpaceBetween>
  );
};

TopArea.propTypes = {
  pageProps: PropTypes.object,
  onSortChange: PropTypes.func,
  cardType: PropTypes.oneOf(['horizontalSmall', 'horizontalMedium', 'medium']),
  onViewChange: PropTypes.func,
  titleType: PropTypes.oneOf(['primary', 'secondary']),
  maxCount: PropTypes.number,
};
TopArea.defaultProps = {
  titleType: 'primary',
};

const ContentsList = ({ storedGoodCardRef, goodList = [], totalCount, fetchNext, cardType, onCardClick, handleSubDisplayCategoryGoodSoldOutChangeList }) => {
  const navigate = useNavigate();
  const { promiseInProgress } = usePromiseTracker();

  const hasNext = useMemo(
    () => !promiseInProgress && !!goodList.length && totalCount > goodList.length,
    [promiseInProgress, goodList.length, totalCount]
  );
  const { setNextRef } = useIntersection({ fetchNext, hasNext });

  const handleCardClick = useCallback(
    (goodDetail) => {
      navigate(`/good/${goodDetail?.goodNumber}`);
      onCardClick?.();
    },
    [navigate, onCardClick]
  );

  const itemList = useMemo(
    () => (
      <>
        {!!goodList?.length && (
          <GoodCardList ref={storedGoodCardRef}>
            {goodList?.map((goodDetail, index) => (
              <FlexBox
                id={`good-card-${goodDetail?.goodNumber}`}
                key={goodDetail?.goodNumber + index}
                padding={'10px 0'}
              >
                <GoodCard type={cardType} onClick={() => handleCardClick(goodDetail)} {...goodDetail} handleSubDisplayCategoryGoodSoldOutChangeList={handleSubDisplayCategoryGoodSoldOutChangeList}/>
              </FlexBox>
            ))}
          </GoodCardList>
        )}
        {hasNext && <FlexBox ref={setNextRef} width={'100%'} height={'20px'} />}
      </>
    ),
    [goodList, storedGoodCardRef, hasNext, setNextRef, cardType, handleCardClick]
  );

  return (
    <>
      {cardType.includes('horizontal') ? (
        <FlexBox width={'100%'} flex-direction={'column'} align-items={'flex-start'}>
          {itemList}
        </FlexBox>
      ) : (
        <FlexBox
          width={'calc(100% + 8px)'}
          flex-direction={'row'}
          justify-content={'flex-start'}
          align-items={'flex-start'}
          flex-wrap={'wrap'}
        >
          {itemList}
        </FlexBox>
      )}
    </>
  );
};

const GoodCardList = styled(FlexBoxSpaceBetween)`
  width: 320px;
  flex-wrap: wrap;
  align-items: flex-start;
`;

ContentsList.propTypes = {
  storedGoodCardRef: PropTypes.object,
  goodList: PropTypes.array,
  totalCount: PropTypes.number,
  fetchNext: PropTypes.func,
  cardType: PropTypes.oneOf(['horizontalSmall', 'horizontalMedium', 'medium']),
  onCardClick: PropTypes.func,
  handleSubDisplayCategoryGoodSoldOutChangeList: PropTypes.func,
};
ContentsList.defaultProps = {
  cardType: 'horizontalSmall',
};
export default GoodTableCardList;
