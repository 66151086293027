import React from 'react';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

import ServerError from '@mo-components/error/ServerError';

const ServerErrorPage = () => {
  return (
    <Container>
      <Spacing top={180} />
      <ServerError />
      <Spacing top={30} />
      <MessageBox>
        <TextBox weight={500} size={moTheme.font.size.$14} line-height={'22px'}>
          고객센터
        </TextBox>
        <TextBox weight={350} size={moTheme.font.size.$14} line-height={'22px'}>
          080-288-5252 / welmall.sei@samsung.com
        </TextBox>
      </MessageBox>
    </Container>
  );
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  min-height: var(--vh-100);
  margin: 0 auto;
  padding: 0 20px;
`;

const MessageBox = styled(FlexBoxColumn)`
  width: 320px;
  background-color: #f7f7f7;
  padding: 20px;
  align-items: flex-start;
  border-radius: 8px;
`;

export default ServerErrorPage;
