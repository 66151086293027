import { useCallback, useState } from 'react';

const useCashReceiptIssuing = (handleChangeCashReceiptInfo) => {
  const [selectedDeductionType, setSelectedDeductionType] = useState({});
  const [selectedFirstPhoneNumber, setSelectedFirstPhoneNumber] = useState({});

  const handleIncomeDeductionTypeChange = useCallback(
    (e) => {
      if (e.key === 'cellPhone') {
        handleChangeCashReceiptInfo({
          cashReceiptType: 'deduction',
          incomeDeductionType: 'cellPhone',
          cellPhoneNumber1: '010',
        });
      } else if (e.key === 'incomeDeductionCard') {
        handleChangeCashReceiptInfo({ cashReceiptType: 'deduction', incomeDeductionType: 'incomeDeductionCard' });
      }
      setSelectedDeductionType(e);
    },
    [handleChangeCashReceiptInfo]
  );

  const handleChangeType = useCallback(
    ({ target: { value } }) => {
      if (value === 'deduction') {
        handleChangeCashReceiptInfo({
          cashReceiptType: value,
          incomeDeductionType: 'cellPhone',
          cellPhoneNumber1: '010',
        });
      } else {
        handleChangeCashReceiptInfo({
          cashReceiptType: value,
        });
      }
    },
    [handleChangeCashReceiptInfo]
  );

  const handleChangeCellPhoneNumber1 = useCallback(
    (e) => {
      handleChangeCashReceiptInfo((prev) => ({ ...prev, cellPhoneNumber1: e.value }));
      setSelectedFirstPhoneNumber(e);
    },
    [handleChangeCashReceiptInfo]
  );

  const handleChangeCellPhoneNumber2 = useCallback(
    ({ target: { value } }) => {
      handleChangeCashReceiptInfo((prev) => ({ ...prev, cellPhoneNumber2: value.replace(/[^0-9.]/g, '') }));
    },
    [handleChangeCashReceiptInfo]
  );

  const handleChangeCellPhoneNumber3 = useCallback(
    ({ target: { value } }) => {
      handleChangeCashReceiptInfo((prev) => ({ ...prev, cellPhoneNumber3: value.replace(/[^0-9.]/g, '') }));
    },
    [handleChangeCashReceiptInfo]
  );

  const handleChangeCashReceiptCardNumber1 = useCallback(
    ({ target: { value } }) => {
      handleChangeCashReceiptInfo((prev) => ({ ...prev, cashReceiptCardNumber1: value.replace(/[^0-9.]/g, '') }));
    },
    [handleChangeCashReceiptInfo]
  );
  const handleChangeCashReceiptCardNumber2 = useCallback(
    ({ target: { value } }) => {
      handleChangeCashReceiptInfo((prev) => ({ ...prev, cashReceiptCardNumber2: value.replace(/[^0-9.]/g, '') }));
    },
    [handleChangeCashReceiptInfo]
  );
  const handleChangeCashReceiptCardNumber3 = useCallback(
    ({ target: { value } }) => {
      handleChangeCashReceiptInfo((prev) => ({ ...prev, cashReceiptCardNumber3: value.replace(/[^0-9.]/g, '') }));
    },
    [handleChangeCashReceiptInfo]
  );
  const handleChangeCashReceiptCardNumber4 = useCallback(
    ({ target: { value } }) => {
      handleChangeCashReceiptInfo((prev) => ({ ...prev, cashReceiptCardNumber4: value.replace(/[^0-9.]/g, '') }));
    },
    [handleChangeCashReceiptInfo]
  );

  const handleChangeBusinessRegistrationNumber1 = useCallback(
    ({ target: { value } }) => {
      handleChangeCashReceiptInfo((prev) => ({ ...prev, businessRegistrationNumber1: value.replace(/[^0-9.]/g, '') }));
    },
    [handleChangeCashReceiptInfo]
  );

  const handleChangeBusinessRegistrationNumber2 = useCallback(
    ({ target: { value } }) => {
      handleChangeCashReceiptInfo((prev) => ({ ...prev, businessRegistrationNumber2: value.replace(/[^0-9.]/g, '') }));
    },
    [handleChangeCashReceiptInfo]
  );
  const handleChangeBusinessRegistrationNumber3 = useCallback(
    ({ target: { value } }) => {
      handleChangeCashReceiptInfo((prev) => ({ ...prev, businessRegistrationNumber3: value.replace(/[^0-9.]/g, '') }));
    },
    [handleChangeCashReceiptInfo]
  );

  return {
    handleIncomeDeductionTypeChange,
    handleChangeType,
    handleChangeCellPhoneNumber1,
    handleChangeCellPhoneNumber2,
    handleChangeCellPhoneNumber3,
    handleChangeCashReceiptCardNumber1,
    handleChangeCashReceiptCardNumber2,
    handleChangeCashReceiptCardNumber3,
    handleChangeCashReceiptCardNumber4,
    handleChangeBusinessRegistrationNumber1,
    handleChangeBusinessRegistrationNumber2,
    handleChangeBusinessRegistrationNumber3,
    selectedDeductionType,
    selectedFirstPhoneNumber,
  };
};

export default useCashReceiptIssuing;
