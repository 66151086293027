import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import useSwiperPaging from '@ecp/common/src/components/swiper/useSwiperPaging';

import { numberOrStringType } from '../../../../style/propTypes';

const Wrapper = styled(FlexBox)`
  position: absolute;
  width: 100%;
  justify-content: center;
  bottom: ${({ bottom }) => `-${bottom}`};
`;

const currentStyle = css`
  width: 20px;
  background: ${theme.color.icon.current};
`;

const Page = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: ${theme.color.icon.default};
  margin-right: 6px;
  cursor: pointer;

  ${({ isCurrent }) => (isCurrent ? currentStyle : '')}
  &:last-of-type {
    margin-right: 0;
  }
`;

const SwiperPaging = (props) => {
  const { current, total, slidesPerView, bottom = '24px', newTotal } = props;
  let totalCount = newTotal ? newTotal : total;
  const { handlePaging } = useSwiperPaging(props);
  const gap = useMemo(() => -slidesPerView + 1, [slidesPerView]);
  const pages = useMemo(() => [...Array(Math.max(0, totalCount + gap))], [gap, totalCount]);
  return (
    <Wrapper bottom={bottom} height="8px" align-items="flex-end" className="swiper-pagination">
      {pages.map((page, index) => (
        <Page
          isCurrent={index === current + gap}
          index={index}
          current={current}
          key={`swiper-paging__${index}`}
          onClick={(e) => handlePaging(e, index)}
        />
      ))}
    </Wrapper>
  );
};

SwiperPaging.propTypes = {
  bottom: numberOrStringType,
  current: PropTypes.number,
  total: PropTypes.number,
  slidesPerView: PropTypes.number,
  handlePrev: PropTypes.func,
  handleNext: PropTypes.func,
  newTotal: PropTypes.number,
};

SwiperPaging.defaultProps = {
  bottom: '24px',
};

export default SwiperPaging;
