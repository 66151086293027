import React from 'react';
import styled from 'styled-components';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

const WelstoryPay = () => {
  return (
    <Container>
      <TitleTextBox>웰스토리페이 이용안내</TitleTextBox>
      <Spacing top={6} />
      <ListContainer>
        <ListTextBox> 웰스토리페이는 결제수단 등록 한번으로 간편하게 결제하는 서비스입니다.</ListTextBox>
      </ListContainer>
    </Container>
  );
};

const Container = styled(FlexBoxColumn)`
  align-items: flex-start;
`;
const TitleTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 700;
`;
const ListContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
  padding-left: 5px;
`;

const ListTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 350;
  display: list-item;
  list-style-type: '-';
`;

export default WelstoryPay;
