import React, { useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { theme } from '@ecp/common/src/style/theme/default';
import { moTheme } from '@ecp/common/src/style/theme/mo';
import { numberOrStringType, sizePropTypes } from '@ecp/common/src/style/propTypes';
import ButtonUnstyled from '@ecp/common/src/components/unstyled/button/ButtonUnstyled';
import { StyledButton } from '@ecp/common/src/components/button/Button';

const StyledPrimaryButton = styled(StyledButton)`
  background-color: ${theme.color.primary};
`;

const StyledSecondaryButton = styled(StyledButton)`
  background-color: ${theme.color.secondary};
`;

const StyledThirdButton = styled(StyledButton)`
  background-color: ${theme.color.third};
`;

const StyledDefaultButton = styled(StyledButton)`
  border: 1px solid ${theme.color.secondary};
  background-color: ${theme.color.background.white};
  color: ${theme.color.text.basic};

  &:disabled {
    border-color: ${theme.color.line.disabled};
  }
`;

const pageButtonCss = css`
  border-radius: 4px;
  font-size: ${moTheme.font.size.$14};
`;

const StyledPagePrimaryButton = styled(StyledPrimaryButton)`
  ${pageButtonCss};
`;

const StyledPageSecondaryButton = styled(StyledSecondaryButton)`
  ${pageButtonCss};
`;

const StyledPageThirdButton = styled(StyledThirdButton)`
  ${pageButtonCss};
`;

const StyledPageDefaultButton = styled(StyledDefaultButton)`
  ${pageButtonCss};
`;

const StyledInnerButton = styled(StyledButton).attrs(({ size = moTheme.font.size.$13, style }) => ({
  style: {
    ...style,
    fontSize: size,
  },
}))`
  color: ${theme.color.text.basic};
  background-color: ${theme.color.background.white};
  border-radius: 4px;
  border: 1px solid ${theme.color.text.guide};

  &:disabled {
    color: ${theme.color.text.disabled};
    background-color: ${theme.color.disabled};
    border: none;
  }
`;

const StyledInnerThirdButton = styled(StyledInnerButton)`
  background-color: #727272;
  color: #ffffff;
`;

const styledButtonComponents = {
  Primary: StyledPagePrimaryButton,
  Secondary: StyledPageSecondaryButton,
  Third: StyledPageThirdButton,
  Default: StyledPageDefaultButton,
};

export const PageButton = React.forwardRef(({ type = 'Primary', ...props }, ref) => {
  const buttonRef = useRef(null);

  useImperativeHandle(ref, () => buttonRef.current);
  const RootComponent = useMemo(() => styledButtonComponents[type], [type]);
  return <ButtonUnstyled ref={buttonRef} rootComponent={RootComponent} {...props} />;
});

PageButton.displayName = 'PageButton';

PageButton.propTypes = {
  type: PropTypes.oneOf(['Primary', 'Secondary', 'Third', 'Default']),
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  ...sizePropTypes,
};

PageButton.defaultProps = {
  type: 'Primary',
  disabled: false,
  width: '100%',
  height: '46px',
};

export const RectanglePageButton = styled(PageButton)`
  border-radius: 0;
  height: 56px;
  font-size: ${moTheme.font.size.$16};
  font-weight: ${moTheme.font.weight.medium};
`;

const InnerButtonComponents = {
  Third: StyledInnerThirdButton,
  Default: StyledInnerButton,
};
export const InnerButton = React.forwardRef((props, ref) => {
  const buttonRef = useRef(null);

  useImperativeHandle(ref, () => buttonRef.current);

  return (
    <ButtonUnstyled ref={buttonRef} rootComponent={InnerButtonComponents[props.type]} {...props} flex-shrink={'0'} />
  );
});

InnerButton.displayName = 'InnerButton';

InnerButton.propTypes = {
  type: PropTypes.oneOf(['Default', 'Third']),
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: numberOrStringType,
  ...sizePropTypes,
  minWidth: PropTypes.string,
};

InnerButton.defaultProps = {
  type: 'Default',
  disabled: false,
  width: '63px',
  height: '30px',
};

export const ReviewInnerButton = React.forwardRef((props, ref) => {
  const buttonRef = useRef(null);

  useImperativeHandle(ref, () => buttonRef.current);

  return <ReviewButton ref={buttonRef} rootComponent={StyledInnerButton} {...props} />;
});
const ReviewButton = styled(ButtonUnstyled)`
  border-color: ${theme.color.line.line02};
`;

ReviewInnerButton.displayName = 'ReviewInnerButton';

ReviewInnerButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: numberOrStringType,
  ...sizePropTypes,
};

ReviewInnerButton.defaultProps = {
  disabled: false,
  width: '63px',
  height: '30px',
};
