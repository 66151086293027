import React, { useCallback, useState } from 'react';
// import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBoxColumn, SpanTitle } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Input from '@ecp/common/src/components/input/Input';
// import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';
import { PCCC_URL } from '@ecp/common/src/const/good';
// import { openWindow } from '@ecp/common/src/utils/utils';
// import { withMobileStatus } from '@mo-recoil/system/selector';
import NoticeBulletGuideBox from '@mo-components/common/NoticeBulletGuideBox';

const PcccInformation = ({ handleChangePersonalCustomsClearanceCode }) => {
  const [pccc, setPccc] = useState('');
  // const { isMobileApp, agentType } = useRecoilValue(withMobileStatus);

  const onChangePersonalCustomsClearanceCode = useCallback(
    ({ target: { value } }) => {
      setPccc(value);
      handleChangePersonalCustomsClearanceCode(value);
    },
    [handleChangePersonalCustomsClearanceCode]
  );

  return (
    <Container>
      <HeaderTextBox required>
        <SpanTitle>필수 입력 항목</SpanTitle>
        해외직구 개인통관 고유부호
      </HeaderTextBox>
      <Spacing top={'4px'} />
      <Input
        clearable={false}
        maxLength={13}
        placeholder="P로 시작하는 13자리"
        width="320px"
        value={pccc}
        onChange={onChangePersonalCustomsClearanceCode}
      />
      <Spacing top={20} />
      <StyledAtag href={PCCC_URL} target="_blank" title="관세청 전자통관시스템 유니패스페이지로 이동">
        <b>개인통관 고유부호 발급 및 확인</b>
      </StyledAtag>
      {/*<TextButton*/}
      {/*  textProps={{ size: '13px' }}*/}
      {/*  type="move"*/}
      {/*  onClick={() => {*/}
      {/*    openWindow(isMobileApp, PCCC_URL, '_blank', agentType);*/}
      {/*  }}*/}
      {/*>*/}
      {/*  개인통관 고유부호 발급 및 확인*/}
      {/*</TextButton>*/}
      <Spacing top={10} />

      <NoticeBulletGuideBox
        textList={[
          '해외 직배송 상품은 관세청 통관을 위해 수령인의 개인통관 고유부호를 판매자에게 제공합니다.',
          '주문 시 수령인 개인통관 고유부호 입력은 필수이므로 정확한 정보 입력을 권장 드립니다.',
          '주문 완료 후, 개인통관고유부호는 수정할 수 없으므로 입력이 올바르게 되었는지 확인해 주시기 바랍니다.',
        ]}
        outerPadding="0"
      />
    </Container>
  );
};

export default PcccInformation;

PcccInformation.propTypes = {
  handleChangePersonalCustomsClearanceCode: PropTypes.func,
};

PcccInformation.defaultProps = {};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const HeaderTextBox = styled(TextBox)`
  font-weight: 500;
  font-size: 14px;
`;

const StyledAtag = styled.a`
    &:link {
        cursor: pointer;
        color: #0838f6;
        text-decoration: underline;
    }

    &:hover {
        cursor: pointer;
        color: #f66408;
        text-decoration: underline;
    }
`;
