import { atom } from 'recoil';

export const gnbTypeState = atom({
  key: 'gnbTypeState',
  default: 'title',
});

export const gnbTitleState = atom({
  key: 'gnbTitleState',
  default: '',
});
