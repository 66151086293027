import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isEmpty from 'lodash-es/isEmpty';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

import DeliveryInformation from '@mo-components/order/payment/DeliveryInformation';
import OrderGoodItemListSingle from '@mo-components/order/payment/OrderGoodItemListSingle';

const SingleDeliveryPlace = ({
  orderDeliveryList,
  handleChangeDeliveryPlaceInfo,
  isDirectForeignGood,
  regularDelivery,
}) => {
  return (
    !isEmpty(orderDeliveryList) && (
      <Container>
        <DeliveryInformation
          deliveryPlace={orderDeliveryList[0]?.deliveryPlace}
          handleChangeDeliveryPlaceInfo={handleChangeDeliveryPlaceInfo}
          isDirectForeignGood={isDirectForeignGood}
          deliveryPlaceIndex={0}
        />
        <Spacing top={'40px'} />
        <OrderGoodItemListSingle
          goodList={orderDeliveryList[0]?.goodList}
          postNumber={orderDeliveryList[0]?.deliveryPlace?.postNumber}
          regularDelivery={regularDelivery}
        />
      </Container>
    )
  );
};

export default SingleDeliveryPlace;

SingleDeliveryPlace.propTypes = {
  orderDeliveryList: PropTypes.array,
  handleChangeDeliveryPlaceInfo: PropTypes.func,
  isDirectForeignGood: PropTypes.bool,
  handleChangePersonalCustomsClearanceCode: PropTypes.func,
  regularDelivery: PropTypes.object,
};

DeliveryInformation.defaultProps = {
  orderDeliveryList: [],
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;
