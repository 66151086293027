import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { AccordionProvider } from '@ecp/common/src/components/unstyled/accordion/useAccordionContext';

const AccordionUnstyled = (props) => {
  const { children, id, onChange, initialOpen, rootComponent: Accordion, ...otherProps } = props;

  const [open, setOpen] = useState(initialOpen);

  const firstMounted = useRef(true);

  useEffect(() => {
    if (!firstMounted.current) {
      onChange?.(open);
    }

    firstMounted.current = false;
  }, [open, onChange]);

  const handleOpenClick = () => {
    if (!onChange || onChange(!open, id)) {
      setOpen(!open);
    }
  };

  useEffect(() => {
    setOpen(initialOpen);
  }, [initialOpen]);

  return (
    <AccordionProvider value={{ id, open, handleOpenClick }}>
      <Accordion {...otherProps}>{children}</Accordion>
    </AccordionProvider>
  );
};

export default AccordionUnstyled;

export const AccordionPropTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func,
  initialOpen: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

AccordionUnstyled.propTypes = {
  rootComponent: PropTypes.elementType,
  ...AccordionPropTypes,
};

export const AccordionDefaultProps = {
  initialOpen: false,
  backgroundColor: '#ffffff',
};

AccordionUnstyled.defaultProps = AccordionDefaultProps;
