import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { mobileNavigateWithFilterInfoTable } from '@ecp/common/src/utils/good/subCategoryUtil';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';

import CategorySwiper from '@mo-components/display/CategorySwiper';

const CategoryShortcuts = () => {
  const { categoryNumber } = useParams();
  const navigate = useNavigate();

  const handleChangeCategory = useCallback(
    (categoryNumber) => {
      const pathname = `/category/sub/${categoryNumber}`;
      const search = mobileNavigateWithFilterInfoTable.getQueryString(
        mobileNavigateWithFilterInfoTable.initSearchCondition,
        mobileNavigateWithFilterInfoTable.initPageParam
      );
      navigate({ pathname, search });
    },
    [navigate]
  );

  return (
    <Container>
      <CategorySwiper onChange={handleChangeCategory} useAll={false} categoryNumber={categoryNumber} width={'360px'} />
    </Container>
  );
};
export default CategoryShortcuts;
const Container = styled(FlexBoxColumn)`
  width: 100%;
  padding: 0 20px;
`;
