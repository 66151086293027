import { useEffect } from 'react';

const useViewportHeight = () => {
  const setVh = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vh-100', 'calc(var(--vh, 1vh) * 100)');
  };

  useEffect(() => {
    setVh();

    window.addEventListener('resize', setVh);
    return () => window.removeEventListener('resize', setVh);
  }, []);
};

export default useViewportHeight;
