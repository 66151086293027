import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash-es/isEmpty';

import { CARD_COMPANY_CODE } from '@ecp/common/src/const/interface/cardCompanyCode';

import inicisApi from '@mo-apis/interface/inicisApi';

const MobileAutoBillForm = ({ data }) => {
  const [autoBillData, setAutoBillData] = useState({});

  useEffect(() => {
    !!data &&
      !isEmpty(data) &&
      (async () => {
        const { result } = await inicisApi.getInicisMobileAutoBillInformation(data);
        setAutoBillData(result);
      })();
  }, [data]);

  useEffect(() => {
    if (autoBillData.mid) {
      const autoBillForm = document.getElementById('inicis-mobile-auto-bill-form');
      autoBillForm.setAttribute('target', '_self');
      autoBillForm.submit();
    }
  }, [autoBillData.mid]);
  return (
    <div>
      {!!data && !isEmpty(data) && (
        <form id="inicis-mobile-auto-bill-form" method="Post" action={autoBillData.billingUrl} acceptCharset="UTF-8">
          <input type="hidden" readOnly name="mid" value={autoBillData.mid} />
          <input type="hidden" readOnly name="authtype" value={'D'} />
          <input type="hidden" readOnly name="orderid" value={data.orderNo || ''} />
          <input type="hidden" readOnly name="price" value={''} />
          <input type="hidden" readOnly name="timestamp" value={autoBillData.timestamp} />
          <input type="hidden" readOnly name="returnurl" value={autoBillData.returnUrl} />
          <input type="hidden" readOnly name="period" value={''} />
          <input type="hidden" readOnly name="goodname" value={''} />
          <input type="hidden" readOnly name="buyername" value={data.buyerName || ''} />
          <input type="hidden" readOnly name="buyertel" value={''} />
          <input type="hidden" readOnly name="buyeremail" value={''} />
          <input type="hidden" readOnly name="merchantreserved" value={''} />
          <input type="hidden" readOnly name="p_noti" value={autoBillData.noti} />
          <input type="hidden" readOnly name="hashdata" value={autoBillData.hashData || ''} />
        </form>
      )}
    </div>
  );
};

export const mobileAutoBillData = PropTypes.shape({
  cardPointUseYn: PropTypes.oneOf(['Y', 'N']),
  noInterestDivisionCode: PropTypes.string,
  cardCompanyNo: PropTypes.oneOf(Object.values(CARD_COMPANY_CODE)), //카드회사번호
  orderNo: PropTypes.string,
  tempOrderNo: PropTypes.string,
  cartDiviCd: PropTypes.string,
  cartNoList: PropTypes.array,
  buyerName: PropTypes.string,
});

MobileAutoBillForm.propTypes = {
  data: PropTypes.oneOfType([mobileAutoBillData]),
};

export default MobileAutoBillForm;
