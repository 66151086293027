import React from 'react';
import PropTypes from 'prop-types';

import Swiper from '@ecp/common/src/components/swiper/mo/Swiper';
import {
  SwiperBannerItem,
  SwiperBannerPopupItem,
  SwiperLazyItem,
  SwiperMainItem,
  SwiperMdPickItem,
  SwiperMainExhibitionItem,
} from '@ecp/common/src/components/swiper/mo/component/SwiperItem';

export const SwiperMain = (props) => <Swiper control="black" itemComponent={SwiperMainItem} {...props} />;

SwiperMain.displayName = 'SwiperMain';

SwiperMain.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperMainDefaultProps = {
  carouselProps: {
    delay: 4000,
    slidesPerView: 'auto',
    spaceBetween: 10,
    autoplay: true,
    navigation: true,
    loop: true,
    slidesOffsetBefore: 20,
    slidesOffsetAfter: 20,
  },
  itemProps: { width: '360px', height: '280px' },
  width: '360px',
  height: '280px',
};

SwiperMain.defaultProps = {
  itemList: [],
  itemProps: {},
  ...SwiperMainDefaultProps,
};

export const SwiperBasic = (props) => (
  <Swiper
    //arrowProps={{ type: '10', position: 'inner', strokeWidth: '2px' }}
    round={true}
    control="edge"
    itemComponent={SwiperBannerItem}
    pagingProps={{}}
    {...props}
  />
);

SwiperBasic.displayName = 'SwiperBasic';

SwiperBasic.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperBasicDefaultProps = {
  carouselProps: {
    delay: 4000,
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    navigation: true,
    loop: true,
  },
  itemProps: { width: '320px', height: '100px' },
  width: '320px',
  height: '100px',
};

SwiperBasic.defaultProps = {
  itemList: [],
  ...SwiperBasicDefaultProps,
};

export const SwiperMdPick = React.forwardRef(({ ...props }, ref) => {
  return <Swiper round control="exhibition" itemComponent={SwiperMdPickItem} ref={ref} {...props} />;
});

SwiperMdPick.displayName = 'SwiperMdPick';

SwiperMdPick.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperMdPickDefaultProps = {
  carouselProps: {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
  },
  controlProps: { right: '14px' },
  itemProps: { width: '320px', height: '320px' },
  width: '320px',
  height: '320px',
};

SwiperMdPick.defaultProps = {
  itemList: [],
  ...SwiperMdPickDefaultProps,
};

export const SwiperMainExhibition = (props) => (
  <Swiper control="exhibition" itemComponent={SwiperMainExhibitionItem} {...props} />
);

SwiperMainExhibition.displayName = 'SwiperMainExhibition';

SwiperMainExhibition.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperMainExhibitionDefaultProps = {
  carouselProps: {
    delay: 4000,
    slidesPerView: 'auto',
    spaceBetween: 0,
    autoplay: false,
    navigation: true,
    loop: true,
    centeredSlides: true,
    effect: 'creative',
    creativeEffect: {
      prev: {
        shadow: true,
        translate: ['-108%', 0, -70],
      },
      next: {
        shadow: true,
        translate: ['108%', 0, -70],
      },
    },
  },
  itemProps: { width: '300px', height: 'auto', radius: '11px' },
  width: '100%',
  height: 'auto',
};

SwiperMainExhibition.defaultProps = {
  itemList: [],
  ...SwiperMainExhibitionDefaultProps,
};

export const SwiperContents = (props) => <Swiper ref={props?.swiperRef} itemComponent={SwiperBannerItem} {...props} />;

SwiperContents.displayName = 'SwiperContents';

SwiperContents.propTypes = {
  swiperRef: PropTypes.object,
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperContentsDefaultProps = {
  carouselProps: {
    delay: 4000,
    slidesPerView: 'auto',
    spaceBetween: 10,
    navigation: true,
    loop: false,
  },
  itemProps: { width: '130px', height: 'auto', radius: '11px' },
  width: '100%',
  height: 'auto',
};

SwiperContents.defaultProps = {
  itemList: [],
  ...SwiperContentsDefaultProps,
};

export const SwiperContentsPaging = (props) => <Swiper itemComponent={SwiperBannerItem} pagingProps={{}} {...props} />;

SwiperContentsPaging.displayName = 'SwiperContentsPaging';

SwiperContentsPaging.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
  itemComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
};

export const SwiperContentsPagingDefaultProps = {
  carouselProps: {
    delay: 4000,
    slidesPerView: 3,
    spaceBetween: 10,
  },
  itemProps: { width: 'auto', height: 'auto', type: 'extraSmall' },
  width: '100%',
  height: 'auto',
};

SwiperContentsPaging.defaultProps = {
  itemList: [],
  ...SwiperContentsPagingDefaultProps,
};

export const SwiperContentsDetail = React.forwardRef(({ ...props }, ref) => (
  <Swiper ref={ref} round control="arrowLabel" itemComponent={SwiperLazyItem} {...props} />
));

SwiperContentsDetail.displayName = 'SwiperContentsDetail';

SwiperContentsDetail.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperContentsDetailDefaultProps = {
  carouselProps: {
    delay: 4000,
    slidesPerView: 1,
    spaceBetween: 0,
  },
  itemProps: { width: '100%', height: 'auto' },
  width: '100%',
  height: 'auto',
};

SwiperContentsDetail.defaultProps = {
  itemList: [],
  ...SwiperContentsDetailDefaultProps,
};

export const SwiperBanner = React.forwardRef(({ ...props }, ref) => (
  <Swiper
    ref={ref}
    round={false}
    control={'black'}
    controlProps={{ left: 'calc(50% - 32px)' }}
    itemComponent={SwiperBannerPopupItem}
    {...props}
  />
));

SwiperBanner.displayName = 'SwiperBanner';

SwiperBanner.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperBannerDefaultProps = {
  carouselProps: {
    delay: 8000,
    autoplay: true,
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
  },
  itemProps: { width: '260px', height: '260px' },
  width: '260px',
  height: '260px',
};

SwiperBanner.defaultProps = {
  itemList: [],
  ...SwiperBannerDefaultProps,
};
