import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { format, parse } from 'date-fns';

import useDepositWithoutBankbook from '@ecp/common/src/hooks/order/payment/useDepositWithoutBankbook';
import { FlexBox, FlexBoxColumn, SpanTitle } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Input from '@ecp/common/src/components/input/Input';
import DropdownDevice from '@ecp/common/src/components/dropdown/mo/DropdownDevice';

import paymentApi from '@mo-apis/order/paymentApi';

const DepositWithoutBankbook = ({
  ordererInformation,
  handleChangePaymentMethod,
  paymentMethod,
  expectedDepositDate,
}) => {
  const dueDate = useMemo(
    () => format(parse(expectedDepositDate, 'yyyyMMdd', new Date()), 'yyyy년 MM월 dd일'),
    [expectedDepositDate]
  );

  const { handleChangeBank, bankList, selectedBank } = useDepositWithoutBankbook(
    paymentMethod,
    ordererInformation,
    handleChangePaymentMethod,
    paymentApi.getBankList
  );

  return (
    <Container>
      <TitleTextBox required><SpanTitle>필수 입력 항목</SpanTitle>무통장 입금</TitleTextBox>

      <Spacing top={'6px'} />
      <SelectBankContainer>
        <DropdownDevice
          placeholder="선택하세요"
          items={bankList}
          value={selectedBank}
          placement="top"
          width={'194px'}
          onChange={handleChangeBank}
        />
        <Input
          name="depositor"
          minWidth={'120px'}
          height={'36px'}
          value={ordererInformation.ordererName || ''}
          placeholder=""
          readOnly
        />
      </SelectBankContainer>
      <Spacing top={'14px'} />
      <BodyContainer>
        <GuideTextBox>- 입금기한 : {dueDate}</GuideTextBox>
        <GuideTextBox>- 주문일 기준, 다음날까지 미입금 시 자동 취소됩니다.(토, 일, 공휴일 제외)</GuideTextBox>
        <GuideTextBox>- 주문완료 시 계좌정보가 생성됩니다.</GuideTextBox>
      </BodyContainer>
      <Spacing top={'20px'} />

      <BodyContainer>
        <SubTitleTextBox>[가상계좌 안내]</SubTitleTextBox>
        <GuideTextBox>본 쇼핑몰은 시중은행과의 제휴를 통해 고객님만의 고유한 전용계좌를 발급해드립니다.</GuideTextBox>
      </BodyContainer>
      <Spacing top={'10px'} />
      <BodyContainer>
        <SubTitleTextBox>[무통장입금 이용 시 주의사항]</SubTitleTextBox>
        <GuideTextBox>
          입금 계좌번호와 입금예정금액이 주문내역과 일치하지 않을 경우, 입금확인이 지연되며 주문일 기준으로 다음날까지
          입금확인이 되지않은 경우 주문이 취소됩니다. (토,일,공휴일 제외/ 취소전 안내메일발송) 입금시 은행에서 발생되는
          송금수수료는 고객님 부담입니다.
        </GuideTextBox>
      </BodyContainer>
    </Container>
  );
};

export default DepositWithoutBankbook;
DepositWithoutBankbook.propTypes = {
  ordererInformation: PropTypes.object,
  handleChangePaymentMethod: PropTypes.func,
  paymentMethod: PropTypes.object,
  expectedDepositDate: PropTypes.string,
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const TitleTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 700;
`;

const SelectBankContainer = styled(FlexBox)`
  align-items: flex-start;
  justify-content: space-between;
  gap: 6px;
`;

const BodyContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
`;

const GuideTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 350;
`;

const SubTitleTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 500;
`;
