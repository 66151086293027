import { getRequest } from '@mo-apis/axios';

const getPolicyHistoryList = (additionalTypeCd) =>
  getRequest({
    url: `/api/v1/system/policy/history-list`,
    params: {
      additionalTypeCd,
    },
  });

const getPolicyContent = ({ additionalTypeCd, versionNo, applyStartDateTime }) => {
  return getRequest({
    url: '/api/v1/system/policy',
    params: {
      additionalTypeCd,
      versionNo,
      applyStartDateTime,
    },
  });
};

const policyApi = {
  getPolicyHistoryList,
  getPolicyContent,
};

export default policyApi;
