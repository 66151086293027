import { useEffect } from 'react';

import mobilePushApi from '@mo-apis/push/mobilePushApi';

const useMobileBridge = () => {
  const receiveData = async (event) => {
    if (typeof event?.detail?.data === 'boolean' || event?.detail?.data) {
      const isReceive = event?.detail?.data === true || event?.detail?.data === 'true' ? 'Y' : 'N';
      await mobilePushApi.updatePushStatusFromSettings(isReceive);
    }
  };

  useEffect(() => {
    window.pushStatus = new CustomEvent('updatePushStatus');
    window.addEventListener('updatePushStatus', receiveData);
    return () => {
      window.removeEventListener('updatePushStatus', receiveData);
    };
  }, []);
};

export default useMobileBridge;
