import { postRequest } from '../axios';

const updatePushStatusFromAgent = () =>
  postRequest({
    url: '/api/v1/mobile/push/update-notification-agent',
    isMobile: true,
  });

const updatePushStatusFromSettings = (data) =>
  postRequest({
    url: '/api/v1/mobile/push/update-notification-settings',
    isMobile: true,
    data: { isReceive: data },
  });

const mobilePushApi = {
  updatePushStatusFromAgent,
  updatePushStatusFromSettings,
};

export default mobilePushApi;
