import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Paper from '@ecp/common/src/components/paper/Paper';
import LayerPopup from '@ecp/common/src/components/modal/mo/popup/LayerPopup';

const RegularDeliveryAgreePopup = ({ open, onClose }) => {
  return (
    <LayerPopup
      open={open}
      onClose={onClose}
      onConfirmClick={onClose}
      header={'자동 승인 동의'}
      useCancelButton={false}
    >
      <Container>
        <BodyContainer>
          <TitleTextBox>금융거래정보 제공동의서</TitleTextBox>
          <ContentTextBox>
            ① 귀하가 신청하신 신용카드 정기출금 결제는 결제대행사 ㈜케이지이니시스에서 대행합니다.
          </ContentTextBox>
          <ContentTextBox>
            ② 따라서, 귀하의 신용카드 결제내역에는 정기출금과 관련한 이용가맹점이 ㈜케이지이니시스로 표기되오니 이점
            착오 없으시기 바랍니다.
          </ContentTextBox>
          <ContentTextBox>
            ③ 또한 ㈜케이지이니시스는 정기출금 결제대행만을 수행하므로, 정기출금 결제신청 해지 등과 관련한 모든 업무는
            해당 인터넷 상점을 통해 직접 요청하셔야 합니다.
          </ContentTextBox>
          <ContentTextBox>
            ④ ㈜케이지이니시스는 귀하의 본 신청과 관련한 거래내역 e-mail로 통보 드리며, 별도 조회를www.inicis.com 에서
            이용하실 수 있도록 하고 있습니다. ㈜케이지이니시스는 이러한 별도 서비스 제공을 위해 필요한 최소정보만(성명,
            e-mail)을 해당 인터넷 상점으로부터 수령하여 보관하게 됩니다.
          </ContentTextBox>
        </BodyContainer>
        <PaperContainer>
          <PaperTextBox>※ 귀하는 동의를 거부할 권리가 있으며, 동의 거부 시 물품배송이 불가합니다.</PaperTextBox>
        </PaperContainer>
      </Container>
    </LayerPopup>
  );
};

RegularDeliveryAgreePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
  gap: 14px;
`;

const BodyContainer = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
  gap: 10px;
`;

const TitleTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 700;
`;

const ContentTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 350;
  word-break: keep-all;
  overflow-wrap: anywhere;
`;

const PaperContainer = styled(Paper)`
  width: 100%;
  padding: 14px;
  background-color: #f7f7f7;
`;

const PaperTextBox = styled(TextBox)`
  font-size: 12px;
  font-weight: 350;
  color: #5a5a5a;
`;
export default RegularDeliveryAgreePopup;
