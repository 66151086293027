import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn, FlexBoxColumnButton, ImageBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBoxAlignCenter } from '@ecp/common/src/text/TextBox';
import NewIconOpen from '@ecp/common/src/assets/icon/icon_new.png';

export const IconLinkCard = ({ onClick, imageSrc, title, imageProps, size = '46px', newBlpYn, ...props }) => {
  const categoryName = useMemo(() => {
    return title.replace(new RegExp(' ', 'g'), '\n').replace(new RegExp('/', 'g'), '/\n');
  }, [title]);

  return (
    <IconLinkCardWrapper {...props}>
      <FlexBoxColumnButton width={'56px'} justify-content={'flex-start'} onClick={onClick} title={categoryName}>
        <ImageBox
          width={'54px'}
          height={'54px'}
          radius={'50%'}
          background-size={size}
          background-color={theme.color.background.basic}
          imageSrc={imageSrc}
          {...imageProps}
        />
        {newBlpYn === 'Y' && (
          <DefaultBadge>
            <img src={NewIconOpen} width={'15px'} height={'15px'}/>
          </DefaultBadge>
        )}
        <Spacing top={'6px'} />
        <NameWrapper size={11.5} height={moTheme.font.lineHeight.medium}>
          {categoryName}
        </NameWrapper>
      </FlexBoxColumnButton>
    </IconLinkCardWrapper>
  );
};

const NameWrapper = styled(TextBoxAlignCenter)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

const DefaultBadge = styled.div`
  position: absolute;
  left: 70%;
`;

IconLinkCard.propTypes = {
  onClick: PropTypes.func,
  imageSrc: PropTypes.string,
  title: PropTypes.string,
  imageProps: PropTypes.object,
  size: PropTypes.string,
  color: PropTypes.string,
  newBlpYn: PropTypes.string,
};

const MainCategoryItem = ({ largeCategoryIconImageUrl, categoryNumber, categoryName }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/category/${categoryNumber}`);
  };
  return <IconLinkCard onClick={handleClick} imageSrc={largeCategoryIconImageUrl} title={categoryName} />;
};

MainCategoryItem.propTypes = {
  largeCategoryIconImageUrl: PropTypes.string,
  categoryNumber: PropTypes.string,
  categoryName: PropTypes.string,
};

const IconLinkCardWrapper = styled(FlexBoxColumn)`
  justify-content: flex-start;
  padding-bottom: 20px;
  padding-right: 10px;

  &:nth-child(5n) {
    padding-right: 0;
  }
`;

export default MainCategoryItem;
