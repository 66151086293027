import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';

const basicStyle = css`
  color: ${theme.color.text.basic};
  border: 1px solid ${theme.color.text.basic};
  background-color: ${theme.color.background.white};
`;

const primaryStyle = css`
  color: ${theme.color.primary};
  border: 1px solid ${theme.color.primary};
  background-color: ${theme.color.background.white};
`;

const activeStyle = css`
  color: ${theme.color.background.basic};
  border: 1px solid ${theme.color.primary};
  background-color: ${theme.color.primary};
`;

export const styleMap = {
  basic: basicStyle,
  primary: primaryStyle,
  active: activeStyle,
};

const Wrapper = styled(FlexBox)`
  width: fit-content;
  height: 20px;
  box-sizing: border-box;
  padding: 0 4px;

  font-size: 12px;
  font-weight: 350;
  line-height: 160%;
  word-break: keep-all;

  ${({ type }) => styleMap[type]};
`;

export const AccordionTag = (props) => {
  const { type, children } = props;

  return <Wrapper type={type}>{children}</Wrapper>;
};

AccordionTag.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(['primary', 'basic', 'active']),
};

AccordionTag.defaultProps = {
  type: 'basic',
};
