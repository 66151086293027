import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Input from '@ecp/common/src/components/input/mo/Input';
import { MAX_PASSWORD_INPUT_LENGTH, MIN_PASSWORD_INPUT_LENGTH } from '@ecp/common/src/const/input';

const PasswordInput = React.forwardRef(
  (
    {
      value,
      dataKey = 'password',
      styleProps,
      placeholder = `비밀번호(영문,숫자,특수기호 혼합 ${MIN_PASSWORD_INPUT_LENGTH}~${MAX_PASSWORD_INPUT_LENGTH}자리)`,
      onChange,
      disabled,
      title,
      id,
    },
    ref
  ) => {
    const handleChangePassword = useCallback(
      (e) => {
        e.target.value = e.target.value.replace(/\s/g, '');
        onChange(e);
      },
      [onChange]
    );

    return (
      <Input
        ref={ref}
        clearable
        height={styleProps?.height}
        width={styleProps?.width || '100%'}
        minWidth={styleProps?.minWidth}
        placeholder={placeholder}
        value={value}
        onChange={handleChangePassword}
        disabled={disabled}
        data-key={dataKey}
        type="password"
        maxLength={MAX_PASSWORD_INPUT_LENGTH}
        title={title}
        id={id}
      />
    );
  }
);
PasswordInput.propTypes = {
  value: PropTypes.string,
  dataKey: PropTypes.string,
  styleProps: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
};
PasswordInput.displayName = 'PasswordInput';
export default PasswordInput;
