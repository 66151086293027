import { useMemo } from 'react';

import { useAccordionContext } from '@ecp/common/src/components/unstyled/accordion/useAccordionContext';
import { DeleteIcon, ReportIcon } from '@ecp/common/src/components/accordion/Accordion';

const useAccordionHeaderControl = ({ onDeleteClick, onRequestClick }) => {
  const { open, id } = useAccordionContext();

  const handleDeleteClick = () => {
    onDeleteClick?.(id);
  };

  const handleRequestClick = () => {
    onRequestClick?.(id);
  };

  const handleClick = () => {
    open ? handleDeleteClick() : handleRequestClick();
  };

  const label = useMemo(() => (open ? '삭제' : '신고'), [open]);

  const customIcon = useMemo(() => (open ? DeleteIcon : ReportIcon), [open]);
  return { handleClick, label, customIcon };
};

export default useAccordionHeaderControl;
