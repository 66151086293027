import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { InnerButton } from '@ecp/common/src/components/button/mo/Button';
import Input from '@ecp/common/src/components/input/Input';

import GiftiShowDecoPopup from '@mo-components/order/payment/popup/GiftiShowDecoPopup';

function GiftiShowMessageInformation({
  handleChangeGiftiShowInformation,
  giftiShowInformation,
  handleChangeDecoration,
  decorationImageList,
}) {
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleChangeMmsTitle = useCallback(
    (e) => {
      handleChangeGiftiShowInformation((prev) => ({ ...prev, mmsTitle: e.target.value }));
    },
    [handleChangeGiftiShowInformation]
  );
  const handleChangeMmsMessage = useCallback(
    (e) => {
      handleChangeGiftiShowInformation((prev) => ({ ...prev, mmsSendMessage: e.target.value }));
    },
    [handleChangeGiftiShowInformation]
  );

  return (
    <Container>
      <ButtonContainer>
        <InnerButton width={'95px'} onClick={handleOpen}>
          메시지 꾸미기
        </InnerButton>
      </ButtonContainer>
      <Spacing top={6} />
      <TitleTextBox>메시지 제목</TitleTextBox>
      <Spacing top={6} />
      <Input
        width={'100%'}
        placeholder={'(선택입력) 기프티쇼 선물도착'}
        value={giftiShowInformation?.mmsTitle || ''}
        onBlur={handleChangeMmsTitle}
        onChange={handleChangeMmsTitle}
        maxLength={10}
      />
      <Spacing top={14} />
      <TitleTextBox>선물 메시지</TitleTextBox>
      <Spacing top={6} />
      <Input
        width={'100%'}
        placeholder={'(선택입력) 메시지를 입력해주세요'}
        value={giftiShowInformation?.mmsSendMessage || ''}
        onBlur={handleChangeMmsMessage}
        onChange={handleChangeMmsMessage}
        maxLength={200}
      />

      <Spacing top={14} />
      {open && (
        <GiftiShowDecoPopup
          open={open}
          onClose={handleClose}
          decorationImageList={decorationImageList}
          handleChangeDecoration={handleChangeDecoration}
          giftiShowInformation={giftiShowInformation}
        />
      )}
    </Container>
  );
}

GiftiShowMessageInformation.propTypes = {
  giftiShowInformation: PropTypes.object,
  handleChangeGiftiShowInformation: PropTypes.func,
  decorationImageList: PropTypes.array,
  handleChangeDecoration: PropTypes.func,
};

const Container = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
`;

const ButtonContainer = styled(FlexBox)`
  width: 100%;
  justify-content: flex-end;
`;

const TitleTextBox = styled(TextBox)`
  font-weight: 500;
  font-size: 14px;
`;
export default GiftiShowMessageInformation;
