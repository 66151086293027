import React from 'react';

import WpayMemberRegComp from '@ecp/common/src/components/interface/WpayMemberRegComp';

import wpayApi from '@mo-apis/interface/wpayApi';

const WpayMemberRegPage = () => {
  return <WpayMemberRegComp wpayApi={wpayApi} />;
};

export default WpayMemberRegPage;
