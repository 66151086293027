import React from 'react';
import styled from 'styled-components';

import { FlexBoxColumn } from "@ecp/common/src/layouts/flex/styled";
import { Spacing } from "@ecp/common/src/layouts/Spacing";
import { TextBox } from "@ecp/common/src/text/TextBox";

const Mobilians = () => {
  return (
    <Container>
      <TitleTextBox>휴대폰결제 이용안내</TitleTextBox>
      <Spacing top={6} />
      <Guide>
        <ListTextBox>휴대폰 결제 한도 금액은 통신사의 규정에 따라 정해집니다. (결제한도 및 이용내역 문의 : 각 통신사)</ListTextBox>
      </Guide>
    </Container>
  );
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const TitleTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 700;
`;

const ListTextBox = styled(TextBox)`
  display: list-item;
  list-style-type: disc;
  font-size: 14px;
  font-weight: 350;
`;

const Guide = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
  padding: 0 0 0 14px;
`;

export default Mobilians;
Mobilians.propTypes = {
};
