import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { SwiperContentsDetail } from '@ecp/common/src/components/swiper/mo/SwiperTemplate';

import Exhibition from '@mo-components/exhibition/Exhibition';
const RelatedExhibition = ({ relationExhibitionList, title, ...props }) => {
  const navigate = useNavigate();
  const getDisplayTypes = (badgeName01, badgeName02) => {
    let displayTypes = [];
    if (badgeName01) {
      displayTypes.push({ name: badgeName01 });
    }
    if (badgeName02) {
      displayTypes.push({ name: badgeName02 });
    }
    return displayTypes;
  };
  const swiperItemList = useMemo(() => {
    return relationExhibitionList.map(
      ({ exhibitionName, exhibitionNumber, exhibitionSubName, linkUrl, mobileImageUrl, badgeName01, badgeName02 }) => ({
        width: '320px',
        height: '100px',
        src: mobileImageUrl,
        subTitle: exhibitionSubName,
        title: exhibitionName,
        exhibitionNumber,
        linkUrl,
        displayTypes: getDisplayTypes(badgeName01, badgeName02),
      })
    );
  }, [relationExhibitionList]);
  return (
    <Spacing width={'100%'}>
      <FlexBoxColumn align-items={'flex-start'}>
        {swiperItemList.length > 0 && (
          <>
            <TextBox size={moTheme.font.size.$18} weight={moTheme.font.weight.bold}>
              {title}
            </TextBox>
            <Spacing top={20} />
            <SwiperContentsDetail
              {...props}
              itemComponent={Exhibition}
              itemList={swiperItemList}
              onClick={(e, index, item) => navigate('/exhibition/' + item?.exhibitionNumber)}
              height={'241px'}
              itemProps={{
                isSwiperSize: true,
                width: '320px',
                height: '184px',
                radius: '8px',
              }}
              controlProps={{ bottom: '65px' }}
            />
          </>
        )}
      </FlexBoxColumn>
    </Spacing>
  );
};

export default RelatedExhibition;

RelatedExhibition.propTypes = {
  relationExhibitionList: PropTypes.arrayOf(
    PropTypes.shape({
      exhibitionName: PropTypes.string,
      exhibitionNumber: PropTypes.string,
      exhibitionSubName: PropTypes.string,
      linkUrl: PropTypes.string,
      mobileImageUrl: PropTypes.string,
    })
  ),
  title: PropTypes.string,
};
