import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useGiftiShowReceiverInformation from '@ecp/common/src/hooks/order/payment/useGiftiShowReceiverInformation';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Accordion from '@ecp/common/src/components/accordion/mo/Accordion';
import DropdownDevice from '@ecp/common/src/components/dropdown/mo/DropdownDevice';

import {
  getGoodListWithMaxQuantity,
  getGoodListWithZeroQuantity,
  goodListWithZeroDeliveryQuantity,
} from '@mo-utils/orderUtils';
import GiftiShowReceiverList from '@mo-components/order/payment/giftiShow/GiftiShowReceiverList';

function TicketReceiverInformation({
  itemTotalCount,
  goodList,
  orderDeliveryList,
  handleChangeOrderDeliveryList,
  ordererInformation,
}) {
  const { selectedItem, dropdownList, handleChangeDeliveryNumber, canMultiDelivery } = useGiftiShowReceiverInformation({
    itemTotalCount,
    goodList,
    orderDeliveryList,
    handleChangeOrderDeliveryList,
    goodListWithZeroDeliveryQuantity,
    getGoodListWithZeroQuantity,
    getGoodListWithMaxQuantity,
  });

  return (
    <Container>
      <Accordion initialOpen>
        <Accordion.Header>
          <HeaderTextBox>받는 분</HeaderTextBox>
          <FlexBox>
            <Accordion.Control buttonPadding="5px 0 0 0" />
          </FlexBox>
        </Accordion.Header>
        <Spacing top={'10px'} />
        <HorizontalDivider color="#141415" />
        <Spacing top={'14px'} />
        <Accordion.Body undead>
          <FlexBoxColumn width="100%" align-items="flex-start">
            <FlexBox width={'100%'} gap={'10px'}>
              <BodyTitleTextBox>받는 분 수</BodyTitleTextBox>
              <DropdownDevice
                placeholder=""
                items={dropdownList}
                onChange={handleChangeDeliveryNumber}
                defaultValue={dropdownList[0]}
                value={selectedItem}
                width={'90px'}
                disabled={!canMultiDelivery}
              />
            </FlexBox>
            <Spacing top={'14px'} />
            <GiftiShowReceiverList
              orderDeliveryList={orderDeliveryList}
              handleChangeDeliveryPlaceInfo={handleChangeOrderDeliveryList}
              ordererInformation={ordererInformation}
            />
          </FlexBoxColumn>
        </Accordion.Body>
      </Accordion>
    </Container>
  );
}

TicketReceiverInformation.propTypes = {
  itemTotalCount: PropTypes.number,
  goodList: PropTypes.array,
  ordererInformation: PropTypes.object,
  orderDeliveryList: PropTypes.array,
  handleChangeOrderDeliveryList: PropTypes.func,
};

const Container = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
`;

const HeaderTextBox = styled(TextBox)`
  font-weight: 700;
  font-size: 16px;
`;

const BodyTitleTextBox = styled(TextBox)`
  font-weight: 500;
  font-size: 14px;
`;

export default TicketReceiverInformation;
