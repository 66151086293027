import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import clsx from 'clsx';
import useScrollDirection from '@ecp/common/src/hooks/useScrollDirection';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxCenter } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import {gnbTitleState, gnbTypeState} from '@mo-recoil/common/gnb/atom';
import Cart from '@mo-components/common/gnb/Cart';
import { ReactComponent as HomeIcon } from '@mo-assets/icon/icon__home.svg';
import { ReactComponent as MyPageIcon } from '@mo-assets/icon/icon__my-page.svg';
import { ReactComponent as GoBackIcon } from '@mo-assets/icon/icon__go_back.svg';
import useSiteInfo from '@mo-hooks/common/useSiteInfo';
import { appStoreLinkBannerState } from '@mo-recoil/banner/atom';

const Gnb = () => {
  const { isCartUse, isEventMall } = useSiteInfo();
  const scrollDirection = useScrollDirection();
  const scrollDirectionStyle = clsx(scrollDirection);
  const title = useRecoilValue(gnbTitleState);
  const type = useRecoilValue(gnbTypeState);
  const navigate = useNavigate();
  const appLinkBanner = useRecoilValue(appStoreLinkBannerState);

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleMyPageClick = () => {
    navigate('/my-page');
  };

  const handleGoBackClick = () => {
    navigate(-1);
  };
  console.log('type===>>', type);
  return (
    <>
      <Container className={scrollDirectionStyle} appLinkBanner={appLinkBanner}>
        <Spacing left={5} />
        {type === gnbTypes.MENU ? (
          <FlexBox>
            <HomeIcon onClick={handleHomeClick} data-ds-label2={'g_home'} />
          </FlexBox>
        ) : (
          <FlexBox>
            <GoBackIcon onClick={handleGoBackClick} data-ds-label2={'g_back'} />
            <Spacing left={14} />
            <HomeIcon onClick={handleHomeClick} data-ds-label2={'g_home'} />
          </FlexBox>
        )}
        <Spacing left={5} />
        <FlexBoxCenter width={'calc(100% - 158px)'}>
          <TextBox size={moTheme.font.size.$16} weight={'500'} ellipsis>
            {title}
          </TextBox>
        </FlexBoxCenter>
        <Spacing left={5} />
        <FlexBox>
          {isEventMall && <MyPageIcon onClick={handleMyPageClick} data-ds-label2={'g_mypage'} />}
          {isCartUse && (
            <>
              <Spacing left={5} />
              <Cart />
            </>
          )}
        </FlexBox>
        <Spacing right={5} />
      </Container>
      <FlexBox width={'360px'} height={'46px'} />
    </>
  );
};

const Container = styled(FlexBox)`
  justify-content: space-between;
  width: 360px;
  height: 46px;
  box-sizing: border-box;
  background-color: #ffffff;
  padding-top: '0';
  border-bottom: '1px solid #eaeaea';
  z-index: 1200;
  position: fixed;
  top: ${({appLinkBanner}) => appLinkBanner.height}px;

  transition: top 0.3s ease-in-out;
  left: auto;

  &.down,
  &.bottom {
    position: fixed;
    top: ${({appLinkBanner}) => appLinkBanner.height - 46}px;
  }
`;

export default Gnb;

export const gnbTypes = {
  MENU: 'menu',
};
