import React from 'react';
import styled from 'styled-components';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

const Payco = () => {
  return (
    <Container>
      <TitleTextBox>PAYCO 이용안내</TitleTextBox>
      <Spacing top={6} />
      <ListContainer>
        <ListTextBox> PAYCO는 온/오프라인 쇼핑은 물론 송금, 멤버십 적립까지 가능한 통합 서비스입니다.</ListTextBox>
        <ListTextBox> 휴대폰과 카드 명의자가 동일해야 결제 가능하며, 결제금액 제한은 없습니다.</ListTextBox>
        <ListTextBox> 가능 결제 : 국내 모든 신용/체크카드, PAYCO 포인트</ListTextBox>
      </ListContainer>

      <Spacing top={6} />
    </Container>
  );
};

const Container = styled(FlexBoxColumn)`
  align-items: flex-start;
`;
const TitleTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 700;
`;
const ListContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
  padding-left: 5px;
`;

const ListTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 350;
  display: list-item;
  list-style-type: '-';
`;

export default Payco;
