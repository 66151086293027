import React, { useCallback, useMemo, useRef, useState } from 'react';

import { format } from 'date-fns';

import { isValidContents, isValidEmail } from '@ecp/common/src/utils/InputValidateUtils';
import useEventCallback from '@ecp/common/src/hooks/useEventCallback';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import FullPagePopup from '@ecp/common/src/components/modal/mo/popup/FullPagePopup';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';

import { useAlertDialog, useConfirmDialog } from '@mo-hooks/common/useModal';
import { createBulkPurchaseQuestion } from '@mo-apis/customerService/mallQuestionApi';
import BulkOrderDescription from '@mo-pages/home/footer/popup/BulkOrderDescription';
import BulkOrderApplicationForm from './BulkOrderApplicationForm';

const BulkOrderButton = () => {
  const { showAlertDialog } = useAlertDialog();
  const { showConfirmDialog } = useConfirmDialog();

  const initialStateValue = useMemo(() => {
    return {
      title: '',
      company: '',
      mobile: '',
      emailValue: '',
      emailDomain: '',
      emailDirectDomain: '',
      delivery: {},
      deliveryDate: null,
      inquiry: '',
      agree: '0',
    };
  }, []);

  const [popupOpen, setPopupOpen] = useState(false);
  const [formValue, setFormValue] = useState({
    ...initialStateValue,
  });

  const handleChangeFormValues = useEventCallback(
    (v, data) => {
      setFormValue((prev) => ({ ...prev, [data.key]: v }));
    },
    { stop: true, prevent: true }
  );

  const handleChangeFormDropdownValues = useCallback((key, item) => {
    setFormValue((prev) => ({ ...prev, [key]: item, ...(key === 'emailDomain' && { emailDirectDomain: '' }) }));
  }, []);

  const validate = useCallback(
    (form) => {
      const validateFormList = [
        { validateForm: isValidContents(form.title), alertMessage: '제목을 입력해주세요.' },
        { validateForm: isValidContents(form.company), alertMessage: '소속을 입력해주세요.' },
        { validateForm: isValidContents(form.mobile, 9, 11), alertMessage: '연락처를 정확히 입력해주세요.' },
        { validateForm: isValidEmail(form.email), alertMessage: '이메일을 정확하게 입력해주세요.' },
        { validateForm: Object.keys(form.delivery).length !== 0, alertMessage: '배송방법을 선택해주세요.' },
        {
          validateForm: !!form.deliveryDate && form.deliveryDate.toDateString?.() !== '',
          alertMessage: '희망 배송일을 선택해주세요.',
        },
        { validateForm: isValidContents(form.inquiry, 1, 200), alertMessage: '대량 구매 문의 내용을 입력해주세요.' },
        {
          validateForm: form.agree === '1',
          alertMessage: '개인정보수집 및 이용에 동의하지 않을 경우\n 대량구매 문의를 하실 수 없습니다.',
        },
      ];

      return !validateFormList.some(({ validateForm, alertMessage }) => {
        if (!validateForm) {
          showAlertDialog(alertMessage);
          return true;
        }
      });
    },
    [showAlertDialog]
  );

  const handleOpenClick = useCallback(() => {
    setFormValue({ ...initialStateValue });
    setPopupOpen(true);
  }, [initialStateValue]);

  const handleClose = useCallback(() => {
    setPopupOpen(false);
    buttonRef.current.focus();
  }, []);

  const buttonRef = useRef(null);

  const handleSubmitClick = useCallback(() => {
    const form = {
      ...formValue,
      email: `${formValue.emailValue}@${
        formValue.emailDirectDomain === '' ? formValue.emailDomain.value : formValue.emailDirectDomain
      }`,
      questionDate: format(new Date(), 'yyyyMMdd'),
      companyName: formValue.company,
      contactNo: formValue.mobile,
      questionContent: formValue.inquiry,
      questionTitle: formValue.title,
      deliveryMethodCode:
        formValue.delivery.value === 'individualDelivery'
          ? '10'
          : formValue.delivery.value === 'bundleDelivery'
          ? '20'
          : '90',
      hopeDeliDate: formValue.deliveryDate ? format(formValue.deliveryDate, 'yyyyMMdd') : '',
    };

    if (!validate(form)) return;

    showConfirmDialog('문의신청을 하시겠습니까?', async () => {
      const { message, code } = await createBulkPurchaseQuestion({ ...form });

      if (code) {
        showAlertDialog('문의 신청간 에러가 발생하였습니다. \n' + '[' + message + code + ']', handleClose);
      } else {
        showAlertDialog('문의 신청이 완료되었습니다.\n검토후 빠른 회신 드리겠습니다. 감사합니다.', handleClose);
      }
    });
  }, [formValue, handleClose, showConfirmDialog, validate, showAlertDialog]);

  return (
    <>
      <TextButton
        ref={buttonRef}
        textProps={{
          weight: moTheme.font.weight.demiLight,
          height: moTheme.font.lineHeight.demiLight,
        }}
        onClick={handleOpenClick}
      >
        대량구매문의
      </TextButton>
      <FullPagePopup
        open={popupOpen}
        onClose={handleClose}
        header={'대량 구매 문의'}
        useCancelButton={false}
        confirmButtonText={'문의신청'}
        confirmButtonProps={{ type: 'Third', width: '156px' }}
        onConfirmClick={handleSubmitClick}
      >
        <FlexBoxColumn width="100%">
          <BulkOrderDescription />
          <Spacing top={'30px'} />
          <BulkOrderApplicationForm
            formValue={formValue}
            onChangeFormValues={handleChangeFormValues}
            onChangeDropdownValues={handleChangeFormDropdownValues}
          />
        </FlexBoxColumn>
      </FullPagePopup>
    </>
  );
};

export default BulkOrderButton;
