import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import pick from 'lodash-es/pick';

import { getPhoneNumbers } from '@ecp/common/src/utils/textUtil';
import { INICIS_PAY_TYPE, INICIS_PAY_TYPE_MOBILE } from '@ecp/common/src/const/interface/inicisPayType';
import { PAYCO_PAY_TYPE } from '@ecp/common/src/const/interface/paycoPayType';
import { ORDER_TYPE, PAYMENT_MEAN_CODE } from '@ecp/common/src/const/order/orderConst';
import { ORD_BASE_INFO_PARAMS, ORD_DELIVERY_PLACE_INFO_PARAMS } from '@ecp/common/src/const/order/orderPaymentConst';
import {MOBILIANS_PAY_TYPE} from "@ecp/common/src/const/interface/MobiliansPayType";

const useOrderPayment = ({ orderType, isMobile, paymentApi }) => {
  const [payType, setPayType] = useState(null);
  const [payData, setPayData] = useState({});
  const navigate = useNavigate();

  const getOrderCostList = useCallback((orderDeliveryList) => {
    return orderDeliveryList.reduce((accTotal, deliveryItem) => {
      const costList = deliveryItem.costList.map((item) => {
        return {
          deliveryPlaceNumber: item.deliveryNumber,
          deliveryGroupNumber: item.deliveryGroupNumber,
          deliveryCostPolicyNumber: item.deliveryCostPolicyNumber,
          deliveryCost: item.totalDeliveryCostAmount,
          islandMountainDeliveryCost: item.islandMountainDeliveryCostAmount || 0,
          deliveryCostFormCode: item.deliveryCostFormCode,
        };
      });
      return [...accTotal, ...costList];
    }, []);
  }, []);

  const getZeroOrderCostList = useCallback((orderDeliveryList) => {
    return orderDeliveryList.map((item, idx) => {
      return {
        deliveryPlaceNumber: idx,
        deliveryGroupNumber: '1',
        deliveryCostPolicyNumber: '',
        deliveryCost: 0,
        islandMountainDeliveryCost: 0,
        deliveryCostFormCode: '',
      };
    });
  }, []);

  const getCashReceipt = useCallback((cashReceiptInfo) => {
    if (cashReceiptInfo?.cashReceiptType === 'deduction') {
      // 50

      if (cashReceiptInfo.incomeDeductionType === 'cellPhone') {
        const { cellPhoneNumber1, cellPhoneNumber2, cellPhoneNumber3 } = cashReceiptInfo;
        return {
          cashReceiptIssueCode: '30', // OD0033 30 휴대폰
          cashReceiptUseDivisionCode: '10', // OD0012 10 소득공제
          cashReceiptCertificateNumber: `${cellPhoneNumber1 || ''}${cellPhoneNumber2 || ''}${cellPhoneNumber3 || ''}`,
        };
      } else {
        const { cashReceiptCardNumber1, cashReceiptCardNumber2, cashReceiptCardNumber3, cashReceiptCardNumber4 } =
          cashReceiptInfo;
        return {
          cashReceiptIssueCode: '50', // OD0033 50 현금영수증카드
          cashReceiptUseDivisionCode: '10', // OD0012 10 소득공제
          cashReceiptCertificateNumber: `${cashReceiptCardNumber1 || ''}${cashReceiptCardNumber2 || ''}${
            cashReceiptCardNumber3 || ''
          }${cashReceiptCardNumber4 || ''}`,
        };
      }
    }
    if (cashReceiptInfo?.cashReceiptType === 'business') {
      const { businessRegistrationNumber1, businessRegistrationNumber2, businessRegistrationNumber3 } = cashReceiptInfo;
      return {
        cashReceiptIssueCode: '20', // OD0033 20 사업자번호
        cashReceiptUseDivisionCode: '20', // OD0012 20 지출증빙
        cashReceiptCertificateNumber: `${businessRegistrationNumber1 || ''}${businessRegistrationNumber2 || ''}${
          businessRegistrationNumber3 || ''
        }`,
      };
    }
    return { cashReceiptIssueCode: '', cashReceiptUseDivisionCode: '', cashReceiptCertificateNumber: '' };
  }, []);

  const getFormData = useCallback(
    (data) => {
      const {
        orderTypeCode,
        orderGoodType,
        personalCustomsClearanceCode,
        paymentMethod,
        orderDeliveryList,
        ordererInformation,
        paymentAmount,
        presentInfo,
        present,
        couponList = [],
        deliveryCostCoupon,
        giftiShowInformation,
        cashReceiptInfo,
        taxInvoiceInfo,
        expectedDepositDate,
        welPointCashReceiptIssuePoss,
        bulkpOrder,
      } = data;
      const orderDeliveryListWithAdditionalInfo = orderDeliveryList.map(({ deliveryPlace }, idx) => {
        const [cellphoneNumber1, cellphoneNumber2, cellphoneNumber3] = getPhoneNumbers(deliveryPlace.cellphoneNumber);

        return {
          ...deliveryPlace,
          cellphoneNumber1: cellphoneNumber1 || deliveryPlace.cellphoneNumber1,
          cellphoneNumber2: cellphoneNumber2 || deliveryPlace.cellphoneNumber2,
          cellphoneNumber3: cellphoneNumber3 || deliveryPlace.cellphoneNumber3,
          deliveryPlaceNumber: idx,
          deliveryName: '',
          orderName: deliveryPlace.receiverName,
          email: '',
          zip: deliveryPlace.postNumber,
          mailReceiveYn: 'N',
          smsReceiveYn: 'N',
          sms: 'N',
          smsMessage: '',
          pccc: deliveryPlace.pccc,
          deliveryDate: deliveryPlace.deliveryDate,
        };
      });

      const orderGoodList = orderDeliveryList.reduce((accTotal, deliveryItem, deliveryIdx) => {
        const goodList = deliveryItem.goodList.reduce((goodAcc, goodItem) => {
          const itemList = goodItem.itemList
            .filter(({ deliveryQuantity }) => deliveryQuantity > 0)
            .map((item) => ({
              deliveryPlaceNumber: deliveryIdx,
              deliveryGroupNumber: [ORDER_TYPE.GIFTISHOW, ORDER_TYPE.PARKPOINT, ORDER_TYPE.TICKET].includes(orderType)
                ? '1'
                : item.deliveryGroupNumber,
              cartNumber: item.cartNumber,
              goodNumber: goodItem.goodNumber,
              itemNumber: item.itemNumber,
              goodDivisionCode: goodItem.goodDivisionCode,
              goodTypeCode: item.goodTypeCode,
              goodDeliveryReservationDateTime: deliveryItem.deliveryPlace.deliveryDate,
              goodOrderCount: item.deliveryQuantity,
              orderQuantity: item.deliveryQuantity,
              virtualVendNumber: item.virtualVendorNumber,
              goodCouponDiscountAmount: item.goodCouponDiscountAmount || 0,
              cartCouponDiscountAmount: item.cartCouponDiscountAmount || 0,
            }));
          return [...goodAcc, ...itemList];
        }, []);
        return [...accTotal, ...goodList];
      }, []);

      const orderCostList = [ORDER_TYPE.GIFTISHOW, ORDER_TYPE.PARKPOINT, ORDER_TYPE.TICKET].includes(orderType)
        ? getZeroOrderCostList(orderDeliveryList)
        : getOrderCostList(orderDeliveryList);

      const goodName = `${orderDeliveryList[0].goodList[0].goodName} ${
        orderDeliveryList[0].goodList.length > 1 ? `외 ${orderDeliveryList[0].goodList.length - 1}건` : ''
      } `;
      const [cellphoneNumber1, cellphoneNumber2, cellphoneNumber3] = getPhoneNumbers(
        ordererInformation.ordererCellphoneNumber
      );
      const [telephoneNumber1, telephoneNumber2, telephoneNumber3] = getPhoneNumbers(
        ordererInformation.telephoneNumber
      );

      const orderBenefitList = couponList
        .filter(({ isSelect }) => isSelect)
        .filter(({ promotionTypeCode, applyCreditCardList }) => {
          /* 장바구니 쿠폰 카드사 할인 결제 수단 확인 */
          if ('022' === promotionTypeCode) {
            if (Array.isArray(applyCreditCardList) && applyCreditCardList.length > 0) {
              return (
                paymentMethod.payMeanCode === '11' &&
                applyCreditCardList.some((cardCode) => cardCode === paymentMethod.cardCompanyCode)
              );
            }
          }
          return true;
        })
        .map((coupon) => {
          return {
            applyQuantity: coupon.couponItem?.applyQuantity,
            discountAmount: coupon.couponItem?.applyAmount,
            saveAmount: '',
            promotionNo: coupon.promotionNo,
            resourceManagementNo: coupon.resourceManagementNo,
            benefitPolicyCode: coupon.benefitPolicyCode,
            promotionTypeCode: coupon.promotionTypeCode,
            assetResourceDivisionCode: '',
            cardCompanyNumber: '',
            applyItemList: coupon.applyItemList,
          };
        });

      if (Array.isArray(orderCostList) && orderCostList.length === 1) {
        const coupon =
          deliveryCostCoupon && deliveryCostCoupon.applyDeliveryCostCoupon && deliveryCostCoupon.isUseDeliveryCostCoupon
            ? deliveryCostCoupon.coupon
            : undefined;

        if (coupon) {
          orderCostList[0] = {
            ...orderCostList[0],
            promotionNo: coupon.promotionNo,
            resourceManagementNo: coupon.resourceManagementNo,
          };
        }
      }

      const cashReceipt = getCashReceipt(cashReceiptInfo);

      const _data = {
        orderTypeCode: orderTypeCode,
        consumerName: ordererInformation.ordererName,
        consumerCellPhoneNumber1: cellphoneNumber1,
        consumerCellPhoneNumber2: cellphoneNumber2,
        consumerCellPhoneNumber3: cellphoneNumber3,
        telephoneNumber1: telephoneNumber1 || cellphoneNumber1,
        telephoneNumber2: telephoneNumber2 || cellphoneNumber2,
        telephoneNumber3: telephoneNumber3 || cellphoneNumber3,
        memberNumber: ordererInformation.memberNumber,
        orderTotalAmount: paymentAmount?.paymentTotalAmount,
        foreignDeliveryGoodYn: orderGoodType?.directForeignGood /* 변수명 정리 필요 */,
        personalCustomsClearanceNumber: personalCustomsClearanceCode,
        deliveryCost: paymentAmount?.deliveryAmount?.total || 0,
        welPoint: paymentAmount?.pointAmount?.welPoint.use || 0,
        welfarePoint: paymentAmount?.pointAmount?.welfarePoint.use || 0,
        saveAmountPoint: paymentAmount?.pointAmount?.savePoint.use || 0,
        siteNumber: '1000000001',
        payMeanSaveYn: paymentMethod?.payMeanSaveYn,
        payMeanCode: paymentMethod?.payMeanCode,
        bankCode: paymentMethod?.bankCode,
        inicisBankCode: paymentMethod?.inicisBankCode,
        cardCompanyCode: paymentMethod?.cardCompanyCode,
        depositName: paymentMethod?.depositName,
        consumerEmail: ordererInformation.ordererEmail,
        postNumber: orderDeliveryList[0].deliveryPlace.postNumber,
        baseAddress: orderDeliveryList[0].deliveryPlace.baseAddress,
        detailAddress: orderDeliveryList[0].deliveryPlace.detailAddress,
        expectedDepositDate,
        orderBenefitList: orderBenefitList,
        goodName,
        present,
        cashReceiptIssueCode: cashReceipt.cashReceiptIssueCode,
        cashReceiptUseDivisionCode: cashReceipt.cashReceiptUseDivisionCode,
        cashReceiptCertificateNumber: cashReceipt.cashReceiptCertificateNumber,
        welPointCashReceiptIssuePoss,
        bulkpOrder,
        taxInvoiceBizName: taxInvoiceInfo?.bizName || '',
        taxInvoiceBizNumber: taxInvoiceInfo?.bizNumber || '',
        taxInvoiceBizEmail: taxInvoiceInfo?.bizEmail || '',
      };

      const baseInfo = pick(_data, ORD_BASE_INFO_PARAMS);
      const filteredOrderDeliveryList = orderDeliveryListWithAdditionalInfo.map((deliveryPlace) => ({
        ...pick(deliveryPlace, ORD_DELIVERY_PLACE_INFO_PARAMS),
      }));

      return {
        ...baseInfo,
        orderDeliveryList: filteredOrderDeliveryList,
        orderGoodList,
        orderCostList,
        presentInfo,
        giftiShowInfo: giftiShowInformation,
      };
    },
    [orderType, getZeroOrderCostList, getOrderCostList, getCashReceipt]
  );

  const getPayType = useCallback(
    (payMeanCode) => {
      if (isMobile) {
        switch (payMeanCode) {
          case '11':
            return INICIS_PAY_TYPE_MOBILE.CARD;
          case '12':
            return INICIS_PAY_TYPE_MOBILE.REAL_TIME_BANK;
          case '16':
            return INICIS_PAY_TYPE_MOBILE.HPP;
          case '17' /* 페이코 */:
            return PAYCO_PAY_TYPE;
          case '19' /* 휴대폰결제 */:
            return MOBILIANS_PAY_TYPE.HP;
          case 'kakaopay':
            return INICIS_PAY_TYPE_MOBILE.KAKAO_PAY;
          case 'naverpay':
            return INICIS_PAY_TYPE_MOBILE.NAVER_PAY;
          case 'spay':
            return INICIS_PAY_TYPE_MOBILE.SAMSUNG_PAY;
          case 'ssgpay':
            return INICIS_PAY_TYPE_MOBILE.SSG_PAY;
          case 'tosspay':
            return INICIS_PAY_TYPE_MOBILE.TOSS_PAY;
        }
      } else {
        switch (payMeanCode) {
          case '11':
            return orderType === ORDER_TYPE.REGULAR_DELIVERY ? INICIS_PAY_TYPE.AUTO_BILL : INICIS_PAY_TYPE.CARD;
          case '12':
            return INICIS_PAY_TYPE.REAL_TIME_BANK;
          case '17':
            return PAYCO_PAY_TYPE;
          case '19' /* 휴대폰결제 */:
            return MOBILIANS_PAY_TYPE.HP;
          case 'kakaopay':
            return INICIS_PAY_TYPE.KAKAO_PAY;
          case 'naverpay':
            return INICIS_PAY_TYPE.NAVER_PAY;
          case 'spay':
            return INICIS_PAY_TYPE.SAMSUNG_PAY;
          case 'ssgpay':
            return INICIS_PAY_TYPE.SSG_PAY;
          case 'tosspay':
            return INICIS_PAY_TYPE.TOSS_PAY;
        }
      }

      return null;
    },
    [isMobile, orderType]
  );

  const goToFailPage = useCallback(
    (code, message) => {
      navigate('/order/payment-fail', { replace: true, state: { code, message } });
    },
    [navigate]
  );

  const createOrder = useCallback(
    async ({ orderNumber }) => {
      //0원인경우 이니시스 미처리
      navigate('/order/in-progress', { replace: true });
      const {
        result: createOrderResult,
        code,
        message,
      } = await paymentApi.createOrder({
        orderNumber: orderNumber,
        resultCode: '0000',
        resultMsg: '',
      });
      if (code) {
        goToFailPage(code, message);
      } else {
        navigate(`/order/complete/${createOrderResult.orderNumber}`, { replace: true });
      }
    },
    [navigate, paymentApi, goToFailPage]
  );

  const createOrderByVirtualAccount = useCallback(
    async (data) => {
      navigate('/order/in-progress', { replace: true });
      const { result, code, message } = await paymentApi.createOrderByVirtualAccount(getFormData(data));
      if (code) {
        goToFailPage(code, message);
      } else {
        navigate(`/order/complete/${result.orderNumber}`, { replace: true });
      }
    },
    [navigate, paymentApi, getFormData, goToFailPage]
  );

  const createOrderByWpay = useCallback(
    async (data) => {
      const { result, code, message } = await paymentApi.createTempOrder(getFormData(data));
      if (code) {
        return goToFailPage(code, message);
      }

      if (!result.doInicisPayment) {
        await createOrder(result);
      } else {
        const wpayOrder = {
          oid: result.orderNumber, // 임시주문 생성 후 orderNo
          goodsName: result.orderNumber,
          goodsPrice: result.price, // pay amount
          buyerName: result.ordererName, // buyer name
          buyerTel: result.ordererCellNo, // buyer tel
          buyerEmail: result.ordererEmail, // buyer email
        };
        const orderParams = new URLSearchParams(wpayOrder).toString();
        if (isMobile) {
          window.open('/interface/wpay-payment-auth?' + orderParams);
        } else {
          window.open('/interface/wpay-payment-auth?' + orderParams, '', 'width=450,height=870,left=200,top=200');
        }
      }
    },
    [paymentApi, getFormData, goToFailPage, createOrder, isMobile]
  );

  const getInicisPayData = useCallback(
    (result, data) => {
      if (isMobile) {
        /* mobile pay data */
        if (data.paymentMethod?.payMeanCode === '17') {
          return {
            price: result.price,
            paymentNumber: result.payNumber,
            orderNumber: result.orderNumber,
            tempOrderNumber: result.tempOrderNumber,
            cartNoList: Array.isArray(result.cartNumberList) ? result.cartNumberList.join(',') : '',
            orderTypeCode: data.orderTypeCode,
          };
        }

        return {
          price: result.price,
          oid: result.orderNumber,
          goodName: result.goodName,
          buyerName: result.ordererName,
          buyerTel: result.ordererCellNo,
          buyerEmail: result.ordererEmail,
          payNo: result.payNumber,
          orderNo: result.orderNumber,
          tempOrderNo: result.tempOrderNumber,
          cardCompanyNo: data.paymentMethod.cardCompanyCode,
          cartNoList: Array.isArray(result.cartNumberList) ? result.cartNumberList.join(',') : '',
          orderTypeCode: data.orderTypeCode,
          scheduledDeliveryYn: orderType === ORDER_TYPE.REGULAR_DELIVERY ? 'Y' : 'N',
          payMeanCode: [
            PAYMENT_MEAN_CODE.NAVER_PAY,
            PAYMENT_MEAN_CODE.KAKAO_PAY,
            PAYMENT_MEAN_CODE.SAMSUNG_PAY,
            PAYMENT_MEAN_CODE.SSG_PAY,
            PAYMENT_MEAN_CODE.TOSS_PAY,
          ].includes(data.paymentMethod.payMeanCode)
            ? '11'
            : data.paymentMethod.payMeanCode,
        };
      }
      /* pc pay data */
      return {
        price: result.price,
        oid: result.orderNumber,
        goodName: result.goodName,
        buyerName: result.ordererName,
        buyerTel: result.ordererCellNo,
        buyerEmail: result.ordererEmail,
        paymentNumber: result.payNumber,
        orderNumber: result.orderNumber,
        tempOrderNumber: result.tempOrderNumber,
        cardCompanyNo: data.paymentMethod.cardCompanyCode,
        scheduledDeliveryYn: orderType === ORDER_TYPE.REGULAR_DELIVERY ? 'Y' : 'N',
        payMeanCode: [
          PAYMENT_MEAN_CODE.NAVER_PAY,
          PAYMENT_MEAN_CODE.KAKAO_PAY,
          PAYMENT_MEAN_CODE.SAMSUNG_PAY,
          PAYMENT_MEAN_CODE.SSG_PAY,
          PAYMENT_MEAN_CODE.TOSS_PAY,
          PAYMENT_MEAN_CODE.HP,
        ].includes(data.paymentMethod.payMeanCode)
          ? '11'
          : data.paymentMethod.payMeanCode,
      };
    },
    [isMobile, orderType]
  );

  const getMobiliansPayData = useCallback(
    (result, data) => {
      if (isMobile) {
        /* mobile pay data */
        if (data.paymentMethod?.payMeanCode === '19') {
          return {
            price: result.price,
            oid: result.orderNumber,
            goodName: result.goodName,
            buyerName: result.ordererName,
            buyerTel: result.ordererCellNo,
            buyerEmail: result.ordererEmail,
            payNo: result.payNumber,
            orderNo: result.orderNumber,
            tempOrderNo: result.tempOrderNumber,
            cardCompanyNo: data.paymentMethod.cardCompanyCode,
            cartNoList: Array.isArray(result.cartNumberList) ? result.cartNumberList.join(',') : '',
            orderTypeCode: data.orderTypeCode,
            scheduledDeliveryYn: orderType === ORDER_TYPE.REGULAR_DELIVERY ? 'Y' : 'N',
            payMeanCode: [
              PAYMENT_MEAN_CODE.HP
            ].includes(data.paymentMethod.payMeanCode)
              ? '19'
              : data.paymentMethod.payMeanCode,
          };
        }

        return {
          price: result.price,
          oid: result.orderNumber,
          goodName: result.goodName,
          buyerName: result.ordererName,
          buyerTel: result.ordererCellNo,
          buyerEmail: result.ordererEmail,
          payNo: result.payNumber,
          orderNo: result.orderNumber,
          tempOrderNo: result.tempOrderNumber,
          cardCompanyNo: data.paymentMethod.cardCompanyCode,
          cartNoList: Array.isArray(result.cartNumberList) ? result.cartNumberList.join(',') : '',
          orderTypeCode: data.orderTypeCode,
          scheduledDeliveryYn: orderType === ORDER_TYPE.REGULAR_DELIVERY ? 'Y' : 'N',
          payMeanCode: [
            PAYMENT_MEAN_CODE.HP
          ].includes(data.paymentMethod.payMeanCode)
            ? '19'
            : data.paymentMethod.payMeanCode,
        };
      }
      /* pc pay data */
      return {
        price: result.price,
        oid: result.orderNumber,
        goodName: result.goodName,
        buyerName: result.ordererName,
        buyerTel: result.ordererCellNo,
        buyerEmail: result.ordererEmail,
        paymentNumber: result.payNumber,
        orderNumber: result.orderNumber,
        tempOrderNumber: result.tempOrderNumber,
        cardCompanyNo: data.paymentMethod.cardCompanyCode,
        scheduledDeliveryYn: orderType === ORDER_TYPE.REGULAR_DELIVERY ? 'Y' : 'N',
        payMeanCode: [
          PAYMENT_MEAN_CODE.NAVER_PAY,
          PAYMENT_MEAN_CODE.KAKAO_PAY,
          PAYMENT_MEAN_CODE.SAMSUNG_PAY,
          PAYMENT_MEAN_CODE.SSG_PAY,
          PAYMENT_MEAN_CODE.TOSS_PAY,
          PAYMENT_MEAN_CODE.HP,
        ].includes(data.paymentMethod.payMeanCode)
          ? '11'
          : data.paymentMethod.payMeanCode,
      };
    },
    [isMobile, orderType]
  );

  const createOrderByMobilians = useCallback(
    async (data) => {
      const { result, code, message } = await paymentApi.createTempOrder(getFormData(data));
      if (code) {
        return goToFailPage(code, message);
      }
      if (!result.doMobiliansPayment) {
        await createOrder(result);
      } else {
        setPayType(getPayType(data.paymentMethod?.payMeanCode));
        setPayData(getMobiliansPayData(result, data));
      }
    },
    [paymentApi, getFormData, goToFailPage, createOrder, getPayType, getMobiliansPayData]
  );

  const createOrderByInicis = useCallback(
    async (data) => {
      const { result, code, message } = await paymentApi.createTempOrder(getFormData(data));
      if (code) {
        return goToFailPage(code, message);
      }

      if (!result.doInicisPayment) {
        await createOrder(result);
      } else {
        setPayType(getPayType(data.paymentMethod?.payMeanCode));
        setPayData(getInicisPayData(result, data));
      }
    },
    [paymentApi, getFormData, goToFailPage, createOrder, getPayType, getInicisPayData]
  );

  const placeOrder = useCallback(
    (data) => {
      if (data?.paymentMethod?.payMeanCode === PAYMENT_MEAN_CODE.DEPOSIT && data?.paymentAmount?.paymentTotalAmount) {
        (async () => {
          await createOrderByVirtualAccount(data);
        })();
      } else if (data?.paymentMethod?.payMeanCode === PAYMENT_MEAN_CODE.WELSTORY_PAY) {
        (async () => {
          await createOrderByWpay(data);
        })();
      } else if(data?.paymentMethod?.payMeanCode === PAYMENT_MEAN_CODE.HP) {
        (async () => {
          await createOrderByMobilians(data);
        })();
      } else {
        (async () => {
          await createOrderByInicis(data);
        })();
      }
    },
    [createOrderByInicis, createOrderByMobilians, createOrderByVirtualAccount, createOrderByWpay]
  );

  return {
    placeOrder,
    payData,
    payType,
  };
};

export default useOrderPayment;
