import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';
import LabeledCheckBox from '@ecp/common/src/components/checkbox/mo/LabeledCheckBox';
import Accordion from '@ecp/common/src/components/accordion/mo/Accordion';
import Paper from '@ecp/common/src/components/paper/Paper';
import { ORDER_TYPE } from '@ecp/common/src/const/order/orderConst';

import OrderAgreePopupsWrapper from '@mo-components/order/payment/popup/OrderAgreePopupsWrapper';

const AgreeTermsConditions = ({ checked, onChange, orderGoodType, orderType }) => {
  const [personalInfoAgreePopupOpen, setPersonalInfoAgreePopup] = useState(false);
  const [orderAgreePopupOpen, setOrderAgreePopupOpen] = useState(false);
  const [thirdPartyAgreePopupOpen, setThirdPartyAgreePopupOpen] = useState(false);
  const [requestAgreePopupOpen, setRequestAgreePopupOpen] = useState(false);
  const [regularDeliveryAgreePopupOpen, setRegularDeliveryAgreePopupOpen] = useState(false);
  const [autoBillingAgreePopupOpen, setAutoBillingAgreePopupOpen] = useState(false);

  return (
    <Container>
      <Accordion initialOpen>
        <Accordion.Header>
          <LabeledCheckBox
            labelText={'위 내용을 확인하였으며 결제에 동의합니다'}
            checked={checked}
            onChange={onChange}
          />
          <FlexBox>
            <Accordion.Control />
          </FlexBox>
        </Accordion.Header>
        <Spacing top={'10px'} />
        <Accordion.Body undead>
          <BodyContainer>
            <AgreeTermsContainer gap={'6px'}>
              <AgreeTermItem show title="개인정보 수집 이용 동의" onClick={() => setPersonalInfoAgreePopup(true)} />
              <AgreeTermItem
                show={orderGoodType?.pointGood || orderGoodType?.rental}
                title="신청동의"
                onClick={() => setRequestAgreePopupOpen(true)}
              />
              <AgreeTermItem
                show={!orderGoodType?.pointGood && !orderType?.rental}
                title="주문 동의"
                onClick={() => setOrderAgreePopupOpen(true)}
              />
              <AgreeTermItem
                show={orderGoodType?.alcohol || orderGoodType?.directForeignGood}
                title="개인정보 제 3자 제공동의"
                onClick={() => setThirdPartyAgreePopupOpen(true)}
              />
              <AgreeTermItem
                show={orderType === ORDER_TYPE.REGULAR_DELIVERY}
                title="정기배송 서비스 이용동의"
                onClick={() => setRegularDeliveryAgreePopupOpen(true)}
              />
              <AgreeTermItem
                show={orderType === ORDER_TYPE.REGULAR_DELIVERY}
                title="자동 승인 동의"
                onClick={() => setAutoBillingAgreePopupOpen(true)}
              />
              {orderGoodType?.alcohol &&
                <PaperTextBox>
                  ※ 전통주를 포함한 일부 상품에 대해서는 삼성웰스토리(주)는 통신판매중개자로서 통신판매의 당사자가 아니며 주문, 품질, 교환/환불 등 의무와 책임은 삼성웰스토리(주)가 아닌 각 판매자에게 있습니다.<br/>
                  통신판매자 정보는 상세페이지에서 확인 바랍니다.
                </PaperTextBox>
              }
            </AgreeTermsContainer>
          </BodyContainer>
        </Accordion.Body>
      </Accordion>
      <OrderAgreePopupsWrapper
        personalInfoAgreePopupOpen={personalInfoAgreePopupOpen}
        orderAgreePopupOpen={orderAgreePopupOpen}
        thirdPartyAgreePopupOpen={thirdPartyAgreePopupOpen}
        requestAgreePopupOpen={requestAgreePopupOpen}
        regularDeliveryAgreePopupOpen={regularDeliveryAgreePopupOpen}
        autoBillingAgreePopupOpen={autoBillingAgreePopupOpen}
        closePersonalInfoAgreePopup={() => setPersonalInfoAgreePopup(false)}
        closeOrderAgreePopupOpen={() => setOrderAgreePopupOpen(false)}
        closeThirdPartyAgreePopupOpen={() => setThirdPartyAgreePopupOpen(false)}
        closeRequestAgreePopupOpen={() => setRequestAgreePopupOpen(false)}
        closeRegularDeliveryAgreePopupOpen={() => setRegularDeliveryAgreePopupOpen(false)}
        closeAutoBillingAgreePopupOpen={() => setAutoBillingAgreePopupOpen(false)}
        orderGoodType={orderGoodType}
        orderType={orderType}
      />
    </Container>
  );
};

export default AgreeTermsConditions;

AgreeTermsConditions.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  orderGoodType: PropTypes.object,
  orderType: PropTypes.string,
};

const AgreeTermItem = ({ show, title, onClick }) =>
  show && (
    <AgreeTermItemContainer>
      <TextBox size={'13px'} weight={'350'}>
        {title}
      </TextBox>
      <TextButton
        onClick={onClick}
        textProps={{
          size: '12px',
          weight: '350',
          color: '#727272',
          decoration: 'underline',
        }}
        title={title}
      >
        보기
      </TextButton>
    </AgreeTermItemContainer>
  );

AgreeTermItem.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

const Container = styled(FlexBox)`
  padding: 0 20px;
  width: 100%;
`;

const BodyContainer = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const AgreeTermsContainer = styled(Paper)`
  width: 100%;
  padding: 14px;
  background: #f7f7f7;
`;

const AgreeTermItemContainer = styled(FlexBoxSpaceBetween)`
  width: 100%;
`;

const PaperTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 350;
  padding-top: 10px;
  justify-content: center;
`;
