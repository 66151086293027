export const ORDER_TYPE = {
  STANDARD: 'STANDARD',
  PRESENT: 'PRESENT',
  GIFTISHOW: 'GIFTISHOW',
  PARKPOINT: 'PARKPOINT',
  RENTAL: 'RENTAL',
  REGULAR_DELIVERY: 'REGULAR_DELIVERY',
  TICKET: 'TICKET',
  BULKP: 'BULKP',
};

export const PAYMENT_MEAN_CODE = {
  CARD: '11',
  TRANSFER: '12',
  DEPOSIT: '13',
  SAMSUNG_WALLET: '14',
  MOCA_PAY: '15',
  CELL_PHONE: '16',
  PAYCO: '17',
  WELSTORY_PAY: '18',
  HP: '19',
  LALA_POINT: '21',
  WEL_POINT: '22',
  RESERVE_POINT: '23',
  WELFARE_POINT: '24',
  KAKAO_PAY: 'kakaopay',
  NAVER_PAY: 'naverpay',
  SAMSUNG_PAY: 'spay',
  SSG_PAY: 'ssgpay',
  TOSS_PAY: 'tosspay',
  TAX_INVOICE: '32',
};

export const PAYMENT_DIVISION_CODE = {
  PAY: '10',
  CANCEL: '20',
};

export const ORD_GOOD_PARAMS = [
  'additionalItem',
  'additionalItemStockManagement',
  'adultGood',
  'applyPromotion',
  'brandName',
  'brandNumber',
  'deliveryCostFormCode',
  'deliveryFormCode',
  'deliveryGoodDivisionCode',
  'deliveryHopeDay',
  'deliveryKindCode',
  'deliveryLeadTime',
  'deliveryPossibleAreaGroupCode',
  'departmentStoreDivisionCode',
  'familyDiscountTarget',
  'giftCertificateAvailableTermDayCount',
  'giftCertificateAvailableTermEndDate',
  'goodName',
  'goodNumber',
  'goodSalePrice',
  'goodTypeCode',
  'inPackageStandardQty',
  'marginRate',
  'ordPossibleMaxAmount',
  'ordPossibleMaxQty',
  'ordPossibleMaxQtyStandardCode',
  'ordPossibleMinAmount',
  'ordPossibleMinQty',
  'originalPrice',
  'purchaseFormCode',
  'salePrice',
  'saleProgressStatCode',
  'saveAmountGive',
  'shipDeliveryPlaceNo',
  'standardCategoryNumber',
  'stockManagement',
  'stockQty',
  'supplyPrice',
  'taxDivisionCode',
  'thumbnailImageUrl01',
  'thumbnailImageUrl02',
  'thumbnailImageUrl03',
  'vendorGoodNumber',
  'vendorNumber',
  'virtualVendorNumber',
  'ticketNumberUse',
];

export const ORD_ITEM_PARAMS = [
  'additionalItem',
  'additionalItemStockManagement',
  'goodTypeCode',
  'inPackageStandardQty',
  'itemName',
  'itemNumber',
  'goodSalePrice',
  'salePrice',
  'stockManagement',
  'stockQty',
  'supplyPrice',
  'taxDivisionCode',
  'virtualVendorNumber',
];
