export const CARD_COMPANY_CODE = {
  KOREA_EXCHANGE_CARD: '01', //외환카드
  LOTTET_CARD: '03', //롯데카드
  HYUNDAI_CARD: '04', //현대카드
  KB_CARD: '06', //국민카드
  BC_CARD: '11', //비씨카드
  SAMSUNG_CARD: '12', //삼성카드
  LG_CARD: '13', //엘지카드
  SHINHAN_CARD: '14', //신한카드
  NH_CARD: '16', //NH카드
  HANA_SK_CARD: '17', //하나SK카드
  VISA_CARD: '21', //Visa해외카드
  MASTER_CARD: '22', //Master해외카드
  JCB_CARD: '23', //JCB해외카드
  AMEX_CARD: '24', //Amex해외카드
  DINERS_CARD: '25', //Diners해외카드
  HOUSING_CARD: '31', //주택(구동남)카드
  GWANGJU_CARD: '32', //광주은행카드
  JEONBUK_CARD: '33', //전북은행카드
  HANA_CARD: '34', //하나카드
  SANEUN_CARD: '35', //산은카드
  CHUNGBUK_CARD: '36', //충북카드
  NONGHYUP_CARD: '41', //농협카드
  HANMI_CARD: '42', //한미카드
  CITY_CARD: '43', //씨티카드
  WOORI_CARD: '44', //우리카드
  SSG_HANMI_CARD: '45', //신세계한미카드
  NH_BC_CARD: '46', //농협BC카드
  KIUP_BC_CARD: '47', //기업BC카드
  CREDIT_UNION_CARD: '48', //신협카드
  SUHYUP_CARD: '51', //수협카드
  JEJU_CARD: '52', //제주은행카드
  CHOHEUNG_CARD: '53', //조흥카드
  EUNRYUN_CARD: '70', //은련카드
  POST_BC_CARD: '71', //우체국 BC카드
  HANA_BC_CARD: '81', //하나BC카드
  TOSS_PAY: '93', //토스페이
  SAVING_CARD: '95', //저축은행카드
  KAKAO_MONEY: '97', //카카오머니
  ETC: '99', //기타카드
  MILITARY_MUTUAL_AID_ASSOCIATION_KB_CARD: '0601',
  MILITARY_MUTUAL_AID_ASSOCIATION_SHINHAN_CARD: '1401',
};
