import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Accordion from '@ecp/common/src/components/accordion/mo/Accordion';
import StyledImg from '@ecp/common/src/components/image/StyledImg';

const PresentMessageInformation = ({ decorationImageList, handleChangePresentInfo }) => {
  const [selectedDecoration, setSelectedDecoration] = useState({});
  const [presentMessage, setPresentMessage] = useState('');

  // 카드 선택이벤트
  const handlerMessageCardChange = useCallback((e, decoration) => {
    setSelectedDecoration(decoration);
  }, []);

  useEffect(() => {
    setSelectedDecoration(decorationImageList[0]);
  }, [decorationImageList]);

  useEffect(() => {
    selectedDecoration?.decorationId &&
      handleChangePresentInfo({ presentMessage, presentImageId: selectedDecoration.decorationId });
  }, [selectedDecoration, presentMessage, handleChangePresentInfo]);

  const handlerMessageAreaChange = (e) => {
    const message = e.target.value;
    if (message && message.length > 100) {
      setPresentMessage(message.slice(0, 100));
    } else {
      setPresentMessage(message);
    }
  };
  const decoCardList = useMemo(() => {
    return decorationImageList.map((decoration, idx) => (
      <PaperStyle key={idx}>
        {idx === 0 ? (
          <CustomRadio
            type="radio"
            name="card"
            id="1"
            defaultChecked
            onChange={(e) => handlerMessageCardChange(e, decoration)}
          />
        ) : (
          <CustomRadio
            type="radio"
            name="card"
            id={idx + 1}
            onChange={(e) => handlerMessageCardChange(e, decoration)}
          />
        )}
        <CustomLabel htmlFor={idx + 1}>
          <StyledImg src={decoration.imageFilePath} width={72} height={50} alt={decoration?.decorationName} />
        </CustomLabel>
      </PaperStyle>
    ));
  }, [decorationImageList, handlerMessageCardChange]);

  return decorationImageList.length ? (
    <Accordion initialOpen>
      <Accordion.Header>
        <FlexBoxSpaceBetween width="100%" size="14px" color="#141415">
          <TextBox weight="700" size="16px">
            선물 메시지 카드
          </TextBox>
          <FlexBox>
            <Accordion.Control buttonPadding="5px 0 0 0" />
          </FlexBox>
        </FlexBoxSpaceBetween>
      </Accordion.Header>
      <Spacing top="14px" />
      <HorizontalDivider height="1px" color="#141415" />
      <Spacing top="20px" />
      <Accordion.Body undead>
        <FlexBoxColumn>
          <LargeImgAbsoluteBox>
            <LargeImgBox>
              <LargeStyledImg src={selectedDecoration?.imageFilePath} alt={selectedDecoration?.decorationName} />
            </LargeImgBox>
            <TextareaWrap>
              <CardMessageText
                countCheck
                maxLength={100}
                placeholder="내용을 입력해 주세요."
                onChange={(e) => handlerMessageAreaChange(e)}
                value={presentMessage}
              />
              <TextPerStyle>{`${presentMessage.length}/100`}</TextPerStyle>
            </TextareaWrap>
          </LargeImgAbsoluteBox>
          <DecoCardListArea>{decoCardList}</DecoCardListArea>
        </FlexBoxColumn>
      </Accordion.Body>
    </Accordion>
  ) : (
    <></>
  );
};

export default PresentMessageInformation;

PresentMessageInformation.propTypes = {
  decorationImageList: PropTypes.array,
  handleChangePresentInfo: PropTypes.func,
};

const DecoCardListArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
`;
const PaperStyle = styled.div`
  overflow: hidden;
  padding-right: 10px;

  &:last-child {
    padding-right: 0;
  }
`;
const LargeImgAbsoluteBox = styled.div`
  width: 100%;
  height: 223px;
`;
const LargeImgBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 223px;
`;
const LargeStyledImg = styled(StyledImg)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const TextPerStyle = styled(TextBox)`
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #727272;
  font-size: 12px;
  line-height: 160%;
  z-index: 10;
`;
const TextareaWrap = styled.div`
  position: absolute;
  top: 125px;
  left: 20px;
  z-index: 10;
  width: 280px;
  height: 84px;
  padding: 14px;
  background-color: #ffffff;
  border-radius: 7px;
`;
const CustomLabel = styled.label`
  position: relative;
  display: block;
  height: 82px;

  &::before {
    content: '';
    position: absolute;
    left: 26px;
    bottom: 0;
    background: rgb(255, 255, 255);
    border-radius: 100%;
    border: 1px solid rgb(211, 211, 211);
    display: inline-block;
    width: 18px;
    height: 18px;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
  }
`;
const CustomRadio = styled.input`
  display: none;

  &:checked + label + div {
    display: block;
  }

  &:checked + label {
    &::before {
      background-color: ${theme.color.primary};
      border: 2px solid ${theme.color.primary};
      box-shadow: white 0 0 0 2px inset;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 72px;
      height: 50px;
      border: 2px solid ${theme.color.primary};
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
`;

const CardMessageText = styled.textarea`
  width: 100%;
  height: 40px;
  padding: 0;
  align-items: flex-start;
  border: none;
  resize: none;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;
