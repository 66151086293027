import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { nextDayExcludingWeekends } from '@ecp/common/src/utils/dateUtils';
import { FlexBoxColumn, FlexBoxSpaceBetween, SpanTitle } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Calendar from '@ecp/common/src/components/calendar/mo/Calendar';

import OrderPageItemTitleContainer from '@mo-components/order/payment/OrderPageItemTitleContainer';

const OrderDeliveryHopeDate = ({ onChange, orderGoodType, goodNumber = '' }) => {
  const title = useMemo(() => {
    if (orderGoodType?.pointGood || orderGoodType?.ticket) {
      return '수령일';
    } else if (orderGoodType?.rental) {
      return '설치희망일';
    } else {
      return '배송희망일';
    }
  }, [orderGoodType]);

  const calcMinDate = useCallback(() => {
    if (orderGoodType?.pointGood || orderGoodType?.ticket) {
      return new Date();
    } else {
      return nextDayExcludingWeekends(nextDayExcludingWeekends(new Date()));
    }
  }, [orderGoodType]);

  const maxDateCheck = () => {
    // if (goodNumber === '2321355911' || goodNumber === '2321355910' || goodNumber === '2321355881' ||
    //     goodNumber === '2321355346' || goodNumber === '2321355355' || goodNumber === '2321355356' ||
    //     goodNumber === 'N2300133059' || goodNumber === 'N2300133060' || goodNumber === 'N2300133073') {
    //   return new Date('2025-02-28');
    if (goodNumber === 'N2506223514' || goodNumber === 'N2506223752' || goodNumber === 'N2506223753' ||
        goodNumber === 'N2506247473' || goodNumber === 'N2506247498' || goodNumber === 'N2506247555' ||
        goodNumber === 'N2300133059' || goodNumber === 'N2300133060' || goodNumber === 'N2300133073') {
      return new Date('2026-02-28');
    } else {
      return new Date('9999-12-31');
    }
  };

  return (
    <Container>
      <OrderPageItemTitleContainer title={title} gapToBody={'20px'} />
      <BodyContainer>
        <DeliveryHopeDateTextBox required><SpanTitle>필수 입력 항목</SpanTitle>{title}</DeliveryHopeDateTextBox>
        <DeliveryHopeDateInput>
          <Calendar
            inputStyle={{ width: '100%' }}
            dayClassName={() => {}}
            inline={false}
            defaultValue={null}
            minDate={calcMinDate()}
            maxDate={maxDateCheck()}
            onChange={onChange}
          />
        </DeliveryHopeDateInput>
      </BodyContainer>
    </Container>
  );
};

export default OrderDeliveryHopeDate;
OrderDeliveryHopeDate.propTypes = {
  onChange: PropTypes.func,
  orderGoodType: PropTypes.object,
  goodNumber: PropTypes.string,
};

const Container = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
`;

const BodyContainer = styled(FlexBoxSpaceBetween)`
  align-items: flex-start;
  width: 100%;
  height: 36px;
`;

const DeliveryHopeDateInput = styled.div`
  width: 185px;
`;

const DeliveryHopeDateTextBox = styled(TextBox)`
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
`;
