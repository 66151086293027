import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';

import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { SwiperContentsPaging } from '@ecp/common/src/components/swiper/mo/SwiperTemplate';

import { userInfoState } from '@mo-recoil/common/user/atom';
import displayRecommendationApi from '@mo-apis/display/displayRecommendationApi';
import { MainContentsTitle } from '@mo-components/styled';
import GoodCardCarouselItem from '@mo-components/good/GoodCardCarouselItem';

const AiBasedRecommendation = ({ title, displayCategoryNumber, channel, handleVisibility }) => {
  const [tags, setTags] = useState([]);
  const { userName } = useRecoilValue(userInfoState) || {};
  const [goodList, setGoodList] = useState([]);

  useEffect(() => {
    (async () => {
      const {
        result: { interestList = [], goodList = [] },
      } = await displayRecommendationApi.getRecommendationAiBasedGoodList(displayCategoryNumber, channel, 12);
      const randomTags =
        interestList?.length > 3 ? interestList.sort(() => 0.5 - Math.random()).slice(0, 3) : interestList;
      setTags(randomTags);
      setGoodList(
        goodList?.map((it) => ({
          ...it,
          totalReviewScore: undefined,
          flagList: [],
        }))
      );
      handleVisibility?.(goodList?.length > 0);
    })();
  }, [displayCategoryNumber, channel, handleVisibility]);

  const mainTitle = useMemo(() => {
    const customTitle = title || '맞춤 상품을 AI가 추천해요';
    return `${userName} ${customTitle}`;
  }, [title, userName]);

  return (
    <>
      {goodList?.length > 0 && (
        <Spacing width="100%" left={20} right={20}>
          <MainContentsTitle>{mainTitle}</MainContentsTitle>
          {tags?.length > 0 ? (
            <FlexBox padding={'14px 0 20px 0'} width={'100%'}>
              {tags?.map((tag) => (
                <FlexBox key={tag}>
                  <TextBox
                    height="17px"
                    textProps={{
                      weight: moTheme.font.weight.demiLight,
                      size: moTheme.font.size.$12,
                      color: theme.color.text.sub,
                      width: 'max-content',
                    }}
                  >
                    {`#${tag}`}
                  </TextBox>
                  <Spacing left="14px" />
                </FlexBox>
              ))}
            </FlexBox>
          ) : (
            <Spacing top={20} />
          )}
          <SwiperContentsPaging
            data-ds-label2={'reco_personal'}
            itemList={goodList}
            itemProps={{ type: 'extraSmall', width: '100px', height: 'fit-content' }}
            itemComponent={GoodCardCarouselItem}
          />
        </Spacing>
      )}
    </>
  );
};

export default AiBasedRecommendation;

AiBasedRecommendation.propTypes = {
  title: PropTypes.string,
  displayCategoryNumber: PropTypes.string,
  channel: PropTypes.string,
  handleVisibility: PropTypes.func,
};
