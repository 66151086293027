import React from 'react';
import PropTypes from 'prop-types';

const AccordionContext = React.createContext(undefined);

const AccordionProvider = ({ children, value }) => {
  return <AccordionContext.Provider value={value}>{children}</AccordionContext.Provider>;
};

const useAccordionContext = () => {
  const context = React.useContext(AccordionContext);
  if (context === undefined) {
    throw new Error('useAccordionContext must be used within a AccordionProvider');
  }
  return context;
};

AccordionProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  value: PropTypes.object,
};

export { AccordionProvider, useAccordionContext };
