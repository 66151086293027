import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import usePaymentMethodInformation from '@ecp/common/src/hooks/order/payment/usePaymentMethodInformation';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import LabeledCheckBox from '@ecp/common/src/components/checkbox/mo/LabeledCheckBox';
import { ORDER_TYPE } from '@ecp/common/src/const/order/orderConst';

import NoticeBulletGuideBox from '@mo-components/common/NoticeBulletGuideBox';
import OrderPageItemTitleContainer from '@mo-components/order/payment/OrderPageItemTitleContainer';
import CreditCard from '@mo-components/order/payment/paymentMethod/CreditCard';
import DepositWithoutBankbook from '@mo-components/order/payment/paymentMethod/DepositWithoutBankbook';
import Payco from '@mo-components/order/payment/paymentMethod/Payco';
import WelstoryPay from '@mo-components/order/payment/paymentMethod/WelstoryPay';
import CashReceiptIssuing from '@mo-components/order/payment/paymentMethod/CashReceiptIssuing';
import Mobilians from "@mo-components/order/payment/paymentMethod/Mobilians";

import { userInfoState } from '@mo-recoil/common/user/atom';
import {SBCOM_WELSTORY} from "@ecp/common/src/const/unusualSubsidiaryCompany";
import TaxInvoice from "@mo-components/order/payment/paymentMethod/TaxInvoice";

const PaymentMethodInformation = ({
  handleChangePaymentMethod,
  ordererInformation,
  paymentMethod,
  isDirectForeignGood,
  cashReceiptInfo,
  handleChangeCashReceiptInfo,
  usablePaymentList,
  couponList,
  orderType,
  expectedDepositDate,
  isWelPointCashReceiptIssuePoss = false,
  taxInvoiceInfo,
  handlerChangeTaxInvoice,
}) => {
  const userInfo = useRecoilValue(userInfoState);
  const paymentOptions = useMemo(
    () =>
      [
        {
          code: '11',
          name: `신용카드${orderType === ORDER_TYPE.REGULAR_DELIVERY ? ' (정기결제)' : ''}`,
          component: (
            <CreditCard
              handleChangePaymentMethod={handleChangePaymentMethod}
              paymentMethod={paymentMethod}
              orderType={orderType}
            />
          ),
        },
        { code: 'spay', name: '삼성페이', component: '' },
        { code: 'kakaopay', name: '카카오페이', component: '' },
        { code: 'naverpay', name: '네이버페이', component: '' },
        { code: '17', name: 'PAYCO', component: <Payco /> },
        { code: 'tosspay', name: '토스페이', component: '' },
        { code: '18', name: '웰스토리페이', component: <WelstoryPay /> },
        { code: 'ssgpay', name: 'SSG페이', component: '' },
        {
          code: '13',
          name: '무통장입금',
          component: (
            <DepositWithoutBankbook
              paymentMethod={paymentMethod}
              ordererInformation={ordererInformation}
              cashReceiptInfo={cashReceiptInfo}
              handleChangeCashReceiptInfo={handleChangeCashReceiptInfo}
              isDirectForeignGood={isDirectForeignGood}
              handleChangePaymentMethod={handleChangePaymentMethod}
              expectedDepositDate={expectedDepositDate}
            />
          ),
        },
        { code:'19', name: '휴대폰결제', component: <Mobilians /> },
        { code:'32', name: '세금계산서 발행', component:
          <TaxInvoice
            taxInvoiceInfo={taxInvoiceInfo}
            handlerChangeTaxInvoice={handlerChangeTaxInvoice}
          />
        },
      ].filter(({ code }) => usablePaymentList.includes(code)),
    [
      cashReceiptInfo,
      expectedDepositDate,
      handleChangeCashReceiptInfo,
      handleChangePaymentMethod,
      isDirectForeignGood,
      orderType,
      ordererInformation,
      paymentMethod,
      usablePaymentList,
      taxInvoiceInfo,
      handlerChangeTaxInvoice,
    ]
  );

  const { methodComponent, handlePaymentOptionsClick, handleCheckSavePaymentMethod, cardBenefitText } =
    usePaymentMethodInformation(
      paymentMethod,
      handleChangePaymentMethod,
      paymentOptions,
      ordererInformation,
      couponList
    );

  const isCashReceipt = useMemo(() => {
    return (
      usablePaymentList.some((payment) => {
        return payment === '12' || payment === '13'; //선택 가능한 결재수단 중 계좌이체, 무통장이 있는 상품
      }) &&
      (isWelPointCashReceiptIssuePoss || ['12', '13'].includes(paymentMethod.payMeanCode)) //현금영수증 가능 웰포인트 사용 or 계좌이체/무통장을 결제수단으로 선택
    );
  }, [usablePaymentList, isWelPointCashReceiptIssuePoss, paymentMethod.payMeanCode]);

  return paymentOptions.length ? (
    <Container>
      <OrderPageItemTitleContainer title={'결제 방법'} gapToBody={'20px'} />
      <PaymentMethodContainer>
        {paymentOptions.map((item) => {
          return (
            <PaymentMethodItemWrap key={item.code} onClick={() => handlePaymentOptionsClick(item)}>
              <PaymentMethodItem
                clicked={paymentMethod.payMeanCode === item.code}
                isRegularDelivery={orderType === ORDER_TYPE.REGULAR_DELIVERY}
                title={paymentMethod.payMeanCode === item.code ? '선택' : '미선택'}
              >
                {item.name}
              </PaymentMethodItem>
            </PaymentMethodItemWrap>
          );
        })}
      </PaymentMethodContainer>
      {orderType !== ORDER_TYPE.REGULAR_DELIVERY && methodComponent && (
        <>
          <Spacing top={'20px'} />
          <>{methodComponent}</>
        </>
      )}
      {isCashReceipt && (
        <CashReceiptIssuing
          isDirectForeignGood={isDirectForeignGood}
          cashReceiptInfo={cashReceiptInfo}
          handleChangeCashReceiptInfo={handleChangeCashReceiptInfo}
        />
      )}
      {orderType === ORDER_TYPE.REGULAR_DELIVERY || (
        <>
          <Spacing top={'20px'} />
          <LabeledCheckBox
            labelText={'이 결제수단을 다음에도 사용'}
            checked={paymentMethod.payMeanSaveYn}
            onChange={handleCheckSavePaymentMethod}
          />
        </>
      )}

      {cardBenefitText && (
        <>
          <Spacing top={'10px'} />
          <CardBenefitGuideTextBox width={'100%'} align-items={'start'}>
            {cardBenefitText}
          </CardBenefitGuideTextBox>
        </>
      )}
      {![ '12', '13', '19', '32' ].includes(paymentMethod.payMeanCode) && (
        <>
          <Spacing top={'20px'} />
          <NoticeBulletGuideBox
            title="무이자 할부 안내"
            textList={[
              '위 결제액은 최종 결제금액 기준이며, 기준금액 미만이거나, 무이자 할부가 되지 않는 신용카드를 사용하시는 경우 일반 할부로 결제되오니 유의하시기 바랍니다.',
            ]}
            outerPadding={'0'}
          />
          {userInfo.subsidiaryCompanyCode === SBCOM_WELSTORY &&
          <NoticeBulletGuideBox
            title="블루베리포인트 차감 안내"
            textList={[
              '신용카드>삼성카드(SFC/일시불) 결제 時만 포인트 차감이 가능합니다.',
              '삼성, 네이버, PAYCO, 카카오페이 等 간편결제는 차감 제외되는 점 참고 바랍니다.',
            ]}
            outerPadding={'0'}
          />
          }
        </>
      )}
      {orderType === ORDER_TYPE.REGULAR_DELIVERY && (
        <>
          <Spacing top={'20px'} />
          <NoticeBulletGuideBox
            title="정기배송 이용안내"
            textList={[
              '정기배송 상품은 할인혜택, 포인트 사용이 불가합니다.',
              '정기배송 상품은 1회차는 주문시 바로 결제, 2회차 결제부터는 배송일 3일전에 등록하신 카드로 결제 되며, 결제 실패 시 해당 회차는 배송이 진행 되지 않습니다.',
              '제품의 종류 혹은 재고 상태에 따라 제품별로 출고 일정이 달라지거나 해지 될 수 있습니다.',
              '배송예정일 전일 또는 당일이 공휴일인 경우, 실 배송일자는 변동 될 수 있습니다.',
              '결제완료 후 주문취소/교환/반품은 회차별로 접수되며, 잔여 회차 해지 및 기타 자세한 사항은 고객센터 (080-288-5252) 로 문의해 주시기 바랍니다.',
            ]}
            outerPadding={'0'}
          />
        </>
      )}
    </Container>
  ) : (
    <></>
  );
};

export default PaymentMethodInformation;

PaymentMethodInformation.propTypes = {
  handleChangePaymentMethod: PropTypes.func,
  ordererInformation: PropTypes.object,
  paymentMethod: PropTypes.object,
  isDirectForeignGood: PropTypes.bool,
  cashReceiptInfo: PropTypes.object,
  handleChangeCashReceiptInfo: PropTypes.func,
  usablePaymentList: PropTypes.arrayOf(PropTypes.string),
  couponList: PropTypes.array,
  orderType: PropTypes.oneOf(Object.values(ORDER_TYPE)),
  expectedDepositDate: PropTypes.string,
  isWelPointCashReceiptIssuePoss: PropTypes.bool,
  taxInvoiceInfo: PropTypes.object,
  handlerChangeTaxInvoice: PropTypes.func,
};

const Container = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
`;

const PaymentMethodContainer = styled(FlexBox)`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
`;

const PaymentMethodItemWrap = styled.div``;

const PaymentMethodItem = styled.button.attrs(({ clicked, isRegularDelivery }) => ({
  style: {
    borderColor: clicked ? theme.color.primary : '#EAEAEA',
    color: clicked ? theme.color.primary : '#141415',
    width: isRegularDelivery ? '320px' : '104px',
  },
}))`
  height: 46px;
  border: 2px solid;
  font-size: 13px;
  font-weight: 500;
`;

const CardBenefitGuideTextBox = styled(TextBox)`
  color: ${theme.color.primary};
  font-size: 12px;
  font-weight: 300;
  line-height: 19px;
`;
