import { useCallback, useState } from 'react';

function usePresentReceiverContent({ ordererInformation, handleChangeDeliveryInfo, index }) {
  const [isSameAsOrderUser, setIsSameAsOrderUser] = useState(false);
  const [receiverName, setReceiveName] = useState('');
  const [cellphoneNumber, setCellphoneNumber] = useState('');

  const handleUseOrderUserInfoCheckChange = useCallback(
    (e) => {
      const { checked } = e.target;
      setIsSameAsOrderUser(checked);
      const name = checked ? ordererInformation?.ordererName : '';
      const phoneNumber = checked ? ordererInformation?.ordererCellphoneNumber.replace(/-/gi, '') : '';
      setReceiveName(name);
      setCellphoneNumber(phoneNumber);
      handleChangeDeliveryInfo({ receiverName: name, cellphoneNumber: phoneNumber }, index);
    },
    [handleChangeDeliveryInfo, index, ordererInformation?.ordererCellphoneNumber, ordererInformation?.ordererName]
  );

  const handleOrderNameChange = useCallback(
    (e) => {
      const regexp = /^[ㄱ-ㅎ가-힣ㆍᆢa-zA-Z]*$/;
      const name = e.target.value;
      if (!regexp.test(name)) return;
      setReceiveName(name);
      setIsSameAsOrderUser(false);
      handleChangeDeliveryInfo({ receiverName: name, cellphoneNumber }, index);
    },
    [cellphoneNumber, handleChangeDeliveryInfo, index]
  );

  const handleOrderPhoneNumberChange = useCallback(
    (e) => {
      const phoneNumber = e.target.value;
      setCellphoneNumber(phoneNumber);
      setIsSameAsOrderUser(false);
      handleChangeDeliveryInfo({ receiverName, cellphoneNumber: phoneNumber }, index);
    },
    [handleChangeDeliveryInfo, index, receiverName]
  );

  return {
    isSameAsOrderUser,
    handleUseOrderUserInfoCheckChange,
    handleOrderNameChange,
    handleOrderPhoneNumberChange,
    receiverName,
    cellphoneNumber,
  };
}

export default usePresentReceiverContent;
