import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';
import { BEST_ITEM_CLASS_CODE } from '@ecp/common/src/const/display/mainTemplateConst';

import displayRecommendationApi from '@mo-apis/display/displayRecommendationApi';
import GoodCard from '@mo-components/good/GoodCard';
import HeaderTitle from '@mo-pages/category/components/HeaderTitle';

const Contents = ({ bestGoodList, handleBestGoodSoldOutChangeList }) => (
  <FlexBoxSpaceBetween width={'100%'} flex-direction={'row'} align-items={'flex-start'} flex-wrap={'wrap'}>
    {bestGoodList.map((goodDetail, index) => (
      <FlexBox key={goodDetail?.goodNumber + index} padding={'0 0 30px 0'}>
        <GoodCard
          hideBestFlag
          type={'medium'}
          itemDecorators={[{ badgeContent: index + 1, type: 'SMALL' }]}
          {...goodDetail}
          handleBestGoodSoldOutChangeList={handleBestGoodSoldOutChangeList}
        />
      </FlexBox>
    ))}
  </FlexBoxSpaceBetween>
);

Contents.propTypes = {
  bestGoodList: PropTypes.array,
  handleBestGoodSoldOutChangeList: PropTypes.func,
};

const Footer = ({ categoryNumber }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/best', { state: { tabCode: BEST_ITEM_CLASS_CODE.ORDER, categoryNumber } });
  };

  return (
    <>
      <HorizontalDivider />
      <Spacing top={10} />
      <TextButton type={'move'} onClick={handleClick}>
        BEST 상품 전체보기
      </TextButton>
    </>
  );
};

Footer.propTypes = {
  categoryNumber: PropTypes.string,
};

const BestGood = () => {
  const { categoryNumber } = useParams();
  const [bestGoodList, setBestGoodList] = useState([]);

  useEffect(() => {
    (async () => {
      const { result = [] } = await displayRecommendationApi.getRecommendationOrderGoodList(
        categoryNumber,
        6,
        'main_best_week'
      );
      setBestGoodList(result);
    })();
  }, [categoryNumber]);

  const handleBestGoodSoldOutChangeList = useCallback (async () => {
    const { result = [] } = await displayRecommendationApi.getRecommendationOrderGoodList(
      categoryNumber,
      6,
      'main_best_week'
    );
    setBestGoodList(result);
  }, []);

  return (
    <>
      {bestGoodList.length > 0 && (
        <>
          <Spacing top={40} />
          <Container>
            <HeaderTitle title={'BEST 상품'} />
            <Spacing top={14} />
            <Contents bestGoodList={bestGoodList} handleBestGoodSoldOutChangeList={handleBestGoodSoldOutChangeList}/>
            <Footer categoryNumber={categoryNumber} />
          </Container>
        </>
      )}
    </>
  );
};
export default BestGood;

const Container = styled(FlexBoxColumn)`
  width: 100%;
  padding: 0 20px;
`;
