import { atom } from 'recoil';

export const showNavigationMenuState = atom({
  key: 'showNavigationMenuState',
  default: false,
});

export const hideNavigationMenuState = atom({
  key: 'hideNavigationMenuState',
  default: false,
});

export const showBenefitState = atom({
  key: 'showBenefitState',
  default: false,
});

export const navigationOffsetState = atom({
  key: 'navigationOffsetState',
  default: 0,
});

export const employeeBenefitOpenState = atom({
  key: 'employeeBenefitOpenState',
  default: false,
});

export const employeeBenefitMessageState = atom({
  key: 'employeeBenefitMessageState',
  default: '',
});
