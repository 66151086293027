import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import ChipOrigin from '@ecp/common/src/components/chip/mo/ChipOrigin';
import { SwiperContents, SwiperContentsDefaultProps } from '@ecp/common/src/components/swiper/mo/SwiperTemplate';

const ChipItem = ({ item, itemProps }) => {
  const { onClick, onClose } = itemProps;
  return (
    <ChipOrigin
      closable={item?.closable}
      type={item?.type}
      data-value={item?.label}
      checked={item?.checked}
      onClick={() => onClick?.(item)}
      onClose={() => onClose?.(item)}
    >
      {item?.label}
    </ChipOrigin>
  );
};
ChipItem.propTypes = {
  item: PropTypes.object,
  itemProps: PropTypes.object,
};
const SwiperChipOrigin = ({ itemList, itemProps, width, ...props }) => (
  <Container width={width}>
    <SwiperContents
      itemList={itemList}
      itemComponent={ChipItem}
      itemProps={itemProps}
      carouselProps={{ ...SwiperContentsDefaultProps.carouselProps, spaceBetween: 6, slidesOffsetAfter: 28 }}
      {...props}
    />
  </Container>
);
export default SwiperChipOrigin;

SwiperChipOrigin.propTypes = {
  swiperRef: PropTypes.object,
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  width: PropTypes.string,
};

SwiperChipOrigin.displayName = 'SwiperChipOrigin';

const Container = styled(FlexBox)`
  overflow: scroll;
`;
