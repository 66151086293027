import { deleteRequest, getRequest, postRequest } from '@mo-apis/axios';

const getMemReqParams = async (oid = '') => {
  const { result } = await getRequest({
    url: '/api/v1/interface/wpay/member-reg-param',
    params: { oid },
  });

  return result;
};

const postMemReqReturnParams = async (payload) =>
  await postRequest({
    url: 'api/v1/interface/wpay/member-req-return',
    data: payload,
  });

const getMyPageParams = async () =>
  await getRequest({
    url: 'api/v1/interface/wpay/mypage-param',
  });

const getPaymentAuthParams = async (payload) =>
  postRequest({
    url: 'api/v1/interface/wpay/payment-auth-param',
    data: payload,
  });

const postPaymentAuthReturn = async (payload) =>
  postRequest({
    url: 'api/v1/interface/wpay/payment-auth-return',
    data: payload,
  });

const deleteMember = async () =>
  await deleteRequest({
    url: `api/v1/interface/wpay/member`,
  });

const delWpayMemberKey = async () =>
  await deleteRequest({
    url: `api/v1/interface/wpay/member-key`,
  });
const getUserInfo = async () =>
  await getRequest({
    url: `api/v1/interface/wpay/member`,
  });

export default {
  getMemReqParams,
  deleteMember,
  delWpayMemberKey,
  postMemReqReturnParams,
  getPaymentAuthParams,
  postPaymentAuthReturn,
  getMyPageParams,
  getUserInfo,
};
