import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import LayerPopup from '@ecp/common/src/components/modal/mo/popup/LayerPopup';

import { ReactComponent as BulRound } from '@mo-assets/image/common/bullet/bul_round01.svg';

const RequestAgreePopup = ({ open, onClose }) => {
  return (
    <LayerPopup open={open} onClose={onClose} onConfirmClick={onClose} header={'신청동의'} useCancelButton={false}>
      <Container>
        <TitleTextBox>본 파크이용권은 삼성웰스토리 임직원분들께 지급되는 복리후생 포인트 입니다.</TitleTextBox>
        <Spacing top={10} />
        <TitleTextBox>
          윤리경영 가이드라인을 반드시 준수하시어 본 이용권을 외부에 판매하여 인사상의 불이익을 받지 않도록 주의해주시기
          바랍니다.
        </TitleTextBox>
        <Spacing top={'14px'} />
        <DetailContainer>
          <Spacing value="14px">
            <DetailTextBox>※ 윤리경영 가이드라인(사리도모)</DetailTextBox>
            <Spacing top={10} />
            <FlexBox justify-content="flex-start" align-items="flex-start">
              <GuideBullet />
              <DetailTextBox>
                복리후생 차원에서 제공되는 등외품, 놀이시설 이용권 등을 제3자에게 이득을 보면서 판매
              </DetailTextBox>
            </FlexBox>
          </Spacing>
        </DetailContainer>
      </Container>
    </LayerPopup>
  );
};

RequestAgreePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const TitleTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 350;
`;

const DetailContainer = styled(FlexBoxColumn)`
  background-color: #f7f7f7;

  align-items: flex-start;
  align-self: stretch;
`;

const DetailTextBox = styled(TextBox)`
  font-size: 12px;
  font-weight: 350;
  line-height: 160%;
  color: #5a5a5a;
`;

const GuideBullet = styled(BulRound)`
  width: 3px;
  height: 3px;
  margin: 7px 6px 0 0;
  flex-shrink: 0;
`;

export default RequestAgreePopup;
