import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';

export const AccordionTextBox = (props) => {
  const { type, bold, size, children } = props;

  return (
    <Container type={type} bold={bold} size={size}>
      {children}
    </Container>
  );
};

const Container = styled(FlexBox).attrs(({ bold, size, type, style }) => ({
  style: {
    ...style,
    fontWeight: bold ? 500 : 350,
    fontSize: size ? size : type === 'guide' ? moTheme.font.size.$12 : moTheme.font.size.$13,
  },
}))`
  width: fit-content;
  gap: 5px;
`;

AccordionTextBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(['guide', 'basic']),
  bold: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AccordionTextBox.defaultProps = {
  type: 'basic',
};
