import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';

import displayRecommendationApi from '@mo-apis/display/displayRecommendationApi';
import RecommendationSmallTypeContent from '@mo-components/display/recommendation/RecommendationSmallTypeContent';
import RecommendationHeader from '@mo-components/display/recommendation/RecommendationHeader';

const CustomerRecommendation = ({ title, displayCategoryNumber, channel, titleStyle }) => {
  const [goodList, setGoodList] = useState([]);

  useEffect(() => {
    (async () => {
      const { result = [] } = await displayRecommendationApi.getRecommendationCustomerGoodList(
        displayCategoryNumber,
        channel,
        10
      );
      setGoodList(result);
    })();
  }, [displayCategoryNumber, channel]);

  return (
    <>
      {!!goodList && !!goodList.length && (
        <FlexBoxColumn align-items={'flex-start'}>
          <RecommendationHeader title={title} titleStyle={titleStyle} />
          <RecommendationSmallTypeContent goodList={goodList} />
        </FlexBoxColumn>
      )}
    </>
  );
};

export default CustomerRecommendation;

CustomerRecommendation.propTypes = {
  title: PropTypes.string,
  displayCategoryNumber: PropTypes.string,
  channel: PropTypes.string,
  titleStyle: PropTypes.object,
};
