import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from '@ecp/common/src/style/theme/default';
import { moTheme } from '@ecp/common/src/style/theme/mo';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
// import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { StyledRoundButton as StyledRoundButtonFo } from '@ecp/common/src/components/button/RoundButton';
import { ReactComponent as IconClose } from '@ecp/common/src/assets/icon/icon__close-x.svg';

export const StyledRoundButton = styled(StyledRoundButtonFo)`
  font-weight: ${moTheme.font.weight.medium};
  font-size: ${moTheme.font.size.$14};
  line-height: ${moTheme.font.lineHeight.demiLight};
  border: none;
  gap: 0;
`;

export const RoundButton = ({ iconType: Icon, inversion, closeable, children, onClose, ...props }) => {
  const color = inversion ? 'white' : theme.color.primary;

  const handleClose = (e) => {
    onClose?.();
    e.stopPropagation();
  };
  return (
    <StyledRoundButton inversion={inversion} {...props}>
      {Icon && (
        <>
          <Icon width={24} height={24} />
          <Spacing left={6} />
        </>
      )}
      {children}
      {closeable && (
        <a
          href={'#'}
          aria-label={'닫기'}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <Spacing left={10} />
          <IconClose width={10} height={10} fill={color} onClick={handleClose} />
        </a>
        // <FlexBox>
        //   <Spacing left={10} />
        //   <IconClose width={10} height={10} fill={color} onClick={handleClose} />
        // </FlexBox>
      )}
    </StyledRoundButton>
  );
};

RoundButton.propTypes = {
  iconType: PropTypes.elementType,
  children: PropTypes.node,
  inversion: PropTypes.bool,
  closeable: PropTypes.bool,
  onClose: PropTypes.func,
};

RoundButton.defaultProps = {
  closeable: false,
  inversion: false,
};

export default RoundButton;
