import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { isValidLength } from '@ecp/common/src/utils/InputValidateUtils';
import useEventCallback from '@ecp/common/src/hooks/useEventCallback';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn, FlexBoxSpaceBetween, ImageBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox, TextBoxAlignLeft } from '@ecp/common/src/text/TextBox';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';
import TabGroup from '@ecp/common/src/components/tab/mo/TabGroup';
import { TabPanel } from '@ecp/common/src/components/tab/mo/TabPanel';
import LayerPopup from '@ecp/common/src/components/modal/mo/popup/LayerPopup';
import Input from '@ecp/common/src/components/input/mo/Input';
import { ReactComponent as DownloadIcon } from '@ecp/common/src/assets/icon/icon__app-download.svg';

import { checkOs, OS_TYPE } from '@mo-utils/commonUtils';
import { withMobileStatus } from '@mo-recoil/system/selector';
import { useAlertDialog } from '@mo-hooks/common/useModal';
import mobileStoreApi from '@mo-apis/system/mobileStoreApi';
import commonApi from '@mo-apis/common/commonApi';
import { ANDROID, iOS } from '@mo-const/system/mobileConst';
import GooglePlayBtn from '@mo-assets/image/image__google_play.png';
import AppleStoreBtn from '@mo-assets/image/image__apple_store.png';

const MOBILE_NUMBER_ALERT_MSG = '연락처를 정확히 입력해주세요.';
const SEND_SUCCESS_ALERT_MSG = '전송되었습니다.';
const SEND_FAIL_ALERT_MSG = '전송 실패하였습니다.\n다시 시도해주세요.';

const AppDownloadPopup = () => {
  const { isMobileApp, agentType } = useRecoilValue(withMobileStatus);
  const { showAlertDialog } = useAlertDialog();
  const [appDownloadPopupOpen, setAppDownloadPopupOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [formValue, setFormValue] = useState({
    mobile: '',
  });
  const [storeUrlList, setStoreUrlList] = useState({});

  const getStoreUrls = async () => {
    const { result: response } = await mobileStoreApi.getAllStoreUrl();
    const result = response?.reduce((acc, cur) => {
      acc[cur?.commonDetailCode] = cur?.storeUrl;
      return acc;
    }, {});

    setStoreUrlList(result);
  };

  useEffect(() => {
    setTabIndex(0);
    setFormValue({ mobile: '' });
    getStoreUrls();
  }, [appDownloadPopupOpen]);

  const handleChangeMobile = useEventCallback(
    (v, data) => {
      if (isValidLength(v, data, validateLength)) {
        setFormValue((prev) => ({ ...prev, [data.key]: v }));
      }
    },
    { stop: true, prevent: true }
  );
  const handleOnClickSendMsg = async () => {
    if (formValue.mobile.length < validateLength.mobile) {
      showAlertDialog(MOBILE_NUMBER_ALERT_MSG);
      return;
    }
    const { result } = await commonApi.sendAppDownloadSms({ cellphoneNumber: formValue.mobile, osType: checkOs() });
    if (result?.result === 'SUCCESS') {
      showAlertDialog(SEND_SUCCESS_ALERT_MSG);
    } else {
      showAlertDialog(SEND_FAIL_ALERT_MSG);
    }
    setFormValue({ mobile: '' });
  };

  const handleOnClickConfirm = () => {
    setAppDownloadPopupOpen(false);
  };

  const handleOnClickClose = useCallback(() => {
    setAppDownloadPopupOpen(false);
  }, [setAppDownloadPopupOpen]);

  const handleOnClickStoreBtn = (inputOs) => {
    if (inputOs === OS_TYPE.IOS || inputOs === iOS) {
      isMobileApp ? (window.location.href = 'welstorymall://store') : window.open('https://' + storeUrlList?.ios);
    } else if (inputOs === OS_TYPE.ANDROID || inputOs === ANDROID) {
      isMobileApp ? window.open('welstorymall://store') : window.open('https://' + storeUrlList?.android);
    }
  };

  const validateLength = {
    mobile: 11,
  };

  const tabList = [
    {
      tabName: (
        <FlexBoxColumn>
          <TextBox weight={moTheme.font.weight.bold}>다운로드 링크</TextBox>
          <TextBox weight={moTheme.font.weight.bold}>SMS 문자 전송</TextBox>
        </FlexBoxColumn>
      ),
      title: '다운로드 링크 SMS 문자 전송',
    },
    { tabName: 'APP 다운로드 안내', title: 'APP 다운로드 안내' },
  ];
  return (
    <>
      <LayerPopup
        header="APP 다운로드"
        open={appDownloadPopupOpen}
        onClose={handleOnClickClose}
        cancelButtonProps={{ type: 'Secondary', width: '136px' }}
        onCancelClick={handleOnClickClose}
        useCancelButton={tabIndex === 0}
        confirmButtonProps={{ width: '136px' }}
        confirmButtonText={tabIndex === 0 ? 'SMS 문자 전송' : '확인'}
        onConfirmClick={tabIndex === 0 ? handleOnClickSendMsg : handleOnClickConfirm}
      >
        <TabGroup
          width="280px"
          height="46px"
          tabItems={tabList}
          deFaultValue={tabIndex}
          onChange={() => setTabIndex((prev) => Math.abs(prev - 1))}
        >
          <TabPanel width="280px">
            <Spacing top="20px" />
            <TextBoxAlignLeft weight="350" height="66px" size={moTheme.font.size.$14}>
              <p style={{ lineHeight: '160%' }}>
                아래에 앱다운로드 링크 SMS 문자 수신을
                <br />
                원하는 휴대전화 번호를 입력 후 <span style={{ fontWeight: 500, lineHeight: '11px' }}>[전송]</span>{' '}
                버튼을
                <br />
                터치해주세요.
              </p>
            </TextBoxAlignLeft>
            <Spacing top="11px" />
            <Input
              type="text"
              onlyNumber
              width="280px"
              placeholder="-없이 숫자만 입력해주세요"
              title="대시 없이 휴대전화 번호 입력해주세요"
              value={formValue.mobile}
              onChange={handleChangeMobile}
              data-key={'mobile'}
            />
          </TabPanel>
          <TabPanel width="280px">
            <FlexBoxColumn>
              <Spacing top="20px" />
              <TextBoxAlignLeft weight="350" height="66px" size={moTheme.font.size.$14}>
                <p style={{ lineHeight: '160%' }}>
                  앱을 다운로드를 원하시는 기기의 종류를 확인 후
                  <br />
                  PLAY 스토어 또는 APP 스토어에 접속 후,
                  <br />
                  웰스토리몰 검색 및 설치해주세요.
                </p>
              </TextBoxAlignLeft>
              <Spacing top="20px" />
              {isMobileApp ? (
                <ImageBoxContainer selected={true} disabled={false} onClick={() => handleOnClickStoreBtn(agentType)}>
                  {agentType === ANDROID ? (
                    <ImageBox imageSrc={GooglePlayBtn} width="100px" height="20px" />
                  ) : (
                    <ImageBox imageSrc={AppleStoreBtn} width="91px" height="20px" />
                  )}
                </ImageBoxContainer>
              ) : (
                <FlexBoxSpaceBetween>
                  <ImageBoxContainer
                    onClick={() => handleOnClickStoreBtn(OS_TYPE.ANDROID)}
                    aria-label="구글 플레이 바로가기, 새 창 열기"
                  >
                    <ImageBox imageSrc={GooglePlayBtn} width="100px" height="20px" />
                  </ImageBoxContainer>
                  <Spacing left={10} />
                  <ImageBoxContainer
                    onClick={() => handleOnClickStoreBtn(OS_TYPE.IOS)}
                    aria-label="애플 스토어 바로가기, 새 창 열기"
                  >
                    <ImageBox imageSrc={AppleStoreBtn} width="91px" height="20px" />
                  </ImageBoxContainer>
                </FlexBoxSpaceBetween>
              )}
            </FlexBoxColumn>
          </TabPanel>
        </TabGroup>
      </LayerPopup>
      <TextButton
        isReverse
        type={'custom'}
        textProps={{ weight: '350' }}
        customIcon={DownloadIcon}
        onClick={() => setAppDownloadPopupOpen(true)}
      >
        APP 다운로드
      </TextButton>
    </>
  );
};

export default AppDownloadPopup;

const ImageBoxContainer = styled.button`
  display: flex;
  width: 135px;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: ${({ selected }) => (selected ? '2px' : '1px')} solid
    ${({ selected }) => (selected ? `${theme.color.primary}` : '#EAEAEA')};
`;
