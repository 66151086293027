import React, { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween, LabelFlexBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Input from '@ecp/common/src/components/input/mo/Input';
import Textarea from '@ecp/common/src/components/input/mo/Textarea';
import { PageButton } from '@ecp/common/src/components/button/mo/Button';
import RadioGroup from '@ecp/common/src/components/radio/mo/RadioGroup';
import { Radio } from '@ecp/common/src/components/radio/mo/Radio';
import Calendar from '@ecp/common/src/components/calendar/mo/Calendar';
import DropdownDevice from '@ecp/common/src/components/dropdown/mo/DropdownDevice';
import Tooltip from '@ecp/common/src/components/tooltip/mo/Tooltip';
import { EMAIL_DOMAIN } from '@ecp/common/src/const/email';

import { useAlertDialog } from '@mo-hooks/common/useModal';
import { QuestionIcon } from '@mo-components/styled';
import IdInput from '@mo-components/member/input/IdInput';
import PasswordInput from '@mo-components/member/input/PasswordInput';
import PhoneNumberInput from '@mo-components/member/input/PhoneNumberInput';

const FormField = forwardRef((props, ref) => {
  const { field, size } = props;
  const {
    key,
    value,
    type,
    label,
    helpText,
    infoText,
    onChange = () => {},
    required = true,
    fieldProps = {},
    item = [],
    columnProps = {},
    customRender: CustomRender,
    simpleFormDisp,
  } = field;

  const height = size === 'large' ? '46px' : '36px';
  const commonInputProps = {
    type: 'text',
    width: '100%',
    height: height,
    placeholder: '',
    onChange: onChange,
  };

  const allProps = { field, commonInputProps };

  const blind = {
    overFlow: 'hidden',
    position: 'absolute',
    clip: 'rect(0,0,0,0)',
    clipPath: 'polygon(0 0, 0 0, 0 0)',
    width: '1px',
    height: '1px',
    margin: '-1px',
  };

  return (
    <FlexBoxColumn width={'100%'} align-items={'flex-start'} key={key} style={columnProps}>
      <Spacing top={20} />
      <FlexBoxSpaceBetween width={'100%'}>
        <FlexBox>
          <TextBox weight={moTheme.font.weight.medium} required={required}>
            {required && <span style={blind}>필수 입력 항목</span>}
            <LabelFlexBox htmlFor={key}>{label}</LabelFlexBox>
          </TextBox>
          <Spacing left={6} />
          {helpText && (
            <Tooltip
              closeable
              openWhenClicked
              placement={'bottom'}
              value={
                <TextBox size={moTheme.font.size.$12} color={'#5A5A5A'}>
                  {helpText}
                </TextBox>
              }
            >
              <QuestionIcon />
            </Tooltip>
          )}
        </FlexBox>
        {type === 'textarea' && !!fieldProps.maxLength && (
          <TextBox font-size={moTheme.font.size.$12} weight={moTheme.font.weight.demiLight} color={'#727272'} ref={ref}>
            ({NumberFormat(value.length)}/{NumberFormat(fieldProps.maxLength)})
          </TextBox>
        )}
        {infoText && (
          <TextBox size={moTheme.font.size.$12} color={simpleFormDisp ? theme.color.fourth : theme.color.secondary}>
            {infoText}
          </TextBox>
        )}
      </FlexBoxSpaceBetween>
      <Spacing top={10} />
      {type === 'input' && (
        <Input {...commonInputProps} {...fieldProps} value={value} data-key={key} id={key} ref={ref} />
      )}
      {type === 'dropdown' && (
        <DropdownDevice
          {...commonInputProps}
          {...fieldProps}
          items={item}
          value={value}
          onChange={(e) => onChange(key, e)}
          ref={ref}
        />
      )}
      {type === 'textarea' && (
        <Textarea {...commonInputProps} {...fieldProps} value={value} data-key={key} id={key} ref={ref} />
      )}
      {type === 'radio' && (
        <>
          <Spacing top={'4px'} />
          <RadioGroup {...fieldProps} defaultValue={value} ref={ref}>
            {item.map((item, index) => (
              <React.Fragment key={item.value}>
                {index !== 0 && <Spacing left={20} />}
                <Radio value={item.value} disabled={item.disabled} onChange={onChange} data-key={key}>
                  {item.label}
                </Radio>
              </React.Fragment>
            ))}
          </RadioGroup>
        </>
      )}
      {type === 'calendar' && (
        <Calendar
          width={'100%'}
          inputStyle={{ ...commonInputProps }}
          {...fieldProps}
          value={value}
          inline={false}
          showPopperArrow={false}
          onChange={(e) => onChange(key, e)}
          ref={ref}
        />
      )}
      {type === 'custom' && CustomRender && <CustomRender {...allProps} ref={ref} />}
    </FlexBoxColumn>
  );
});

export default FormField;

FormField.displayName = 'FormField';

FormField.propTypes = {
  field: PropTypes.object,
  size: PropTypes.oneOf(['default', 'large']),
};

FormField.defaultProps = {
  size: 'default',
};

export const RenderEmail = forwardRef((props, ref) => {
  const { field, commonInputProps } = props;
  const {
    key: emailValueKey,
    value: emailValue,
    emailDomainKey,
    emailDomain,
    emailDirectDomainKey,
    emailDirectDomain,
    onChangeDropdownValues,
    fieldProps,
    emailDomainFieldProps,
    emailDirectDomainFieldProps,
  } = field;

  return (
    <>
      <FlexBox width={'100%'}>
        <Input
          {...commonInputProps}
          {...fieldProps}
          width={'50%'}
          minWidth={'50%'}
          value={emailValue}
          maxLength={30}
          data-key={emailValueKey}
          id={emailValueKey}
          ref={ref}
        />
        <Spacing left={6} />
        <TextBox size={moTheme.font.size.$15} weight={moTheme.font.weight.medium}>
          @
        </TextBox>
        <Spacing left={6} />
        <DropdownDevice
          {...commonInputProps}
          {...emailDomainFieldProps}
          width="50%"
          items={EMAIL_DOMAIN}
          value={emailDomain}
          placeholder={'선택하세요'}
          onChange={(e) => onChangeDropdownValues(emailDomainKey, e)}
        />
      </FlexBox>
      <Spacing top={10} />
      <Input
        {...commonInputProps}
        {...emailDirectDomainFieldProps}
        disabled={emailDomain.key !== 'custom'}
        value={emailDirectDomain}
        maxLength={30}
        data-key={emailDirectDomainKey}
      />
    </>
  );
});

RenderEmail.propTypes = {
  field: PropTypes.object,
  onChangeDropdownValues: PropTypes.func,
  commonInputProps: PropTypes.object,
};

RenderEmail.displayName = 'RenderEmail';

export const RenderAttachment = (props) => {
  const { field, commonInputProps } = props;
  const { key, value, fieldProps } = field;
  const { onChange, ...restCommonInputProps } = commonInputProps;

  const extensions = ['jpg', 'jpeg', 'png', 'xlsx', 'xls', 'hwp', 'docx', 'doc', 'pptx', 'ppt', 'pdf'];
  const maxFileSize = 50 * 1024 * 1024;
  const fileInput = useRef(null);

  const { showAlertDialog } = useAlertDialog();

  const handleButtonClick = () => {
    fileInput.current.click();
  };

  const handleChange = (e) => {
    const { name, size } = e.target.files[0];
    const [, ext] = name.split('.');
    if (!extensions.includes(ext)) {
      showAlertDialog('첨부가 불가한 파일 유형입니다.');
      return;
    }
    if (size > maxFileSize) {
      showAlertDialog('첨부 가능한 파일용량은 50MB이하입니다.');
      return;
    }
    onChange && onChange(e);
  };

  const handleDeleteClick = (e) => {
    if (e.target.value) return;
    fileInput.current.value = '';
    onChange && onChange(e);
  };

  return (
    <>
      <FlexBoxSpaceBetween width={'100%'}>
        <Input {...restCommonInputProps} {...fieldProps} value={value} data-key={key} onChange={handleDeleteClick} />
        <Spacing left={6} />
        <PageButton width={'80px'} type={'Default'} onClick={handleButtonClick}>
          파일첨부
        </PageButton>
        <input
          type="file"
          accept={`.${extensions.join(',.')}`}
          data-key="attachment"
          ref={fileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
      </FlexBoxSpaceBetween>
      <Spacing top={10} />
      <TextBox color={'#727272'} size={moTheme.font.size.$12} height={moTheme.font.lineHeight.demiLight}>
        파일첨부가능 jpg, jpeg, png, xlsx, xls, hwp, docx, doc, pptx, ppt, pdf
        <br />( 50MB이하 )
      </TextBox>
    </>
  );
};

RenderAttachment.propTypes = {
  field: PropTypes.object,
  commonInputProps: PropTypes.object,
};

export const RenderUserId = (props) => {
  const { field, commonInputProps } = props;
  const { key, value, fieldProps, onValidateId } = field;

  return (
    <FlexBoxSpaceBetween width={'100%'}>
      <IdInput {...commonInputProps} {...fieldProps} value={value} dataKey={key} />
      <Spacing left={6} />
      {!fieldProps?.disabled && (
        <PageButton width={'80px'} type={'Default'} onClick={onValidateId}>
          중복확인
        </PageButton>
      )}
    </FlexBoxSpaceBetween>
  );
};

RenderUserId.propTypes = {
  field: PropTypes.object,
  commonInputProps: PropTypes.object,
};

export const RenderPassword = (props) => {
  const { field, commonInputProps } = props;
  const { key, value, fieldProps } = field;

  return <PasswordInput value={value} dataKey={key} {...commonInputProps} {...fieldProps} />;
};

RenderPassword.propTypes = {
  field: PropTypes.object,
  commonInputProps: PropTypes.object,
};

export const RenderPhoneNumber = (props) => {
  const { field, commonInputProps } = props;
  const { key, value, fieldProps } = field;
  return <PhoneNumberInput value={value} dataKey={key} {...commonInputProps} {...fieldProps} />;
};

RenderPhoneNumber.propTypes = {
  field: PropTypes.object,
  commonInputProps: PropTypes.object,
};
