import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Paper from '@ecp/common/src/components/paper/Paper';
import Accordion from '@ecp/common/src/components/accordion/mo/Accordion';

import PresentReceiverContent from '@mo-components/order/payment/present/PresentReceiverContent';
import { ReactComponent as BulRound } from '@mo-assets/image/common/bullet/bul_round01.svg';
import { ReactComponent as BulNotice } from '@mo-assets/image/common/bullet/bul_notice.svg';

const ParkPointReceiverInformation = ({ ordererInformation, orderDeliveryList, handleChangeOrderDeliveryList }) => {
  const handleChangeDeliveryInfo = useCallback(
    (deliveryInfo, idx) => {
      const newOrderDeliveryList = orderDeliveryList.map((deliveryItem, deliveryIdx) => {
        if (idx !== deliveryIdx) {
          return deliveryItem;
        }
        return {
          ...deliveryItem,
          deliveryPlace: { ...deliveryItem.deliveryPlace, ...deliveryInfo },
        };
      });
      handleChangeOrderDeliveryList(newOrderDeliveryList, false);
    },
    [handleChangeOrderDeliveryList, orderDeliveryList]
  );

  return (
    <Container>
      <Accordion initialOpen>
        <Accordion.Header>
          <HeaderTextBox>받는 분</HeaderTextBox>
          <FlexBox>
            <Accordion.Control buttonPadding="5px 0 0 0" />
          </FlexBox>
        </Accordion.Header>
        <Spacing top="10px" />
        <HorizontalDivider color="#141415" />

        <Accordion.Body undead>
          <BodyContainer>
            <Spacing top="20px" />
            <PresentReceiverContent
              ordererInformation={ordererInformation}
              handleChangeDeliveryInfo={handleChangeDeliveryInfo}
              index={0}
            />
            <Paper>
              <Paper background-color={theme.color.background.basic} padding="14px">
                <GuideText>
                  <span>
                    위 휴대폰번호로 <b>[예약번호 & 티켓수령일자]</b>가 문자로 발송됩니다.
                  </span>
                </GuideText>
                <Spacing top="14px" />
                <TextBox size={12} weight="500" color="#141415">
                  <BulNotice />
                  <Spacing left="6px" />
                  실물 티켓 수령 안내
                </TextBox>
                <Spacing top="6px" />
                <GuideText>① 에버랜드 정문 우측 인포메이션 방문</GuideText>
                <GuideText>② 창구 근무자에게 받은 문자와 신분증 제시</GuideText>
                <GuideText>③ 티켓 수령</GuideText>
                <Spacing top="14px" />
                <BulList>
                  <BulRound width="3px" height="3px" />
                  캐리비안베이 티켓은 성수기 7~8월 한정 캐리비안베이 서비스라운지에서, 그 외 기간은 에버랜드 정문 우측
                  인포메이션에서 티켓 수령 후 이용가능합니다.
                </BulList>
                <BulList>
                  <BulRound width="3px" height="3px" />
                  티켓수령시간: 파크 영업 오픈 30분 전 ~ 17시 00분
                </BulList>
              </Paper>
            </Paper>
          </BodyContainer>
        </Accordion.Body>
      </Accordion>
    </Container>
  );
};

export default ParkPointReceiverInformation;

ParkPointReceiverInformation.propTypes = {
  ordererInformation: PropTypes.object,
  orderDeliveryList: PropTypes.array,
  handleChangeOrderDeliveryList: PropTypes.func,
};

const Container = styled(FlexBox)`
  width: 100%;
`;

const BodyContainer = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const HeaderTextBox = styled(TextBox)`
  font-size: 16px;
  font-weight: 700;
`;

const GuideText = styled(TextBox).attrs(() => ({
  size: '12px',
  color: theme.color.text.sub,
  height: moTheme.font.lineHeight.demiLight,
}))`
  width: 100%;
  align-items: flex-start;
`;

const BulList = styled(GuideText)`
  position: relative;
  padding-left: 9px;

  & > svg {
    position: absolute;
    left: 0;
    top: 10px;
  }
`;
