import { getRequest } from '@mo-apis/axios';

const getMainFunctionList = async (mainFunctionDivisionCode) => {
  return await getRequest({
    url: '/api/v1/display/main-function',
    params: { mainFunctionDivisionCode },
  });
};

const getAllianceBarList = async () => {
  return await getRequest({
    url: '/api/v1/display/main-function/alliance-bar',
  });
};

const getGnbList = async () => {
  return await getRequest({
    url: '/api/v1/display/main-function/gnb',
  });
};

const getSearchPhraseList = async () => {
  return await getRequest({
    url: '/api/v1/display/main-function/search-phrase',
  });
};

const getBestTabList = async () => {
  return await getRequest({
    url: '/api/v1/display/main-function/best',
  });
};
const getShortcutList = async () => {
  return await getRequest({
    url: '/api/v1/display/main-function/shortcut',
  });
};

const getMainMarketingPopup = () =>
  getRequest({
    url: '/api/v1/display/main-function/marketing-popup',
  });

const getEventMallSpeList = async () => {
  return await getRequest({
    url: '/api/v1/display/main-function/event-mall-spe',
  });
};

const mainFunctionApi = {
  getMainFunctionList,
  getAllianceBarList,
  getGnbList,
  getSearchPhraseList,
  getBestTabList,
  getMainMarketingPopup,
  getShortcutList,
  getEventMallSpeList,
};

export default mainFunctionApi;
