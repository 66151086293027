import { useCallback, useEffect, useMemo, useState } from 'react';

import { format } from 'date-fns';

import useCouponSelection from '@ecp/common/src/hooks/order/payment/useCouponSelection';
import { ORDER_TYPE, PAYMENT_MEAN_CODE } from '@ecp/common/src/const/order/orderConst';

const useCommonOrderPage = ({
  orderType,
  orderPageInfo,
  setDeliveryListWithDeliveryCost,
  getBaseDeliveryLocation,
  systemInformation,
  setError,
}) => {
  const { goodList } = orderPageInfo;

  const enableCouponUsage = useMemo(() => systemInformation?.couponUseYn === 'Y', [systemInformation?.couponUseYn]);
  const enablePointUsage = useMemo(() => systemInformation?.pointUseYn === 'Y', [systemInformation?.pointUseYn]);
  const enableSavingsUsage = useMemo(() => systemInformation?.savingsUseYn === 'Y', [systemInformation?.savingsUseYn]);

  const [pointUsage, setPointUsage] = useState({});
  const [discountAmount, setDiscountAmount] = useState({});
  const [ordererInformation, setOrdererInformation] = useState({});
  const [paymentMethod, setPaymentMethod] = useState({ payMeanSaveYn: true });
  const [goodCouponList, setGoodCouponList] = useState([]);
  const [cartCouponList, setCartCouponList] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [paymentAmount, setPaymentAmount] = useState({});
  const [orderDeliveryList, setOrderDeliveryList] = useState([]);

  const [goodPriceList, setGoodPriceList] = useState([]); /* 상품 가격 정보 */

  const [deliveryCostCoupon, setDeliveryCostCoupon] = useState(undefined);
  const [isFinishInit, setIsFinishInit] = useState(false);
  const [simpleCouponApply, setSimpleCouponApply] = useState(false);
  const [agreeTermsChecked, setAgreeTermsChecked] = useState(false);
  const [isProcessPayment, setProcessPayment] = useState(false);

  const [myWelPointList, setMyWelPointList] = useState([]);
  const [isWelPointCashReceiptIssuePoss, setIsWelPointCashReceiptIssuePoss] = useState(false);

  const initializePointUsage = useCallback(() => {
    setPointUsage((prev) => ({
      ...prev,
      savePoint: { ...prev.savePoint, use: 0 },
      welPoint: { ...prev.welPoint, use: 0 },
      welfarePoint: { ...prev.welfarePoint, use: 0 },
    }));
    setIsWelPointCashReceiptIssuePoss(false);
  }, []);

  const getOrderDeliveryListWithDiscountAmount = useCallback((orderDeliveryList, goodPrices) => {
    // Map goodNo - itemNo, { remainAmount, quantity }
    const goodDiscountMap = new Map();
    goodPrices.forEach((goodPrice) => {
      const key = `${goodPrice.goodNumber}-${goodPrice.itemNumber}`;
      goodDiscountMap.set(key, {
        ...goodPrice,
        remainQuantity: goodPrice.orderQuantity,
        remainGoodDiscountAmount: goodPrice.goodDiscountAmount || 0,
        remainCartDiscountAmount: goodPrice.cartDiscountAmount || 0,
      });
    });

    return orderDeliveryList.map((deliveryItem) => {
      const goodList = deliveryItem.goodList.map((goodItem) => {
        const itemList = goodItem.itemList.map((item) => {
          const key = `${goodItem.goodNumber}-${item.itemNumber}`;
          const discountData = goodDiscountMap.get(key);

          if (!discountData) {
            return {
              ...item,
              goodCouponDiscountAmount: 0,
              cartCouponDiscountAmount: 0,
            };
          }

          let goodCouponDiscountAmount = 0;
          let cartCouponDiscountAmount = 0;

          if (discountData.remainGoodDiscountAmount > 0) {
            if (discountData.goodApplyQuantityCode === '1') {
              goodCouponDiscountAmount = discountData.remainGoodDiscountAmount;
            } else {
              if (discountData.remainQuantity === item.deliveryQuantity) {
                goodCouponDiscountAmount = discountData.remainGoodDiscountAmount;
              } else {
                goodCouponDiscountAmount = Math.floor(
                  discountData.goodDiscountAmount * (item.deliveryQuantity / discountData.orderQuantity)
                );
              }
            }
          }
          if (discountData.remainCartDiscountAmount > 0) {
            if (discountData.remainQuantity === item.deliveryQuantity) {
              cartCouponDiscountAmount = discountData.remainCartDiscountAmount;
            } else {
              cartCouponDiscountAmount = Math.floor(
                discountData.cartDiscountAmount * (item.deliveryQuantity / discountData.orderQuantity)
              );
            }
          }
          goodDiscountMap.set(key, {
            ...discountData,
            remainQuantity: discountData.remainQuantity - item.deliveryQuantity,
            remainGoodDiscountAmount: discountData.remainGoodDiscountAmount - goodCouponDiscountAmount,
            remainCartDiscountAmount: discountData.remainCartDiscountAmount - cartCouponDiscountAmount,
          });
          return {
            ...item,
            goodCouponDiscountAmount,
            cartCouponDiscountAmount,
          };
        });
        return {
          ...goodItem,
          itemList,
        };
      });
      return {
        ...deliveryItem,
        goodList,
      };
    });
  }, []);

  const getOrderDeliveryList = useCallback(
    async (
      orderDeliveryList,
      { refreshDeliveryCost = false, refreshAvailableQuantity = false, targetIdx } = {},
      goodPrices = []
    ) => {
      if (refreshAvailableQuantity) {
        const newOrderDeliveryList = orderDeliveryList.map((deliveryItem, deliveryIdx) => {
          const newGoodList = deliveryItem.goodList.map((goodItem) => {
            const newItemList = goodItem.itemList.map((item) => {
              const notAvailableQuantity = orderDeliveryList.reduce(
                (deliveryAcc, otherDeliveryItem, otherDeliveryIdx) => {
                  if (otherDeliveryIdx === deliveryIdx) return deliveryAcc;
                  return (
                    deliveryAcc +
                    otherDeliveryItem.goodList.reduce((goodAcc, otherGoodItem) => {
                      if (otherGoodItem.goodNumber !== goodItem.goodNumber) return goodAcc;
                      return (
                        goodAcc +
                          otherGoodItem.itemList.find((otherItem) => otherItem.itemNumber === item.itemNumber)
                            .deliveryQuantity || 0
                      );
                    }, 0)
                  );
                },
                0
              );
              return { ...item, availableQuantity: item.orderQuantity - notAvailableQuantity };
            });
            return { ...goodItem, itemList: newItemList };
          });
          return { ...deliveryItem, goodList: newGoodList };
        });

        if (refreshDeliveryCost) {
          //사용가능한 포인트값이 바뀌니 초기화해준다
          initializePointUsage();

          return await setDeliveryListWithDeliveryCost(
            getOrderDeliveryListWithDiscountAmount(newOrderDeliveryList, goodPrices),
            targetIdx
          );
        }

        return getOrderDeliveryListWithDiscountAmount(newOrderDeliveryList, goodPrices);
      }

      if (refreshDeliveryCost) {
        //사용가능한 포인트값이 바뀌니 초기화해준다
        initializePointUsage();

        return await setDeliveryListWithDeliveryCost(
          getOrderDeliveryListWithDiscountAmount(orderDeliveryList, goodPrices),
          targetIdx
        );
      }

      return getOrderDeliveryListWithDiscountAmount(orderDeliveryList, goodPrices);
    },
    [setDeliveryListWithDeliveryCost, getOrderDeliveryListWithDiscountAmount, initializePointUsage]
  );

  const resetOrderDeliveryList = useCallback(
    async (orderDeliveryList, { refreshDeliveryCost = false, refreshAvailableQuantity = false, targetIdx } = {}) => {
      const resultDeliveryList = await getOrderDeliveryList(
        orderDeliveryList,
        {
          refreshDeliveryCost,
          refreshAvailableQuantity,
          targetIdx,
        },
        goodPriceList
      );
      setOrderDeliveryList(resultDeliveryList);
      setError && setError((prev) => ({ ...prev, message: undefined, deliveryDisableList: [] }));
    },
    [getOrderDeliveryList, goodPriceList, setError]
  );

  const handleReloadDeliveryList = useCallback(
    async (goodPrices) => {
      const returnOrderDeliveryList = await getOrderDeliveryList(
        orderDeliveryList,
        { refreshDeliveryCost: [ORDER_TYPE.STANDARD, ORDER_TYPE.PRESENT].includes(orderType) },
        goodPrices
      );
      setOrderDeliveryList(returnOrderDeliveryList);
    },
    [getOrderDeliveryList, orderDeliveryList, orderType]
  );

  const { handleSelectCoupon, handleConfirmCoupons, handleSimpleApply, calcGoodPrice } = useCouponSelection({
    couponList,
    goodCouponList,
    cartCouponList,
    goodPriceList,
    simpleCouponApply,
    paymentMethod,
    setGoodPriceList,
    setDiscountAmount,
    setDeliveryCostCoupon,
    setCouponList,
    setGoodCouponList,
    setCartCouponList,
    setSimpleCouponApply,
    setPointUsage,
    handleReloadDeliveryList,
    enableCouponUsage,
  });

  /* 상품 금액 정보 */
  const totalProductAmount = useMemo(() => {
    return goodPriceList.reduce((acc, item) => {
      return acc + item.salePrice * item.orderQuantity;
    }, 0);
  }, [goodPriceList]);

  /* 결제 금액 정보 */
  const totalPayAmount = useMemo(() => {
    return goodPriceList.reduce((acc, item) => {
      return acc + item.salePrice * item.orderQuantity - item.goodDiscountAmount;
    }, 0);
  }, [goodPriceList]);

  /* 주문 아이템 수량 합계 */
  const itemTotalCount = useMemo(() => {
    return goodList.reduce((acc, { itemList }) => {
      itemList.forEach(({ orderQuantity, additionalItem }) => {
        if (!additionalItem) acc += orderQuantity;
      });
      return acc;
    }, 0);
  }, [goodList]);

  /* 주문상품 총 건수 */
  const orderItemCount = useMemo(() => {
    return goodList.map(({ itemList }) => itemList).flat().length;
  }, [goodList]);

  const getInitDeliveryPlace = useCallback(async () => {
    if ([ORDER_TYPE.STANDARD, ORDER_TYPE.REGULAR_DELIVERY].includes(orderType)) {
      const { result: baseDeliveryPlace } = await getBaseDeliveryLocation();

      return await getOrderDeliveryList(
        [
          {
            deliveryPlace: {
              ...baseDeliveryPlace,
              cellphoneNumber: (baseDeliveryPlace?.cellphoneNumber1 || '')
                .concat(baseDeliveryPlace?.cellphoneNumber2 || '')
                .concat(baseDeliveryPlace?.cellphoneNumber3 || ''),
              orderMemo: baseDeliveryPlace?.deliveryMessage,
              saveDefaultDeliveryMemo: true,
              noteOrdererName: true,
            },
            goodList,
          },
        ],
        { refreshDeliveryCost: orderType === ORDER_TYPE.REGULAR_DELIVERY || !enableCouponUsage } // 쿠폰 적용을 안하는 경우 (정기배송, 쿠폰사용불가 사이트 등) 배송비를 여기서 계산.
      );
    } else {
      return await getOrderDeliveryList([
        {
          deliveryPlace: {},
          goodList,
        },
      ]);
    }
  }, [enableCouponUsage, getBaseDeliveryLocation, getOrderDeliveryList, goodList, orderType]);

  useEffect(() => {
    setPointUsage({ ...orderPageInfo.pointUsage });
    setMyWelPointList({ ...orderPageInfo.myWelPoint });
    setOrdererInformation({ ...orderPageInfo.ordererInformation });
    setCouponList([...orderPageInfo.myCouponInfo.couponList]);
    setCartCouponList([...orderPageInfo.myCouponInfo.cartCouponList]);
    setGoodCouponList([
      ...orderPageInfo.myCouponInfo.goodCouponList.map((good) => {
        const couponList = good.couponList.map((coupon) => ({
          ...coupon,
          goodNumber: good.goodNumber,
          itemNumber: good.itemNumber,
        }));
        return { ...good, couponList };
      }),
    ]);

    const deliveryCostCoupon = orderPageInfo.myCouponInfo.couponList.find(
      (coupon) => '023' === coupon.promotionTypeCode
    );

    setDeliveryCostCoupon({
      applyDeliveryCostCoupon: true,
      coupon: deliveryCostCoupon,
      isUseDeliveryCostCoupon: !!deliveryCostCoupon,
    });

    const { payMeanCode, payInfoCode, bankCode } = orderPageInfo.paymentMethod;

    if (orderType === ORDER_TYPE.REGULAR_DELIVERY) {
      setPaymentMethod({ payMeanCode: PAYMENT_MEAN_CODE.CARD });
    } else {
      if (payMeanCode === PAYMENT_MEAN_CODE.DEPOSIT) {
        setPaymentMethod((prev) => ({
          ...prev,
          payMeanCode,
          inicisBankCode: payInfoCode,
          bankCode,
          depositName: orderPageInfo.ordererInformation.ordererName,
        }));
      } else if (payMeanCode === PAYMENT_MEAN_CODE.CARD) {
        setPaymentMethod((prev) => ({ ...prev, payMeanCode, cardCompanyCode: payInfoCode }));
      } else {
        setPaymentMethod((prev) => ({ ...prev, payMeanCode }));
      }
    }

    setGoodPriceList([
      ...orderPageInfo.goodList.reduce((acc, good) => {
        const newItemList = good.itemList.reduce((acc, item) => {
          return [
            ...acc,
            {
              goodNumber: good.goodNumber,
              itemNumber: item.itemNumber,
              orderQuantity: item.orderQuantity,
              salePrice: item.salePrice,
              discountAmount: 0,
              cartDiscountAmount: 0,
              resourceManagementNo: null,
            },
          ];
        }, []);
        return [...acc, ...newItemList];
      }, []),
    ]);

    (async () => {
      setOrderDeliveryList(await getInitDeliveryPlace());
      setIsFinishInit(true);
    })();
  }, [orderPageInfo, getInitDeliveryPlace, getOrderDeliveryList, goodList, orderType]);

  useEffect(() => {
    if (isFinishInit) {
      setIsFinishInit(false);
      if (enableCouponUsage && orderType !== ORDER_TYPE.REGULAR_DELIVERY) handleSimpleApply(!!couponList?.length); //정기배송은 쿠폰을 못쓰니까 제외해준다. 배송비 계산은 다른데서 위에 다른 코드에서 함
    }
  }, [enableCouponUsage, couponList?.length, handleSimpleApply, isFinishInit, orderType]);

  const hasDeliveryHopeDate = useMemo(() => goodList?.some(({ deliveryHopeDay }) => !!deliveryHopeDay), [goodList]);

  useEffect(() => {
    const pointAmount = {
      total:
        (pointUsage?.savePoint?.use || 0) + (pointUsage?.welPoint?.use || 0) + (pointUsage?.welfarePoint?.use || 0),
      ...pointUsage,
    };

    const deliveryAmount = {
      total: orderDeliveryList.reduce((acc, { totalDeliveryCost }) => acc + (totalDeliveryCost || 0), 0),
      baseCost: orderDeliveryList.reduce((acc, { deliveryCost }) => acc + (deliveryCost || 0), 0),
      islandsCost: orderDeliveryList.reduce(
        (acc, { islandMountainDeliveryCost }) => acc + (islandMountainDeliveryCost || 0),
        0
      ),
    };

    if (
      deliveryCostCoupon?.isUseDeliveryCostCoupon &&
      deliveryCostCoupon?.applyDeliveryCostCoupon &&
      deliveryCostCoupon?.coupon
    ) {
      deliveryAmount.total = 0;
      deliveryAmount.baseCost = 0;
      deliveryAmount.islandsCost = 0;
    }

    const _totalProductAmount = goodPriceList.reduce((acc, item) => {
      return acc + item.salePrice * item.orderQuantity;
    }, 0);

    const _totalPayAmount = goodPriceList.reduce((acc, item) => {
      return acc + item.salePrice * item.orderQuantity;
    }, 0);

    setPaymentAmount({
      totalProductAmount: _totalProductAmount,
      totalPayAmount: _totalPayAmount,
      discountAmount,
      pointAmount,
      deliveryAmount,
      paymentTotalAmount:
        (_totalProductAmount || 0) -
        (discountAmount?.total || 0) -
        (pointAmount.total || 0) +
        (deliveryAmount.total || 0),
    });
  }, [goodPriceList, orderDeliveryList, pointUsage, discountAmount, deliveryCostCoupon]);

  useEffect(() => {
    setDeliveryCostCoupon((prev) => {
      const canUseDeliveryCostCoupon = () => {
        if (Array.isArray(orderDeliveryList) && orderDeliveryList.length > 1) return false;
        if (Array.isArray(orderDeliveryList) && orderDeliveryList.length === 1) {
          const { totalDeliveryCost = 0, costList = [] } = orderDeliveryList[0];
          if (totalDeliveryCost > 0 && costList.length === 1) {
            return !!prev.coupon && [ORDER_TYPE.STANDARD, ORDER_TYPE.PRESENT].includes(orderType);
          }
        }
        return false;
      };

      return {
        ...prev,
        isUseDeliveryCostCoupon: canUseDeliveryCostCoupon(),
      };
    });
  }, [orderDeliveryList, orderType]);

  const standardResetOrderDeliveryList = useCallback(
    async (orderDeliveryList, refreshOptions) => {
      await resetOrderDeliveryList(orderDeliveryList, { refreshAvailableQuantity: true, ...refreshOptions });
    },
    [resetOrderDeliveryList]
  );

  const ticketResetOrderDeliveryList = useCallback(
    async (orderDeliveryList) => {
      await resetOrderDeliveryList(orderDeliveryList, { refreshAvailableQuantity: true });
    },
    [resetOrderDeliveryList]
  );

  const presentResetOrderDeliveryList = useCallback(
    async (orderDeliveryList, refreshOptions) => {
      return await resetOrderDeliveryList(orderDeliveryList, { refreshDeliveryCost: true, ...refreshOptions });
    },
    [resetOrderDeliveryList]
  );

  const parkPointResetOrderDeliveryList = useCallback(
    async (orderDeliveryList) => {
      await resetOrderDeliveryList(orderDeliveryList);
    },
    [resetOrderDeliveryList]
  );

  const usablePaymentList = useMemo(() => {
    return orderPageInfo.usablePaymentMeanList
      ?.filter(
        (paymentMean) =>
          ![ORDER_TYPE.GIFTISHOW, ORDER_TYPE.PARKPOINT].includes(orderType) ||
          ![PAYMENT_MEAN_CODE.DEPOSIT, PAYMENT_MEAN_CODE.TRANSFER].includes(paymentMean)
      )
      .filter(
        (paymentMean) =>
          ![ORDER_TYPE.PRESENT].includes(orderType) ||
          ![PAYMENT_MEAN_CODE.DEPOSIT, PAYMENT_MEAN_CODE.TRANSFER, PAYMENT_MEAN_CODE.HP].includes(paymentMean)
      )
      .filter(
        (paymentMean) => ![ORDER_TYPE.REGULAR_DELIVERY].includes(orderType) || paymentMean === PAYMENT_MEAN_CODE.CARD
      );
  }, [orderPageInfo, orderType]);

  const handleChangeDeliveryCostCoupon = useCallback(
    (value) => {
      setDeliveryCostCoupon((prev) => ({ ...prev, applyDeliveryCostCoupon: value }));
      initializePointUsage();
      if (!value) setSimpleCouponApply(false);
    },
    [initializePointUsage]
  );

  const handleChangePaymentMethod = useCallback(
    (paymentMethod) => {
      setPaymentMethod(paymentMethod);
      if (paymentMethod?.payMeanCode === PAYMENT_MEAN_CODE.TAX_INVOICE) initializePointUsage(); //세금계산서 결제수단인 경우 포인트 결제불가이기 때문에 포인트 초기화
      calcGoodPrice(couponList, paymentMethod, true);
    },
    [calcGoodPrice, couponList]
  );

  const onChangeDeliveryHopeDate = useCallback(
    async (date) => {
      const newOrderDeliveryList = orderDeliveryList.map((item) => {
        return {
          ...item,
          deliveryPlace: { ...item.deliveryPlace, deliveryDate: date ? format(date, 'yyyyMMdd') : null },
        };
      });
      await resetOrderDeliveryList(newOrderDeliveryList);
    },
    [orderDeliveryList, resetOrderDeliveryList]
  );

  const handleAgreeTermsCheckChange = useCallback((e) => {
    setAgreeTermsChecked(e.target.checked);
  }, []);

  const handlePaymentStart = useCallback(() => {
    setProcessPayment(true);
  }, []);

  const handlePaymentComplete = useCallback(() => {
    setTimeout(() => setProcessPayment(false), 500);
  }, []);

  const isFamilyDiscountOrder = useMemo(() => {
    return (
      Array.isArray(orderPageInfo.goodItemDetailList) &&
      orderPageInfo.goodItemDetailList.find(({ familyDiscountTarget }) => familyDiscountTarget)
    );
  }, [orderPageInfo.goodItemDetailList]);

  const handleWelPointCashReceiptIssuePoss = useCallback((useWelPoint) => {
      for (let index in myWelPointList) {
        const { balanceAmt, cashRcptIssuePossYn } = myWelPointList[index];
        if (0 === useWelPoint) {
          setIsWelPointCashReceiptIssuePoss(false);
          return;
        }
        if (balanceAmt <= useWelPoint) {
          useWelPoint = useWelPoint - balanceAmt;
          if (cashRcptIssuePossYn === 'Y') {
            setIsWelPointCashReceiptIssuePoss(true);
            return;
          }
        } else {
          useWelPoint = 0;
          if (cashRcptIssuePossYn === 'Y') {
            setIsWelPointCashReceiptIssuePoss(true);
            return;
          }
        }
      }
    },
    [pointUsage.welPoint]
  );

  return {
    totalProductAmount,
    totalPayAmount,
    pointUsage,
    handleChangePointUsage: setPointUsage,
    ordererInformation,
    setOrdererInformation,
    paymentMethod,
    setPaymentMethod,
    itemTotalCount,
    orderItemCount,
    hasDeliveryHopeDate,
    couponList,
    goodCouponList,
    cartCouponList,
    handleSelectCoupon,
    paymentAmount,
    orderDeliveryList,
    standardResetOrderDeliveryList,
    ticketResetOrderDeliveryList,
    presentResetOrderDeliveryList,
    parkPointResetOrderDeliveryList,
    handleChangeDeliveryCostCoupon,
    usablePaymentList,
    goodPriceList,
    handleConfirmCoupons,
    deliveryCostCoupon,
    handleSimpleApply,
    simpleCouponApply,
    setCouponList,
    setGoodCouponList,
    setCartCouponList,
    setDeliveryCostCoupon,
    setGoodPriceList,
    setOrderDeliveryList,
    getOrderDeliveryList,
    setIsFinishInit,
    handleChangePaymentMethod,
    enableCouponUsage,
    enablePointUsage,
    enableSavingsUsage,
    onChangeDeliveryHopeDate,
    agreeTermsChecked,
    handleAgreeTermsCheckChange,
    isProcessPayment,
    handlePaymentStart,
    handlePaymentComplete,
    isFamilyDiscountOrder,
    isWelPointCashReceiptIssuePoss,
    handleWelPointCashReceiptIssuePoss,
  };
};

export default useCommonOrderPage;
