import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { getPhoneNumbers } from '@ecp/common/src/utils/textUtil';
import Input from '@ecp/common/src/components/input/mo/Input';

const PhoneNumberInput = ({
  value,
  dataKey = 'cellphoneNumber',
  styleProps,
  placeholder = `-없이 숫자만 입력해주세요`,
  onChange,
  disabled,
  showSeparator,
}) => {
  const formattedValue = useMemo(
    () =>
      getPhoneNumbers(value)
        .filter((i) => i)
        .join('-'),
    [value]
  );

  const handleChangePhoneNumber = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;
      if (showSeparator) {
        e.target.value = value.replace(/[^0-9.]/g, '');
      }
      onChange && onChange(e);
    },
    [onChange, showSeparator]
  );

  return (
    <Input
      clearable
      height={styleProps?.height}
      width={styleProps?.width || '100%'}
      minWidth={styleProps?.minWidth}
      placeholder={placeholder}
      value={formattedValue}
      onChange={handleChangePhoneNumber}
      disabled={disabled}
      data-key={dataKey}
      maxLength={13}
    />
  );
};

PhoneNumberInput.propTypes = {
  value: PropTypes.string,
  dataKey: PropTypes.string,
  styleProps: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  showSeparator: PropTypes.bool,
};

export default PhoneNumberInput;
