export const ACCEPT_METHOD = {
  CARD: 'popreturn:noeasypay:centerCd(Y):below1000',
  PAYCO: 'cardonly',
  SAMSUNG_PAY: 'cardonly:useoverlay:centerCd(Y):below1000',
  L_PAY: 'cardonly:centerCd(Y):below1000',
  SSG_PAY: 'cardonly:useoverlay:centerCd(Y):below1000',
  KAKAO_PAY: 'cardonly:centerCd(Y):below1000',
  TOSS_PAY: 'cardonly:useoverlay:centerCd(Y):below1000',
  NAVER_PAY: 'cardonly:centerCd(Y):below1000',
  V_BANK: 'no_receipt:centerCd(Y)',
  REAL_TIME_BANK: 'popreturn:no_receipt:centerCd(Y)',
  HPP: 'no_receipt:HPP(2):centerCd(Y)',
  AUTO_BILL: 'BILLAUTH(Card):centerCd(Y)',
};
