import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { overrideProperties } from '@ecp/common/src/style/utils';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxCenter, FlexBoxColumn, FlexBoxColumnATag } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import StyledImg from '@ecp/common/src/components/image/StyledImg';
import noImagePath from '@ecp/common/src/assets/image/image__no_image.png';

const StyledRoot = styled(FlexBoxColumnATag)`
  ${overrideProperties(sizePropTypes, {})};

  & > :not(:first-child) {
    padding-top: 10px;
  }
`;

const StyledContents = styled.div`
  ${overrideProperties(sizePropTypes, {})};
  position: relative;
`;

const StyledFlexBox = styled(FlexBox)`
  position: absolute;
  top: 4px;
  right: 4px;
`;

const StyledFlexBoxColumn = styled(FlexBoxColumn)`
  position: absolute;
  top: 0;
  right: 4px;
`;

const StyledChip = styled(FlexBoxCenter)`
  background: rgba(0, 0, 0, 0.5);
  border-radius: 14px;
  padding: 7px 12px;
  height: 26px;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
`;

const StyledTitle = styled.div`
  width: 100%;
  text-align: left;
  font-size: ${moTheme.font.size.$14};
  font-weight: ${moTheme.font.weight.medium};
  line-height: ${moTheme.font.lineHeight.demiLight};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const StyledSubTitle = styled.div`
  width: 100%;
  text-align: left;
  font-size: ${moTheme.font.size.$12};
  font-weight: ${moTheme.font.weight.demiLight};
  line-height: ${moTheme.font.lineHeight.medium};
  color: ${theme.color.text.sub};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const MainStyledTitle = styled.div`
  white-space: pre-wrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: #141415;
  text-align: left;
  width: 100%;
  min-height: 26px;
`;
const MainStyledSubTitle = styled.div`
  white-space: pre-wrap;
  font-weight: 300;
  font-size: 14px;
  line-height: 160%;
  color: #5a5a5a;
  text-align: left;
  width: 100%;
  min-height: 22px;
`;

const Exhibition = ({ item, itemProps, handleDrawerClose, ...props }) => {
  const navigate = useNavigate();
  const onClicked = (e) => {
    e.preventDefault();
    navigate(`/exhibition/${item.exhibitionNumber}`);
    if (handleDrawerClose) handleDrawerClose();
  };
  return (
    <StyledRoot {...props} onClick={onClicked} data-ds-contents={item.exhibitionNumber}>
      <StyledContents height={itemProps.height}>
        <StyledImg src={item?.src || noImagePath} {...itemProps} alt={item?.title} />
        {item?.displayTypes && itemProps.isSwiperSize && (
          <StyledFlexBoxColumn justify-content="flex-end" align-items="flex-end">
            {item?.displayTypes?.map((type, index) => (
              <React.Fragment key={index}>
                <Spacing top={4} />
                <StyledChip>{type?.name}</StyledChip>
              </React.Fragment>
            ))}
          </StyledFlexBoxColumn>
        )}
        {item?.displayTypes && !itemProps.isSwiperSize && (
          <StyledFlexBox justify-content="flex-end" align-items="flex-end">
            {item?.displayTypes?.map((type, index) => (
              <React.Fragment key={index}>
                {index !== 0 && <Spacing left={3} />}
                <StyledChip>{type?.name}</StyledChip>
              </React.Fragment>
            ))}
          </StyledFlexBox>
        )}
      </StyledContents>
      {(item?.title || item?.subTitle) && itemProps.isSwiperSize && (
        <FlexBoxColumn width="100%">
          {item?.title && (
            <>
              <StyledTitle>{item?.title}</StyledTitle>
              <Spacing top={4} />
            </>
          )}
          {item?.subTitle && <StyledSubTitle>{item?.subTitle}</StyledSubTitle>}
        </FlexBoxColumn>
      )}
      {(item?.title || item?.subTitle) && !itemProps.isSwiperSize && (
        <FlexBoxColumn width="100%">
          {item?.title && <MainStyledTitle>{item?.title}</MainStyledTitle>}
          {item?.subTitle && <MainStyledSubTitle>{item?.subTitle}</MainStyledSubTitle>}
        </FlexBoxColumn>
      )}
    </StyledRoot>
  );
};

Exhibition.propTypes = {
  item: PropTypes.shape({
    displayTypes: PropTypes.array,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    src: PropTypes.string,
    exhibitionNumber: PropTypes.string,
  }),
  itemProps: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  handleDrawerClose: PropTypes.func,
};

Exhibition.defaultProps = {
  itemProps: {
    isSwiperSize: false,
    width: '100%',
    height: '15px',
    radius: '8px',
  },
  width: '100%',
};
export default Exhibition;
