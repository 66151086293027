import { atom } from 'recoil';

export const searchKeywordHistoryListState = atom({
  key: 'searchKeywordHistoryListState',
  default: null,
});

export const searchKeywordHistoryOnState = atom({
  key: 'searchKeywordHistoryOnState',
  default: false,
});

export const searchKeywordState = atom({
  key: 'searchKeywordState',
  default: '',
});

export const autoCompleteSearchKeywordListState = atom({
  key: 'autoCompleteSearchKeywordListState',
  default: [],
});

export const searchGoodListState = atom({
  key: 'searchGoodListState',
  default: [],
});

export const searchGoodListUrlState = atom({
  key: 'searchGoodListUrlState',
  default: '',
});

export const searchGoodTotalCountState = atom({
  key: 'searchGoodTotalCountState',
  default: {
    goodTotalCount: 0,
    goodCount: 0,
    goodHyundaiCount: 0,
  },
});
