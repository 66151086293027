import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import clsx from 'clsx';

import useIgnorableError from '@ecp/common/src/hooks/useIgnorableError';
import { overrideProperties } from '@ecp/common/src/style/utils';
import theme from '@ecp/common/src/style/theme/default';
import { fontPropTypes, sizePropTypes } from '@ecp/common/src/style/propTypes';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { stateClassNames } from '@ecp/common/src/components/unstyled/dropdown/DropdownUnstyled';
import CollapseArrow from '@ecp/common/src/assets/icon/collapse_arrow.svg';

const StyledErrorMessage = styled.div`
  font-weight: 350;
  font-size: ${moTheme.font.size.$12};
  line-height: 17px;

  color: ${theme.color.text.error};
`;

const DropdownDevice = (props) => {
  const { items, value, defaultValue, placeholder, onChange, disabled, width, error, errorMessage, ...rest } = props;
  const { ignoreError } = useIgnorableError({ error, value });

  const stateClasses = {
    [stateClassNames.notSelected]: !value || (typeof value === 'object' && Object.keys(value).length === 0),
    [stateClassNames.disabled]: disabled,
  };

  const handleChange = (e) => {
    const {
      target: { value: selectedItem },
    } = e;
    const item = items.find((i) => i.value === selectedItem);
    onChange?.(item);
  };

  return (
    <FlexBoxColumn width={width} align-items={'flex-start'}>
      <StyledDropdownWrapper className={clsx(stateClasses)} {...{ width: '100%' }} {...rest}>
        <StyledLabelWrapper
          className={clsx(stateClasses)}
          disabled={disabled}
          onChange={handleChange}
          value={value?.value || defaultValue?.value || ''}
          title={props?.title}
        >
          <option hidden disabled value={''}>
            {placeholder}
          </option>
          {items.map((item) => (
            <option key={item.key} value={item.value}>
              {item.label}
            </option>
          ))}
        </StyledLabelWrapper>
        <DefaultLabelIcon className={clsx(stateClasses)} />
      </StyledDropdownWrapper>
      {!ignoreError && error && errorMessage && (
        <>
          <Spacing top={6} />
          <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
        </>
      )}
    </FlexBoxColumn>
  );
};

DropdownDevice.propTypes = {
  ...sizePropTypes,
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

DropdownDevice.defaultProps = {
  items: [],
  value: '',
  placeholder: '선택하세요',
  disabled: false,
};

export default DropdownDevice;

DropdownDevice.displayName = 'DropdownDevice';

const DropdownDefaultTextProps = {
  'font-size': theme.font.size.basic,
  'font-weight': '350',
};

const DropdownDefaultSizeProps = {
  width: '338px',
  height: '36px',
};

const StyledDropdownWrapper = styled(FlexBoxSpaceBetween)`
  ${overrideProperties(sizePropTypes, DropdownDefaultSizeProps)};
  align-items: center;
  padding: 0 10px;
  border: 1px solid ${theme.color.line.disabled};

  &.${stateClassNames.disabled} {
    background-color: ${theme.color.background.disabled};
  }
`;

const StyledLabelWrapper = styled.select`
  width: 100%;
  border: none;

  color: ${theme.color.text.basic};
  ${overrideProperties(fontPropTypes, DropdownDefaultTextProps)};

  background-color: inherit;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &.${stateClassNames.notSelected} {
    color: ${theme.color.text.placeholder};
  }

  &.${stateClassNames.disabled} {
    color: ${theme.color.text.disabled};
  }
`;

const DefaultLabelIcon = styled.div`
  width: 12px;
  height: 8px;
  background-image: url(${CollapseArrow});
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(0deg);

  &.${stateClassNames.disabled} {
    color: ${theme.color.text.disabled};
  }
`;
