import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import { zIndex } from '@ecp/common/src/style/constant';
import { FlexBoxAlignCenter } from '@ecp/common/src/layouts/flex/styled';

const EventEndDimPopupWrap = ({ text }) => {
  return <Container>{text}</Container>;
};

EventEndDimPopupWrap.propTypes = {
  text: PropTypes.string,
};
export default EventEndDimPopupWrap;

const Container = styled(FlexBoxAlignCenter)`
  justify-content: center;
  color: #ffffff;
  font-size: ${moTheme.font.weight.medium};
  font-weight: ${moTheme.font.weight.medium};
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: ${zIndex.dimWrapper};
  border-radius: 6px;
  white-space: pre-line;
  text-align: center;
`;
