import React, { useCallback, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RemoveScroll } from 'react-remove-scroll';

import { FloatingOverlay } from '@floating-ui/react';

import theme from '@ecp/common/src/style/theme/default';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { maxSizePropTypes, paddingPropTypes } from '@ecp/common/src/style/propTypes';
import { zIndex } from '@ecp/common/src/style/constant';
import { modalPlacementClassNames as modalClassNames } from '@ecp/common/src/components/unstyled/floating/useModal';
import ModalUnstyled from '@ecp/common/src/components/unstyled/floating/ModalUnstyled';
import { getPositionProp, ModalWrapperStyle } from '@ecp/common/src/components/modal/Modal';
import { ReactComponent as TooltipCloseIcon } from '@ecp/common/src/assets/icon/tooltip__close.svg';

export const ModalWrapper = styled(ModalWrapperStyle)`
  ${overrideProperties({ ...paddingPropTypes, ...maxSizePropTypes }, { padding: '30px 30px 20px' })}

  &.${modalClassNames.right} {
    height: calc(var(--vh, 1vh) * 100);
  }

  &.${modalClassNames.left} {
    height: calc(var(--vh, 1vh) * 100);
  }

  &.center {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
`;

const StyledModalRoot = React.forwardRef(
  ({ children, zIndex: zIndexProp, modalWrapper: ModalWrapper, isOpen, ...props }, ref) => {
    const rootRef = useRef(null);

    useImperativeHandle(ref, () => rootRef.current);

    const { className } = props;

    return (
      <RemoveScroll>
        <FloatingOverlay
          data-testid="floating-overlay"
          style={{
            zIndex: zIndexProp ?? zIndex.modal,
            display: 'grid',
            background: 'rgba(25, 25, 25, 0.8)',
            ...getPositionProp(className),
          }}
        >
          <ModalWrapper ref={rootRef} isOpen={isOpen} {...props}>
            {children}
          </ModalWrapper>
        </FloatingOverlay>
      </RemoveScroll>
    );
  }
);

StyledModalRoot.displayName = 'StyledModalRoot';

StyledModalRoot.propTypes = {
  zIndex: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  modalWrapper: PropTypes.elementType,
  isOpen: PropTypes.bool,
};

StyledModalRoot.defaultProps = {
  modalWrapper: ModalWrapper,
};

const Modal = (props) => {
  const rootPropsTypes = Object.keys({ ...paddingPropTypes, ...maxSizePropTypes });
  const rootProps = Object.keys(props).reduce((accumulator, key) => {
    if (rootPropsTypes.includes(key)) {
      return { ...accumulator, [key]: props[key] };
    }
    return accumulator;
  }, {});

  const { onClose, onOpenChange } = props;

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleOpenChange = useCallback(
    (isOpen) => {
      onOpenChange?.(isOpen);
    },
    [onOpenChange]
  );

  return (
    <ModalUnstyled
      rootComponent={StyledModalRoot}
      rootProps={rootProps}
      openWhenClicked
      {...props}
      onOpenChange={handleOpenChange}
      onClose={handleClose}
      {...{
        closeComponent: props.closeComponent ?? <DefaultCloseComponent onClick={() => props.onClose?.()} />,
      }}
    />
  );
};

Modal.displayName = 'Modal';

Modal.propTypes = ModalUnstyled.propTypes;

export default Modal;

const DefaultCloseComponent = styled(TooltipCloseIcon)`
  border: none;
  width: 18px;
  height: 18px;
  background-color: ${theme.color.background.white};
  position: inherit;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;
