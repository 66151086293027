import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import PropTypes from 'prop-types';

import isEmpty from 'lodash-es/isEmpty';

import useMemberGrade from '@ecp/common/src/hooks/member/useMemberGrade';
import useEmployeeBenefitCoupon from '@ecp/common/src/hooks/coupon/useEmployeeBenefitCoupon';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import BottomSheets from '@ecp/common/src/components/bottomSheets/mo/BottomSheets';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';
import { COUPON_POPUP_TYPE, COUPON_TYPE } from '@ecp/common/src/const/coupon/couponConst';

import { employeeBenefitOpenState } from '@mo-recoil/common/navigation/atom';
import { withCategoryById } from '@mo-recoil/category/selector';
import { userInfoState } from '@mo-recoil/common/user/atom';
import { employeeBenefitBrandCountState, employeeBenefitBrandNameState } from '@mo-recoil/main/atom';
import { categoryNumberState } from '@mo-recoil/category/atom';
import { useAlertDialog } from '@mo-hooks/common/useModal';
import myMembershipApi from '@mo-apis/myPage/myMembershipApi';
import couponApi from '@mo-apis/coupon/couponApi';
import myReviewApi from '@mo-apis/myPage/myReviewApi';
import BenefitCardSwiper from '@mo-components/benefit/BenefitCardSwiper';
import EmployeeBenefitCouponPopup from '@mo-components/benefit/popup/EmployeeBenefitCouponPopup';
import { ReactComponent as SearchIcon } from '@mo-assets/icon/icon__benefit-search.svg';

const BenefitCardBottomSheet = ({ open, onClose }) => {
  const { pathname } = useLocation();
  const { brandNumber } = useParams();
  const { showAlertDialog } = useAlertDialog();

  const { userName, memberNumber } = useRecoilValue(userInfoState);
  const { gradeInfo } = useMemberGrade({ myMembershipApi, memberNumber });
  const setEmployeeBenefitOpen = useSetRecoilState(employeeBenefitOpenState);
  const categoryNumber = useRecoilValue(categoryNumberState);
  const categoryState = useRecoilValue(withCategoryById(categoryNumber));
  const brandCountState = useRecoilValue(employeeBenefitBrandCountState);
  const brandNameState = useRecoilValue(employeeBenefitBrandNameState);

  const [selectedBenefitCardType, setSelectedBenefitCardType] = useState(COUPON_POPUP_TYPE.BRAND);
  const [couponPopupOpen, setCouponPopupOpen] = useState(false);
  const swiperRef = useRef();

  const BenefitCardPopupHeader = useMemo(
    () => (
      <>
        <TextBox color={theme.color.primary} size={moTheme.font.size.$18} weight={moTheme.font.weight.bold}>
          {userName}
        </TextBox>
        <TextBox size={moTheme.font.size.$18} weight={moTheme.font.weight.bold}>
          님을 위한 임직원 혜택
        </TextBox>
      </>
    ),
    [userName]
  );

  const {
    downloadableCouponCount,
    myNoReviewCount,
    maxReviewSaveMoney,
    brandCouponCount,
    brandCount,
    brandListCouponCount,
    brandName,
    categoryCouponCount,
    showBrandListCoupon,
    showBrandCoupon,
    showCategoryCoupon,
    showBirthdayCoupon,
    showFirstOrderCoupon,
    showMembershipCoupon,
    showDownloadCoupon,
    showReviewCoupon,
    handleBirthDayCouponDownloadClick,
    handleFirstPurCouponDownloadClick,
    handleNavigateButtonClick,
  } = useEmployeeBenefitCoupon({
    pathname,
    categoryNumber,
    brandNumber,
    brandCountState,
    brandNameState,
    couponApi,
    myReviewApi,
    myMembershipApi,
    showAlertDialog,
    handleDrawerClose: onClose,
    open,
  });

  const handleCouponPopupOpen = useCallback((popupType) => {
    setSelectedBenefitCardType(popupType);
    setCouponPopupOpen(true);
  }, []);

  const categoryName = useMemo(() => {
    return categoryState?.categoryName || '';
  }, [categoryState?.categoryName]);

  const couponDetailName = useMemo(() => {
    if (selectedBenefitCardType === COUPON_POPUP_TYPE.BRAND) {
      return brandName;
    } else if (selectedBenefitCardType === COUPON_POPUP_TYPE.CATEGORY) {
      return categoryName;
    } else {
      return '';
    }
  }, [brandName, categoryName, selectedBenefitCardType]);

  const handleMoreClick = () => {
    setEmployeeBenefitOpen(true);
    onClose();
  };

  const benefitCardList = useMemo(() => {
    let tempCardList = [];
    if (showBrandListCoupon)
      tempCardList.push({
        couponType: COUPON_TYPE.BRAND_LIST,
        messageValue: brandCount,
        countValue: brandListCouponCount,
        handleButtonClick: () => handleCouponPopupOpen(COUPON_POPUP_TYPE.BRAND_LIST),
      });
    if (showBrandCoupon)
      tempCardList.push({
        couponType: COUPON_TYPE.BRAND,
        messageValue: brandName,
        countValue: brandCouponCount,
        handleButtonClick: () => handleCouponPopupOpen(COUPON_POPUP_TYPE.BRAND),
      });
    if (showCategoryCoupon)
      tempCardList.push({
        couponType: COUPON_TYPE.CATEGORY,
        messageValue: categoryName,
        countValue: categoryCouponCount,
        handleButtonClick: () => handleCouponPopupOpen(COUPON_POPUP_TYPE.CATEGORY),
      });
    if (showFirstOrderCoupon)
      tempCardList.push({
        couponType: COUPON_TYPE.FIRST_ORDER,
        handleButtonClick: () => handleFirstPurCouponDownloadClick(),
      });
    if (showBirthdayCoupon)
      tempCardList.push({
        couponType: COUPON_TYPE.BIRTHDAY,
        handleButtonClick: () => handleBirthDayCouponDownloadClick(),
      });
    if (showMembershipCoupon)
      tempCardList.push({
        couponType: COUPON_TYPE.MEMBERSHIP,
        messageValue: gradeInfo?.memberGradeName,
        handleButtonClick: () => handleNavigateButtonClick(COUPON_TYPE.MEMBERSHIP),
      });
    if (showDownloadCoupon)
      tempCardList.push({
        couponType: COUPON_TYPE.DOWNLOAD,
        countValue: downloadableCouponCount,
        handleButtonClick: () => handleNavigateButtonClick(COUPON_TYPE.DOWNLOAD),
      });
    if (showReviewCoupon)
      tempCardList.push({
        couponType: COUPON_TYPE.REVIEW,
        countValue: myNoReviewCount,
        pointValue: maxReviewSaveMoney,
        handleButtonClick: () => handleNavigateButtonClick(COUPON_TYPE.REVIEW),
      });
    return tempCardList;
  }, [
    brandCount,
    brandCouponCount,
    brandListCouponCount,
    brandName,
    categoryCouponCount,
    categoryName,
    downloadableCouponCount,
    gradeInfo?.memberGradeName,
    handleBirthDayCouponDownloadClick,
    handleCouponPopupOpen,
    handleFirstPurCouponDownloadClick,
    handleNavigateButtonClick,
    maxReviewSaveMoney,
    myNoReviewCount,
    showBirthdayCoupon,
    showBrandCoupon,
    showBrandListCoupon,
    showCategoryCoupon,
    showDownloadCoupon,
    showFirstOrderCoupon,
    showMembershipCoupon,
    showReviewCoupon,
  ]);

  useEffect(() => {
    if (!isEmpty(swiperRef.current?.swiper)) {
      swiperRef.current?.swiper?.slideTo(0);
    }
  }, [benefitCardList]);

  const NoCouponCard = () => (
    <FlexBoxColumn
      width={'260px'}
      height={'160px'}
      background-color={'#ececec'}
      justify-content={'space-between'}
      style={{ padding: '14px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', borderRadius: '8px' }}
    >
      <FlexBox width={'100%'} justify-content={'flex-start'}>
        <TextBox size={13} weight={350} color={'#141415'} line-height={'18.2px'}>
          {'다운로드 가능한 쿠폰이 없습니다.\n' + '\n' + '아래 혜택 더보기를 클릭하여\n' + '더 많은 혜택을 알아보세요.'}
        </TextBox>
      </FlexBox>
      <FlexBox width={'100%'} justify-content={'flex-end'}>
        <SearchIcon />
      </FlexBox>
    </FlexBoxColumn>
  );

  return (
    <BottomSheets
      open={open}
      onClose={onClose}
      header={BenefitCardPopupHeader}
      headerType="popup"
      headerSubType="default"
      closeButtonType="Secondary"
    >
      <FlexBoxColumn width="100%" align-items={'center'}>
        {benefitCardList.length > 0 ? (
          <BenefitCardSwiper
            data-ds-label2={'bt_coupon_p'}
            swiperRef={swiperRef}
            itemList={benefitCardList}
            itemProps={{ width: '260px', height: 'fit-content' }}
            carouselProps={{
              slidesPerView: 'auto',
              spaceBetween: 20,
              autoplay: false,
              loop: benefitCardList.length > 1,
              effect: 'creative',
              centeredSlides: true,
              creativeEffect: {
                prev: {
                  shadow: true,
                  translate: ['-108%', 0, -70],
                },
                next: {
                  shadow: true,
                  translate: ['108%', 0, -70],
                },
              },
            }}
            control="leftOuterLabel"
          />
        ) : (
          <NoCouponCard />
        )}
        <Spacing bottom="15px" />
        <FlexBox justify-content="flex-end" width={'100%'} padding={'1px 20px'}>
          <TextButton type="move" textProps={{ size: '13px' }} onClick={handleMoreClick}>
            혜택 더보기
          </TextButton>
        </FlexBox>
      </FlexBoxColumn>
      <EmployeeBenefitCouponPopup
        open={couponPopupOpen}
        type={selectedBenefitCardType}
        detailNumber={brandNumber || categoryNumber || ''}
        detailName={couponDetailName}
        onClose={() => setCouponPopupOpen(false)}
      />
    </BottomSheets>
  );
};

export default BenefitCardBottomSheet;

BenefitCardBottomSheet.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
