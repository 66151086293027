import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { positionPropTypes, sizePropTypes } from '@ecp/common/src/style/propTypes';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';
import { SwiperBasic } from '@ecp/common/src/components/swiper/mo/SwiperTemplate';
import Badge from '@ecp/common/src/components/badge/mo/Badge';
import { SwiperAdBannerItem } from '@ecp/common/src/components/swiper/mo/component/SwiperItem';
import { MAIN_FUNCTION_CODE, MAIN_FUNCTION_TYPE_CODE } from '@ecp/common/src/const/display/mainTemplateConst';

import { withMainCategoryTreeList } from '@mo-recoil/category/selector';
import { commonMainFunctionListState } from '@mo-recoil/main/atom';
import { withCommonMainFunctionUseQuery } from '@mo-recoil/main/selector';
import { menuListState } from '@mo-recoil/common/menu/atom';
import useBanner from '@mo-hooks/display/useBanner';
import { useGnbTitle } from '@mo-hooks/common/useGnb';
import useBestBrandList from '@mo-hooks/brand/useBestBrandList';
import useNavigateBrandDetailPage from '@mo-hooks/brand/useNavigateBrandDetailPage';
import mainFunctionApi from '@mo-apis/display/mainFunctionApi';
import MainCategoryItem, { IconLinkCard } from '@mo-components/category/MainCategoryItem';
import MenuGroups from '@mo-components/common/gnb/GnbMenuGroups';
import { ReactComponent as CustomerServiceIcon } from '@mo-assets/icon/icon__customer-service.svg';

const MainCategoryPage = () => {
  useGnbTitle('카테고리');
  const navigate = useNavigate();
  const mainCategories = useRecoilValue(withMainCategoryTreeList);
  const mainCategoryMenuList = useRecoilValue(menuListState);
  const showAdBanner = useRecoilValue(withCommonMainFunctionUseQuery(MAIN_FUNCTION_CODE.AD_BANNER));
  const [commonMainFunctionList, setCommonMainFunctionList] = useRecoilState(commonMainFunctionListState);

  const { bestBrandList } = useBestBrandList();
  const { handleNavigateToBrandDetail } = useNavigateBrandDetailPage();

  useEffect(() => {
    (async () => {
      if (!commonMainFunctionList.length) {
        const { result: commonList } = await mainFunctionApi.getMainFunctionList(MAIN_FUNCTION_TYPE_CODE.COMMON);
        setCommonMainFunctionList(commonList);
      }
    })();
  }, [commonMainFunctionList.length, setCommonMainFunctionList]);

  return (
    <FlexBoxColumn width={'100%'} data-ds-label1={'m_ctg'}>
      <FlexBoxColumn padding={'30px 20px'} align-items={'flex-start'}>
        <TextBox size={moTheme.font.size.$16} weight={moTheme.font.weight.bold} height={'26px'}>
          원하시는 카테고리를 선택해 주세요
        </TextBox>
        <Spacing top={20} />
        <FlexBox flex-wrap={'wrap'} align-items={'flex-start'} data-ds-label2={'ctg'}>
          {mainCategories.map((category) => (
            <MainCategoryItem key={category.categoryNumber} {...category} />
          ))}
        </FlexBox>
        <Spacing top={10} />
        <FlexBox width={'100%'} flex-wrap={'wrap'} data-ds-label2={'menu'}>
          <MenuGroups gnbMenuList={mainCategoryMenuList} />
        </FlexBox>
      </FlexBoxColumn>
      <FlexBox width={'100%'} height={'10px'} background-color={theme.color.background.disabled} />
      <FlexBoxColumn width={'100%'} padding={'20px 20px 0 20px'} align-items={'flex-start'} data-ds-label2={'brand'}>
        <TextButton type={'move'} size={moTheme.font.size.$13} onClick={() => navigate('/brand')}>
          브랜드 전체 보기
        </TextButton>
        <Spacing top={14} />
        <FlexBox flex-wrap={'wrap'} align-items={'flex-start'}>
          {bestBrandList?.map?.((brand) => (
            <IconLinkCard
              key={brand.brandNumber}
              onClick={() => handleNavigateToBrandDetail({ brandNumber: brand.brandNumber })}
              imageSrc={brand.logoImageUrl}
              title={brand.brandName}
              size={'cover'}
              imageProps={{ border: `1px solid ${theme.color.line.background}` }}
              data-ds-contents={brand.brandNumber}
            />
          ))}
        </FlexBox>
      </FlexBoxColumn>
      <FlexBox width={'100%'} height={'10px'} background-color={theme.color.background.disabled} />
      <FlexBoxColumn width={'100%'} padding={'30px 20px'} align-items={'flex-start'}>
        {showAdBanner && <AdBanner />}
        <Spacing top={14} />
        <FlexBox justify-content={'center'}>
          <TextBox weight={moTheme.font.weight.medium}>도움이 필요하신가요?</TextBox>
          <Spacing left={8} />
          <TextButton
            type={'custom-left'}
            width={'90px'}
            height={'30px'}
            border={`1px solid ${theme.color.secondary}`}
            border-radius={'4px'}
            justify-content={'center'}
            customIcon={CustomerServiceIcon}
            onClick={() => navigate('/customer-service')}
            title={'고객센터'}
          >
            고객센터
          </TextButton>
        </FlexBox>
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default MainCategoryPage;

const MAX_EXPOSURE_COUNT = 10;
const AdBanner = () => {
  const [adBannerList, setAdBannerList] = useState([]);
  const { handleClick, dsLabel } = useBanner(
    MAIN_FUNCTION_CODE.AD_BANNER,
    adBannerList,
    setAdBannerList,
    MAX_EXPOSURE_COUNT
  );

  return (
    <>
      {adBannerList.length > 0 && (
        <>
          <Badge
            size={{ width: '32px', height: '22px' }}
            position={{ top: 0, right: 0 }}
            badgeComponent={AdDecorator}
            badgeContent={'AD'}
          >
            <SwiperBasic
              data-ds-label2={dsLabel}
              itemList={adBannerList}
              itemComponent={SwiperAdBannerItem}
              onClick={handleClick}
              title={'광고 배너'}
            />
          </Badge>
          <Spacing top={20} />
        </>
      )}
    </>
  );
};

const AdDecorator = styled(FlexBox)`
  position: absolute;
  justify-content: center;
  border: 1px solid ${theme.color.line.basic};
  border-radius: 0 6px;
  color: ${theme.color.text.basic};
  font-size: ${moTheme.font.size.$12};
  font-weight: ${moTheme.font.weight.medium};
  background: ${theme.color.background.white};
  z-index: 1;

  ${overrideProperties(positionPropTypes, {})};
  ${overrideProperties(sizePropTypes, {})};
`;
