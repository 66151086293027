import React, { useCallback, useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import clsx from 'clsx';

import { hasSearchCondition } from '@ecp/common/src/utils/good/brandUtil';
import theme from '@ecp/common/src/style/theme/default';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import SearchInput from '@ecp/common/src/components/input/mo/SearchInput';

import { useAlertDialog } from '@mo-hooks/common/useModal';
import CategorySwiper from '@mo-components/display/CategorySwiper';

const BrandSearchCondition = React.forwardRef(
  (
    {
      sticky,
      scrollDirection,
      searchCondition,
      handleSearchConditionChange,
      handleSearchConditionInitialize,
      handleCategorySwiperArrowClick,
    },
    ref
  ) => {
    const { showAlertDialog } = useAlertDialog();
    const searchInputRef = useRef(null);

    const [searchKeyword, setSearchKeyword] = useState('');

    const handleInitializeClick = useCallback(() => {
      setSearchKeyword('');
      handleSearchConditionInitialize();
    }, [handleSearchConditionInitialize]);

    const handleSearchKeywordChange = useCallback(() => {
      if (!searchKeyword.trim()) {
        showAlertDialog('검색하실 브랜드명을 입력해 주세요.', () => {
          searchInputRef?.current?.focus();
        });
        return;
      }

      handleSearchConditionChange({ searchKeyword });
    }, [handleSearchConditionChange, searchKeyword, showAlertDialog]);

    useEffect(()=>{
      if (searchCondition.searchKeyword){
        setSearchKeyword(searchCondition.searchKeyword);
      }
    },[searchCondition.searchKeyword]);
    
    return (
      <Wrapper ref={ref} className={clsx({ sticky })} scrollDirection={scrollDirection}>
        <Spacing left={20} right={20} width={'100%'}>
          <SearchInput
            ref={searchInputRef}
            width={'100%'}
            value={searchKeyword}
            placeholder={'브랜드명을 입력해 주세요.'}
            enableInitialize={hasSearchCondition(searchCondition)}
            onChange={(e) => setSearchKeyword(e?.target?.value ?? '')}
            onSearchClick={handleSearchKeywordChange}
            onEnter={handleSearchKeywordChange}
            onInitializeClick={handleInitializeClick}
          />
        </Spacing>
        <Spacing top={30} />
        <FlexBox id={'brand-search-category-swiper'}>
          <CategorySwiper
            selectedCategoryNumber={searchCondition.largeDisplayCategoryNumber}
            onChange={(largeDisplayCategoryNumber) =>
              handleSearchConditionChange({
                largeDisplayCategoryNumber: largeDisplayCategoryNumber === 'ALL' ? '' : largeDisplayCategoryNumber,
              })
            }
            onArrowClick={handleCategorySwiperArrowClick}
            useAll
          />
        </FlexBox>
        <Spacing top={20} />
      </Wrapper>
    );
  }
);

const Wrapper = styled(FlexBoxColumn)`
  width: 100%;
  background-color: ${theme.color.background.white};
  z-index: 9;

  &.sticky {
    position: sticky;
    top: ${({ scrollDirection }) => (scrollDirection === 'up' ? '86px' : '40px')};
    transition: top 0.3s ease-in-out;
  }
`;

BrandSearchCondition.displayName = 'BrandSearchCondition';

export default BrandSearchCondition;

BrandSearchCondition.propTypes = {
  sticky: PropTypes.bool,
  scrollDirection: PropTypes.string,
  searchCondition: PropTypes.object,
  handleSearchConditionChange: PropTypes.func,
  handleSearchConditionInitialize: PropTypes.func,
  handleCategorySwiperArrowClick: PropTypes.func,
};
