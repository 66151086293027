import { PAY_MEAN_CODE } from '@ecp/common/src/const/interface/payMeanCode';
import { GO_PAY_METHOD } from '@ecp/common/src/const/interface/goPayMethod';
import { ACCEPT_METHOD } from '@ecp/common/src/const/interface/acceptMethod';
import { P_RESERVED } from '@ecp/common/src/const/interface/pReserved';
import { INI_PAYMENT } from '@ecp/common/src/const/interface/iniPayment';

export const INICIS_PAY_TYPE = {
  K_PAY: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    acceptMethod: ACCEPT_METHOD.CARD,
    pReserved: P_RESERVED.K_PAY,
  },
  SAMSUNG_PAY: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    goPayMethod: GO_PAY_METHOD.SAMSUNG_PAY,
    acceptMethod: ACCEPT_METHOD.SAMSUNG_PAY,
    pReserved: P_RESERVED.SAMSUNG_PAY,
    cardType: 'spay',
  },
  SSG_PAY: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    goPayMethod: GO_PAY_METHOD.SSG_PAY,
    acceptMethod: ACCEPT_METHOD.SSG_PAY,
    pReserved: P_RESERVED.SSG_PAY,
    cardType: 'ssgpay',
  },
  L_PAY: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    goPayMethod: GO_PAY_METHOD.LPAY,
    acceptMethod: ACCEPT_METHOD.L_PAY,
    pReserved: P_RESERVED.L_PAY,
  },
  KAKAO_PAY: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    goPayMethod: GO_PAY_METHOD.KAKAO_PAY,
    acceptMethod: ACCEPT_METHOD.KAKAO_PAY,
    pReserved: P_RESERVED.KAKAO_PAY,
    cardType: 'kakaopay',
  },
  TOSS_PAY: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    goPayMethod: GO_PAY_METHOD.TOSS_PAY,
    acceptMethod: ACCEPT_METHOD.TOSS_PAY,
    pReserved: P_RESERVED.TOSS_PAY,
    cardType: 'tosspay',
  },
  NAVER_PAY: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    goPayMethod: GO_PAY_METHOD.NAVER_PAY,
    acceptMethod: ACCEPT_METHOD.NAVER_PAY,
    pReserved: P_RESERVED.NAVER_PAY,
    cardType: 'naverpay',
  },
  CARD: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    goPayMethod: GO_PAY_METHOD.CARD,
    acceptMethod: ACCEPT_METHOD.CARD,
    pReserved: P_RESERVED.CARD,
  },
  REAL_TIME_BANK: {
    payMeanCode: PAY_MEAN_CODE.REAL_TIME_BANK,
    goPayMethod: GO_PAY_METHOD.REAL_TIME_BANK,
    acceptMethod: ACCEPT_METHOD.REAL_TIME_BANK,
  },
  V_BANK: {
    payMeanCode: PAY_MEAN_CODE.V_BANK,
    goPayMethod: GO_PAY_METHOD.V_BANK,
    acceptMethod: ACCEPT_METHOD.V_BANK,
  },
  SAMSUNG_WALLET: {
    payMeanCode: PAY_MEAN_CODE.SAMSUNG_WALLET,
  },
  HPP: {
    payMeanCode: PAY_MEAN_CODE.HPP,
    goPayMethod: GO_PAY_METHOD.HPP,
    acceptMethod: ACCEPT_METHOD.HPP,
  },
  AUTO_BILL: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    acceptMethod: ACCEPT_METHOD.AUTO_BILL,
  },
};

export const INICIS_PAY_TYPE_MOBILE = {
  K_PAY: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    iniPayment: INI_PAYMENT.CARD,
    pReserved: P_RESERVED.K_PAY,
  },
  SAMSUNG_PAY: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    iniPayment: INI_PAYMENT.SAMSUNG_PAY,
    pReserved: P_RESERVED.SAMSUNG_PAY,
    cardType: 'spay',
  },
  SSG_PAY: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    iniPayment: INI_PAYMENT.SSG_PAY,
    pReserved: P_RESERVED.SSG_PAY,
    cardType: 'ssgpay',
  },
  L_PAY: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    iniPayment: INI_PAYMENT.LPAY,
    pReserved: P_RESERVED.L_PAY,
  },
  KAKAO_PAY: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    iniPayment: INI_PAYMENT.KAKAO_PAY,
    pReserved: P_RESERVED.KAKAO_PAY,
    cardType: 'kakaopay',
  },
  TOSS_PAY: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    iniPayment: INI_PAYMENT.TOSS_PAY,
    pReserved: P_RESERVED.TOSS_PAY,
    cardType: 'tosspay',
  },
  NAVER_PAY: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    iniPayment: INI_PAYMENT.NAVER_PAY,
    pReserved: P_RESERVED.NAVER_PAY,
    cardType: 'naverpay',
  },
  CARD: {
    payMeanCode: PAY_MEAN_CODE.CARD,
    iniPayment: INI_PAYMENT.CARD,
    pReserved: P_RESERVED.CARD,
  },
  REAL_TIME_BANK: {
    payMeanCode: PAY_MEAN_CODE.REAL_TIME_BANK,
    iniPayment: INI_PAYMENT.REAL_TIME_BANK,
  },
  V_BANK: {
    payMeanCode: PAY_MEAN_CODE.V_BANK,
    iniPayment: INI_PAYMENT.V_BANK,
  },
  SAMSUNG_WALLET: {
    payMeanCode: PAY_MEAN_CODE.SAMSUNG_WALLET,
  },
  HPP: {
    payMeanCode: PAY_MEAN_CODE.HPP,
    iniPayment: INI_PAYMENT.HPP,
  },
};
