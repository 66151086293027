import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Qs from 'qs';

import { useAlertDialog } from '@mo-hooks/common/useModal';
import paymentApi from '@mo-apis/order/paymentApi';

const PaycoReturnPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showAlertDialog } = useAlertDialog();

  useEffect(() => {
    const queryParameters = Qs.parse(location.search, { ignoreQueryPrefix: true });
    if (queryParameters.code !== '0') {
      showAlertDialog(queryParameters.message, () => {
        // 주문서 페이지로 이동
        const { cartNoList, orderTypeCode } = queryParameters;
        if (cartNoList) {
          navigate('/order', {
            replace: true,
            state: {
              cartNoList: cartNoList.split(','),
              isPresent: orderTypeCode === '90',
            },
          });
        } else {
          navigate('/');
        }
      });
    } else {
      (async () => {
        navigate('/order/in-progress', { replace: true });
        const { result, code, message } = await paymentApi.createOrder({
          ...queryParameters,
        });
        if (code) {
          navigate('/order/payment-fail', { replace: true, state: { code, message } });
        } else {
          navigate(`/order/complete/${result.orderNumber}`, { replace: true });
        }
      })();
    }
  }, [location.search, navigate, showAlertDialog]);

  return <div />;
};

export default PaycoReturnPage;
