import React from 'react';
import { useRecoilValue } from 'recoil';

import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { BULK_ORDER_DESCRIPTION } from '@ecp/common/src/const/footer';

import { getPcBulkpImgFilePath, getPcBulkpPhrase } from '@mo-recoil/system/selector';

const BulkOrderDescription = () => {
  const CustomLogo = useRecoilValue(getPcBulkpImgFilePath);
  const CustomPhrase = useRecoilValue(getPcBulkpPhrase);

  return (
    <>
      <FlexBoxColumn background-color={theme.color.background.basic} width={'100%'} padding={'20px'}>
        <img width={'145px'} height={'30px'} src={CustomLogo} alt={'LogoIcon'} />
        <Spacing top={'14px'} />
        <TextBox
          size={moTheme.font.size.$14}
          weight={moTheme.font.weight.medium}
          height={moTheme.font.lineHeight.demiLight}
          style={{ textAlign: 'center' }}
        >
          {CustomPhrase}
        </TextBox>
      </FlexBoxColumn>
      <Spacing top={'14px'} />
      <TextBox
        size={moTheme.font.size.$12}
        weight={moTheme.font.weight.demiLight}
        height={moTheme.font.lineHeight.demiLight}
        color={theme.color.text.sub}
      >
        {BULK_ORDER_DESCRIPTION}
      </TextBox>
    </>
  );
};

export default BulkOrderDescription;
