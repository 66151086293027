const MEMBER_BIZ_MSG = {
  MB_0002: {
    PC: '현재 비밀번호가 틀립니다.\n다시 정확히 입력해주세요.',
    MO: '현재 비밀번호가 틀립니다.\n다시 정확히 입력해주세요.',
  },
  MB_0003: {
    PC: '임시 비밀번호가 틀립니다.\n다시 정확히 입력해주세요.',
    MO: '임시 비밀번호가 틀립니다.\n다시 정확히 입력해주세요.',
  },
};

export { MEMBER_BIZ_MSG };
