import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxCenter, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import BottomSheets from '@ecp/common/src/components/bottomSheets/mo/BottomSheets';

import ShareLink from '@mo-components/common/share/ShareLink';

const ShareBottomSheets = ({ kakaoProps, open, onClose, urlLink }) => {
  return (
    <>
      <BottomSheets
        open={open}
        onClose={() => onClose(false)}
        header={'공유하기'}
        width={'100%'}
        headerProps={{ width: '100%' }}
      >
        <FlexBoxColumn width={'100%'}>
          <Spacing top={20} />
          <ShareLink
            iconProps={{ width: '50px', height: '50px' }}
            gap={'40px'}
            kakaoProps={kakaoProps}
            urlLink={urlLink}
          />
          <Spacing top={10} />
          <TextWrapper>
            <FlexBoxCenter width={55}>
              <TextBox
                size={moTheme.font.size.$12}
                weight={moTheme.font.weight.demiLight}
                color={theme.color.text.basic}
              >
                카카오톡
              </TextBox>
            </FlexBoxCenter>
            <Spacing left={40} />
            <FlexBoxCenter width={55}>
              <TextBox
                size={moTheme.font.size.$12}
                weight={moTheme.font.weight.demiLight}
                color={theme.color.text.basic}
              >
                URL 복사
              </TextBox>
            </FlexBoxCenter>
          </TextWrapper>
        </FlexBoxColumn>
      </BottomSheets>
    </>
  );
};

const TextWrapper = styled(FlexBoxCenter)`
  width: 100%;
`;

export default ShareBottomSheets;

ShareBottomSheets.propTypes = {
  kakaoProps: PropTypes.shape({
    title: PropTypes.string,
    imageUrl: PropTypes.string,
    productName: PropTypes.string,
    regularPrice: PropTypes.number,
    discountRate: PropTypes.number,
    discountPrice: PropTypes.number,
    linkUrl: PropTypes.string,
    productNumber: PropTypes.string,
  }),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  urlLink: PropTypes.string,
};
