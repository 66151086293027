import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import debounce from 'lodash-es/debounce';

import useOrderValidation from '@ecp/common/src/hooks/order/payment/useOrderValidation';
import useCommonOrderPage from '@ecp/common/src/hooks/order/payment/useCommonOrderPage';
import useTicketOrderPage from '@ecp/common/src/hooks/order/payment/useTicketOrderPage';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { RectanglePageButton } from '@ecp/common/src/components/button/mo/Button';
import Floating from '@ecp/common/src/components/floating/mo/Floating';

import { setDeliveryListWithDeliveryCost } from '@mo-utils/orderUtils';
import { withAdultCertification } from '@mo-recoil/common/user/selector';
import { systemInformationState } from '@mo-recoil/system/atom';
import { useGnbTitle } from '@mo-hooks/common/useGnb';
import { useAlertDialog } from '@mo-hooks/common/useModal';
import AgreeTermsConditions from '@mo-components/order/payment/AgreeTermsConditions';
import DiscountBenefitInformation from '@mo-components/order/payment/DiscountBenefitInformation';
import TicketReceiverInformation from '@mo-components/order/payment/giftiShow/TicketReceiverInformation';
import OrderDeliveryHopeDate from '@mo-components/order/payment/OrderDeliveryHopeDate';
import OrdererInformation from '@mo-components/order/payment/OrdererInformation';
import OrderGoodItemListSingle from '@mo-components/order/payment/OrderGoodItemListSingle';
import OrderPayment from '@mo-components/order/payment/OrderPayment';
import OrderPaymentStatement from '@mo-components/order/payment/OrderPaymentStatement';
import OrderSummary from '@mo-components/order/payment/OrderSummary';
import PaymentMethodInformation from '@mo-components/order/payment/PaymentMethodInformation';
import PointsUsageInformation from '@mo-components/order/payment/PointsUsageInformation';
import OrderAdultVerifyPopup from '@mo-components/order/payment/OrderAdultVerifyPopup';
import useSiteInfo from '@mo-hooks/common/useSiteInfo';
import { PAYMENT_MEAN_CODE } from '@ecp/common/src/const/order/orderConst';

const TicketOrderPage = ({ orderPageInfo, orderType }) => {
  useGnbTitle('주문결제');
  const { isPriceOpen, isPointUse } = useSiteInfo();
  const { showAlertDialog } = useAlertDialog();
  const isAdultVerified = useRecoilValue(withAdultCertification);
  const systemInformation = useRecoilValue(systemInformationState);
  const [orderPaymentData, setOrderPaymentData] = useState({});
  const [cashReceiptInfo, setCashReceiptInfo] = useState({ cashReceiptType: 'none' });
  const [taxInvoiceInfo, setTaxInvoiceInfo] = useState({ bizName: '', bizNumber: '', bizEmail: orderPageInfo?.ordererInformation?.ordererEmail });

  const { validate, error } = useOrderValidation({ isAdultVerified, showAlertDialog });

  const {
    totalPayAmount,
    pointUsage,
    handleChangePointUsage,
    ordererInformation,
    setOrdererInformation,
    paymentMethod,
    handleChangePaymentMethod,
    paymentAmount,
    couponList,
    goodCouponList,
    cartCouponList,
    goodPriceList,
    handleConfirmCoupons,
    itemTotalCount,
    orderItemCount,
    hasDeliveryHopeDate,
    orderDeliveryList,
    ticketResetOrderDeliveryList,
    usablePaymentList,
    handleSimpleApply,
    simpleCouponApply,
    enableCouponUsage,
    enablePointUsage,
    enableSavingsUsage,
    onChangeDeliveryHopeDate,
    agreeTermsChecked,
    handleAgreeTermsCheckChange,
    isProcessPayment,
    handlePaymentStart,
    handlePaymentComplete,
    handleWelPointCashReceiptIssuePoss,
    isWelPointCashReceiptIssuePoss,
  } = useCommonOrderPage({
    orderType,
    orderPageInfo,
    setDeliveryListWithDeliveryCost,
    systemInformation,
  });
  const { orderGoodType } = useTicketOrderPage();

  const debouncedClick = useMemo(
    () =>
      debounce(async () => {
        if (!agreeTermsChecked) {
          showAlertDialog('개인정보동의 확인 해주세요');
          return;
        }

        const data = {
          orderTypeCode: '84', // OD0010 82 티켓
          hasDeliveryHopeDate,
          orderGoodType,
          paymentMethod,
          isMultiDelivery: orderDeliveryList.length > 1,
          orderDeliveryList,
          ordererInformation,
          paymentAmount,
          cashReceiptInfo,
          couponList,
          welPointCashReceiptIssuePoss: isWelPointCashReceiptIssuePoss,
          isPriceOpen: isPriceOpen,
          taxInvoiceInfo,
        };

        if (!(await validate(data))) return;
        if (isProcessPayment) return;
        handlePaymentStart();

        setOrderPaymentData(data);
      }, 200),
    [
      agreeTermsChecked,
      hasDeliveryHopeDate,
      orderGoodType,
      paymentMethod,
      orderDeliveryList,
      ordererInformation,
      paymentAmount,
      cashReceiptInfo,
      couponList,
      validate,
      isProcessPayment,
      handlePaymentStart,
      showAlertDialog,
      isWelPointCashReceiptIssuePoss,
      isPriceOpen,
      taxInvoiceInfo,
    ]
  );

  const handlePaymentButtonClick = useCallback(debouncedClick, [debouncedClick]);

  const handlerChangeTaxInvoice = useCallback((item) => {
    setTaxInvoiceInfo((prev) => ({ ...prev, ...item}));
  }, []);

  return (
    <Container>
      <Spacing top={'20px'} />
      <OrdererInformation
        goodList={orderPageInfo.goodList}
        ordererInformation={ordererInformation}
        handleChangeOrdererInformation={setOrdererInformation}
      />
      <HorizontalDivider height="10px" />
      <Spacing top={'20px'} />
      <InnerContainer>
        <TicketReceiverInformation
          goodList={orderPageInfo.goodList}
          ordererInformation={ordererInformation}
          orderDeliveryList={orderDeliveryList}
          handleChangeOrderDeliveryList={ticketResetOrderDeliveryList}
          itemTotalCount={itemTotalCount}
        />
        {hasDeliveryHopeDate && (
          <OrderDeliveryHopeDate onChange={onChangeDeliveryHopeDate} orderGoodType={orderGoodType} />
        )}
        <OrderGoodItemListSingle goodList={orderPageInfo.goodList} />
        {isPriceOpen && enableCouponUsage && (
          <DiscountBenefitInformation
            paymentAmount={paymentAmount}
            goodCouponList={goodCouponList}
            cartCouponList={cartCouponList}
            couponList={couponList}
            goodPriceList={goodPriceList}
            onChange={handleConfirmCoupons}
            onClickSimpleApply={handleSimpleApply}
            simpleCouponApply={simpleCouponApply}
          />
        )}
        {isPriceOpen && isPointUse && (
          <PointsUsageInformation
            totalPayAmount={totalPayAmount}
            pointUsage={pointUsage}
            handleChangePointUsage={handleChangePointUsage}
            goodList={orderPageInfo.goodList}
            paymentAmount={paymentAmount}
            orderGoodType={orderGoodType}
            usablePaymentList={usablePaymentList}
            enablePointUsage={enablePointUsage}
            enableSavingsUsage={enableSavingsUsage}
            disablePaymentMethodPointUsage={[PAYMENT_MEAN_CODE.TAX_INVOICE].includes(paymentMethod?.payMeanCode)}
            handleWelPointCashReceiptIssuePoss={handleWelPointCashReceiptIssuePoss}
          />
        )}
        {isPriceOpen && (
          <>
            <OrderPaymentStatement paymentAmount={paymentAmount} />
            <PaymentMethodInformation
              handleChangePaymentMethod={handleChangePaymentMethod}
              ordererInformation={ordererInformation}
              paymentMethod={paymentMethod}
              isDirectForeignGood={orderGoodType.directForeignGood}
              cashReceiptInfo={cashReceiptInfo}
              handleChangeCashReceiptInfo={setCashReceiptInfo}
              usablePaymentList={usablePaymentList}
              couponList={couponList}
              orderType={orderType}
              expectedDepositDate={orderPageInfo.expectedDepositDate}
              isWelPointCashReceiptIssuePoss={isWelPointCashReceiptIssuePoss}
              taxInvoiceInfo={taxInvoiceInfo}
              handlerChangeTaxInvoice={handlerChangeTaxInvoice}
            />
          </>
        )}
      </InnerContainer>
      <Spacing top={'20px'} />
      <HorizontalDivider height="10px" />
      <Spacing top={'20px'} />
      <AgreeTermsConditions
        checked={agreeTermsChecked}
        onChange={handleAgreeTermsCheckChange}
        orderGoodType={orderGoodType}
        orderType={orderType}
      />
      <Spacing top={'68px'} />
      <HorizontalDivider />
      <OrderSummary orderItemCount={orderItemCount} paymentAmount={paymentAmount.paymentTotalAmount} />
      <Floating
        buttonContent={() => (
          <PayButtonWrapper>
            <RectanglePageButton onClick={handlePaymentButtonClick}>결제하기</RectanglePageButton>
          </PayButtonWrapper>
        )}
      />
      <Spacing top={'56px'} />
      <OrderPayment orderType={orderType} data={orderPaymentData} onComplete={handlePaymentComplete} />
      <OrderAdultVerifyPopup error={error} />
    </Container>
  );
};
export default TicketOrderPage;

TicketOrderPage.propTypes = {
  orderPageInfo: PropTypes.object,
  orderType: PropTypes.string,
};

const Container = styled(FlexBoxColumn)`
  align-self: stretch;
  align-items: center;
`;
const InnerContainer = styled(FlexBoxColumn)`
  width: 100%;
  gap: 40px;
  padding: 0 20px;
`;
const PayButtonWrapper = styled(FlexBox)`
  width: 100%;
`;
