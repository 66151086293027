import React, { useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isEmpty from 'lodash-es/isEmpty';

import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';
import Swiper from '@ecp/common/src/components/swiper/mo/Swiper';
import { ReactComponent as Arrow } from '@ecp/common/src/assets/icon/icon__arrow--right.svg';

import { withCanDisplayBreadcrumb } from '@mo-recoil/category/selector';

const BreadCrumbSwiperItem = ({ item, onClick }) => {
  return (
    <Wrapper lastChild={item?.lastChild}>
      <TextButton
        key={`breadcrumb-${item?.categoryNumber}`}
        onClick={onClick}
        textProps={{
          size: moTheme.font.size.$13,
          weight: moTheme.font.weight.demiLight,
          color:
            item?.currentCategoryNumber === item?.categoryNumber ? theme.color.text.selected : theme.color.text.basic,
          ellipsis: true,
        }}
      >
        {item?.categoryName}
      </TextButton>
      <Spacing right={10} />
      {!item?.lastChild && (
        <>
          <Arrow width={'5px'} />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(FlexBox)`
  margin-right: ${({ lastChild }) => (lastChild ? '20px' : '10px')};
`;

BreadCrumbSwiperItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
};

const DisplayBreadcrumb = ({ categoryNumber }) => {
  const breadcrumb = useRecoilValue(withCanDisplayBreadcrumb(categoryNumber));
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  const mappedCategoryList = useMemo(
    () =>
      breadcrumb.map((it, index) => ({
        ...it,
        currentCategoryNumber: categoryNumber,
        lastChild: index === breadcrumb?.length - 1,
      })),
    [breadcrumb, categoryNumber]
  );

  const handleCategoryClick = (event, index) => {
    const { depthNumber, categoryNumber } = mappedCategoryList[index];
    navigate(`/category/${depthNumber > 1 ? 'sub/' : ''}${categoryNumber}`);
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(0);
    }
  };

  return (
    <FlexBox width={'100%'} justify-content={'flex-start'} padding={'12px 0'} flex-wrap={'wrap'}>
      {!isEmpty(breadcrumb) && (
        <Swiper
          ref={swiperRef}
          width={'360px'}
          itemComponent={BreadCrumbSwiperItem}
          itemList={mappedCategoryList}
          itemProps={{ width: 'max-content', categoryNumber }}
          carouselProps={{
            slidesPerView: 'auto',
            loop: false,
            autoplay: false,
            slidesOffsetBefore: 20,
            slidesOffsetAfter: 20,
          }}
          onClick={handleCategoryClick}
        />
      )}
    </FlexBox>
  );
};

DisplayBreadcrumb.propTypes = {
  categoryNumber: PropTypes.string,
};
export default DisplayBreadcrumb;
