import React from 'react';

import { swiperItemPropTypes } from '@ecp/common/src/components/swiper/component/SwiperItem';

import GoodCard from './GoodCard';

const GoodCardCarouselItem = ({ item, itemProps }) => (
  <GoodCard type={itemProps.type} showRelated={itemProps.showRelated} {...item} />
);

GoodCardCarouselItem.propTypes = swiperItemPropTypes;

export default GoodCardCarouselItem;
