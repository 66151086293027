import { useCallback, useState } from 'react';

const useExternalScript = (src) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleLoad = useCallback(() => setLoading(false), []);
  const handleError = useCallback((error) => setError(error), []);

  const getScriptElement = useCallback(() => {
    return document.querySelector(`script[src="${src}"]`);
  }, [src]);

  const startLoad = useCallback(() => {
    const scriptElement = document.createElement('script');
    scriptElement.src = src;
    scriptElement.async = true;

    scriptElement.addEventListener('load', handleLoad);
    scriptElement.addEventListener('error', handleError);

    if (!getScriptElement()) {
      document.body.appendChild(scriptElement);
    }
  }, [getScriptElement, handleError, handleLoad, src]);

  const clearScriptElement = () => {
    const scriptElement = getScriptElement();
    scriptElement !== null && scriptElement.remove();
    setLoading(true);
  };

  return [startLoad, loading, error, clearScriptElement];
};

export default useExternalScript;
