import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Paper from '@ecp/common/src/components/paper/Paper';
import LayerPopup from '@ecp/common/src/components/modal/mo/popup/LayerPopup';

const RegularDeliveryAgreePopup = ({ open, onClose }) => {
  return (
    <LayerPopup
      open={open}
      onClose={onClose}
      onConfirmClick={onClose}
      header={'정기배송 서비스 이용동의'}
      useCancelButton={false}
    >
      <Container>
        <BodyContainer>
          <TitleTextBox>제1조. 정기주문 서비스 상품의 결제 등</TitleTextBox>
          <ContentTextBox>
            ① 정기배송 서비스 상품에 대하여 할인 혜택을 제공할 수 있으며, 할인율 및 거래조건 등은 상품 별로 다를 수 있고
            변경될 수 있습니다.
          </ContentTextBox>
          <ContentTextBox>
            ② 정기배송 서비스의 특성 상 상품의 가격은 계속적으로 변경될 수 있으며, 고객에게 고지된 시점의 상품 가격을
            기준으로 결제가 이루어집니다. 가격 결정의 기준 시점은 변경될 수 있습니다.
          </ContentTextBox>
          <ContentTextBox>
            ③ 정기주문 서비스 상품의 추가 또는 변경 시 결제되는 총 상품의 금액은 변경됩니다.
          </ContentTextBox>
          <ContentTextBox>
            ④ 카드 한도 초과 등으로 상품의 결제가 이루어 지지 않을 경우, 해당 회차의 정기주문은 이루어지지 않을 수
            있습니다.
          </ContentTextBox>
          <TitleTextBox>제2조. 정기주문 서비스 상품의 판매 종료 등</TitleTextBox>
          <ContentTextBox>
            정기주문 대상 상품을 더 이상 판매할 수 없거나 해당 상품을 정기주문 서비스로 제공할 수 없는 사유가 있는 경우,
            해당 상품의 정기주문 서비스를 중지하거나 해당 회차의 상품을 공급하지 않을 수 있습니다.
          </ContentTextBox>
          <TitleTextBox>제3조. 정기주문 서비스의 종료</TitleTextBox>
          <ContentTextBox>
            서비스 종료에 대해 고객에게 해당여부를 통지함으로써 정기배송 서비스를 종료시킬 수 있습니다.
          </ContentTextBox>
          <TitleTextBox>제4조. 정기주문 서비스 이용의 제한</TitleTextBox>
          <ContentTextBox>① 특정상품에 대하여 구매 수량을 제한할 수 있습니다.</ContentTextBox>
          <ContentTextBox>
            ② 상품의 재판매 가능성이 있는 경우, 또는 불법적이거나 부당한 행위와 관련된 경우 서비스 제공을 제한할 수
            있습니다.
          </ContentTextBox>
          <ContentTextBox>③ 서비스 이용의 제한에 대한 구체적인 기준은 내부 운영정책에 따라 적용 됩니다.</ContentTextBox>{' '}
          <ContentTextBox>
            ④ 회사는 자체적인 시스템을 통한 모니터링과 각종 기관에서 접수된 민원 내용, 수사기관의 정보 등을 통해 정황을
            확인한 후, 정기배송 서비스 제한 사유 행위를 한 것으로 추정되는 경우 정기배송 서비스 이용을 제한 할 수
            있습니다
          </ContentTextBox>
          <TitleTextBox>제5조. 기타</TitleTextBox>
          <ContentTextBox>
            ① 고객이 본 약관에 동의함으로써 약관의 효력이 발생하고 정기배송 서비스가 계속되는 동안 본 약관의 효력은
            유지됩니다. 고객이 정기배송 서비스 이용을 시작하고 정기배송 상품을 추가, 변경하는 경우에도 본 약관이 계속
            적용됩니다.
          </ContentTextBox>
          <ContentTextBox>
            ② 정기배송 서비스의 개인정보보호정책은 웰스토리몰의 개인정보처리방침을 준용합니다.
          </ContentTextBox>
          <ContentTextBox>③ 본 약관에서 정하지 아니한 내용은 웰스토리몰의 이용약관에 따릅니다.</ContentTextBox>
          <ContentTextBox>
            ④ 약관을 변경하는 경우에는 기존약관과 개정약관 및 개정약관의 적용일자와 개정사유를 명시하여 현행약관과 함께
            그 적용일자 7일 전부터 적용일 이후 상당한 기간 동안 공지만을 하고, 개정 내용이 고객에게 불리한 경우에는 그
            적용일자 30일 전부터 적용일 이후 상당한 기간 동안 이를 웰스토리몰 홈페이지에 공지하고 고객의 이메일 주소로
            약관 개정 사실을 발송하여 고지합니다.
          </ContentTextBox>
          <ContentTextBox>
            ⑤ 웰스토리몰이 전항에 따라 고객에게 통지하면서 공지 또는 공지∙고지일로부터 개정약관 시행일 7일 후까지
            거부의사를 표시하지 아니하면 이를 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 불구하고 거부의
            의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다. 고객이 개정약관에 동의하지 않을 경우 고객은
            제4조에 따라 정기주문 서비스를 종료시킬 수 있습니다.
          </ContentTextBox>
        </BodyContainer>
        <PaperContainer>
          <PaperTextBox>※ 귀하는 동의를 거부할 권리가 있으며, 동의 거부 시 물품배송이 불가합니다.</PaperTextBox>
        </PaperContainer>
      </Container>
    </LayerPopup>
  );
};

RegularDeliveryAgreePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
  gap: 14px;
`;

const BodyContainer = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
  gap: 10px;
`;

const TitleTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 700;
`;

const ContentTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 350;
  word-break: keep-all;
  overflow-wrap: anywhere;
`;

const PaperContainer = styled(Paper)`
  width: 100%;
  padding: 14px;
  background-color: #f7f7f7;
`;

const PaperTextBox = styled(TextBox)`
  font-size: 12px;
  font-weight: 350;
  color: #5a5a5a;
`;
export default RegularDeliveryAgreePopup;
