import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useScrollDirection from '@ecp/common/src/hooks/useScrollDirection';
import { FlexBox, FlexBoxCenter } from '@ecp/common/src/layouts/flex/styled';
import RoundButton from '@ecp/common/src/components/button/mo/RoundButton';

import { employeeBenefitMessageState, showNavigationMenuState } from '@mo-recoil/common/navigation/atom';
import { ReactComponent as IconBenefitWhite } from '@mo-assets/icon/icon__gnb-benefit--white.svg';

const BenefitCardBanner = ({ onClick }) => {
  const showNavigationMenu = useRecoilValue(showNavigationMenuState);
  const [employeeBenefitMessage, setEmployeeBenefitMessage] = useRecoilState(employeeBenefitMessageState);
  const scrollDirection = useScrollDirection();
  const benetfitBannerDispYN = sessionStorage.getItem('benetfitBannerDispYN');

    const getBottom = useMemo(() => {
    let bottom = 40;

    if (showNavigationMenu) {
      bottom += scrollDirection === 'down' ? 0 : 50;
    }

    return bottom;
  }, [scrollDirection, showNavigationMenu]);

  const handleBenefitCardClose = () => {
    setEmployeeBenefitMessage('');
    sessionStorage.setItem('benetfitBannerDispYN',"N");
  };

  return (
    <>
      {employeeBenefitMessage && benetfitBannerDispYN !== "N" && (
        <Layout bottom={getBottom}>
          <RoundButton
            inversion
            closeable
            width={'320px'}
            iconType={IconBenefitWhite}
            onClick={onClick}
            onClose={handleBenefitCardClose}
            data-ds-label2={'bt_coupon'}
          >
            <FlexBox width={'224px'}>{employeeBenefitMessage}</FlexBox>
          </RoundButton>
        </Layout>
      )}
    </>
  );
};

BenefitCardBanner.propTypes = {
  onClick: PropTypes.func,
};

export default BenefitCardBanner;

const Layout = styled(FlexBoxCenter)`
  height: 34px;
  box-sizing: border-box;

  position: absolute;
  bottom: ${({ bottom }) => `${bottom}px`};
  right: 29px;
  width: 302px;
  transition: bottom 0.3s ease-in-out;
`;
