import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';

import GiftiShowReceiverItem from '@mo-components/order/payment/giftiShow/GiftiShowReceiverItem';

function GiftiShowReceiverList({ orderDeliveryList, handleChangeDeliveryPlaceInfo, ordererInformation }) {
  const handleCheckSameAsOrderer = useCallback(
    (idx, checked) => {
      const newOrderDeliveryList = orderDeliveryList.map((deliveryItem, deliveryIdx) => {
        if (deliveryIdx !== idx) return deliveryItem;
        return {
          ...deliveryItem,
          deliveryPlace: {
            ...deliveryItem.deliveryPlace,
            cellphoneNumber: checked ? ordererInformation?.ordererCellphoneNumber : '',
          },
        };
      });
      handleChangeDeliveryPlaceInfo(newOrderDeliveryList, { refreshAvailableQuantity: false });
    },
    [handleChangeDeliveryPlaceInfo, orderDeliveryList, ordererInformation?.ordererCellphoneNumber]
  );

  const handleChangePhoneNumber = useCallback(
    (idx, cellphoneNumber) => {
      const newOrderDeliveryList = orderDeliveryList.map((deliveryItem, deliveryIdx) => {
        if (deliveryIdx !== idx) return { ...deliveryItem };
        return {
          ...deliveryItem,
          deliveryPlace: {
            ...deliveryItem.deliveryPlace,
            cellphoneNumber,
          },
        };
      });
      handleChangeDeliveryPlaceInfo(newOrderDeliveryList, { refreshAvailableQuantity: false });
    },
    [handleChangeDeliveryPlaceInfo, orderDeliveryList]
  );

  const handleChangeQuantity = useCallback(
    (idx, deliveryQuantity) => {
      const newOrderDeliveryList = orderDeliveryList.map((deliveryItem, deliveryIdx) => {
        if (deliveryIdx !== idx) return { ...deliveryItem };
        return {
          ...deliveryItem,
          goodList: [
            { ...deliveryItem.goodList[0], itemList: [{ ...deliveryItem.goodList[0].itemList[0], deliveryQuantity }] },
          ],
        };
      });
      handleChangeDeliveryPlaceInfo(newOrderDeliveryList);
    },
    [handleChangeDeliveryPlaceInfo, orderDeliveryList]
  );
  return (
    <Container gap={20}>
      {orderDeliveryList.map(
        (
          {
            deliveryPlace: { cellphoneNumber },
            goodList: [
              {
                itemList: [item],
              },
            ],
          },
          idx
        ) => {
          return (
            <GiftiShowReceiverItem
              key={idx}
              isMulti={orderDeliveryList.length > 1}
              cellphoneNumber={cellphoneNumber}
              handleCheckSameAsOrderer={handleCheckSameAsOrderer}
              handleChangePhoneNumber={handleChangePhoneNumber}
              handleChangeQuantity={handleChangeQuantity}
              itemInfo={item}
              idx={idx}
            />
          );
        }
      )}
    </Container>
  );
}

GiftiShowReceiverList.propTypes = {
  orderDeliveryList: PropTypes.array,
  handleChangeDeliveryPlaceInfo: PropTypes.func,
  ordererInformation: PropTypes.object,
};

const Container = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
`;

export default GiftiShowReceiverList;
