export const BULK_ORDER_TITLE =
  '고객의 건강하고 풍요로운 삶을 위한 쇼핑공간\n웰스토리몰과 함께\n더욱 큰 성공을 누리실 수 있습니다.';

export const BULK_ORDER_DESCRIPTION =
  '삼성 웰스토리는 차별화된 서비스로 고객만족을 위해 오늘도 최선을 다하고 있습니다. 대량구매 문의를 통해 웰스토리 몰에서 판매되고 있는 엄선된 최고 품질의 상품들을 더욱 큰 혜택으로 만나 보실 수 있습니다. 아래 신청란에 기재해 주시면 최대한 빠른 시간내에 즉시 응대해 드리도록 하겠습니다.';

export const PRIVACY_POLICY_DESCRIPTION =
  '1. 개인정보의 수집 및 이용 목적\n' +
  '- 대량 구매\n' +
  '2. 수집하는 개인정보의 항목\n' +
  '① 대량구매 문의 서비스 이용 시 수집하는 개인정보의 범위\n' +
  '- 필수 수집항목 : 소속(이름), 전화번호, 특판 문의 내용\n' +
  '② 서비스 이용과정에서 생성, 수집되는 정보\n' +
  '- 서비스 이용기록, 접속로그 등\n' +
  '3. 개인정보의 보유 및 이용기간\n' +
  '- 수집 시점으로부터 3개월 후 일괄 파기';
