import { selector } from 'recoil';

import { cartBadgeCountAtom } from './atom';
import { myDeliveryPlaceListState } from '@ecp/mo/src/recoil/order/atom';

export const withCartBadgeCount = selector({
  key: 'withCartBadgeCount',
  get: ({ get }) => {
    const { count } = get(cartBadgeCountAtom);
    return count;
  },
  set: ({ set }, count) => set(cartBadgeCountAtom, { count }),
});

export const myBaseDeliveryPlace = selector({
  key: 'myBaseDeliveryPlace',
  get: ({ get }) => {
    return get(myDeliveryPlaceListState)?.find(({ base }) => base);
  },
});
