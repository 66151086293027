import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash-es/isEmpty';

import useOrderPayment from '@ecp/common/src/hooks/order/payment/useOrderPayment';
import { ORDER_TYPE } from '@ecp/common/src/const/order/orderConst';

import paymentApi from '@mo-apis/order/paymentApi';
import MobileAutoBillForm from '@mo-pages/interface/inicis/MobileAutoBillForm';
import MobilePayForm from '@mo-pages/interface/inicis/MobilePayForm';
import MobileMobiliansPayForm from '@mo-pages/interface/inicis/MobileMobiliansPayForm';

const OrderPayment = ({ orderType, data, onComplete }) => {
  const { placeOrder, payData, payType } = useOrderPayment({ orderType, isMobile: true, paymentApi });

  useEffect(() => {
    if (!data || isEmpty(data)) return;

    /* 주문 요청 */
    placeOrder(data);
  }, [data, placeOrder]);
  return (
    <>
      {orderType === ORDER_TYPE.REGULAR_DELIVERY ? (
        <MobileAutoBillForm data={payData} />
      ) : (
        <>{payType !== '19' && (
          <MobilePayForm data={payData} payType={payType} onComplete={onComplete} />
        )}
        {payType === '19' && (
          <MobileMobiliansPayForm data={payData} payType={payType} onComplete={onComplete} />
        )}
          </>
      )}
    </>
  );
};

OrderPayment.propTypes = {
  data: PropTypes.object,
  orderType: PropTypes.oneOf(Object.values(ORDER_TYPE)),
  onComplete: PropTypes.func,
};
export default OrderPayment;
