import React, { cloneElement, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useExternalScript from '@ecp/common/src/hooks/useExternalScript';
import Modal from '@ecp/common/src/components/modal/Modal';
import { ReactComponent as TooltipCloseIcon } from '@ecp/common/src/assets/icon/tooltip__close.svg';

const Embed = styled.div`
  width: 500px;
  height: 500px;
`;

const CloseComponent = styled(TooltipCloseIcon)`
  border: none;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  position: inherit;
  top: 13px;
  right: 13px;
  cursor: pointer;
`;

const PostcodeModal = ({
  children,
  onComplete,
  onSearch,
  onResize,
  innerModal = false,
  closeable = true,
  width = '500px',
  height = '500px',
  openModal = false,
  onCloseModal,
  closeWhenOutside = true,
}) => {
  const [startLoad, loading, error, clear] = useExternalScript(
    'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'
  );
  const [embedElement, setEmbedElement] = useState();
  const [open, setOpen] = useState(false);
  const embedCallback = useCallback((elem) => elem !== null && setEmbedElement(elem), []);

  const handleComplete = useCallback(
    (data) => {
      onComplete?.(data);
      setOpen(false);
      clear();
    },
    [clear, onComplete]
  );

  useEffect(() => {
    setOpen(openModal);
    if (openModal) {
      startLoad();
    }
  }, [openModal, startLoad]);

  useEffect(() => {
    if (!loading) {
      new daum.Postcode({
        oncomplete: handleComplete,
        onsearch: onSearch,
        onresize: onResize,
        width: '100%',
        height: '100%',
        useBannerLink: false,
      }).embed(embedElement, { autoClose: false });
    }
  }, [embedElement, handleComplete, loading, onResize, onSearch]);

  return (
    <Modal
      open={open}
      onOpenChange={(v) => setOpen(v)}
      closeable={closeable}
      closeComponent={
        <CloseComponent
          onClick={() => {
            setOpen(false);
            onCloseModal?.(false);
            clear();
          }}
        />
      }
      innerModal={innerModal}
      closeWhenOutside={closeWhenOutside}
      value={
        <Embed style={{ width: width, height: height }} ref={embedCallback}>
          {error && (
            <div>
              현재 kakao 주소 api를 이용할 수 없습니다. 잠시 후에 다시 시도해주세요.{' '}
              {JSON.stringify(error, ['message', 'arguments', 'type', 'name'])}
            </div>
          )}
        </Embed>
      }
    >
      {children &&
        cloneElement(children, {
          ...children.props,
          onClick: () => {
            const { onClick } = children.props;
            onClick?.();
            startLoad();
          },
        })}
    </Modal>
  );
};

PostcodeModal.propTypes = {
  children: PropTypes.element,
  onComplete: PropTypes.func,
  onSearch: PropTypes.func,
  onResize: PropTypes.func,
  innerModal: PropTypes.bool,
  closeable: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  openModal: PropTypes.bool,
  onCloseModal: PropTypes.func,
  closeWhenOutside: PropTypes.bool,
};

export default PostcodeModal;
