import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { textEllipsis } from '@ecp/common/src/style/theme/defaultStyled';
import { FlexBox, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import AccordionUnstyled, {
  AccordionDefaultProps,
  AccordionPropTypes,
} from '@ecp/common/src/components/unstyled/accordion/AccordionUnstyled';
import AccordionBodyUnstyled, {
  AccordionBodyPropTypes,
} from '@ecp/common/src/components/unstyled/accordion/AccordionBodyUnstyled';
import AccordionHeaderUnstyled, {
  AccordionHeaderDefaultProps,
  AccordionHeaderPropTypes,
} from '@ecp/common/src/components/unstyled/accordion/AccordionHeaderUnstyled';
import AccordionTitleUnstyled, {
  AccordionTitlePropTypes,
} from '@ecp/common/src/components/unstyled/accordion/AccordionTitleUnstyled';
import AccordionControlUnstyled, {
  AccordionControlDefaultProps,
  AccordionControlPropTypes,
} from '@ecp/common/src/components/unstyled/accordion/AccordionControlUnstyled';
import { useAccordionContext } from '@ecp/common/src/components/unstyled/accordion/useAccordionContext';
import { AccordionTextBox } from '@ecp/common/src/components/accordion/mo/AccordionTextBox';
import { AccordionTag } from '@ecp/common/src/components/accordion/mo/AccordionTag';
import StarRating from '@ecp/common/src/components/icon/StarRating';
import { TextButton } from '@ecp/common/src/components/button/TextButton';
import useAccordionHeaderControl from '@ecp/common/src/components/accordion/useAccordionHeaderControl';
import { ReactComponent as IconOpen } from '@ecp/common/src/assets/icon/icon__arrow--below.svg';
import { ReactComponent as LockIcon } from '@ecp/common/src/assets/icon/icon__lock.svg';
import AccordionQnAControlUnstyled, {
  AccordionQnAControlDefaultProps,
  AccordionQnAControlPropTypes,
} from '@ecp/common/src/components/unstyled/accordion/AccordionQnAControlUnstyled';

const StyledAccordion = styled.div.attrs(
  ({ backgroundColor = theme.color.background.white, width = '100%', style }) => ({
    style: {
      ...style,
      backgroundColor,
      width,
    },
  })
)``;

const Accordion = ({ children, ...props }) => (
  <AccordionUnstyled rootComponent={StyledAccordion} {...props}>
    {children}
  </AccordionUnstyled>
);

Accordion.propTypes = AccordionPropTypes;
Accordion.defaultProps = AccordionDefaultProps;

const StyledAccordionHeader = styled(FlexBoxSpaceBetween)`
  width: 100%;
  height: 21px;
`;

const AccordionHeader = ({ children }) => (
  <AccordionHeaderUnstyled rootComponent={StyledAccordionHeader}>{children}</AccordionHeaderUnstyled>
);

AccordionHeader.propTypes = AccordionHeaderPropTypes;
AccordionHeader.defaultProps = AccordionHeaderDefaultProps;

const StyledAccordionTitle = styled(FlexBox)`
  width: 100%;
  height: fit-content;
`;

const AccordionTitle = ({ children }) => (
  <AccordionTitleUnstyled rootComponent={StyledAccordionTitle}>{children}</AccordionTitleUnstyled>
);

AccordionTitle.propTypes = AccordionTitlePropTypes;

const StyledAccordionBody = styled(FlexBox)`
  width: 100%;
  height: fit-content;
`;

const AccordionBody = ({ children }) => (
  <AccordionBodyUnstyled rootComponent={StyledAccordionBody}>{children}</AccordionBodyUnstyled>
);

AccordionBody.propTypes = AccordionBodyPropTypes;

const StyledAccordionControl = styled(FlexBox)`
  max-width: 100%;
  min-height: 19px;
  width: 100%;

  align-items: flex-start;
  justify-content: ${(props) => props['justify-content']};
`;

const AccordionControl = ({ children, question, buttonPadding = 0, ...props }) => (
  <AccordionControlUnstyled
    rootComponent={StyledAccordionControl}
    buttonElement={
      <FlexBox padding={buttonPadding}>
        <IconOpen width={'10px'} height={'7px'} fill={'#5A5A5A'}/>
      </FlexBox>
    }
    {...props}
  >
    {question && (
      <>
        <TextBox size={moTheme.font.size.$16} weight={moTheme.font.weight.medium}>
          Q
        </TextBox>
        <Spacing right={10} />
      </>
    )}
    {children}
  </AccordionControlUnstyled>
);

AccordionControl.propTypes = {
  ...AccordionControlPropTypes,
  'justify-content': PropTypes.string,
  buttonPadding: PropTypes.string,
};

AccordionControl.defaultProps = {
  ...AccordionControlDefaultProps,
  'justify-content': 'space-between',
};

const HeaderGuideWrapper = styled(FlexBox)`
  width: fit-content;
  height: 12px;
`;

const StarRateWrapper = styled(FlexBox)`
  font-size: ${moTheme.font.size.$12};
  font-weight: 500;
  color: ${theme.color.text.basic};
`;

const AccordionHeaderGuide = ({ rate, lock, mine, children }) => (
  <HeaderGuideWrapper>
    {rate && (
      <>
        <StarRating maxRate={5} rating={rate} size={12} />
        <Spacing left={5} />
        <StarRateWrapper>{rate.toFixed?.(1)}</StarRateWrapper>
        <Spacing left={5} />
      </>
    )}
    {lock && (
      <>
        <LockIcon width={'15px'} height={'18px'} />
        <Spacing left={6} />
        {!mine && (
          <>
            <AccordionTextBox type={'basic'}>비밀글 입니다.</AccordionTextBox>
            <Spacing left={5} />
          </>
        )}
      </>
    )}
    {!lock && children}
  </HeaderGuideWrapper>
);

AccordionHeaderGuide.propTypes = {
  rate: PropTypes.number,
  lock: PropTypes.bool,
  mine: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
AccordionHeaderGuide.defaultProps = {
  lock: false,
  mine: false,
};

const HeaderControlWrapper = styled(FlexBox)`
  width: fit-content;
  height: 12px;
`;

const AccordionHeaderControl = ({ children, onDeleteClick, onRequestClick }) => {
  const { handleClick, label, customIcon } = useAccordionHeaderControl({ onDeleteClick, onRequestClick });

  return (
    <HeaderControlWrapper>
      {children}
      <Spacing left={10} />
      <TextButton
        type={'custom-left'}
        textProps={{
          size: moTheme.font.size.$12,
          weight: moTheme.font.weight.demiLight,
          height: moTheme.font.lineHeight.demiLight,
          color: theme.color.text.guide,
        }}
        customIcon={customIcon}
        onClick={handleClick}
      >
        {label}
      </TextButton>
    </HeaderControlWrapper>
  );
};

AccordionHeaderControl.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onDeleteClick: PropTypes.func,
  onRequestClick: PropTypes.func,
};

const ControlQuestionWrapper = styled(FlexBox).attrs(({ open, style }) => ({
  style: {
    ...style,
    height: open ? 'fit-content' : '22px',
  },
}))`
  width: fit-content;
  align-items: flex-start;
`;

const QuestionMark = styled(FlexBox)`
  height: 22px;
  font-size: ${moTheme.font.size.$16};
  font-weight: ${moTheme.font.weight.medium};
  color: ${theme.color.text.basic};
`;

const QuestionWrapper = styled.div`
  width: 270px;

  font-size: ${moTheme.font.size.$14};
  font-weight: ${({ open }) => (open ? moTheme.font.weight.medium : moTheme.font.weight.demiLight)};
  color: ${theme.color.text.basic};
  line-height: ${moTheme.font.lineHeight.demiLight};

  ${({ open }) => !open && textEllipsis};
`;

const AccordionControlQuestion = ({ children }) => {
  const { open } = useAccordionContext();

  return (
    <ControlQuestionWrapper open={open}>
      <QuestionMark aria-label={'질문'}>Q</QuestionMark>
      <Spacing left={10} />
      <QuestionWrapper open={open}>{children}</QuestionWrapper>
    </ControlQuestionWrapper>
  );
};

AccordionControlQuestion.propTypes = {
  children: PropTypes.node,
};

const ControlBodyWrapper = styled(FlexBox).attrs(({ open, style }) => ({
  style: {
    ...style,
    height: open ? 'fit-content' : '37px',
  },
}))`
  width: fit-content;
  align-items: flex-start;
`;

const ControlBody = styled(FlexBox).attrs(({ open, style }) => ({
  style: {
    ...style,
    width: open ? '100%' : 'calc(100% - 30px)',
  },
}))`
  flex-wrap: wrap;

  font-size: ${moTheme.font.size.$14};
  font-weight: ${moTheme.font.weight.demiLight};
  color: ${theme.color.text.basic};
  line-height: ${moTheme.font.lineHeight.demiLight};

  word-wrap: break-word;
  ${({ open }) => !open && textEllipsis};
`;

const AccordionControlBody = ({ children }) => {
  const { open } = useAccordionContext();

  return (
    <ControlBodyWrapper open={open}>
      <ControlBody open={open}>{children}</ControlBody>
    </ControlBodyWrapper>
  );
};

AccordionControlBody.propTypes = {
  children: PropTypes.node,
};

const AccordionClickable = ({ rootComponent: Clickable, rootStyle: clickableStyle, children }) => {
  const { handleOpenClick } = useAccordionContext();
  return (
    <Clickable onClick={handleOpenClick} style={clickableStyle}>
      {children}
    </Clickable>
  );
};

AccordionClickable.propTypes = {
  rootComponent: PropTypes.elementType,
  rootStyle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

AccordionClickable.defaultProps = {
  rootStyle: {},
};

const AccordionQnAClickable = ({ rootComponent: Clickable, rootStyle: clickableStyle, children, type }) => {
  const { open, handleOpenClick } = useAccordionContext();
  return (
    <Clickable onClick={handleOpenClick} style={clickableStyle} type={type} title={open ? '접기' : '펼쳐보기'}>
      {children}
    </Clickable>
  );
};

AccordionQnAClickable.propTypes = {
  rootComponent: PropTypes.elementType,
  rootStyle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  type: PropTypes.string,
  title: PropTypes.string,
};

AccordionQnAClickable.defaultProps = {
  rootStyle: {},
};

const AccordionQnAControl = ({ children, buttonPadding = 0, ...props }) => (
  <AccordionQnAControlUnstyled
    buttonElement={
      <FlexBox padding={buttonPadding}>
        <IconOpen width={'10px'} height={'7px'} fill={'#5A5A5A'} />
      </FlexBox>
    }
    {...props}
  >
    {children}
  </AccordionQnAControlUnstyled>
);

AccordionQnAControl.propTypes = {
  ...AccordionQnAControlPropTypes,
  'justify-content': PropTypes.string,
  buttonPadding: PropTypes.string,
};

AccordionQnAControl.defaultProps = {
  ...AccordionQnAControlDefaultProps,
  'justify-content': 'space-between',
};

Accordion.Header = AccordionHeader;
Accordion.HeaderGuide = AccordionHeaderGuide;
Accordion.HeaderControl = AccordionHeaderControl;
Accordion.Title = AccordionTitle;
Accordion.Control = AccordionControl;
Accordion.ControlQuestion = AccordionControlQuestion;
Accordion.ControlBody = AccordionControlBody;
Accordion.Body = AccordionBody;

Accordion.TextBox = AccordionTextBox;
Accordion.Tag = AccordionTag;
Accordion.Clickable = AccordionClickable;

Accordion.QnA = AccordionQnAControl;
Accordion.QnAClickable = AccordionQnAClickable;


export default Accordion;
