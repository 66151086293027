const font = {
  size: {
    $10: '10px',
    $11: '11px',
    $12: '12px',
    $13: '13px',
    $14: '14px',
    $15: '15px',
    $16: '16px',
    $18: '18px',
    $19: '19px',
    $20: '20px',
    $22: '22px',
    $24: '24px',
    $25: '25px',
    $28: '28px',
  },
  weight: {
    demiLight: 350,
    medium: 500,
    bold: 700,
  },
  lineHeight: {
    demiLight: '160%',
    medium: '140%',
    bold: '100%',
  },
};

export const moTheme = {
  font,
};

export default moTheme;
