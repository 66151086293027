import { addDays, format, parseISO, isValid, startOfToday, sub } from 'date-fns';

export const nextDayExcludingWeekends = (date) => {
  let nextDay = addDays(date, 1);
  while (nextDay.getDay() === 0 || nextDay.getDay() === 6) {
    nextDay = addDays(nextDay, 1);
  }
  // 공휴일 처리 추가 필요
  return nextDay;
};

export const formatDateTime = (dateTimeString, formatString = 'yyyy.MM.dd HH:mm:ss') => {
  const parsedDateTime = parseISO(dateTimeString);

  if (isValid(parsedDateTime)) {
    return format(parsedDateTime, formatString);
  }
  return '';
};

export const periodFromDate = (duration) => {
  return addDays(sub(startOfToday(), duration), 1);
};
