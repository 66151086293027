import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import clsx from 'clsx';

import useIgnorableError from '@ecp/common/src/hooks/useIgnorableError';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import CalendarUnstyled from '@ecp/common/src/components/unstyled/calendar/CalendarUnstyled';
import useCalendar from '@ecp/common/src/components/calendar/useCalendar';
import { calendarClassNames, CalenderPropTypes, StyledCalendar } from '@ecp/common/src/components/calendar/Calendar';

const StyledMoCalendar = styled(StyledCalendar)`
  .react-datepicker {
    padding: 16px 10px 6px 10px;

    &__month {
      width: 243px;
    }

    &__current-month {
      border-radius: 36px;
      width: 134px;
      height: 36px;
      margin: 0 20px;
      font-size: ${moTheme.font.size.$16};
    }

    &__week {
      width: 226px;
    }

    &__day {
      width: 34px;
      height: 34px;
      line-height: 34px;
      font-size: ${moTheme.font.size.$11};

      &-name {
        width: 34px;
        height: 34px;
        line-height: 34px;
        font-size: ${moTheme.font.size.$12};
        font-weight: ${moTheme.font.weight.bold};
      }

      &-names {
        border-top: solid 1px ${theme.color.line.emphasis};
        margin-top: 10px;
        width: 226px;
      }

      &--selected {
        &:hover {
          background: ${theme.color.primary};
        }
      }
    }
  }

  .react-datepicker__input-container {
    height: 36px;
    width: 122px;

    ${({ inputStyle }) => inputStyle};

    input {
      padding: 0 27px 0 10px;
      font-size: ${moTheme.font.size.$13};
      font-weight: ${moTheme.font.weight.demiLight};
      background-position: top 6px right 7px;
    }
  }

  &.${calendarClassNames.error} {
    & input {
      border: 1px solid ${theme.color.text.error};
    }
  }

  &.${calendarClassNames.readOnly} {
    pointer-events: none;
  }
`;

const InlineStyledCalendar = styled(StyledMoCalendar)`
  .react-datepicker {
    border: none;
    padding: 0;

    &__header {
    }

    &__month {
      border-bottom: 1px solid ${theme.color.line.basic};
      margin: 0;
      width: 320px;
      padding: 10px 0;
    }

    &__current-month {
      width: 160px;
    }

    &__week {
      width: 320px;
      padding: 0 6px;
    }

    &__day {
      width: 30px;
      height: 30px;
      line-height: 30px;
      flex-shrink: 0;
      margin: auto;
      font-size: ${moTheme.font.size.$12};

      &-name {
        width: 30px;
        height: 30px;
        line-height: 30px;
        flex-shrink: 0;
        margin: auto;
      }

      &-names {
        border-top: solid 2px ${theme.color.line.emphasis};
        border-bottom: solid 1px ${theme.color.line.basic};
        margin-top: 10px;
        justify-content: space-evenly;
        width: 320px;
        padding: 0 6px;
      }
    }
  }

  .custom {
    &-sunday {
      color: ${theme.color.text.error};
      border-radius: 50%;
      background: none;
    }

    &-attendance {
      pointer-events: none;
      width: 40px;
      height: 56px;

      &--today {
        pointer-events: none;
        background: ${theme.color.background.sub1};
        color: ${theme.color.primary};
        border: none;
      }

      &--checked {
        display: flex;
        pointer-events: none;
      }
    }
  }

  &.${calendarClassNames.error} {
    & input {
      border: 1px solid ${theme.color.text.error};
    }
  }

  &.${calendarClassNames.readOnly} {
    pointer-events: none;
  }
`;

const StyledErrorMessage = styled(TextBox)`
  margin-top: 6px;
  color: ${theme.color.text.error};
  font-size: ${moTheme.font.size.$12};
  line-height: 19px;
`;

const Calendar = ({ inputStyle, error, errorMessage, ...props }) => {
  const { value, readOnly } = props;
  const { ignoreError } = useIgnorableError({ error, value });
  const stateClasses = {
    [calendarClassNames.error]: error,
    [calendarClassNames.readOnly]: readOnly,
  };
  const StyledDefaultCalendar = props.inline ? InlineStyledCalendar : StyledMoCalendar;
  return (
    <FlexBoxColumn align-items={'flex-start'} width={'100%'}>
      <StyledDefaultCalendar inputStyle={inputStyle} className={clsx(stateClasses)}>
        <CalendarUnstyled {...props} error={!ignoreError && error} />
      </StyledDefaultCalendar>
      {!ignoreError && error && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </FlexBoxColumn>
  );
};

Calendar.propTypes = {
  ...CalenderPropTypes,
};

Calendar.defaultProps = {
  inline: true,
  inputStyle: {},
};

export const CalendarRange = ({
  startDate,
  endDate,
  inputStyle,
  startMinDate,
  onStartChange,
  onEndChange,
  onAllDateChange,
  error,
  errorMessage,
  ...props
}) => {
  const { ignoreError } = useIgnorableError({ error, value: { startDate, endDate } });
  const { handleStartChange, handleEndChange } = useCalendar({
    startMinDate,
    startDate,
    endDate,
    onStartChange,
    onEndChange,
    onAllDateChange,
  });

  return (
    <FlexBox width={'100%'}>
      <FlexBox height="36px">
        <Calendar
          selectsStart
          inline={false}
          {...props}
          value={startDate}
          startDate={startDate}
          endDate={endDate}
          minDate={startMinDate}
          maxDate={endDate}
          onChange={handleStartChange}
          inputStyle={inputStyle}
          error={!ignoreError && error}
          dateGb={'start'}
        />
        <Spacing left="4px" right="4px">
          <TextBox height="36px" line-height="36px">
            ~
          </TextBox>
        </Spacing>
        <Calendar
          selectsEnd
          inline={false}
          {...props}
          value={endDate}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          onChange={handleEndChange}
          inputStyle={inputStyle}
          error={!ignoreError && error}
          dateGb={'end'}
        />
      </FlexBox>
      {!ignoreError && error && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </FlexBox>
  );
};

CalendarRange.propTypes = {
  ...CalenderPropTypes,
  direction: PropTypes.oneOf(['row', 'column']),
  startMinDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onStartChange: PropTypes.func,
  onEndChange: PropTypes.func,
};

CalendarRange.defaultProps = {
  inputStyle: {},
};

export default Calendar;
