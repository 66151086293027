import { getRequest, postRequest } from '@mo-apis/axios';

const getMobiliansInformation = () => {
  return getRequest({
    url: '/api/v1/interfaces/mobilians/information',
  });
};

const getMobiliansResut = (data) => {
  return postRequest({
    url: '/api/v1/interfaces/mobilians/okUrl',
    data: { ...data },
  });
};

const checkMobiliansForged = (data) =>
  postRequest({
    url: '/api/v1/interfaces/mobilians/isForged',
    data: { ...data },
  });

const getMobiliansHpInformtion = (data) => {
  return postRequest({
    url: '/api/v1/interfaces/mobilians/mobile',
    data: { ...data },
  })
}

const mobiliansApi = {
  getMobiliansInformation,
  checkMobiliansForged,
  getMobiliansResut,
  getMobiliansHpInformtion,
};

export default mobiliansApi;
