import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import useAssetPoint from '@ecp/common/src/hooks/asset/useAssetPoint';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

import { getIsMultimall } from '@mo-recoil/system/selector';
import myAssetPointApi from '@mo-apis/myPage/myAssetPointApi';
import couponApi from '@mo-apis/coupon/couponApi';
import MyPageMembershipBadge from '@mo-components/myPage/member/MyPageMembershipBadge';

const MembershipInfo = ({ gradeInfo, infoChanged, onClose }) => {
  const [assetSummary, setAssetSummary] = useState([]);
  const [myCouponCount, setMyCouponCount] = useState(0);

  const isMultimall = useRecoilValue(getIsMultimall);

  const { getSortedAssetSummary, getAssetUnit, createMoveAssetPointClickHandler } = useAssetPoint({
    myCouponCount,
  });

  const handleMembershipInfoClick = useCallback(
    (assetResourceDivisionCode) => {
      createMoveAssetPointClickHandler(assetResourceDivisionCode)();
      onClose();
    },
    [createMoveAssetPointClickHandler, onClose]
  );

  const AssetCard = ({ style, idx }) => (
    <FlexBoxSpaceBetween
      style={style}
      onClick={() => handleMembershipInfoClick(assetSummary[idx]?.assetResourceDivisionCode)}
    >
      <TextBox size={12} weight="350" height="140%">
        {assetSummary[idx]?.assetDivisionName}
      </TextBox>
      <FlexBox>
        <TextBox size={14} weight="700" height="140%">
          {NumberFormat(assetSummary[idx]?.assetBalance ?? 0)}
        </TextBox>
        <Spacing right={4} />
        <TextBox size={14} weight="350" height="140%">
          {getAssetUnit(assetSummary[idx]?.assetResourceDivisionCode)}
        </TextBox>
      </FlexBox>
    </FlexBoxSpaceBetween>
  );

  AssetCard.propTypes = {
    style: PropTypes.object,
    idx: PropTypes.number,
  };

  const getMySummary = useCallback(async () => {
    const { result } = await myAssetPointApi.summary();
    setAssetSummary(getSortedAssetSummary(result));
  }, [getSortedAssetSummary]);

  const getMyCouponCount = useCallback(async () => {
    const { result } = await couponApi.getMyCouponListCount();
    setMyCouponCount(result);
  }, []);

  useEffect(() => {
    (async () => {
      await Promise.all([getMySummary(), getMyCouponCount()]);
    })();
  }, [getMyCouponCount, getMySummary, infoChanged]);

  return (
    <FlexBoxColumn width={'100%'}>
      {!isMultimall && (
        <GradeBadge>
          <MyPageMembershipBadge isSmall gradeName={gradeInfo?.memberGradeName ?? 'SILVER'} />
          <TextBox size={14} weight="700">
            {gradeInfo?.memberGradeName ?? 'SILVER'} 등급
          </TextBox>
        </GradeBadge>
      )}
      <Spacing top={20} />
      <Container>
        <AssetCategory>
          <FlexBox style={{ paddingBottom: '15px', borderBottom: '1px solid #eaeaea' }} width={'100%'}>
            <AssetCard style={{ width: '50%', paddingRight: '15px' }} idx={0} />
            <AssetCard style={{ width: '50%', paddingLeft: '15px', borderLeft: '1px solid #eaeaea' }} idx={1} />
          </FlexBox>
          <AssetCard style={{ width: '100%', padding: '15px 0', borderBottom: '1px solid #eaeaea' }} idx={2} />
          <AssetCard style={{ width: '100%', padding: '15px 0', borderBottom: '1px solid #eaeaea' }} idx={3} />
        </AssetCategory>
      </Container>
    </FlexBoxColumn>
  );
};

const Container = styled(FlexBox)`
  width: 260px;
  flex-wrap: wrap;
`;

const AssetCategory = styled(FlexBoxColumn)`
  width: 260px;
  cursor: pointer;
`;

const GradeBadge = styled(FlexBox)`
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default MembershipInfo;

MembershipInfo.propTypes = {
  gradeInfo: PropTypes.object,
  infoChanged: PropTypes.bool,
  onClose: PropTypes.func,
};
