import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { openWindow } from '@ecp/common/src/utils/utils';
import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { SwiperContents, SwiperContentsDefaultProps } from '@ecp/common/src/components/swiper/mo/SwiperTemplate';

import { withMobileStatus } from '@mo-recoil/system/selector';

const Menu = ({ item }) => {
  const { link, title, target, bold, dot, ...props } = item;
  const { pathname } = useLocation();
  const { isMobileApp, agentType } = useRecoilValue(withMobileStatus);

  const active = useMemo(() => pathname === link, [link, pathname]);

  const handleMenuClick = useCallback(
    (e) => {
      if (!link) {
        e.stopPropagation();
        e.preventDefault();
      } else {
        openWindow(isMobileApp, link, target, agentType);
      }
    },
    [agentType, isMobileApp, link, target]
  );

  return (
    <SmallMenuButton
      $active={active}
      $bold={bold}
      $dot={dot}
      link={link}
      onClick={handleMenuClick}
      title={dot ? '최신내용이 있습니다.' : undefined}
      {...props}
      {...(active ? { 'aria-selected': 'true' } : {})}
    >
      {title}
    </SmallMenuButton>
  );
};

Menu.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
  bold: PropTypes.bool,
  dot: PropTypes.bool,
};

const RedDot = css`
  &::after {
    content: '';
    position: absolute;
    right: -6px;
    top: 0;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: ${theme.color.point.negative};
  }
`;

const SmallMenuButton = styled.a.attrs({ href: '#'})`
  position: relative;
  height: 25px;
  min-width: fit-content;
  padding: 0;
  font-size: ${moTheme.font.size.$14};
  font-weight: ${moTheme.font.weight.medium};
  line-height: 22px;
  background-color: transparent;
  box-sizing: content-box;
  border: unset;
  text-decoration: none;
  cursor: ${({ link }) => (link ? 'pointer' : 'default')};

  //text-decoration: ${({ $active }) => $active && `underline ${theme.color.primary} solid 5px`};
  text-decoration-line: ${({ $active }) => $active && `underline`};
  text-decoration-style: ${({ $active }) => $active && `solid`};
  text-decoration-color: ${({ $active }) => $active && `${theme.color.primary}`};
  text-decoration-thickness: ${({ $active }) => $active && `8px`};
  -webkit-text-decoration-line: ${({ $active }) => $active && `underline`};
  // border-bottom: ${({ $active }) => $active && `5px solid ${theme.color.primary}`};
  color: ${({ $active, color = theme.color.text.basic }) => ($active ? theme.color.text.selected : color)};
  font-weight: ${({ $bold, $active }) =>
    $bold ? moTheme.font.weight.bold : $active ? moTheme.font.weight.medium : 400};
  ${({ $dot }) => $dot && RedDot};

  &:not(:first-of-type) {
    margin-left: 22px;
  }
`;

Menu.propTypes = {
  item: PropTypes.object,
};

Menu.defaultProps = {
  item: {
    button: FlexBox,
    link: '',
    title: '',
  },
};

const MenuGroups = ({ gnbMenuList }) => (
  <SwiperContents
    data-ds-label2={'g_menu'}
    itemComponent={Menu}
    itemList={gnbMenuList}
    itemProps={{ width: 'auto' }}
    carouselProps={{
      ...SwiperContentsDefaultProps.carouselProps,
      navigation: true,
      spaceBetween: 14,
      slidesOffsetAfter: 41,
    }}
  />
);

export default MenuGroups;

MenuGroups.propTypes = {
  gnbMenuList: PropTypes.array,
};
