import React from 'react';
import PropTypes from 'prop-types';

import { useAccordionContext } from '@ecp/common/src/components/unstyled/accordion/useAccordionContext';

const AccordionTitleUnstyled = (props) => {
  const { children, rootComponent: AccordionTitle } = props;

  const { open } = useAccordionContext();

  return <>{!open && <AccordionTitle>{children}</AccordionTitle>}</>;
};

export default AccordionTitleUnstyled;

export const AccordionTitlePropTypes = {
  rootComponent: PropTypes.elementType,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

AccordionTitleUnstyled.propTypes = AccordionTitlePropTypes;

AccordionTitleUnstyled.defaultProps = {};
