import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Accordion from '@ecp/common/src/components/accordion/mo/Accordion';

import { getOrderItemCount } from '@mo-utils/orderUtils';
import OrderGoodItemInformationMulti from '@mo-components/order/payment/OrderGoodItemInformationMulti';
import OrderValidContext from '@mo-components/order/payment/OrderValidContext';

const OrderGoodItemListMulti = ({ orderDelivery, deliveryPlaceIndex, handleChangeQuantity }) => {
  const { deliveryDisableList } = useContext(OrderValidContext);
  const { goodList, deliveryPlace, totalDeliveryCost } = orderDelivery;
  const itemTypeCount = useMemo(() => {
    return getOrderItemCount(goodList);
  }, [goodList]);

  useEffect(() => {
    orderDelivery.goodList.forEach((good) => {
      good.itemList?.forEach((item) => {
        const { goodNumber, itemList } = good;
        const { itemNumber, deliveryQuantity, additionalItem } = item;
        if (additionalItem) {
          const isOrderItem = itemList?.find(
            ({ additionalItem, deliveryQuantity }) => !additionalItem && deliveryQuantity > 0
          );
          if (!isOrderItem && deliveryQuantity > 0) {
            handleChangeQuantity(deliveryPlaceIndex, goodNumber, itemNumber, 0);
          }
        }
      });
    });
  }, [deliveryPlaceIndex, orderDelivery.goodList, handleChangeQuantity]);

  const itemList = useMemo(() => {
    return goodList
      .map((goodItem) => {
        return goodItem.itemList.map((item) => {
          const { additionalItem } = item;
          let { availableQuantity } = item;
          const deliveryDisable =
            deliveryDisableList &&
            deliveryDisableList.includes(`${deliveryPlace?.postNumber}-${goodItem.goodNumber}-${item.itemNumber}`);
          if (additionalItem) {
            const isOrderItem = goodItem.itemList.find(
              ({ additionalItem, deliveryQuantity }) => !additionalItem && deliveryQuantity > 0
            );
            if (!isOrderItem) {
              availableQuantity = 0;
            }
          }
          return (
            <OrderGoodItemInformationMulti
              key={`${goodItem.goodName}-${item.itemName}`}
              deliveryPlaceIndex={deliveryPlaceIndex}
              handleChangeQuantity={handleChangeQuantity}
              goodInfo={goodItem}
              itemInfo={{ ...item, availableQuantity }}
              {...(deliveryDisable ? { errorMessage: '제주, 도서산간 등 배송 불가' } : {})}
            />
          );
        });
      })
      .flat();
  }, [deliveryPlaceIndex, goodList, handleChangeQuantity, deliveryPlace, deliveryDisableList]);

  return (
    <Accordion initialOpen>
      <Accordion.Header>
        <HeaderTextContainer>
          <HeaderTextBox>주문 상품 </HeaderTextBox>
          <HeaderItemCountTextBox> {itemTypeCount}건</HeaderItemCountTextBox>
        </HeaderTextContainer>
        <FlexBox height={'26px'}>
          <DeliveryCostTextBox>배송비 {NumberFormat(totalDeliveryCost || 0)}원</DeliveryCostTextBox>
          <Spacing left={'14px'} />
          <FlexBox>
            <Accordion.Control buttonPadding="5px 0 0 0" />
          </FlexBox>
        </FlexBox>
      </Accordion.Header>
      <Spacing top={'10px'} />
      <HorizontalDivider color={'#D3D3D3'} />
      <Spacing top={'20px'} />
      <Accordion.Body undead>
        <ItemListContainer gap={'14px'}>{itemList}</ItemListContainer>
      </Accordion.Body>
    </Accordion>
  );
};

export default OrderGoodItemListMulti;
OrderGoodItemListMulti.propTypes = {
  orderDelivery: PropTypes.object,
  deliveryPlaceIndex: PropTypes.number,
  handleChangeQuantity: PropTypes.func,
};

const HeaderTextContainer = styled(FlexBox)``;

const HeaderTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 500;
`;
const HeaderItemCountTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 500;
  color: ${theme.color.primary};
`;

const DeliveryCostTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 350;
`;

const ItemListContainer = styled(FlexBoxColumn)`
  width: 100%;
  align-items: center;
`;
