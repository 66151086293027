import { selector } from 'recoil';

import { intervalToDuration } from 'date-fns';

import { userInfoState } from '@mo-recoil/common/user/atom';

export const withAdultCertification = selector({
  key: 'withAdultCertification',
  get: ({ get }) => {
    const userInfo = get(userInfoState);
    if (userInfo?.adultCertificationDate) {
      const { years } = intervalToDuration({
        start: new Date(userInfo?.adultCertificationDate),
        end: new Date(),
      });
      return years < 1;
    }
    return false;
  },
});
