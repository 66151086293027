import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { getAccessToken, getRefreshToken } from '@ecp/common/src/utils/utils';

import UserInfoProvider from '@mo-components/common/provider/UserInfoProvider';

const ProtectedRoutes = () => {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();

  const { pathname } = useLocation();

  return accessToken && refreshToken ? (
    <UserInfoProvider>
      <Outlet />
    </UserInfoProvider>
  ) : (
    <Navigate to="/login" state={{ prev: pathname, current: '/login' }} />
  );
};

export default ProtectedRoutes;
