import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { PD_GOOD_TYPE } from '@ecp/common/src/const/good';
import { ORDER_TYPE } from '@ecp/common/src/const/order/orderConst';

import { concatPhoneNumbers, getGoodListWithDetail, getPointUsage } from '@mo-utils/orderUtils';
import { userInfoState } from '@mo-recoil/common/user/atom';
import { useHideFooter } from '@mo-hooks/common/useFooter';
import { useAlertDialog } from '@mo-hooks/common/useModal';
import { useHideNavigation } from '@mo-hooks/common/useNavigation';
import orderApi from '@mo-apis/order/orderApi';
import GiftiShowOrderPage from '@mo-pages/order/payment/GiftiShowOrderPage';
import PresentOrderPage from '@mo-pages/order/payment/PresentOrderPage';
import RegularDeliveryOrderPage from '@mo-pages/order/payment/RegularDeliveryOrderPage';
import StandardOrderPage from '@mo-pages/order/payment/StandardOrderPage';
import BulkpOrderPage from '@mo-pages/order/payment/BulkpOrderPage';
import TicketOrderPage from '@mo-pages/order/payment/TicketOrderPage';
import ParkPointOrderPage from '@mo-pages/order/payment/ParkPointOrderPage';

const OrderPage = () => {
  useHideFooter();
  useHideNavigation(56);
  const userInfo = useRecoilValue(userInfoState);
  const pageParam = useLocation().state || {};
  const [orderPageInfo, setOrderPageInfo] = useState();
  const { isPresent = false, cartNoList, regularDelivery, isBulkpOrder = false } = pageParam;
  const { showAlertDialog } = useAlertDialog();
  const navigate = useNavigate();

  useEffect(() => {
    if (cartNoList) {
      (async () => {
        const { result, code, message } = await orderApi.getOrderPageInfo(cartNoList, isPresent, isBulkpOrder);
        if (code) {
          showAlertDialog(message, () => navigate('/'));
          return;
        }
        const { goodItemDetailList, myAssetPointSummary, myAssetWelpointList } = result;
        const goodListWithDetail = getGoodListWithDetail(goodItemDetailList);
        const { memberNumber, userName, cellphoneNumber1, cellphoneNumber2, cellphoneNumber3, email } = userInfo;
        const ordererInformation = {
          memberNumber,
          ordererName: userName,
          ordererCellphoneNumber: concatPhoneNumbers(cellphoneNumber1, cellphoneNumber2, cellphoneNumber3),
          ordererCellphoneNumber1: cellphoneNumber1,
          ordererCellphoneNumber2: cellphoneNumber2,
          ordererCellphoneNumber3: cellphoneNumber3,
          ordererEmail: email,
        };

        setOrderPageInfo({
          ...result,
          goodList: goodListWithDetail,
          ordererInformation,
          pointUsage: getPointUsage(myAssetPointSummary),
          myWelPoint: myAssetWelpointList.map((welPoint) => {
            return { balanceAmt: welPoint.balanceAmt, cashRcptIssuePossYn: welPoint.cashRcptIssuePossYn };
          }),
          regularDelivery,
        });
      })();
    } else {
      navigate('/');
    }
  }, [cartNoList, isPresent, isBulkpOrder, regularDelivery, userInfo, showAlertDialog, navigate]);

  const OrderPage = useMemo(() => {
    if (!orderPageInfo) return <></>;
    if (isPresent) {
      return <PresentOrderPage orderPageInfo={orderPageInfo} isPresent orderType={ORDER_TYPE.PRESENT} />;
    }
    if (isBulkpOrder) {
      //대량주문은 일반상품과 같은 orderType으로 한다
      return <BulkpOrderPage orderPageInfo={orderPageInfo} orderType={ORDER_TYPE.STANDARD} />;
    }
    if (orderPageInfo.goodList.some(({ goodTypeCode }) => goodTypeCode === PD_GOOD_TYPE.GIFTISHOW)) {
      return <GiftiShowOrderPage orderPageInfo={orderPageInfo} orderType={ORDER_TYPE.GIFTISHOW} />;
    }
    if (orderPageInfo.goodList.some(({ goodTypeCode }) => goodTypeCode === PD_GOOD_TYPE.TICKET)) {
      return <TicketOrderPage orderPageInfo={orderPageInfo} orderType={ORDER_TYPE.TICKET} />;
    }
    if (orderPageInfo.goodList.find(({ goodTypeCode }) => goodTypeCode === PD_GOOD_TYPE.PARK_POINT)) {
      return <ParkPointOrderPage orderPageInfo={orderPageInfo} orderType={ORDER_TYPE.PARKPOINT} />;
    }
    if (orderPageInfo.goodList.find(({ goodTypeCode }) => goodTypeCode === PD_GOOD_TYPE.REGULAR_DELIVERY)) {
      return <RegularDeliveryOrderPage orderPageInfo={orderPageInfo} orderType={ORDER_TYPE.REGULAR_DELIVERY} />;
    }
    return <StandardOrderPage orderPageInfo={orderPageInfo} orderType={ORDER_TYPE.STANDARD} />;
  }, [isPresent, orderPageInfo, isBulkpOrder]);

  return <>{OrderPage}</>;
};
export default OrderPage;
