import { atom } from 'recoil';

import { MOBILE_APP_STATUS } from '@ecp/mo/src/const/system/mobileConst';

export const systemInformationState = atom({
  key: 'systemInformationStateMo',
  default: {
    clientName: '',
    clientRepresentativeName: '',
    clientRepresentativeTel01: '',
    clientRepresentativeTel02: '',
    clientRepresentativeTel03: '',
    couponUseYn: '',
    isPossibleKnoxLogin: '',
    isMultimall: true,
    logoImgBtypeUseYn: '',
    mobileGnbImgFilePath: '',
    mobileLoginImgFilePath: '',
    mobileFooterImgFilePath: '',
    pcFooterImgFilePath: '',
    pcGnbImgFilePath: '',
    pcLoginImgFilePath: '',
    pcLoginPhrase: '',
    mobileLoginPhrase01: '',
    mobileLoginPhrase02: '',
    loginQuestUseYn: '',
    memberAddInfoInptYn: '',
    memberEntryMeanCode: '',
    memberGendInptYn: '',
    memberGradeUseYn: '',
    pointUseYn: '',
    possibleKnoxLogin: '',
    promoBannerBtypeUseYn: '',
    pcPromoBannerImgFilePath: '',
    savingsUseYn: '',
    otoQuestUseYn: '',
    siteDescription: '',
    siteName: '',
    useYn: '',
    customerCenterOpStartHh: '',
    customerCenterOpEndHh: '',
    customerCenterLchStartHh: '',
    customerCenterLchEndHh: '',
    siteTypeCode: '',
    mktingUseYn: '',
    pntExpsNm: '',
    cartUseYn: '',
    priceExpsUseYn: '',
    couponMenuExpsYn: '',
    bulkOrdMenuUseYn :'',
  },
});

export const mobileAppState = atom({
  key: 'mobileAppState',
  default: {
    isMobileApp: false,
    agentType: 'MOBILE_WEB',
    neededUpdate: false,
    versionUpdateType: MOBILE_APP_STATUS.NOT_APPLICABLE,
  },
});
