import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { format } from 'date-fns';
import isEmpty from 'lodash-es/isEmpty';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { dayOfWeekMapper } from '@ecp/common/src/const/good';

import { GoodImage } from '@mo-components/good/GoodCard';
import useSiteInfo from '@mo-hooks/common/useSiteInfo';

const OrderGoodItemInformationSingle = ({
  goodInfo,
  itemInfo,
  regularDelivery = {},
  multiReceiverCount = 1,
  errorMessage,
}) => {
  const { isPriceOpen } = useSiteInfo();
  const { goodName, thumbnailImageUrl01, thumbnailImageUrl02, thumbnailImageUrl03 } = goodInfo;
  const { itemNumber, itemNameView, orderQuantity, salePrice, originalPrice, adultGood, additionalItem } = itemInfo;
  const { period, total, time, startDateTime } = regularDelivery;

  const regularDeliveryText = useMemo(() => {
    if (isEmpty(regularDelivery)) return '';
    return `${period.name} 간격 / 총 ${total.label} ${time?.label ? `/ ${time.label}` : ''}`;
  }, [period, regularDelivery, time, total]);

  return (
    <Container gap={10}>
      <ItemContainer>
        <GoodImage
          type="horizontalSmall"
          adultGood={adultGood}
          imgPath={thumbnailImageUrl01 || thumbnailImageUrl02 || thumbnailImageUrl03}
        />
        <Spacing left={'14px'} />
        <ItemDescriptionContainer>
          <GoodNameTextBox>{goodName}</GoodNameTextBox>
          <ItemNameTextContainer>
            <ItemNameTextBox>
              {itemNumber === '00000' ? '' : additionalItem ? `(추가옵션) ${itemNameView}` : itemNameView}
            </ItemNameTextBox>
          </ItemNameTextContainer>
          {regularDeliveryText && (
            <OptionalInfoContainer>
              <OptionalInfoTextBox>{regularDeliveryText}</OptionalInfoTextBox>
            </OptionalInfoContainer>
          )}
          <PriceInfoContainer>
            <OptionalInfoContainer>
              <OptionalInfoTextBox>수량 {orderQuantity * multiReceiverCount}</OptionalInfoTextBox>
            </OptionalInfoContainer>
            {isPriceOpen && (
              <>
                <PriceContainer>
                  {salePrice < originalPrice && (
                    <OriginalPriceTextBox>
                      {NumberFormat(originalPrice * orderQuantity * multiReceiverCount)}원
                    </OriginalPriceTextBox>
                  )}
                  <SalePriceContainer>
                    <SalePriceTextBox>{NumberFormat(salePrice * orderQuantity * multiReceiverCount)}</SalePriceTextBox>
                    <UnitTextBox>원</UnitTextBox>
                  </SalePriceContainer>
                </PriceContainer>
              </>
            )}
          </PriceInfoContainer>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </ItemDescriptionContainer>
      </ItemContainer>
      {startDateTime && (
        <DeliveryInfoContainer>
          <DeliveryInfoTextBox>
            배송시작일: {format(startDateTime, 'yyyy년 MM월 dd일')} ({dayOfWeekMapper[startDateTime.getDay()]})
          </DeliveryInfoTextBox>
        </DeliveryInfoContainer>
      )}
    </Container>
  );
};

export default OrderGoodItemInformationSingle;
OrderGoodItemInformationSingle.propTypes = {
  goodInfo: PropTypes.object,
  itemInfo: PropTypes.object,
  regularDelivery: PropTypes.object,
  multiReceiverCount: PropTypes.number,
  errorMessage: PropTypes.string,
};

const Container = styled(FlexBoxColumn)`
  align-items: center;
  width: 100%;
  padding: 14px 0;
  border-bottom: 1px solid #eaeaea;
`;

const ItemContainer = styled(FlexBox)`
  align-items: flex-start;
  width: 100%;
`;

const ItemDescriptionContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
  align-self: stretch;
`;

const GoodNameTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18px;
`;

const ItemNameTextContainer = styled(FlexBox)`
  height: auto;
`;

const ItemNameTextBox = styled(TextBox)`
  font-size: 12px;
  font-weight: 350;
  color: #5a5a5a;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
`;

const PriceInfoContainer = styled(FlexBoxSpaceBetween)`
  align-items: flex-start;
  width: 100%;
`;

const OptionalInfoContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
`;

const OptionalInfoTextBox = styled(TextBox)`
  font-size: 12px;
  font-weight: 350;
  color: #5a5a5a;
`;

const PriceContainer = styled(FlexBoxColumn)`
  align-items: flex-end;
`;

const OriginalPriceTextBox = styled(TextBox)`
  font-size: 11px;
  font-weight: 350;
  text-decoration: line-through;
  line-height: 14px;
  color: #a3a3a3;
`;

const SalePriceContainer = styled(FlexBox)`
  align-items: flex-start;
`;

const SalePriceTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 700;
`;

const UnitTextBox = styled(TextBox)`
  font-size: 12px;
  font-weight: 350;
`;

const DeliveryInfoContainer = styled(FlexBox)`
  width: 100%;
  align-items: flex-start;
`;

const DeliveryInfoTextBox = styled(TextBox)`
  font-size: 12px;
  font-weight: 350;
`;

const ErrorMessage = styled(TextBox).attrs(() => ({
  color: theme.color.text.error,
  size: moTheme.font.size.$12,
  weight: moTheme.font.weight.demiLight,
  height: moTheme.font.lineHeight.bold,
}))`
  flex: 1;
  width: 100%;
  align-items: flex-end;
  margin-top: 6px;
`;
