import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { moTheme } from '@ecp/common/src/style/theme/mo';
import { color } from '@ecp/common/src/style/theme/default';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBoxButton } from '@ecp/common/src/text/TextBoxButton';
import ButtonUnstyled from '@ecp/common/src/components/unstyled/button/ButtonUnstyled';
import { StyledButton } from '@ecp/common/src/components/button/TextButton';
import { ReactComponent as IconRight } from '@ecp/common/src/assets/icon/icon__arrow--right.svg';
import { ReactComponent as IconRightStroke } from '@ecp/common/src/assets/icon/icon__arrow--right_stroke.svg';
import { ReactComponent as IconBelow } from '@ecp/common/src/assets/icon/icon__arrow--below.svg';

const StyledTextButton = styled(StyledButton)`
  cursor: pointer;
`;

const StyledTextButtonReverse = styled(StyledTextButton)`
  flex-direction: row-reverse;
`;

export const TextButton = React.forwardRef(
  ({ type, textProps, customIcon: CustomIcon, children, isReverse, iconSize, ...props }, ref) => {
    const buttonRef = useRef(null);

    useImperativeHandle(ref, () => buttonRef.current);

    const textDefaultValues = {
      size: moTheme.font.size.$13,
      color: type === 'summary-selected' ? color.hover.primary : ``,
      weight: moTheme.font.weight.medium,
    };

    const Icon = ({ type, iconSize }) => {
      switch (type) {
        case 'move':
          return <IconRight width={'5px'} height={'8px'} />;
        case 'move_stroke':
          return <IconRightStroke width={'5px'} height={'8px'} />;
        case 'list':
          return <IconBelow width={'8px'} height={'5px'} />;
        case 'custom':
          return <CustomIcon width={iconSize.width} height={iconSize.height} />;
        default:
          return <></>;
      }
    };

    return (
      <ButtonUnstyled rootComponent={isReverse ? StyledTextButtonReverse : StyledTextButton} {...props}>
        {type === 'custom-left' && (
          <>
            <CustomIcon />
            <Spacing left={6} />
          </>
        )}
        <TextBoxButton
          {...textDefaultValues}
          {...textProps}
          title={(props?.title ? props?.title : '') + children}
          aria-label={(props?.ariaLabel ? props?.ariaLabel : '') +','+ children}
          ref={buttonRef}
        >
          {children}
        </TextBoxButton>
        {type === 'move_stroke' && (
          <>
            <Icon type={type} iconSize={iconSize} />
            <Spacing left={15} />
          </>
        )}
        {type !== 'basic' && type !== 'move_stroke' && (
          <>
            <Spacing left={6} />
            <Icon type={type} iconSize={iconSize} />
          </>
        )}
      </ButtonUnstyled>
    );
  }
);

TextButton.displayName = 'TextButton';

TextButton.propTypes = {
  type: PropTypes.oneOf(['basic', 'move', 'list', 'custom', 'custom-left']),
  textProps: PropTypes.object,
  customIcon: PropTypes.elementType,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  isReverse: PropTypes.bool,
  iconSize: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  title: PropTypes.string,
};

TextButton.defaultProps = {
  type: 'basic',
  isReverse: false,
  iconSize: {
    height: '16px',
    width: '16px',
  },
};
