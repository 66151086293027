import React from 'react';
import PropTypes from 'prop-types';

import AutoBillingAgreePopup from '@mo-components/order/payment/popup/AutoBillingAgreePopup';
import RegularDeliveryAgreePopup from '@mo-components/order/payment/popup/RegularDeliveryAgreePopup';
import DirectForeignGoodsAgreePopup from '@mo-components/order/payment/popup/DirectForeignGoodsAgreePopup';
import OrderAgreePopup from '@mo-components/order/payment/popup/OrderAgreePopup';
import PersonalInfoAgreePopup from '@mo-components/order/payment/popup/PersonalInfoAgreePopup';
import ThirdPartyAgreePopup from '@mo-components/order/payment/popup/ThirdPartyAgreePopup';
import RequestAgreePopup from '@mo-components/order/payment/popup/RequestAgreePopup';

const OrderAgreePopupsWrapper = ({
  personalInfoAgreePopupOpen,
  orderAgreePopupOpen,
  thirdPartyAgreePopupOpen,
  directForeignGoodsAgreePopupOpen,
  regularDeliveryAgreePopupOpen,
  autoBillingAgreePopupOpen,
  closePersonalInfoAgreePopup,
  closeOrderAgreePopupOpen,
  closeThirdPartyAgreePopupOpen,
  closeDirectForeignGoodsAgreePopupOpen,
  closeRegularDeliveryAgreePopupOpen,
  closeAutoBillingAgreePopupOpen,
  requestAgreePopupOpen,
  closeRequestAgreePopupOpen,
  orderGoodType,
  orderType,
}) => {
  return (
    <>
      <PersonalInfoAgreePopup
        open={personalInfoAgreePopupOpen}
        onClose={closePersonalInfoAgreePopup}
        orderGoodType={orderGoodType}
        orderType={orderType}
      />
      <OrderAgreePopup open={orderAgreePopupOpen} onClose={closeOrderAgreePopupOpen} />
      <RequestAgreePopup open={requestAgreePopupOpen} onClose={closeRequestAgreePopupOpen} />
      <ThirdPartyAgreePopup
        open={thirdPartyAgreePopupOpen}
        onClose={closeThirdPartyAgreePopupOpen}
        orderGoodType={orderGoodType}
      />
      <DirectForeignGoodsAgreePopup
        open={directForeignGoodsAgreePopupOpen}
        onClose={closeDirectForeignGoodsAgreePopupOpen}
      />
      <RegularDeliveryAgreePopup open={regularDeliveryAgreePopupOpen} onClose={closeRegularDeliveryAgreePopupOpen} />
      <AutoBillingAgreePopup open={autoBillingAgreePopupOpen} onClose={closeAutoBillingAgreePopupOpen} />
    </>
  );
};

OrderAgreePopupsWrapper.propTypes = {
  personalInfoAgreePopupOpen: PropTypes.bool,
  orderAgreePopupOpen: PropTypes.bool,
  thirdPartyAgreePopupOpen: PropTypes.bool,
  directForeignGoodsAgreePopupOpen: PropTypes.bool,
  regularDeliveryAgreePopupOpen: PropTypes.bool,
  autoBillingAgreePopupOpen: PropTypes.bool,
  closePersonalInfoAgreePopup: PropTypes.func,
  closeOrderAgreePopupOpen: PropTypes.func,
  closeThirdPartyAgreePopupOpen: PropTypes.func,
  closeDirectForeignGoodsAgreePopupOpen: PropTypes.func,
  closeRegularDeliveryAgreePopupOpen: PropTypes.func,
  closeAutoBillingAgreePopupOpen: PropTypes.func,
  requestAgreePopupOpen: PropTypes.bool,
  closeRequestAgreePopupOpen: PropTypes.func,
  orderGoodType: PropTypes.object,
  orderType: PropTypes.string,
};

export default OrderAgreePopupsWrapper;
