import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Radio } from '@ecp/common/src/components/radio/mo/Radio';
import { TabLabelComponent } from '@ecp/common/src/components/tab/Tab';
import { radioClassNames } from '@ecp/common/src/components/unstyled/input/RadioUnstyled';

const Tab = (props) => <Radio labelComponent={TabLabelComponent} hideControl {...props} />;

Tab.displayName = 'Tab';

Tab.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hideControl: PropTypes.bool,
};

const StyledPrimaryTabButton = styled(FlexBoxColumn)`
  justify-content: center;
  align-items: center;
  ${overrideProperties(sizePropTypes, {
    height: '100%',
  })};
  font-weight: ${moTheme.font.weight.bold};
  font-size: ${moTheme.font.size.$14};
  color: ${theme.color.secondary};
  background: ${theme.color.background.sub2};
  border: 1px solid ${theme.color.line.background};
  border-bottom: none;
  cursor: pointer;
  white-space: pre-line;
  margin-left: -1px;
  padding-left: 1px;

  &:after {
    position: absolute;
    height: 1px;
    bottom: 0;
    left: -0.5px;
    right: -0.5px;
    content: '';
    background-color: ${theme.color.line.emphasis};
    z-index: 2;
  }

  &.${radioClassNames.checked} {
    color: ${theme.color.text.basic};
    background: ${theme.color.background.white};
    border: 1px solid ${theme.color.line.emphasis};
    border-bottom: none;
    z-index: 1;

    &:after {
      display: none;
    }
  }
`;

const StyledSecondaryTabButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${overrideProperties(sizePropTypes, {
    height: '100%',
  })};
  font-weight: ${moTheme.font.weight.demiLight};
  font-size: ${moTheme.font.size.$13};
  background: ${theme.color.background.white};
  border: 1px solid ${theme.color.line.basic};
  cursor: pointer;
  white-space: pre-line;
  text-align: center;
  margin-left: -1px;
  padding-left: 1px;

  &.${radioClassNames.checked} {
    position: relative;
    color: ${theme.color.text.white};
    background: ${theme.color.third};
    font-weight: ${moTheme.font.weight.medium};
    border: none;
    z-index: 1;
  }
`;

export const StyledEmptyTab = styled.div`
  flex: 1;
  border: none;
  border-bottom: solid 1px #141415;
`;

export const DefaultTabButton = (props) => <StyledPrimaryTabButton {...props} />;
export const SecondaryTabButton = (props) => <StyledSecondaryTabButton {...props} />;

DefaultTabButton.displayName = 'DefaultTabButton';
export default Tab;

export const SecondaryTabRealButton = (props) => <StyledTabRealButton {...props} />;
const StyledTabRealButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${overrideProperties(sizePropTypes, {
    height: '100%',
    width: '100%',
  })};
  font-weight: ${moTheme.font.weight.demiLight};
  font-size: ${moTheme.font.size.$13};
  background: ${theme.color.background.white};
  border: 1px solid ${theme.color.line.basic};
  cursor: pointer;
  white-space: pre-line;
  text-align: center;
  margin-left: -1px;
  padding-left: 1px;

  &.${radioClassNames.checked} {
    position: relative;
    color: ${theme.color.text.white};
    background: ${theme.color.third};
    font-weight: ${moTheme.font.weight.medium};
    border: none;
    z-index: 1;
  }
`;
