import { postRequest } from '@mo-apis/axios';

const createPaycoOrderReservation = (data) =>
  postRequest({
    url: '/api/v1/interfaces/payco/create-order-reservation',
    data,
  });

const paycoApi = {
  createPaycoOrderReservation,
};

export default paycoApi;
