import React from 'react';
import PropTypes from 'prop-types';

import BenefitCouponCard from '@ecp/common/src/components/coupon/BenefitCouponCard';

const BenefitCardItem = ({ item }) => {
  return (
    <BenefitCouponCard
      mobile
      couponType={item.couponType}
      messageValue={item.messageValue}
      countValue={item.countValue}
      pointValue={item.pointValue}
      handleButtonClick={item.handleButtonClick}
    />
  );
};

BenefitCardItem.propTypes = {
  item: PropTypes.object,
};

export default BenefitCardItem;
