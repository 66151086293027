import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getLabelByPrice } from '@ecp/common/src/utils/good/goodSearchUtil';
import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import NumberInput from '@ecp/common/src/components/input/mo/NumberInput';
import { PageButton } from '@ecp/common/src/components/button/mo/Button';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';

import { useAlertDialog } from '@mo-hooks/common/useModal';
import SearchFilterPopupHeader from '@mo-components/display/filter/components/SearchFilterPopupHeader';

const PriceFilter = ({ priceList, selectedPrice, onChangePrice }) => {
  const { showAlertDialog } = useAlertDialog();
  const minRef = useRef(null);
  const maxRef = useRef(null);

  const [customMinPrice, setCustomMinPrice] = useState(0);
  const [customMaxPrice, setCustomMaxPrice] = useState(0);

  useEffect(() => {
    if (selectedPrice && !priceList?.find((price) => selectedPrice.label === price.label)) {
      setCustomMinPrice(selectedPrice.min ? Number(selectedPrice.min) : 0);
      setCustomMaxPrice(selectedPrice.max ? Number(selectedPrice.max) : 0);
    }
  }, [priceList, selectedPrice]);

  const handleApplyPrice = useCallback(() => {
    if (!customMaxPrice) {
      showAlertDialog('검색을 원하시는 최저가와 최고가를 모두 입력해주세요.');
      return;
    }
    if (customMinPrice > customMaxPrice) {
      showAlertDialog('최저가는 최고가보다 낮아야 합니다.');
      return;
    }

    const priceLabel = getLabelByPrice(customMinPrice, customMaxPrice);

    if (selectedPrice?.min === customMinPrice && selectedPrice?.max === customMaxPrice) {
      return;
    }

    onChangePrice({ label: priceLabel, min: customMinPrice, max: customMaxPrice });
  }, [customMaxPrice, customMinPrice, onChangePrice, showAlertDialog, selectedPrice]);

  const handleChangePrice = (price) => {
    onChangePrice(price);
    setCustomMinPrice(0);
    setCustomMaxPrice(0);
  };

  const handleMinPrice = (e) => {
    setCustomMinPrice(Number(e.target.value));
  };

  const handleMinClick = () => {
    minRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleMaxPrice = (e) => {
    setCustomMaxPrice(Number(e.target.value));
  };

  const handleMaxClick = () => {
    maxRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <FlexBoxColumn align-items={'flex-start'} flex={'1'}>
      <SearchFilterPopupHeader title={'가격'} />
      {priceList?.map((price, index) => (
        <SubFilterListText
          key={index}
          textProps={
            price.label === selectedPrice.label
              ? { color: theme.color.text.selected, weight: moTheme.font.weight.bold }
              : { weight: moTheme.font.weight.demiLight }
          }
          onClick={() => handleChangePrice(price)}
          title={price.label === selectedPrice.label ? '선택됨,' : ''}
        >
          {price.label}
        </SubFilterListText>
      ))}
      <Spacing top={10} />
      <FlexBoxSpaceBetween width="100%">
        <NumberInput
          minWidth="auto"
          suffixText="원"
          clearable={false}
          ref={minRef}
          value={customMinPrice}
          onChange={handleMinPrice}
          onClick={handleMinClick}
          title={'최저가격(원)'}
        />
        <TextBoxStyle>~</TextBoxStyle>
        <NumberInput
          minWidth="auto"
          suffixText="원"
          clearable={false}
          ref={maxRef}
          value={customMaxPrice}
          onChange={handleMaxPrice}
          onClick={handleMaxClick}
          title={'최고가격(원)'}
        />
      </FlexBoxSpaceBetween>
      <Spacing top="10px" />
      <PageButton width={'100%'} height="40px" type="Default" onClick={handleApplyPrice}>
        적용
      </PageButton>
    </FlexBoxColumn>
  );
};

const SubFilterListText = styled(TextButton)`
  font-size: ${moTheme.font.size.$13};
  padding-bottom: 10px;
`;

const TextBoxStyle = styled(TextBox)`
  font-size: ${moTheme.font.size.$13};
  font-weight: ${moTheme.font.weight.demiLight};
  padding: 0 3px;
`;

export default PriceFilter;

PriceFilter.propTypes = {
  priceList: PropTypes.array,
  selectedPrice: PropTypes.object,
  onChangePrice: PropTypes.func,
};
