export const SBCOM_WELSTORY = 'E72';

export const SBCOM_SDI = 'C31';

//-- SDI 복지포인트 사용 가능 협력업체 코드
// 1904012345  삼성전자(주)
// 1904012364  신라스테이 주식회사
// 1306004564  삼성에버랜드
// WS00111376  (주)호텔신라
// 1806011292  삼성물산 (주) 안양컨트리클럽
// 2306017607  (주)아이엔에프아이엑스
// 1312006771  웹뜰 주식회사
// 1404007033  (주)아이엔에프아이엑스 (동일한 회사명... 혼동 금지)
export const SDI_POINT_VENDORS = ['1904012345', '1904012364','1306004564','WS00111376','1806011292','2306017607','1312006771','1404007033'];
