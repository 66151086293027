import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

import PresentReceiverContent from '@mo-components/order/payment/present/PresentReceiverContent';

function PresentMultiReceiverContent({ ordererInformation, deliveryNumber, handleChangeDeliveryInfo }) {
  return (
    <FlexBoxColumn width="100%">
      {[...new Array(deliveryNumber)].map((item, idx) => (
        <Fragment key={idx}>
          <Spacing top={14} />
          <PresentReceiverContent
            ordererInformation={ordererInformation}
            handleChangeDeliveryInfo={handleChangeDeliveryInfo}
            index={idx}
            key={idx}
            isMultipleReceiver
          />
          <HorizontalDivider height="1px" color="#eaeaea" />
        </Fragment>
      ))}
      <Spacing top={10} />
    </FlexBoxColumn>
  );
}

PresentMultiReceiverContent.propTypes = {
  ordererInformation: PropTypes.object,
  deliveryNumber: PropTypes.number,
  handleChangeDeliveryInfo: PropTypes.func,
};

export default PresentMultiReceiverContent;
