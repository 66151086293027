import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  ENG_CONSONANT_LIST,
  ETC_CONSONANT,
  getConsonantFilteredList,
  KOR_CONSONANT_LIST,
} from '@ecp/common/src/utils/good/brandUtil';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import TabGroup from '@ecp/common/src/components/tab/mo/TabGroup';
import { TabPanel } from '@ecp/common/src/components/tab/mo/TabPanel';
import { SecondaryTabButton } from '@ecp/common/src/components/tab/mo/Tab';

import BrandResultList from '@mo-pages/brand/components/BrandResultList';

const tabItems = [{ tabName: '가나다' }, { tabName: 'ABC' }];

const BrandResult = ({
  categorySwiperHeight,
  consonantSwiperRef,
  sticky,
  scrollDirection,
  selectedConsonant,
  brandGroup,
  brandCountGroup,
  handleSearchConditionChange,
  handleBrandClick,
  handleShowMoreBrand,
  tabIndex,
}) => {
  const koreanConsonantList = useMemo(() => {
    return getConsonantFilteredList(brandGroup, [...KOR_CONSONANT_LIST, ETC_CONSONANT]);
  }, [brandGroup]);

  const englishConsonantList = useMemo(() => {
    return getConsonantFilteredList(brandGroup, ENG_CONSONANT_LIST);
  }, [brandGroup]);

  const tabGroupStickyTop = useMemo(() => {
    const offsetHeight = scrollDirection === 'up' ? 182 : 136;
    return `${categorySwiperHeight + offsetHeight}px`;
  }, [categorySwiperHeight, scrollDirection]);

  const consonantSwiperStickyTop = useMemo(() => {
    const offsetHeight = scrollDirection === 'up' ? 222 : 176;
    return `${categorySwiperHeight + offsetHeight}px`;
  }, [categorySwiperHeight, scrollDirection]);

  const handleChangeTab = useCallback(
    (e) => {
      handleSearchConditionChange({ initialConsonant: '', tabIndex: Number(e.target.value) });
    },
    [handleSearchConditionChange]
  );

  return (
    <Spacing left={20} right={20} width={'100%'}>
      <TabGroup
        tabItems={tabItems}
        tabSlot={SecondaryTabButton}
        width={'100%'}
        height={'40px'}
        sticky
        stickyTop={tabGroupStickyTop}
        onChange={handleChangeTab}
        value={tabIndex}
      >
        <TabPanel>
          <BrandResultList
            consonantSwiperRef={consonantSwiperRef}
            sticky={sticky}
            consonantSwiperStickyTop={consonantSwiperStickyTop}
            selectedConsonant={selectedConsonant}
            consonantList={[...KOR_CONSONANT_LIST, ETC_CONSONANT]}
            filteredConsonantList={koreanConsonantList}
            brandGroup={brandGroup}
            brandCountGroup={brandCountGroup}
            handleSearchConditionChange={handleSearchConditionChange}
            handleBrandClick={handleBrandClick}
            handleShowMoreBrand={handleShowMoreBrand}
            title={'가나다순으로 찾기'}
          />
        </TabPanel>
        <TabPanel>
          <BrandResultList
            consonantSwiperRef={consonantSwiperRef}
            sticky={sticky}
            consonantSwiperStickyTop={consonantSwiperStickyTop}
            selectedConsonant={selectedConsonant}
            consonantList={ENG_CONSONANT_LIST}
            filteredConsonantList={englishConsonantList}
            brandGroup={brandGroup}
            brandCountGroup={brandCountGroup}
            handleSearchConditionChange={handleSearchConditionChange}
            handleBrandClick={handleBrandClick}
            handleShowMoreBrand={handleShowMoreBrand}
            title={'ABC순으로 찾기'}
          />
        </TabPanel>
      </TabGroup>
    </Spacing>
  );
};

export default BrandResult;

BrandResult.propTypes = {
  categorySwiperHeight: PropTypes.number,
  consonantSwiperRef: PropTypes.object,
  sticky: PropTypes.bool,
  scrollDirection: PropTypes.string,
  selectedConsonant: PropTypes.string,
  brandGroup: PropTypes.object,
  brandCountGroup: PropTypes.object,
  handleSearchConditionChange: PropTypes.func,
  handleBrandClick: PropTypes.func,
  handleShowMoreBrand: PropTypes.func,
  tabIndex: PropTypes.number,
};
