import { postRequest } from '../axios';

const updateAppLoginInfo = () =>
  postRequest({
    url: '/api/v1/mobile/member/login-info',
    isMobile: true,
  });

const mobileMemberApi = {
  updateAppLoginInfo,
};

export default mobileMemberApi;
