import React, { useEffect } from 'react';

import usePreventPopState from '@ecp/common/src/hooks/order/payment/usePreventPopState';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Loading from '@ecp/common/src/components/progress/Loading';

import { useHideFooter } from '@mo-hooks/common/useFooter';
import theme from '@ecp/common/src/style/theme/default';

const PaymentInProgressPage = () => {
  useHideFooter();
  usePreventPopState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <FlexBoxColumn width="100%" padding="22px">
      <Spacing top={120} />
      <Loading />
      <Spacing top={20} />
      <TextBox size={'21px'} weight={500} color={theme.color.primary}>
        결제 진행 중 입니다.
      </TextBox>
      <Spacing top={40} />
      <TextBox>결제 완료까지 다소 시간이 걸릴 수 있습니다.</TextBox>
      <TextBox>현재 화면을 벗어나도 결제는 계속 진행되며,</TextBox>
      <TextBox style={{ display: 'inline' }}>
        주문결과는 <span style={{ fontWeight: 500 }}>[마이페이지 &gt; 나의 쇼핑 &gt; 주문내역]</span>
      </TextBox>
      <TextBox>에서 확인할 수 있습니다.</TextBox>
      <Spacing bottom={120} />
    </FlexBoxColumn>
  );
};

export default PaymentInProgressPage;
