import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import ButtonUnstyled from '@ecp/common/src/components/unstyled/button/ButtonUnstyled';
import { SwiperContents } from '@ecp/common/src/components/swiper/mo/SwiperTemplate';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';
import { ReactComponent as IconOpen } from '@ecp/common/src/assets/icon/icon__arrow--below.svg';

import { withCategorySubList, withSameParentCategoryList } from '@mo-recoil/category/selector';

const initSubCategory = [{ categoryName: '전체', categoryNumber: '' }];

const CategoryItem = ({ item, itemProps }) => {
  const { categoryNumber } = useParams();
  const navigate = useNavigate();

  return (
    <TextButton
      width={itemProps?.width}
      padding={itemProps?.padding}
      textProps={{
        size: moTheme.font.size.$13,
        weight: moTheme.font.weight.demiLight,
        color:
          item?.categoryNumber === '' || item?.categoryNumber === categoryNumber
            ? theme.color.text.selected
            : theme.color.text.basic,
      }}
      onClick={() => (item?.categoryNumber !== '' ? navigate(`/category/sub/${item?.categoryNumber}`) : null)}
    >
      {item?.categoryName}
    </TextButton>
  );
};
CategoryItem.propTypes = {
  item: PropTypes.object,
  itemProps: PropTypes.object,
};
CategoryItem.defaultProps = {
  padding: '5px 0 5px 4px',
};

const SwiperSubCategoryLabel = ({ categoryDetail }) => {
  const { categoryNumber, depthNumber } = categoryDetail;
  const [isActive, setIsActive] = useState(false);
  const [categoryList, setCategoryList] = useState([...initSubCategory]);
  const subCategoryList = useRecoilValue(withCategorySubList(categoryNumber));
  const sameParentCategoryList = useRecoilValue(withSameParentCategoryList(categoryNumber));

  const handleArrowClick = useCallback(() => {
    setIsActive((current) => !current);
  }, []);

  useEffect(() => {
    if (depthNumber === 4) {
      setCategoryList([...sameParentCategoryList]);
    } else {
      setCategoryList([...initSubCategory, ...subCategoryList]);
    }
  }, [depthNumber, sameParentCategoryList, subCategoryList]);

  return (
    <Container>
      <CategoryLabelContainer>
        {isActive ? (
          categoryList?.map((category) => (
            <CategoryItem
              key={`swiper-label-${category?.categoryNumber}`}
              item={category}
              itemProps={{
                width: 'auto',
                padding: '5px 0 5px 14px',
              }}
            />
          ))
        ) : (
          <>
            <SwiperContents
              width={'296px'}
              padding={'0 0 0 10px'}
              itemList={categoryList}
              itemComponent={CategoryItem}
              itemProps={{
                width: 'auto',
                padding: '5px 0 5px 4px',
              }}
            />
          </>
        )}
      </CategoryLabelContainer>
      <Arrow type={isActive ? 'open' : 'close'} onClick={handleArrowClick}>
        <IconOpen width="12px" height="8px" />
      </Arrow>
    </Container>
  );
};
export default SwiperSubCategoryLabel;

SwiperSubCategoryLabel.propTypes = {
  categoryDetail: PropTypes.object,
};

const Container = styled(FlexBox)`
  width: 100%;
  padding: 7px 0 7px 6px;
  box-sizing: border-box;
  align-items: flex-start;
  overflow: scroll;
`;

const CategoryLabelContainer = styled(FlexBox)`
  flex: 1;
  flex-wrap: wrap;
`;

const Arrow = styled(ButtonUnstyled)`
  width: 48px;
  height: 30px;
  background: linear-gradient(270deg, #ffffff 78.41%, rgba(255, 255, 255, 0) 135.23%);
  & svg {
    transform: ${({ type }) => type === 'open' && 'rotate(180deg)'};
  }
`;
