import React from 'react';
import PropTypes from 'prop-types';

import usePresentReceiverContent from '@ecp/common/src/hooks/order/payment/usePresentReceiverContent';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBoxColumn, FlexBoxSpaceBetween, SpanTitle } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import LabeledCheckBox from '@ecp/common/src/components/checkbox/LabeledCheckBox';
import Input from '@ecp/common/src/components/input/Input';

function PresentReceiverContent({ ordererInformation, handleChangeDeliveryInfo, isMultipleReceiver = false, index }) {
  const {
    isSameAsOrderUser,
    handleUseOrderUserInfoCheckChange,
    handleOrderNameChange,
    handleOrderPhoneNumberChange,
    receiverName,
    cellphoneNumber,
  } = usePresentReceiverContent({ ordererInformation, handleChangeDeliveryInfo, index });

  return (
    <FlexBoxColumn align-items="flex-start" width="100%">
      <FlexBoxSpaceBetween width="100%">
        <TextBox size={14} weight="500" color="#141415" required>
          <SpanTitle>필수 입력 항목</SpanTitle>
          받는 분{`${isMultipleReceiver ? ` ${index + 1}` : ''}`}
        </TextBox>
        <LabeledCheckBox
          labelText="보내는 분과 동일"
          onChange={handleUseOrderUserInfoCheckChange}
          checked={isSameAsOrderUser}
        />
      </FlexBoxSpaceBetween>
      <Spacing bottom={14} />
      <Input
        width="100%"
        clearable={false}
        maxLength={10}
        placeholder="받는 분을 입력해 주세요"
        value={receiverName}
        onChange={(e) => handleOrderNameChange(e)}
      />
      <Spacing bottom={14} />
      <TextBox size={14} weight="500" color="#141415" required>
        <SpanTitle>필수 입력 항목</SpanTitle>
        휴대전화번호
      </TextBox>
      <Spacing bottom={6} />
      <Input
        width="100%"
        clearable={false}
        onlyNumber
        maxLength={11}
        placeholder="휴대전화 번호를 입력해 주세요"
        value={cellphoneNumber}
        onChange={(e) => handleOrderPhoneNumberChange(e)}
      />
      <Spacing bottom={14} />
    </FlexBoxColumn>
  );
}

PresentReceiverContent.propTypes = {
  ordererInformation: PropTypes.object,
  handleChangeDeliveryInfo: PropTypes.func,
  isMultipleReceiver: PropTypes.bool,
  index: PropTypes.number,
};

export default PresentReceiverContent;
