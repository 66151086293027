import React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { zIndex } from '@ecp/common/src/style/constant';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import Toast from '@ecp/common/src/components/toast/mo/Toast';

import { toastState } from '@mo-recoil/common/atom';

const StyledToastList = styled(FlexBoxColumn)`
  bottom: 30px;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: ${zIndex.toast};
`;

const ToastProvider = () => {
  const toasts = useRecoilValue(toastState);
  return (
    <StyledToastList>
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </StyledToastList>
  );
};

export default ToastProvider;
