import pick from 'lodash-es/pick';
import uniq from 'lodash-es/uniq';

import { ORD_GOOD_PARAMS } from '@ecp/common/src/const/order/orderConst';
import { ASSET_RESOURCE_DIVISION_CODE } from '@ecp/common/src/const/asset';

import orderApi from '@mo-apis/order/orderApi';

export const goodListWithZeroDeliveryQuantity = (goodList) => {
  return goodList.map((goodItem) => {
    const newItemList = goodItem.itemList.map((item) => {
      return { ...item, deliveryQuantity: 0 };
    });
    return { ...goodItem, itemList: newItemList };
  });
};

export const goodListWithMaxDeliveryQuantity = (goodList) => {
  return goodList.map((goodItem) => {
    const newItemList = goodItem.itemList.map((item) => {
      return { ...item, deliveryQuantity: item.orderQuantity };
    });
    return { ...goodItem, itemList: newItemList };
  });
};

export const getGoodListWithZeroQuantity = (orderDeliveryList) => {
  return orderDeliveryList.map((deliveryItem) => {
    return { ...deliveryItem, goodList: goodListWithZeroDeliveryQuantity(deliveryItem.goodList) };
  });
};

export const getGoodListWithMaxQuantity = (orderDeliveryList) => {
  return orderDeliveryList.map((deliveryItem) => {
    return { ...deliveryItem, goodList: goodListWithMaxDeliveryQuantity(deliveryItem.goodList) };
  });
};

export const reformDeliveryListToDeliveryCostData = (orderDeliveryList) => {
  return orderDeliveryList.reduce((deliveryAcc, deliveryItem, deliveryIdx) => {
    const allItemList = deliveryItem.goodList.reduce((goodAcc, goodItem) => {
      const itemList = goodItem.itemList
        .filter(({ deliveryQuantity }) => {
          return !!deliveryQuantity;
        })
        .map((item) => {
          return {
            deliveryNumber: deliveryIdx,
            sequenceNumber: deliveryIdx,
            goodNumber: goodItem.goodNumber,
            itemNumber: item.itemNumber,
            salePrice: item.salePrice,
            discountAmount: (item.goodCouponDiscountAmount || 0) + (item.cartCouponDiscountAmount || 0),
            cartCouponDiscountAmount: item.cartCouponDiscountAmount || 0,
            orderQuantity: item.deliveryQuantity,
            virtualVendorNumber: goodItem.virtualVendorNumber,
            zip: deliveryItem.deliveryPlace.postNumber,
          };
        });
      return [...goodAcc, ...itemList];
    }, []);
    return [...deliveryAcc, ...allItemList];
  }, []);
};

export const setDeliveryListWithDeliveryCost = async (orderDeliveryList, targetIdx) => {
  const reformedData = reformDeliveryListToDeliveryCostData(orderDeliveryList);
  if (!reformedData?.length) {
    // 모든 아이템 퀀티티가 0이면 안보내고 배송비0원 처리
    return orderDeliveryList.map((deliveryItem, idx) => {
      if (targetIdx !== undefined) {
        if (idx !== targetIdx) {
          return { ...deliveryItem };
        }
      }
      return { ...deliveryItem, totalDeliveryCost: 0, deliveryCost: 0, islandMountainDeliveryCost: 0 };
    });
  }

  const {
    result: { deliveryCostList, deliveryGoodList },
  } = await orderApi.getDeliveryCost(reformedData);

  const orderDeliveryListWithCost = orderDeliveryList.map((deliveryItem, idx) => {
    if (targetIdx !== undefined) {
      if (idx !== targetIdx) {
        return { ...deliveryItem };
      }
    }

    const deliveryCost = deliveryCostList
      .filter(({ deliveryNumber }) => Number(deliveryNumber) === idx)
      .reduce((acc, item) => acc + item.deliveryCostAmount, 0);

    const islandMountainDeliveryCost = deliveryCostList
      .filter(({ deliveryNumber }) => Number(deliveryNumber) === idx)
      .reduce((acc, item) => acc + item.islandMountainDeliveryCostAmount, 0);

    const totalDeliveryCost = deliveryCost + islandMountainDeliveryCost;

    const costList = deliveryCostList.filter((item) => Number(item.deliveryNumber) === idx);
    return { ...deliveryItem, totalDeliveryCost, deliveryCost, islandMountainDeliveryCost, costList };
  });
  return orderDeliveryListWithCost.map((deliveryItem, deliveryIdx) => {
    if (targetIdx !== undefined) {
      if (deliveryIdx !== targetIdx) {
        return { ...deliveryItem };
      }
    }
    const newGoodList = deliveryItem.goodList.map((goodItem) => {
      const newItemList = goodItem.itemList.map((item) => {
        const matchedDeliveryGood = deliveryGoodList.find(({ deliveryNumber, goodNumber, itemNumber }) => {
          return (
            deliveryIdx === Number(deliveryNumber) &&
            goodItem.goodNumber === goodNumber &&
            item.itemNumber === itemNumber
          );
        });
        if (!matchedDeliveryGood) {
          return { ...item };
        }
        return { ...item, deliveryGroupNumber: matchedDeliveryGood.deliveryGroupNumber };
      });
      return { ...goodItem, itemList: newItemList };
    });

    return { ...deliveryItem, goodList: newGoodList };
  });
};

export const concatPhoneNumbers = (phoneNumber1, phoneNumber2, phoneNumber3) => {
  return (phoneNumber1 || '').concat(phoneNumber2 || '').concat(phoneNumber3 || '');
};

export const getPointUsage = (myAssetPointSummary) => {
  const saveMoneyBalance = myAssetPointSummary.find(
    ({ assetResourceDivisionCode }) => assetResourceDivisionCode === ASSET_RESOURCE_DIVISION_CODE.SAVE_MONEY
  )?.assetBalance;
  const savePoint = { balance: saveMoneyBalance || 0, use: 0 };

  const welPointBalance = myAssetPointSummary.find(
    ({ assetResourceDivisionCode }) => assetResourceDivisionCode === ASSET_RESOURCE_DIVISION_CODE.WEL_POINT
  )?.assetBalance;
  const welPoint = { balance: welPointBalance || 0, use: 0 };

  const welfarePointBalance = myAssetPointSummary.find(
    ({ assetResourceDivisionCode }) => assetResourceDivisionCode === ASSET_RESOURCE_DIVISION_CODE.SUPPORT
  )?.assetBalance;
  const welfarePoint = { balance: welfarePointBalance || 0, use: 0 };
  return { savePoint, welPoint, welfarePoint };
};

export const getGoodListWithDetail = (fetchedGoodList) => {
  return uniq(fetchedGoodList.map((good) => good.goodNumber)).map((goodNo) => {
    const filteredGoodItemList = fetchedGoodList.filter((resultGood) => resultGood.goodNumber === goodNo);
    const newItemList = filteredGoodItemList.map((item) => {
      return {
        ...item,
        deliveryQuantity: item.orderQuantity,
        availableQuantity: item.orderQuantity,
      };
    });
    return { ...pick(filteredGoodItemList[0], ORD_GOOD_PARAMS), itemList: newItemList };
  });
};

export const getOrderItemCount = (goodList) => {
  return goodList.reduce((goodAcc, goodItem) => {
    return goodAcc + goodItem.itemList.length;
  }, 0);
};
