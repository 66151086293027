import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxCenter, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import CheckboxUnstyled from '@ecp/common/src/components/unstyled/checkbox/CheckboxUnstyled';
import CheckboxAddUnstyled from '@ecp/common/src/components/unstyled/checkbox/CheckboxAddUnstyled';
import { styledComponent } from '@ecp/common/src/components/swiper/component/SwiperController';
import useSwiperController from '@ecp/common/src/components/swiper/useSwiperController';
import playWhite from '@ecp/common/src/assets/icon/play__white.svg';
import pauseWhite from '@ecp/common/src/assets/icon/pause__white.svg';
import { ReactComponent as IconLeftArrow } from '@ecp/common/src/assets/icon/left-rolling.svg';
import { ReactComponent as IconRightArrow } from '@ecp/common/src/assets/icon/right-rolling.svg';

// inner
const RootComponent = styled(styledComponent.RootComponent)`
  height: 12px;
  width: 40px;
  right: 9.5%;
  bottom: 12px;
  padding: 0 14px;
`;

const fixedPosition = css`
  @media (max-height: 800px) {
    position: fixed;
    bottom: 52px;
  }
`;

const InnerRootComponent = styled(styledComponent.RootComponent)`
  height: 22px;
  width: 78px;
  right: 6px;
  bottom: 6px;
  ${({ fixed }) => fixed && fixedPosition};
  padding: 5px 12px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 11px;
`;

const StyledLabel = styled(FlexBox)`
  color: ${({ isWhite }) => (isWhite ? '#ffffff' : '#141415')};
  font-size: ${({ size }) => (size ? size : moTheme.font.size.$12)};
`;

const LabelComponent = (props) => {
  const { current, total, isWhite } = props;

  return (
    <StyledLabel isWhite={isWhite} aria-hidden={'true'}>
      <styledComponent.StyledTotalLabel>
        <styledComponent.StyledCurrentLabel>{current}</styledComponent.StyledCurrentLabel>/{total}
      </styledComponent.StyledTotalLabel>
    </StyledLabel>
  );
};

LabelComponent.displayName = 'LabelComponent';

LabelComponent.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  isWhite: PropTypes.bool,
};

const StyledToggleButton = styled(styledComponent.StyledToggleButton)`
  width: 7px;
  height: 10px;
  background-size: 7px 10px;
`;

export const SwiperController = (props) => {
  const { current, total, togglePlay, ...otherProps } = props;
  const { handleChange } = useSwiperController({ togglePlay });
  const title = total + '개 슬라이드 중 ' + current + '번';
  return (
    <CheckboxAddUnstyled
      rootProps={{ ...otherProps }}
      rootComponent={InnerRootComponent}
      checkboxComponent={StyledToggleButton}
      playGb="Y"
      playGbAddTitle={title}
      onChange={handleChange}
      label={LabelComponent}
      labelProps={{ current, total }}
      {...props}
      handleItemClick={props.handleItemClick}
    />
  );
};

SwiperController.displayName = 'SwiperController';

SwiperController.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  togglePlay: PropTypes.func,
  handleItemClick: PropTypes.func,
};

// white
const RootWhiteComponent = styled(RootComponent)`
  right: 14px;
  bottom: 14px;
`;

const StyledToggleWhiteButton = styled(StyledToggleButton)`
  &.checkbox-checked {
    background-image: url(${playWhite});
  }

  &.checkbox-unchecked {
    background-image: url(${pauseWhite});
  }
`;

export const SwiperWhiteController = (props) => {
  const { current, total, togglePlay, ...otherProps } = props;
  const { handleChange } = useSwiperController({ togglePlay });
  const title = total + '개 슬라이드 중 ' + current + '번';
  return (
    <CheckboxUnstyled
      rootProps={{ ...otherProps }}
      rootComponent={RootWhiteComponent}
      checkboxComponent={StyledToggleWhiteButton}
      playGb="Y"
      playGbAddTitle={title}
      onChange={handleChange}
      label={LabelComponent}
      labelProps={{ current, total, isWhite: true }}
    />
  );
};

SwiperWhiteController.displayName = 'SwiperWhiteController';

SwiperWhiteController.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  togglePlay: PropTypes.func,
};

// exhibition
const RootExhibitionComponent = styled(RootComponent)`
  right: ${({ right = '12%' }) => right};
  bottom: 14px;
`;

export const SwiperMainExhibitionController = ({ current, total, right }) => {
  return (
    <RootExhibitionComponent width="100%" right={right}>
      <StyledLabel isWhite>
        <styledComponent.StyledTotalLabel>
          <styledComponent.StyledCurrentLabel>{current}</styledComponent.StyledCurrentLabel>/{total}
        </styledComponent.StyledTotalLabel>
      </StyledLabel>
    </RootExhibitionComponent>
  );
};

SwiperMainExhibitionController.displayName = 'SwiperMainExhibitionController';

SwiperMainExhibitionController.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  right: PropTypes.string,
};

// edge
const RootEdgeComponent = styled.label`
  display: flex;
  padding: 0 14px;
  justify-content: space-between;
  background-color: rgba(20, 20, 21, 0.45);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 68px;
  height: 22px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  align-items: center;
  z-index: 4;
  cursor: pointer;
`;

export const SwiperEdgeController = (props) => {
  const { current, total, togglePlay, ...otherProps } = props;
  const { handleChange } = useSwiperController({ togglePlay });
  const title = total + '개 슬라이드 중 ' + current + '번';
  return (
    <CheckboxUnstyled
      rootProps={{ ...otherProps }}
      rootComponent={RootEdgeComponent}
      checkboxComponent={StyledToggleWhiteButton}
      playGb="Y"
      playGbAddTitle={title}
      onChange={handleChange}
      label={LabelComponent}
      labelProps={{ current, total, isWhite: true }}
    />
  );
};

SwiperEdgeController.displayName = 'SwiperEdgeController';

SwiperEdgeController.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  togglePlay: PropTypes.func,
};

// label
const StyledSwiperLabel = styled(FlexBoxSpaceBetween)`
  font-size: ${({ size }) => (size ? size : moTheme.font.size.$12)};
  border-radius: 12px;
  color: #141415;
  width: 68px;
  height: 24px;
  position: absolute;
  bottom: ${({ bottom }) => (bottom ? bottom : '10px')};
  left: ${({ left }) => (left ? left : 'calc(50% - 34px)')};
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'rgba(255, 255, 255, 0.85)')};
  padding: 0 10px;
  z-index: 1;
`;

export const StyledSwiperEdgeLabel = styled(FlexBoxSpaceBetween)`
  font-size: ${({ size }) => (size ? size : moTheme.font.size.$12)};
  color: #ffffff;
  justify-content: center;
  background-color: rgba(20, 20, 21, 0.45);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 53px;
  height: 22px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  align-items: center;
  z-index: 4;
`;

export const SwiperLabel = (props) => {
  const { current, total, hasArrow, labelComponent: SwiperLabel, prevEvent, nextEvent, ...otherProps } = props;

  return (
    <SwiperLabel {...otherProps}>
      {hasArrow && (
        <Awrapper
          href="#"
          onClick={(e) => {
            e.preventDefault();
            prevEvent();
          }}
        >
          <IconLeftArrow width="4px" heigth="8px" stroke={theme.color.line.emphasis} strokeWidth="3px" />
        </Awrapper>
      )}
      <FlexBoxCenter width={'100%'}>
        <styledComponent.StyledTotalLabel>
          <styledComponent.StyledCurrentLabel>{current}</styledComponent.StyledCurrentLabel>/{total}
        </styledComponent.StyledTotalLabel>
      </FlexBoxCenter>
      {hasArrow && (
        <Awrapper
          href="#"
          onClick={(e) => {
            e.preventDefault();
            nextEvent();
          }}
        >
          <IconRightArrow width="4px" heigth="8px" stroke={theme.color.line.emphasis} strokeWidth="3px" />
        </Awrapper>
      )}
    </SwiperLabel>
  );
};

SwiperLabel.displayName = 'SwiperLabel';

SwiperLabel.propTypes = {
  labelComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node]),
  current: PropTypes.number,
  total: PropTypes.number,
  hasArrow: PropTypes.bool,
  prevEvent: PropTypes.func,
  nextEvent: PropTypes.func,
};

SwiperLabel.defaultProps = {
  labelComponent: StyledSwiperLabel,
};

export const SwiperArrowLabel = (props) => <SwiperLabel hasArrow={true} {...props} />;

SwiperArrowLabel.displayName = 'SwiperArrowLabel';

SwiperArrowLabel.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
};

export const SwiperOuterLabel = (props) => <SwiperLabel bottom="-14px" backgroundColor="#eaeaea" {...props} />;

export const SwiperLeftOuterLabel = (props) => (
  <SwiperLabel left="20px" bottom="-38px" backgroundColor="#eaeaea" {...props} />
);

SwiperOuterLabel.displayName = 'SwiperOuterLabel';

SwiperOuterLabel.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
};

export const SwiperEdgeLabel = (props) => <SwiperLabel labelComponent={StyledSwiperEdgeLabel} {...props} />;

SwiperEdgeLabel.displayName = 'SwiperEdgeLabel';

SwiperEdgeLabel.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
};

export default SwiperController;

const Awrapper = styled.a`
  display: flex;
`;
