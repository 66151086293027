import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox, FlexBoxCenter, FlexBoxColumn, FlexBoxEnd } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox, TextBoxAlignLeft } from '@ecp/common/src/text/TextBox';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';
import { BENEFIT_PROPERTY } from '@ecp/common/src/const/benefit/benefitConst';

import CardBenefit from '@mo-components/benefit/CardBenefit';
import AllianceBannerSwiper from '@mo-components/benefit/AllianceBannerSwiper';

const AllBenefitTab = ({ handleDrawerClose }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (url) => {
      navigate(url);
      handleDrawerClose();
    },
    [handleDrawerClose, navigate]
  );

  const BenefitCard = ({ type }) => (
    <CardContainer>
      <FlexBoxColumn width={'60px'} height={'100%'} justify-content={'flex-start'}>
        <TypeIcon src={BENEFIT_PROPERTY[type].imageSrc} />
      </FlexBoxColumn>
      <FlexBoxColumn align-items={'flex-start'} width={'186px'} height={'100%'}>
        <FlexBoxEnd width={'100%'} height={'26px'}>
          <TypeLabel>{BENEFIT_PROPERTY[type].label}</TypeLabel>
        </FlexBoxEnd>
        <BenefitCardTitleTextBox>{BENEFIT_PROPERTY[type].title}</BenefitCardTitleTextBox>
        <Spacing top={6} />
        <ContentTextBox>{BENEFIT_PROPERTY[type].content}</ContentTextBox>
        {BENEFIT_PROPERTY[type].navigateUrl && (
          <>
            <Spacing top={6} />
            <FlexBoxEnd width={'100%'}>
              <TextButton
                type="move"
                textProps={{ size: '12px' }}
                onClick={() => {
                  handleClick(BENEFIT_PROPERTY[type].navigateUrl);
                }}
              >
                전체보기
              </TextButton>
            </FlexBoxEnd>
          </>
        )}
      </FlexBoxColumn>
    </CardContainer>
  );

  BenefitCard.propTypes = {
    type: PropTypes.string,
  };

  return (
    <FlexBoxColumn width={'100%'} data-ds-label2={'navi_benefit_p'} align-items={'flex-start'}>
      <Spacing top={20} />
      <BenefitContainer width={'100%'}>
        {Object.keys(BENEFIT_PROPERTY).map((k) => {
          return <BenefitCard key={k} type={k} />;
        })}
      </BenefitContainer>
      <Spacing top={30} />
      <TitleTextBox>카드 혜택</TitleTextBox>
      <SubTitleTextBox>임직원 분들께 드리는 카드 무이자 할부 혜택입니다.</SubTitleTextBox>
      <Spacing top={10} />
      <CardBenefit />
      <AllianceBannerSwiper width={'260px'} height={'108px'} handleDrawerClose={handleDrawerClose} />
    </FlexBoxColumn>
  );
};

export default AllBenefitTab;

AllBenefitTab.propTypes = {
  handleDrawerClose: PropTypes.func,
};

const BenefitContainer = styled(FlexBoxColumn)`
  border-bottom: 1px solid #dfdfdf;
`;

const TitleTextBox = styled(TextBox)`
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
`;

const SubTitleTextBox = styled(TextBox)`
  font-size: 12px;
  font-weight: 350;
  line-height: 160%;
`;

const CardContainer = styled(FlexBox)`
  width: 260px;
  height: fit-content;
  padding-bottom: 20px;
  border-top: 1px solid #dfdfdf;
  align-items: flex-start;
  justify-content: space-between;
`;

const TypeIcon = styled.img`
  width: 60px;
  height: 60px;
  margin-top: 17px;
`;

const TypeLabel = styled(FlexBoxCenter)`
  width: 61px;
  height: 26px;
  background-color: #141415;
  border-radius: 0 0 0 8px;
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  align-items: center;
`;

const BenefitCardTitleTextBox = styled(TextBoxAlignLeft)`
  font-weight: 500;
  font-size: 14px;
  color: #141415;
  margin-top: -10px;
`;

const ContentTextBox = styled(TextBoxAlignLeft)`
  font-weight: 350;
  font-size: 12px;
  color: #727272;
  line-height: 140%;
`;
