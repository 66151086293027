import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Accordion from '@ecp/common/src/components/accordion/mo/Accordion';
import DropdownDevice from '@ecp/common/src/components/dropdown/mo/DropdownDevice';

import PresentMultiReceiverContent from '@mo-components/order/payment/present/PresentMultiReceiverContent';
import PresentSingleReceiverContent from '@mo-components/order/payment/present/PresentSingleReceiverContent';
import useSiteInfo from '@mo-hooks/common/useSiteInfo';

const PresentReceiverInformation = ({
  goodList,
  ordererInformation,
  orderDeliveryList,
  handleChangeOrderDeliveryList,
  getOrderDeliveryList,
  onChangeOrderQuantity,
  goodPriceList,
  deliveryNumber,
  setDeliveryNumber,
  isFamilyDiscountOrder,
}) => {
  const { isPriceOpen } = useSiteInfo();
  const [selectedItem, setSelectedItem] = useState({ label: `${1}명`, value: String(1), key: String(1) });

  const itemTypeCount = useMemo(() => goodList.map(({ itemList }) => itemList).flat().length, [goodList]);
  const dropdownList = useMemo(
    () =>
      [...Array(10)].map((data, index) => ({
        label: `${index + 1}명`,
        value: `${index + 1}`,
        key: `${index + 1}`,
      })),

    []
  );
  const handleChangeDeliveryInfo = useCallback(
    (deliveryInfo, idx) => {
      const newOrderDeliveryList = orderDeliveryList.map((deliveryItem, deliveryIdx) => {
        if (idx !== deliveryIdx) {
          return deliveryItem;
        }
        return {
          ...deliveryItem,
          deliveryPlace: deliveryInfo,
        };
      });
      handleChangeOrderDeliveryList(newOrderDeliveryList, { refreshDeliveryCost: false });
    },
    [handleChangeOrderDeliveryList, orderDeliveryList]
  );

  const handleChangeDeliveryNumber = useCallback(
    async (data) => {
      const selectedNumber = Number(data.value);
      if (selectedNumber === orderDeliveryList.length) return;
      if (selectedNumber > orderDeliveryList.length) {
        const newOrderDeliveryList = [...new Array(selectedNumber)].map((item, idx) => {
          if (idx < orderDeliveryList.length) {
            return { ...orderDeliveryList[idx] };
          }
          return {
            deliveryPlace: { receiverName: '', cellPhoneNumber: '' },
            goodList,
          };
        });

        const _presentDeliveryList = await getOrderDeliveryList(
          newOrderDeliveryList,
          { refreshDeliveryCost: false },
          goodPriceList
        );
        await onChangeOrderQuantity({ goodList, quantity: selectedNumber, orderDeliveryList: _presentDeliveryList });
      } else {
        const newOrderDeliveryList = orderDeliveryList.filter((item, idx) => idx < selectedNumber);

        const _presentDeliveryList = await getOrderDeliveryList(
          newOrderDeliveryList,
          { refreshDeliveryCost: false },
          goodPriceList
        );
        await onChangeOrderQuantity({ goodList, quantity: selectedNumber, orderDeliveryList: _presentDeliveryList });
      }
      setDeliveryNumber(selectedNumber);
      setSelectedItem(data);
    },
    [orderDeliveryList, getOrderDeliveryList, goodPriceList, onChangeOrderQuantity, goodList, setDeliveryNumber]
  );
  return (
    <FlexBox width="100%">
      <Accordion initialOpen>
        <Accordion.Header>
          <TextBox size="16px" weight="700">
            받는분
          </TextBox>
          <FlexBox>
            <Accordion.Control buttonPadding="5px 0 0 0" />
          </FlexBox>
        </Accordion.Header>
        <Spacing top="14px" />
        <HorizontalDivider height="1px" color="#141415" />
        <Accordion.Body undead>
          <FlexBoxColumn width="100%">
            <FlexBoxColumn width="100%" align-items="flex-start">
              {isPriceOpen && !isFamilyDiscountOrder && (
                <>
                  <Spacing top="14px" />
                  {itemTypeCount === 1 ? (
                    <FlexBox align-items="center" justify-content="flex-start" gap="10px" width="100%">
                      <TextBox height="36px" size="14px" weight="500">
                        받는분 수
                      </TextBox>
                      <DropdownDevice
                        placeholder=""
                        items={dropdownList}
                        onChange={handleChangeDeliveryNumber}
                        value={selectedItem}
                        width="90px"
                        height="36px"
                      />
                    </FlexBox>
                  ) : (
                    <GuideText>주문 상품 종류가 2가지 이상인 경우, 받는 분은 1명만 지정 가능합니다.</GuideText>
                  )}
                  <Spacing top="14px" />
                  <HorizontalDivider height="1px" color="#eaeaea" />
                </>
              )}
            </FlexBoxColumn>
            <FlexBoxColumn width="100%">
              {deliveryNumber === 1 ? (
                <PresentSingleReceiverContent
                  ordererInformation={ordererInformation}
                  handleChangeDeliveryInfo={handleChangeDeliveryInfo}
                />
              ) : (
                <PresentMultiReceiverContent
                  ordererInformation={ordererInformation}
                  deliveryNumber={deliveryNumber}
                  handleChangeDeliveryInfo={handleChangeDeliveryInfo}
                />
              )}
              <TextBox width="100%" size="12px" line-height="19px" color="#727272">
                결제가 완료되면 받는 분에게 카카오톡 알림톡이 전달되며,
                <br />
                실패 시 SMS 메세지로 대체 발송 됩니다.
              </TextBox>
            </FlexBoxColumn>
          </FlexBoxColumn>
        </Accordion.Body>
      </Accordion>
    </FlexBox>
  );
};

export default PresentReceiverInformation;

PresentReceiverInformation.propTypes = {
  goodList: PropTypes.array,
  ordererInformation: PropTypes.object,
  orderDeliveryList: PropTypes.array,
  handleChangeOrderDeliveryList: PropTypes.func,
  getOrderDeliveryList: PropTypes.func,
  onChangeOrderQuantity: PropTypes.func,
  goodPriceList: PropTypes.array,
  orderItemCount: PropTypes.number,
  deliveryNumber: PropTypes.number,
  setDeliveryNumber: PropTypes.func,
  isFamilyDiscountOrder: PropTypes.bool,
};

const GuideText = styled(TextBox)`
  width: 100%;
  align-items: flex-start;
  font-size: 12px;
  line-height: 19px;
  color: #727272;
`;
