import { useCallback, useEffect, useState, useMemo } from 'react';

const useDepositWithoutBankbook = (paymentMethod, ordererInformation, handleChangePaymentMethod, getBankList) => {
  const [bankList, setBankList] = useState([]);

  const selectedBank = useMemo(() => {
    return { ...bankList?.find((item) => item.key === paymentMethod.inicisBankCode) };
  }, [bankList, paymentMethod]);

  useEffect(() => {
    (async () => {
      const { result } = await getBankList();
      setBankList(
        result?.map(({ inicisBankCode, bankCode, bankName }) => ({
          key: inicisBankCode,
          value: bankCode,
          label: bankName,
        }))
      );
    })();
  }, [getBankList]);

  const handleChangeBank = useCallback(
    (selectedItem) => {
      handleChangePaymentMethod({
        ...paymentMethod,
        bankCode: selectedItem?.value,
        inicisBankCode: selectedItem?.key,
      });
    },
    [handleChangePaymentMethod, paymentMethod]
  );

  return {
    handleChangeBank,
    bankList,
    selectedBank,
  };
};

export default useDepositWithoutBankbook;
