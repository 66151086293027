export const GO_PAY_METHOD = {
  CARD: 'Card',
  PAYCO: 'onlypayco',
  SAMSUNG_PAY: 'onlyssp',
  LPAY: 'onlylpay',
  SSG_PAY: 'onlyssgcard',
  KAKAO_PAY: 'onlykakaopay',
  TOSS_PAY: 'onlytosspay',
  NAVER_PAY: 'onlynaverpay',
  V_BANK: 'VBank',
  REAL_TIME_BANK: 'DirectBank',
  HPP: 'HPP',
};
