import { atom } from 'recoil';

export const alertDialogState = atom({
  key: 'alertDialogStateMo',
  default: {
    open: false,
    message: '',
    onConfirm: () => {},
  },
});

export const confirmDialogState = atom({
  key: 'confirmDialogStateMo',
  default: {
    open: false,
    message: '',
    onConfirm: () => {},
    onClose: () => {},
  },
});

export const toastState = atom({
  key: 'toastStateMo',
  default: [],
});

export const historyState = atom({
  key: 'historyState',
  default: {
    prev: '/',
    current: '/',
  },
});
