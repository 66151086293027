export const alertMsg = {
  ID_NOT_FOUND_ALERT_MSG: '입력하신 정보와 일치하는\n회원정보가 없습니다.\n다시 정확히 확인하여 주시기\n바랍니다.',
  USERNAME_INVALID_ALERT_MSG: '회원명을 정확히 입력해주세요.',
  SEND_FAIL_ALERT_MSG: `임시비밀번호 발송이 실패하였습니다.\n다시 시도해주십시오.`,
};
export const FIND_MODE = { EMAIL: 'EMAIL', CELL_NO: 'CELL_NO' };
export const SEND_VIA = { EMAIL: 'EMAIL', SMS: 'SMS' };

export const LOGIN_TYPE = {
  ID: 'id',
  MOBILE: 'mobile',
  OUTSITE_SSO: 'outsite_sso',
};

export const VALIDATE_MEMBER_RESULT_CODE = {
  VALIDATED: '10',
  NOT_VALIDATED: '20',
  CI_VALUE_EMPTY: '30',
  ASSOCIATE_USER: '40',
  DUPLICATE_CI: '50',
};

export const VALIDATE_MEMBER_FOR_SIGNUP_RESULT_CODE = {
  SUCCESS: '10',
  KNOX_NOT_FOUND: '20',
  ALREADY_SIGNED_UP: '30',
  ASSOCIATE_USER_NOT_FOUND: '40',
  WITHDRAW_USER: '50',
  UNDER_14: '60',
};

export const MEMBER_ENTRY_MEAN_CODE = {
  SABUN: '10', // 사번인증
  EMAIL: '20', // 이메일인증
  ALL: '30', // 사번&이메일인증
  NOTHING: '40', // 인증x
  COUPON: '50', // 쿠폰인증
};

export const SABUN_GUIDE_CONTENT_LIST = ['본인 회사 사번을 입력 후 확인 버튼 클릭', '회원 가입 후 재 로그인 필수'];
export const EMAIL_GUIDE_CONTENT_LIST = [
  '본인 회사 이메일을 입력 후 확인 버튼 클릭',
  '본인 회사 이메일에서 받은 메일함 확인',
  '메일 내 인증번호를 본 임직원 인증 화면 내의 인증 번호 입력란에 입력',
  '회원 가입 후 재 로그인 필수',
  `회원 인증 메일이 오지 않을 시 개인 스팸함 혹은 본인 소속 회사 전산실에 webmaster@welstorymall.com 메일 차단 해제 처리 요청`,
];
