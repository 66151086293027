import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

const SearchFilterPopupHeader = ({ title }) => {
  return (
    <Wrapper>
      <TextBox size={moTheme.font.size.$14} weight={moTheme.font.weight.bold}>
        {title}
      </TextBox>
      <Spacing top={10} />
      <HorizontalDivider height={2} color={theme.color.line.emphasis} />
      <Spacing top={20} />
    </Wrapper>
  );
};

const Wrapper = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

export default SearchFilterPopupHeader;

SearchFilterPopupHeader.propTypes = {
  title: PropTypes.string,
};
