import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn, FlexBoxColumnButton } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import ContextMenu from '@ecp/common/src/components/contextmenu/mo/ContextMenu';
import { TextAButton } from '@ecp/common/src/components/button/mo/TextAButton';

const SortItem = ({ itemList, handleSortChange }) => {
  return (
    <FlexBoxColumn width={'100px'} align-items={'flex-start'} data-ds-label2={'sort_type'}>
      {itemList.map((sort, index) => (
        <SortItemWrapper key={index}>
          <TextBox onClick={() => handleSortChange(sort)}>{sort.label}</TextBox>
        </SortItemWrapper>
      ))}
    </FlexBoxColumn>
  );
};

const SortItemWrapper = styled(FlexBox)`
  padding-bottom: 14px;

  :last-child {
    padding-bottom: 0;
  }
`;

SortItem.propTypes = {
  itemList: PropTypes.array,
  handleSortChange: PropTypes.func,
};

const GoodSort = ({ sortLabel, itemList, handleSortChange }) => {
  const [open, setOpen] = useState(false);

  const handleSortClick = useCallback(
    (e) => {
      setOpen(false);
      handleSortChange(e);
    },
    [handleSortChange]
  );
  const handleOpenChange = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <FlexBoxColumnButton
      align-items={'flex-end'}
      type="button"
      title={open ? '목록 정렬 옵션 닫기' : '목록 정렬 옵션 펼치기'}
      placeholder={'선택하세요'}
    >
      <ContextMenu
        openWhenClicked
        closeWhenOutside
        open={open}
        onOpenChange={handleOpenChange}
        placement={'bottom-end'}
        value={<SortItem itemList={itemList} handleSortChange={handleSortClick} />}
      >
        <FlexBox>
          <TextAButton
            type={'list'}
            size={moTheme.font.size.$14}
            weight={moTheme.font.weight.medium}
            data-ds-label2={'sort_type'}
          >
            {sortLabel}
          </TextAButton>
        </FlexBox>
      </ContextMenu>
    </FlexBoxColumnButton>
  );
};

export default GoodSort;

GoodSort.propTypes = {
  sortLabel: PropTypes.string,
  itemList: PropTypes.array,
  handleSortChange: PropTypes.func,
};
