import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import theme from '@ecp/common/src/style/theme/default';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Accordion from '@ecp/common/src/components/accordion/mo/Accordion';

import { getOrderItemCount } from '@mo-utils/orderUtils';
import OrderGoodItemInformationSingle from '@mo-components/order/payment/OrderGoodItemInformationSingle';
import OrderValidContext from '@mo-components/order/payment/OrderValidContext';

const OrderGoodItemListSingle = ({
  goodList,
  postNumber,
  regularDelivery,
  isPresent = false,
  receiverCount = 1,
  deliveryCost = 0,
}) => {
  const { deliveryDisableList } = useContext(OrderValidContext);
  const itemTypeCount = useMemo(() => {
    return getOrderItemCount(goodList);
  }, [goodList]);

  const itemList = useMemo(() => {
    return goodList
      .map((goodItem) => {
        return goodItem.itemList.map((item) => {
          const deliveryDisable =
            deliveryDisableList &&
            deliveryDisableList.includes(`${postNumber}-${goodItem.goodNumber}-${item.itemNumber}`);
          const multiReceiverCount = isPresent ? receiverCount : 1;

          return (
            <OrderGoodItemInformationSingle
              key={`${goodItem.goodName}-${item.itemName}`}
              goodInfo={goodItem}
              itemInfo={item}
              regularDelivery={regularDelivery}
              multiReceiverCount={multiReceiverCount}
              {...(deliveryDisable ? { errorMessage: '제주, 도서산간 등 배송 불가' } : {})}
            />
          );
        });
      })
      .flat();
  }, [goodList, deliveryDisableList, postNumber, regularDelivery, isPresent, receiverCount]);

  return (
    <Container>
      <Accordion initialOpen>
        <Accordion.Header>
          <HeaderWrapper>
            <HeaderTextBox>주문상품</HeaderTextBox>
            <ItemCountTextBox>
              <TextBox weight="700">{itemTypeCount}</TextBox>건
            </ItemCountTextBox>
          </HeaderWrapper>
          <FlexBox height={'26px'} align-items="center">
            {isPresent && itemTypeCount === 1 && (
              <>
                <TextBox>{`배송비 `}</TextBox>
                <TextBox weight="700">{NumberFormat(deliveryCost)}</TextBox>
                <TextBox>원</TextBox>
                <Spacing left={'14px'} />
              </>
            )}
            <FlexBox>
              <Accordion.Control buttonPadding="5px 0 0 0" />
            </FlexBox>
          </FlexBox>
        </Accordion.Header>
        <Spacing top="10px" />
        <HorizontalDivider color="#141415" />

        <Accordion.Body undead>
          <BodyContainer>
            <Spacing top={6} />
            <ItemListContainer>{itemList}</ItemListContainer>
          </BodyContainer>
        </Accordion.Body>
      </Accordion>
    </Container>
  );
};

export default OrderGoodItemListSingle;

OrderGoodItemListSingle.propTypes = {
  goodList: PropTypes.array,
  postNumber: PropTypes.string,
  regularDelivery: PropTypes.object,
  isPresent: PropTypes.bool,
  receiverCount: PropTypes.number,
  deliveryCost: PropTypes.number,
};

const Container = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
`;

const BodyContainer = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const HeaderWrapper = styled(FlexBox)`
  height: 26px;
  align-items: center;
  gap: 6px;
`;
const HeaderTextBox = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
`;

const ItemCountTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 350;
  color: ${theme.color.primary};
`;

const ItemListContainer = styled(FlexBoxColumn)`
  width: 100%;
  align-items: center;
`;
