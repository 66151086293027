import isEmpty from 'lodash-es/isEmpty';

import { deleteRequest, getRequest, postRequest, putRequest } from '@mo-apis/axios';

const updateUserPassword = async (payload = {}) => {
  const res = await putRequest({
    url: `/api/v1/member/my-info/password`,
    data: { ...payload },
  });

  return res?.code;
};

const getMemberInfo = () =>
  getRequest({
    url: `/api/v1/member/my-info`,
  });

const updateAdultCertificationDate = (mobiliansResult) =>
  putRequest({
    url: '/api/v1/member/my-info/adult-cert',
    data: mobiliansResult,
  });
const getWithdrawReasonList = () =>
  getRequest({
    url: '/api/v1/member/my-info/withdraw-reason',
  });
const withdrawUser = (data) =>
  deleteRequest({
    url: '/api/v1/member/my-info/withdraw',
    data: data,
  });
const getMemberInfoDetail = (payload) =>
  postRequest({
    url: `/api/v1/member/my-info?detail`,
    data: { ...payload },
  });
const updateMyInfoDetail = (payload = {}) =>
  putRequest({
    url: `/api/v1/member/my-info?detail`,
    data: { ...toUpdateRequest(payload) },
  });
const toUpdateRequest = (data) => {
  const [cellphoneNumber1, cellphoneNumber2, cellphoneNumber3] = data.cellphoneNumber.split('-');
  const email =
    data.emailDomain.key === 'custom'
      ? `${data.emailId}@${data.customEmailDomain}`
      : `${data.emailId}@${data.emailDomain.value}`;
  const interestCodeList = data.myInterestList.filter((i) => i.checked).map((i) => i.code);

  return {
    loginId: data.loginId,
    memberName: data.memberName,
    email,
    cellphoneNumber1,
    cellphoneNumber2,
    cellphoneNumber3,
    birthday: data.birthday,
    emailReceive: data.emailReceive,
    smsReceive: data.smsReceive,
    genderCode: isEmpty(data.myGenderList?.filter((i) => i?.checked))
      ? ''
      : data.myGenderList?.filter((i) => i?.checked)[0].code,
    interestCodeList,
    certificationInfo: data.certificationInfo,
    password: data.password,
  };
};

const updateEmailDisagree = (payload = {}) =>
  putRequest({
    url: `/api/v1/member/my-info/dm-email-disagree`,
    data: { ...payload },
  });

const updateMarketingAgreementAndIssueCoupon = (payload = {}) =>
  putRequest({
    url: '/api/v1/member/my-info/marketing-agreement-info-and-issue-coupon',
    data: { ...payload },
  });

const updateMultimallCertInfo = (payload) =>
  putRequest({
    url: '/api/v1/member/my-info/update-multimall-certinfo',
    data: payload,
  });
    
const memberMyInfoApi = {
  updateUserPassword,
  getMemberInfo,
  updateAdultCertificationDate,
  getWithdrawReasonList,
  withdrawUser,
  getMemberInfoDetail,
  updateMyInfoDetail,
  updateEmailDisagree,
  updateMarketingAgreementAndIssueCoupon,
  updateMultimallCertInfo,
};

export default memberMyInfoApi;
