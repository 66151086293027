import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { openWindow } from '@ecp/common/src/utils/utils';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBoxButton } from '@ecp/common/src/text/TextBoxButton';

import { withMobileStatus } from '@mo-recoil/system/selector';

const Menu = ({ link, target, children, onTotalMenuClose, ...props }) => {
  const { pathname } = useLocation();
  const { isMobileApp, agentType } = useRecoilValue(withMobileStatus);

  const active = useMemo(() => pathname === link, [link, pathname]);

  const handleMenuClick = useCallback(
    (e) => {
      if (!link) {
        e.stopPropagation();
        e.preventDefault();
      } else {
        openWindow(isMobileApp, link, target, agentType);
      }
      onTotalMenuClose?.();
    },
    [agentType, isMobileApp, link, onTotalMenuClose, target]
  );

  return (
    <MenuText $active={active} link={link} title={children} onClick={handleMenuClick} {...props}>
      {children}
    </MenuText>
  );
};

Menu.propTypes = {
  link: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.string,
  onTotalMenuClose: PropTypes.func,
};

const MenuGroups = ({ gnbMenuList, onTotalMenuClose = () => {}, ...props }) => (
  <>
    {gnbMenuList.map(({ title, link, target }, index) => (
      <Menu key={index} link={link} target={target} {...props} onTotalMenuClose={onTotalMenuClose}>
        {title}
      </Menu>
    ))}
    <Spacing right={34} />
  </>
);

export default MenuGroups;

MenuGroups.propTypes = {
  gnbMenuList: PropTypes.array,
  onTotalMenuClose: PropTypes.func,
};

const MenuText = styled(TextBoxButton)`
  position: relative;
  color: ${({ $active }) => ($active ? theme.color.text.white : theme.color.text.basic)};
  font-size: ${moTheme.font.size.$13};
  font-weight: ${moTheme.font.weight.demiLight};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: ${({ link }) => (link ? 'pointer' : 'default')};

  width: calc(100% / 3);
  height: 45px;
  box-sizing: border-box;
  background-color: ${({ $active }) => ($active ? '#3c3c3c' : theme.color.background.white)};
  z-index: 2;

  border-bottom: 1px solid ${theme.color.line.basic};
  border-right: 1px solid ${theme.color.line.basic};

  &:nth-child(3n + 1) {
    border-left: 1px solid ${theme.color.line.basic};
  }

  &:not(:nth-child(3n + 1)) {
    border-left: none;
  }

  &:nth-child(-n + 3) {
    border-top: 1px solid ${theme.color.line.basic};
  }

  &:not(:nth-child(-n + 3)) {
    border-top: none;
  }
`;
