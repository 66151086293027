import { getRequest, postRequest, putRequest } from '@mo-apis/axios';

const prefix = '/api/v1/coupon';

const getMyCouponList = (pageParams) =>
  getRequest({
    url: `${prefix}/my-coupon-list`,
    params: { ...pageParams },
  });

const getMyCouponListCount = () =>
  getRequest({
    url: `${prefix}/my-coupon-list-count`,
  });

const getDownloadableCouponCount = () =>
  getRequest({
    url: `${prefix}/downloadable-count`,
  });

const getMyDownloadableCouponList = (pageParams) => {
  if (pageParams) {
    return getRequest({
      url: `${prefix}/downloadable-page`,
      params: { ...pageParams },
    });
  }
  return getRequest({
    url: `${prefix}/downloadable-list`,
  });
};

const downloadCoupon = (promotionNo) =>
  getRequest({
    url: `${prefix}/download/${promotionNo}`,
  });

const downloadAllCoupon = (data) =>
  postRequest({
    url: `${prefix}/download-all`,
    data,
  });

const getApplyGoodListWithPromotionNumberCount = (promotionNo) =>
  getRequest({
    url: `${prefix}/${promotionNo}/apply-good-count`,
  });

const getApplyGoodListWithPromotionNumber = (params, promotionNo) =>
  getRequest({
    url: `${prefix}/${promotionNo}/apply-good`,
    params,
  });

const getBirthDayCoupon = () =>
  getRequest({
    url: `${prefix}/birthday`,
  });

const getFirstCouponList = () =>
  getRequest({
    url: `${prefix}/first-coupon-list`,
  });

const getEmployeeBenefitSummary = () =>
  getRequest({
    url: `${prefix}/employee-benefit-summary`,
  });

const getBrandBenefitSummary = (brandNumber) =>
  getRequest({
    url: `${prefix}/brand-benefit-summary`,
    params: { brandNumber },
  });

const getCategoryBenefitSummary = (categoryNumber) =>
  getRequest({
    url: `${prefix}/category-benefit-summary`,
    params: { categoryNumber },
  });

const getEmployeeBenefitCouponList = (applyType, id) =>
  postRequest({
    url: `${prefix}/employee-benefit-coupon`,
    data: { applyType, id },
  });

const getEmployeeBenefitCouponCount = (applyType, id) =>
  postRequest({
    url: `${prefix}/employee-benefit-coupon-count`,
    data: { applyType, id },
  });

const updateUseProcessingCoupon = (resourceManagementNumber) =>
  putRequest(
    {
      headers: {
        'Content-Type': 'text/plain'
      },
      url: `${prefix}/use-processing-coupon`,
      data: resourceManagementNumber.toString(),
    },
    { skipSpinner: true }
  );

export default {
  getMyCouponList,
  getMyCouponListCount,
  getDownloadableCouponCount,
  getMyDownloadableCouponList,
  downloadCoupon,
  downloadAllCoupon,
  getApplyGoodListWithPromotionNumberCount,
  getApplyGoodListWithPromotionNumber,
  getBirthDayCoupon,
  getFirstCouponList,
  getEmployeeBenefitSummary,
  getBrandBenefitSummary,
  getCategoryBenefitSummary,
  getEmployeeBenefitCouponList,
  getEmployeeBenefitCouponCount,
  updateUseProcessingCoupon,
};
