import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import { getSessionStorageItem, saveSessionStorageItem } from '@ecp/common/src/utils/utils';
import { useGetRecentlyViewedGoodList } from '@ecp/common/src/hooks/user/useRecentlyViewedGoodList';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { MAIN_FUNCTION_TYPE_CODE } from '@ecp/common/src/const/display/mainTemplateConst';

import { categoryListState } from '@mo-recoil/category/atom';
import { withMobileStatus } from '@mo-recoil/system/selector';
import { recentlyViewedGoodListState, userInfoState } from '@mo-recoil/common/user/atom';
import { commonMainFunctionListState } from '@mo-recoil/main/atom';
import { useBenefitCard, useNavigationMenu } from '@mo-hooks/common/useNavigation';
import mainFunctionApi from '@mo-apis/display/mainFunctionApi';
import goodListApi from '@mo-apis/good/goodListApi';
// import mobilePushApi from '@mo-apis/push/mobilePushApi';
import displayApi from '@mo-apis/display/displayApi';
import Gnb from '@mo-components/common/gnb/Gnb';
import Navigation from '@mo-components/common/navigation/Navigation';
import PageFooter from '@mo-pages/home/footer/PageFooter';
import { ANDROID, iOS } from '@mo-const/system/mobileConst';
import useSiteInfo from '@mo-hooks/common/useSiteInfo';
import EventMallGnb from '@mo-pages/eventMall/gnb/Gnb';

const HomePage = () => {
  const { isEventMall, getSystemInformationByKey } = useSiteInfo();
  const setCategoryList = useSetRecoilState(categoryListState);
  const { memberNumber } = useRecoilValue(userInfoState);
  const { neededUpdate, isMobileApp, agentType } = useRecoilValue(withMobileStatus);
  const userInfo = useRecoilValue(userInfoState);
  const [recentlyViewedList, setRecentlyViewedList] = useRecoilState(recentlyViewedGoodListState);
  const [commonMainFunctionList, setCommonMainFunctionList] = useRecoilState(commonMainFunctionListState);
  const pushUpdate = getSessionStorageItem('pushUpdate') || false;

  useNavigationMenu();
  useBenefitCard();
  useGetRecentlyViewedGoodList(userInfo, recentlyViewedList, setRecentlyViewedList, goodListApi.getGoodDisplayableList);

  useEffect(() => {
    console.debug("siteName: ", getSystemInformationByKey('siteName'), ", isEventMall: ", isEventMall, ", siteTypeCode:",getSystemInformationByKey('siteTypeCode'));
    
    (async () => {
      if (!commonMainFunctionList.length) {
        const { result: commonList } = await mainFunctionApi.getMainFunctionList(MAIN_FUNCTION_TYPE_CODE.COMMON);
        setCommonMainFunctionList(commonList);
      }
    })();
  }, [commonMainFunctionList.length, setCommonMainFunctionList]);

  useEffect(() => {
    (async () => {
      const { result } = await displayApi.getDisplayCategory({ skipSpinner: true });
      setCategoryList(result);
    })();
  }, [setCategoryList, memberNumber]);

  useEffect(() => {
    (async () => {
      if (isMobileApp) {
        if (agentType === ANDROID) {
          window.Welstorymall?.getNeededAppUpdate(neededUpdate);
        } else if (agentType === iOS) {
          window.webkit?.messageHandlers?.getNeededAppUpdate?.postMessage(neededUpdate);
        }

        if (!pushUpdate) {
          // await mobilePushApi.updatePushStatusFromAgent();
          if (agentType === ANDROID) {
            window?.Welstorymall?.displayMarketingPushPopup();
          } else if (agentType === iOS) {
            window?.webkit?.messageHandlers?.displayMarketingPushPopup?.postMessage(null);
          }
          saveSessionStorageItem('pushUpdate', true);
        }
      }
    })();
  }, [agentType, isMobileApp, neededUpdate, pushUpdate]);

  return (
    <Container>
      {isEventMall ? <EventMallGnb /> : <Gnb />}
      <FlexBoxColumn width={'360px'} flex={1} id="contents">
        <Outlet />
      </FlexBoxColumn>
      <PageFooter />
      {!isEventMall && <Navigation />}
    </Container>
  );
};

const Container = styled(FlexBoxColumn)`
  overflow: visible;
  width: 360px;
  min-height: calc(var(--vh, 1vh) * 100);
  max-width: 360px;
  margin: 0 auto;
  position: relative;
`;

export default HomePage;
