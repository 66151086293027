import { getFilePath } from '@ecp/common/src/utils/fileUtil';

export const getDisplayTypes = (exhibition) => {
  if (!exhibition) return '';
  const result = [];
  if (exhibition.badgeName01) {
    result.push({ name: exhibition.badgeName01 });
  }
  if (exhibition.badgeName02) {
    result.push({ name: exhibition.badgeName02 });
  }
  return result;
};

export const mapExhibitionMOData = (exhibitionList, topExposed = false) => {
  return exhibitionList?.map((exhibition) => ({
    title: exhibition.name,
    subTitle: exhibition.subName,
    displayTypes: getDisplayTypes(exhibition),
    src: topExposed ? getFilePath(exhibition.commonImageFilePath) : getFilePath(exhibition.mobileImageFilePath),
    exhibitionNumber: exhibition.exhibitionNumber,
    hashTagList: exhibition.hashTagList || [],
    registerDatetime: exhibition.registerDatetime,
  }));
};
