import { getRequest, postRequest, deleteRequest, putRequest } from '@mo-apis/axios';

const getWelfarePoint = () =>
  getRequest({
    url: '/api/v1/mypage/asset-point/get-welfare-point',
  });

const getDeliveryLocations = (pageParams) =>
  getRequest({
    url: '/api/v1/member/delivery/location',
    params: { ...pageParams },
  });

const getBaseDeliveryLocation = async () => {
  return await getRequest({
    url: '/api/v1/member/delivery/location-base',
  });
};

const createDeliverLocation = (payload = {}) =>
  postRequest({
    url: `/api/v1/member/delivery/location`,
    data: { ...payload },
  });

const updateDeliverLocation = (deliverySequence, payload = {}) =>
  putRequest({
    url: `/api/v1/member/delivery/location/${deliverySequence}`,
    data: { ...payload },
  });

const deleteDeliverLocation = (deliverySequence) =>
  deleteRequest({
    url: `/api/v1/member/delivery/location/${deliverySequence}`,
  });

const memberApi = {
  getWelfarePoint,
  getDeliveryLocations,
  getBaseDeliveryLocation,
  createDeliverLocation,
  updateDeliverLocation,
  deleteDeliverLocation,
};

export default memberApi;
