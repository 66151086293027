import { getRequest, postRequest, putRequest } from '@mo-apis/axios';

const eventUrl = 'api/v1/event';

const getEventList = (query, state, pageParams) =>
  getRequest({
    url: `${eventUrl}`,
    params: { query, state, ...pageParams },
  });

const getEventResultList = (pageParams) =>
  getRequest({
    url: `${eventUrl}/result`,
    params: {
      ...pageParams,
    },
  });

const getTopEventList = () =>
  getRequest({
    url: `${eventUrl}/top`,
    params: {},
  });

const getEventData = (eventNo) =>
  getRequest({
    url: `${eventUrl}/${eventNo}`,
  });

const getEventResultInfo = (eventNo) =>
  getRequest({
    url: `${eventUrl}/result/${eventNo}`,
  });

const applyEvent = (payload = {}) =>
  postRequest({
    url: `${eventUrl}/apply`,
    data: { ...payload },
  });

const postEventAttendance = (payload = {}) =>
  postRequest({
    url: `${eventUrl}/attendance`,
    data: { ...payload },
  });

const postEventCommentsData = (payload = {}) =>
  postRequest({
    url: `${eventUrl}/create-event-comment`,
    data: payload,
  });

const putEventCommentsList = (payload) =>
  putRequest({
    url: `${eventUrl}/set-event-comments-list`,
    data: payload,
  });
const getEventCommentsList = (eventNo, pageParams) =>
  getRequest({
    url: `${eventUrl}/comments-list/${eventNo}`,
    params: { ...pageParams },
  });

const getNewestEvent = () => getRequest({ url: `${eventUrl}/newest-event` });

const postCoupon = (payload = {}) =>
  postRequest({
    url: `${eventUrl}/coupon`,
    data: { ...payload },
  });
/*
 * legacy below
 * */

const eventApi = {
  getEventList,
  getEventResultList,
  getTopEventList,
  getEventData,
  getEventResultInfo,
  postEventAttendance,
  applyEvent,
  postEventCommentsData,
  putEventCommentsList,
  getEventCommentsList,
  getNewestEvent,
  postCoupon,
};

export default eventApi;
