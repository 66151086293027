import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Paper from '@ecp/common/src/components/paper/Paper';

import { ReactComponent as BulRound } from '@mo-assets/image/common/bullet/bul_round01.svg';
import { ReactComponent as BulNotice } from '@mo-assets/image/common/bullet/bul_notice.svg';

const NoticeBulletGuideBox = ({ title, textList, outerPadding, innerPadding }) => {
  return (
    <InfoWrap outerPadding={outerPadding}>
      <InfoContents innerPadding={innerPadding}>
        {title && (
          <>
            <TextBox size={12} weight="500" color="#141415">
              <BulNotice />
              <Spacing left="6px" />
              {title}
            </TextBox>
            <Spacing top="6px" />
          </>
        )}
        {textList.map((text, index) => (
          <BulList key={`${index}-${text.slice(0, 2)}`} width="100%" size={12} color="#5a5a5a" align-items="flex-start">
            <BulRoundWrap>
              <BulRound width="3px" height="3px" />
            </BulRoundWrap>
            {text}
          </BulList>
        ))}
      </InfoContents>
    </InfoWrap>
  );
};

export default NoticeBulletGuideBox;

NoticeBulletGuideBox.propTypes = {
  title: PropTypes.string,
  textList: PropTypes.array,
  outerPadding: PropTypes.string,
  innerPadding: PropTypes.string,
};

const InfoWrap = styled(Paper)`
  width: 100%;
  padding: ${({ outerPadding }) => (outerPadding ? outerPadding : '20px')};
  border-top: none;
`;

const InfoContents = styled(Paper)`
  width: 100%;
  padding: ${({ innerPadding }) => (innerPadding ? innerPadding : '14px')};
  background: #f7f7f7;
  font-size: 12px;
  line-height: 19px;
  color: #5a5a5a;
`;

const BulRoundWrap = styled.div`
  width: 9px;
  min-width: 9px;
  height: 19px;
  display: flex;
  align-items: center;
`;

const BulList = styled(TextBox)`
  position: relative;
`;
