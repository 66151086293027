import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import {
  getQueryStringInSubCategoryPage,
  initialPageParamInMobileSubCategoryPage,
  mobileNavigateWithFilterInfoTable,
} from '@ecp/common/src/utils/good/subCategoryUtil';
import useNavigateWithFilter from '@ecp/common/src/hooks/search/useNavigateWithFilter';
import useScrollToPreviousGood from '@ecp/common/src/hooks/search/useScrollToPreviousGood';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { GOOD_PRICE_FILTER_LIST } from '@ecp/common/src/const/good';

import { getPageQueryParams } from '@mo-utils/commonUtils';
import { withCanDisplayCategoryById } from '@mo-recoil/category/selector';
import { historyState } from '@mo-recoil/common/atom';
import {
  subCategoryGoodListState,
  subCategoryGoodListUrlState,
  subCategoryGoodTotalCountState,
} from '@mo-recoil/category/atom';
import { useGnbTitle } from '@mo-hooks/common/useGnb';
import displayCategoryApi from '@mo-apis/display/displayCategoryApi';
import DisplayBreadcrumb from '@mo-components/display/DisplayBreadcrumb';
import SwiperSubCategoryLabel from '@mo-components/display/SwiperSubCategoryLabel';
import GoodCardListBySubCategory from '@mo-components/display/GoodCardListBySubCategory';
import SearchFilterBar from '@mo-components/display/filter/SearchFilterBar';
import { FILTER_TYPE } from '@mo-pages/search/result/searchOption';

const SubCategoryPage = () => {
  const { categoryNumber } = useParams();
  const categoryDetail = useRecoilValue(withCanDisplayCategoryById(categoryNumber));
  const history = useRecoilValue(historyState);
  const [storedGoodList, setStoredGoodList] = useRecoilState(subCategoryGoodListState);
  const [storedUrl, setStoredUrl] = useRecoilState(subCategoryGoodListUrlState);
  const [storedTotalCount, setStoredTotalCount] = useRecoilState(subCategoryGoodTotalCountState);

  const [goodList, setGoodList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [brandList, setBrandList] = useState([]);

  useGnbTitle(categoryDetail?.categoryName);

  const {
    searchCondition,
    pageParam,
    setPageParam,
    handleRefresh,
    handleSortChange,
    fetchNextPage,
    handleApplyFilter,
    handleChangeFilter,
    handleViewTypeChange,
  } = useNavigateWithFilter(mobileNavigateWithFilterInfoTable);

  const getGoodListBySubDisplayCategory = useCallback(async () => {
    if (!categoryDetail) {
      return;
    }
    const url = `${window.location.pathname}?${getQueryStringInSubCategoryPage(searchCondition, pageParam)}`;
    if (url === storedUrl) {
      return;
    }

    const {
      result: { content = [], totalElements },
    } = await displayCategoryApi.getGoodListByDisplayCategory(
      {
        categoryNumber: categoryDetail.categoryNumber,
        depthNumber: categoryDetail.depthNumber,
        brandList: searchCondition?.brandList?.map((value) => value),
        minPrice: searchCondition?.price?.min,
        maxPrice: searchCondition?.price?.max,
      },
      getPageQueryParams(pageParam.page, pageParam.countPerPage, pageParam.sort?.value)
    );
    if (pageParam.page === 1) {
      setGoodList(content);
      setStoredGoodList(content);
    } else {
      setGoodList((prev) => (prev ? [...prev, ...content] : content));
      setStoredGoodList((prev) => (prev ? [...prev, ...content] : content));
    }
    setTotalCount(totalElements);
    setStoredUrl(url);
    setStoredTotalCount(totalElements);
  }, [categoryDetail, pageParam, searchCondition, setStoredGoodList, setStoredTotalCount, setStoredUrl, storedUrl]);

  useEffect(() => {
    (async () => {
      await getGoodListBySubDisplayCategory();
    })();
  }, [getGoodListBySubDisplayCategory]);

  const { storedGoodCardRef } = useScrollToPreviousGood({
    pathVariable: categoryNumber,
    history,
    mobileInitialPageParam: initialPageParamInMobileSubCategoryPage,
    goodList,
    storedGoodList,
    storedUrl,
    storedTotalCount,
    setGoodList,
    setTotalCount,
    setPageParam,
    setStoredGoodList,
  });

  const getTotalBrandList = useCallback(async () => {
    const { result } = await displayCategoryApi.getCategoryBrandList(categoryNumber, null);
    setBrandList(result);
  }, [categoryNumber]);

  const brandNumberToName = useMemo(() => {
    return brandList?.reduce((acc, elem) => {
      acc[elem?.brandNumber] = elem?.brandName;
      return acc;
    }, {});
  }, [brandList]);

  useEffect(() => {
    (async () => await getTotalBrandList())();
  }, [getTotalBrandList]);

  const handleSubDisplayCategoryGoodSoldOutChangeList = useCallback(async () => {
    if (!categoryDetail) {
      return;
    }

    const {
      result: { content = [], totalElements },
    } = await displayCategoryApi.getGoodListByDisplayCategory(
      {
        categoryNumber: categoryDetail.categoryNumber,
        depthNumber: categoryDetail.depthNumber,
        brandList: searchCondition?.brandList?.map((value) => value),
        minPrice: searchCondition?.price?.min,
        maxPrice: searchCondition?.price?.max,
      },
      getPageQueryParams(pageParam.page, pageParam.countPerPage, pageParam.sort?.value)
    );

    if (pageParam.page === 1) {
      setGoodList(content);
      setStoredGoodList(content);
    } else {
      setGoodList((prev) => (prev ? [...prev, ...content] : content));
      setStoredGoodList((prev) => (prev ? [...prev, ...content] : content));
    }
    setTotalCount(totalElements);
    setStoredTotalCount(totalElements);
  }, [categoryDetail, pageParam, searchCondition, setStoredGoodList, setStoredTotalCount, setStoredUrl, storedUrl]);

  return (
    <Container>
      <DisplayBreadcrumb categoryNumber={categoryNumber} />
      <HorizontalDivider />
      {categoryDetail && <SwiperSubCategoryLabel categoryDetail={categoryDetail} />}
      <HorizontalDivider />
      <FlexBox width={'100%'}>
        <SearchFilterBar
          filterOptions={[
            { key: FILTER_TYPE.BRAND, label: '브랜드', 'data-ds-label2': 'brand_filter' },
            { key: FILTER_TYPE.PRICE, label: '가격', 'data-ds-label2': 'price_filter' },
          ]}
          searchCondition={searchCondition}
          brandNumberToName={brandNumberToName}
          brandList={brandList}
          priceList={GOOD_PRICE_FILTER_LIST}
          handleApplyFilter={handleApplyFilter}
          handleRefreshFilter={handleRefresh}
          handleChangeFilter={handleChangeFilter}
        />
      </FlexBox>
      {categoryDetail && (
        <GoodCardListBySubCategory
          storedGoodCardRef={storedGoodCardRef}
          pageProps={pageParam}
          totalCount={totalCount}
          goodList={goodList}
          handleSortChange={handleSortChange}
          fetchNextPage={fetchNextPage}
          handleViewTypeChange={handleViewTypeChange}
          handleSubDisplayCategoryGoodSoldOutChangeList={handleSubDisplayCategoryGoodSoldOutChangeList}
        />
      )}
    </Container>
  );
};

export default SubCategoryPage;

const Container = styled(FlexBoxColumn)`
  width: 360px;
`;
