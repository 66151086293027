import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { openWindow } from '@ecp/common/src/utils/utils';
import { MAIN_FUNCTION_CODE } from '@ecp/common/src/const/display/mainTemplateConst';
import { BANNER_CONTENTS_DIVISION_CODE } from '@ecp/common/src/const/display/bannerConst';

import { bannerMainFunctionState } from '@mo-recoil/banner/atom';
import { withMobileStatus } from '@mo-recoil/system/selector';
import displayApi from '@mo-apis/display/displayApi';

const useBanner = (mainFunctionKindCode, bannerList, setBannerList, maxExposureCount = 20) => {
  const navigate = useNavigate();
  const [bannerMainFunction, setBannerMainFunction] = useRecoilState(bannerMainFunctionState);
  const { isMobileApp, agentType } = useRecoilValue(withMobileStatus);

  useEffect(() => {
    (async () => {
      if (!bannerList.length) {
        const { result } = await displayApi.getBannerList(mainFunctionKindCode);
        setBannerList(result?.bannerList.slice(0, maxExposureCount));
        setBannerMainFunction((prev) => ({ ...prev, [mainFunctionKindCode]: result?.mainFunction }));
      }
    })();
  }, [bannerList.length, mainFunctionKindCode, maxExposureCount, setBannerList, setBannerMainFunction]);

  const handleClick = (e, index, item) => {
    e.preventDefault();
    const targetUrl = item.bannerMobileLinkUrl;
    if (!targetUrl) {
      e.stopPropagation();

      return;
    }

    if (item.bannerMobileLinkBlank) {
      openWindow(isMobileApp, targetUrl, '_blank', agentType);
    } else if (item.bannerContentsDivisionCode === BANNER_CONTENTS_DIVISION_CODE.ETC) {
      openWindow(isMobileApp, targetUrl, '_self', agentType);
    } else {
      navigate(targetUrl);
    }
  };

  const dsLabel = useMemo(() => {
    switch (mainFunctionKindCode) {
      case MAIN_FUNCTION_CODE.AD_BANNER:
        return 'ad_banner';
      case MAIN_FUNCTION_CODE.STRIP_BANNER_ONE:
        return 'banner_1';
      case MAIN_FUNCTION_CODE.STRIP_BANNER_TWO:
        return 'banner_2';
      case MAIN_FUNCTION_CODE.STRIP_BANNER_COMMON:
      default:
        return 'common_banner';
    }
  }, [mainFunctionKindCode]);

  return { handleClick, mainFunction: bannerMainFunction?.[mainFunctionKindCode], dsLabel };
};

export default useBanner;
