import React, { useEffect, useState } from 'react';
import mobiliansApi from "@mo-apis/interface/mobiliansApi";
import PropTypes from 'prop-types';
import { PAY_MEAN_CODE } from '@ecp/common/src/const/interface/payMeanCode';

const MobileMobiliansPayForm = ({ data, onComplete }) => {
  const [payData, setPayData] = useState({});
  // const { isMobileApp } = useRecoilValue(withMobileStatus);

  useEffect(() => {
    (async () => {
      const { result } = await mobiliansApi.getMobiliansHpInformtion(data);
      setPayData(result);
    })();
  }, [setPayData]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = payData.actionUrl;
    document.head.appendChild(script);
    script.onload = () => {
      window.MCASH_PAYMENT(document.getElementById('mobiliansPayForm'));
    };
    onComplete?.();
  }, [payData.actionUrl, onComplete]);

  return (
    <form id="mobiliansPayForm" method="post">
      <input type="hidden" name="CASH_GB" id="CASH_GB" value="MC" />
      <input type="hidden" name="MC_SVCID" id="MC_SVCID" value={payData.mcSvcId || ''} />
      <input type="hidden" name="Prdtprice" id="Prdtprice" value={payData.prdtPrice || ''} />
      <input type="hidden" name="PAY_MODE" id="PAY_MODE" value={payData.payMode || ''} />
      <input type="hidden" name="Okurl" id="Okurl" value={payData.okUrl || ''} />
      <input type="hidden" name="Prdtnm" id="Prdtnm" value={payData.prdtNm || ''} />
      <input type="hidden" name="Siteurl" id="Siteurl" value={payData.siteUrl || ''} />
      <input type="hidden" name="LOGO_YN" id="LOGO_YN" value={payData.logoYn || ''} />
      <input type="hidden" name="CALL_TYPE" id="CALL_TYPE" value={'SELF'} />
      <input type="hidden" name="MC_AUTHPAY" id="MC_AUTHPAY" value={payData.mcAuthPay || ''} />
      <input type="hidden" name="MC_No" id="MC_No" value={payData.mcNo || ''} />
      <input type="hidden" name="Payeremail" id="Payeremail" value={payData.payerEmail || ''} />
      <input type="hidden" name="Callback" id="Callback" value={payData.callback || ''} />
      <input type="hidden" name="Tradeid" id="Tradeid" value={payData.tradeId || ''} />
      <input type="hidden" name="Cryptokurl" id="Cryptokurl" value={payData.cryptokurl || ''} />
      <input type="hidden" name="MC_FIXCOMMID" id="MC_FIXCOMMID" value={payData.mcFixCommId || ''} />
      <input type="hidden" name="Closeurl" id="Closeurl" size="30" value={payData.closeUrl || ''} />
      <input type="hidden" name="MSTR" value={payData.popupCloseUrl || ''} />
      <input type="hidden" name="onlyEncrypt" value="Y" />
      <input type="hidden" name="Sendtype" value="POST" />
    </form>
  );
};

export const inicisData = PropTypes.shape({
  actionUrl: PropTypes.string,
  goodName: PropTypes.string, //상품명
  buyerName: PropTypes.string, //구매자명
  buyerTel: PropTypes.string, // 구매자핸드폰번호
  buyerEmail: PropTypes.string, //구매자 이메일
  price: PropTypes.number, // 금액
  oid: PropTypes.string, // 주문번호
  payMeanCode: PropTypes.oneOf([Object.values(PAY_MEAN_CODE)]), //지불수단코드
  scheduledDeliveryYn: PropTypes.oneOf(['Y', 'N']), //정기배송여부
  noInterestMonth: PropTypes.string,
  noInterest: PropTypes.string,
});

export const paycoData = PropTypes.shape({
  tempOrderNumber: PropTypes.string,
  orderNumber: PropTypes.string,
  paymentNumber: PropTypes.string,
  orderChannel: PropTypes.string,
  inAppYn: PropTypes.string,
  memberId: PropTypes.string,
  payNo: PropTypes.string,
  cartDiviCd: PropTypes.string, // AS-IS에서 넘기는 값이던데...
  cartNoList: PropTypes.array, // AS-IS에서 넘기는 값이던데...
});

MobileMobiliansPayForm.propTypes = {
  data: PropTypes.oneOfType([inicisData, paycoData]),
  onComplete: PropTypes.func,
}

export default MobileMobiliansPayForm;
