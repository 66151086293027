export const ORD_BASE_INFO_PARAMS = [
  'orderTypeCode',
  'consumerName',
  'consumerCellPhoneNumber1',
  'consumerCellPhoneNumber2',
  'consumerCellPhoneNumber3',
  'telephoneNumber1',
  'telephoneNumber2',
  'telephoneNumber3',
  'memberNumber',
  'orderTotalAmount',
  'foreignDeliveryGoodYn',
  'personalCustomsClearanceNumber',
  'totalDeliveryCost',
  'deliveryCost',
  'islandMountainDeliveryCost',
  'welPoint',
  'welfarePoint',
  'saveAmountPoint',
  'siteNumber',
  'payMeanSaveYn',
  'payMeanCode',
  'bankCode',
  'inicisBankCode',
  'cardCompanyCode',
  'depositName',
  'consumerEmail',
  'postNumber',
  'baseAddress',
  'detailAddress',
  'expectedDepositDate',
  'goodName',
  'mailReceiveYn',
  'smsReceiveYn',
  'kakaoReceiveYn',
  'sms',
  'orderBenefitList',
  'smsMessage',
  'present',
  'cashReceiptIssueCode',
  'cashReceiptUseDivisionCode',
  'cashReceiptCertificateNumber',
  'welPointCashReceiptIssuePoss',
  'bulkpOrder',
  'taxInvoiceBizName',
  'taxInvoiceBizNumber',
  'taxInvoiceBizEmail',
];

export const ORD_DELIVERY_PLACE_INFO_PARAMS = [
  'deliveryPlaceNumber',
  'memberDeliverySequence',
  'deliveryName',
  'orderName',
  'orderMemo',
  'orderNameYn',
  'telephoneNumber1',
  'telephoneNumber2',
  'telephoneNumber3',
  'cellphoneNumber1',
  'cellphoneNumber2',
  'cellphoneNumber3',
  'email',
  'zip',
  'baseAddress',
  'detailAddress',
  'deliveryDate',
  'pccc',
  'saveDefaultDeliveryMemo',
  'noteOrdererName',
];

export const ORD_GOOD_INFO_PARAMS = [
  'cartNumber',
  'goodNumber',
  'itemNumber',
  'goodDivisionCode',
  'goodTypeCode',
  'goodDeliveryReservationDateTime',
  'goodOrderCount',
];

export const HOUR_OPTIONS = Array(24)
  .fill()
  .map((arr, i) => ({
    label: `${i}`.padStart(2, '0') + ':00',
    key: `${i}`.padStart(2, '0'),
    value: `${i}`.padStart(2, '0'),
  }));
