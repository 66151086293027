import { getRequest, postRequest } from '@mo-apis/axios';

const getBannerList = (mainFunctionKindCode) =>
  getRequest({
    url: `/api/v1/display/banner/${mainFunctionKindCode}`,
  });

const createRecommendationLog = (action, { goodNumber, brandName, largeDisplayCategoryNumber, subGoodList }) =>
  postRequest(
    {
      url: `/api/v1/display/recommendation/log`,
      data: {
        action,
        goodNumberList: subGoodList ? [goodNumber, ...subGoodList.map((good) => good.goodNumber)] : [goodNumber],
        brandName,
        largeDisplayCategoryNumber,
      },
    },
    { skipError: true, skipSpinner: true }
  );

const getDisplayCategory = () =>
  getRequest({
    url: '/api/v1/display/category',
  });

const displayApi = {
  getBannerList,
  createRecommendationLog,
  getDisplayCategory,
};

export default displayApi;
