import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import 'swiper/css/lazy';

import { overrideProperties } from '@ecp/common/src/style/utils';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import SwiperUnstyled from '@ecp/common/src/components/unstyled/swiper/SwiperUnstyled';
import SwiperController, {
  SwiperArrowLabel,
  SwiperEdgeController,
  SwiperEdgeLabel,
  SwiperLabel,
  SwiperLeftOuterLabel,
  SwiperMainExhibitionController,
  SwiperOuterLabel,
  SwiperWhiteController,
} from '@ecp/common/src/components/swiper/mo/component/SwiperController';
import SwiperItem from '@ecp/common/src/components/swiper/mo/component/SwiperItem';
import { ArrowNext, ArrowPrev } from '@ecp/common/src/components/swiper/mo/component/SwiperArrow';
import SwiperPaging from '@ecp/common/src/components/swiper/mo/component/SwiperPaging';

export const StyledRoundedWrapper = styled.div`
  position: relative;

  ${overrideProperties(sizePropTypes, {
    height: '100%',
    width: '100%',
  })};

  .swiper {
    border-radius: ${({ round }) => (round ? '8px' : 0)};
    height: 100%;
    width: 100%;

    &-wrapper {
      transform-style: preserve-3d;
    }
  }

  .swiper-3d .swiper-slide-shadow {
    background: none;
  }
`;

const getController = (control) => {
  switch (control) {
    case 'black':
      return SwiperController;
    case 'white':
      return SwiperWhiteController;
    case 'edge':
      return SwiperEdgeController;
    case 'exhibition':
      return SwiperMainExhibitionController;
    case 'label':
      return SwiperLabel;
    case 'outerLabel':
      return SwiperOuterLabel;
    case 'leftOuterLabel':
      return SwiperLeftOuterLabel;
    case 'arrowLabel':
      return SwiperArrowLabel;
    case 'edgeLabel':
      return SwiperEdgeLabel;
    default:
      return undefined;
  }
};

const Swiper = React.forwardRef(({ padding, control, ...props }, ref) => {
  const additionalProps = {
    rootProps: { style: { padding, width: '100%', height: '100%' } },
    controllerComponent: getController(control),
    pagingComponent: props.pagingProps && SwiperPaging,
  };

  return (
    <SwiperUnstyled
      ref={ref}
      arrowPrevComponent={ArrowPrev}
      arrowNextComponent={ArrowNext}
      wrapperComponent={StyledRoundedWrapper}
      {...props}
      {...additionalProps}
    />
  );
});

Swiper.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  carouselProps: PropTypes.object,
  pagingProps: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  control: PropTypes.oneOf([
    'black',
    'white',
    'edge',
    'exhibition',
    'label',
    'outerLabel',
    'leftOuterLabel',
    'arrowLabel',
    'edgeLabel',
    undefined,
  ]),
  round: PropTypes.bool,
  padding: PropTypes.string,
};

export const SwiperDefaultProps = {
  carouselProps: {
    delay: 3500,
    spaceBetween: 10,
    autoplay: true,
    navigation: true,
    loop: true,
    centeredSlides: true,
  },
  itemProps: { width: '100%', height: 'auto' },
  itemComponent: SwiperItem,
  width: '100%',
  height: 'auto',
};

Swiper.defaultProps = {
  itemList: [],
  itemProps: {},
  ...SwiperDefaultProps,
};

Swiper.displayName = 'Swiper';

export default Swiper;
