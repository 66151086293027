import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isEmpty from 'lodash-es/isEmpty';

import theme from '@ecp/common/src/style/theme/default';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import SearchInput from '@ecp/common/src/components/input/mo/SearchInput';
import FullPagePopup from '@ecp/common/src/components/modal/mo/popup/FullPagePopup';

import { useAlertDialog } from '@mo-hooks/common/useModal';
import PriceFilter from '@mo-components/display/filter/components/PriceFilter';
import SearchFilterPopupCategory from '@mo-components/display/filter/components/SearchFilterPopupCategory';
import SearchFilterPopupTab from '@mo-components/display/filter/components/SearchFilterPopupTab';
import BrandFilter from '@mo-components/display/filter/components/BrandFilter';
import SelectedFilterBar from '@mo-components/display/filter/components/SelectedFilterBar';
import { FILTER_TYPE } from '@mo-pages/search/result/searchOption';

const SearchFilterPopup = ({
  open,
  filterOptions,
  filterInfo,
  totalCount,
  filteredLargeCategory,
  filteredMiddleCategory,
  filteredSmallCategory,
  filteredDetailCategory,
  brandList,
  priceList,
  selectedFilterList,
  currentCategory,
  onClose,
  handleChangeFilter,
  handleRefreshFilter,
  handleDeleteFilter,
  handleApplyFilter,
  handleCurrentCategoryChange,
  getTypeOfTreeItem,
  getCategoryToBeChanged,
}) => {
  const { showAlertDialog } = useAlertDialog();

  const [researchTerm, setResearchTerm] = useState(filterInfo?.researchTerm);

  const mappedBrandList = useMemo(() => {
    return brandList?.map(({ brandNumber, brandName }) => ({
      label: brandName,
      value: brandNumber,
    }));
  }, [brandList]);

  const categoryCount = useMemo(() => {
    return !filterInfo?.categoryNumber?.current ? 0 : 1;
  }, [filterInfo?.categoryNumber]);

  const priceCount = useMemo(() => {
    return isEmpty(filterInfo?.price?.label) ? 0 : 1;
  }, [filterInfo?.price?.label]);

  const handleChangeBrand = useCallback(
    (brand, selected) => {
      if (!selected) {
        const updatedBrandList = filterInfo?.brandList.filter((it) => it !== brand?.value);
        handleChangeFilter({ brandList: updatedBrandList });
        return;
      }

      if (filterInfo?.brandList?.length + 1 === brandList?.length) {
        showAlertDialog('전체 브랜드를 선택하셨습니다.');
        handleChangeFilter({ brandList: [] });
        return;
      }

      const updatedBrandList = [...filterInfo.brandList, brand.value];
      handleChangeFilter({ brandList: updatedBrandList });
    },
    [brandList?.length, filterInfo?.brandList, handleChangeFilter, showAlertDialog]
  );

  const handleChangePrice = useCallback(
    (price) => {
      if (filterInfo?.price?.min === price?.min && filterInfo?.price?.max === price?.max) {
        handleChangeFilter({
          price: {
            min: '',
            max: '',
            label: '',
          },
        });
      } else {
        handleChangeFilter({ price });
      }
    },
    [filterInfo?.price, handleChangeFilter]
  );

  const showFilter = useCallback(
    (filter) => {
      return filterOptions.map(({ key }) => key).includes(filter);
    },
    [filterOptions]
  );

  return (
    <FullPagePopup
      header={'필터'}
      open={open}
      onClose={onClose}
      onCancelClick={onClose}
      onConfirmClick={handleApplyFilter}
      confirmButtonText={'필터 적용'}
      ariaLabel={'필터 닫기'}
    >
      <Wrapper>
        {showFilter(FILTER_TYPE.RESEARCH) && (
          <>
            <SearchInput
              placeholder={'결과 내 재검색'}
              value={researchTerm}
              onChange={(e) => setResearchTerm(e?.target?.value)}
              onEnter={() => handleChangeFilter({ researchTerm: researchTerm })}
              onSearchClick={() => handleChangeFilter({ researchTerm: researchTerm })}
              title={'결과 내 재검색'}
            />
            <Spacing top={30} />
          </>
        )}
        <TabWrapper>
          <SearchFilterPopupTab
            showCategory={showFilter(FILTER_TYPE.CATEGORY)}
            showBrand={showFilter(FILTER_TYPE.BRAND)}
            showPrice={showFilter(FILTER_TYPE.PRICE)}
            tab={filterInfo?.tab}
            categoryCount={categoryCount}
            brandCount={filterInfo?.brandList?.length}
            priceCount={priceCount}
            handleChangeTab={(tab) => handleChangeFilter({ tab })}
          />
          <Spacing right={20} />
          <ContentWrapper>
            {[FILTER_TYPE.CATEGORY, FILTER_TYPE.RESEARCH].includes(filterInfo?.tab) && (
              <SearchFilterPopupCategory
                totalCount={totalCount}
                currentCategory={currentCategory}
                filteredLargeCategory={filteredLargeCategory}
                filteredMiddleCategory={filteredMiddleCategory}
                filteredSmallCategory={filteredSmallCategory}
                filteredDetailCategory={filteredDetailCategory}
                getTypeOfTreeItem={getTypeOfTreeItem}
                getCategoryToBeChanged={getCategoryToBeChanged}
                handleCurrentCategoryChange={handleCurrentCategoryChange}
                handleCategoryChange={(categoryNumber) =>
                  handleChangeFilter({
                    categoryNumber,
                  })
                }
              />
            )}
            {filterInfo?.tab === FILTER_TYPE.BRAND && (
              <BrandFilter
                brandList={mappedBrandList}
                selectedBrandList={filterInfo?.brandList}
                onClickBrand={handleChangeBrand}
              />
            )}
            {filterInfo?.tab === FILTER_TYPE.PRICE && (
              <PriceFilter priceList={priceList} selectedPrice={filterInfo?.price} onChangePrice={handleChangePrice} />
            )}
          </ContentWrapper>
        </TabWrapper>
      </Wrapper>
      <SelectedFilterBarWrapper>
        <SelectedFilterBar
          selectedList={selectedFilterList}
          handleRefresh={handleRefreshFilter}
          handleDelete={handleDeleteFilter}
        />
      </SelectedFilterBarWrapper>
    </FullPagePopup>
  );
};

const Wrapper = styled(FlexBoxColumn)`
  width: 100%;
  height: 100%;
  padding: 0 20px;
`;

const TabWrapper = styled(FlexBox)`
  flex: 1;
  width: 100%;
  height: auto;
`;

const ContentWrapper = styled(FlexBox)`
  flex: 1;
  height: 100%;
  align-items: flex-start;
  overflow-y: auto;
`;

const SelectedFilterBarWrapper = styled(FlexBox)`
  width: 360px;
  position: fixed;
  bottom: 96px;
  border-top: 1px solid ${theme.color.line.basic};
  border-bottom: 1px solid ${theme.color.line.basic};
`;

export default SearchFilterPopup;

SearchFilterPopup.propTypes = {
  open: PropTypes.bool,
  filterOptions: PropTypes.array,
  filterInfo: PropTypes.shape({
    tab: PropTypes.string,
    researchTerm: PropTypes.string,
    categoryNumber: PropTypes.object,
    brandList: PropTypes.array,
    price: PropTypes.object,
  }),
  totalCount: PropTypes.number,
  filteredLargeCategory: PropTypes.array,
  filteredMiddleCategory: PropTypes.array,
  filteredSmallCategory: PropTypes.array,
  filteredDetailCategory: PropTypes.array,
  brandList: PropTypes.array,
  priceList: PropTypes.array,
  selectedFilterList: PropTypes.array,
  currentCategory: PropTypes.object,
  onClose: PropTypes.func,
  handleChangeFilter: PropTypes.func,
  handleRefreshFilter: PropTypes.func,
  handleDeleteFilter: PropTypes.func,
  handleApplyFilter: PropTypes.func,
  handleCurrentCategoryChange: PropTypes.func,
  getTypeOfTreeItem: PropTypes.func,
  getCategoryToBeChanged: PropTypes.func,
};
