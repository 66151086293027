import React from 'react';
import styled from 'styled-components';

import { Spacing } from '@ecp/common/src/layouts/Spacing';
import BgGuide from '@ecp/common/src/assets/icon/icon_guide.svg';

const ScheduleSendGuide = () => {
  return (
    <ScheduleSendGuideBox>
      <ScheduleSendGuideBoxDt>
        <ScheduleSendGuideIcon />
        예약발송 안내
      </ScheduleSendGuideBoxDt>
      <ScheduleSendGuideBoxDd>
        • 예약전송은 다음날부터 30일 이내, 1시간 단위로 지정하실 수 있습니다.
      </ScheduleSendGuideBoxDd>
      <ScheduleSendGuideBoxDd>
        • 예약 발송 시각에 발송 환경에 따라 발송이 지연될 수 있으니, 이점 양해 부탁드립니다. (ex. 15시로 지정 시,
        15시에서 16시 사이에 전송됨)
      </ScheduleSendGuideBoxDd>
      <Spacing top={'14px'} />
      <ScheduleSendGuideBoxDt>
        <ScheduleSendGuideIcon />
        예약 발송한 상품의 판매가 중단된 경우 환불/취소 안내
      </ScheduleSendGuideBoxDt>
      <ScheduleSendGuideBoxDd>
        • 예약 발송 하신 기프티쇼 상품의 경우, 발송 당일 해당 상품의 판매 중단이 발생할 수 있습니다. 이 경우, 고객님이
        등록 하신 휴대폰 번호 (발신번호)로 상품 중단 관련 사항을 SMS로 안내해 드립니다.
      </ScheduleSendGuideBoxDd>
      <ScheduleSendGuideBoxDd>
        • 상품 발송이 중단 된 경우 고객센터를 통해서 취소, 환불 신청이 가능하오니 양해 부탁드립니다.
      </ScheduleSendGuideBoxDd>
    </ScheduleSendGuideBox>
  );
};

export default ScheduleSendGuide;

const ScheduleSendGuideBox = styled.div`
  margin-top: 20px;
  padding: 16px;
  background: #f7f7f7;
`;

const ScheduleSendGuideIcon = styled.div`
  background: url(${BgGuide}) no-repeat center;
  background-size: contain;
  width: 16px;
  height: 16px;
`;
const ScheduleSendGuideBoxDt = styled.div`
  color: #141415;
  height: 16px;
  line-height: 16px;
  display: flex;
  gap: 4px;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
`;
const ScheduleSendGuideBoxDd = styled.div`
  position: relative;
  color: #727272;
  font-size: 12px;
  font-weight: 300;
`;
