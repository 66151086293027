import React from 'react';
import PropTypes from 'prop-types';

import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import LayerPopup from '@ecp/common/src/components/modal/mo/popup/LayerPopup';

import { ReactComponent as EmailIcon } from '@mo-assets/icon/icon__email.svg';

const UnauthorizedEmailCollectionPopup = ({ open, onClose }) => {
  return (
    <LayerPopup
      open={open}
      onClose={onClose}
      header={'이메일 무단수집거부'}
      useCancelButton={false}
      onConfirmClick={onClose}
      confirmButtonProps={{ width: '156px' }}
    >
      <FlexBoxColumn width={'100%'}>
        <FlexBox width={'100%'} align-items={'flex-start'}>
          <EmailIcon width={'40px'} height={'40px'} />
          <Spacing left={14} />
          <TextBox size={moTheme.font.size.$16} weight={'500'} height={'25px'}>
            웰스토리는 이메일 주소의
            <br />
            무단 수집을 거부합니다.
          </TextBox>
        </FlexBox>
        <Spacing top={14} />
        <HorizontalDivider width={'100%'} height={'1px'} color={'#141415'} />
        <Spacing top={20} />
        <TextBox size={moTheme.font.size.$14} width={'350'} height={'22px'} color={'#5A5A5A'}>
          {`본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며, 이를 위반 시 정보통신망법에 의해 형사 처벌됨을 유념하시기 바랍니다.\n[게시일 2007년 9월 3일]`}
        </TextBox>
      </FlexBoxColumn>
    </LayerPopup>
  );
};

export default UnauthorizedEmailCollectionPopup;

UnauthorizedEmailCollectionPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
