import isEmpty from 'lodash-es/isEmpty';

import { getLabelByPrice } from '@ecp/common/src/utils/good/goodSearchUtil';
import { GOOD_SORT_TYPE } from '@ecp/common/src/const/good';

const initialSearchConditionInSubCategoryPage = {
  brandList: [],
  price: {
    min: null,
    max: null,
    label: '',
  },
};

const initialPageParamInSubCategoryPage = {
  page: 1,
  countPerPage: 60,
  sort: GOOD_SORT_TYPE[0],
  totalCount: 0,
  listView: false,
};

const initialPageParamInMobileSubCategoryPage = {
  page: 1,
  countPerPage: 20,
  sort: GOOD_SORT_TYPE[0],
  totalCount: 0,
  listView: false,
};

const getQueryStringInSubCategoryPage = (filterInfo, pageInfo) => {
  const brandNumber =
    filterInfo.brandList?.length > 0 ? `brandNumber=${encodeURI(filterInfo.brandList.join(','))}` : '';
  const min = filterInfo.price?.min ? `min=${filterInfo.price.min}` : '';
  const max = filterInfo.price?.max ? `max=${filterInfo.price.max}` : '';

  const countPerPage = pageInfo.countPerPage ? `countPerPage=${pageInfo.countPerPage}` : '';
  const page = pageInfo.page ? `page=${pageInfo.page}` : '';
  const sort = pageInfo.sort ? `sort=${pageInfo.sort.key}` : '';
  const listView = pageInfo.listView ? `listView=${pageInfo.listView}` : '';

  return [brandNumber, min, max, countPerPage, page, sort, listView].filter((it) => !isEmpty(it)).join('&');
};

const getInitialFilterInfoInSubCategoryPage = (initialFilterInfo, searchParams) => ({
  ...initialFilterInfo,
  brandList: searchParams.get('brandNumber')?.split(',') || [],
  price: {
    min: searchParams.get('min') || '',
    max: searchParams.get('max') || '',
    label: getLabelByPrice(searchParams.get('min') || '', searchParams.get('max') || ''),
  },
});

const getInitialPageInfoInSubCategoryPage = (initialPageInfo, searchParams) => ({
  page: searchParams.get('page') ? Number(searchParams.get('page')) : initialPageInfo.page,
  countPerPage: searchParams.get('countPerPage')
    ? Number(searchParams.get('countPerPage'))
    : initialPageInfo.countPerPage,
  sort: searchParams.get('sort')
    ? GOOD_SORT_TYPE.find(({ key }) => key === searchParams.get('sort'))
    : initialPageInfo.sort,
  listView: searchParams.get('listView') || initialPageInfo.listView,
});

const navigateWithFilterInfoTable = {
  pathName: `/category/sub/`,
  initSearchCondition: initialSearchConditionInSubCategoryPage,
  initPageParam: initialPageParamInSubCategoryPage,
  getQueryString: getQueryStringInSubCategoryPage,
  getFilterInfo: getInitialFilterInfoInSubCategoryPage,
  getPageInfo: getInitialPageInfoInSubCategoryPage,
};

const mobileNavigateWithFilterInfoTable = {
  pathName: '/category/sub/',
  initSearchCondition: initialSearchConditionInSubCategoryPage,
  initPageParam: initialPageParamInMobileSubCategoryPage,
  getQueryString: getQueryStringInSubCategoryPage,
  getFilterInfo: getInitialFilterInfoInSubCategoryPage,
  getPageInfo: getInitialPageInfoInSubCategoryPage,
};

export {
  navigateWithFilterInfoTable,
  mobileNavigateWithFilterInfoTable,
  initialPageParamInMobileSubCategoryPage,
  getQueryStringInSubCategoryPage,
};
