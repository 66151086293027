import { getRequest } from '@mo-apis/axios';

const getAppVersion = () =>
  getRequest(
    {
      url: '/api/v1/mobile/version/check-version',
      isMobile: true,
    },
    { skipSpinner: true }
  );

const mobileVersionApi = {
  getAppVersion,
};

export default mobileVersionApi;
