import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import useNavigateBack from '@ecp/common/src/hooks/useNavigateBack';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox, TextBoxAlignCenter } from '@ecp/common/src/text/TextBox';
import { PageButton } from '@ecp/common/src/components/button/mo/Button';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';
import { ReactComponent as NotFoundIcon } from '@ecp/common/src/assets/icon/icon__error-not-found.svg';
const PageNotFoundPage = () => {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const handleGoBackClick = () => {
    navigateBack();
  };
  const handleGoMainClick = () => {
    navigate('/');
  };
  const handleGoCsClick = () => {
    navigate('/customer-service');
  };
  return (
    <Container>
      <Spacing top={180} />
      <StyledNotFoundIcon />
      <Spacing bottom={30} />
      <TextBox size={moTheme.font.size.$18} weight={'500'}>
        요청하신 페이지를 찾을 수 없습니다.
      </TextBox>
      <Spacing bottom={20} />
      <TextBoxAlignCenter font-weight={350} font-size={moTheme.font.size.$14}>
        <p>
          방문하시려는 페이지 주소가 잘못 입력되었거나,
          <br />
          페이지의 주소가 변경 혹은 삭제되어 요청하신
          <br />
          페이지를 찾을 수 없습니다.
          <br />
          입력하신 주소가 정확한지 다시 한번
          <br />
          확인 부탁 드립니다.
        </p>
      </TextBoxAlignCenter>
      <Spacing bottom={50} />
      <FlexBox>
        <PageButton type={'Default'} width={'156px'} height={'46px'} onClick={handleGoBackClick}>
          이전 페이지로 이동
        </PageButton>
        <Spacing left={8} />
        <PageButton type={'Third'} width={'156px'} height={'46px'} onClick={handleGoMainClick}>
          메인으로 가기
        </PageButton>
      </FlexBox>
      <Spacing bottom={28} />
      <TextButton
        type={'move'}
        textProps={{ size: moTheme.font.size.$13, weight: '500', color: '#141415' }}
        onClick={handleGoCsClick}
      >
        고객센터 바로가기
      </TextButton>
    </Container>
  );
};

export default PageNotFoundPage;

const Container = styled(FlexBoxColumn)`
  width: 100%;
  min-height: var(--vh-100);
  margin: 0 auto;
  padding: 0 20px;
`;

const StyledNotFoundIcon = styled(NotFoundIcon)`
  width: 100px;
  height: 80px;
`;
