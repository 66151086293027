import React from 'react';

import WpayMemberReturnComp from '@ecp/common/src/components/interface/WpayMemberReturnComp';

import { useAlertDialog } from '@mo-hooks/common/useModal';
import wpayApi from '@mo-apis/interface/wpayApi';

const WpayMemberReturnPage = () => {
  const { showAlertDialog } = useAlertDialog();

  return <WpayMemberReturnComp showAlertDialog={showAlertDialog} wpayApi={wpayApi} />;
};

export default WpayMemberReturnPage;
