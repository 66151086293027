import { selector, selectorFamily } from 'recoil';

import { mobileAppState, systemInformationState } from '@mo-recoil/system/atom';

export const getSiteName = selector({
  key: 'getSiteNameMo',
  get: ({ get }) => get(systemInformationState).siteName,
});

export const getCustomerCenterGuide = selector({
  key: 'getCustomerCenterGuide',
  get: ({ get }) => get(systemInformationState).customerCenterGuide,
});

export const getIsMultimall = selector({
  key: 'isMultimallMo',
  get: ({ get }) => get(systemInformationState).isMultimall,
});

export const getOperationTime = selector({
  key: 'getOperationTimeMo',
  get: ({ get }) =>
    `운영시간 : 평일 ${get(systemInformationState).customerCenterOpStartHh} ~ ${
      get(systemInformationState).customerCenterOpEndHh
    }`,
});

export const getMoOperationTime = selector({
  key: 'getMoOperationTime',
  get: ({ get }) =>
    `평일 ${get(systemInformationState).customerCenterOpStartHh} ~ ${
      get(systemInformationState).customerCenterOpEndHh
    }`,
});

export const getLunchTime = selector({
  key: 'getLunchTimeMo',
  get: ({ get }) =>
    `(점심시간 : ${get(systemInformationState).customerCenterLchStartHh} ~ ${
      get(systemInformationState).customerCenterLchEndHh
    })`,
});

export const getMoLunchTime = selector({
  key: 'getMoLunchTime',
  get: ({ get }) =>
    `점심시간 : ${get(systemInformationState).customerCenterLchStartHh} ~ ${
      get(systemInformationState).customerCenterLchEndHh
    }`,
});

export const getSiteInformation = selectorFamily({
  key: 'getSiteInformation',
  get:
    (param) =>
    ({ get }) =>
      get(systemInformationState)[param],
});

export const getCustomerCenterTel = selector({
  key: 'getMoCustomerCenterTel',
  get: ({ get }) => {
    const { customerCenterTel } = get(systemInformationState);
    return `${customerCenterTel}`;
  },
});

export const withMobileStatus = selector({
  key: 'withMobileStatus',
  get: ({ get }) => get(mobileAppState),
  set: ({ get, set }, newMobileStatus) => {
    set(mobileAppState, { ...get(mobileAppState), ...newMobileStatus });
  },
});

export const getPcBulkpPhrase = selector({
  key: 'getPcBulkpPhrase',
  get: ({ get }) => {
    const { pcBulkpPhrase } = get(systemInformationState);
    return `${pcBulkpPhrase}`;
  },
});

export const getPcBulkpImgFilePath = selector({
  key: 'getPcBulkpImgFilePath',
  get: ({ get }) => {
    const { pcBulkpImgFilePath } = get(systemInformationState);
    return `${pcBulkpImgFilePath}`;
  },
});
export const getSavingsUseYn = selector({
  key: 'getSavingsUseYn',
  get: ({ get }) => {
    const { savingsUseYn } = get(systemInformationState);
    return `${savingsUseYn}`;
  },
});
export const getOtoQuestUseYn = selector({
  key: 'getOtoQuestUseYn',
  get: ({ get }) => {
    const { otoQuestUseYn } = get(systemInformationState);
    return `${otoQuestUseYn}`;
  },
});
export const getIsEventMall = selector({
  key: 'getIsEventMall',
  get: ({ get }) => {
    const isEventMall = get(systemInformationState).siteTypeCode === '20' ? true : false;
    return isEventMall;
  },
});
export const getIsMarketingUse = selector({
  key: 'getIsMarketingUse',
  get: ({ get }) => {
    const isMarketingUse = get(systemInformationState).mktingUseYn === 'Y' ? true : false;
    return isMarketingUse;
  },
});
export const getDisplayPointName = selector({
  key: 'getDisplayPointName',
  get: ({ get }) => {
    const displayPointName = get(systemInformationState).pntExpsNm;
    return displayPointName;
  },
});
export const getIsCouponMenuUse = selector({
  key: 'getIsCouponMenuUse',
  get: ({ get }) => {
    const IsCouponMenuUse = get(systemInformationState).couponMenuExpsYn === 'N' ? false : true;
    return IsCouponMenuUse;
  },
});
export const getIsCartUse = selector({
  key: 'getIsCartUse',
  get: ({ get }) => {
    const isCartUse = get(systemInformationState).cartUseYn === 'N' ? false : true;
    return isCartUse;
  },
});
export const getIsPriceOpen = selector({
  key: 'getIsPriceOpen',
  get: ({ get }) => {
    const isPriceOpen = get(systemInformationState).priceExpsUseYn === 'N' ? false : true;
    return isPriceOpen;
  },
});
export const getIsPointUse = selector({
  key: 'getIsPointUse',
  get: ({ get }) => {
    const isPointUse = get(systemInformationState).pointUseYn === 'N' ? false : true;
    return isPointUse;
  },
});
export const getIsBulkOrdMenuUseYn = selector({
  key: 'getIsBulkOrdMenuUseYn',
  get: ({ get }) => {
    const isbulkOrdMenuUseYn = get(systemInformationState).bulkOrdMenuUseYn === 'N' ? false : true;
    return isbulkOrdMenuUseYn;
  },
});
