import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import { overrideProperties } from '@ecp/common/src/style/utils';
import theme from '@ecp/common/src/style/theme/default';
import { zIndex as zIndexConst } from '@ecp/common/src/style/constant';
import { placements } from '@ecp/common/src/components/unstyled/floating/useCustomFloating';
import FloatingUnstyled from '@ecp/common/src/components/unstyled/floating/FloatingUnstyled';
import { defaultArrowPosition } from '@ecp/common/src/components/tooltip/Tooltip';
import ShareIcon from '@ecp/common/src/assets/icon/share.svg';
import { ReactComponent as TooltipCloseIcon } from '@ecp/common/src/assets/icon/tooltip__close.svg';

const Tooltip = ({ children, ...props }) => (
  <FloatingUnstyled
    blockAutoFocus
    rootComponent={StyledTooltip}
    closeComponent={<StyledClose />}
    offset={3 - defaultArrowPosition}
    useShift
    shiftPadding={10}
    {...props}
  >
    {children}
  </FloatingUnstyled>
);

const StyledTooltip = styled.div`
  width: max-content;
  max-width: ${({ maxWidth = '220px' }) => maxWidth};
  border: 1px solid ${theme.color.text.basic};
  padding: 14px 42px 14px 14px;
  background-color: ${theme.color.text.white};
  color: ${theme.color.text.sub};
  z-index: ${({ zIndex = zIndexConst.tooltip }) => zIndex};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  word-break: keep-all;

  &:focus-visible {
    outline: none;
  }
`;

export const StyledClose = styled(TooltipCloseIcon)`
  border: none;
  width: 14px;
  height: 14px;
  background-color: #ffffff;
  position: inherit;
  top: 14px;
  right: 14px;
  cursor: pointer;
`;

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.oneOf(placements),
};

Tooltip.defaultProps = {
  closeWhenOutside: true,
};

export const StyledShareIcon = styled.div`
  background: url(${ShareIcon}) no-repeat;
  ${overrideProperties(sizePropTypes, { width: '30px', height: '30px' })};
  cursor: pointer;
`;

export default Tooltip;
