import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { format, parseISO } from 'date-fns';

import { unescapeHtml } from '@ecp/common/src/utils/htmlUtil';
import useScrollTop from '@ecp/common/src/hooks/useScrollTop';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import FullPagePopup from '@ecp/common/src/components/modal/mo/popup/FullPagePopup';
import LayerPopup from '@ecp/common/src/components/modal/mo/popup/LayerPopup';
import { TextAButton } from '@ecp/common/src/components/button/mo/TextAButton';

import { useAlertDialog } from '@mo-hooks/common/useModal';
import policyApi from '@mo-apis/system/policyApi';
import EditorViewer from '@mo-components/common/EditorViewer';
import { ADDITIONAL_TYPE_CD_WP_SIMPLE_AGREE } from '@mo-components/system/const';
import HistoryDropdown from './HistoryDropdown';
import { ReactComponent as IconSimpleJoinDot } from '@ecp/common/src/assets/icon/icon_simple_join_dot.svg';

const NO_DATA_MESSAGE = '조회된 데이터가 없습니다.';

const EditorWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow: auto;
  border: 1px solid ${theme.color.line.background};
  padding: 25px 10px;
`;

const PolicyPopupButton = ({
  popupTitle,
  popupType,
  additionalTypeCd,
  buttonText,
  buttonTextProps,
  buttonType,
  textColor,
  ariaLabel,
}) => {
  const { showAlertDialog } = useAlertDialog();

  const [popupOpen, setPopupOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [content, setContent] = useState(null);

  const { ref } = useScrollTop([content]);

  const isSimpleAgreement = useMemo(() => {
    return additionalTypeCd == ADDITIONAL_TYPE_CD_WP_SIMPLE_AGREE ? true : false;
  }, [additionalTypeCd]);

  const searchPolicyContent = useCallback(
    async ({ siteNo, additionalTypeCd, versionNo, applyStartDateTime }) => {
      const { result } = await policyApi.getPolicyContent({
        siteNo,
        additionalTypeCd,
        versionNo,
        applyStartDateTime,
      });
      setContent(result ? unescapeHtml(result) : '');
    },
    [setContent]
  );

  const handleOpenClick = useCallback(async (e) => {
    e.preventDefault();
    const { result } = await policyApi.getPolicyHistoryList(additionalTypeCd);
    const convertList = result.map(({ applyStartDateTime, versionNo, siteNo }) => ({
      label: `${format(parseISO(applyStartDateTime), 'yyyy.MM.dd')}`,
      key: `${additionalTypeCd} ${versionNo} ${siteNo} ${applyStartDateTime}`,
      value: { siteNo, additionalTypeCd, versionNo, applyStartDateTime },
    }));

    if (convertList.length > 0) {
      setItems(convertList);
      setSelectedItem(convertList[0]);
      await searchPolicyContent(convertList[0].value);
      setPopupOpen(true);
    } else {
      showAlertDialog(NO_DATA_MESSAGE);
    }
  }, [additionalTypeCd, searchPolicyContent, showAlertDialog]);

  const handleCloseClick = useCallback(() => {
    setPopupOpen(false);
  }, []);

  const handleHistoryChange = useCallback(
    async (item) => {
      setSelectedItem(item);
      await searchPolicyContent(item.value);
    },
    [searchPolicyContent]
  );

  return (
    <>
      <TextAButton
        textProps={{ height: moTheme.font.lineHeight.demiLight, ...buttonTextProps }}
        onClick={handleOpenClick}
        type={buttonType}
        style={textColor}
        aria-label={ariaLabel}
      >
        {buttonType == 'move_stroke' && (
          <>
            <IconSimpleJoinDot width={'3px'} height={'3px'} /> <Spacing left="5px" />
          </>
        )}
        {buttonText}
      </TextAButton>
      {isSimpleAgreement ? (
        <LayerPopup
          open={popupOpen}
          onClose={handleCloseClick}
          header={popupTitle}
          useCancelButton={false}
          onConfirmClick={handleCloseClick}
        >
          <FlexBoxColumn width={'100%'} align-items={'flex-end'} flex={'1'} style={{ overflowY: 'overlay' }}>
            {popupType === 'history' && (
              <HistoryDropdown items={items} onChange={handleHistoryChange} value={selectedItem} />
            )}
            <Spacing top={'9px'} />
            <EditorWrapper ref={ref}>
              <EditorViewer html={content} />
            </EditorWrapper>
          </FlexBoxColumn>
        </LayerPopup>
      ) : (
        <FullPagePopup
          open={popupOpen}
          onClose={handleCloseClick}
          header={popupTitle}
          useCancelButton={false}
          onConfirmClick={handleCloseClick}
        >
          <FlexBoxColumn width={'100%'} align-items={'flex-end'} flex={'1'} style={{ overflowY: 'overlay' }}>
            {popupType === 'history' && (
              <HistoryDropdown items={items} onChange={handleHistoryChange} value={selectedItem} />
            )}
            <Spacing top={'9px'} />
            <EditorWrapper ref={ref} tabIndex={0}>
              <EditorViewer html={content} />
            </EditorWrapper>
          </FlexBoxColumn>
        </FullPagePopup>
      )}
    </>
  );
};

PolicyPopupButton.propTypes = {
  popupTitle: PropTypes.string,
  popupType: PropTypes.oneOf(['default', 'history']),
  additionalTypeCd: PropTypes.string,
  buttonText: PropTypes.string,
  buttonTextProps: PropTypes.object,
  buttonType: PropTypes.oneOf(['basic', 'move', 'list', 'custom', 'custom-left', 'move_stroke']),
  textColor: PropTypes.object,
  ariaLabel: PropTypes.string,
};

PolicyPopupButton.defaultProps = {
  popupType: 'default',
};
export default PolicyPopupButton;
