import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { validateNewPassword } from '@ecp/common/src/utils/InputValidateUtils';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn, FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { PageButton } from '@ecp/common/src/components/button/mo/Button';
import { MAX_PASSWORD_INPUT_LENGTH, MIN_PASSWORD_INPUT_LENGTH } from '@ecp/common/src/const/input';

import { userInfoState } from '@mo-recoil/common/user/atom';
import { useAlertDialog, useConfirmDialog } from '@mo-hooks/common/useModal';
import authApi from '@mo-apis/common/authApi';
import PasswordInput from '@mo-components/member/input/PasswordInput';

const Wrapper = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
`;

const PasswordLabel = styled(TextBox)`
  font-size: ${moTheme.font.size.$14};
  font-weight: ${moTheme.font.weight.medium};
  width: 100%;
`;

const screenText = {
  recovery: {
    passwordInputAlertMsg: '임시 비밀번호를 입력해주세요.',
    passwordNotMatchedAlertMsg: '현재 비밀번호가 일치하지 않습니다.',
    changePasswordAlertMsg: '입력하신 정보로 비밀번호를\n변경하시겠습니까?',
    passwordInputLabel: '임시비밀번호',
    passwordInputPlaceholder: '발급받은 임시 비밀번호를 입력해주세요.',
    reNewPasswordInputPlaceholder: '비밀번호 확인 입력',
    cancelButtonText: '취소',
    confirmButtonText: '비밀번호 변경',
  },
  mypage: {
    passwordInputAlertMsg: '현재 비밀번호를 입력해주세요.',
    passwordNotMatchedAlertMsg: '현재 비밀번호가 일치하지 않습니다.',
    changePasswordAlertMsg: '비밀번호를 변경하시겠습니까?',
    passwordInputLabel: '현재비밀번호',
    passwordInputPlaceholder: '현재 비밀번호를 입력해주세요.',
    reNewPasswordInputPlaceholder: '비밀번호 확인',
    cancelButtonText: '취소',
    confirmButtonText: '변경',
  },
  over90days: {
    passwordInputAlertMsg: '현재 비밀번호를 입력해주세요.',
    passwordNotMatchedAlertMsg: '현재 비밀번호가 일치하지 않습니다.',
    changePasswordAlertMsg: '비밀번호를 변경하시겠습니까?',
    passwordInputLabel: '현재비밀번호',
    passwordInputPlaceholder: '현재 비밀번호를 입력해주세요.',
    reNewPasswordInputPlaceholder: '비밀번호 확인',
    cancelButtonText: '90일 후 변경하기',
    confirmButtonText: '지금 변경하기',
  },
};

const ChangePassword = ({ updatePassword, onCancelButtonClick, userId, type = 'mypage' }) => {
  const userInfo = useRecoilValue(userInfoState);
  const { showAlertDialog } = useAlertDialog();
  const { showConfirmDialog } = useConfirmDialog();

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reNewPassword, setReNewPassword] = useState('');

  const handleChangePassword = async () => {
    if (!(await isValidPassword())) return;

    showConfirmDialog(screenText[type].changePasswordAlertMsg, async () => await updatePassword(password, newPassword));
  };

  const confirmCurrentPassword = async () => {
    const {
      data: { result },
    } = await authApi.confirmPassword({ loginId: userId, password });
    return result?.matched;
  };

  const isValidPassword = async () => {
    if (!password || password.trim() === '') {
      showAlertDialog(screenText[type].passwordInputAlertMsg);
      return false;
    }

    const isMatchedCurrentPassword = await confirmCurrentPassword();
    if (!isMatchedCurrentPassword) {
      showAlertDialog(screenText[type].passwordNotMatchedAlertMsg);
      return false;
    }

    const errMessage = validateNewPassword(newPassword, reNewPassword, userId || userInfo.userId);
    if (errMessage) {
      showAlertDialog(errMessage);
      return false;
    }

    if (password === newPassword) {
      showAlertDialog('현재 비밀번호와 다르게 입력해주세요.');
      return false;
    }

    return true;
  };

  return (
    <Wrapper>
      <FlexBoxColumn width="100%">
        <PasswordLabel>{screenText[type].passwordInputLabel}</PasswordLabel>
        <Spacing top={10} />
        <PasswordInput
          value={password}
          placeholder={screenText[type].passwordInputPlaceholder}
          onChange={(e) => setPassword(e.target.value)}
          styleProps={{ height: '46px' }}
        />
      </FlexBoxColumn>
      <Spacing top={20} />
      <FlexBoxColumn width="100%">
        <PasswordLabel>신규비밀번호</PasswordLabel>
        <Spacing top={10} />
        <PasswordInput
          value={newPassword}
          placeholder={`영문(대소문자 구분),숫자,특수기호 혼합 ${MIN_PASSWORD_INPUT_LENGTH}~${MAX_PASSWORD_INPUT_LENGTH}자리`}
          onChange={(e) => setNewPassword(e.target.value)}
          styleProps={{ height: '46px' }}
        />
      </FlexBoxColumn>
      <Spacing top={20} />
      <FlexBoxColumn width="100%">
        <PasswordLabel>신규비밀번호 확인</PasswordLabel>
        <Spacing top={10} />
        <PasswordInput
          value={reNewPassword}
          placeholder={screenText[type].reNewPasswordInputPlaceholder}
          onChange={(e) => setReNewPassword(e.target.value)}
          styleProps={{ height: '46px' }}
        />
      </FlexBoxColumn>
      <Spacing top={30} />
      <FlexBox width="100%" justify-content="center">
        <PageButton type="Secondary" width="156px" onClick={onCancelButtonClick}>
          {screenText[type].cancelButtonText}
        </PageButton>
        <Spacing left={8} />
        <PageButton width="156px" onClick={handleChangePassword}>
          {screenText[type].confirmButtonText}
        </PageButton>
      </FlexBox>
    </Wrapper>
  );
};

export default ChangePassword;

ChangePassword.propTypes = {
  updatePassword: PropTypes.func,
  onCancelButtonClick: PropTypes.func,
  userId: PropTypes.string,
  type: PropTypes.oneOf(['recovery', 'mypage', 'over90days']),
};
