import { getRequest, postRequest } from '@mo-apis/axios';

const displayCategoryBaseUrl = 'api/v1/display/category';

const getCategoryBrandList = async (categoryNumber, limit, hasImage = false) =>
  await getRequest({
    url: `${displayCategoryBaseUrl}/${categoryNumber}/brand`,
    params: { limit, hasImage },
  });

const getGoodListByDisplayCategory = async (search, pageParams) =>
  await postRequest({
    url: `${displayCategoryBaseUrl}/good-list`,
    data: { ...search },
    params: { ...pageParams },
  });

const getNewGoodList = async (search, pageParams) =>
  await postRequest({
    url: `${displayCategoryBaseUrl}/new-good-list`,
    data: { depthNumber: 1, ...search },
    params: { ...pageParams },
  });

const displayCategoryApi = {
  getCategoryBrandList,
  getGoodListByDisplayCategory,
  getNewGoodList,
};

export default displayCategoryApi;
