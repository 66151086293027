import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useAccordionContext } from '@ecp/common/src/components/unstyled/accordion/useAccordionContext';
import { ReactComponent as IconOpen } from '@ecp/common/src/assets/icon/icon__arrow--below.svg';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';

const openToggleStyle = css`
  transform: scaleY(-1);
`;

const StyledAccordionQnAControl = styled(FlexBox)`
  max-width: 100%;
  min-height: 19px;
  width: 100%;

  align-items: flex-start;
  justify-content: ${(props) => props['justify-content']};
  & svg {
    ${({ open }) => open && openToggleStyle}
  }
`;

const AccordionQnAControlUnstyled = (props) => {
  const { children, rootComponent: AccordionControl, buttonElement, noFold, ...otherProps } = props;
  const { open, handleOpenClick } = useAccordionContext();

  const handleAccordionOpenClick = () => {
    if (noFold) return;
    handleOpenClick();
  };

  return (
    <AccordionControl
      onClick={handleAccordionOpenClick}
      style={!noFold ? { cursor: 'pointer' } : {}}
      {...otherProps}
      open={open}
    >
      {children}
      {buttonElement ? buttonElement : <IconOpen />}
    </AccordionControl>
  );
};

export default AccordionQnAControlUnstyled;

export const AccordionQnAControlPropTypes = {
  rootComponent: PropTypes.elementType,
  buttonElement: PropTypes.node,
  noFold: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  headerTitle: PropTypes.string,
};

AccordionQnAControlUnstyled.propTypes = AccordionQnAControlPropTypes;

export const AccordionQnAControlDefaultProps = {
  noFold: false,
  rootComponent: StyledAccordionQnAControl,
};

AccordionQnAControlUnstyled.defaultProps = AccordionQnAControlDefaultProps;
