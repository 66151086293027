import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import useCashReceiptIssuing from '@ecp/common/src/hooks/order/payment/useCashReceiptIssuing';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Input from '@ecp/common/src/components/input/Input';
import DropdownDevice from '@ecp/common/src/components/dropdown/mo/DropdownDevice';
import RadioGroup from '@ecp/common/src/components/radio/RadioGroup';
import { Radio } from '@ecp/common/src/components/radio/Radio';
import { DROPDOWN_FIRST_PHONE_NUMBER, DROPDOWN_INCOME_DEDUCTION_TYPE } from '@ecp/common/src/const/order/cashReceipt';

const CashReceiptIssuing = ({ isDirectForeignGood, cashReceiptInfo, handleChangeCashReceiptInfo }) => {
  const {
    handleIncomeDeductionTypeChange,
    handleChangeType,
    handleChangeCellPhoneNumber1,
    handleChangeCellPhoneNumber2,
    handleChangeCellPhoneNumber3,
    handleChangeCashReceiptCardNumber1,
    handleChangeCashReceiptCardNumber2,
    handleChangeCashReceiptCardNumber3,
    handleChangeCashReceiptCardNumber4,
    handleChangeBusinessRegistrationNumber1,
    handleChangeBusinessRegistrationNumber2,
    handleChangeBusinessRegistrationNumber3,
    selectedDeductionType,
    selectedFirstPhoneNumber,
  } = useCashReceiptIssuing(handleChangeCashReceiptInfo);

  return (
    <Container>
      <TitleTextBox>현금영수증 발급</TitleTextBox>
      <Spacing top={10} />
      <RadioGroupContainer>
        <RadioGroup defaultValue="none" gap={'40px'} onChange={handleChangeType} width={'100%'}>
          <RadioButtonContainer>
            <FlexBox>
              <Radio value="none">신청안함</Radio>
              <Spacing left={30} />
              <Radio value="deduction">소득공제용</Radio>
            </FlexBox>
            <Radio value="business">지출증빙용(사업자)</Radio>
          </RadioButtonContainer>
        </RadioGroup>
      </RadioGroupContainer>
      <Spacing top={14} />
      {cashReceiptInfo?.cashReceiptType === 'deduction' && (
        <InputTypeContainer>
          <DropdownDevice
            defaultValue={DROPDOWN_INCOME_DEDUCTION_TYPE[0]}
            value={selectedDeductionType}
            items={DROPDOWN_INCOME_DEDUCTION_TYPE}
            width={'320px'}
            onChange={handleIncomeDeductionTypeChange}
          />
          {cashReceiptInfo?.incomeDeductionType === 'cellPhone' ? (
            <InputContainer>
              <DropdownDevice
                defaultValue={DROPDOWN_FIRST_PHONE_NUMBER[0]}
                value={selectedFirstPhoneNumber}
                items={DROPDOWN_FIRST_PHONE_NUMBER}
                width={'102px'}
                onChange={handleChangeCellPhoneNumber1}
              />
              <Input
                name="tel2"
                clearable={false}
                minWidth={'102px'}
                width={'102px'}
                maxLength={4}
                onChange={handleChangeCellPhoneNumber2}
                value={cashReceiptInfo?.cellPhoneNumber2 || ''}
                placeholder=""
              />
              <Input
                name="tel3"
                clearable={false}
                minWidth={'102px'}
                width={'102px'}
                maxLength={4}
                onChange={handleChangeCellPhoneNumber3}
                value={cashReceiptInfo?.cellPhoneNumber3 || ''}
                placeholder=""
              />
            </InputContainer>
          ) : (
            <InputContainer>
              <Input
                name="cashReceiptCard1"
                clearable={false}
                minWidth={'75px'}
                width={'75px'}
                maxLength={4}
                onChange={handleChangeCashReceiptCardNumber1}
                value={cashReceiptInfo?.cashReceiptCardNumber1 || ''}
                placeholder=""
              />
              <Input
                name="cashReceiptCard2"
                clearable={false}
                minWidth={'75px'}
                width={'75px'}
                maxLength={4}
                onChange={handleChangeCashReceiptCardNumber2}
                value={cashReceiptInfo?.cashReceiptCardNumber2 || ''}
                placeholder=""
              />
              <Input
                name="cashReceiptCard3"
                clearable={false}
                minWidth={'75px'}
                width={'75px'}
                maxLength={4}
                onChange={handleChangeCashReceiptCardNumber3}
                value={cashReceiptInfo?.cashReceiptCardNumber3 || ''}
                placeholder=""
              />
              <Input
                name="cashReceiptCard4"
                clearable={false}
                minWidth={'75px'}
                width={'75px'}
                maxLength={4}
                onChange={handleChangeCashReceiptCardNumber4}
                format={false}
                value={cashReceiptInfo?.cashReceiptCardNumber4 || ''}
                placeholder=""
              />
            </InputContainer>
          )}
        </InputTypeContainer>
      )}
      {cashReceiptInfo?.cashReceiptType === 'business' && (
        <InputTypeContainer>
          <BusinessTextBox required>사업자등록번호</BusinessTextBox>
          <InputContainer>
            <Input
              name="businessNumber1"
              clearable={false}
              minWidth={'102px'}
              width={'102px'}
              maxLength={3}
              value={cashReceiptInfo?.businessRegistrationNumber1 || ''}
              onChange={handleChangeBusinessRegistrationNumber1}
              placeholder=""
            />
            <Input
              name="businessNumber2"
              clearable={false}
              minWidth={'102px'}
              width={'102px'}
              maxLength={2}
              value={cashReceiptInfo?.businessRegistrationNumber2 || ''}
              onChange={handleChangeBusinessRegistrationNumber2}
              placeholder=""
            />
            <Input
              name="businessNumber3"
              clearable={false}
              minWidth={'102px'}
              width={'102px'}
              maxLength={5}
              value={cashReceiptInfo?.businessRegistrationNumber3 || ''}
              onChange={handleChangeBusinessRegistrationNumber3}
              placeholder=""
            />
          </InputContainer>
          <Spacing top={20} />
        </InputTypeContainer>
      )}
      <BodyContainer>
        <SubTitleTextBox>[현금영수증 안내]</SubTitleTextBox>
        <GuideTextBox>- 할인금액 및 포인트 사용금액은 현금영수증 발급금액에서 제외됩니다.</GuideTextBox>
        <GuideTextBox>- 단, 웰포인트는 예외이며 웰포인트 사용금액은 현금영수증 발급이 가능합니다.</GuideTextBox>
        <HighlightTextBox>(출산포인트의 경우 소득 미반영 분으로, 현금영수증 발급 예외 대상입니다.)</HighlightTextBox>
        <GuideTextBox>
          - 부가세법 시행령에 따라 지출증빙용 현금영수증을 신청하 신 경우 세금계산서 발급이 제한됩니다.
        </GuideTextBox>
      </BodyContainer>
      {isDirectForeignGood && (
        <BodyContainer>
          <Spacing top={'10px'} />
          <SubTitleTextBox>[해외직구 상품 현금영수증 안내]</SubTitleTextBox>
          <GuideTextBox>- 해외 직구 상품의 경우 당사 수수료에 대하여만 현금영수증 처리가 가능합니다.</GuideTextBox>
        </BodyContainer>
      )}
      <GuideTextBox>- 자세한 문의는 고객센터 080-288-5252로 문의 부탁드립니다.</GuideTextBox>
    </Container>
  );
};

export default CashReceiptIssuing;

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const TitleTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 700;
`;

const RadioGroupContainer = styled(FlexBox)`
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const RadioButtonContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
  gap: 14px;
`;

const BodyContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
`;

const GuideTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 350;
`;

const HighlightTextBox = styled.span`
  font-size: 13px;
  font-weight: 300;
  color: ${theme.color.primary};
  //word-break: keep-all;
  //overflow-wrap: anywhere;
`;

const SubTitleTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 500;
`;
const InputTypeContainer = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
  gap: 10px;
`;

const BusinessTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 500;
`;

const InputContainer = styled(FlexBox)`
  width: 100%;
  align-items: flex-start;
  gap: 6px;
`;

CashReceiptIssuing.propTypes = {
  isDirectForeignGood: PropTypes.bool,
  cashReceiptInfo: PropTypes.object,
  handleChangeCashReceiptInfo: PropTypes.func,
};
