import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RemoveScroll } from 'react-remove-scroll';

import { FloatingOverlay } from '@floating-ui/react';

import { zIndex } from '@ecp/common/src/style/constant';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { placements } from '@ecp/common/src/components/unstyled/floating/useModal';
import DrawerUnstyled from '@ecp/common/src/components/unstyled/floating/DrawerUnstyled';
import { DrawerWrapper } from '@ecp/common/src/components/modal/Drawer';
import { ReactComponent as TooltipCloseIcon } from '@ecp/common/src/assets/icon/tooltip__close.svg';

const CloseComponent = styled(TooltipCloseIcon)`
  width: 18px;
  height: 18px;
  position: absolute;
  background-color: ${theme.color.background.white};
  top: 20px;
  ${({ position }) => `${position}: 20px;`};
  cursor: pointer;
`;

const DrawerRoot = React.forwardRef(({ children, zIndex: zIndexProp, ...props }, ref) => {
  const rootRef = useRef(null);

  useImperativeHandle(ref, () => rootRef.current);

  return (
    <RemoveScroll>
      <FloatingOverlay
        style={{
          zIndex: zIndexProp ?? zIndex.modal,
          display: 'flex',
          background: 'rgba(25, 25, 25, 0.8)',
          overflow: 'hidden',
          justifyContent: 'center',
        }}
      >
        <DrawerMobileWrapper>
          <DrawerWrapper ref={rootRef} {...props}>
            {children}
          </DrawerWrapper>
        </DrawerMobileWrapper>
      </FloatingOverlay>
    </RemoveScroll>
  );
});

DrawerRoot.propTypes = {
  zIndex: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.arrayOf(PropTypes.string),
};

DrawerRoot.displayName = 'DrawerRoot';

const Drawer = ({ value, children, placement, offset, duration = 500, delay = '50ms', closePosition, ...props }) => (
  <DrawerUnstyled
    closeable
    duration={duration}
    delay={delay}
    value={value}
    rootComponent={DrawerRoot}
    rootProps={{ padding: '30px' }}
    placement={placement}
    offset={offset}
    closeComponent={<CloseComponent position={closePosition} />}
    {...props}
  >
    {children}
  </DrawerUnstyled>
);

Drawer.displayName = 'Drawer';

Drawer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  children: PropTypes.node,
  onClose: PropTypes.func,
  placement: PropTypes.oneOf(placements),
  offset: PropTypes.number,
  closePosition: PropTypes.oneOf(['left', 'right']),
  duration: PropTypes.number,
  delay: PropTypes.string,
  open: PropTypes.bool,
};

Drawer.defaultProps = {
  closePosition: 'right',
  padding: '30px',
};

export default Drawer;

const DrawerMobileWrapper = styled(FlexBox)`
  width: 360px;
  overflow: hidden;
`;
