import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

import { FILTER_TYPE } from '@mo-pages/search/result/searchOption';

const Tab = ({ text, count, selected, onClick }) => {
  const mappedText = useMemo(() => {
    if (count === 0) {
      return text;
    }

    return `${text}(${count})`;
  }, [count, text]);

  return (
    <>
      <Spacing top={14} bottom={14} width={'100%'} onClick={onClick}>
        <TextBox
          size={moTheme.font.size.$13}
          weight={selected ? moTheme.font.weight.bold : moTheme.font.weight.demiLight}
          title={selected ? `${mappedText}, 선택됨` : ''}
        >
          {mappedText}
        </TextBox>
      </Spacing>
      <HorizontalDivider />
    </>
  );
};

Tab.propTypes = {
  text: PropTypes.string,
  count: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

const SearchFilterPopupTab = ({
  showCategory,
  showBrand,
  showPrice,
  tab,
  categoryCount,
  brandCount,
  priceCount,
  handleChangeTab,
}) => {
  return (
    <Wrapper>
      {showCategory && (
        <Tab
          text={'카테고리'}
          count={categoryCount}
          selected={tab === FILTER_TYPE.CATEGORY}
          onClick={() => handleChangeTab(FILTER_TYPE.CATEGORY)}
        />
      )}
      {showBrand && (
        <Tab
          text={'브랜드'}
          count={brandCount}
          selected={tab === FILTER_TYPE.BRAND}
          onClick={() => handleChangeTab(FILTER_TYPE.BRAND)}
        />
      )}
      {showPrice && (
        <Tab
          text={'가격'}
          count={priceCount}
          selected={tab === FILTER_TYPE.PRICE}
          onClick={() => handleChangeTab(FILTER_TYPE.PRICE)}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled(FlexBoxColumn)`
  width: 120px;
  height: 100%;
  background-color: ${theme.color.background.basic};
  padding: 0 14px 10px 14px;
`;

export default SearchFilterPopupTab;

SearchFilterPopupTab.propTypes = {
  showCategory: PropTypes.bool,
  showBrand: PropTypes.bool,
  showPrice: PropTypes.bool,
  tab: PropTypes.string,
  categoryCount: PropTypes.number,
  brandCount: PropTypes.number,
  priceCount: PropTypes.number,
  handleChangeTab: PropTypes.func,
};
