import { atom } from 'recoil';

export const mainFunctionListState = atom({
  key: 'mainFunctionListState',
  default: [],
});

export const commonMainFunctionListState = atom({
  key: 'commonMainFunctionListState',
  default: [],
});

export const heroBannerListState = atom({
  key: 'heroBannerListState',
  default: [],
});

export const employeeBenefitBrandCountState = atom({
  key: 'employeeBenefitBrandCountState',
  default: '',
});

export const employeeBenefitBrandNameState = atom({
  key: 'employeeBenefitBrandNameState',
  default: '',
});

export const mainBestReviewListState = atom({
  key: 'mainBestReviewListState',
  default: [],
});
