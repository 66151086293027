import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { gnbDefault } from '@ecp/common/src/const/home/gnbConst';

import { userInfoState } from '@mo-recoil/common/user/atom';
import { gnbTypeState } from '@mo-recoil/common/gnb/atom';
import { menuListState } from '@mo-recoil/common/menu/atom';
import { withGnbMenuList } from '@mo-recoil/common/menu/selector';
import mainFunctionApi from '@mo-apis/display/mainFunctionApi';
import GnbSubTop from '@mo-components/common/gnb/GnbSubTop';
import GnbTopUtility from '@mo-components/common/gnb/GnbTopUtility';
import { appStoreLinkBannerState } from '@mo-recoil/banner/atom';

const Gnb = () => {
  const type = useRecoilValue(gnbTypeState);
  const [linkSiteList, setLinkSiteList] = useState([]);
  const [searchPhraseList, setSearchPhraseList] = useState([]);
  const userInfo = useRecoilValue(userInfoState);
  const setMenuList = useSetRecoilState(menuListState);
  const gnbMenuList = useRecoilValue(withGnbMenuList);
  const searchPhraseSelectedIndex = sessionStorage.getItem('searchPhraseSelectedIndex');
  const appLinkBanner = useRecoilValue(appStoreLinkBannerState);

  const fetchAllianceBarList = useCallback(async () => {
    const { result } = await mainFunctionApi.getAllianceBarList();
    const mallList = result.map(({ exposureSiteName, mobileUrl, mobileNewWindow }) => ({
      label: exposureSiteName,
      url: mobileUrl,
      target: mobileNewWindow ? '_blank' : '_self',
    }));
    setLinkSiteList(mallList);
  }, []);

  const fetchGnbMenuList = useCallback(async () => {
    const { result } = await mainFunctionApi.getGnbList();
    const gnbList = result.map(({ title, bullet, fontColorCode, highLight, mobileNewWindow, mobileUrl }) => ({
      title: title.slice(0, 6),
      link: mobileUrl,
      dot: bullet,
      bold: highLight,
      color: fontColorCode ? `#${fontColorCode}` : gnbDefault.color,
      target: mobileNewWindow ? '_blank' : '_self',
    }));
    setMenuList(gnbList);
  }, [setMenuList, userInfo]);

  const fetchSearchPhraseList = useCallback(async () => {
    const { result } = await mainFunctionApi.getSearchPhraseList();

    const searchPhraseList = result.map(({ text, mobileNewWindow, mobileUrl }) => ({
      text,
      mobileUrl,
      target: mobileNewWindow ? '_blank' : '_self',
    }));
    sessionStorage.setItem(
      'searchPhraseSelectedIndex',
      searchPhraseSelectedIndex !== null
        ? parseInt(searchPhraseSelectedIndex) >= searchPhraseList.length - 1
          ? 0
          : parseInt(searchPhraseSelectedIndex) + 1
        : 0
    );
    setSearchPhraseList(searchPhraseList);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([fetchAllianceBarList(), fetchGnbMenuList(), fetchSearchPhraseList()]);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [fetchAllianceBarList, fetchGnbMenuList, fetchSearchPhraseList]);

  return (
    <>
      {type === gnbTypes.MENU ? (
        <GnbTopUtility
          linkSiteList={linkSiteList}
          gnbMenuList={gnbMenuList}
          searchPhraseList={searchPhraseList}
          searchPraseListIndex={parseInt(searchPhraseSelectedIndex)}
          appLinkBanner={appLinkBanner}
        />
      ) : (
        <GnbSubTop type={type} appLinkBanner={appLinkBanner} />
      )}
    </>
  );
};

export default Gnb;

export const gnbTypes = {
  MENU: 'menu',
  TITLE: 'title',
  SEARCH: 'search',
  EVENT: 'event',
};
