export const MOBILE_APP_STATUS = {
  MAJOR_UPDATE: 'MajorUpdate',
  MINOR_UPDATE: 'MinorUpdate',
  NOT_APPLICABLE: 'NotApplicable',
  LATEST: 'Latest',
  UNKNOWN: 'Unknown',
};

export const APP_UPDATE_TITLE = '새로운 업데이트 버전이 있습니다.';
export const APP_UPDATE_CONTENTS = '더 나은 서비스 이용을 위해 최신 버전으로\n 앱을 업데이트 해주세요.';
export const APP_FORCE_UPDATE_CONTENTS = '\n앱 업데이트 후 서비스를 이용하실 수 있습니다.';

export const ANDROID = 'ANDROID_APP';
export const iOS = 'IOS_APP';
