import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getFilterTypeBrandOrPrice, initialCurrentCategory } from '@ecp/common/src/utils/good/goodSearchUtil';
import useCategoryFilter from '@ecp/common/src/hooks/search/useCategoryFilter';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, VerticalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import ChipOrigin from '@ecp/common/src/components/chip/mo/ChipOrigin';

import SwiperChipOrigin from '@mo-components/display/SwiperChipOrigin';
import SearchFilterPopup from '@mo-components/display/filter/components/SearchFilterPopup';
import { FILTER_OPTIONS, FILTER_TYPE } from '@mo-pages/search/result/searchOption';
import { ReactComponent as IconRefresh } from '@mo-assets/image/common/button/btn__image__refresh.svg';
import { ReactComponent as FilterIcon } from '@mo-assets/icon/icon__filter.svg';

const FilterOption = ({ filterOptions, handleOpenFilterPopup }) => {
  return (
    <>
      {filterOptions.map(({ key, label, ...item }) => (
        <ChipWrapper key={key} onClick={() => handleOpenFilterPopup(key)} {...item}>
          <ChipOrigin type={'default'} closable={false} title={label}>
            {label}
          </ChipOrigin>
        </ChipWrapper>
      ))}
    </>
  );
};

FilterOption.propTypes = {
  filterOptions: PropTypes.array,
  handleOpenFilterPopup: PropTypes.func,
};

const FilterSelected = ({ selectedList, handleRefresh, handleDelete, handleOpenFilterPopup }) => {
  const handleOpenPopup = useCallback(
    ({ type, label }) => {
      const key = type && label ? getFilterTypeBrandOrPrice(type, label) : FILTER_TYPE.CATEGORY;
      handleOpenFilterPopup(key);
    },
    [handleOpenFilterPopup]
  );

  return (
    <FlexBox>
      <Spacing left={14} />
      <IconRefresh onClick={handleRefresh} />
      <Spacing left={14} />
      <SwiperChipOrigin
        width={'250px'}
        itemList={selectedList}
        itemProps={{ width: 'auto', onClick: handleOpenPopup, onClose: handleDelete }}
      />
    </FlexBox>
  );
};

FilterSelected.propTypes = {
  selectedList: PropTypes.array,
  handleRefresh: PropTypes.func,
  handleDelete: PropTypes.func,
  handleOpenFilterPopup: PropTypes.func,
};

const initialTempFilterInfo = {
  tab: FILTER_TYPE.CATEGORY,
  searchTerm: '',
  researchTerm: '',
  typoTerm: '',
  noTypoCorrection: false,
  categoryNumber: {
    large: '',
    middle: '',
    small: '',
    detail: '',
    current: '',
  },
  brandList: [],
  price: { min: '', max: '', label: '' },
};

const SearchFilterBar = ({
  filterOptions,
  searchCondition,
  brandNumberToName,
  brandList,
  priceList,
  largeCategoryList,
  middleCategoryList,
  smallCategoryList,
  detailCategoryList,
  goodTotalCount,
  handleChangeFilter,
  handleApplyFilter,
  handleRefreshFilter,
}) => {
  const [filterPopupOpen, setFilterPopupOpen] = useState(false);
  const [tempFilterInfo, setTempFilterInfo] = useState(initialTempFilterInfo);
  const [tempCurrentCategory, setTempCurrentCategory] = useState(initialCurrentCategory);

  const {
    filteredLargeCategory,
    filteredMiddleCategory,
    filteredSmallCategory,
    filteredDetailCategory,
    categoryNumberToName,
    getTypeOfTreeItem,
    getCategoryToBeChanged,
  } = useCategoryFilter({
    currentCategory: tempCurrentCategory,
    largeCategoryList,
    middleCategoryList,
    smallCategoryList,
    detailCategoryList,
  });

  const getSelectedFilterList = useCallback(
    (filter) => {
      const researchTerm = filter?.researchTerm ? [{ label: filter?.researchTerm, type: 'rescan' }] : [];
      const category = filter?.categoryNumber?.current
        ? [
            {
              label: categoryNumberToName[filter?.categoryNumber?.current],
              type: 'category',
            },
          ]
        : [];
      const brand =
        filter?.brandList?.map((it) => ({
          label: brandNumberToName[it],
          type: 'brand',
        })) || [];
      const price = filter?.price?.label ? [{ ...filter?.price, type: 'price' }] : [];
      return [...researchTerm, ...category, ...brand, ...price];
    },
    [brandNumberToName, categoryNumberToName]
  );
  const selectedFilterList = useMemo(() => {
    return getSelectedFilterList(searchCondition);
  }, [searchCondition, getSelectedFilterList]);
  const tempSelectedFilterList = useMemo(() => {
    return getSelectedFilterList(tempFilterInfo);
  }, [getSelectedFilterList, tempFilterInfo]);

  const handleOpenFilterPopup = useCallback(
    (tab) => {
      setFilterPopupOpen(true);
      setTempFilterInfo({ ...searchCondition, tab });
      setTempCurrentCategory({
        categoryNumber: searchCondition.categoryNumber,
        depthNumber: searchCondition.depthNumber,
      });
    },
    [searchCondition]
  );

  const handleChangeTempFilter = useCallback((updated) => {
    setTempFilterInfo((prev) => ({ ...prev, ...updated }));
  }, []);

  const handleRefreshTempFilter = useCallback(() => {
    setTempFilterInfo((prev) => ({
      ...initialTempFilterInfo,
      searchTerm: prev?.searchTerm,
      researchTerm: prev?.researchTerm,
      typoTerm: prev?.typoTerm,
      noTypoCorrection: prev?.noTypoCorrection,
      tab: prev?.tab,
    }));
    setTempCurrentCategory(initialCurrentCategory);
  }, []);

  const getDeleteFilter = (deleted, searchCondition) => {
    if (deleted?.type === 'price') {
      return { price: { min: '', max: '', label: '' } };
    }

    if (deleted?.type === 'brand') {
      const updatedBrandList = searchCondition?.brandList.filter((it) => it?.value !== deleted?.value);
      return { brandList: updatedBrandList };
    }

    if (deleted?.type === 'rescan') {
      return { researchTerm: '' };
    }

    return { categoryNumber: { large: '', middle: '', small: '', detail: '' } };
  };
  const handleDeleteFilter = useCallback(
    (filter) => {
      const updateFilter = getDeleteFilter(filter, searchCondition);
      handleChangeFilter(updateFilter);
      if (filter?.type === 'category') {
        setTempCurrentCategory(initialCurrentCategory);
      }
    },
    [searchCondition, handleChangeFilter, setTempCurrentCategory]
  );
  const handleDeleteTempFilter = useCallback(
    (filter) => {
      const updateFilter = getDeleteFilter(filter, searchCondition);
      handleChangeTempFilter(updateFilter);
      if (filter?.type === 'category') {
        setTempCurrentCategory(initialCurrentCategory);
      }
    },
    [searchCondition, handleChangeTempFilter, setTempCurrentCategory]
  );

  const handleApplyFilterConfirm = useCallback(() => {
    handleApplyFilter({ ...tempFilterInfo, ...tempCurrentCategory });
    setFilterPopupOpen(false);
  }, [handleApplyFilter, tempCurrentCategory, tempFilterInfo]);

  const handleClosePopup = useCallback(() => {
    setFilterPopupOpen(false);
  }, []);

  useEffect(() => {
    if (filterPopupOpen) {
      window.addEventListener('popstate', handleClosePopup);
    }
    return () => window.removeEventListener('popstate', handleClosePopup);
  }, [filterPopupOpen, handleClosePopup]);

  return (
    <>
      <Wrapper>
        <Spacing left={25} />
        <button type="button" aria-label="필터 열기">
          <StyledFilterIcon
            data-ds-label2={'all_filter'}
            onClick={() =>
              handleOpenFilterPopup(
                filterOptions.length === FILTER_OPTIONS.length ? FILTER_TYPE.CATEGORY : filterOptions[0].key
              )
            }
          />
        </button>
        <Spacing left={14} right={10}>
          <VerticalDivider height={12} />
        </Spacing>
        {selectedFilterList.length === 0 ? (
          <FilterOption filterOptions={filterOptions} handleOpenFilterPopup={handleOpenFilterPopup} />
        ) : (
          <FilterSelected
            selectedList={selectedFilterList}
            handleRefresh={handleRefreshFilter}
            handleDelete={handleDeleteFilter}
            handleOpenFilterPopup={handleOpenFilterPopup}
          />
        )}
      </Wrapper>
      <SearchFilterPopup
        open={filterPopupOpen}
        filterOptions={filterOptions}
        filterInfo={tempFilterInfo}
        totalCount={goodTotalCount}
        filteredLargeCategory={filteredLargeCategory}
        filteredMiddleCategory={filteredMiddleCategory}
        filteredSmallCategory={filteredSmallCategory}
        filteredDetailCategory={filteredDetailCategory}
        brandList={brandList}
        priceList={priceList}
        selectedFilterList={tempSelectedFilterList}
        currentCategory={tempCurrentCategory}
        onClose={() => setFilterPopupOpen(false)}
        handleChangeFilter={handleChangeTempFilter}
        handleRefreshFilter={handleRefreshTempFilter}
        handleDeleteFilter={handleDeleteTempFilter}
        handleApplyFilter={handleApplyFilterConfirm}
        handleCurrentCategoryChange={setTempCurrentCategory}
        getTypeOfTreeItem={getTypeOfTreeItem}
        getCategoryToBeChanged={getCategoryToBeChanged}
      />
    </>
  );
};

const Wrapper = styled(FlexBox)`
  width: 100%;
  background: ${theme.color.background.basic};
  height: 51px;
`;

const ChipWrapper = styled(FlexBox)`
  padding-right: 4px;

  &:last-child {
    padding-right: 0;
  }
`;

const StyledFilterIcon = styled(FilterIcon)`
  min-width: 16px;
  min-height: 18px;
`;

export default SearchFilterBar;

SearchFilterBar.propTypes = {
  filterOptions: PropTypes.array,
  searchCondition: PropTypes.object,
  brandNumberToName: PropTypes.object,
  brandList: PropTypes.array,
  priceList: PropTypes.array,
  largeCategoryList: PropTypes.array,
  middleCategoryList: PropTypes.array,
  smallCategoryList: PropTypes.array,
  detailCategoryList: PropTypes.array,
  goodTotalCount: PropTypes.number,
  handleChangeFilter: PropTypes.func,
  handleApplyFilter: PropTypes.func,
  handleRefreshFilter: PropTypes.func,
};

SearchFilterBar.defaultProps = {
  filterOptions: FILTER_OPTIONS,
};
