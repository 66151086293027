import { getRequest } from '@mo-apis/axios';

const getGoodDisplayableList = async (goodNumberList, limit) =>
  await getRequest({
    url: '/api/v1/good-list/displayable',
    params: { goodNumberList, limit },
  });

const getGoodOrderOftenList = async () =>
  await getRequest({
    url: '/api/v1/good-list/order-often',
  });

const getSDIGoodAvailableList = async () =>
  await getRequest({
    url: '/api/v1/good-list/available-sdi',
  });

const goodListApi = {
  getGoodOrderOftenList,
  getGoodDisplayableList,
  getSDIGoodAvailableList,
};

export default goodListApi;
