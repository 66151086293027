import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { endOfToday, isWithinInterval, startOfToday, subMonths } from 'date-fns';
import orderBy from 'lodash-es/orderBy';

import { exhibitionListState } from '@mo-recoil/exhibition/atom';
import exhibitionApi from '@mo-apis/exhibition/exhibitionApi';
import { EXHIBITION_TYPE_CODE } from '@mo-const/exhibition/exhibitionConst';

const useTopExposedExhibition = () => {
  const [exhibitionList, setExhibitionList] = useRecoilState(exhibitionListState);

  useEffect(() => {
    (async () => {
      const { result } = await exhibitionApi.getExhibitionList();
      setExhibitionList([...result]);
    })();
  }, [setExhibitionList]);

  const getTopExposedExhibitionList = useCallback((list) => {
    let topExposedExhibitions;
    const withInAMonth = list?.filter((exhibition) =>
      isWithinInterval(new Date(exhibition.registerDatetime), {
        start: subMonths(startOfToday(), 1),
        end: endOfToday(),
      })
    );
    const mdContentsExhibitions =
      withInAMonth?.filter((exhibition) => exhibition.typeCode === EXHIBITION_TYPE_CODE.MD_CONTENTS) || [];
    topExposedExhibitions = [...mdContentsExhibitions];

    if (mdContentsExhibitions.length < 2) {
      const discountExhibitions = list?.filter((exhibition) => exhibition.typeCode === EXHIBITION_TYPE_CODE.SALE) || [];
      const mdPickExhibitions =
        list?.filter((exhibition) => exhibition.typeCode === EXHIBITION_TYPE_CODE.MD_PICK) || [];
      topExposedExhibitions = [
        ...topExposedExhibitions,
        ...orderBy([...discountExhibitions, ...mdPickExhibitions], ['registerDatetime'], ['desc']),
      ].slice(0, 2);
    }
    return topExposedExhibitions;
  }, []);
  return {
    exhibitionList,
    getTopExposedExhibitionList,
  };
};

export default useTopExposedExhibition;
