export const CUSTOM_DELIVERY_MESSAGE = {
  label: '직접입력',
  key: 'custom',
  value: '',
};

export const CUSTOM_DELIVERY_MESSAGE_WITH_VALUE = {
  label: '직접입력',
  key: 'custom',
  value: 'custom',
};

export const DEFAULT_DELIVERY_MESSAGE = [
  {
    label: '부재시 경비실에 맡겨주세요.',
    key: 'securityRoom',
    value: '부재시 경비실에 맡겨주세요.',
  },
  {
    label: '부재시 휴대폰으로 연락바랍니다.',
    key: 'cellphone',
    value: '부재시 휴대폰으로 연락바랍니다.',
  },
  {
    label: '직접 받겠습니다.',
    key: 'inPerson',
    value: '직접 받겠습니다.',
  },
  {
    label: '집 앞에 놓아주세요.',
    key: 'inFrontOfHome',
    value: '집 앞에 놓아주세요.',
  },
  {
    label: '택배함에 넣어주세요.',
    key: 'box',
    value: '택배함에 넣어주세요.',
  },
];
export const DELIVERY_MESSAGE = [CUSTOM_DELIVERY_MESSAGE, ...DEFAULT_DELIVERY_MESSAGE];
export const DELIVERY_MESSAGE_WITH_CUSTOM_VALUE = [CUSTOM_DELIVERY_MESSAGE_WITH_VALUE, ...DEFAULT_DELIVERY_MESSAGE];

export const DELIVERY_LOCATION_MODAL_MODE = {
  INPUT: 'INPUT',
  CREATE: 'CREATE',
  MODIFY: 'MODIFY',
};
