import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, VerticalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

import SwiperChip from '@mo-components/display/SwiperChip';
import { ReactComponent as IconRefresh } from '@mo-assets/image/common/button/btn__image__refresh.svg';

const SelectedFilterBar = ({ selectedList, handleRefresh, handleDelete }) => (
  <SelectedFilterBarWrapper>
    <Spacing left={14} />
    <button type="button" aria-label="상품 검색 결과 정렬 필터 초기화">
      <IconRefresh onClick={handleRefresh} />
    </button>
    <Spacing left={14} />
    <VerticalDivider color={theme.color.text.disabled} height={'12px'} width={'1px'} />
    <Spacing left={10} />
    <SwiperChip itemList={selectedList} itemProps={{ width: 'auto', onClose: handleDelete, ariaLabel: '선택한 필터 삭제' }} width={'311px'} />
  </SelectedFilterBarWrapper>
);

export default SelectedFilterBar;

SelectedFilterBar.propTypes = {
  selectedList: PropTypes.array,
  handleRefresh: PropTypes.func,
  handleDelete: PropTypes.func,
};

const SelectedFilterBarWrapper = styled(FlexBox)`
  width: 100%;
  height: 58px;
  min-height: 58px;
  background-color: ${theme.color.background.basic};
`;
