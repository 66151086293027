import { getRequest } from '@mo-apis/axios';

const checkMembershipCouponDownloaded = () => {
  return getRequest({
    url: `/api/v1/coupon/check/membership`,
  });
};
const getGradeList = () =>
  getRequest({
    url: '/api/v1/mypage/grade/grade-list',
  });

const getUserCurrentGradeInfo = () => {
  return getRequest({
    url: `/api/v1/mypage/grade`,
  });
};

const getUserNextGradeInfo = () => {
  return getRequest({
    url: `/api/v1/mypage/grade/next`,
  });
};
const downloadMembershipCoupon = () =>
  getRequest({
    url: '/api/v1/coupon/membership/download',
  });

const getGradeCouponList = () => {
  return getRequest({
    url: `/api/v1/mypage/grade/coupon`,
  });
};

const myMembershipApi = {
  checkMembershipCouponDownloaded,
  getGradeList,
  getUserCurrentGradeInfo,
  getUserNextGradeInfo,
  downloadMembershipCoupon,
  getGradeCouponList,
};

export default myMembershipApi;
