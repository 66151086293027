import React, { useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { format } from 'date-fns';
import debounce from 'lodash-es/debounce';

import useCommonOrderPage from '@ecp/common/src/hooks/order/payment/useCommonOrderPage';
import useOrderQuantityChange from '@ecp/common/src/hooks/order/payment/useOrderQuantityChange';
import usePresentOrderPage from '@ecp/common/src/hooks/order/payment/usePresentOrderPage';
import useOrderValidation from '@ecp/common/src/hooks/order/payment/useOrderValidation';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { RectanglePageButton } from '@ecp/common/src/components/button/mo/Button';
import Floating from '@ecp/common/src/components/floating/mo/Floating';

import { setDeliveryListWithDeliveryCost } from '@mo-utils/orderUtils';
import { withAdultCertification } from '@mo-recoil/common/user/selector';
import { systemInformationState } from '@mo-recoil/system/atom';
import { useGnbTitle } from '@mo-hooks/common/useGnb';
import { useAlertDialog } from '@mo-hooks/common/useModal';
import orderApi from '@mo-apis/order/orderApi';
import NoticeBulletGuideBox from '@mo-components/common/NoticeBulletGuideBox';
import AgreeTermsConditions from '@mo-components/order/payment/AgreeTermsConditions';
import DiscountBenefitInformation from '@mo-components/order/payment/DiscountBenefitInformation';
import OrderDeliveryHopeDate from '@mo-components/order/payment/OrderDeliveryHopeDate';
import OrdererInformation from '@mo-components/order/payment/OrdererInformation';
import OrderPayment from '@mo-components/order/payment/OrderPayment';
import OrderPaymentStatement from '@mo-components/order/payment/OrderPaymentStatement';
import OrderSummary from '@mo-components/order/payment/OrderSummary';
import PaymentMethodInformation from '@mo-components/order/payment/PaymentMethodInformation';
import PointsUsageInformation from '@mo-components/order/payment/PointsUsageInformation';
import PresentMessageInformation from '@mo-components/order/payment/present/PresentMessageInformation';
import PresentReceiverInformation from '@mo-components/order/payment/present/PresentReceiverInformation';
import OrderAdultVerifyPopup from '@mo-components/order/payment/OrderAdultVerifyPopup';
import OrderGoodItemListSingle from '@mo-components/order/payment/OrderGoodItemListSingle';
import useSiteInfo from '@mo-hooks/common/useSiteInfo';
import { PAYMENT_MEAN_CODE } from '@ecp/common/src/const/order/orderConst';

const PresentOrderPage = ({ orderPageInfo, orderType }) => {
  useGnbTitle('주문결제');
  const { isPriceOpen, isPointUse } = useSiteInfo();
  const { showAlertDialog } = useAlertDialog();
  const isAdultVerified = useRecoilValue(withAdultCertification);
  const systemInformation = useRecoilValue(systemInformationState);
  const [orderPaymentData, setOrderPaymentData] = useState({});
  const [cashReceiptInfo, setCashReceiptInfo] = useState({ cashReceiptType: 'none' });
  const [presentInfo, setPresentInfo] = useState({});
  const [deliveryNumber, setDeliveryNumber] = useState(1);
  const [taxInvoiceInfo, setTaxInvoiceInfo] = useState({ bizName: '', bizNumber: '', bizEmail: orderPageInfo?.ordererInformation?.ordererEmail });

  const { validate, error } = useOrderValidation({ isAdultVerified, showAlertDialog });

  const {
    totalPayAmount,
    pointUsage,
    handleChangePointUsage,
    ordererInformation,
    setOrdererInformation, //TODO: handleChange..로 wrap 하기
    paymentMethod,
    handleChangePaymentMethod,
    orderItemCount,
    hasDeliveryHopeDate,
    couponList,
    goodCouponList,
    cartCouponList,
    goodPriceList,
    handleConfirmCoupons,
    paymentAmount,
    orderDeliveryList,
    standardResetOrderDeliveryList,
    deliveryCostCoupon,
    presentResetOrderDeliveryList,
    usablePaymentList,
    handleSimpleApply,
    simpleCouponApply,
    setCouponList,
    setGoodCouponList,
    setCartCouponList,
    setDeliveryCostCoupon,
    setGoodPriceList,
    setOrderDeliveryList,
    getOrderDeliveryList,
    setIsFinishInit,
    enableCouponUsage,
    enablePointUsage,
    enableSavingsUsage,
    agreeTermsChecked,
    handleAgreeTermsCheckChange,
    isProcessPayment,
    handlePaymentStart,
    handlePaymentComplete,
    isFamilyDiscountOrder,
    handleWelPointCashReceiptIssuePoss,
    isWelPointCashReceiptIssuePoss,
  } = useCommonOrderPage({
    orderType,
    orderPageInfo,
    setDeliveryListWithDeliveryCost,
    systemInformation,
  });
  const { orderGoodType, decorationImageList } = usePresentOrderPage(orderPageInfo);
  const { onChangeOrderQuantity } = useOrderQuantityChange(
    orderApi.changeOrderQuantity,
    setCouponList,
    setGoodCouponList,
    setCartCouponList,
    setDeliveryCostCoupon,
    setGoodPriceList,
    setOrderDeliveryList,
    setIsFinishInit
  );

  const onChangeDeliveryHopeDate = useCallback(
    (date) => {
      const newOrderDeliveryList = orderDeliveryList.map((item) => {
        return { ...item, deliveryPlace: { ...item.deliveryPlace, deliveryDate: format(date, 'yyyyMMdd') } };
      });
      standardResetOrderDeliveryList(newOrderDeliveryList);
    },
    [orderDeliveryList, standardResetOrderDeliveryList]
  );

  const debouncedClick = useMemo(
    () =>
      debounce(async () => {
        if (!agreeTermsChecked) {
          showAlertDialog('개인정보동의 확인 해주세요');
          return;
        }

        const data = {
          orderTypeCode: '90', // OD0010 90 선물
          present: true,
          orderGoodType,
          paymentMethod,
          orderDeliveryList,
          ordererInformation,
          paymentAmount,
          cashReceiptInfo,
          couponList,
          deliveryCostCoupon,
          presentInfo,
          welPointCashReceiptIssuePoss: isWelPointCashReceiptIssuePoss,
          isPriceOpen: isPriceOpen,
          taxInvoiceInfo: taxInvoiceInfo,
        };

        if (!(await validate(data))) return;
        if (isProcessPayment) return;
        handlePaymentStart();

        setOrderPaymentData(data);
      }, 200),
    [
      agreeTermsChecked,
      orderGoodType,
      paymentMethod,
      orderDeliveryList,
      ordererInformation,
      paymentAmount,
      cashReceiptInfo,
      couponList,
      deliveryCostCoupon,
      presentInfo,
      validate,
      isProcessPayment,
      handlePaymentStart,
      showAlertDialog,
      isWelPointCashReceiptIssuePoss,
      isPriceOpen,
      taxInvoiceInfo,
    ]
  );

  const handlePaymentButtonClick = useCallback(debouncedClick, [debouncedClick]);

  const handlerChangeTaxInvoice = useCallback((item) => {
    setTaxInvoiceInfo((prev) => ({ ...prev, ...item}));
  }, []);

  return (
    <Container>
      <Spacing top="20px" />
      <OrdererInformation
        isPresent
        goodList={orderPageInfo.goodList}
        ordererInformation={ordererInformation}
        handleChangeOrdererInformation={setOrdererInformation}
      />
      <HorizontalDivider height="10px" />
      <Spacing top="20px" />
      <InnerContainer>
        <PresentReceiverInformation
          goodList={orderPageInfo.goodList}
          ordererInformation={ordererInformation}
          orderDeliveryList={orderDeliveryList}
          handleChangeOrderDeliveryList={presentResetOrderDeliveryList}
          getOrderDeliveryList={getOrderDeliveryList}
          onChangeOrderQuantity={onChangeOrderQuantity}
          goodPriceList={goodPriceList}
          orderItemCount={orderItemCount}
          deliveryNumber={deliveryNumber}
          setDeliveryNumber={setDeliveryNumber}
          isFamilyDiscountOrder={isFamilyDiscountOrder}
        />
        <OrderGoodItemListSingle
          goodList={orderPageInfo.goodList}
          isPresent
          receiverCount={deliveryNumber}
          deliveryCost={paymentAmount?.deliveryAmount?.total || 0}
        />
        <PresentMessageInformation decorationImageList={decorationImageList} handleChangePresentInfo={setPresentInfo} />
        {isPriceOpen && enableCouponUsage && (
          <DiscountBenefitInformation
            paymentAmount={paymentAmount}
            goodCouponList={goodCouponList}
            cartCouponList={cartCouponList}
            couponList={couponList}
            goodPriceList={goodPriceList}
            onChange={handleConfirmCoupons}
            deliveryCostCoupon={deliveryCostCoupon}
            paymentMethod={paymentMethod}
            onClickSimpleApply={handleSimpleApply}
            simpleCouponApply={simpleCouponApply}
          />
        )}
        {isPriceOpen && isPointUse && (
          <PointsUsageInformation
            totalPayAmount={totalPayAmount}
            pointUsage={pointUsage}
            handleChangePointUsage={handleChangePointUsage}
            goodList={orderPageInfo.goodList}
            paymentAmount={paymentAmount}
            orderGoodType={orderGoodType}
            usablePaymentList={usablePaymentList}
            enablePointUsage={enablePointUsage}
            enableSavingsUsage={enableSavingsUsage}
            disablePaymentMethodPointUsage={[PAYMENT_MEAN_CODE.TAX_INVOICE].includes(paymentMethod?.payMeanCode)}
            handleWelPointCashReceiptIssuePoss={handleWelPointCashReceiptIssuePoss}
          />
        )}
        {hasDeliveryHopeDate && (
          <OrderDeliveryHopeDate onChange={onChangeDeliveryHopeDate} orderGoodType={orderGoodType} />
        )}
        {isPriceOpen && (
          <>
            <OrderPaymentStatement paymentAmount={paymentAmount} />
            <PaymentMethodInformation
              handleChangePaymentMethod={handleChangePaymentMethod}
              ordererInformation={ordererInformation}
              paymentMethod={paymentMethod}
              isDirectForeignGood={orderGoodType?.directForeignGood}
              cashReceiptInfo={cashReceiptInfo}
              handleChangeCashReceiptInfo={setCashReceiptInfo}
              usablePaymentList={usablePaymentList}
              couponList={couponList}
              orderType={orderType}
              isWelPointCashReceiptIssuePoss={isWelPointCashReceiptIssuePoss}
              taxInvoiceInfo={taxInvoiceInfo}
              handlerChangeTaxInvoice={handlerChangeTaxInvoice}
            />
          </>
        )}
        <Spacing margin="-20px 0 0 0">
          <NoticeBulletGuideBox
            title="선물하기 안내"
            textList={[
              '결제를 완료하시면 선물안내 문자가 받는 분의 휴대전화 또는 알림톡으로 발송됩니다.',
              '받는 분께서 선물 수락과 함께 직접 수령을 원하는 배송주소를 입력하시면 배송이 시작됩니다.',
              '받는 분께서 선물 수락여부와 배송 주소지를 입력하지 않으실 경우 선물 주문일로부터 7일 이후 자동 환불됩니다.',
              '받는 분께서 선물을 거절하실 경우 자동으로 주문이 취소됩니다.',
              '카드사 정책에 따라 부분취소가 불가한 신용카드의 경우, 선물하기 결제수단에서 제외됩니다.',
              '제주도, 도서산간의 경우 추가운임이 발생하거나 배송이 불가할 수 있습니다. 받는 분의 배송지가 배송제한지역은 아닌지 꼭 확인해 주세요.',
            ]}
            outerPadding={'0'}
          />
        </Spacing>
      </InnerContainer>
      <Spacing top="20px" />
      <HorizontalDivider height="10px" />
      <Spacing top="20px" />
      <AgreeTermsConditions
        checked={agreeTermsChecked}
        onChange={handleAgreeTermsCheckChange}
        orderGoodType={orderGoodType}
        orderType={orderType}
      />
      <Spacing top={'68px'} />
      <HorizontalDivider />
      <OrderSummary orderItemCount={orderItemCount} paymentAmount={paymentAmount?.paymentTotalAmount || 0} />
      <Floating
        buttonContent={() => (
          <PayButtonWrapper>
            <RectanglePageButton onClick={handlePaymentButtonClick}>결제하기</RectanglePageButton>
          </PayButtonWrapper>
        )}
      />
      <Spacing top="56px" />
      <OrderPayment orderType={orderType} data={orderPaymentData} onComplete={handlePaymentComplete} />
      <OrderAdultVerifyPopup error={error} />
    </Container>
  );
};
export default PresentOrderPage;

PresentOrderPage.propTypes = {
  orderPageInfo: PropTypes.object,
  orderType: PropTypes.string,
};

const Container = styled(FlexBoxColumn)`
  align-self: stretch;
  align-items: center;
`;
const InnerContainer = styled(FlexBoxColumn)`
  width: 100%;
  gap: 40px;
  padding: 0 20px;
`;
const PayButtonWrapper = styled(FlexBox)`
  width: 360px;
`;
