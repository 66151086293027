import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';

const OrderPaymentStatementItemDetail = ({ title, price }) => {
  return price !== '0' ? (
    <Container>
      <TitleTextBox>{title}</TitleTextBox>
      <PriceContainer>
        <PriceTextBox>{price}</PriceTextBox>
        <UnitTextBox>원</UnitTextBox>
      </PriceContainer>
    </Container>
  ) : (
    <></>
  );
};

export default OrderPaymentStatementItemDetail;
OrderPaymentStatementItemDetail.propTypes = {
  title: PropTypes.string,
  price: PropTypes.string,
};

const Container = styled(FlexBoxSpaceBetween)`
  width: 100%;
`;

const TitleTextBox = styled(TextBox)`
  font-weight: 350;
  font-size: 12px;
  color: #727272;
`;

const PriceContainer = styled(FlexBox)``;

const PriceTextBox = styled(TextBox)`
  font-weight: 700;
  font-size: 12px;
  color: #727272;
`;

const UnitTextBox = styled(TextBox)`
  font-weight: 350;
  font-size: 12px;
  color: #727272;
`;
