import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { numberOrStringType } from '@ecp/common/src/style/propTypes';
import useSwiperArrow from '@ecp/common/src/components/swiper/useSwiperArrow';
import { ReactComponent as IconLeftArrow } from '@ecp/common/src/assets/icon/left-rolling.svg';
import { ReactComponent as IconRightArrow } from '@ecp/common/src/assets/icon/right-rolling.svg';

const getArrowProps = ({ padding, type = '15', position = 'outer', direction = 'left', top }) => {
  switch (type) {
    case '10':
      return {
        [direction]: `${position === 'outer' ? '-' : ''}${padding ? padding : 0}`,
        top: top ?? 'calc(50% - 25px)',
        width: '40px',
        height: '40px',
        background: 'rgba(0, 0, 0, 0.25)',
        padding: '12px 16px',
        stroke: '#ffffff',
        'stroke-width': '8px',
      };
    case '15':
    default:
      return {
        [direction]: `${position === 'outer' ? '-' : ''}${padding ? padding : '35px'}`,
        width: '15px',
        height: '30px',
        top: top ?? '31.5%',
        'background-size': '15px 30px',
      };
  }
};

const StyledArrow = styled.span`
  position: absolute;
  z-index: 2;
  background: none;
  border: none;
  display: inline-block;
  content: '';
  cursor: pointer;
  stroke: ${({ color }) => color ?? '#a3a3a3'};
  stroke-width: ${({ strokeWidth }) => strokeWidth ?? '3px'};
  ${(props) => getArrowProps(props)};
`;

export const ArrowPrev = ({ onClick, isEdge, ...props }) => {
  const { handleClick } = useSwiperArrow({ onClick });

  return (
    <>
      {!isEdge && (
        <StyledArrow direction="left" onClick={handleClick} {...props}>
          <IconLeftArrow />
        </StyledArrow>
      )}
    </>
  );
};

ArrowPrev.displayName = 'ArrowPrev';

ArrowPrev.propTypes = {
  padding: numberOrStringType,
  type: PropTypes.oneOf(['10', '15']),
  position: PropTypes.oneOf(['outer', 'inner']),
  top: PropTypes.string,
  onClick: PropTypes.func,
  isEdge: PropTypes.bool,
};

export const ArrowNext = ({ onClick, isEdge, ...props }) => {
  const { handleClick } = useSwiperArrow({ onClick });

  return (
    <>
      {!isEdge && (
        <StyledArrow direction="right" onClick={handleClick} {...props}>
          <IconRightArrow />
        </StyledArrow>
      )}
    </>
  );
};
ArrowNext.displayName = 'ArrowNext';

ArrowNext.propTypes = {
  padding: numberOrStringType,
  type: PropTypes.oneOf(['10', '15']),
  position: PropTypes.oneOf(['outer', 'inner']),
  top: PropTypes.string,
  onClick: PropTypes.func,
  isEdge: PropTypes.bool,
};
