import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

import GoodTableCardList from '@mo-components/good/GoodTableCardList';

const GoodCardListBySubCategory = ({
  storedGoodCardRef,
  pageProps,
  totalCount,
  goodList,
  handleSortChange,
  fetchNextPage,
  handleViewTypeChange,
  handleSubDisplayCategoryGoodSoldOutChangeList,
}) => {
  const cardType = useMemo(() => (pageProps.listView ? 'horizontalMedium' : 'medium'), [pageProps.listView]);

  return (
    <Container>
      <Spacing top={30} />
      <GoodTableCardList
        storedGoodCardRef={storedGoodCardRef}
        pageProps={{ ...pageProps, totalCount }}
        goodList={goodList}
        onSortChange={handleSortChange}
        fetchNextPage={fetchNextPage}
        cardType={cardType}
        onViewChange={handleViewTypeChange}
        titleType={'secondary'}
        handleSubDisplayCategoryGoodSoldOutChangeList={handleSubDisplayCategoryGoodSoldOutChangeList}
      />
      <Spacing bottom={20} />
    </Container>
  );
};

export default GoodCardListBySubCategory;

GoodCardListBySubCategory.propTypes = {
  storedGoodCardRef: PropTypes.object,
  pageProps: PropTypes.object,
  totalCount: PropTypes.number,
  goodList: PropTypes.array,
  handleSortChange: PropTypes.func,
  fetchNextPage: PropTypes.func,
  handleViewTypeChange: PropTypes.func,
  handleSubDisplayCategoryGoodSoldOutChangeList: PropTypes.func,
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0 20px;
`;
