import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { openWindow } from '@ecp/common/src/utils/utils';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import SearchInput from '@ecp/common/src/components/input/mo/SearchInput';
import { ReactComponent as SearchSvg } from '@ecp/common/src/assets/icon/icon__search.svg';

import { withMobileStatus } from '@mo-recoil/system/selector';

const TextSwiperItem = ({ item, onClick }) => {
  const { text } = item;

  return (
    <TextBox
      cursor={'text'}
      width={'182px'}
      size={moTheme.font.size.$13}
      weight={moTheme.font.weight.demiLight}
      onClick={onClick}
    >
      {text}
    </TextBox>
  );
};

TextSwiperItem.propTypes = {
  item: PropTypes.shape({
    text: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

const TopUtilitySearch = ({ searchPhraseList, isShowSite, searchPraseListIndex }) => {
  const navigate = useNavigate();
  const { isMobileApp, agentType } = useRecoilValue(withMobileStatus);

  const hasSearchPhrase = useMemo(() => {
    return searchPhraseList?.length > 0;
  }, [searchPhraseList]);

  const currentPhrase = useMemo(() => {
    return searchPhraseList[searchPraseListIndex];
  }, [searchPhraseList, searchPraseListIndex]);

  const width = useMemo(() => {
    return isShowSite ? '222px' : '248px';
  }, [isShowSite]);

  const handleClickSearch = useCallback(() => {
    if (searchPhraseList?.length === 0) {
      navigate('/search');
      return;
    }

    currentPhrase?.mobileUrl && openWindow(isMobileApp, currentPhrase?.mobileUrl, currentPhrase?.target, agentType);
  }, [agentType, currentPhrase?.mobileUrl, currentPhrase?.target, isMobileApp, navigate, searchPhraseList?.length]);

  const handleRouteToSearch = useCallback(() => {
    navigate('/search');
  }, [navigate]);

  return (
    <>
      {hasSearchPhrase ? (
        <SearchInputPhraseWrapper width={width}>
          <FlexBox width={'100%'}>
            <Spacing left={10} />
            <FlexBox width={'182px'} height={'23px'} data-ds-label2={'g_search_bar'} onClick={handleRouteToSearch}>
            {searchPhraseList[searchPraseListIndex].text}
            </FlexBox>
            <Spacing right={10} />
            <SearchIcon
              onClick={handleClickSearch}
              data-ds-label2={'g_search_copy'}
              data-ds-contents={currentPhrase?.mobileUrl}
            />
          </FlexBox>
          <HorizontalDivider height={2} color={theme.color.text.basic} />
        </SearchInputPhraseWrapper>
      ) : (
        <FlexBoxColumn width={width} onClick={handleRouteToSearch}>
          <SearchInput type={'main'} clearable placeholder={'검색어를 입력해주세요'} width={'100%'} />
        </FlexBoxColumn>
      )}
    </>
  );
};

const SearchInputPhraseWrapper = styled(FlexBoxColumn)`
  padding-top: 12px;
  height: 44px;
  justify-content: space-between;
`;

const SearchIcon = styled(SearchSvg)`
  width: 20px;
  cursor: pointer;
`;

export default TopUtilitySearch;

TopUtilitySearch.propTypes = {
  searchPhraseList: PropTypes.array,
  isShowSite: PropTypes.bool,
  searchPraseListIndex: PropTypes.number,
};
