import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import DropdownDevice from '@ecp/common/src/components/dropdown/mo/DropdownDevice';
import { InnerButton } from '@ecp/common/src/components/button/mo/Button';
import LabeledCheckBox from '@ecp/common/src/components/checkbox/mo/LabeledCheckBox';
import Input from '@ecp/common/src/components/input/mo/Input';
import {
  CUSTOM_DELIVERY_MESSAGE,
  DEFAULT_DELIVERY_MESSAGE,
  DELIVERY_MESSAGE,
} from '@ecp/common/src/const/deliveryMessage';

import { concatPhoneNumbers } from '@mo-utils/orderUtils';
import DeliveryPlaceSelectionPopup from '@mo-components/order/payment/DeliveryPlaceSelectionPopup';
import PcccInformation from '@mo-components/order/payment/PcccInformation';
import MyPageDeliveryLocationAddPopup from '@mo-pages/myPage/deliveryLocation/MyPageDeliveryLocationAddPopup';
import { ReactComponent as BaseAddressIcon } from '@mo-assets/icon/base_address_icon.svg';

const DeliveryInformation = ({
  deliveryPlace,
  handleChangeDeliveryPlaceInfo,
  deliveryPlaceIndex,
  isDirectForeignGood,
}) => {
  const {
    base,
    baseAddress,
    detailAddress,
    postNumber,
    receiverName,
    cellphoneNumber1,
    cellphoneNumber2,
    cellphoneNumber3,
    telephoneNumber1,
    telephoneNumber2,
    telephoneNumber3,
    noteOrdererName,
    saveDefaultDeliveryMemo,
    orderMemo,
    deliveryMessage,
    memberDeliverySequence,
  } = deliveryPlace;
  const [isDirectInput, setIsDirectInput] = useState(false);
  const [deliveryMessageDropdownValue, setDeliveryMessageDropdownValue] = useState(null);
  const [deliveryPlaceChangePopupOpen, setDeliveryPlaceChangePopupOpen] = useState(false);
  const [newDeliveryLocationPopupOpen, setNewDeliveryLocationPopupOpen] = useState(false);
  const [orderMemoInput, setOrderMemoInput] = useState('');

  useEffect(() => {
    setOrderMemoInput(orderMemo);
  }, [orderMemo]);

  const handleClickNewDeliveryPlace = useCallback(() => {
    setNewDeliveryLocationPopupOpen(true);
  }, []);

  useEffect(() => {
    if (deliveryMessage) {
      //어쩔수없이 스트링 비교
      const selectedMessage = DEFAULT_DELIVERY_MESSAGE.find(({ value }) => value === deliveryMessage);
      setIsDirectInput(!selectedMessage);
      setDeliveryMessageDropdownValue(selectedMessage || CUSTOM_DELIVERY_MESSAGE);
    }
  }, [deliveryMessage]);

  const cellphoneNumberWithDash = useMemo(() => {
    return `${cellphoneNumber1}-${cellphoneNumber2}-${cellphoneNumber3}`;
  }, [cellphoneNumber1, cellphoneNumber2, cellphoneNumber3]);

  const telephoneNumberWithDash = useMemo(() => {
    if (!telephoneNumber1 || !telephoneNumber2 || !telephoneNumber3) return '';
    return `${telephoneNumber1}-${telephoneNumber2}-${telephoneNumber3}`;
  }, [telephoneNumber1, telephoneNumber2, telephoneNumber3]);

  const handleDeliveryRequestChange = (data) => {
    setDeliveryMessageDropdownValue({ ...data });
    handleChangeDeliveryPlaceInfo(
      deliveryPlaceIndex,
      {
        ...deliveryPlace,
        orderMemo: data.value,
      },
      { refreshAvailableQuantity: false, refreshDeliveryCost: false }
    );
    setIsDirectInput(data.key === CUSTOM_DELIVERY_MESSAGE.key);
  };

  const handleDeliveryRequestInputChange = useCallback((data) => {
    setOrderMemoInput(data.target.value);
  }, []);

  const handleDeliveryRequestInputBlur = useCallback(() => {
    handleChangeDeliveryPlaceInfo(
      deliveryPlaceIndex,
      {
        ...deliveryPlace,
        orderMemo: orderMemoInput,
      },
      { refreshAvailableQuantity: false, refreshDeliveryCost: false }
    );
  }, [deliveryPlace, deliveryPlaceIndex, handleChangeDeliveryPlaceInfo, orderMemoInput]);

  const handleChangePersonalCustomsClearanceCode = useCallback(
    (data) => {
      handleChangeDeliveryPlaceInfo(
        deliveryPlaceIndex,
        {
          ...deliveryPlace,
          pccc: data,
        },
        { refreshAvailableQuantity: false, refreshDeliveryCost: false }
      );
    },
    [deliveryPlace, deliveryPlaceIndex, handleChangeDeliveryPlaceInfo]
  );

  const handleCheckSaveBaseDeliveryMemo = useCallback(
    ({ target: { checked } }) => {
      handleChangeDeliveryPlaceInfo(
        deliveryPlaceIndex,
        { ...deliveryPlace, saveDefaultDeliveryMemo: checked },
        { refreshAvailableQuantity: false, refreshDeliveryCost: false }
      );
    },
    [deliveryPlaceIndex, handleChangeDeliveryPlaceInfo, deliveryPlace]
  );

  const handleCheckNoteOrdererName = useCallback(
    ({ target: { checked } }) => {
      handleChangeDeliveryPlaceInfo(
        deliveryPlaceIndex,
        { ...deliveryPlace, noteOrdererName: checked },
        { refreshAvailableQuantity: false, refreshDeliveryCost: false }
      );
    },
    [deliveryPlaceIndex, handleChangeDeliveryPlaceInfo, deliveryPlace]
  );

  const handleSelectDeliveryPlace = useCallback(
    (data) => {
      handleChangeDeliveryPlaceInfo(
        deliveryPlaceIndex,
        {
          ...deliveryPlace,
          ...data,
          cellphoneNumber: concatPhoneNumbers(data.cellphoneNumber1, data.cellphoneNumber2, data.cellphoneNumber3),
          cellphoneNumber1: data.cellphoneNumber1,
          cellphoneNumber2: data.cellphoneNumber2,
          cellphoneNumber3: data.cellphoneNumber3,
          orderMemo: data.deliveryMessage,
        },
        { refreshAvailableQuantity: false, refreshDeliveryCost: deliveryPlace.postNumber !== data.postNumber }
      );
    },
    [deliveryPlaceIndex, handleChangeDeliveryPlaceInfo, deliveryPlace]
  );

  const handleConfirmNewDeliveryLocationAdd = useCallback(
    (data) => {
      setNewDeliveryLocationPopupOpen(false);
      if (!data) return;
      handleSelectDeliveryPlace(data);
    },
    [handleSelectDeliveryPlace]
  );

  return (
    <Container>
      <HeaderContainer>
        <FlexBox>
          {postNumber ? (
            <FlexBox width={'152px'}>
              {base && (
                <FlexBox width={'72px'}>
                  <BaseAddressIcon />
                </FlexBox>
              )}
              <DeliveryPlaceContainer>
                <DeliveryPlaceName ellipsis>{receiverName}</DeliveryPlaceName>
              </DeliveryPlaceContainer>
            </FlexBox>
          ) : (
            <DeliveryPlaceInputGuide>배송지를 입력해주세요.</DeliveryPlaceInputGuide>
          )}
          <Spacing left={6} />
        </FlexBox>
        <FlexBox width={'164px'}>
          <HeaderButton onClick={handleClickNewDeliveryPlace}>
            <HeaderButtonText>신규배송지</HeaderButtonText>
          </HeaderButton>
          <Spacing left={'4px'} />
          <HeaderButton onClick={() => setDeliveryPlaceChangePopupOpen(true)}>
            <HeaderButtonText>배송지목록</HeaderButtonText>
          </HeaderButton>
        </FlexBox>
      </HeaderContainer>
      {!!postNumber && (
        <>
          <PhoneNumberTextBox>
            {cellphoneNumberWithDash} {telephoneNumberWithDash && `/ ${telephoneNumberWithDash}`}
          </PhoneNumberTextBox>
          <AddressTextBox>
            ({postNumber}) {baseAddress}, {detailAddress}
          </AddressTextBox>
          <Spacing top={'14px'} />
          <DropdownDevice
            width="100%"
            items={DELIVERY_MESSAGE}
            onChange={handleDeliveryRequestChange}
            value={deliveryMessageDropdownValue}
            placeholder="배송 요청사항을 선택해 주세요."
            title={'배송 요청사항'}
          />
          {isDirectInput && (
            <>
              <Spacing top={10} />
              <Input
                value={orderMemoInput || ''}
                onChange={handleDeliveryRequestInputChange}
                onBlur={handleDeliveryRequestInputBlur}
                width={'100%'}
                maxLength={20}
                countCheck
              />
            </>
          )}
          <Spacing top={'10px'} />
          <LabeledCheckBox
            labelText={'보내는 분 표기'}
            checked={noteOrdererName}
            onChange={handleCheckNoteOrdererName}
          />
          <Spacing top={'10px'} />
          <LabeledCheckBox
            labelText={'기본 배송 요청사항으로 저장'}
            checked={saveDefaultDeliveryMemo}
            onChange={handleCheckSaveBaseDeliveryMemo}
          />
        </>
      )}

      {isDirectForeignGood && (
        <>
          <Spacing top={'20px'} />
          <PcccInformation handleChangePersonalCustomsClearanceCode={handleChangePersonalCustomsClearanceCode} />
        </>
      )}
      <DeliveryPlaceSelectionPopup
        open={deliveryPlaceChangePopupOpen}
        onClose={() => {
          setDeliveryPlaceChangePopupOpen(false);
        }}
        onConfirm={handleSelectDeliveryPlace}
        currentValue={memberDeliverySequence}
      />
      <MyPageDeliveryLocationAddPopup
        editLocation={null}
        open={newDeliveryLocationPopupOpen}
        onClose={() => setNewDeliveryLocationPopupOpen(false)}
        refreshData={handleConfirmNewDeliveryLocationAdd}
        isNew
      />
    </Container>
  );
};

export default DeliveryInformation;

DeliveryInformation.propTypes = {
  deliveryPlace: PropTypes.object,
  handleChangeDeliveryPlaceInfo: PropTypes.func,
  deliveryPlaceIndex: PropTypes.number,
  isDirectForeignGood: PropTypes.bool,
};

DeliveryInformation.defaultProps = {
  deliveryPlace: {},
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;
const HeaderContainer = styled(FlexBoxSpaceBetween)`
  width: 100%;
  align-items: center;
`;

const DeliveryPlaceContainer = styled(FlexBox)`
  justify-content: center;
  overflow: hidden;
`;
const DeliveryPlaceName = styled(TextBox)`
  font-size: 14px;
  font-weight: 700;
  width: 100%;
`;

const DeliveryPlaceInputGuide = styled(TextBox)`
  font-size: 14px;
  font-weight: 700;
  width: 150px;
`;
const HeaderButton = styled(InnerButton)`
  width: 80px;
`;
const HeaderButtonText = styled(TextBox)`
  font-weight: 500;
  font-size: 13px;
`;
const PhoneNumberTextBox = styled(TextBox)`
  font-weight: 350;
  font-size: 12px;
  color: #5a5a5a;
`;
const AddressTextBox = styled(TextBox)`
  font-weight: 350;
  font-size: 12px;
  color: #5a5a5a;
`;
