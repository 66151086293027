import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import DropdownDevice from '@ecp/common/src/components/dropdown/mo/DropdownDevice';

import { GoodImage } from '@mo-components/good/GoodCard';

const OrderGoodItemInformationMulti = ({
  goodInfo,
  itemInfo,
  deliveryPlaceIndex,
  handleChangeQuantity,
  errorMessage,
}) => {
  const { goodName, thumbnailImageUrl01, thumbnailImageUrl02, thumbnailImageUrl03, goodNumber } = goodInfo;
  const { itemNumber, itemName, orderQuantity, availableQuantity, adultGood, deliveryQuantity, additionalItem } =
    itemInfo;
  const [selectedItem, setSelectedItem] = useState({ label: String(0), key: String(0), value: String(0) });

  useEffect(() => {
    setSelectedItem({
      label: String(deliveryQuantity),
      key: String(deliveryQuantity),
      value: String(deliveryQuantity),
    });
  }, [deliveryQuantity]);

  const dropdownItems = useMemo(() => {
    return [...new Array(availableQuantity + 1)].map((item, idx) => ({
      label: String(idx),
      key: String(idx),
      value: String(idx),
    }));
  }, [availableQuantity]);

  const onChangeDropDown = useCallback(
    (data) => {
      handleChangeQuantity(deliveryPlaceIndex, goodNumber, itemNumber, Number(data.value));
    },
    [deliveryPlaceIndex, goodNumber, handleChangeQuantity, itemNumber]
  );
  return (
    <Container>
      <ItemContainer>
        <GoodImage
          type="horizontalSmall"
          adultGood={adultGood}
          imgPath={thumbnailImageUrl01 || thumbnailImageUrl02 || thumbnailImageUrl03}
        />
        <Spacing left={'14px'} />
        <ItemDescriptionContainer>
          <GoodNameTextBox>{goodName}</GoodNameTextBox>
          <ItemNameTextContainer>
            <ItemNameTextBox>
              {itemNumber === '00000' ? '' : additionalItem ? `(추가옵션) ${itemName}` : itemName}
            </ItemNameTextBox>
          </ItemNameTextContainer>
          <QuantitySelectContainer>
            <QuantitySelectTextBox required>수량선택</QuantitySelectTextBox>
            <Spacing left={'6px'} />
            <FlexBox width={'80px'}>
              <DropdownDevice
                items={dropdownItems}
                value={selectedItem}
                placeholder={''}
                onChange={onChangeDropDown}
                width={'80px'}
              />
            </FlexBox>
            <Spacing left={'10px'} />
            <QuantitySelectTextBox>{`/ ${orderQuantity}`}개</QuantitySelectTextBox>
          </QuantitySelectContainer>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </ItemDescriptionContainer>
      </ItemContainer>
      <Spacing top={'14px'} />
      <HorizontalDivider />
    </Container>
  );
};

export default OrderGoodItemInformationMulti;
OrderGoodItemInformationMulti.propTypes = {
  goodInfo: PropTypes.object,
  itemInfo: PropTypes.object,
  deliveryPlaceIndex: PropTypes.number,
  handleChangeQuantity: PropTypes.func,
  errorMessage: PropTypes.string,
};

const Container = styled(FlexBoxColumn)`
  align-items: center;
  width: 100%;
`;

const ItemContainer = styled(FlexBox)`
  align-items: flex-start;
  width: 100%;
`;

const ItemDescriptionContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
  align-self: stretch;
`;

const GoodNameTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18px;
`;

const ItemNameTextContainer = styled(FlexBox)`
  height: 22.5px;
`;

const ItemNameTextBox = styled(TextBox)`
  font-size: 12px;
  font-weight: 350;
  color: #5a5a5a;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const QuantitySelectContainer = styled(FlexBox)`
  justify-content: flex-end;
  width: 100%;
`;

const QuantitySelectTextBox = styled(TextBox)`
  font-size: 12px;
  font-weight: 350;
`;

const ErrorMessage = styled(TextBox).attrs(() => ({
  color: theme.color.text.error,
  size: moTheme.font.size.$12,
  weight: moTheme.font.weight.demiLight,
  height: moTheme.font.lineHeight.bold,
}))`
  flex: 1;
  width: 100%;
  align-items: flex-end;
  margin-top: 6px;
`;
