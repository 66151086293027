import React, { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import useScrollDirection from '@ecp/common/src/hooks/useScrollDirection';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

import {
  navigationOffsetState,
  showNavigationMenuState,
  employeeBenefitMessageState,
} from '@mo-recoil/common/navigation/atom';
import { CircleWrapper } from '@mo-components/styled';
import RecentButton from '@mo-components/common/navigation/RecentButton';
import { ReactComponent as TopArrowIcon } from '@mo-assets/icon/icon__top_arrow.svg';

const Container = styled(FlexBoxColumn)`
  align-items: flex-end;

  position: absolute;
  right: 20px;
  bottom: ${({ bottom }) => `${bottom}px`};
  transition: bottom 0.3s ease-in-out;
`;

const SideFloatingNavigation = () => {
  const showNavigationMenu = useRecoilValue(showNavigationMenuState);
  const employeeBenefitMessage = useRecoilValue(employeeBenefitMessageState);
  const navigationOffset = useRecoilValue(navigationOffsetState);
  const scrollDirection = useScrollDirection();

  const showNavigationTop = useMemo(() => {
    return scrollDirection && scrollDirection !== 'top';
  }, [scrollDirection]);

  const navigationBottom = useMemo(() => {
    let bottom = 40 + navigationOffset;

    if (showNavigationMenu) {
      bottom += ['down', 'bottom'].includes(scrollDirection) ? 0 : 60;
    }

    if (employeeBenefitMessage) {
      bottom += ['down', 'bottom'].includes(scrollDirection) ? 0 : 40;
    }

    return bottom;
  }, [navigationOffset, scrollDirection, employeeBenefitMessage, showNavigationMenu]);

  const handleTopClick = useCallback(() => {
    window.scroll({ top: 0 });
  }, []);

  return (
    <Container bottom={navigationBottom}>
      {showNavigationTop && (
        <CircleWrapper onClick={handleTopClick} data-ds-label2={'float_top'}>
          <TopArrowIcon width="10px" height="14px" />
        </CircleWrapper>
      )}
      <Spacing bottom={10} />
      <RecentButton />
    </Container>
  );
};

export default SideFloatingNavigation;
