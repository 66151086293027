import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useClipboard from '@ecp/common/src/hooks/useCopyToClipboard';
import useEventCallback from '@ecp/common/src/hooks/useEventCallback';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { ReactComponent as ShareKakaoIcon } from '@ecp/common/src/assets/icon/share__kakao.svg';
import { ReactComponent as ShareUrlIcon } from '@ecp/common/src/assets/icon/share__url.svg';

// import useToast from '@mo-hooks/common/useToast';
import {useAlertDialog} from '@mo-hooks/common/useModal';
import {useRecoilValue} from "recoil";
import {userInfoState} from "@mo-recoil/common/user/atom";

const kakaoKey = process.env.REACT_APP_KAKAO_KEY;

const StyledShareKakaoIcon = styled(ShareKakaoIcon)`
  ${overrideProperties(sizePropTypes, { width: '30px', height: '30px' })};
  cursor: pointer;
`;

const StyledShareUrlIcon = styled(ShareUrlIcon)`
  ${overrideProperties(sizePropTypes, { width: '30px', height: '30px' })};
  cursor: pointer;
`;

const shareKakao = (siteNumber, kakaoProps) => {
  const {
    type,
    title,
    imageUrl,
    productName,
    regularPrice,
    discountRate,
    discountPrice,
    linkUrl=window.location.href,
    productNumber,
  } = kakaoProps;


  if(siteNumber === process.env.REACT_APP_WELLMALL) {
    kakaoProps.linkUrl = process.env.REACT_APP_DEEP_LINK_DEFAULT_URL;
    kakaoProps.linkUrl += encodeURIComponent(process.env.REACT_APP_DEEP_LINK_GOOD_DETAIL) + productNumber;
  } else {
    kakaoProps.linkUrl = linkUrl;
  }

  const kakao = window.Kakao;
  if (kakao) {
    if (!kakao.isInitialized()) {
      kakao.init(kakaoKey);
    }

    if (type === 'commerce') {
      const baseCommerce = {
        productName: productName || '',
        regularPrice: regularPrice || 0,
        discountRate: discountRate || 0,
      };
      const commerce =
        regularPrice === discountPrice ? { ...baseCommerce } : { ...baseCommerce, discountPrice: discountPrice || 0 };

      kakao.Share.sendDefault({
        objectType: 'commerce',
        content: {
          title: title || '',
          imageUrl: imageUrl || '',
          link: {
            mobileWebUrl: kakaoProps.linkUrl,
            webUrl: kakaoProps.linkUrl,
          },
        },
        commerce,
        buttons: [
          {
            title: '구매하기',
            link: {
              androidExecutionParams: 'linkUrl=' + encodeURIComponent(process.env.REACT_APP_DEEP_LINK_GOOD_DETAIL) + kakaoProps.productNumber,
              iosExecutionParams:  'linkUrl=' + encodeURIComponent(process.env.REACT_APP_DEEP_LINK_GOOD_DETAIL) + kakaoProps.productNumber,
            },
          },
        ],
      });
    } else if (type === 'scrap') {
      kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          title: title || '',
          imageUrl: imageUrl || '',
          link: {
            mobileWebUrl: kakaoProps.linkUrl,
            webUrl: kakaoProps.linkUrl,
          },
        },
        buttons: [
          {
            title: '자세히보기',
            link: {
              mobileWebUrl: kakaoProps.linkUrl,
              webUrl: kakaoProps.linkUrl,
            },
          },
        ],
      });
    }
  }
};

const ShareLink = ({ urlLink = window.location.href, iconProps, gap, kakaoProps }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js';
    script.async = true;
    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, []);
  const { siteNumber } = useRecoilValue(userInfoState) || {};

  const { onCopy } = useClipboard();
  // const { showToast } = useToast();
  const handleKakaoClick = useEventCallback(
    () => {
      shareKakao(siteNumber, kakaoProps);
    },
    { stop: true }
  );
  const { showAlertDialog } = useAlertDialog();
  const handleUrlClick = useEventCallback(
    async () => {
      if(siteNumber === process.env.REACT_APP_WELLMALL) {
        urlLink = process.env.REACT_APP_DEEP_LINK_DEFAULT_URL;
        urlLink += encodeURIComponent(process.env.REACT_APP_DEEP_LINK_GOOD_DETAIL) + kakaoProps.productNumber;
        // window.Welstorymall?.copyToClipBoard?.(urlLink);
        await onCopy(urlLink);
        // showToast('링크를 복사했습니다.');
        showAlertDialog('링크를 복사했습니다.');
      } else {
        window.Welstorymall?.copyToClipBoard?.(urlLink);
        await onCopy(urlLink);
        // showToast('링크를 복사했습니다.');
        showAlertDialog('링크를 복사했습니다.');
      }
    },
    { stop: true }
  );

  return (
    <FlexBox>
      <a href="#" title="카카오톡 앱으로 이동" onClick={e => e.preventDefault}>
        <StyledShareKakaoIcon id="kakao-link-btn" {...iconProps} onClick={handleKakaoClick} />
      </a>
      <Spacing left={gap} />
      <a href="#" title="URL 복사" onClick={e => e.preventDefault}>
        <StyledShareUrlIcon {...iconProps} onClick={handleUrlClick}/>
      </a>
    </FlexBox>
  );
};

ShareLink.propTypes = {
  urlLink: PropTypes.string,
  kakaoContents: PropTypes.object,
  iconProps: PropTypes.object,
  gap: PropTypes.string,
  kakaoProps: PropTypes.shape({
    type: PropTypes.oneOf(['commerce', 'scrap']),
    title: PropTypes.string,
    imageUrl: PropTypes.string,
    productName: PropTypes.string,
    regularPrice: PropTypes.number,
    discountRate: PropTypes.number,
    discountPrice: PropTypes.number,
    linkUrl: PropTypes.string,
    productNumber: PropTypes.string,
  }),
};

ShareLink.defaultProps = {
  iconProps: {
    width: '30px',
    height: '30px',
  },
  kakaoProps: {
    type: '',
    title: '',
    imageUrl: '',
    productName: '',
    regularPrice: 0,
    discountRate: 0,
    discountPrice: 0,
    linkUrl: '',
    productNumber: '',
  },
  gap: '10px',
};

export default ShareLink;
