import React, { useEffect, useState } from 'react';
import { useResetRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RemoveScroll } from 'react-remove-scroll';

import clsx from 'clsx';
import { FloatingOverlay } from '@floating-ui/react';

import useScrollDirection from '@ecp/common/src/hooks/useScrollDirection';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import ButtonUnstyled from '@ecp/common/src/components/unstyled/button/ButtonUnstyled';
import { ReactComponent as IconOpen } from '@ecp/common/src/assets/icon/icon__arrow--below.svg';

import { searchKeywordState } from '@mo-recoil/search/atom';
import TopUtility from '@mo-components/common/gnb/TopUtility';
import MenuGroups from '@mo-components/common/gnb/GnbMenuGroups';
import GnbSwiperMenuGroups from '@mo-components/common/gnb/GnbSwiperMenuGroups';

const GnbTopUtility = ({ linkSiteList, gnbMenuList, searchPhraseList, searchPraseListIndex, appLinkBanner }) => {
  const [openTotalMenu, setOpenTotalMenu] = useState(false);
  const resetSearchKeyword = useResetRecoilState(searchKeywordState);

  const scrollDirection = useScrollDirection();
  const scrollDirectionStyle = clsx(scrollDirection);

  const handleArrowClick = () => {
    setOpenTotalMenu(!openTotalMenu);
  };

  const handleTotalMenuClose = () => {
    setOpenTotalMenu(false);
  };

  useEffect(() => {
    resetSearchKeyword();
  }, [resetSearchKeyword]);
  
  return (
    <>
      <Container className={scrollDirectionStyle} appLinkBanner={appLinkBanner}>
        <TopUtility
          linkSiteList={linkSiteList}
          searchPhraseList={searchPhraseList}
          searchPraseListIndex={searchPraseListIndex}
        />
        <GnbMenuContainer className={scrollDirectionStyle}>
          <Spacing top={'14px'} />
          <MenuWrapper>
            {openTotalMenu ? (
              <TotalMenuLabel
                height="22px"
                size={moTheme.font.size.$15}
                weight={moTheme.font.weight.medium}
                color={theme.color.text.basic}
              >
                전체메뉴
              </TotalMenuLabel>
            ) : (
              <GnbSwiperMenuGroups gnbMenuList={gnbMenuList} />
            )}
          </MenuWrapper>
          <Arrow
            type={openTotalMenu ? 'open' : 'close'}
            onClick={handleArrowClick}
            ariaLabel={!openTotalMenu ? '전체 메뉴 펼치기' : '전체 메뉴 접기'}
          >
            <IconOpen width="12px" height="8px" />
          </Arrow>
          {openTotalMenu && (
            <TotalMenu data-ds-label2={'g_menu'}>
              <MenuGroups gnbMenuList={gnbMenuList} onTotalMenuClose={handleTotalMenuClose} />
            </TotalMenu>
          )}
        </GnbMenuContainer>
        {openTotalMenu && (
          <RemoveScroll>
            <Overlay />
          </RemoveScroll>
        )}
      </Container>
      <GnbScreen />
    </>
  );
};

const Container = styled(FlexBoxColumn)`
  width: 360px;
  z-index: 1100;
  background-color: ${theme.color.background.white};
  position: fixed;
  top: ${({appLinkBanner}) => appLinkBanner.height}px;

  transition: top 0.3s ease-in-out;

  &.down,
  &.bottom {
    position: fixed;
    top: ${({appLinkBanner}) => appLinkBanner.height - 46}px;
  }
`;

const GnbScreen = styled.div`
  width: 360px;
  height: 87px;
`;

const GnbMenuContainer = styled(FlexBoxColumn)`
  width: 100%;
  height: fit-content;
  background-color: ${theme.color.background.white};
  z-index: 2;
`;

const MenuWrapper = styled(FlexBox)`
  width: 100%;
  height: 27px;
  padding: 0 20px;
  box-sizing: border-box;
  align-items: flex-start;
  overflow: scroll;
  z-index: 2;
`;

const Arrow = styled(ButtonUnstyled)`
  position: absolute;
  top: 14px;
  right: 0;
  width: 54px;
  height: 26px;
  border: unset;
  background: linear-gradient(270deg, #ffffff 78.41%, rgba(255, 255, 255, 0) 135.23%);
  z-index: 2;

  & svg {
    transform: ${({ type }) => type === 'open' && 'rotate(180deg)'};
  }
`;

const Overlay = styled(FloatingOverlay)`
  background-color: #000000;
  opacity: 0.65;
  z-index: 1;
`;

export const TotalMenu = styled(FlexBox)`
  width: 100%;
  box-sizing: border-box;
  padding: 10px 21px 20px 20px;
  flex-wrap: wrap;
  z-index: 2;
`;

const TotalMenuLabel = styled(TextBox)`
  height: 27px;
  background-color: #ffffff;
  z-index: 2;
`;

export default GnbTopUtility;

GnbTopUtility.propTypes = {
  linkSiteList: PropTypes.array,
  gnbMenuList: PropTypes.array,
  searchPhraseList: PropTypes.array,
  searchPraseListIndex: PropTypes.number,
  appLinkBanner: PropTypes.object,
};
