import React, { lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';

import PaymentCompletePage from '@mo-pages/order/payment/PaymentCompletePage';
import PaymentFailPage from '@mo-pages/order/payment/PaymentFailPage';
import PaymentInProgressPage from '@mo-pages/order/payment/PaymentInProgressPage';
import PaymentRequestPage from '@mo-pages/order/payment/PaymentRequestPage';
import OrderPage from '@mo-pages/order/payment/OrderPage';
import PageNotFoundPage from '@mo-pages/error/PageNotFoundPage';
import ServerErrorPage from '@mo-pages/error/ServerErrorPage';
import MaintenancePage from '@mo-pages/error/MaintenancePage';
import UnExpectedErrorPage from '@mo-pages/error/UnExpectedErrorPage';
import HomePage from '@mo-pages/HomePage';
import MainCategoryPage from '@mo-pages/category/MainCategoryPage';
import CategoryPage from '@mo-pages/category/CategoryPage';
import SubCategoryPage from '@mo-pages/category/SubCategoryPage';
import BrandPage from '@mo-pages/brand/BrandPage';
import BrandDetailPage from '@mo-pages/brand/BrandDetailPage';
import ProtectedRoutes from './ProtectedRoutes';
import RootRoute, { AppUpdateLoader } from '@ecp/mo/src/routes/RootRoute';
import InterfaceRoutes from '@ecp/mo/src/routes/InterfaceRoutes';

const GoodRoutes = lazy(() => import('@ecp/mo/src/routes/GoodRoutes'));
const EventRoutes = lazy(() => import('@ecp/mo/src/routes/EventRoutes'));
const MyPageRoutes = lazy(() => import('@ecp/mo/src/routes/MyPageRoutes'));
const CustomerServiceRoutes = lazy(() => import('@ecp/mo/src/routes/CustomerServiceRoutes'));

const LoginPage = lazy(() => import('@mo-pages/member/login/LoginPage'));
const LoginChangeTempPasswordPage = lazy(() => import('@mo-pages/member/login/LoginChangeTempPasswordPage'));
const LogoutPage = lazy(() => import('@mo-pages/member/login/LogoutPage'));
const SignUpPage = lazy(() => import('@mo-pages/member/login/SignUpPage'));
const MyPageWithdrawCompletePage = lazy(() => import('@mo-pages/myPage/myInfo/MyPageWithdrawCompletePage'));

const MainPage = lazy(() => import('@mo-pages/main/MainPage'));

const SearchPage = lazy(() => import('@mo-pages/search/search/SearchPage'));
const SearchResultPage = lazy(() => import('@mo-pages/search/result/SearchResultPage'));

const ExhibitionPage = lazy(() => import('@mo-pages/exhibition/ExhibitionPage'));
const ExhibitionDetailPage = lazy(() => import('@mo-pages/exhibition/ExhibitionDetailPage'));
const EventDetailPage = lazy(() => import('@mo-pages/event/EventDetailPage'));

const FamilySalePage = lazy(() => import('@mo-pages/main/FamilySalePage'));
const BestPage = lazy(() => import('@mo-pages/display/best/BestPage'));
const FoodPage = lazy(() => import('@mo-pages/main/FoodPage'));
const NewGoodPage = lazy(() => import('@mo-pages/newGood/NewGoodPage'));
const SpecialPricePage = lazy(() => import('@mo-pages/main/SpecialPricePage'));
const SpecialExhibitionPage = lazy(() => import('@mo-pages/main/SpecialExhibitionPage'));
const GroupGiftPage = lazy(() => import('@mo-pages/main/GroupGiftPage'));
const ShoppingCartPage = lazy(() => import('@mo-pages/order/cart/ShoppingCartPage'));

const PresentHome = lazy(() => import('src/pages/order/present/PresentHome'));
const PresentMainPage = lazy(() => import('src/pages/order/present/PresentMainPage'));
const MemberEmailDisagreePage = lazy(() => import('@mo-pages/member/MemberEmailDisagree'));

const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="" element={<RootRoute />} loader={AppUpdateLoader} errorElement={<ServerErrorPage />}>
      <Route path="login" element={<LoginPage />} />
      <Route path="mobile/secure/login/:serviceName.action" element={<LoginPage />} /> --웰플, 삼성생명 등 sso 연동 관련
      Route
      <Route path="password" element={<LoginChangeTempPasswordPage />} />
      <Route path="logout" element={<LogoutPage />} />
      <Route path="sign-up" element={<SignUpPage />} />
      <Route path="withdraw-complete" element={<MyPageWithdrawCompletePage />} />
      <Route path="interface/*" element={<InterfaceRoutes />} />
      <Route path="present" element={<PresentHome />}>
        <Route path=":presentUrl" element={<PresentMainPage />} />
      </Route>
      <Route path="" element={<ProtectedRoutes />}>
        <Route path="" element={<HomePage />}>
          <Route path="" element={<MainPage />} />
          <Route path="good/*" element={<GoodRoutes />} />
          <Route path="event/*" element={<EventRoutes />} />
          <Route path="category">
            <Route path="" element={<MainCategoryPage />} />
            <Route path=":categoryNumber" element={<CategoryPage />} />
            <Route path="sub/:categoryNumber" element={<SubCategoryPage />} />
          </Route>
          <Route path="my-page/*" element={<MyPageRoutes />} />
          <Route path="customer-service/*" element={<CustomerServiceRoutes />} />
          <Route path="search">
            <Route path="" element={<SearchPage />} />
            <Route path="result" element={<SearchResultPage />} />
          </Route>
          <Route path="exhibition">
            <Route path="" element={<ExhibitionPage />} />
            <Route path=":pathExhibitionNo" element={<ExhibitionDetailPage />} />
          </Route>
          <Route path="brand">
            <Route path="" element={<BrandPage />} />
            <Route path=":brandNumber" element={<BrandDetailPage />} />
          </Route>
          <Route path="family-sale" element={<FamilySalePage />} />
          <Route path="best" element={<BestPage />} />
          <Route path="food" element={<FoodPage />} />
          <Route path="new-good" element={<NewGoodPage />} />
          <Route path="special-price" element={<SpecialPricePage />} />
          <Route path="special-exhibition" element={<SpecialExhibitionPage />} />
          <Route path="group-gift" element={<GroupGiftPage />} />
          <Route path="order">
            <Route path="" element={<OrderPage />} />
            <Route path="payment-request" element={<PaymentRequestPage />} />
            <Route path="in-progress" element={<PaymentInProgressPage />} />
            <Route path="payment-fail" element={<PaymentFailPage />} />
            <Route path="complete/:orderNumber" element={<PaymentCompletePage />} />
          </Route>
          <Route path="cart">
            <Route path="" element={<ShoppingCartPage />} />
          </Route>
        </Route>
        <Route path="eventForExhibition/:pathEventNo/:eventForExhbt" element={<EventDetailPage />} />
      </Route>
      <Route path="event-mall">
        <Route path="" element={<MainPage />} />
        <Route path=":pathExhibitionNo" element={<MainPage />} />
      </Route>
      <Route path="member-email-disagree" element={<MemberEmailDisagreePage />} />
      <Route path="error">
        <Route path="" element={<PageNotFoundPage />} />
        <Route path="403" element={<PageNotFoundPage />} />
        <Route path="404" element={<PageNotFoundPage />} />
        <Route path="500" element={<ServerErrorPage />} />
        <Route path="maintenance" element={<MaintenancePage />} />
        <Route path="unexpected" element={<UnExpectedErrorPage />} />
      </Route>
      <Route path="*" element={<PageNotFoundPage />} />
    </Route>
  )
);

export default routes;
