import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { overrideProperties } from '@ecp/common/src/style/utils';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxCenter } from '@ecp/common/src/layouts/flex/styled';
import RadioUnstyled, { radioClassNames } from '@ecp/common/src/components/unstyled/input/RadioUnstyled';
import { DefaultRadioButtonDefaultSize, InputComponent, LabelComponent } from '@ecp/common/src/components/radio/Radio';

export const Radio = (props) => {
  const { children } = props;
  return (
    <RadioUnstyled inputComponent={InputComponent} labelComponent={LabelComponent} {...props}>
      {children}
    </RadioUnstyled>
  );
};

Radio.displayName = 'Radio';

Radio.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hideControl: PropTypes.bool,
};

const StyledRadioButton = styled(FlexBoxCenter)`
  padding: 10px 14px;
  background: ${theme.color.background.basic};
  border: 1px solid ${theme.color.line.background};
  cursor: pointer;

  &.${radioClassNames.disabled} {
    cursor: default;
  }

  ${overrideProperties(sizePropTypes, DefaultRadioButtonDefaultSize)};

  &.${radioClassNames.checked} {
    color: ${theme.color.primary};
    background: ${theme.color.background.white};
    border: 1px solid ${theme.color.primary};
    font-weight: ${moTheme.font.weight.medium};
  }
`;

export const DefaultRadioButton = (props) => <StyledRadioButton {...props} />;

DefaultRadioButton.displayName = 'DefaultRadioButton';

DefaultRadioButton.propTypes = {
  ...sizePropTypes,
};

export const RoundRadioButton = styled(FlexBoxCenter)`
  background: ${theme.color.background.white};
  cursor: pointer;

  border: 1px solid ${theme.color.line.basic};
  border-radius: 15px;
  margin-right: 6px;
  margin-bottom: 6px;

  &.${radioClassNames.disabled} {
    cursor: default;
  }

  ${overrideProperties(sizePropTypes, {
    width: '58px',
    height: '30px',
  })};

  &.${radioClassNames.checked} {
    color: ${theme.color.text.white};
    background: ${theme.color.third};
    border: 1px solid ${theme.color.third};
    font-weight: ${moTheme.font.weight.medium};
  }
`;
