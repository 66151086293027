import React, { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useMemberGrade from '@ecp/common/src/hooks/member/useMemberGrade';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { TabPanel } from '@ecp/common/src/components/tab/TabPanel';
import TabGroup from '@ecp/common/src/components/tab/TabGroup';

import { userInfoState } from '@mo-recoil/common/user/atom';
import myMembershipApi from '@mo-apis/myPage/myMembershipApi';
import MembershipInfo from '@mo-components/benefit/MembershipInfo';
import AvailableBenefitTab from '@mo-components/benefit/AvailableBenefitTab';
import AllBenefitTab from '@mo-components/benefit/AllBenefitTab';

const EmployeeBenefit = ({ onClose }) => {
  const { userName } = useRecoilValue(userInfoState) || {};
  const [tabIndex, setTabIndex] = useState(location?.state?.defaultTabIndex ?? 0);
  const [infoChanged, setInfoChanged] = useState(false);
  const { memberNumber } = useRecoilValue(userInfoState);
  const { gradeInfo } = useMemberGrade({ myMembershipApi, memberNumber });

  const changeTabIndex = useCallback(
    (e) => {
      setTabIndex(Number(e.target.value));
    },
    [setTabIndex]
  );
  const handleDrawerClose = () => {
    onClose();
  };

  return (
    <FlexBoxColumn align-items={'flex-start'} width={'260px'} padding={'20px 0 0'}>
      <Spacing top={30} />
      <FlexBox>
        <TitleTexBox color={theme.color.primary}>{userName}</TitleTexBox>
        <TitleTexBox>님</TitleTexBox>
      </FlexBox>
      <TitleTexBox>임직원 혜택을 안내해 드릴게요.</TitleTexBox>
      <Spacing top={10} />
      <MembershipInfo
        gradeInfo={gradeInfo}
        infoChanged={infoChanged}
        setInfoChanged={setInfoChanged}
        onClose={onClose}
      />
      <Spacing top={30} />
      <TitleTexBox>한눈에 확인하는 임직원 혜택</TitleTexBox>
      <Spacing top={20} />
      <TabGroup
        height="60px"
        tabItems={[
          {
            tabName: `사용 가능 혜택`,
          },
          {
            tabName: `전체 혜택 정보`,
          },
        ]}
        onChange={changeTabIndex}
        value={tabIndex}
        width={'100%'}
      >
        <TabPanel>
          {
            <AvailableBenefitTab
              gradeInfo={gradeInfo}
              handleDrawerClose={handleDrawerClose}
              setInfoChanged={setInfoChanged}
            />
          }
        </TabPanel>
        <TabPanel>{<AllBenefitTab handleDrawerClose={handleDrawerClose} />}</TabPanel>
      </TabGroup>
    </FlexBoxColumn>
  );
};

EmployeeBenefit.propTypes = {
  onClose: PropTypes.func,
};

export default EmployeeBenefit;

const TitleTexBox = styled(TextBox)`
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
`;
