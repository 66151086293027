import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { FloatingTree } from '@floating-ui/react';

import '@ecp/common/src/assets/css/default.css';
import useViewportHeight from '@ecp/common/src/hooks/useViewportHeight';

import RecoilExternalStatePortal from '@mo-recoil/RecoilExternalStatePortal';
import ToastProvider from '@mo-components/common/provider/ToastProvider';
import LoadingSpinner from '@mo-components/common/LoadingSpinner';
import SiteInformationProvider from '@mo-components/common/provider/SiteInformationProvider';
import SuspenseSpinner from '@mo-components/common/SuspenseSpinner';
import InvisibleLoading from '@mo-components/common/InvisibleLoading';
import routes from '@ecp/mo/src/routes/Routes';
import './App.css';

import useNetfunnelComplete from '@ecp/common/src/hooks/useNetfunnelComplete';

function App() {
  useViewportHeight();

  useNetfunnelComplete();

  return (
    <RecoilRoot>
      <FloatingTree>
        <InvisibleLoading />
        <LoadingSpinner />
        <Suspense fallback={<SuspenseSpinner height={'100vh'} message={'페이지를 열고 있어요\n조금만 기다려주세요'} />}>
          <RouterProvider router={routes}></RouterProvider>
        </Suspense>
        <ToastProvider />
        <SiteInformationProvider />
      </FloatingTree>
      <RecoilExternalStatePortal />
    </RecoilRoot>
  );
}

export default App;
