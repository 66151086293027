import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import DropdownDevice from '@ecp/common/src/components/dropdown/mo/DropdownDevice';
import LabeledCheckBox from '@ecp/common/src/components/checkbox/mo/LabeledCheckBox';
import Input from '@ecp/common/src/components/input/Input';

function GiftiShowReceiverItem({
  isMulti,
  cellphoneNumber,
  itemInfo,
  idx,
  handleCheckSameAsOrderer,
  handleChangePhoneNumber,
  handleChangeQuantity,
}) {
  const [selectedItem, setSelectedItem] = useState({ label: String(0), key: String(0), value: String(0) });
  const [isSameAsOrderUser, setIsSameAsOrderUser] = useState(false);

  const itemList = useMemo(() => {
    return [...Array(itemInfo.availableQuantity + 1)].map((item, index) => ({
      label: String(index),
      value: String(index),
      key: String(index),
    }));
  }, [itemInfo.availableQuantity]);

  const handleClickCheckBox = useCallback(
    ({ target: { checked } }) => {
      handleCheckSameAsOrderer(idx, checked);
      setIsSameAsOrderUser(checked);
    },
    [handleCheckSameAsOrderer, idx]
  );

  const handleChangeCellphoneNumber = useCallback(
    ({ target: { value } }) => {
      const cellphoneNumber = value.replace(new RegExp('-', 'g'), '');
      handleChangePhoneNumber(idx, cellphoneNumber);
      setIsSameAsOrderUser(false);
    },
    [handleChangePhoneNumber, idx]
  );

  const handleChangeDropdown = useCallback(
    (data) => {
      setSelectedItem(data);
      handleChangeQuantity(idx, Number(data.value));
    },
    [handleChangeQuantity, idx]
  );
  return (
    <ReceiverContainer gap={14}>
      <HorizontalDivider color={'#EAEAEA'} />
      <TitleContainer>
        <TitleTextBox required>휴대전화번호</TitleTextBox>
        <LabeledCheckBox labelText={'보내는 분과 동일'} onChange={handleClickCheckBox} checked={isSameAsOrderUser} />
      </TitleContainer>
      <Input
        placeholder={'휴대전화번호를 입력해 주세요'}
        width={'100%'}
        value={cellphoneNumber || ''}
        onChange={handleChangeCellphoneNumber}
      />
      {isMulti && (
        <OrderQuantityContainer>
          <TextBox>수량 선택</TextBox>
          <Spacing left={6} />
          <DropdownDevice
            items={itemList}
            value={selectedItem}
            onChange={handleChangeDropdown}
            width={'80px'}
            disabled={itemList.length === 1}
          />
          <Spacing left={10} />
          <TextBox>/ {itemInfo.orderQuantity}개</TextBox>
        </OrderQuantityContainer>
      )}
    </ReceiverContainer>
  );
}

GiftiShowReceiverItem.propTypes = {
  isMulti: PropTypes.bool,
  cellphoneNumber: PropTypes.string,
  itemInfo: PropTypes.object,
  idx: PropTypes.number,
  handleCheckSameAsOrderer: PropTypes.func,
  handleChangePhoneNumber: PropTypes.func,
  handleChangeQuantity: PropTypes.func,
};

const ReceiverContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
`;

const TitleContainer = styled(FlexBoxSpaceBetween)`
  width: 100%;
`;

const TitleTextBox = styled(TextBox)`
  font-weight: 500;
  font-size: 14px;
`;

const OrderQuantityContainer = styled(FlexBox)`
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export default GiftiShowReceiverItem;
