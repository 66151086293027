import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isEmpty from 'lodash-es/isEmpty';

import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Accordion from '@ecp/common/src/components/accordion/mo/Accordion';

import DeliveryInformation from '@mo-components/order/payment/DeliveryInformation';
import OrderGoodItemListMulti from '@mo-components/order/payment/OrderGoodItemListMulti';

const MultiDeliveryItem = ({
  orderDelivery,
  isDirectForeignGood,
  deliveryPlaceIndex,
  handleChangeQuantity,
  handleChangeDeliveryPlaceInfo,
}) => {
  return (
    !isEmpty(orderDelivery) && (
      <Accordion initialOpen>
        <HeaderContainer>
          <Accordion.Header>
            <FlexBox>
              <HeaderTextBox>배송지 {deliveryPlaceIndex + 1}</HeaderTextBox>
              {!!orderDelivery.deliveryPlace?.deliveryLocationName && (
                <DeliveryNameTextBox> / {orderDelivery.deliveryPlace.deliveryLocationName}</DeliveryNameTextBox>
              )}
            </FlexBox>

            <FlexBox>
              <Accordion.Control buttonPadding="5px 0 0 0" />
            </FlexBox>
          </Accordion.Header>
        </HeaderContainer>
        <Accordion.Body undead>
          <BodyContainer>
            <Spacing top={'20px'} />
            <DeliveryInformation
              deliveryPlace={orderDelivery.deliveryPlace}
              isDirectForeignGood={isDirectForeignGood}
              deliveryPlaceIndex={deliveryPlaceIndex}
              handleChangeDeliveryPlaceInfo={handleChangeDeliveryPlaceInfo}
            />
            <Spacing top={'40px'} />
            <OrderGoodItemListMulti
              orderDelivery={orderDelivery}
              deliveryPlaceIndex={deliveryPlaceIndex}
              handleChangeQuantity={handleChangeQuantity}
            />
          </BodyContainer>
        </Accordion.Body>
      </Accordion>
    )
  );
};

export default MultiDeliveryItem;

MultiDeliveryItem.propTypes = {
  orderDelivery: PropTypes.object,
  isDirectForeignGood: PropTypes.bool,
  deliveryPlaceIndex: PropTypes.number,
  handleChangeQuantity: PropTypes.func,
  handleChangeDeliveryPlaceInfo: PropTypes.func,
};

MultiDeliveryItem.defaultProps = {
  orderDelivery: {},
};

const HeaderContainer = styled(FlexBox)`
  padding: 16px 14px;
  background-color: #eaeaea;
`;

const HeaderTextBox = styled(TextBox)`
  font-weight: 700;
  font-size: 14px;
`;

const DeliveryNameTextBox = styled(TextBox)`
  font-weight: 400;
  font-size: 14px;
`;

const BodyContainer = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;
