import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { addDays, format, parse } from 'date-fns';

import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import Calendar from '@ecp/common/src/components/calendar/mo/Calendar';
import DropdownDevice from '@ecp/common/src/components/dropdown/mo/DropdownDevice';
import { Radio } from '@ecp/common/src/components/radio/mo/Radio';
import RadioGroup from '@ecp/common/src/components/radio/RadioGroup';
import { HOUR_OPTIONS } from '@ecp/common/src/const/order/orderPaymentConst';

import OrderPageItemTitleContainer from '@mo-components/order/payment/OrderPageItemTitleContainer';
import ScheduleSendGuide from '@mo-components/order/payment/giftiShow/ScheduleSendGuide';

function GiftiShowSendTimeInformation({ giftiShowInformation, handleChangeGiftiShowInformation }) {
  const handleClickRadioItem = useCallback(
    ({ target: { value } }) => {
      if (value === 'immediate') {
        handleChangeGiftiShowInformation((prev) => ({ ...prev, reserveYn: 'N', reserveHours: '', reserveDate: '' }));
      } else {
        handleChangeGiftiShowInformation((prev) => ({ ...prev, reserveYn: 'Y' }));
      }
    },
    [handleChangeGiftiShowInformation]
  );

  const handleSelectDate = useCallback(
    (date) => {
      handleChangeGiftiShowInformation((prev) => ({ ...prev, reserveDate: format(date, 'yyyyMMdd') }));
    },
    [handleChangeGiftiShowInformation]
  );

  const handleSelectTime = useCallback(
    ({ value }) => {
      handleChangeGiftiShowInformation((prev) => ({ ...prev, reserveHours: value }));
    },
    [handleChangeGiftiShowInformation]
  );

  return (
    <Container>
      <OrderPageItemTitleContainer title={'발송시간'} gapToBody={'20px'} />
      <BodyContainer gap={20}>
        <RadioGroup defaultValue="immediate" gap={'30px'}>
          <Radio value="immediate" onChange={handleClickRadioItem}>
            즉시발송
          </Radio>
          {/*<Radio value="schedule" onChange={handleClickRadioItem}>*/}
          {/*  예약발송*/}
          {/*</Radio>*/}
        </RadioGroup>
        {giftiShowInformation?.reserveYn === 'Y' && (
          <FlexBoxColumn>
            <FlexBox gap={6} align-items={'center'}>
              <Calendar
                selectOnly
                inline={false}
                minDate={new Date()}
                maxDate={addDays(new Date(), 30)}
                onChange={handleSelectDate}
                inputStyle={{ width: '185px' }}
                value={
                  giftiShowInformation?.reserveDate
                    ? parse(giftiShowInformation?.reserveDate, 'yyyyMMdd', new Date())
                    : ''
                }
              />
              <DropdownDevice
                width={'96px'}
                placeholder="시간"
                items={HOUR_OPTIONS}
                value={HOUR_OPTIONS.find(({ key }) => key === giftiShowInformation.reserveHours)}
                placement="top"
                onChange={handleSelectTime}
              />
            </FlexBox>
            <ScheduleSendGuide />
          </FlexBoxColumn>
        )}
      </BodyContainer>
    </Container>
  );
}

GiftiShowSendTimeInformation.propTypes = {
  giftiShowInformation: PropTypes.object,
  handleChangeGiftiShowInformation: PropTypes.func,
};

const Container = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
`;

const BodyContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
`;

export default GiftiShowSendTimeInformation;
