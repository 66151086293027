import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import PropTypes from 'prop-types';

import { clearAccessTokens, openWindow } from '@ecp/common/src/utils/utils';
import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn, HorizontalDivider, VerticalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';
import { WELSTORY_URL } from '@ecp/common/src/const/footer';
import { ReactComponent as FaqIcon } from '@ecp/common/src/assets/icon/icon__faq.svg';
import { ReactComponent as InquiryIcon } from '@ecp/common/src/assets/icon/icon__inquiry.svg';

import { withMobileStatus } from '@mo-recoil/system/selector';
import { myDeliveryPlaceListState } from '@mo-recoil/order/atom';
import { useConfirmDialog } from '@mo-hooks/common/useModal';
import useSiteInfo from '@mo-hooks/common/useSiteInfo';
import authApi from '@mo-apis/common/authApi';
import PolicyPopupButton from '@mo-components/system/PolicyPopupButton';
import {
  ADDITIONAL_TYPE_CD_PRIVACY,
  ADDITIONAL_TYPE_CD_TERM,
  PRIVACY_POLICY_TITLE,
  TERM_OF_USE_TITLE,
} from '@mo-components/system/const';
import BulkOrderButton from '@mo-pages/home/footer/popup/BulkOrderButton';
import UnauthorizedEmailCollectionPopup from './popup/UnauthorizedEmailCollectionPopup';
import AppDownloadPopup from './popup/AppDownloadPopup';

const ContactInfoDesc = () => {
  const { systemInformation } = useSiteInfo();

  const { opTime, lunchTime, customerCenterGuide } = useMemo(
    () => ({
      opTime: `평일 ${systemInformation.customerCenterOpStartHh} - ${systemInformation.customerCenterOpEndHh}`,
      lunchTime: `점심시간 ${systemInformation.customerCenterLchStartHh} - ${systemInformation.customerCenterLchEndHh}`,
      customerCenterGuide: systemInformation.customerCenterGuide,
    }),
    [systemInformation]
  );

  return (
    <FlexBoxColumn align-items={'flex-start'} width={'100%'}>
      <FlexBox>
        <TextBox
          size={moTheme.font.size.$13}
          weight={moTheme.font.weight.demiLight}
          height={moTheme.font.lineHeight.demiLight}
        >
          고객지원
        </TextBox>
        <Spacing left={'4px'} />
        <TextBox
          size={moTheme.font.size.$13}
          weight={moTheme.font.weight.medium}
          height={moTheme.font.lineHeight.demiLight}
        >
          {systemInformation.customerCenterTel}
        </TextBox>
      </FlexBox>
      <Spacing top={'3px'} />
      <TextBox
        size={moTheme.font.size.$12}
        weight={moTheme.font.weight.demiLight}
        height={moTheme.font.lineHeight.demiLight}
      >
        {`${opTime} ${lunchTime}(${customerCenterGuide})`}
      </TextBox>
    </FlexBoxColumn>
  );
};

const ActionIcons = () => {
  const {
    isMultimall,
    systemInformation: { footerAppDownloadUseYn },
  } = useSiteInfo();
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'));

  useEffect(() => {
    setAccessToken(localStorage.getItem('access_token'));
  }, []);

  const isLogin = useMemo(() => {
    return accessToken?.length > 0;
  }, [accessToken]);

  const handleOnClickMoveToFaq = () => {
    navigate('/customer-service/faq');
  };

  const handleOnClickMoveTo1on1 = () => {
    navigate('/customer-service/qna');
  };

  return (
    <FlexBox width={'100%'}>
      {isLogin && (
        <>
          <TextButton
            type="custom"
            textProps={{ weight: moTheme.font.weight.demiLight }}
            onClick={handleOnClickMoveToFaq}
            customIcon={FaqIcon}
            isReverse
          >
            FAQ
          </TextButton>
          <Spacing left={14} />
          <VerticalDivider width={'1px'} height={'14px'} />
          <Spacing left={14} />
          <TextButton
            type="custom"
            textProps={{ weight: moTheme.font.weight.demiLight }}
            onClick={handleOnClickMoveTo1on1}
            customIcon={InquiryIcon}
            isReverse
          >
            1:1문의
          </TextButton>
          <Spacing left={14} />
          <VerticalDivider width={'1px'} height={'14px'} />
          <Spacing left={14} />
        </>
      )}
      {!isMultimall && footerAppDownloadUseYn === 'Y' && <AppDownloadPopup />}
    </FlexBox>
  );
};

const ActionTexts = ({ isMultiMall }) => {
  const [unauthorizedEmailPopup, setUnauthorizedEmailPopup] = useState(false);
  const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token'));
  const navigate = useNavigate();
  const { isMobileApp, agentType } = useRecoilValue(withMobileStatus);

  useEffect(() => {
    setAccessToken(localStorage.getItem('access_token'));
  }, []);

  const handleWelstorySiteClick = () => {
    openWindow(isMobileApp, WELSTORY_URL, '_blank', agentType);
  };

  const isLogin = useMemo(() => {
    return accessToken?.length > 0;
  }, [accessToken]);

  const { showConfirmDialog } = useConfirmDialog();
  const resetDeliveryPlaceList = useResetRecoilState(myDeliveryPlaceListState);
  const handleLogout = async () => {
    showConfirmDialog('로그아웃 하시겠습니까?', async () => {
      await authApi.logout();
      clearAccessTokens();
      resetDeliveryPlaceList();
      navigate('/logout');
    });
  };

  const handleClickCloseUnauthorizedEmailPopup = useCallback(
    () => setUnauthorizedEmailPopup(false),
    [setUnauthorizedEmailPopup]
  );

  return (
    <FlexBoxColumn background-color={theme.color.background.white} padding={'14px 20px'} width={'100%'}>
      <FlexBox width={'100%'} flex-wrap={'wrap'}>
        {!isMultiMall && (
          <Spacing bottom={6} right={18} width={'max-content'}>
            <BulkOrderButton />
          </Spacing>
        )}
        <Spacing bottom={6} right={18} width={'max-content'}>
          <PolicyPopupButton
            popupType={'history'}
            popupTitle={TERM_OF_USE_TITLE}
            additionalTypeCd={ADDITIONAL_TYPE_CD_TERM}
            buttonText={'이용약관'}
            buttonTextProps={{ weight: moTheme.font.weight.demiLight }}
            textColor={{ color: 'black' }}
          />
        </Spacing>
        <Spacing bottom={6} right={18} width={'max-content'}>
          <PolicyPopupButton
            popupType={'history'}
            popupTitle={PRIVACY_POLICY_TITLE}
            additionalTypeCd={ADDITIONAL_TYPE_CD_PRIVACY}
            buttonText={'개인정보처리방침'}
            buttonTextProps={{ weight: moTheme.font.weight.bold }}
            textColor={{ color: theme.color.primary }}
          />
        </Spacing>
        <Spacing bottom={6} right={18} width={'max-content'}>
          <TextButton
            textProps={{ weight: moTheme.font.weight.demiLight, height: moTheme.font.lineHeight.demiLight }}
            onClick={() => setUnauthorizedEmailPopup(true)}
          >
            이메일 무단수집거부
          </TextButton>
        </Spacing>
        <Spacing bottom={6} right={18} width={'max-content'}>
          <TextButton
            textProps={{ weight: moTheme.font.weight.demiLight, height: moTheme.font.lineHeight.demiLight }}
            onClick={handleWelstorySiteClick}
          >
            기업사이트
          </TextButton>
        </Spacing>
        {isLogin && (
          <Spacing bottom={6} right={18} width={'max-content'}>
            <TextButton
              textProps={{
                size: moTheme.font.size.$13,
                weight: moTheme.font.weight.demiLight,
                color: theme.color.text.basic,
              }}
              onClick={handleLogout}
            >
              로그아웃
            </TextButton>
          </Spacing>
        )}
      </FlexBox>
      <UnauthorizedEmailCollectionPopup
        open={unauthorizedEmailPopup}
        onClose={handleClickCloseUnauthorizedEmailPopup}
      />
    </FlexBoxColumn>
  );
};

ActionTexts.propTypes = {
  isMultiMall: PropTypes.bool,
};

const ContactInfo = () => {
  const { isMultimall } = useSiteInfo();
  return (
    <>
      <FlexBoxColumn background-color={theme.color.background.basic} padding={'14px 20px'} width={'100%'}>
        <ContactInfoDesc />
        {!isMultimall && (
          <>
            <Spacing top={10} />
            <ActionIcons />
          </>
        )}
      </FlexBoxColumn>
      <ActionTexts isMultiMall={isMultimall} />
      <HorizontalDivider />
    </>
  );
};

export default ContactInfo;
