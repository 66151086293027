import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import Swiper from '@ecp/common/src/components/swiper/mo/Swiper';

import Exhibition from '@mo-components/exhibition/Exhibition';

const SwiperExhibition = (props) => (
  <SwiperContainer>
    <Swiper
      carouselProps={{
        slidesPerView: 2,
        spaceBetween: 8,
        loop: props.itemList.length > 2,
      }}
      control={props.itemList.length <= 2 ? undefined : 'outerLabel'}
      controlProps={{
        backgroundColor: 'white',
        border: `1px solid ${theme.color.line.background}`,
      }}
      {...props}
    />
  </SwiperContainer>
);

SwiperExhibition.displayName = 'SwiperExhibition';

SwiperExhibition.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  arrowProps: PropTypes.object,
  itemComponent: PropTypes.elementType,
  onClick: PropTypes.func,
  carouselProps: PropTypes.object,
};

export const SwiperExhibitionDefaultProps = {
  width: '320px',
  height: '240px',
  itemComponent: Exhibition,
  itemProps: {
    isSwiperSize: true,
    width: '156px',
    height: '156px',
    radius: '6px',
  },
};

SwiperExhibition.defaultProps = {
  itemList: [],
  ...SwiperExhibitionDefaultProps,
};

export default SwiperExhibition;

const SwiperContainer = styled.div`
  display: flex;
  justify-content: center;
`;
