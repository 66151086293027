import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { PageButton } from '@ecp/common/src/components/button/Button';
import { ReactComponent as IconOpen } from '@ecp/common/src/assets/icon/icon__arrow--below.svg';

const SeeMoreButton = ({ page, totalPage, padding, handleClickSeeMoreSearch, ariaLabel }) => {
  console.log(ariaLabel);
  return (
    <FlexBoxColumn width={'100%'} padding={padding}>
      <MoreButton show={page < totalPage} onClick={handleClickSeeMoreSearch}>
        {/* <FlexBox>
          더보기({page}/{totalPage})
        </FlexBox>
        <Spacing left="6px" />
        {page < totalPage ? (
          <>
            <OpenButtonWrapper>
              <OpenButton width="5px" height="8px" />
            </OpenButtonWrapper>
          </>
        ) : (
          <></>
        )} */}
        <PageButton
          width={'100%'}
          type={'Default'}
          size={moTheme.font.size.$14}
          weight={moTheme.font.weight.medium}
          ariaLabel={ariaLabel}
          style={{ fontWeight: '300' }}
        >
          {`더보기(${page}/${totalPage})`}
          <Spacing left={6} />
          {page < totalPage ? (
            <>
              <OpenButton width="5px" height="8px" />
            </>
          ) : (
            <></>
          )}
        </PageButton>
      </MoreButton>
    </FlexBoxColumn>
  );
};

SeeMoreButton.propTypes = {
  padding: PropTypes.string,
  totalPage: PropTypes.number,
  page: PropTypes.number,
  handleClickSeeMoreSearch: PropTypes.func,
  ariaLabel: PropTypes.string,
};

SeeMoreButton.defaultProps = {
  padding: '30px 0 0 0',
};

export default SeeMoreButton;

const MoreButton = styled(FlexBox)`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  width: 100%;
  height: 46px;
  //border: 1px solid #bdbdbd;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  align-items: center;
`;

//const OpenButtonWrapper = styled.div``;

const OpenButton = styled(IconOpen)`
  width: ${(width) => (width ? width : '10px')};
  height: ${(height) => (height ? height : '7px')};
  color: ${(color) => (color ? color : '#141415')}
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
