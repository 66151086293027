import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useRecentlyViewedGoodList from '@ecp/common/src/hooks/user/useRecentlyViewedGoodList';
import { flexCenter } from '@ecp/common/src/style/theme/defaultStyled';
import { positionPropTypes, sizePropTypes } from '@ecp/common/src/style/propTypes';
import { overrideProperties } from '@ecp/common/src/style/utils';
import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { ImageBox } from '@ecp/common/src/layouts/flex/styled';
import Badge from '@ecp/common/src/components/badge/mo/Badge';
import Drawer from '@ecp/common/src/components/modal/mo/Drawer';

import { recentlyViewedGoodListState, userInfoState } from '@mo-recoil/common/user/atom';
import { withAdultCertification } from '@mo-recoil/common/user/selector';
import RecentGood from '@mo-components/common/navigation/RecentGood';
import { CircleWrapper } from '@mo-components/styled';
import { ReactComponent as RecentIcon } from '@mo-assets/icon/icon__recent.svg';

const RecentItemBadge = styled.span`
  ${flexCenter};
  position: absolute;
  border-radius: 50%;
  background-color: ${({ badgeContent }) => (badgeContent === 0 ? theme.color.primary : theme.color.primary)};
  color: ${theme.color.text.white};
  font-size: ${moTheme.font.size.$10};
  font-weight: ${moTheme.font.weight.medium};

  ${overrideProperties(positionPropTypes, {})};
  ${overrideProperties(sizePropTypes, {})};
`;

const RecentButton = () => {
  const userInfo = useRecoilValue(userInfoState);
  const adultCertification = useRecoilValue(withAdultCertification);
  const [recentlyViewedList, setRecentlyViewedList] = useRecoilState(recentlyViewedGoodListState);
  const { recentlyViewedGoodList, floatingThumbnail } = useRecentlyViewedGoodList(
    userInfo,
    recentlyViewedList,
    adultCertification,
    setRecentlyViewedList
  );

  return (
    <Drawer
      placement="right"
      value={<RecentGood recentlyViewedGoodList={recentlyViewedGoodList} />}
      closePosition={'left'}
    >
      <Badge
        showZero
        max={9}
        position={{ top: 0, right: '-6px' }}
        size={{ width: '20px', height: '20px' }}
        badgeComponent={RecentItemBadge}
        badgeContent={recentlyViewedGoodList?.length}
        rootComponent={BadgeRootButton}
        rootProps={{ type: 'button', 'aria-label': `최근 본 상품 ${recentlyViewedGoodList?.length}건` }}
      >
        <CircleWrapper border={`1px solid ${theme.color.line.background}`} data-ds-label2={'float_rece_view_pd'}>
          {recentlyViewedGoodList?.[0] ? (
            <ImageBox imageSrc={floatingThumbnail} width={'100%'} height={'100%'} />
          ) : (
            <RecentIcon />
          )}
        </CircleWrapper>
      </Badge>
    </Drawer>
  );
};

RecentButton.propTypes = {
  itemCount: PropTypes.number,
};

export default RecentButton;

const BadgeRootButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;
