import { useCallback } from 'react';

const useCouponSelection = ({
  couponList,
  setCouponList,
  setGoodCouponList,
  setCartCouponList,
  goodPriceList,
  setGoodPriceList,
  setDiscountAmount,
  setDeliveryCostCoupon,
  paymentMethod,
  setSimpleCouponApply,
  setPointUsage,
  handleReloadDeliveryList,
  enableCouponUsage,
}) => {
  const getPayAmountWithCartCoupon = useCallback((cartCoupon, goodPrices) => {
    return goodPrices
      ?.filter(
        (goodPrice) =>
          Array.isArray(cartCoupon?.applyItemList) &&
          cartCoupon.applyItemList.find(
            (applyItem) =>
              goodPrice.goodNumber === applyItem.goodNumber && goodPrice.itemNumber === applyItem.itemNumber
          )
      )
      .reduce((acc, item) => acc + item.salePrice * item.orderQuantity - (item.goodDiscountAmount || 0), 0);
  }, []);

  const setCartAndGoodCouponList = useCallback(
    (goodPrices, newCouponList) => {
      setGoodCouponList(
        (prev) =>
          prev.map((good) => {
            const filteredList = good.couponList.map((goodCoupon) => {
              const isSelectOtherGood = !!newCouponList.find(
                (coupon) =>
                  coupon.isSelect &&
                  coupon.resourceManagementNo === goodCoupon.resourceManagementNo &&
                  (coupon.applyItemList[0].goodNumber !== good.goodNumber ||
                    coupon.applyItemList[0].itemNumber !== good.itemNumber)
              );
              const isSelect = !!newCouponList.find(
                (coupon) =>
                  coupon.isSelect &&
                  coupon.resourceManagementNo === goodCoupon.resourceManagementNo &&
                  (coupon.applyItemList[0].goodNumber === good.goodNumber ||
                    coupon.applyItemList[0].itemNumber === good.itemNumber)
              );
              return {
                ...goodCoupon,
                disabled: isSelectOtherGood,
                isSelect,
              };
            });
            return {
              ...good,
              couponList: filteredList || [],
            };
          }) || []
      );
      setCartCouponList((prev) =>
        prev.map((coupon) => {
          const payAmount = getPayAmountWithCartCoupon(coupon, goodPrices);
          return {
            ...coupon,
            disabled: payAmount < coupon.applyLimitAmount,
          };
        })
      );
    },
    [setGoodCouponList, setCartCouponList, getPayAmountWithCartCoupon]
  );

  const getCartCouponDiscount = useCallback(
    (cartCoupon, paymentMethod, goodPrices) => {
      if (!paymentMethod) return { discountAmount: 0, applyItemList: [] };

      const { payMeanCode, cardCompanyCode } = paymentMethod;

      const payAmount = getPayAmountWithCartCoupon(cartCoupon, goodPrices);
      if (cartCoupon) {
        /* 카드사 할인 가능 여부 판단 */
        const { applyCreditCardList } = cartCoupon;
        if (Array.isArray(applyCreditCardList) && applyCreditCardList.length > 0 && payMeanCode === '11') {
          if (!applyCreditCardList.some((cardCode) => cardCode === cardCompanyCode)) {
            return { discountAmount: 0, applyItemList: [] };
          }
        } else if (Array.isArray(applyCreditCardList) && applyCreditCardList.length > 0 && payMeanCode !== '11') {
          return { discountAmount: 0, applyItemList: [] };
        }

        /* 적용제한금액 확인 */
        if (payAmount !== 0 && payAmount >= cartCoupon.applyLimitAmount) {
          let applyDiscountAmount = 0;
          if (cartCoupon.benefitTypeCode === '10') {
            const discountAmount = Math.floor((payAmount * Number(cartCoupon.benefitValue)) / 100);
            applyDiscountAmount =
              discountAmount > cartCoupon.maxDiscountAmount ? cartCoupon.maxDiscountAmount : discountAmount;
          } else {
            applyDiscountAmount = Number(cartCoupon.benefitValue);
          }

          /* 장바구니 쿠폰 할인 금액은 상품 금액보다 클 수 없음 */
          if (applyDiscountAmount > payAmount) {
            applyDiscountAmount = payAmount;
          }

          const applyData = {
            remainAmount: applyDiscountAmount,
            remainQuantity: goodPrices
              .filter(
                (goodPrice) =>
                  Array.isArray(cartCoupon?.applyItemList) &&
                  cartCoupon.applyItemList.find(
                    (applyItem) =>
                      goodPrice.goodNumber === applyItem.goodNumber && goodPrice.itemNumber === applyItem.itemNumber
                  )
              )
              .reduce((acc, item) => acc + item.orderQuantity, 0),
          };

          const applyItemList = [];
          goodPrices
            .filter(
              (goodPrice) =>
                Array.isArray(cartCoupon?.applyItemList) &&
                cartCoupon.applyItemList.find(
                  (applyItem) =>
                    goodPrice.goodNumber === applyItem.goodNumber && goodPrice.itemNumber === applyItem.itemNumber
                )
            )
            .forEach((goodPrice) => {
              const goodPayAmount = goodPrice.salePrice * goodPrice.orderQuantity - (goodPrice.discountAmount || 0);
              let discountAmount = 0;

              if (applyData.remainQuantity === goodPrice.orderQuantity) {
                discountAmount = applyData.remainAmount;
              } else {
                discountAmount = Math.floor(applyDiscountAmount * (goodPayAmount / payAmount));
              }

              /* 누적 금액 */
              applyData.remainQuantity -= goodPrice.orderQuantity;
              applyData.remainAmount -= discountAmount;

              applyItemList.push({
                goodNumber: goodPrice.goodNumber,
                itemNumber: goodPrice.itemNumber,
                discountAmount: discountAmount,
              });
            });
          return { discountAmount: applyDiscountAmount, applyItemList: applyItemList };
        }
      }
      return { discountAmount: 0, applyItemList: [] };
    },
    [getPayAmountWithCartCoupon]
  );

  const getDiscountAmount = useCallback(
    (coupons, goodPrices, cartCouponDiscount) => {
      if (!enableCouponUsage) {
        return {
          total: 0,
          basicDiscount: 0,
          goodCouponDiscount: 0,
          cartCouponDiscount: 0,
        };
      }

      const basicDiscount = 0;

      const goodCouponDiscount = coupons
        .filter((coupon) => '021' === coupon.promotionTypeCode && coupon.isSelect)
        .reduce((acc, item) => {
          return acc + item.applyAmount;
        }, 0);

      return {
        total: (basicDiscount || 0) + (goodCouponDiscount || 0) + (cartCouponDiscount.discountAmount || 0),
        basicDiscount: basicDiscount,
        goodCouponDiscount: goodCouponDiscount,
        cartCouponDiscount: cartCouponDiscount.discountAmount,
      };
    },
    [enableCouponUsage]
  );

  const canApplyCartCoupon = useCallback(
    (cartCoupon, goodPrices) => {
      if (!cartCoupon) {
        return false;
      }

      const payAmount = getPayAmountWithCartCoupon(cartCoupon, goodPrices);

      return payAmount >= cartCoupon.applyLimitAmount;
    },
    [getPayAmountWithCartCoupon]
  );

  const calcGoodPrice = useCallback(
    (coupons, selectedPaymentMethod = paymentMethod, isChangePaymentMethod = false) => {
      /* 상품 쿠폰 반영된 가격 정보 세팅 */
      const applyGoodCouponGoodPrices = goodPriceList.map((good) => {
        const selectedGoodCoupon = coupons?.find(
          ({ isSelect, applyItemList, promotionTypeCode }) =>
            isSelect &&
            '021' === promotionTypeCode &&
            applyItemList.length > 0 &&
            applyItemList[0].goodNumber === good.goodNumber &&
            applyItemList[0].itemNumber === good.itemNumber
        );
        /* 상품 쿠폰인지 장바구니 쿠폰인지 구분해 다르게 처리 필요 */
        if (selectedGoodCoupon) {
          return {
            ...good,
            goodDiscountAmount: selectedGoodCoupon.applyAmount,
            goodApplyQuantityCode: `${selectedGoodCoupon.applyQuantityCode}`,
            cartDiscountAmount: 0,
            resourceManagementNo: selectedGoodCoupon.resourceManagementNo,
          };
        }
        return {
          ...good,
          goodDiscountAmount: 0,
          goodApplyQuantityCode: '0',
          cartDiscountAmount: 0,
          resourceManagementNo: null,
        };
      });

      /* 장바구니 쿠폰 정보 세팅 */
      const cartCoupon = coupons.find((coupon) => '022' === coupon.promotionTypeCode && coupon.isSelect);
      const isApplyCartCoupon = canApplyCartCoupon(cartCoupon, applyGoodCouponGoodPrices);
      if (!isApplyCartCoupon) {
        setCouponList(
          coupons.map((coupon) => {
            return {
              ...coupon,
              isSelect: '022' === coupon.promotionTypeCode ? false : coupon.isSelect,
            };
          })
        );
      } else {
        setCouponList(coupons);
      }

      const cartCouponDiscount = getCartCouponDiscount(cartCoupon, selectedPaymentMethod, applyGoodCouponGoodPrices);
      /* 할인 금액 세팅 */
      if (setDiscountAmount) {
        setDiscountAmount(getDiscountAmount(coupons, applyGoodCouponGoodPrices, cartCouponDiscount));
      }

      const goodPrices = applyGoodCouponGoodPrices.map((goodPrice) => {
        const cartDiscount = cartCouponDiscount?.applyItemList.find(
          ({ goodNumber, itemNumber }) => goodNumber === goodPrice.goodNumber && itemNumber === goodPrice.itemNumber
        );

        return {
          ...goodPrice,
          cartDiscountAmount: cartDiscount?.discountAmount || 0,
        };
      });

      setGoodPriceList(goodPrices);
      setCartAndGoodCouponList(goodPrices, coupons);

      const beforePayAmount = goodPriceList.reduce(
        (acc, item) => acc + item.salePrice * item.orderQuantity - item.goodDiscountAmount - item.cartDiscountAmount,
        0
      );

      const newPayAmount = goodPrices.reduce(
        (acc, item) => acc + item.salePrice * item.orderQuantity - item.goodDiscountAmount - item.cartDiscountAmount,
        0
      );

      if (isChangePaymentMethod) {
        if (beforePayAmount !== newPayAmount) {
          /* 상품금액변경으로 인한 배송비 재계산 */
          handleReloadDeliveryList?.(goodPrices);
          /* 쿠폰 변경으로 인한 포인트 초기화 */
          setPointUsage?.((prev) => ({
            ...prev,
            savePoint: { ...prev.savePoint, use: 0 },
            welPoint: { ...prev.welPoint, use: 0 },
            welfarePoint: { ...prev.welfarePoint, use: 0 },
          }));
        }
      } else {
        /* 상품금액변경으로 인한 배송비 재계산 */
        handleReloadDeliveryList?.(goodPrices);
        if (beforePayAmount !== newPayAmount) {
          /* 쿠폰 변경으로 인한 포인트 초기화 */
          setPointUsage?.((prev) => ({
            ...prev,
            savePoint: { ...prev.savePoint, use: 0 },
            welPoint: { ...prev.welPoint, use: 0 },
            welfarePoint: { ...prev.welfarePoint, use: 0 },
          }));
        }
      }
    },
    [
      goodPriceList,
      canApplyCartCoupon,
      setPointUsage,
      getCartCouponDiscount,
      paymentMethod,
      setDiscountAmount,
      setGoodPriceList,
      setCartAndGoodCouponList,
      handleReloadDeliveryList,
      setCouponList,
      getDiscountAmount,
    ]
  );

  const handleSelectCoupon = useCallback(
    (value, isCartCoupon) => {
      setSimpleCouponApply(false);
      const newCouponList = couponList.map((coupon) => {
        /* 상품 쿠폰인지 장바구니 쿠폰인지 구분해 다르게 처리 필요 */
        if (isCartCoupon) {
          if ('022' === coupon.promotionTypeCode && coupon.resourceManagementNo === value.key) {
            return {
              ...coupon,
              isSelect: true,
            };
          } else if ('022' === coupon.promotionTypeCode) {
            return {
              ...coupon,
              isSelect: false,
            };
          } else {
            return coupon;
          }
        } else {
          if ('021' === coupon.promotionTypeCode && coupon.resourceManagementNo === value.key) {
            return {
              ...coupon,
              applyItemList: [{ goodNumber: value.goodNumber, itemNumber: value.itemNumber }],
              couponItem: { ...value },
              isSelect: true,
              applyAmount: value.applyAmount || 0,
            };
          } else if (
            '021' === coupon.promotionTypeCode &&
            Array.isArray(coupon.applyItemList) &&
            coupon.applyItemList.length > 0 &&
            coupon.applyItemList[0].goodNumber === value.goodNumber &&
            coupon.applyItemList[0].itemNumber === value.itemNumber
          ) {
            return {
              ...coupon,
              applyItemList: [],
              couponItem: {},
              isSelect: false,
              applyAmount: 0,
            };
          } else {
            return coupon;
          }
        }
      });
      calcGoodPrice(newCouponList);
    },
    [setSimpleCouponApply, couponList, calcGoodPrice]
  );

  /* MO 쿠폰 선택 팝업 확인 */
  const handleConfirmCoupons = useCallback(
    (newCouponList, applyDeliveryCostCoupon, simpleCouponApply) => {
      const coupons = couponList.map((coupon) => {
        const newCoupon = newCouponList.find(
          ({ resourceManagementNo }) => resourceManagementNo === coupon.resourceManagementNo
        );
        return { ...coupon, ...newCoupon };
      });

      setSimpleCouponApply(simpleCouponApply);
      setDeliveryCostCoupon((prev) => ({ ...prev, ...applyDeliveryCostCoupon }));
      calcGoodPrice(coupons);
    },
    [couponList, setSimpleCouponApply, setDeliveryCostCoupon, calcGoodPrice]
  );

  const handleSimpleApply = useCallback(
    (applySimpleCoupon) => {
      setSimpleCouponApply(applySimpleCoupon);
      if (applySimpleCoupon) {
        const newCouponList = couponList.map((coupon) => {
          if (coupon.promotionTypeCode === '021') {
            if (coupon.defaultCoupon) {
              return {
                ...coupon,
                applyItemList: [{ goodNumber: coupon.defaultGoodNumber, itemNumber: coupon.defaultItemNumber }],
                // couponItem: { ...value },
                isSelect: true,
                applyAmount: coupon.defaultApplyAmount || 0,
              };
            }
            return {
              ...coupon,
              applyItemList: [],
              applyAmount: 0,
              // couponItem: { ...value },
              isSelect: false,
            };
          }
          if (coupon.promotionTypeCode === '022') {
            if (coupon.defaultCoupon) {
              return {
                ...coupon,
                isSelect: true,
              };
            }
            return {
              ...coupon,
              isSelect: false,
            };
          }
          return coupon;
        });

        setDeliveryCostCoupon((prev) => ({
          ...prev,
          applyDeliveryCostCoupon: true,
        }));
        calcGoodPrice(newCouponList);
      } else {
        const newCouponList = couponList.map((coupon) => {
          if (coupon.promotionTypeCode === '021') {
            return {
              ...coupon,
              applyItemList: [],
              applyAmount: 0,
              // couponItem: { ...value },
              isSelect: false,
            };
          }
          if (coupon.promotionTypeCode === '022') {
            return {
              ...coupon,
              isSelect: false,
            };
          }
          return coupon;
        });

        setDeliveryCostCoupon((prev) => ({
          ...prev,
          applyDeliveryCostCoupon: false,
        }));
        calcGoodPrice(newCouponList);
      }
    },
    [setSimpleCouponApply, couponList, setDeliveryCostCoupon, calcGoodPrice]
  );

  const handleInitCoupon = useCallback(() => {
    calcGoodPrice(couponList);
  }, [couponList, calcGoodPrice]);

  return {
    handleSelectCoupon,
    handleConfirmCoupons,
    handleSimpleApply,
    handleInitCoupon,
    calcGoodPrice,
  };
};
export default useCouponSelection;
