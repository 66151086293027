import { useEffect, useRef } from 'react';

const useScrollToPreviousGood = ({
  pathVariable,
  history,
  mobileInitialPageParam,
  goodList,
  storedGoodList,
  storedUrl,
  storedTotalCount,
  setGoodList,
  setTotalCount,
  setPageParam,
  setStoredGoodList,
}) => {
  const storedGoodCardRef = useRef(null);

  useEffect(() => {
    setStoredGoodList(goodList);
  }, [goodList, setStoredGoodList]);

  useEffect(() => {
    const url = `${window.location.pathname}${window.location.search}`;
    if (url !== storedUrl) {
      setPageParam(mobileInitialPageParam);
      return;
    }

    setGoodList(storedGoodList);
    setTotalCount(storedTotalCount);
  }, [pathVariable, setPageParam]);

  useEffect(() => {
    const previousUrl = history.prev;
    if (!previousUrl.includes('/good/')) {
      return;
    }

    if (!storedGoodCardRef?.current) {
      return;
    }

    const previousGoodNumber = previousUrl.split('/')[2];
    const targetGoodCard = document.getElementById(`good-card-${previousGoodNumber}`);
    if (targetGoodCard) {
      targetGoodCard.scrollIntoView({ block: 'center' });
    }
  }, [pathVariable, history]);

  return { storedGoodCardRef };
};

export default useScrollToPreviousGood;
