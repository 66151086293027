import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getPercentSize } from '@ecp/common/src/utils/utils';
import { positionPropTypes, sizePropTypes } from '@ecp/common/src/style/propTypes';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { FlexBox, FlexBoxATag, ImageBox, SpanTitle } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Badge from '@ecp/common/src/components/badge/mo/Badge';
import StyledImg, { LazyImg } from '@ecp/common/src/components/image/StyledImg';
import {
  StyledComponent,
  StyledFlexBoxColumn,
  StyledRoot,
  swiperItemPropTypes
} from '@ecp/common/src/components/swiper/component/SwiperItem';
import { BANNER_CONTENTS_DIVISION_CODE } from '@ecp/common/src/const/display/bannerConst';
import { ReactComponent as IconShare } from '@ecp/common/src/assets/icon/icon__share.svg';
import adultPath from '@ecp/common/src/assets/icon/icon__adult-certification.svg';

const StyledTitle = styled(StyledComponent.StyledTitle)`
  top: 37px;
  left: 20px;
  font-size: ${moTheme.font.size.$16};
  max-width: 300px;
`;

const StyledSubTitle = styled(StyledComponent.StyledSubTitle)`
  bottom: 36px;
  left: 20px;
  font-size: ${moTheme.font.size.$12};
  max-width: 135px;
`;

export const StyledRootATag = styled(FlexBoxATag)`
  position: relative;
  cursor: ${({ cursor }) => cursor || 'pointer'};
  user-select: none;
  ${overrideProperties(sizePropTypes, {})};
`;

const getSwiperItemProps = ({ width, height, ...itemProps }) => ({
  ...itemProps,
  width: getPercentSize(width),
  height: getPercentSize(height),
});

const SwiperItem = ({ item, itemProps, ...props }) => (
  <StyledComponent.StyledRoot {...getSwiperItemProps(itemProps)} {...props}>
    <StyledImg src={item.src} {...getSwiperItemProps(itemProps)} alt={item.title} />
    {(item.title || item.subTitle) && (
      <StyledComponent.StyledFlexBoxColumn>
        {item.title && <StyledTitle>{item.title}</StyledTitle>}
        {item.subTitle && <StyledSubTitle>{item.subTitle}</StyledSubTitle>}
      </StyledComponent.StyledFlexBoxColumn>
    )}
  </StyledComponent.StyledRoot>
);

SwiperItem.displayName = 'SwiperItem';

SwiperItem.propTypes = swiperItemPropTypes;

export const SwiperEventItem = ({ item, itemProps, ...props }) => (
  <StyledComponent.StyledRoot {...getSwiperItemProps(itemProps)} {...props} data-ds-contents={item.eventNumber}>
    <StyledImg src={item.src} {...getSwiperItemProps(itemProps)} alt={item.title} />
  </StyledComponent.StyledRoot>
);

SwiperEventItem.displayName = 'SwiperEventItem';

SwiperEventItem.propTypes = swiperItemPropTypes;

const BannerText1 = styled(TextBox)`
  position: absolute;
  top: 37px;
  left: 20px;
  font-size: ${moTheme.font.size.$22};
  font-weight: ${theme.font.weight.bold};
  white-space: pre-wrap;
  max-width: 300px;
`;

const BannerText2 = styled(BannerText1)`
  top: 65px;
  font-size: ${moTheme.font.size.$22};
  font-weight: ${theme.font.weight.bold};
`;

const BannerText3 = styled(BannerText1)`
  top: 100px;
  font-size: ${moTheme.font.size.$13};
  max-width: 135px;
`;

export const SwiperMainItem = ({ item, itemProps, ...props }) => {
  const backgroundColor = useMemo(
    () => (item.bannerBackgroundColorCode ? `#${item.bannerBackgroundColorCode}` : 'none'),
    [item.bannerBackgroundColorCode]
  );
  const fontColor = useMemo(
    () => (item.bannerFontColorCode ? `#${item.bannerFontColorCode}` : theme.color.text.basic),
    [item.bannerFontColorCode]
  );
  const contents = useMemo(
    () =>
      `${item.bannerNumber}${
        item.bannerContentsDivisionCode === BANNER_CONTENTS_DIVISION_CODE.ETC
          ? `|${item.bannerMobileImageUrl || item.bannerCommonImageUrl}`
          : ''
      }${
        item.bannerContentsDivisionCode === BANNER_CONTENTS_DIVISION_CODE.EXHIBITION
          ? `|${item.relationContentsNumber}`
          : ''
      }`,
    [
      item.bannerCommonImageUrl,
      item.bannerContentsDivisionCode,
      item.bannerNumber,
      item.bannerMobileImageUrl,
      item.relationContentsNumber,
    ]
  );

  return (
    <StyledRoot {...itemProps} {...props} data-ds-contents={contents}>
      <ImageBox
        imageSrc={item.bannerMobileImageUrl || item.bannerCommonImageUrl}
        background-color={backgroundColor}
        {...itemProps}
      />
      <StyledFlexBoxColumn>
        {item.bannerPhrase01 && <BannerText1 color={fontColor}>{item.bannerPhrase01}</BannerText1>}
        {item.bannerPhrase02 && <BannerText2 color={fontColor}>{item.bannerPhrase02}</BannerText2>}
        {item.bannerPhrase03 && <BannerText3 color={fontColor} style={fontColor === theme.color.text.basic ? {fontWeight:600}:{fontWeight:400}}>{item.bannerPhrase03}</BannerText3>}
      </StyledFlexBoxColumn>
    </StyledRoot>
  );
};

SwiperMainItem.displayName = 'SwiperMainItem';

SwiperMainItem.propTypes = swiperItemPropTypes;

const StyledBannerTitle = styled(StyledComponent.StyledBannerTitle)`
  top: ${({ top }) => (top ? top : '24px')};
  left: ${({ left }) => (left ? left : '20px')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '280px')};
  font-size: ${moTheme.font.size.$16};
  font-weight: ${({ fontWeight = moTheme.font.weight.medium }) => fontWeight};
  line-height: ${({ lineHeight = moTheme.font.lineHeight.medium }) => lineHeight};
  text-overflow: ${({ textOverflow }) => textOverflow};
  white-space: ${({ whiteSpace }) => whiteSpace};
  overflow-x: ${({ overflowX }) => overflowX};
`;

export const SwiperLazyItem = ({ item, itemProps, itemTitleProps, ...props }) => (
  <StyledComponent.StyledRoot {...getSwiperItemProps(itemProps)} {...props}>
    <LazyImg src={item.src} {...getSwiperItemProps(itemProps)} alt={item.title} />
    {item.title && <StyledBannerTitle {...itemTitleProps}>{item.title}</StyledBannerTitle>}
  </StyledComponent.StyledRoot>
);

SwiperLazyItem.displayName = 'SwiperLazyItem';

SwiperLazyItem.propTypes = swiperItemPropTypes;

export const SwiperBannerItem = ({ item, itemProps, itemTitleProps, ...props }) => (
  <StyledComponent.StyledRoot {...getSwiperItemProps(itemProps)} {...props}>
    <StyledImg src={item.src} {...getSwiperItemProps(itemProps)} alt={item.title} />
    {item.title && <StyledBannerTitle {...itemTitleProps}>{item.title}</StyledBannerTitle>}
  </StyledComponent.StyledRoot>
);

SwiperBannerItem.displayName = 'SwiperBannerItem';

SwiperBannerItem.propTypes = swiperItemPropTypes;

export const SwiperBannerPopupItem = ({ item, itemProps, ...props }) => {
  const contents = useMemo(
    () =>
      `${item.bannerNumber}${
        item.bannerContentsDivisionCode === BANNER_CONTENTS_DIVISION_CODE.ETC
          ? `|${item.bannerMobileImageUrl || item.bannerCommonImageUrl}`
          : ''
      }`,
    [item.bannerCommonImageUrl, item.bannerContentsDivisionCode, item.bannerNumber, item.bannerMobileImageUrl]
  );
  return (
    <StyledRootATag {...itemProps} {...props} data-ds-contents={contents} aria-label={item.bannerName}>
      <ImageBox imageSrc={item.bannerMobileImageUrl || item.bannerCommonImageUrl} {...itemProps}>
        <SpanTitle>{item.bannerName}</SpanTitle>
      </ImageBox>
    </StyledRootATag>
  );
};

SwiperBannerPopupItem.displayName = 'SwiperBannerItem';

SwiperBannerPopupItem.propTypes = swiperItemPropTypes;

const AdText1 = styled(BannerText1)`
  top: 24px;
  font-size: 17px;
  font-weight: ${theme.font.weight.medium};
`;

const AdText2 = styled(AdText1)`
  top: 50px;
`;

export const SwiperAdBannerItem = ({ item, itemProps, ...props }) => {
  const contents = useMemo(
    () =>
      `${item.bannerNumber}${
        item.bannerContentsDivisionCode === BANNER_CONTENTS_DIVISION_CODE.ETC
          ? `|${item.bannerMobileImageUrl || item.bannerCommonImageUrl}`
          : ''
      }`,
    [item.bannerCommonImageUrl, item.bannerContentsDivisionCode, item.bannerNumber, item.bannerMobileImageUrl]
  );
  return (
    <StyledComponent.StyledRoot {...itemProps} {...props} data-ds-contents={contents}>
      <ImageBox imageSrc={item.bannerMobileImageUrl || item.bannerCommonImageUrl} {...itemProps} />
      <StyledFlexBoxColumn>
        {item.bannerPhrase01 && <AdText1>{item.bannerPhrase01}</AdText1>}
        {item.bannerPhrase02 && <AdText2>{item.bannerPhrase02}</AdText2>}
      </StyledFlexBoxColumn>
    </StyledComponent.StyledRoot>
  );
};

SwiperAdBannerItem.displayName = 'SwiperAdBannerItem';

SwiperAdBannerItem.propTypes = swiperItemPropTypes;

const StyledAdultGoodText = styled(TextBox)`
  position: absolute;
  justify-content: center;
  height: 19px;
  top: 227px;
  width: 100%;
  font-size: ${moTheme.font.size.$12};
  color: ${theme.color.text.basic};
`;

export const SwiperBlockedItem = ({ item, itemProps, itemTitleProps, adultGood, ...props }) => {
  const blockItemProps = useMemo(
    () => ({ src: adultGood ? adultPath : item.src, padding: adultGood ? '56px 80px 104px 80px' : '0px' }),
    [adultGood, item.src]
  );
  return (
    <StyledComponent.StyledRootButton {...getSwiperItemProps(itemProps)} {...props} type="button" aria-label="상품 이미지 크게 보기">
      <StyledImg {...blockItemProps} {...getSwiperItemProps(itemProps)} alt={item.title}/>
      {item.title && <StyledBannerTitle {...itemTitleProps}>{item.title}</StyledBannerTitle>}
      {adultGood && <StyledAdultGoodText>19세 이상만 구매 가능</StyledAdultGoodText>}
    </StyledComponent.StyledRootButton>
  );
};

SwiperBlockedItem.displayName = 'SwiperBlockedItem';

SwiperBlockedItem.propTypes = swiperItemPropTypes;

const StyledMdPickTitle = styled.div`
  position: absolute;
  white-space: pre-wrap;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  right: 0;
  height: 78px;
  padding: 14px 14px 14px 20px;
  font-size: ${moTheme.font.size.$18};
  font-weight: 700;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.3);
`;

export const SwiperMdPickItem = ({ item, itemProps, ...props }) => (
  <StyledComponent.StyledRoot {...getSwiperItemProps(itemProps)} {...props} data-ds-contents={item.exhibitionNumber}>
    <StyledImg src={item.src} {...getSwiperItemProps(itemProps)} alt={item.title} />
    {item.title && <StyledMdPickTitle>{item.title}</StyledMdPickTitle>}
  </StyledComponent.StyledRoot>
);

SwiperMdPickItem.displayName = 'SwiperMdPickItem';

SwiperMdPickItem.propTypes = swiperItemPropTypes;

export const SwiperNavigatorItem = ({ item, itemProps, ...props }) => (
  <StyledImg src={item.src} radius="5px" {...itemProps} alt={item.title} {...props} />
);

SwiperNavigatorItem.displayName = 'SwiperNavigatorItem';

SwiperNavigatorItem.propTypes = swiperItemPropTypes;

const LargeDecorator = styled(FlexBox)`
  position: absolute;
  justify-content: center;
  ${overrideProperties(positionPropTypes, {})};
  ${overrideProperties(sizePropTypes, {})};
  border-radius: 11px 0;
  color: #ffffff;
  font-size: ${moTheme.font.size.$14};
  font-weight: 700;
  background: ${theme.color.primary};
`;

const StyledNewTitle = styled(StyledMdPickTitle)`
  height: 60px;
  padding: 10px 10px 10px 14px;
  font-size: ${moTheme.font.size.$14};
  border-radius: 0 0 11px 11px;
`;

const StyledNewSubTitle = styled(StyledMdPickTitle)`
  height: 60px;
  padding: 30px 10px 10px 14px;
  font-size: ${moTheme.font.size.$14};
  border-radius: 0 0 11px 11px;
  background: none;
`;

const StyledBlackCover = styled.div`
  position: absolute;
  z-index: 2;
  width: inherit;
  height: inherit;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 12px;
`;

export const SwiperMainExhibitionItem = ({ item, itemProps, selected, ...props }) => {
  return (
    <Badge
      type={'LARGE'}
      badgeComponent={LargeDecorator}
      imageWidth={getPercentSize(itemProps.width)}
      badgeContent="NEW"
      position={{ top: 0, left: 0 }}
      size={{ width: '50px', height: '50px' }}
      invisible={!item.isNew}
    >
      <StyledComponent.StyledRoot {...getSwiperItemProps(itemProps)} {...props} data-ds-contents={item.exhibitionNo}>
        {!selected && <StyledBlackCover />}
        <StyledImg
          src={item.src}
          {...getSwiperItemProps(itemProps)}
          alt={item.title}
          selected={selected}
          radius={'11px'}
        />
        {item.title && <StyledNewTitle>{item.title}</StyledNewTitle>}
        {item.subTitle && <StyledNewSubTitle>{item.subTitle}</StyledNewSubTitle>}
      </StyledComponent.StyledRoot>
    </Badge>
  );
};

SwiperMainExhibitionItem.displayName = 'SwiperMainExhibitionItem';

SwiperMainExhibitionItem.propTypes = swiperItemPropTypes;

export const SwiperShareItem = ({ item, itemProps, ...props }) => (
  <StyledComponent.StyledRoot {...getSwiperItemProps(itemProps)} {...props}>
    <StyledImg src={item.src} {...getSwiperItemProps(itemProps)} alt={item.title} />
    <button type="button" aria-label={item.title + " 공유하기"}>
      <IconShare
        style={{ position: 'absolute', 'z-index': '1', top: '8px', right: '8px' }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          itemProps?.onShareIconClick(item);
        }}
      />
    </button>
  </StyledComponent.StyledRoot>
);

SwiperShareItem.displayName = 'SwiperShareItem';
SwiperShareItem.propTypes = { ...swiperItemPropTypes, onClick: PropTypes.func };
export default SwiperItem;
