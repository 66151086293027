import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { theme } from '@ecp/common/src/style/theme/default';

import { ReactComponent as loadingIcon } from '@ecp/common/src/assets/icon/loading.svg';

const rotation = keyframes`
    from{
        transform: rotate(0deg);
    }

    to{
        transform: rotate(360deg);
    }
`;

const LoadingIcon = styled(loadingIcon)`
  fill: ${(props) => props.color};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin: 16px auto;
  animation: ${rotation} 1.5s linear infinite;
`;

const Loading = (props) => {
  return <LoadingIcon {...props} />;
};

Loading.displayName = 'Loading';

Loading.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

Loading.defaultProps = {
  width: '60px',
  height: '60px',
  color: theme.color.primary,
};
export default Loading;
