import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { SwiperContentsPaging } from '@ecp/common/src/components/swiper/mo/SwiperTemplate';

import BrandCard from '@mo-components/brand/BrandCard';

const BrandItem = ({ item }) => {
  return (
    <FlexBoxColumn>
      <BrandCard brand={item} />
    </FlexBoxColumn>
  );
};

BrandItem.propTypes = {
  item: PropTypes.shape({
    brandNumber: PropTypes.string,
    brandName: PropTypes.string,
    mobileImageUrl: PropTypes.string,
  }),
};

const RecommendBrand = ({ recommendBrandList }) => (
  <Wrapper>
    <TextBox size={moTheme.font.size.$18} weight={moTheme.font.weight.bold}>
      브랜드
    </TextBox>
    <Spacing top={10} />
    <TextBox size={moTheme.font.size.$12} weight={moTheme.font.weight.demiLight} color={theme.color.text.guide}>
      지금 가장 핫한 브랜드의 상품을 만나보세요
    </TextBox>
    <Spacing top={20} />
    <SwiperContentsPaging
      itemList={recommendBrandList}
      itemComponent={BrandItem}
      width={'320px'}
      carouselProps={{ slidesPerView: 3, spaceBetween: 10, loop: false, navigation: true }}
      title={'추천 브랜드'}
    />
    <Spacing top={30} />
  </Wrapper>
);

const Wrapper = styled(FlexBoxColumn)`
  width: 100%;
  background-color: ${theme.color.background.sub};
  padding: 30px 20px;
  align-items: flex-start;
`;

export default RecommendBrand;

RecommendBrand.propTypes = {
  recommendBrandList: PropTypes.array,
};
