import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { theme } from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxSpaceBetween, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import ButtonUnstyled from '@ecp/common/src/components/unstyled/button/ButtonUnstyled';
import { inputBasePropTypes } from '@ecp/common/src/components/input/Input';
import { ReactComponent as SearchSvg } from '@ecp/common/src/assets/icon/icon__search.svg';
import { ReactComponent as InitializationSvg } from '@ecp/common/src/assets/icon/icon__initialization.svg';

import Input from './Input';

const StyledSearchInputRoot = styled(FlexBoxSpaceBetween)`
  flex-grow: 1;
  height: 46px;

  padding-left: 10px;
  padding-top: 2px;
  border-bottom: 2px solid ${theme.color.line.emphasis};
  box-sizing: border-box;

  & input {
    font-weight: 350;
    font-size: ${moTheme.font.size.$14};
    line-height: 24px;
  }
`;

const StyledInputInitialization = styled(FlexBoxSpaceBetween)`
  padding-bottom: 2px;
  flex-grow: 0;
  cursor: pointer;
  user-select: none;
`;

const boxStyle = css`
  padding: 20px;
  border-radius: 8px;
  background-color: ${theme.color.background.basic};
`;

const StyledSearchInputBox = styled(FlexBox)`
  flex: 1;

  & > div:first-child {
    flex-grow: 1;
    align-items: stretch;
  }
`;

const StyledSearchInputArea = styled(FlexBoxColumn)`
  ${({ type }) => type === 'box' && boxStyle}

  align-items: stretch;

  width: 100%;
  height: fit-content;
  box-sizing: border-box;
`;

const SearchIcon = styled(SearchSvg)`
  width: 18px;
  height: 18px;
`;

const StyledTagsArea = styled(FlexBox)`
  flex-wrap: wrap;
  padding-top: 14px;
`;

const StyledTagChip = styled(ButtonUnstyled)`
  width: fit-content;
  height: 30px;
  background-color: ${theme.color.background.white};
  border: 1px solid ${theme.color.line.disabled};
  border-radius: 24px;
  padding: 0 12px;
  box-sizing: border-box;
  font-size: ${moTheme.font.size.$12};
  color: ${theme.color.text.basic};
`;

const SearchInput = React.forwardRef(
  (
    {
      enableInitialize,
      endAdornment,
      type,
      tags,
      onSearchClick,
      onInitializeClick,
      onTagClick,
      searchIconProps,
      ...props
    },
    ref
  ) => {
    const handleSearchClick = (e) => {
      e.stopPropagation();
      onSearchClick?.(e);
    };

    return (
      <StyledSearchInputArea type={type}>
        <StyledSearchInputBox>
          <Input
            {...props}
            ref={ref}
            rootComponent={StyledSearchInputRoot}
            endAdornment={
              <>
                <button type="button" aria-label={props?.title ? props?.title : '검색'}>
                  <SearchIcon {...searchIconProps} onClick={handleSearchClick} />
                  {endAdornment}
                </button>
              </>
            }
          />
          {enableInitialize && (
            <>
              <Spacing left={20} />
              <StyledInputInitialization onClick={onInitializeClick}>
                <InitializationSvg width={15} height={18} />
              </StyledInputInitialization>
            </>
          )}
        </StyledSearchInputBox>
        {tags && (
          <StyledTagsArea>
            {tags.map((tag, index) => (
              <Spacing right={4} bottom={6} key={`tag-item__${index}`}>
                <StyledTagChip value={tag} onClick={onTagClick}>
                  {tag}
                </StyledTagChip>
              </Spacing>
            ))}
          </StyledTagsArea>
        )}
      </StyledSearchInputArea>
    );
  }
);

SearchInput.displayName = 'SearchInput';

SearchInput.propTypes = {
  type: PropTypes.oneOf(['main', 'box']),
  enableInitialize: PropTypes.bool,
  onSearchClick: PropTypes.func,
  tags: PropTypes.array,
  autoFocus: PropTypes.bool,
  ...inputBasePropTypes,
};

SearchInput.defaultProps = {
  type: 'main',
  enableInitialize: false,
};

export default SearchInput;
