import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Badge from '@ecp/common/src/components/badge/mo/Badge';

import { withCartBadgeCount } from '@mo-recoil/order/selector';
import { ReactComponent as CartIcon } from '@mo-assets/icon/icon__cart.svg';

const Wrapper = styled.div`
  width: 20px;
  height: 20px;
`;

const Cart = () => {
  const navigate = useNavigate();
  const cartBadgeCount = useRecoilValue(withCartBadgeCount);

  const handleClick = (e) => {
    e.stopPropagation();
    navigate('/cart');
  };

  return (
    <Wrapper data-ds-label2={'g_basket'}>
      <Badge
        showZero
        badgeContent={cartBadgeCount}
        max={99}
        position={{
          right: -7,
          top: 0,
        }}
        size={{
          height: '16px',
          width: '16px',
        }}
        onClick={handleClick}
        // rootComponent={BadgeRootATag}
        // rootProps={{ href: '/cart', 'aria-label': `장바구니 상품 ${cartBadgeCount}개` }}
      >
        <button type="button" aria-label="장바구니 이동">
          <CartIcon width={20} height={20} />
        </button>
      </Badge>
    </Wrapper>
  );
};

Cart.propTypes = {
  count: PropTypes.number,
};

export default Cart;

// const BadgeRootATag = styled.a`
//   position: relative;
//   width: 100%;
//   height: 100%;
//   margin: 0;
//   padding: 0;
// `;
