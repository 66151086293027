import { useCallback } from 'react';

const useOrderQuantityChange = (
  changeApi,
  setCouponList,
  setGoodCouponList,
  setCartCouponList,
  setDeliveryCostCoupon,
  setGoodPriceList,
  setOrderDeliveryList,
  setIsFinishInit
) => {
  const onChangeOrderQuantity = useCallback(
    async ({ goodList = [], quantity, orderDeliveryList = [] }) => {
      if (goodList.length === 0) return;

      const data = goodList.reduce((acc, { itemList }) => {
        acc = [
          ...acc,
          ...itemList.map(({ goodNumber, itemNumber, cartNumber, orderQuantity }) => ({
            goodNumber,
            itemNumber,
            cartNumber,
            orderQuantity: orderQuantity * quantity,
          })),
        ];
        return acc;
      }, []);

      const { result } = await changeApi(data);

      setCouponList([...result.couponList]);
      setGoodCouponList([...result.goodCouponList]);
      setCartCouponList([...result.cartCouponList]);
      const deliveryCostCoupon = result.couponList.find((coupon) => '023' === coupon.promotionTypeCode);

      setGoodPriceList((prev) => {
        return prev.map((goodPrice) => {
          const good = result.goodCouponList.find(
            ({ goodNumber, itemNumber }) => goodNumber === goodPrice.goodNumber && itemNumber === goodPrice.itemNumber
          );

          return {
            ...goodPrice,
            orderQuantity: good.orderQuantity,
          };
        });
      });

      setDeliveryCostCoupon({
        applyDeliveryCostCoupon: true,
        coupon: deliveryCostCoupon,
        isUseDeliveryCostCoupon: !!deliveryCostCoupon && orderDeliveryList.length === 1,
      });
      setOrderDeliveryList(orderDeliveryList);

      setIsFinishInit(true);
    },
    [
      changeApi,
      setCouponList,
      setGoodCouponList,
      setCartCouponList,
      setGoodPriceList,
      setDeliveryCostCoupon,
      setOrderDeliveryList,
      setIsFinishInit,
    ]
  );
  return { onChangeOrderQuantity };
};

export default useOrderQuantityChange;
