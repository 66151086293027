import React, { useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';

import { extractDataProps } from '@ecp/common/src/utils/utils';
import useIgnorableError from '@ecp/common/src/hooks/useIgnorableError';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { defaultProps, FlexBox, FlexBoxColumn, flexPropTypes } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import InputUnstyled, { inputClassNames } from '@ecp/common/src/components/unstyled/input/InputUnstyled';
import { inputBasePropTypes } from '@ecp/common/src/components/input/Input';
import { ReactComponent as ClearSvg } from '@ecp/common/src/assets/icon/input__clear.svg';

const ClearIcon = styled(ClearSvg)`
  cursor: pointer;
  width: 18px;
  height: 18px;
`;

export const StyledInputRoot = styled.form`
  display: flex;
  position: relative;
  justify-content: space-between;
  ${overrideProperties(flexPropTypes, defaultProps)};

  padding: 1px 10px;
  background-color: ${theme.color.background.white};
  border: 1px solid ${theme.color.line.disabled};
  min-width: ${({ minWidth = '193px' }) => minWidth};

  &,
  & input,
  & textarea {
    font-weight: 350;
    font-size: ${moTheme.font.size.$14};
    line-height: 20px;
  }

  ${overrideProperties(sizePropTypes, {
    width: 'fit-content',
    height: '36px',
  })};

  &.${inputClassNames.disabled} {
    color: ${theme.color.secondary};
    background-color: ${theme.color.background.disabled};
    border: 1px solid ${theme.color.line.disabled};
  }

  &.${inputClassNames.selected} {
    border: 1px solid ${theme.color.line.selected};
  }

  &.${inputClassNames.readOnly} {
    background-color: ${theme.color.background.basic};
  }

  &.${inputClassNames.error} {
    border: 1px solid ${theme.color.line.error};
  }

  &.${inputClassNames.empty} {
    color: ${theme.color.text.placeholder};
  }
`;

export const StyledInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  padding: 0;

  &.${inputClassNames.disabled} {
    color: ${theme.color.secondary};
  }

  &.${inputClassNames.empty} {
    color: ${theme.color.text.newPlaceholder};
  }

  &::placeholder {
    color: ${theme.color.text.newPlaceholder};
  }
`;

export const StyledErrorMessage = styled.div`
  margin-top: 6px;
  font-weight: 350;
  font-size: ${moTheme.font.size.$12};
  line-height: 17px;

  color: ${theme.color.text.error};
`;

const Input = React.forwardRef(
  (
    {
      clearable,
      clearableComponent: ClearableComponent,
      suffixText = '',
      height,
      width,
      minWidth,
      error,
      onEnter,
      ...props
    },
    ref
  ) => {
    const { value, disabled, readOnly, endAdornment, errorMessage, onChange } = props;

    const inputRef = useRef(null);
    useImperativeHandle(ref, () => inputRef.current);

    const { ignoreError } = useIgnorableError({ error, value });

    const dataProps = extractDataProps(props);

    const handleEnter = (e) => {
      inputRef.current.blur();
      onEnter?.(e);
    };
    const handleClearClick = (e) => {
      if (disabled || readOnly) return;

      e.target.value = '';
      onChange(e);
    };

    return (
      <FlexBoxColumn width={width} align-items={'flex-start'}>
        <InputUnstyled
          rootComponent={StyledInputRoot}
          rootProps={{ height, width: '100%', minWidth }}
          inputComponent={StyledInput}
          error={!ignoreError && error}
          ref={inputRef}
          onEnter={handleEnter}
          {...props}
          endAdornment={
            <FlexBox width="fit-content" flex="0">
              <span>{suffixText}</span>
              {clearable && !!value && !disabled && !readOnly && (
                <>
                  <Spacing left={10} />
                  <button type="button" aria-label={'입력텍스트 삭제'}>
                    <ClearableComponent {...dataProps} onClick={handleClearClick} />
                  </button>
                </>
              )}
              {endAdornment && (
                <>
                  <Spacing left={10} />
                  {endAdornment}
                </>
              )}
            </FlexBox>
          }
        />
        {!ignoreError && error && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
      </FlexBoxColumn>
    );
  }
);

Input.displayName = 'Input';

Input.propTypes = {
  ...inputBasePropTypes,
};

Input.defaultProps = {
  clearable: true,
  clearableComponent: ClearIcon,
  placeholder: '입력해 주세요',
  autoComplete: 'on',
  onFocus: () => {},
};

export default Input;
