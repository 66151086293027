import isEmpty from 'lodash-es/isEmpty';

const useValidate = () => {
  const formValidate = async (type, form, setError, validators) => {
    const validatorKeys = Object.keys(validators);

    validatorKeys.forEach((key) => {
      setError((prev) => ({ ...prev, [key]: undefined }));
    });

    const isValid = await validatorKeys.reduce(async (isValidatePromise, key) => {
      const haveBeenValidate = !!(await isValidatePromise);
      const isValidate = await (async (fieldValidators) => {
        for (const fieldValidator of fieldValidators) {
          const { when = ['created', 'updated', 'temp-saved'], message, validator } = fieldValidator;
          if (!when.includes(type)) return true;

          const isValid = await validator?.(form);
          if (isValid) {
            setError((prev) => ({ ...prev, [key]: undefined }));
          } else {
            setError((prev) => ({ ...prev, [key]: message }));
            return false;
          }
        }

        return true;
      })(validators[key]);

      return haveBeenValidate && isValidate;
    }, true);

    return isValid;
  };

  return { formValidate, isEmpty };
};

export default useValidate;
