import PropTypes from 'prop-types';

import moTheme from '@ecp/common/src/style/theme/mo';

const sizeProps = {
  small: {
    goodName: moTheme.font.size.$12,
    originalPrice: moTheme.font.size.$10,
    discountRate: moTheme.font.size.$12,
    percent: moTheme.font.size.$11,
    discountPrice: moTheme.font.size.$12,
    won: moTheme.font.size.$11,
    totalReviewScore: moTheme.font.size.$11,
    flag: moTheme.font.size.$11,
    adult: 0,
  },
  medium: {
    goodName: moTheme.font.size.$13,
    originalPrice: moTheme.font.size.$12,
    discountRate: moTheme.font.size.$16,
    percent: moTheme.font.size.$12,
    discountPrice: moTheme.font.size.$16,
    won: moTheme.font.size.$12,
    totalReviewScore: moTheme.font.size.$12,
    flag: moTheme.font.size.$11,
    adult: moTheme.font.size.$10,
  },
  large: {
    goodName: moTheme.font.size.$14,
    originalPrice: moTheme.font.size.$14,
    discountRate: moTheme.font.size.$22,
    percent: moTheme.font.size.$14,
    discountPrice: moTheme.font.size.$22,
    won: moTheme.font.size.$14,
    totalReviewScore: moTheme.font.size.$12,
    flag: moTheme.font.size.$12,
    adult: moTheme.font.size.$10,
  },
  horizontal: {
    goodName: moTheme.font.size.$13,
    originalPrice: moTheme.font.size.$12,
    discountRate: moTheme.font.size.$16,
    percent: moTheme.font.size.$12,
    discountPrice: moTheme.font.size.$16,
    won: moTheme.font.size.$12,
    totalReviewScore: moTheme.font.size.$12,
    flag: moTheme.font.size.$11,
    adult: 0,
  },
  review: {
    goodName: moTheme.font.size.$12,
    originalPrice: moTheme.font.size.$10,
    discountRate: moTheme.font.size.$14,
    percent: moTheme.font.size.$12,
    discountPrice: moTheme.font.size.$14,
    won: moTheme.font.size.$12,
    totalReviewScore: moTheme.font.size.$12,
    flag: moTheme.font.size.$11,
    adult: moTheme.font.size.$10,
  },
};

const lineHeightProps = {
  small: {
    goodName: '17px',
    totalReviewScore: '19px',
    flag: '18px',
  },
  medium: {
    goodName: '18px',
    totalReviewScore: '19px',
    flag: '18px',
  },
  large: {
    goodName: '22px',
    totalReviewScore: '19px',
    flag: '19px',
  },
  horizontal: {
    goodName: '18px',
    totalReviewScore: '19px',
    flag: '18px',
  },
  review: {
    goodName: '17px',
    totalReviewScore: '19px',
    flag: '18px',
  },
};

const extraSmallProps = {
  rootCardProp: {
    width: '100px',
    flexDirection: 'column',
  },
  imageWidth: '100px',
  titleGap: 10,
  size: sizeProps.small,
  lineHeight: lineHeightProps.small,
  soldOutHeight: '30px',
};
const smallProps = {
  rootCardProp: {
    width: '130px',
    flexDirection: 'column',
  },
  imageWidth: '130px',
  titleGap: 10,
  size: sizeProps.medium,
  lineHeight: lineHeightProps.medium,
  soldOutHeight: '30px',
};

const mediumProps = {
  rootCardProp: {
    width: '156px',
    flexDirection: 'column',
  },
  imageWidth: '156px',
  titleGap: 10,
  size: sizeProps.medium,
  lineHeight: lineHeightProps.medium,
  soldOutHeight: '30px',
};

const largeProps = {
  rootCardProp: {
    width: '210px',
    flexDirection: 'column',
  },
  imageWidth: '210px',
  titleGap: 10,
  size: sizeProps.medium,
  lineHeight: lineHeightProps.medium,
  soldOutHeight: '30px',
};

const gridSmallProps = {
  rootCardProp: {
    width: '18px',
    flexDirection: 'column',
  },
  imageWidth: '80px',
  titleGap: 10,
  size: sizeProps.small,
  lineHeight: lineHeightProps.small,
  soldOutHeight: '30px',
};

const horizontalSmallProps = {
  rootCardProp: {
    width: 'fit-content',
    flexDirection: 'row',
  },
  imageWidth: '90px',
  titleGap: 12,
  size: sizeProps.horizontal,
  lineHeight: lineHeightProps.horizontal,
  soldOutHeight: '30px',
};

const horizontalMediumProps = {
  rootCardProp: {
    width: 'fit-content',
    flexDirection: 'row',
  },
  imageWidth: '130px',
  titleGap: 10,
  size: sizeProps.medium,
  lineHeight: lineHeightProps.medium,
  soldOutHeight: '30px',
};

const reviewProps = {
  rootCardProp: {
    width: '315px',
    flexDirection: 'row',
  },
  imageWidth: '130px',
  titleGap: 6,
  size: sizeProps.review,
  lineHeight: lineHeightProps.review,
  soldOutHeight: '30px',
};

const goodCardPropTypes = {
  thumbnailImageUrl02: PropTypes.string,
  thumbnailImageUrl03: PropTypes.string,
  itemDecorators: PropTypes.array,
  type: PropTypes.oneOf([
    'extraSmall',
    'small',
    'medium',
    'large',
    'gridSmall',
    'horizontalSmall',
    'horizontalMedium',
    'review',
  ]).isRequired,
  goodNumber: PropTypes.string,
  adultGood: PropTypes.bool,
  wish: PropTypes.bool,
  soldOut: PropTypes.bool,
  showRelated: PropTypes.bool,
  onWishClick: PropTypes.func,
};

const cardProps = {
  extraSmall: extraSmallProps,
  small: smallProps,
  medium: mediumProps,
  large: largeProps,
  gridSmall: gridSmallProps,
  horizontalSmall: horizontalSmallProps,
  horizontalMedium: horizontalMediumProps,
  review: reviewProps,
};

export { cardProps, goodCardPropTypes };
