import React from 'react';
import PropTypes from 'prop-types';

import { ImageBox } from '@ecp/common/src/layouts/flex/styled';
import FullPagePopup from '@ecp/common/src/components/modal/mo/popup/FullPagePopup';

const headerTag = () => {
  const styled = {
    padding: '20px',
    fontSize: '18px',
    fontWeight: '700',
    color: 'inherit',
    textDecoration: 'none',
    lineHeight: '18px',
    backgroundColor: 'unset',
    whiteSpace: 'pre-wrap',
  };

  return <h2 style={styled}>정보보호 및 개인정보보호 관리 체계</h2>;
};

const IsmspPopup = ({ open, onClose }) => {
  return (
    <FullPagePopup
      open={open}
      onClose={onClose}
      //header={'정보보호 및 개인정보보호 관리 체계'}
      header={headerTag}
      useCancelButton={false}
      onConfirmClick={onClose}
      buttonPosition="up"
    >
      <ImageBox
        imageSrc={`${process.env.REACT_APP_CLOUDFRONT_URL}/contents/250f63f2aa774ad5bdb0676723e036eb/a5e8d3f2fccd418d8db2b4ee0f4c588b.jpg`}
        width={'100%'}
        height={'100%'}
        background-size={'contain'}
        style={{ minHeight: '400px' }}
      >
        <article style={blind}>
          정보보호 및 개인정보보호 관리체계 인증서 1. 인증번호 : ISMS-P-KISA-2020-044 2. 업체명 : 삼성웰스토리(주) 3.
          대표자 : 정해린 4. 인증의 범위 : 홈페이지, 웰스토리몰, 식자재주문 서비스 5. 유효기간 : 2023.12.16~2026.12.15
          6. 심사수행기관 : 차세대정보보안인증원 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제47조 제1항, 같은 법
          시행령 제47조, 개인정보 보호법 제32조의2에 따라 위와 같이 정보보호 및 개인정보보호 관리체계를 인증합니다.
          2023년 10월 19일 한국인터넷진흥원장
        </article>
      </ImageBox>
    </FullPagePopup>
  );
};

export default IsmspPopup;

IsmspPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

const blind = {
  overFlow: 'hidden',
  position: 'absolute',
  clip: 'rect(0,0,0,0)',
  clipPath: 'polygon(0 0, 0 0, 0 0)',
  width: '1px',
  height: '1px',
  margin: '-1px',
};
