import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { FlexBox, SpanTitle } from '@ecp/common/src/layouts/flex/styled';
import { theme } from '@ecp/common/src/style/theme/default';

import StarRatingIcon from './StarRatingIcon';

const StarRating = ({ rating, maxRate, size, fill }) => {
  const ratingPercentArray = useMemo(
    () =>
      [...Array(maxRate).keys()].map((i) => {
        const gap = rating - i;
        if (gap >= 1) {
          return 100;
        }
        const percent = gap * 100;
        return percent < 0 ? 0 : percent;
      }),
    [rating, maxRate]
  );

  return (
    <FlexBox>
      <SpanTitle>{'총 별점 5점 만점에 ' + rating + '점'}</SpanTitle>
      {ratingPercentArray.map((fillPercent, index) => (
        <StarRatingIcon key={index} size={size} fill={fill} fillPercent={fillPercent} />
      ))}
    </FlexBox>
  );
};

StarRating.displayName = 'StarRating';

StarRating.propTypes = {
  rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxRate: PropTypes.number,
  size: PropTypes.number,
  fill: PropTypes.string,
};

StarRating.defaultProps = {
  maxRate: 5,
  size: 12,
  fill: theme.color.primary,
};

export default StarRating;
