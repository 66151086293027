import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

import { GoodCardImage } from '@mo-components/good/GoodCard';
import { ReactComponent as RecentNoResultIcon } from '@mo-assets/icon/icon__recent__no-result.svg';

const RecentGoodList = ({ list, emptyListText, onClose }) => {
  const navigate = useNavigate();
  const handleGoodClick = (e, goodNumber) => {
    navigate(`/good/${goodNumber}`);
    onClose?.();
  };

  return (
    <GoodCardListWrapper>
      {list.length > 0 ? (
        <>
          {list.map((item, index) => (
            <GoodCardWrapper key={index}>
              <GoodCardImage {...item} onClick={handleGoodClick} onWishClick={onClose} type={'gridSmall'} />
            </GoodCardWrapper>
          ))}
        </>
      ) : (
        <EmptyWrapper>
          <Spacing top={80} />
          <RecentNoResultIcon />
          <Spacing top={22} />
          <TextBox size={moTheme.font.size.$16} weight={moTheme.font.weight.demiLight} color={theme.color.text.guide}>
            {emptyListText}
          </TextBox>
        </EmptyWrapper>
      )}
    </GoodCardListWrapper>
  );
};

RecentGoodList.propTypes = {
  list: PropTypes.array,
  emptyListText: PropTypes.string,
  onClose: PropTypes.func,
};

export default RecentGoodList;

const GoodCardListWrapper = styled(FlexBox)`
  width: 100%;
  flex-wrap: wrap;
  padding-top: 14px;
`;

const GoodCardWrapper = styled(FlexBox)`
  padding: 0 10px 10px 0;

  &:nth-child(3n) {
    padding-right: 0;
  }
`;

const EmptyWrapper = styled(FlexBoxColumn)`
  width: 100%;
  justify-content: center;
  align-items: center;
`;
