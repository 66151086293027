import { trackPromise } from 'react-promise-tracker';

import axios from 'axios';
import isEmpty from 'lodash-es/isEmpty';

import { getEcpErrorMessage } from '@ecp/common/src/utils/errorUtils';
import { clearAccessTokens, getAccessToken, getRefreshToken, saveAccessTokens } from '@ecp/common/src/utils/utils';
import pathnameConverter from '@ecp/common/src/utils/pathnameConverter';

import { showExternalAlertDialog } from '@mo-hooks/common/useModal';
import authApi from '@mo-apis/common/authApi';
import commonApi from '@mo-apis/common/commonApi';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const MOBILE_BASE_URL = process.env.REACT_APP_MOBILE_BASE_URL;
const DEFAULT_VALID_STATUS_CODE = [200, 201, 202, 204];
const DEFAULT_ERROR_STATUS_CODE = [403, 404, 500];
const AUTH_ERROR_STATUS_CODE = [401];

const headers = {
  Accept: 'application/json',
  withCredentials: false,
};

const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers,
});

const axiosSkipErrorClient = axios.create({
  baseURL: BASE_URL,
  headers,
});

export const axiosRawClient = axios.create({
  baseURL: BASE_URL,
  headers,
});

const axiosMobileClient = axios.create({
  baseURL: MOBILE_BASE_URL,
  headers,
});

const axiosSkipErrorMobileClient = axios.create({
  baseURL: MOBILE_BASE_URL,
  headers,
});

const moveLogoutPage = () => {
  clearAccessTokens();

  const paramString = pathnameConverter.toParamString(window.location.pathname);
  if (!isEmpty(paramString)) {
    window.location.replace(`/logout?${paramString}`);
    return;
  }
  window.location.replace('/logout');
};

const sendUnknownError = async (error) => {
  await commonApi.sendUnknownError(JSON.stringify(error));
}

const requestInterceptor = (config) => {
  const accessToken = getAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

const moveErrorPage = (errorCode) => {
  window.location.replace(`/error/${errorCode}`);
};

const responseErrorInterceptor = async (error) => {
    if (DEFAULT_ERROR_STATUS_CODE.includes(error.response?.status)) {
    // 403, 404, 500
    moveErrorPage(error.response?.status);
  } else if (AUTH_ERROR_STATUS_CODE.includes(error.response?.status)) {
    //401
    if (error.response?.data?.errorCode === 'TOKEN_EXPIRED') {
      const refreshToken = getRefreshToken();
      try {
        const response = await authApi.reissue(refreshToken);
        const { data: { result: { accessToken: newAccessToken, refreshToken: newRefreshToken } } = {} } = response;
        if (newAccessToken && newRefreshToken) {
          saveAccessTokens(newAccessToken, newRefreshToken);
        } else {
          moveLogoutPage();
        }
        //Retry Origin Request.
        const originRequest = error.config;
        originRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
        const originalRes = await axiosClient(originRequest);
        return Promise.resolve(originalRes);
      } catch (e) {
        if (e?.response?.data?.errorCode === 'TOKEN_EXPIRED') {
          showExternalAlertDialog('세션만료되어 로그아웃되었습니다', () => {
            moveLogoutPage();
          });
        } else {
          showExternalAlertDialog('알 수 없는 오류로 로그아웃되었습니다', async () => {
            await sendUnknownError(e);
            moveLogoutPage();
          });
        }
      }
    } else if (error.response?.data?.errorCode === 'INVALID_TOKEN') {
      showExternalAlertDialog('세션만료되어 로그아웃되었습니다', () => {
        moveLogoutPage();
      });
    }
  } else if (error.response?.data?.errorType === 'ECP_EXCEPTION') {
    showExternalAlertDialog(getEcpErrorMessage(error), () => {});
  } else {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else {
      return window.location.replace('/serverError.html');
    }
  }
  return Promise.reject(error);
};

axiosClient.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));
axiosClient.interceptors.response.use((response) => response.data, responseErrorInterceptor);
axiosSkipErrorClient.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));

axiosMobileClient.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));
axiosMobileClient.interceptors.response.use((response) => response.data, responseErrorInterceptor);
axiosSkipErrorMobileClient.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));

const validStatusConfig = (validStatusList) => ({
  validateStatus: (status) => {
    if (validStatusList.length < 1) {
      return true;
    }
    return validStatusList.includes(status);
  },
});

const getAxiosClient = (data, skipError = false) => {
  if (skipError) {
    return data?.isMobile ? axiosSkipErrorMobileClient(data) : axiosSkipErrorClient(data);
  } else {
    return data?.isMobile ? axiosMobileClient(data) : axiosClient(data);
  }
};

export const getRequest = (options, skip = {}) => {
  const { skipError = false, skipSpinner = false } = skip;
  let data = {
    ...options,
    method: 'GET',
  };
  const client = getAxiosClient(data, skipError);
  return skipSpinner ? client : trackPromise(client);
};

export const postRequest = (options, skip = {}, validStatusList = DEFAULT_VALID_STATUS_CODE) => {
  const { skipError = false, skipSpinner = false } = skip;
  let data = {
    ...options,
    ...validStatusConfig(validStatusList),
    method: 'POST',
  };
  const client = getAxiosClient(data, skipError);
  return skipSpinner ? client : trackPromise(client);
};

export const putRequest = (options, skip = {}, validStatusList = DEFAULT_VALID_STATUS_CODE) => {
  const { skipError = false, skipSpinner = false } = skip;
  let data = {
    ...options,
    ...validStatusConfig(validStatusList),
    method: 'PUT',
  };
  const client = getAxiosClient(data, skipError);
  return skipSpinner ? client : trackPromise(client);
};

export const patchRequest = (options, skip = {}, validStatusList = DEFAULT_VALID_STATUS_CODE) => {
  const { skipError = false, skipSpinner = false } = skip;
  let data = {
    ...options,
    ...validStatusConfig(validStatusList),
    method: 'PATCH',
  };
  const client = getAxiosClient(data, skipError);
  return skipSpinner ? client : trackPromise(client);
};

export const deleteRequest = (options, skip = {}, validStatusList = DEFAULT_VALID_STATUS_CODE) => {
  const { skipError = false, skipSpinner = false } = skip;
  let data = {
    ...options,
    ...validStatusConfig(validStatusList),
    method: 'DELETE',
  };
  const client = getAxiosClient(data, skipError);
  return skipSpinner ? client : trackPromise(client);
};
