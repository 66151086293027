import { postRequest, getRequest } from '@mo-apis/axios';

const createAffilateQuestion = (question) =>
  postRequest({
    url: '/api/v1/mall-question/affilate-question',
    data: { ...question },
  });

const createBulkPurchaseQuestion = (question) =>
  postRequest({
    url: '/api/v1/mall-question/bulk-purchase-question',
    data: { ...question },
  });
const createEntryQuestion = (question) =>
  postRequest({
    url: '/api/v1/mall-question/entry-question',
    data: { ...question },
  });

const getEntryFormData = () =>
  getRequest({
    url: '/api/v1/mall-question/entry-form',
  });

export { createAffilateQuestion, createBulkPurchaseQuestion, createEntryQuestion, getEntryFormData };
