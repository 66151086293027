import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RemoveScroll } from 'react-remove-scroll';

import { FloatingOverlay } from '@floating-ui/react';

import { zIndex } from '@ecp/common/src/style/constant';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import PopupUnstyled from '@ecp/common/src/components/unstyled/floating/PopupUnstyled';
import { StyledBottomButtonComponent } from '@ecp/common/src/components/modal/mo/popup/FullPagePopup';
import { ReactComponent as TooltipCloseIcon } from '@ecp/common/src/assets/icon/tooltip__close.svg';

const LayerPopup = ({
  open,
  onClose,
  header,
  headerProps,
  closable,
  closeButtonType,
  useCancelButton,
  cancelButtonText,
  onCancelClick,
  cancelButtonProps,
  useConfirmButton,
  confirmButtonText,
  onConfirmClick,
  confirmButtonProps,
  bodyPadding,
  bodyMargin,
  buttonPadding,
  ...rest
}) => {
  const bottomButtonProps = {
    useCancelButton,
    cancelButtonText,
    onCancelClick,
    cancelButtonProps,
    useConfirmButton,
    confirmButtonText,
    onConfirmClick,
    confirmButtonProps,
    padding: buttonPadding ? buttonPadding : '30px 20px 20px',
  };

  return (
    <PopupUnstyled
      open={open}
      onClose={onClose}
      rootComponent={StyledPopupRoot}
      headerComponent={header}
      headerProps={headerProps}
      bodyProps={{ bodyPadding, bodyMargin }}
      bodyComponent={StyledBodyComponent}
      bottomButtonComponent={<StyledBottomButtonComponent {...bottomButtonProps} />}
      closable={closable}
      closeComponent={
        closeButtonType === 'Primary' ? (
          <button>
            <DefaultCloseComponent />
          </button>
        ) : (
          <></>
        )
      }
      {...rest}
    />
  );
};

export default LayerPopup;

LayerPopup.displayName = 'LayerPopup';

LayerPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  header: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node, PropTypes.string]),
  headerProps: PropTypes.object,
  closable: PropTypes.bool,
  closeButtonType: PropTypes.oneOf(['Primary', 'Secondary']),
  useCancelButton: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  onCancelClick: PropTypes.func,
  cancelButtonProps: PropTypes.object,
  useConfirmButton: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  onConfirmClick: PropTypes.func,
  confirmButtonProps: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node]),
  bodyPadding: PropTypes.string,
  bodyMargin: PropTypes.string,
  buttonPadding: PropTypes.string,
};

LayerPopup.defaultProps = {
  headerProps: {
    size: '18px',
    weight: '700',
    height: '20px',
    style: { padding: '20px' },
  },
  closable: true,
  closeButtonType: 'Primary',
  useCancelButton: true,
  cancelButtonProps: {
    type: 'Secondary',
    width: '136px',
  },
  cancelButtonText: '취소',
  useConfirmButton: true,
  confirmButtonProps: {
    type: 'Primary',
    width: '136px',
  },
  confirmButtonText: '확인',
};

const StyledPopupRoot = React.forwardRef(({ children, ...props }, ref) => {
  const rootRef = useRef(null);

  useImperativeHandle(ref, () => rootRef.current);

  return (
    <RemoveScroll>
      <FloatingOverlay
        style={{
          zIndex: zIndex.popup,
          display: 'grid',
          background: 'rgba(25, 25, 25, 0.8)',
          placeItems: 'center',
        }}
      >
        <PopupWrapper ref={rootRef} {...props}>
          {children}
        </PopupWrapper>
      </FloatingOverlay>
    </RemoveScroll>
  );
});

StyledPopupRoot.displayName = 'StyledPopupRoot';

StyledPopupRoot.propTypes = {
  children: PropTypes.node,
};

const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 320px;
  height: fit-content;
  max-height: calc(var(--vh, 1vh) * 100);
  background-color: ${theme.color.background.white};
  border-radius: 8px;

  &:focus-visible {
    outline: none;
  }
`;

const StyledBodyComponent = styled(FlexBox)`
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
  flex: 1;
  margin: ${({ bodyMargin }) => (bodyMargin ? `${bodyMargin}px` : '20px 0 0')};
  padding: ${({ bodyPadding }) => (bodyPadding ? `${bodyPadding}px` : '0 20px')};
`;

const DefaultCloseComponent = styled(TooltipCloseIcon)`
  border: none;
  width: 18px;
  height: 18px;
  background-color: ${theme.color.background.white};
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;
