import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import usePreventPopState from '@ecp/common/src/hooks/order/payment/usePreventPopState';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { PageButton } from '@ecp/common/src/components/button/mo/Button';
import Paper from '@ecp/common/src/components/paper/Paper';

import { useHideFooter } from '@mo-hooks/common/useFooter';
import { ReactComponent as IconOrderFailed } from '@mo-assets/icon/icon__order-fail.svg';
import useSiteInfo from '@mo-hooks/common/useSiteInfo';

const PaymentFailPage = () => {
  const { isCartUse } = useSiteInfo();
  useHideFooter();

  const { code, message } = useLocation().state;
  const navigate = useNavigate();
  usePreventPopState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <FlexBoxColumn width={'100%'}>
      <Spacing top={120} />
      <FlexBox>
        <IconOrderFailed />
      </FlexBox>
      <Spacing top={20} />
      <FlexBoxColumn gap="6px">
        <TextBox
          size={moTheme.font.size.$18}
          weight={moTheme.font.weight.medium}
          lineHeight={moTheme.font.lineHeight.demiLight}
          color={'#141415'}
        >
          결제를 실패했습니다.
        </TextBox>
        <TextBox
          size={moTheme.font.size.$14}
          weight={moTheme.font.weight.demiLight}
          lineHeight={moTheme.font.lineHeight.demiLight}
          color={'#5A5A5A'}
        >
          아래의 실패 사유를 확인해 주세요.
        </TextBox>
      </FlexBoxColumn>
      <Spacing width={'100%'} top={30} left={20} right={20}>
        <Paper width={'100%'} height="{'fit-content'}" style={{ background: '#F7F7F7', padding: '19px' }}>
          <FlexBoxColumn>
            <TextBox
              size={moTheme.font.size.$14}
              weight={moTheme.font.weight.demiLight}
              lineHeight={moTheme.font.lineHeight.demiLight}
              color={'#F22214'}
            >
              [{code || ''}] {message || ''}
            </TextBox>
          </FlexBoxColumn>
        </Paper>
      </Spacing>
      <Spacing top={30} />
      {isCartUse ? (
        <PageButton type="Default" width={'156px'} onClick={() => navigate('/cart')}>
          장바구니 돌아가기
        </PageButton>
      ) : (
        <PageButton type="Default" width={'156px'} onClick={() => navigate('/')}>
          홈으로 돌아가기
        </PageButton>
      )}
      <Spacing bottom={120} />
    </FlexBoxColumn>
  );
};

export default PaymentFailPage;
