import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Input, { StyledInput } from '@ecp/common/src/components/input/mo/Input';
import { inputBasePropTypes } from '@ecp/common/src/components/input/Input';
import { NumberInputBase } from '@ecp/common/src/components/input/NumberInput';

const StyledDefaultNumberInput = styled(StyledInput)`
  text-align: right;
`;

const NumberInput = React.forwardRef((props, ref) => {
  return <NumberInputBase ref={ref} input={Input} inputComponent={StyledDefaultNumberInput} {...props} title={props?.title ? props?.title : '수량'} />;
});

NumberInput.propTypes = {
  title: PropTypes.string,
};

NumberInputBase.propTypes = {
  ...inputBasePropTypes,
  format: PropTypes.bool,
  max: PropTypes.number,
  title: PropTypes.string,
};

NumberInputBase.defaultProps = {
  suffixText: '',
  maxLength: 15,
  placeholder: '',
  format: true,
  max: Number.MAX_SAFE_INTEGER,
  title: '',
};

export default NumberInput;

NumberInput.displayName = 'NumberInput';
