import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import styled from 'styled-components';

import clsx from 'clsx';

import useScrollDirection from '@ecp/common/src/hooks/useScrollDirection';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxCenter, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import ButtonUnstyled from '@ecp/common/src/components/unstyled/button/ButtonUnstyled';

import { searchKeywordState } from '@mo-recoil/search/atom';
import { showBenefitState } from '@mo-recoil/common/navigation/atom';
import BenefitCardBanner from '@mo-components/benefit/BenefitCardBanner';
import BenefitCardBottomSheet from '@mo-components/benefit/BenefitCardBottomSheet';
import { ReactComponent as BackgroundImage } from '@mo-assets/image/image__navigation.svg';
import { ReactComponent as HomeImage } from '@mo-assets/image/common/button/image__navigation__home.svg';
import { ReactComponent as CategoryImage } from '@mo-assets/image/common/button/image__navigation__category.svg';
import { ReactComponent as SearchImage } from '@mo-assets/image/common/button/image__navigation__search.svg';
import { ReactComponent as MyPageImage } from '@mo-assets/image/common/button/image__navigation__my-page.svg';
import { ReactComponent as BenefitIcon } from '@mo-assets/image/common/button/icon__navigation__benefit.svg';
import { ReactComponent as BenefitLabel } from '@mo-assets/image/common/button/image__navigation__benefit-label.svg';

const NavigationMenu = () => {
  const resetSearchKeyword = useResetRecoilState(searchKeywordState);
  const navigate = useNavigate();

  const showBenefit = useRecoilValue(showBenefitState);
  const [showBottomSheet, setShowBottomSheet] = useState(false);

  const scrollDirection = useScrollDirection();
  const scrollDirectionStyle = clsx(scrollDirection);

  const handleBottomSheetOpen = () => {
    setShowBottomSheet(true);
  };

  const handleBottomSheetClos = () => {
    setShowBottomSheet(false);
  };

  const navigateSearch = (e) => {
    e.stopPropagation();
    e.preventDefault();

    resetSearchKeyword();
    navigate('/search');
  };

  const menuPath = window.location.pathname.replace('/', '');

  return (
    <Container className={scrollDirectionStyle}>
      {showBenefit && <BenefitCardBanner onClick={handleBottomSheetOpen} />}
      <BenefitCardBottomSheet open={showBottomSheet} onClose={handleBottomSheetClos} />
      <MenuContainer>
        <MenuBackgroundLeft />
        <MenuBackgroundRight>
          {showBenefit ? (
            <BackgroundImage width={'360px'} height={'65px'} />
          ) : (
            <FlexBoxSpaceBetween
              width={'360px'}
              height={'65px'}
              background-color={theme.color.background.basic}
              border={`1px solid ${theme.color.line.basic}`}
            />
          )}
        </MenuBackgroundRight>
        <MenuButtonContainer>
          <Menu onClick={() => navigate('/')} data-ds-label2={'navi_home'}>
            <MenuWrapper selected={menuPath === ''}>
              <HomeImage width={22} height={41} />
            </MenuWrapper>
          </Menu>
          <Menu onClick={() => navigate('/category')} data-ds-label2={'navi_ctg'}>
          <MenuWrapper selected={menuPath === 'category'}>
              <CategoryImage width={37} height={41} />
            </MenuWrapper>
          </Menu>
          <Menu onClick={navigateSearch} data-ds-label2={'navi_search'}>
          <MenuWrapper selected={menuPath === 'search'}>
              <SearchImage width={22} height={41} />
            </MenuWrapper>
          </Menu>
          <Menu onClick={() => navigate('/my-page')} data-ds-label2={'navi_my_page'}>
          <MenuWrapper selected={menuPath === 'my-page'}>
              <MyPageImage width={46} height={41} />
            </MenuWrapper>
          </Menu>
          {showBenefit && (
            <BenefitMenuWrapper>
              <BenefitMenu data-ds-label2={'navi_benefit'}>
                <BenefitIconWrapper>
                  <BenefitIcon onClick={handleBottomSheetOpen} />
                </BenefitIconWrapper>
                <Spacing bottom={15} />
                <BenefitLabel width={45} height={10} />
              </BenefitMenu>
            </BenefitMenuWrapper>
          )}
        </MenuButtonContainer>
      </MenuContainer>
    </Container>
  );
};

export default NavigationMenu;

const Container = styled(FlexBoxColumn)`
  position: relative;
  width: 360px;
  margin-top: 0;
  height: 100%;
  transition: margin-top 0.3s ease-in-out;

  &.down,
  &.bottom {
    margin-top: 200px;
  }
`;

const MenuContainer = styled.div`
  width: 360px;
  height: 100%;
`;

const MenuButtonContainer = styled.div`
  position: relative;
  display: flex;
  width: 360px;
  height: 100%;
  z-index: 10;

  &::after {
    position: absolute;
    bottom: -1px;
    content: '';
    height: 2px;
    left: 0;
    right: 0;
    border-left: 1px solid ${theme.color.line.basic};
    border-right: 1px solid ${theme.color.line.basic};
    background-color: ${theme.color.background.basic};
  }
`;

const MenuBackgroundLeft = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  width: 10px;
  height: 50px;
  background: ${theme.color.background.basic};
  bottom: 0px;
`;

const MenuBackgroundRight = styled.div`
  overflow: hidden;
  width: 360px;
  position: absolute;
  right: 0;
  z-index: 1;
  text-align: right;
`;

const Menu = styled(ButtonUnstyled)`
  flex: 1;
  height: 86px;
  border: none;
  padding: 12px 0;
  background-color: transparent;
`;

const MenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: ${({ selected }) => (selected ? '1px solid #919191;' : 'none')}
`;

const BenefitMenuWrapper = styled(FlexBoxCenter)`
  width: 86px;
`;

const BenefitMenu = styled(Menu)`
  position: absolute;
  bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  height: 71px;
`;

const BenefitIconWrapper = styled(FlexBoxCenter)`
  background-color: ${theme.color.background.white};
  width: 46px;
  height: 46px;
  border-radius: 50%;
  /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.35);*/
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
`;

