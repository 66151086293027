import React, { useCallback } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import PropTypes from 'prop-types';

import { clearAccessTokens, getAccessToken } from '@ecp/common/src/utils/utils';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBoxAlignCenter } from '@ecp/common/src/text/TextBox';
import LayerPopup from '@ecp/common/src/components/modal/mo/popup/LayerPopup';

import { myDeliveryPlaceListState } from '@mo-recoil/order/atom';
import { withMobileStatus } from '@mo-recoil/system/selector';
import authApi from '@mo-apis/common/authApi';
import {
  ANDROID,
  APP_FORCE_UPDATE_CONTENTS,
  APP_UPDATE_CONTENTS,
  APP_UPDATE_TITLE,
  MOBILE_APP_STATUS,
} from '@ecp/mo/src/const/system/mobileConst';

const MobileAppUpdatePopup = ({ open, onClose }) => {
  const { versionUpdateType, agentType } = useRecoilValue(withMobileStatus);

  const resetDeliveryPlaceList = useResetRecoilState(myDeliveryPlaceListState);

  const handleClickConfirm = useCallback(async () => {
    console.log(versionUpdateType === MOBILE_APP_STATUS.MAJOR_UPDATE)
    if (versionUpdateType === MOBILE_APP_STATUS.MAJOR_UPDATE) {
      if (getAccessToken() != null) {
        await authApi.logout();
        clearAccessTokens();
        resetDeliveryPlaceList();
      }
    }

    if (agentType === ANDROID) {
      window.open('welstorymall://store');
    } else {
      window.location.href = 'https://apps.apple.com/kr/app/%EC%9B%B0%EC%8A%A4%ED%86%A0%EB%A6%AC%EB%AA%B0/id704167894';
    }
  }, [agentType, versionUpdateType]);

  return (
    <>
      <LayerPopup
        open={open}
        closable={false}
        header={' '}
        cancelButtonProps={
          versionUpdateType === MOBILE_APP_STATUS.MAJOR_UPDATE
            ? { type: 'Secondary', width: '136px' }
            : { type: 'Default', width: '136px' }
        }
        cancelButtonText={versionUpdateType === MOBILE_APP_STATUS.MAJOR_UPDATE ? '앱종료' : '다음에 하기'}
        onCancelClick={onClose}
        confirmButtonProps={{ type: 'Third', width: '136px' }}
        confirmButtonText={'업데이트'}
        onConfirmClick={handleClickConfirm}
      >
        <FlexBoxColumn width={'100%'} gap={'14px'}>
          <TextBoxAlignCenter weight={moTheme.font.weight.medium} size={moTheme.font.size.$16}>
            {APP_UPDATE_TITLE}
          </TextBoxAlignCenter>
          <TextBoxAlignCenter width={moTheme.font.weight.demiLight} size={moTheme.font.size.$14}>
            {APP_UPDATE_CONTENTS}{' '}
            {versionUpdateType === MOBILE_APP_STATUS.MAJOR_UPDATE ? APP_FORCE_UPDATE_CONTENTS : null}
          </TextBoxAlignCenter>
        </FlexBoxColumn>
      </LayerPopup>
    </>
  );
};

export default MobileAppUpdatePopup;

MobileAppUpdatePopup.propTypes = {
  open: PropTypes.func,
  onClose: PropTypes.func,
};
