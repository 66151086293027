import { useCallback, useEffect, useMemo } from 'react';

export default function useGiftiShowOrderPage(orderPageInfo, resetOrderDeliveryList, setGiftiShowInformation) {
  const decorationImageList = useMemo(() => {
    return orderPageInfo.giftiShowDecorationList;
  }, [orderPageInfo]);

  useEffect(() => {
    const defaultBanner = decorationImageList.find(({ decorationTypeCode }) => decorationTypeCode === 'B');
    const defaultCard = decorationImageList.find(({ decorationTypeCode }) => decorationTypeCode === 'C');
    setGiftiShowInformation((prev) => ({ ...prev, banner: defaultBanner, card: defaultCard }));
  }, [decorationImageList, setGiftiShowInformation]);

  const orderGoodType = useMemo(() => {
    return {
      directForeignGood: false,
      alcohol: false,
      ticket: false,
      pointGood: false,
      rental: false,
      giftCertificate: false,
    };
  }, []);

  const handleChangeDecoration = useCallback(
    ({ banner, card }) => {
      setGiftiShowInformation((prev) => ({ ...prev, banner, card }));
    },
    [setGiftiShowInformation]
  );

  return {
    orderGoodType,
    decorationImageList,
    handleChangeDecoration,
  };
}
