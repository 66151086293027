import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { usePromiseTracker } from 'react-promise-tracker';

import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

import { getPageQueryParams } from '@mo-utils/commonUtils';
import useIntersection from '@mo-hooks/common/useIntersection';
import displayCategoryApi from '@mo-apis/display/displayCategoryApi';
import GoodCard from '@mo-components/good/GoodCard';
import NoResultFoundList from '@mo-components/common/NoResultFoundList';
import NewGoodHeader from '@mo-pages/newGood/NewGoodHeader';
import HeaderTitle from '@mo-pages/category/components/HeaderTitle';

const COUNT_PER_PAGE = 20;

const NewGoodList = ({ categoryNumber = 'ALL', pageProps, setPageProps, showHeaderTitle }) => {
  const [newGoodList, setNewGoodList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const categoryNumberRef = useRef(null);
  const pagePropsRef = useRef(null);

  const [fetchMoreThanOnePage, setFetchMoreThanOnePage] = useState(pageProps.page > 1);

  const getNewGoodList = useCallback(
    async (categoryNumber, param) => {
      if (
        pagePropsRef.current?.sort === param.sort &&
        pagePropsRef.current.page === param.page &&
        categoryNumberRef.current?.categoryNumber === categoryNumber
      ) {
        return;
      }

      const defParam = { ...pagePropsRef.current, ...param };
      const page = fetchMoreThanOnePage ? 1 : defParam.page;
      const pagePerItemCount = fetchMoreThanOnePage ? COUNT_PER_PAGE * defParam.page : COUNT_PER_PAGE;
      const pageParams = getPageQueryParams(page, pagePerItemCount, defParam.sort.value);

      const { result } = await displayCategoryApi.getNewGoodList({ categoryNumber }, pageParams);

      const { content = [], totalElements = 0 } = result;
      const flagFilteredContent = content.map((good) => ({
        ...good,
        flagList: good.flagList?.filter((flag) => flag !== 'NEW'),
      }));
      setNewGoodList((prev) =>
        pageParams.page === 0 || fetchMoreThanOnePage ? [...flagFilteredContent] : [...prev, ...flagFilteredContent]
      );
      setTotalCount(totalElements);

      setPageProps({ ...defParam });
      pagePropsRef.current = { ...defParam };
      categoryNumberRef.current = { categoryNumber };
      setFetchMoreThanOnePage(false);
    },
    [fetchMoreThanOnePage, setPageProps]
  );

  useEffect(() => {
    (async () => {
      await getNewGoodList(categoryNumber, { page: pageProps.page, sort: pageProps.sort });
    })();
  }, [categoryNumber, pageProps, getNewGoodList]);

  const handleSortChange = async (sort) => {
    await getNewGoodList(categoryNumber, { page: 1, sort });
  };

  const fetchNext = async () => {
    const nextPage = pagePropsRef.current.page + 1;
    await getNewGoodList(categoryNumber, { page: nextPage });
  };

  const { promiseInProgress } = usePromiseTracker();

  const hasNext = useMemo(
    () => !promiseInProgress && !!newGoodList?.length && totalCount > newGoodList?.length,
    [promiseInProgress, newGoodList?.length, totalCount]
  );

  const { setNextRef } = useIntersection({ fetchNext, hasNext });

  return (
    <FlexBoxColumn align-items={'flex-start'} padding={'0 20px'} width={'100%'}>
      {showHeaderTitle && (
        <>
          <HeaderTitle title={'신상품'} />
          <Spacing top={14} />
        </>
      )}
      <NewGoodHeader totalCount={totalCount} sort={pagePropsRef.current?.sort} handleSortChange={handleSortChange} />
      <Spacing top={14} />
      {newGoodList?.length > 0 ? (
        <>
          <FlexBoxSpaceBetween flex-wrap={'wrap'} width={'100%'} align-items={'flex-start'}>
            {newGoodList?.map((newGood, index) => (
              <Spacing key={`new-good-item__${index}`} bottom={30}>
                <GoodCard {...newGood} type={'medium'} />
              </Spacing>
            ))}
          </FlexBoxSpaceBetween>
          {hasNext && <FlexBox ref={setNextRef} width={'100%'} height={'20px'} />}
        </>
      ) : (
        <>
          <NoResultFoundList message={'등록된 신상품이 없습니다.'} />
          <Spacing top={20} />
        </>
      )}
      <Spacing top={30} />
    </FlexBoxColumn>
  );
};

NewGoodList.propTypes = {
  categoryNumber: PropTypes.string,
  newGoodList: PropTypes.array,
  totalCount: PropTypes.number,
  fetchNext: PropTypes.func,
  showHeaderTitle: PropTypes.bool,
  pageProps: PropTypes.shape({
    page: PropTypes.number,
    sort: PropTypes.object,
  }),
  setPageProps: PropTypes.func,
};

export default NewGoodList;
