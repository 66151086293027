import { useCallback, useMemo } from 'react';

import isEmpty from 'lodash-es/isEmpty';

import { isValidCellNoDash, isValidPhoneNoDash } from '@ecp/common/src/utils/InputValidateUtils';
import useEventCallback from '@ecp/common/src/hooks/useEventCallback';
import { DELIVERY_MESSAGE_WITH_CUSTOM_VALUE } from '@ecp/common/src/const/deliveryMessage';

import AddressInput from '@mo-components/myPage/deliveryLocation/AddressInput';

const formValidators = {
  deliveryLocationName: [
    {
      when: ['all'],
      message: '배송지명을 입력해주세요.',
      validator: ({ deliveryLocationName }) => !isEmpty(deliveryLocationName),
    },
  ],
  receiverName: [
    {
      when: ['all'],
      message: '받는 사람을 입력해주세요.',
      validator: ({ receiverName }) => !isEmpty(receiverName),
    },
  ],
  cellphoneNumber: [
    {
      when: ['all'],
      message: '휴대전화번호를 정확히 입력해주세요.',
      validator: ({ cellphoneNumber }) =>
        !isEmpty(cellphoneNumber) && cellphoneNumber.length >= 10 && isValidCellNoDash(cellphoneNumber),
    },
  ],
  telephoneNumber: [
    {
      when: ['all'],
      message: '일반전화번호를 정확히 입력해주세요.',
      validator: ({ telephoneNumber }) => {
        return !telephoneNumber || (telephoneNumber.length >= 9 && isValidPhoneNoDash(telephoneNumber));
      },
    },
  ],
  postNumber: [
    {
      when: ['all'],
      message: '주소를 입력해주세요.',
      validator: ({ postNumber }) => !isEmpty(postNumber),
    },
  ],
  baseAddress: [
    {
      when: ['all'],
      message: '주소를 입력해주세요.',
      validator: ({ baseAddress }) => !isEmpty(baseAddress),
    },
  ],
  detailAddress: [
    {
      when: ['all'],
      message: '주소를 입력해주세요.',
      validator: ({ detailAddress }) => !isEmpty(detailAddress),
    },
  ],
};

const useDeliveryLocation = ({ form, setForm, error, setCustomMessage, locationCnt, editingBase, isFromNew }) => {
  const changeFormValues = useEventCallback((v, data) => {
    setForm((prev) => ({ ...prev, [data.key]: v }));
  });
  const changeMessageDropdown = useCallback(
    (key, messageOption) => {
      setForm((prev) => {
        if (prev.deliveryMessage.key === 'custom') {
          setCustomMessage('');
        }
        return { ...prev, [key]: messageOption };
      });
    },
    [setCustomMessage, setForm]
  );

  const customMessageSelected = useMemo(() => {
    return form.deliveryMessage?.key === 'custom';
  }, [form.deliveryMessage]);

  const fields = useMemo(
    () => [
      {
        key: 'deliveryLocationName',
        value: form.deliveryLocationName,
        type: 'input',
        label: '배송지명',
        onChange: changeFormValues,
        fieldProps: {
          maxLength: 10,
          placeholder: '배송지명을 입력해 주세요',
          error: !!error.deliveryLocationName,
          errorMessage: error.deliveryLocationName,
        },
      },
      {
        key: 'receiverName',
        value: form.receiverName,
        type: 'input',
        label: '받는분',
        onChange: changeFormValues,
        fieldProps: {
          maxLength: 10,
          placeholder: '받는 분을 입력해 주세요',
          error: !!error.receiverName,
          errorMessage: error.receiverName,
        },
      },
      {
        key: 'cellphoneNumber',
        value: form.cellphoneNumber,
        type: 'input',
        label: '휴대전화번호',
        onChange: changeFormValues,
        fieldProps: {
          maxLength: 11,
          placeholder: '휴대전화번호를 입력해 주세요',
          type: 'number',
          error: !!error.cellphoneNumber,
          errorMessage: error.cellphoneNumber,
        },
      },
      {
        key: 'telephoneNumber',
        value: form.telephoneNumber,
        type: 'input',
        label: '일반전화번호',
        onChange: changeFormValues,
        required: false,
        fieldProps: {
          maxLength: 11,
          placeholder: '일반전화번호를 입력해 주세요',
          type: 'number',
          error: !!error.telephoneNumber,
          errorMessage: error.telephoneNumber,
        },
      },
      {
        key: 'address',
        type: 'custom',
        label: '주소',
        customRender: AddressInput,
        base: form.base,
        addNew: form.addNew,
        postNumber: form.postNumber,
        baseAddress: form.baseAddress,
        detailAddress: form.detailAddress,
        setForm,
        errorMessage: error.postNumber || error.baseAddress || error.detailAddress,
        locationCnt,
        editingBase,
        isFromNew,
      },
      {
        key: 'deliveryMessage',
        value: form.deliveryMessage,
        item: DELIVERY_MESSAGE_WITH_CUSTOM_VALUE,
        type: 'dropdown',
        label: '배송 메세지',
        onChange: changeMessageDropdown,
        required: false,
        fieldProps: { maxLength: 20, placeholder: '배송메시지를 선택해주세요', defaultValue: form.deliveryMessage },
      },
    ],
    [form, changeFormValues, changeMessageDropdown, error, locationCnt, setForm, editingBase, isFromNew]
  );

  return { fields, customMessageSelected };
};

export { useDeliveryLocation, formValidators };
