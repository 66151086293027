import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useIgnorableError from '@ecp/common/src/hooks/useIgnorableError';
import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import CheckboxUnstyled from '@ecp/common/src/components/unstyled/checkbox/CheckboxUnstyled';

export const StyledErrorMessage = styled.div`
  font-weight: 350;
  font-size: ${moTheme.font.size.$12};
  line-height: 17px;

  color: ${theme.color.text.error};
`;

const LabelContent = ({ children, ...labelProps }) => <TextBox {...labelProps}>{children}</TextBox>;

LabelContent.propTypes = {
  labelText: PropTypes.string,
  labelProps: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
};

const LabeledCheckBox = ({ labelText, labelProps, error, errorMessage, ...props }) => {
  const { ignoreError } = useIgnorableError({ error, value: props.checked });

  return (
    <FlexBoxColumn align-items={'flex-start'} gap={'6px'}>
      <CheckboxUnstyled
        imageProps={{ width: '22px', height: '22px' }}
        labelProps={{ children: labelText, ...labelProps }}
        label={LabelContent}
        {...props}
      />
      {!ignoreError && error && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </FlexBoxColumn>
  );
};

LabeledCheckBox.displayName = 'LabeledCheckBox';
export default LabeledCheckBox;

LabeledCheckBox.propTypes = {
  labelText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelProps: PropTypes.object,
  checked: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};
