import { useCallback, useState, useMemo, useEffect } from 'react';

const useOrderShippingInformation = ({
  orderDeliveryList,
  handleChangeOrderDeliveryList,
  handleChangeMultiDelivery,
  goodList,
  itemTotalCount,
  getGoodListWithMaxQuantity,
  getGoodListWithZeroQuantity,
  goodListWithZeroDeliveryQuantity,
}) => {
  const [deliveryNumber, setDeliveryNumber] = useState(1);
  const [selectedItem, setSelectedItem] = useState({});

  const dropdownList = useMemo(
    () =>
      [...Array(itemTotalCount > 40 ? 40 : itemTotalCount)].map((data, index) => ({
        label: `${index + 1}곳`,
        value: `${index + 1}`,
        key: `${index + 1}`,
      })),

    [itemTotalCount]
  );
  useEffect(() => {
    setSelectedItem(dropdownList[1]);
  }, [dropdownList]);

  const getIncreaseDeliveryPlaceList = useCallback(
    (newDeliveryNumber) => {
      if (newDeliveryNumber <= orderDeliveryList.length) return [...orderDeliveryList];
      return [...new Array(newDeliveryNumber)].map((item, idx) => {
        if (idx < orderDeliveryList.length) {
          return { ...orderDeliveryList[idx] };
        }
        return {
          deliveryPlace: { saveDefaultDeliveryMemo: true, noteOrdererName: true },
          goodList: goodListWithZeroDeliveryQuantity(goodList),
        };
      });
    },
    [goodList, goodListWithZeroDeliveryQuantity, orderDeliveryList]
  );

  const handleClickMultiDelivery = useCallback(
    (data) => {
      handleChangeMultiDelivery(data.target.checked);
      setSelectedItem(dropdownList[1]);
      setDeliveryNumber(data.target.checked ? 2 : 1);
      if (data.target.checked) {
        handleChangeOrderDeliveryList(getGoodListWithZeroQuantity(getIncreaseDeliveryPlaceList(2)), {
          refreshDeliveryCost: true,
          refreshAvailableQuantity: false,
        });
      } else {
        const newDeliveryList = [orderDeliveryList[0]];
        handleChangeOrderDeliveryList(getGoodListWithMaxQuantity(newDeliveryList), { refreshDeliveryCost: true });
      }
    },
    [
      handleChangeMultiDelivery,
      dropdownList,
      handleChangeOrderDeliveryList,
      getGoodListWithZeroQuantity,
      getIncreaseDeliveryPlaceList,
      orderDeliveryList,
      getGoodListWithMaxQuantity,
    ]
  );

  const handleChangeDeliveryNumber = useCallback(
    (data) => {
      setSelectedItem(data);

      const prevDeliveryNumber = deliveryNumber;
      const newDeliveryNumber = typeof data.value === 'string' ? Number(data.value) : data.value;
      setDeliveryNumber(newDeliveryNumber);

      if (newDeliveryNumber === prevDeliveryNumber) return;
      if (newDeliveryNumber > prevDeliveryNumber) {
        handleChangeOrderDeliveryList(getIncreaseDeliveryPlaceList(newDeliveryNumber), { refreshDeliveryCost: true });
      } else {
        const newOrderDeliveryList = orderDeliveryList.filter((item, idx) => idx < newDeliveryNumber);
        handleChangeOrderDeliveryList(newOrderDeliveryList, { refreshDeliveryCost: true });
      }
    },
    [deliveryNumber, getIncreaseDeliveryPlaceList, handleChangeOrderDeliveryList, orderDeliveryList]
  );

  const handleChangeDeliveryPlaceInfo = useCallback(
    (changeIdx, changeDeliveryInfo, { refreshDeliveryCost, refreshAvailableQuantity } = {}) => {
      const newOrderDeliveryList = orderDeliveryList.map((deliveryItem, deliveryIdx) => {
        if (changeIdx === deliveryIdx) return { ...deliveryItem, deliveryPlace: changeDeliveryInfo };
        return deliveryItem;
      });
      handleChangeOrderDeliveryList(newOrderDeliveryList, {
        refreshDeliveryCost,
        refreshAvailableQuantity,
        targetIdx: changeIdx,
      });
    },
    [handleChangeOrderDeliveryList, orderDeliveryList]
  );

  return {
    selectedItem,
    dropdownList,
    handleClickMultiDelivery,
    handleChangeDeliveryNumber,
    handleChangeDeliveryPlaceInfo,
  };
};

export default useOrderShippingInformation;
