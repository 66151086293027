import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import useEventCallback from '@ecp/common/src/hooks/useEventCallback';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Input from '@ecp/common/src/components/input/Input';
import Button from '@ecp/common/src/components/button/bo/Button';
import Dropdown from '@ecp/common/src/components/dropdown/Dropdown';
import { INICIS_PAY_TYPE_MOBILE } from '@ecp/common/src/const/interface/inicisPayType';
import { CARD_COMPANY_CODE } from '@ecp/common/src/const/interface/cardCompanyCode';

import MobilePayForm from '@mo-pages/interface/inicis/MobilePayForm';

const InicisPaySamplePage = () => {
  const [pay, setPay] = useState({
    price: 1000,
    orderNo: 'test-oid',
    goodName: 'test',
    buyerName: 'test',
    buyerTel: '02011111111',
    buyerEmail: 'test@test.com',
  });
  const [payType, setPayType] = useState(null);
  const handleChangeFormValues = useEventCallback(
    (v, data) => {
      setPay((prev) => ({ ...prev, [data.key]: v }));
    },
    { stop: true, prevent: true }
  );

  const [creditCardList] = useState(
    Object.entries(CARD_COMPANY_CODE).map((obj) => ({
      key: obj[1],
      value: obj[1],
      label: obj[0],
    }))
  );

  const [selectedCreditCard, setSelectedCreditCard] = useState(null);
  const handleChangeCard = useCallback(
    (selectedItem) => {
      pay.cardCompanyNo = selectedItem.value;
      setSelectedCreditCard(selectedItem);
    },
    [pay]
  );

  return (
    <Wrapper>
      <FlexBox width="100%">
        <InicisLabel>결제금액</InicisLabel>
        <Spacing top={10} />
        <Input data-key="price" value={pay.price} onChange={handleChangeFormValues}></Input>
      </FlexBox>
      <FlexBox width="100%">
        <InicisLabel>주문번호</InicisLabel>
        <Spacing top={10} />
        <Input data-key="orderNo" value={pay.orderNo} onChange={handleChangeFormValues}></Input>
      </FlexBox>
      <FlexBox width="100%">
        <InicisLabel>상품명</InicisLabel>
        <Spacing top={10} />
        <Input data-key="goodName" value={pay.goodName} onChange={handleChangeFormValues}></Input>
      </FlexBox>
      <FlexBox width="100%">
        <InicisLabel>구매자명</InicisLabel>
        <Spacing top={10} />
        <Input data-key="buyerName" value={pay.buyerName} onChange={handleChangeFormValues}></Input>
      </FlexBox>
      <FlexBox width="100%">
        <InicisLabel>구매자전화번호</InicisLabel>
        <Spacing top={10} />
        <Input data-key="buyerTel" value={pay.buyerTel} onChange={handleChangeFormValues}></Input>
      </FlexBox>
      <FlexBox width="100%">
        <InicisLabel>구매자이메일</InicisLabel>
        <Spacing top={10} />
        <Input data-key="buyerEmail" value={pay.buyerEmail} onChange={handleChangeFormValues}></Input>
      </FlexBox>
      <Spacing top={6} />
      <Dropdown
        placeholder="카드 종류를 선택해 주세요"
        items={creditCardList}
        value={selectedCreditCard}
        placement="top"
        onChange={handleChangeCard}
      />
      <Button onClick={() => setPayType(INICIS_PAY_TYPE_MOBILE.CARD)}>카드</Button>
      <Button onClick={() => setPayType(INICIS_PAY_TYPE_MOBILE.SAMSUNG_PAY)}>삼성페이</Button>
      <Button onClick={() => setPayType(INICIS_PAY_TYPE_MOBILE.L_PAY)}>L.Pay</Button>
      <Button onClick={() => setPayType(INICIS_PAY_TYPE_MOBILE.SSG_PAY)}>SSG 페이</Button>
      <Button onClick={() => setPayType(INICIS_PAY_TYPE_MOBILE.KAKAO_PAY)}>카카오페이</Button>
      <Button onClick={() => setPayType(INICIS_PAY_TYPE_MOBILE.TOSS_PAY)}>토스</Button>
      <Button onClick={() => setPayType(INICIS_PAY_TYPE_MOBILE.NAVER_PAY)}>네이버페이</Button>
      <Button onClick={() => setPayType(INICIS_PAY_TYPE_MOBILE.V_BANK)}>가상계좌결재</Button>
      <Button onClick={() => setPayType(INICIS_PAY_TYPE_MOBILE.HPP)}>HPP</Button>
      <MobilePayForm data={pay} payType={payType} />
    </Wrapper>
  );
};

export default InicisPaySamplePage;

const Wrapper = styled(FlexBox)`
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const InicisLabel = styled(TextBox)`
  font-size: ${moTheme.font.size.$14};
  font-weight: ${moTheme.font.weight.medium};
  width: 100%;
`;
