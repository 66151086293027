import React, { useCallback, useEffect, useState, useRef, useImperativeHandle } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';

import { withCategorySubList } from '@mo-recoil/category/selector';
import CategorySwiperLabel from '@mo-components/display/CategorySwiperLabel';

const defaultLargeCategory = {
  closable: false,
  type: 'default',
  checked: true,
  label: '전체',
  value: 'ALL',
};

const titleChip = {
  closable: false,
  type: 'title',
  label: '카테고리',
};

const CategorySwiper = React.forwardRef(
  ({ onChange, useAll, categoryNumber, selectedCategoryNumber, hasNewGood, onArrowClick, ...props }, ref) => {
    const swiperRef = useRef(null);

    useImperativeHandle(ref, () => swiperRef?.current);

    const categoryList = useRecoilValue(withCategorySubList(categoryNumber));
    const [categoryChip, setCategoryChip] = useState([defaultLargeCategory]);

    const handleChipClick = useCallback(
      ({ value, checked }) => {
        if (checked) {
          return;
        }

        setCategoryChip((prev) => {
          return prev.map((chip) => ({ ...chip, checked: chip.value === value }));
        });
        onChange?.(value);
      },
      [onChange]
    );

    useEffect(() => {
      const chipList = categoryList
        .filter((category) => (hasNewGood ? !!category.hasNewGood : true))
        .map(({ categoryName, categoryNumber }) => ({
          ...defaultLargeCategory,
          checked: categoryNumber === selectedCategoryNumber,
          label: categoryName,
          value: categoryNumber,
        }));
      const useAllChipList = [
        { ...defaultLargeCategory, checked: !selectedCategoryNumber || selectedCategoryNumber === 'ALL' },
        ...chipList,
      ];
      setCategoryChip(useAll ? useAllChipList : [titleChip, ...chipList]);
    }, [categoryList, hasNewGood, selectedCategoryNumber, useAll]);

    useEffect(() => {
      if (!selectedCategoryNumber) {
        swiperRef?.current?.swiper?.slideTo(0);
      }
    }, [selectedCategoryNumber]);

    useEffect(() => {
      if (selectedCategoryNumber && selectedCategoryNumber !== 'ALL') {
        const index = categoryChip.findIndex(({ value }) => value === selectedCategoryNumber);
        swiperRef?.current?.swiper?.slideTo(index);
      }
    }, [categoryChip, selectedCategoryNumber]);

    return (
      <CategorySwiperLabel
        swiperRef={swiperRef}
        itemList={categoryChip}
        onChipClick={handleChipClick}
        onArrowClick={onArrowClick}
        {...props}
      />
    );
  }
);

CategorySwiper.displayName = 'CategorySwiper';

export default CategorySwiper;

CategorySwiper.propTypes = {
  onChange: PropTypes.func,
  useAll: PropTypes.bool,
  categoryNumber: PropTypes.string,
  selectedCategoryNumber: PropTypes.string,
  hasNewGood: PropTypes.bool,
  onArrowClick: PropTypes.func,
};

CategorySwiper.defaultProps = {
  categoryNumber: null,
  useAll: true,
};
