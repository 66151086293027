import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isEmpty from 'lodash-es/isEmpty';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { PD_GOOD_TYPE } from '@ecp/common/src/const/good';

import MultiDeliveryItem from '@mo-components/order/payment/MultiDeliveryItem';

const MultiDeliveryPlace = ({ orderDeliveryList, handleChangeOrderDeliveryList, handleChangeDeliveryPlaceInfo }) => {
  const handleChangeQuantity = useCallback(
    (deliveryPlaceIndex, goodNumber, itemNumber, deliveryQuantity) => {
      const newDeliveryList = orderDeliveryList.map((deliveryItem, deliveryIndex) => {
        if (deliveryIndex !== deliveryPlaceIndex) return { ...deliveryItem };
        const newGoodList = deliveryItem.goodList.map((goodItem) => {
          if (goodItem.goodNumber !== goodNumber) return { ...goodItem };
          const newItemList = goodItem.itemList.map((item) => {
            if (item.itemNumber !== itemNumber) return { ...item };
            return { ...item, deliveryQuantity: deliveryQuantity };
          });
          return { ...goodItem, itemList: newItemList };
        });
        return { ...deliveryItem, goodList: newGoodList };
      });
      handleChangeOrderDeliveryList(
        newDeliveryList,
        {
          targetIdx: deliveryPlaceIndex,
          refreshAvailableQuantity: true,
          refreshDeliveryCost: true,
        },
        deliveryPlaceIndex
      );
    },
    [handleChangeOrderDeliveryList, orderDeliveryList]
  );

  return (
    !isEmpty(orderDeliveryList) && (
      <Container>
        {orderDeliveryList.map((orderDelivery, idx) => {
          const hasDirectForeignGood = orderDelivery?.goodList?.some((goodItem) => {
            return goodItem?.itemList?.some(
              (item) => item.deliveryQuantity && item.goodTypeCode === PD_GOOD_TYPE.OVERSEAS
            );
          });
          return (
            <MultiDeliveryItem
              key={idx}
              orderDelivery={orderDelivery}
              deliveryPlaceIndex={idx}
              isDirectForeignGood={hasDirectForeignGood}
              handleChangeQuantity={handleChangeQuantity}
              handleChangeDeliveryPlaceInfo={handleChangeDeliveryPlaceInfo}
            />
          );
        })}
      </Container>
    )
  );
};

export default MultiDeliveryPlace;

MultiDeliveryPlace.propTypes = {
  orderDeliveryList: PropTypes.array,
  handleChangeOrderDeliveryList: PropTypes.func,
  handleChangeDeliveryPlaceInfo: PropTypes.func,
};

MultiDeliveryPlace.defaultProps = {
  orderDeliveryList: [],
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
  gap: 20px;
`;
