import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import FullPagePopup from '@ecp/common/src/components/modal/mo/popup/FullPagePopup';
import { color } from '@ecp/common/src/style/theme/default';

import authApi from '@mo-apis/common/authApi';
import ChangePassword from '@mo-components/member/ChangePassword';
import { LOGIN_TYPE } from '@mo-const/member/memberConst';

const ChangeOldPasswordPopup = ({
  open,
  onClose,
  loginId,
  loginType,
  mobiliansValue,
  onChangeLaterClicked,
  onChangeNowClicked,
  serviceName,
  ssoToken,
}) => {
  const [userId, setUserId] = useState('');

  useEffect(() => {
    if (open) {
      (async () => {
        if (loginType === LOGIN_TYPE.ID && !!loginId) {
          setUserId(loginId);
        } else if (loginType === LOGIN_TYPE.MOBILE && Object?.keys(mobiliansValue)?.length > 0) {
          const { result } = await authApi.getLoginIdByCi(mobiliansValue);
          setUserId(result);
        } else if (loginType === LOGIN_TYPE.OUTSITE_SSO) {
          const { result } = await authApi.getLoginIdByOutsiteSsoToken({ serviceName: serviceName, ssoToken: ssoToken });
          setUserId(result);
        }
      })();
    }
  }, [mobiliansValue, loginId, loginType, open]);

  const handleClickConfirmButton = useCallback(
    async (password, newPassword) => {
      if (onChangeNowClicked) {
        await onChangeNowClicked(userId, password, newPassword);
      }
    },
    [onChangeNowClicked, userId]
  );

  const handleClickCancelButton = useCallback(async () => {
    if (onChangeLaterClicked) {
      await onChangeLaterClicked(userId);
    }
  }, [onChangeLaterClicked, userId]);

  return (
    <FullPagePopup
      width={'100%'}
      open={open}
      onClose={onClose}
      header={'비밀번호 변경'}
      useCancelButton={false}
      useConfirmButton={false}
    >
      <FlexBoxColumn width={'100%'} align-items={'flex-start'}>
        <Spacing top={40} />
        <FlexBox>
          <SubTitle>고객님은 현재</SubTitle>
          <SubTitle color={color.primary}> 90일 이상,</SubTitle>
        </FlexBox>
        <Spacing top={10} />
        <FlexBox>
          <SubTitle color={color.primary}>같은 비밀번호를 사용</SubTitle>
          <SubTitle>하고 계십니다.</SubTitle>
        </FlexBox>
        <Spacing top={10} />
        <TextBox
          color={'#5a5a5a'}
        >{`고객님의 소중한 개인정보보호를 위해 주기적으로\n비밀번호를 변경하여 주십시오.\n(동일한 비밀번호로 변경 불가)`}</TextBox>
        <Spacing top={30} />
        <HorizontalDivider color={'#141415'} />
        <Spacing top={20} />
        <ChangePassword
          type={'over90days'}
          userId={userId}
          onCancelButtonClick={handleClickCancelButton}
          updatePassword={handleClickConfirmButton}
        />
      </FlexBoxColumn>
    </FullPagePopup>
  );
};

export default ChangeOldPasswordPopup;

ChangeOldPasswordPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  loginId: PropTypes.string,
  loginType: PropTypes.string,
  mobiliansValue: PropTypes.object,
  onChangeNowClicked: PropTypes.func,
  onChangeLaterClicked: PropTypes.func,
  serviceName: PropTypes.string,
  ssoToken: PropTypes.string,
};

const SubTitle = styled(TextBox)`
  font-weight: 500;
  font-size: 18px;
`;
