import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { format } from 'date-fns';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import useCouponSelection from '@ecp/common/src/hooks/order/payment/useCouponSelection';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import FullPagePopup from '@ecp/common/src/components/modal/mo/popup/FullPagePopup';
import LabeledCheckBox from '@ecp/common/src/components/checkbox/mo/LabeledCheckBox';
import DropdownDevice from '@ecp/common/src/components/dropdown/mo/DropdownDevice';
import { RectanglePageButton } from '@ecp/common/src/components/button/mo/Button';

import OrderGoodCouponSelection from '@mo-components/order/payment/OrderGoodCouponSelection';
import { ReactComponent as PlusIcon } from '@mo-assets/icon/icon__plus.svg';
import { ReactComponent as EqualIcon } from '@mo-assets/icon/icon__equal.svg';
import theme from '@ecp/common/src/style/theme/default';

const CouponChangePopup = ({
  open,
  onClose,
  goodCouponList: prevGoodCouponList,
  cartCouponList: prevCartCouponList,
  couponList: prevCouponList,
  goodPriceList: prevGoodPriceList,
  onChange,
  deliveryCostCoupon,
  paymentMethod,
  simpleCouponApply: prevSimpleCouponApply,
}) => {
  const [init, setInit] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [goodCouponList, setGoodCouponList] = useState([]);
  const [cartCouponList, setCartCouponList] = useState([]);
  const [goodPriceList, setGoodPriceList] = useState([]);
  const [discountAmount, setDiscountAmount] = useState({});
  const [applyDeliveryCostCoupon, setApplyDeliveryCostCoupon] = useState({});
  const [simpleCouponApply, setSimpleCouponApply] = useState(false);
  const { handleSelectCoupon, handleSimpleApply, handleInitCoupon } = useCouponSelection({
    couponList,
    setCouponList,
    goodCouponList,
    setGoodCouponList,
    cartCouponList,
    setCartCouponList,
    goodPriceList,
    setGoodPriceList,
    setDiscountAmount,
    paymentMethod,
    simpleCouponApply,
    setSimpleCouponApply,
    setDeliveryCostCoupon: setApplyDeliveryCostCoupon,
    enableCouponUsage: true,
  });

  useEffect(() => {
    if (open) {
      setCouponList([...prevCouponList]);
      setGoodCouponList([...prevGoodCouponList]);
      setCartCouponList([...prevCartCouponList]);
      setGoodPriceList([...prevGoodPriceList]);
      setApplyDeliveryCostCoupon(deliveryCostCoupon);
      setSimpleCouponApply(prevSimpleCouponApply);
      setInit(true);
    }
  }, [
    prevCouponList,
    prevGoodCouponList,
    prevCartCouponList,
    prevGoodPriceList,
    open,
    deliveryCostCoupon,
    prevSimpleCouponApply,
  ]);

  useEffect(() => {
    if (init) {
      setInit(false);
      handleInitCoupon();
    }
  }, [handleInitCoupon, init]);

  const cartCouponItems = useMemo(() => {
    if (Array.isArray(cartCouponList) && cartCouponList.length > 0) {
      const dropdownList = cartCouponList.map((coupon) => ({
        key: coupon.resourceManagementNo,
        label: coupon.endDateTime
          ? `${coupon.promotionName}(~${format(new Date(coupon.endDateTime), 'MM.dd')})`
          : `${coupon.promotionName}`,
        value: coupon.resourceManagementNo,
        ...coupon,
      }));
      return [{ key: '', label: '선택하세요', value: '' }, ...dropdownList];
    }
    return [];
  }, [cartCouponList]);

  const selectedCartCoupon = useMemo(() => {
    const selectedCoupon = couponList.find((coupon) => coupon.isSelect && '022' === coupon.promotionTypeCode);
    if (selectedCoupon) {
      const selectedItem = cartCouponList.find(
        (coupon) => coupon.resourceManagementNo === selectedCoupon.resourceManagementNo
      );
      return {
        key: selectedItem.resourceManagementNo,
        label: selectedItem.endDateTime
          ? `${selectedItem.promotionName}(~${format(new Date(selectedItem.endDateTime), 'MM.dd')})`
          : `${selectedItem.promotionName}`,
        value: selectedItem.resourceManagementNo,
        ...selectedItem,
      };
    }
    return { key: '', label: '선택하세요', value: '' };
  }, [cartCouponList, couponList]);

  const cartCouponItemList = useMemo(
    () => selectedCartCoupon.applyItemList?.map(({ goodNumber, itemNumber }) => `${goodNumber}-${itemNumber}`),
    [selectedCartCoupon]
  );

  const handleSelectCouponDropdown = useCallback(
    (item) => {
      handleSelectCoupon(item, true);
    },
    [handleSelectCoupon]
  );

  const clickApplyDeliveryCostCoupon = useCallback(({ target: { checked } }) => {
    setApplyDeliveryCostCoupon((prev) => ({ ...prev, applyDeliveryCostCoupon: checked }));
    if (!checked) setSimpleCouponApply(false);
  }, []);

  const handleClickConfirm = useCallback(() => {
    onChange(couponList, applyDeliveryCostCoupon, simpleCouponApply);
    onClose();
  }, [applyDeliveryCostCoupon, couponList, onChange, onClose, simpleCouponApply]);

  const handleClickApplySimpleDiscount = useCallback(
    (e) => {
      handleSimpleApply(e.target.checked);
    },
    [handleSimpleApply]
  );

  return (
    <FullPagePopup
      open={open}
      onClose={onClose}
      header={'쿠폰 변경'}
      useCancelButton={false}
      useConfirmButton={false}
      bottomButtonComponent={
        <CouponPopupBottom
          discountAmount={discountAmount}
          onCancelClick={onClose}
          onConfirmClick={handleClickConfirm}
        />
      }
    >
      <Container>
        <LabeledCheckBox
          labelText={'간편 할인 적용'}
          checked={simpleCouponApply}
          onChange={handleClickApplySimpleDiscount}
        />
        <Spacing top={10} />
        <HorizontalDivider color={'#141415'} />
        {applyDeliveryCostCoupon?.isUseDeliveryCostCoupon && (
          <>
            <Spacing top={20} />
            <LabeledCheckBox
              labelText={'배송지 쿠폰적용'}
              checked={applyDeliveryCostCoupon?.applyDeliveryCostCoupon}
              onChange={clickApplyDeliveryCostCoupon}
            />
          </>
        )}

        <Spacing top={20} />

        <CouponContainer>
          <CouponTitleTextBox>상품 쿠폰</CouponTitleTextBox>
          <Spacing top={14} />
          <FlexBoxColumn width="100%" gap="20px">
            {Array.isArray(goodCouponList) &&
              goodCouponList.map((goodItem, idx) => (
                <OrderGoodCouponSelection
                  key={idx}
                  goodItem={goodItem}
                  onChange={handleSelectCoupon}
                  excludeCoupon={
                    cartCouponItemList && !cartCouponItemList.includes(`${goodItem.goodNumber}-${goodItem.itemNumber}`)
                  }
                  couponList={couponList}
                />
              ))}
          </FlexBoxColumn>
        </CouponContainer>
        <Spacing top={30} />
        <CouponContainer>
          <CouponTitleTextBox>장바구니 쿠폰</CouponTitleTextBox>
          <Spacing top={14} />
          <DropdownDevice
            width="100%"
            items={cartCouponItems}
            disabled={!cartCouponItems.length}
            placeholder={!cartCouponItems.length ? '선택 가능한 쿠폰이 없습니다.' : '적용할 쿠폰을 선택하세요.'}
            value={selectedCartCoupon}
            onChange={handleSelectCouponDropdown}
            title={'장바구니 쿠폰'}
          />
        </CouponContainer>
      </Container>
      <Spacing top={30} />
    </FullPagePopup>
  );
};

const CouponPopupBottom = ({ discountAmount, onCancelClick, onConfirmClick }) => {
  return (
    <FlexBoxColumn width="100%">
      <SummaryContainer>
        <DiscountAmountContainer>
          <DiscountAmountTitleTextBox>상품 총 할인금액</DiscountAmountTitleTextBox>
          <FlexBox>
            <DiscountAmountTextBox>{NumberFormat(discountAmount?.goodCouponDiscount)}</DiscountAmountTextBox>
            <DiscountAmountUnitTextBox>원</DiscountAmountUnitTextBox>
          </FlexBox>
        </DiscountAmountContainer>
        <PlusIcon />
        <DiscountAmountContainer>
          <DiscountAmountTitleTextBox>장바구니 총 할인금액</DiscountAmountTitleTextBox>
          <FlexBox>
            <DiscountAmountTextBox>{NumberFormat(discountAmount?.cartCouponDiscount)}</DiscountAmountTextBox>
            <DiscountAmountUnitTextBox>원</DiscountAmountUnitTextBox>
          </FlexBox>
        </DiscountAmountContainer>
        <EqualIcon />
        <DiscountAmountContainer>
          <DiscountAmountTitleTextBox total>총 할인금액</DiscountAmountTitleTextBox>
          <FlexBox>
            <DiscountAmountTextBox total>{NumberFormat(discountAmount?.total)}</DiscountAmountTextBox>
            <DiscountAmountUnitTextBox total>원</DiscountAmountUnitTextBox>
          </FlexBox>
        </DiscountAmountContainer>
      </SummaryContainer>
      <FlexBoxSpaceBetween width="100%">
        <RectanglePageButton type="Secondary" onClick={onCancelClick}>
          취소
        </RectanglePageButton>
        <RectanglePageButton type="Primary" onClick={onConfirmClick}>
          쿠폰적용
        </RectanglePageButton>
      </FlexBoxSpaceBetween>
    </FlexBoxColumn>
  );
};

CouponPopupBottom.propTypes = {
  discountAmount: PropTypes.object,
  onCancelClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
};

CouponChangePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  goodCouponList: PropTypes.array,
  cartCouponList: PropTypes.array,
  couponList: PropTypes.array,
  goodPriceList: PropTypes.array,
  onChange: PropTypes.func,
  deliveryCostCoupon: PropTypes.object,
  paymentMethod: PropTypes.object,
  simpleCouponApply: PropTypes.bool,
};

const Container = styled(FlexBoxColumn)`
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;

const CouponContainer = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const CouponTitleTextBox = styled(TextBox)`
  font-weight: 700;
  font-size: 16px;
`;

const SummaryContainer = styled(FlexBox)`
  width: 100%;
  height: 59px;
  justify-content: center;
  border-top: 1px solid #eaeaea;
  padding: 20px 0;
  gap: 7px;
`;

const DiscountAmountContainer = styled(FlexBoxColumn)``;

const DiscountAmountTitleTextBox = styled(TextBox).attrs(({ total }) => {
  return total && { color: theme.color.primary };
})`
  font-weight: 350;
  font-size: 11px;
`;
const DiscountAmountTextBox = styled(TextBox).attrs(({ total }) => {
  return total && { color: theme.color.primary };
})`
  font-weight: 700;
  font-size: 14px;
`;
const DiscountAmountUnitTextBox = styled(TextBox).attrs(({ total }) => {
  return total && { color: theme.color.primary };
})`
  font-weight: 350;
  font-size: 12px;
`;

export default CouponChangePopup;
