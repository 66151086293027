import React, { useMemo } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxCenter, FlexBoxColumn, ImageBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import StarRatingIcon from '@ecp/common/src/components/icon/StarRatingIcon';
import { BADGE_TYPE, PD_EVALUATION_DIVI } from '@ecp/common/src/const/good';
import adultPath from '@ecp/common/src/assets/icon/icon__adult-certification.svg';

import { withAdultCertification } from '@mo-recoil/common/user/selector';
import { historyState } from '@mo-recoil/common/atom';
import {useAlertDialog, useConfirmDialog} from '@mo-hooks/common/useModal';
import recopickApi from '@mo-apis/interface/recopickApi';
import memberWishApi from '@mo-apis/member/memberWishApi';
import GoodBenefitChip from '@mo-components/good/GoodBenefitChip';
import GoodBadge from '@mo-components/good/GoodBadge';
import { cardProps, goodCardPropTypes } from '@mo-const/good/goodCardConst';
import { ReactComponent as DoubleQuotation } from '@mo-assets/icon/icon__review__double-quotation.svg';
import goodApi from "@mo-apis/good/goodApi";

const MAX_COUNT = 999;

const GoodOriginalPrice = ({ type, originalPrice, discountPrice }) => {
  const showOriginalPrice = useMemo(
    () => !!originalPrice && originalPrice > discountPrice,
    [discountPrice, originalPrice]
  );

  return (
    <>
      {showOriginalPrice && (
        <TextBox
          decoration={'line-through'}
          hoverDecoration={'line-through'}
          size={cardProps[type].size.originalPrice}
          color={theme.color.text.disabled}
          height={moTheme.font.lineHeight.bold}
        >
          <BlindSpan>정가</BlindSpan>
          {Number(originalPrice).toLocaleString()}원
        </TextBox>
      )}
    </>
  );
};

GoodOriginalPrice.propTypes = {
  type: PropTypes.string,
  originalPrice: PropTypes.number,
  discountPrice: PropTypes.number,
};

const BlindSpan = styled.span`
  over-flow: hidden;
  position: absolute;
  clip: rect(0, 0, 0, 0);
  clip-path: polygon(0 0, 0 0, 00);
  width: 1px;
  height: 1px;
  margin: -1px;
`;

export const GoodPriceBox = ({
  type,
  originalPrice,
  discountRate,
  discountPrice,
  'align-items': alignItems = 'flex-start',
}) => {
  const isOriginalPricePositionTop = useMemo(() => 'horizontalSmall' !== type, [type]);

  return (
    <FlexBoxColumn align-items={alignItems} width={'100%'}>
      {isOriginalPricePositionTop && (
        <GoodOriginalPrice type={type} originalPrice={originalPrice} discountPrice={discountPrice} />
      )}
      <FlexBox align-items={'baseline'} flex-wrap={'wrap'}>
        {!!discountRate && (
          <FlexBox>
            <TextBox
              height={'fit-content'}
              align-items={'baseline'}
              size={cardProps[type].size.discountRate}
              weight={moTheme.font.weight.bold}
              color={theme.color.primary}
            >
              <BlindSpan>할인율</BlindSpan>
              {discountRate}
              <TextBox size={cardProps[type].size.percent} weight={moTheme.font.weight.bold}>
                %
              </TextBox>
            </TextBox>
            <Spacing left={'6px'} />
          </FlexBox>
        )}
        <TextBox
          align-items={'baseline'}
          size={cardProps[type].size.discountPrice}
          weight={moTheme.font.weight.bold}
          height={moTheme.font.lineHeight.bold}
        >
          <BlindSpan>판매가</BlindSpan>
          {Number(discountPrice).toLocaleString()}
          <TextBox size={cardProps[type].size.won} height={moTheme.font.lineHeight.bold}>
            원
          </TextBox>
        </TextBox>
        <Spacing left={'6px'} />
        {!isOriginalPricePositionTop && (
          <GoodOriginalPrice type={type} originalPrice={originalPrice} discountPrice={discountPrice} />
        )}
      </FlexBox>
    </FlexBoxColumn>
  );
};

const GoodPriceBoxPropTypes = {
  originalPrice: PropTypes.number,
  discountRate: PropTypes.number,
  discountPrice: PropTypes.number,
  type: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large', 'horizontalSmall', 'horizontalMedium', 'review']),
  'align-items': PropTypes.string,
};
GoodPriceBox.propTypes = GoodPriceBoxPropTypes;

export const GoodImage = ({ adultGood, soldOut, type, imgPath }) => {
  const isAdultVerified = useRecoilValue(withAdultCertification) || !adultGood;
  // const showAdultMessage = useMemo(() => !isAdultVerified && !!cardProps[type].size.adult, [isAdultVerified, type]);

  return (
    <FlexBox>
      <ImageBox
        width={cardProps[type].imageWidth}
        height={cardProps[type].imageWidth}
        imageSrc={imgPath}
        radius={'6px'}
        border={`1px solid ${theme.color.line.basic}`}
        backgroundPositionY={'center'}
        justify-content={'center'}
        align-items={'center'}
      >
        {!isAdultVerified && (
          <ImageBox
            clickable
            width={cardProps[type].imageWidth}
            height={cardProps[type].imageWidth}
            imageSrc={adultPath}
            backgroundPositionY={'center'}
            backgroundPositionX={'center'}
            justify-content={'center'}
            align-items={'center'}
          ></ImageBox>
        )}
        {/* <TextBox size={cardProps[type].size.adult} padding={'62px 0 0 0'} height={'16px'}>
          {!isAdultVerified ? '19세 이상만 구매 가능' : ''}
        </TextBox> */}
      </ImageBox>
      {soldOut && (
        <SoldOut height={cardProps[type]?.soldOutHeight}>
          <TextBox color={theme.color.text.white} size={moTheme.font.size.$12} weight={moTheme.font.weight.medium}>
            품절
          </TextBox>
        </SoldOut>
      )}
    </FlexBox>
  );
};

const SoldOut = styled(FlexBoxCenter)`
  position: absolute;
  background-color: rgba(20, 20, 21, 0.5);
  width: 100%;
  height: ${({ height }) => height};
  bottom: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

GoodImage.propTypes = {
  adultGood: PropTypes.bool,
  soldOut: PropTypes.bool,
  type: PropTypes.string,
  imgPath: PropTypes.string,
};

export const GoodCardImage = ({ onClick, handleHotTopAllCategorySoldOutChangeList, handleViewGoodSoldOutChangeList, handleOrderGoodSoldOutChangeList, selectedCategoryNumber, handelBestViewGoodSoldOutChangeList, handleBestOrderGoodSoldOutChangeList, handleSubDisplayCategoryGoodSoldOutChangeList, ...props }) => {
  const {
    thumbnailImageUrl02,
    thumbnailImageUrl03,
    itemDecorators,
    type,
    goodNumber,
    adultGood,
    wish,
    soldOut,
    showRelated,
    clickLogLink,
    onWishClick,
    goodName,
    isEventMall,
  } = props;

  const history = useRecoilValue(historyState);

  const imgPath = useMemo(() => {
    switch (type) {
      case 'large':
        return thumbnailImageUrl02;
      case 'extraSmall':
      case 'small':
      case 'medium':
      case 'horizontalSmall':
      case 'horizontalMedium':
      case 'review':
      default:
        return thumbnailImageUrl03;
    }
  }, [thumbnailImageUrl02, thumbnailImageUrl03, type]);

  const decorators = useMemo(() => {
    const itemDecoratorList = Array.isArray(itemDecorators) ? itemDecorators : [];
    return [
      ...itemDecoratorList,
      { type: BADGE_TYPE.RELATED, badgeContent: showRelated },
      !isEventMall ? { type: BADGE_TYPE.FAVORITE, badgeContent: wish } : { type: null, badgeContent: null },
    ];
  }, [itemDecorators, showRelated, wish]);

  const { showConfirmDialog } = useConfirmDialog();
  const navigate = useNavigate();

  const getChangeHandler = (type, onChange) => {
    switch (type) {
      case BADGE_TYPE.FAVORITE:
        return async (wish) => {
          const { result: isWish } = await memberWishApi.setWish({ goodNumber, wish });
          if (isWish) {
            showConfirmDialog(
              '선택하신 상품이 위시리스트에 담겼습니다. 계속 쇼핑하시겠습니까?',
              () => {
                navigate('/my-page/wishlist');
                onWishClick?.();
              },
              () => {},
              '위시 가기',
              '계속 쇼핑하기'
            );
            if (goodNumber) {
              await recopickApi.createRecommendationItemLog('like', { goodNumber }, history?.prev, history?.current);
            }
          }
        };
      default:
        return onChange;
    }
  };

  const { handleClick } = useGoodCardClick({ onClick, clickLogLink, props,handleHotTopAllCategorySoldOutChangeList, handleViewGoodSoldOutChangeList, handleOrderGoodSoldOutChangeList, selectedCategoryNumber, handelBestViewGoodSoldOutChangeList, handleBestOrderGoodSoldOutChangeList, handleSubDisplayCategoryGoodSoldOutChangeList,});

  return (
    <FlexBox width={cardProps[type].imageWidth} height={cardProps[type].imageWidth} onClick={handleClick}>
      {decorators.reduce(
        (accumulator, item) => (
          <GoodBadge
            imageWidth={cardProps[type].imageWidth}
            {...item}
            onChange={getChangeHandler(item?.type, item?.onChange)}
            title={goodName}
          >
            {accumulator}
          </GoodBadge>
        ),
        <GoodImage adultGood={adultGood} soldOut={soldOut} type={type} imgPath={imgPath} />
      )}
    </FlexBox>
  );
};

GoodCardImage.propTypes = {
  ...goodCardPropTypes,
};

const useGoodCardClick = ({ onClick, clickLogLink, props, handleHotTopAllCategorySoldOutChangeList, handleViewGoodSoldOutChangeList, handleOrderGoodSoldOutChangeList, handelBestViewGoodSoldOutChangeList, handleBestOrderGoodSoldOutChangeList, handleSubDisplayCategoryGoodSoldOutChangeList,}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showAlertDialog } = useAlertDialog();
  const { goodNumber } = props;
  const handleClick = async (e) => {
    e.stopPropagation();

    if (onClick) {
      onClick(e, goodNumber);
    } else {
      if (clickLogLink) {
        const url = new URL(clickLogLink);
        const queryParams = new URLSearchParams(url?.search);
        const { code } = await goodApi.getStockCheck(goodNumber);

        if(code) {
          showAlertDialog("죄송합니다.\n해당 상품은 품절되었습니다.", () => {
            if(handleHotTopAllCategorySoldOutChangeList !== undefined) {  // main 구매Top, 조회Top
              handleHotTopAllCategorySoldOutChangeList();
            } else if(handleViewGoodSoldOutChangeList!== undefined) { // main 조회Top 하단 카테고리
              handleViewGoodSoldOutChangeList(state?.categoryNumber);
            } else if(handleOrderGoodSoldOutChangeList !== undefined) { // main 구매Top 하단 카테고리
              handleOrderGoodSoldOutChangeList(state?.categoryNumber);
            } else if(handelBestViewGoodSoldOutChangeList !== undefined) {  // best 조회Top
              handelBestViewGoodSoldOutChangeList(state?.categoryNumber);
            } else if(handleBestOrderGoodSoldOutChangeList !== undefined) { // best 구매Top
              handleBestOrderGoodSoldOutChangeList(state?.categoryNumber);
            } else if(handleSubDisplayCategoryGoodSoldOutChangeList !== undefined) {  // 서브 카테고리
              handleSubDisplayCategoryGoodSoldOutChangeList();
            }
          });
        } else {
          navigate(`/good/${goodNumber}?recopick=${queryParams.get('method')}&product_type=R`);
        }
      } else {
        const { code } = await goodApi.getStockCheck(goodNumber);
        if(code) {
          showAlertDialog("죄송합니다.\n해당 상품은 품절되었습니다.", () => {
            if(handleHotTopAllCategorySoldOutChangeList !== undefined) {  // main 구매Top, 조회Top
              handleHotTopAllCategorySoldOutChangeList();
            } else if(handleViewGoodSoldOutChangeList!== undefined) { // main 조회Top 하단 카테고리
              handleViewGoodSoldOutChangeList(state?.categoryNumber);
            } else if(handleOrderGoodSoldOutChangeList !== undefined) { // main 구매Top 하단 카테고리
              handleOrderGoodSoldOutChangeList(state?.categoryNumber);
            } else if(handelBestViewGoodSoldOutChangeList !== undefined) {  // best 조회Top
              handelBestViewGoodSoldOutChangeList(state?.categoryNumber);
            } else if(handleBestOrderGoodSoldOutChangeList !== undefined) { // best 구매Top
              handleBestOrderGoodSoldOutChangeList(state?.categoryNumber);
            } else if(handleSubDisplayCategoryGoodSoldOutChangeList !== undefined) { // 서브 카테고리
              handleSubDisplayCategoryGoodSoldOutChangeList();
            }
          });
        } else {
          navigate(`/good/${goodNumber}`);
        }
      }
    }
    if (clickLogLink) {
      await recopickApi.createRecommendationClickLog(clickLogLink);
    }
  };
  return { handleClick };
};

const GoodCard = ({ onClick, handleHotTopAllCategorySoldOutChangeList, handleViewGoodSoldOutChangeList, handleOrderGoodSoldOutChangeList, selectedCategoryNumber, handelBestViewGoodSoldOutChangeList, handleBestOrderGoodSoldOutChangeList, handleSubDisplayCategoryGoodSoldOutChangeList, ...props }) => {
  const {
    goodName,
    originalPrice,
    discountRate,
    discountPrice,
    totalReviewScore,
    reviewCount,
    buyCount = 0,
    flagList,
    hideBestFlag,
    type,
    review,
    onReviewClick,
    buyCountDisplay,
    evaluationDivisionCode,
    clickLogLink,
    goodNumber,
    isEventMall,
    isPriceOpen,
  } = props;

  const priceBoxProps = {
    type,
    originalPrice,
    discountRate,
    discountPrice,
  };
  const imageSpacing = useMemo(
    () => ({ left: type.includes('horizontal') ? '14px' : '10px', top: type.includes('horizontal') ? '0px' : '10px' }),
    [type]
  );

  const filteredFlagList = useMemo(() => {
    return flagList?.filter((it) => !(hideBestFlag && it === 'BEST'));
  }, [flagList, hideBestFlag]);
  const { handleClick } = useGoodCardClick({ onClick, clickLogLink, props, handleHotTopAllCategorySoldOutChangeList, handleViewGoodSoldOutChangeList, handleOrderGoodSoldOutChangeList, selectedCategoryNumber, handelBestViewGoodSoldOutChangeList, handleBestOrderGoodSoldOutChangeList, handleSubDisplayCategoryGoodSoldOutChangeList });

  const handleReviewClick = (e) => {
    e.stopPropagation();
    onReviewClick?.(review);
  };

  const displayReviewCount = useMemo(() => {
    return reviewCount > MAX_COUNT ? `${MAX_COUNT}+` : reviewCount;
  }, [reviewCount]);

  const displayBuyCount = useMemo(() => {
    return buyCount > MAX_COUNT ? `${MAX_COUNT}+` : buyCount;
  }, [buyCount]);

  return (
    <GoodCardRoot type={type} onClick={handleClick} data-ds-contents={goodNumber}>
      <GoodCardImage handleHotTopAllCategorySoldOutChangeList={handleHotTopAllCategorySoldOutChangeList} handleViewGoodSoldOutChangeList={handleViewGoodSoldOutChangeList} handleOrderGoodSoldOutChangeList={handleOrderGoodSoldOutChangeList} selectedCategoryNumber={selectedCategoryNumber} handelBestViewGoodSoldOutChangeList={handelBestViewGoodSoldOutChangeList} handleBestOrderGoodSoldOutChangeList={handleBestOrderGoodSoldOutChangeList} handleSubDisplayCategoryGoodSoldOutChangeList={handleSubDisplayCategoryGoodSoldOutChangeList} {...props} />
      <Spacing left={imageSpacing.left} top={imageSpacing.top} />
      {'review' === type && (
        <FlexBox width={'20px'}>
          <DoubleQuotation width={'15px'} height={'12px'} />
          <Spacing left={'5px'} />
        </FlexBox>
      )}
      <FlexBoxColumn width={'100%'} height={'100%'} justify-content={'space-between'}>
        {'review' === type && (
          <>
            <ReviewContentWrapper onClick={handleReviewClick}>
              <ReviewContent>{review.content}</ReviewContent>
            </ReviewContentWrapper>
            <Spacing top={'14px'} />
          </>
        )}
        <StyledTitle size={cardProps[type].size.goodName} lineHeight={cardProps[type].lineHeight.goodName}>
          {goodName}
        </StyledTitle>
        <Spacing top={cardProps[type].titleGap} width={'100%'}>
          {(isPriceOpen==undefined ? true : isPriceOpen) && <GoodPriceBox {...priceBoxProps} />}
          {totalReviewScore !== undefined &&
            (evaluationDivisionCode !== PD_EVALUATION_DIVI.UNAVAILABLE || buyCountDisplay) && (
              <Spacing top={'6px'} width={'100%'}>
                <FlexBox
                  width={'100%'}
                  align-items={'center'}
                  flex-wrap={'wrap'}
                  minHeight={cardProps[type].lineHeight.totalReviewScore}
                >
                  {evaluationDivisionCode !== PD_EVALUATION_DIVI.UNAVAILABLE && (
                    <>
                      <StarRatingIcon size={cardProps[type].size.totalReviewScore} fillPercent={100} />
                      <Spacing left={'3px'} />
                      <TextBox size={cardProps[type].size.totalReviewScore} color={theme.color.text.guide}>
                        <BlindP>총 별점 5점 만점에</BlindP>
                        <p>{totalReviewScore?.toFixed?.(1)}</p>
                        <BlindP>리뷰 수</BlindP>
                        <p>({displayReviewCount})</p>
                      </TextBox>
                      <Spacing left={'6px'} />
                    </>
                  )}
                  {buyCountDisplay && (
                    <TextBox
                      size={cardProps[type].size.totalReviewScore}
                      color={theme.color.text.guide}
                      align-items={'baseline'}
                    >
                      {`구매 `}
                      <TextBox size={cardProps[type].size.totalReviewScore} color={theme.color.primary}>
                        {displayBuyCount}
                      </TextBox>
                    </TextBox>
                  )}
                </FlexBox>
              </Spacing>
            )}
          {!isEventMall && filteredFlagList?.length > 0 && (
            <>
              <Spacing top={'6px'} />
              <FlexBox width={'100%'} flex-wrap={'wrap'}>
                {filteredFlagList.map((item, index) => (
                  <Spacing right={'4px'} bottom={'4px'} key={index}>
                    <GoodBenefitChip
                      item={item}
                      size={cardProps[type].size.flag}
                      height={cardProps[type].lineHeight.flag}
                    />
                  </Spacing>
                ))}
              </FlexBox>
            </>
          )}
        </Spacing>
      </FlexBoxColumn>
    </GoodCardRoot>
  );
};

GoodCard.propTypes = {
  type: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large', 'horizontalSmall', 'horizontalMedium', 'review'])
    .isRequired,
  itemDecorators: PropTypes.array,
  onClick: PropTypes.func,
  goodName: PropTypes.string,
  thumbnailImageUrl01: PropTypes.string,
  thumbnailImageUrl02: PropTypes.string,
  thumbnailImageUrl03: PropTypes.string,
  originalPrice: PropTypes.number,
  discountRate: PropTypes.number,
  discountPrice: PropTypes.number,
  totalReviewScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  reviewCount: PropTypes.number,
  evaluationDivisionCode: PropTypes.string,
  buyCount: PropTypes.number,
  buyCountDisplay: PropTypes.bool,
  flagList: PropTypes.array,
  hideBestFlag: PropTypes.bool,
  review: PropTypes.shape({
    content: PropTypes.string,
  }),
  onReviewClick: PropTypes.func,
  goodNumber: PropTypes.string,
  adultGood: PropTypes.bool,
  wish: PropTypes.bool,
  soldOut: PropTypes.bool,
  showRelated: PropTypes.bool,
  clickLogLink: PropTypes.string,
  isEventMall: PropTypes.bool,
  isPriceOpen: PropTypes.bool,
  handleHotTopAllCategorySoldOutChangeList: PropTypes.func,
  handleViewGoodSoldOutChangeList: PropTypes.func,
  handleOrderGoodSoldOutChangeList: PropTypes.func,
  selectedCategoryNumber: PropTypes.number,
  handelBestViewGoodSoldOutChangeList: PropTypes.func,
  handleBestOrderGoodSoldOutChangeList: PropTypes.func,
  handleSubDisplayCategoryGoodSoldOutChangeList: PropTypes.func,
};

GoodCard.defaultProps = {
  itemDecorators: [],
  type: 'small',
};

export default GoodCard;

const GoodCardRoot = styled(FlexBox)`
  height: 100%;
  align-items: flex-start;
  ${({ type }) => cardProps[type].rootCardProp};
`;

const StyledTitle = styled.div`
  width: 100%;
  font-size: ${({ size }) => size};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${theme.color.text.basic};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ReviewContentWrapper = styled.div`
  width: 100%;
  height: 51px;
`;

const ReviewContent = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: ${moTheme.font.lineHeight.medium};
  max-height: 51px;
  height: fit-content;
  color: ${theme.color.text.guide};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const BlindP = styled.p`
  overflow: hidden;
  position: absolute;
  clip: rect(0, 0, 0, 0);
  clip-path: polygon(0 0, 0 0, 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
`;
