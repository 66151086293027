import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import moTheme from '@ecp/common/src/style/theme/mo';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { StyledChip, StyledChipClose } from '@ecp/common/src/components/chip/Chip';
import useChip from '@ecp/common/src/components/chip/useChip';

export const StyledMoChip = styled(StyledChip)`
  font-size: ${moTheme.font.size.$12};
`;

export const StyledMoChipClose = styled(StyledChipClose)`
  width: 10px;
  height: 10px;
`;

const ChipOrigin = ({ closeIcon: CloseIcon, closable, children, height, width, type, onClick, onClose, ...props }) => {
  const { chipProps, handleClick, handleClose } = useChip({ type, onClick, onClose, ...props });
  return (
    <StyledMoChip height={height} width={width} {...props} {...chipProps} onClick={handleClick}>
      {children}
      {closable && (
        <>
          <Spacing left={4} />
          <CloseIcon {...props} onClick={handleClose} />
        </>
      )}
    </StyledMoChip>
  );
};

ChipOrigin.propTypes = {
  type: PropTypes.oneOf(['default', 'rescan', 'category', 'brand', 'price', 'hashtag', 'brandSelect', 'title']),
  hoverIcon: PropTypes.elementType,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  checked: PropTypes.bool,
  closable: PropTypes.bool,
  ...sizePropTypes,
};

ChipOrigin.defaultProps = {
  type: 'default',
  closeIcon: StyledMoChipClose,
  onClose: () => {},
  closable: true,
};

export default ChipOrigin;
