import { useLayoutEffect } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { withMobileStatus } from '@mo-recoil/system/selector';
import { MOBILE_APP_STATUS } from '@ecp/mo/src/const/system/mobileConst';

export const useMobileStatus = (result) => {
  const setMobileStatus = useSetRecoilState(withMobileStatus);
  const resetMobileStatus = useResetRecoilState(withMobileStatus);

  useLayoutEffect(() => {
    const mobileStatus = {
      neededUpdate:
        result?.versionUpdateType === MOBILE_APP_STATUS.MAJOR_UPDATE ||
        result?.versionUpdateType === MOBILE_APP_STATUS.MINOR_UPDATE,
      ...result,
    };
    setMobileStatus({ ...mobileStatus });

    return () => resetMobileStatus();
  }, [resetMobileStatus, result, setMobileStatus]);
};
