import React from 'react';
import PropTypes from 'prop-types';

import { SwiperContents } from '@ecp/common/src/components/swiper/mo/SwiperTemplate';

import GoodCardCarouselItem from '@mo-components/good/GoodCardCarouselItem';

const RecommendationSmallTypeContent = ({ goodList, showRelated }) => (
  <>
    {!!goodList && !!goodList.length && (
      <SwiperContents
        data-ds-label2={'reco_search'}
        width={'360px'}
        itemProps={{ width: '130px', height: 'auto', type: 'small', showRelated }}
        itemList={goodList}
        carouselProps={{
          speed: 500,
          autoplay: false,
          loop: false,
          slidesPerView: 'auto',
          spaceBetween: 10,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
        }}
        itemComponent={GoodCardCarouselItem}
      />
    )}
  </>
);

export default RecommendationSmallTypeContent;

RecommendationSmallTypeContent.propTypes = {
  goodList: PropTypes.array,
  showRelated: PropTypes.bool,
};
