import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';

import SearchFilterPopupHeader from '@mo-components/display/filter/components/SearchFilterPopupHeader';

const BrandFilter = ({ brandList, selectedBrandList, onClickBrand }) => {
  const handleClick = useCallback(
    (brand) => {
      const originSelected = selectedBrandList?.some((selected) => selected === brand.value);
      onClickBrand(brand, !originSelected);
    },
    [onClickBrand, selectedBrandList]
  );

  return (
    <Wrapper>
      <SearchFilterPopupHeader title={'브랜드'} />
      {brandList.map((brand, index) => (
        <SubFilterListText
          key={index}
          textProps={
            selectedBrandList?.some((selected) => selected === brand.value)
              ? { color: theme.color.text.selected, weight: moTheme.font.weight.bold }
              : { weight: moTheme.font.weight.demiLight }
          }
          onClick={() => handleClick(brand)}
        >
          {brand?.label}
        </SubFilterListText>
      ))}
    </Wrapper>
  );
};

export default BrandFilter;

const Wrapper = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const SubFilterListText = styled(TextButton)`
  font-size: ${moTheme.font.size.$13};
  line-height: 21px;
  padding-bottom: 10px;
`;

BrandFilter.propTypes = {
  brandList: PropTypes.array,
  selectedBrandList: PropTypes.array,
  onClickBrand: PropTypes.func,
};
