import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import qs from 'qs';

import { useAlertDialog } from '@mo-hooks/common/useModal';
import paymentApi from '@mo-apis/order/paymentApi';

const PaymentRequestPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showAlertDialog } = useAlertDialog();

  useEffect(() => {
    const queryParameters = { ...qs.parse(location.search, { ignoreQueryPrefix: true }), isMobiliansOrder : false };

    if (queryParameters.errorMessage) {
      showAlertDialog(queryParameters.errorMessage, () => {
        // 주문서 페이지로 이동
        const { cartNoList, orderTypeCode } = queryParameters;
        if (cartNoList) {
          navigate('/order', {
            replace: true,
            state: {
              cartNoList: cartNoList.split(','),
              isPresent: orderTypeCode === '90',
            },
          });
        } else {
          navigate('/');
        }
      });
    } else {
      (async () => {
        navigate('/order/in-progress', { replace: true });
        if(queryParameters.tradeId) {
          queryParameters.resultCd = queryParameters.resultCode;
          queryParameters.isMobiliansOrder = true;
        }
        const { result, code, message } = await paymentApi.createOrder({
          ...queryParameters,
          orderNumber: queryParameters.orderNo,
          resultCode: queryParameters.resultCd,
          AutoBillPayLogNumber: queryParameters.payLogNo,
        });
        if (code) {
          navigate('/order/payment-fail', { replace: true, state: { code, message } });
        } else {
          navigate(`/order/complete/${result.orderNumber}`, { replace: true });
        }
      })();
    }
  }, [location.search, navigate, showAlertDialog]);
};

export default PaymentRequestPage;
