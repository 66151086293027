import { atom } from 'recoil';

export const stripBannerCommonState = atom({
  key: 'stripBannerCommonState',
  default: [],
});

export const stripBannerOneState = atom({
  key: 'stripBannerOneState',
  default: [],
});

export const stripBannerTwoState = atom({
  key: 'stripBannerTwoState',
  default: [],
});

export const bannerMainFunctionState = atom({
  key: 'bannerMainFunctionState',
  default: {},
});

export const stripBannerAllianceState = atom({
  key: 'stripBannerAllianceState',
  default: {},
});

export const appStoreLinkBannerState = atom({
  key: 'appStoreLinkBannerState',
  default: {
    show: false,
    height: 0,
  },
});
