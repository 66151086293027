import { useState, useCallback, useEffect } from 'react';

const useGoodUnusualList = ({ goodListApi }) => {
  const [sdiGoodAvailableList, setSdiGoodAvailableList] = useState(undefined);

  const getSDIGoodAvailableList = useCallback(async () => {
    const { result } = await goodListApi.getSDIGoodAvailableList();
    setSdiGoodAvailableList(result);
  }, [goodListApi]);

  // const getXXXGoodAvailableList = useCallback(async () => {
  //   const { result } = await goodListApi.getXXXGoodAvailableList();
  //   setXxxGoodAvailableList(result);
  // }, [goodListApi]);

  useEffect(() => {
      (async () => {
        await getSDIGoodAvailableList();
        // await getGoodAvailableETC();
      })();
  }, [getSDIGoodAvailableList]);

  return {
    sdiGoodAvailableList,
  };
};

export default useGoodUnusualList;
