import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import ChipOrigin from '@ecp/common/src/components/chip/mo/ChipOrigin';
import { SwiperContents, SwiperContentsDefaultProps } from '@ecp/common/src/components/swiper/mo/SwiperTemplate';

import useNavigateBrandDetailPage from '@mo-hooks/brand/useNavigateBrandDetailPage';
import displayCategoryApi from '@mo-apis/display/displayCategoryApi';

const ChipLabel = ({ item }) => {
  const { handleNavigateToBrandDetail } = useNavigateBrandDetailPage();

  return (
    <ChipOrigin
      checked={false}
      key={item?.value}
      data-key={item?.value}
      {...item}
      width={'100%'}
      onClick={() => handleNavigateToBrandDetail({ brandNumber: item?.value })}
    >
      {item?.label}
    </ChipOrigin>
  );
};

ChipLabel.propTypes = {
  item: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
};

const BrandSwiperLabel = ({ itemList }) => (
  <FlexBox padding={'14px 4px 14px 20px'} width={'360px'}>
    <ChipOrigin checked={false} width={'66px'} type={'title'} closable={false}>
      브랜드
    </ChipOrigin>
    <SwiperContents
      width={'276px'}
      itemList={itemList}
      itemComponent={ChipLabel}
      itemProps={{ width: 'auto' }}
      carouselProps={{ ...SwiperContentsDefaultProps.carouselProps, slidesOffsetAfter: 20 }}
    />
  </FlexBox>
);

BrandSwiperLabel.propTypes = {
  itemList: PropTypes.array,
};

const BestBrand = () => {
  const { categoryNumber } = useParams();

  const [brandList, setBrandList] = useState([]);
  useEffect(() => {
    (async () => {
      const { result } = await displayCategoryApi.getCategoryBrandList(categoryNumber, 6, true);
      setBrandList([
        ...result.map(({ brandNumber, brandName }) => ({
          label: brandName,
          value: brandNumber,
          closable: false,
          type: 'brandSelect',
        })),
      ]);
    })();
  }, [categoryNumber]);

  return <>{brandList.length > 0 && <BrandSwiperLabel itemList={brandList} />}</>;
};
export default BestBrand;
