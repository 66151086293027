import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';

import useSiteInfo from '@mo-hooks/common/useSiteInfo';
import OrderPageItemTitleContainer from '@mo-components/order/payment/OrderPageItemTitleContainer';
import OrderPaymentStatementItemDetail from '@mo-components/order/payment/OrderPaymentStatementItemDetail';
import OrderPaymentStatementItem from '@mo-components/order/payment/OrderPaymentStatementItem';
import theme from '@ecp/common/src/style/theme/default';

const OrderPaymentStatement = ({ paymentAmount }) => {
  const { displayPointName } = useSiteInfo();

  return (
    <>
      <Container>
        <OrderPageItemTitleContainer title={'최종 결제금액'} gapToBody={'14px'} />
        <PaymentItemListContainer>
          <OrderPaymentStatementItem
            title={'총 상품금액'}
            price={NumberFormat(paymentAmount?.totalProductAmount || 0)}
          />
          <OrderPaymentStatementItem
            title={'할인금액'}
            price={NumberFormat((paymentAmount?.discountAmount?.total || 0) * -1)}
          />
          {!!paymentAmount?.discountAmount?.total && (
            <>
              <OrderPaymentStatementItemDetail
                title={'상품 쿠폰할인'}
                price={NumberFormat(paymentAmount?.discountAmount?.goodCouponDiscount || 0)}
              />
              <OrderPaymentStatementItemDetail
                title={'장바구니 쿠폰할인'}
                price={NumberFormat(paymentAmount?.discountAmount?.cartCouponDiscount || 0)}
              />
              <OrderPaymentStatementItemDetail
                title={'배송비 쿠폰할인'}
                price={NumberFormat(paymentAmount?.discountAmount?.deliveryCouponDiscount || 0)}
              />
            </>
          )}
          <OrderPaymentStatementItem
            title={'포인트'}
            price={NumberFormat((paymentAmount?.pointAmount?.total || 0) * -1)}
          />
          {!!paymentAmount?.pointAmount && (
            <>
              <OrderPaymentStatementItemDetail
                title={'적립금'}
                price={NumberFormat(paymentAmount?.pointAmount?.savePoint?.use)}
              />
              <OrderPaymentStatementItemDetail
                title={'웰포인트'}
                price={NumberFormat(paymentAmount?.pointAmount?.welPoint?.use)}
              />
              <OrderPaymentStatementItemDetail
                title={displayPointName}
                price={NumberFormat(paymentAmount?.pointAmount?.welfarePoint?.use)}
              />
            </>
          )}
          <OrderPaymentStatementItem title={'배송비'} price={NumberFormat(paymentAmount?.deliveryAmount?.total || 0)} />
          {!!paymentAmount?.deliveryAmount?.total && (
            <>
              <OrderPaymentStatementItemDetail
                title={'배송비'}
                price={NumberFormat(paymentAmount?.deliveryAmount?.baseCost || 0)}
              />
              <OrderPaymentStatementItemDetail
                title={'도서산간 배송비'}
                price={NumberFormat(paymentAmount?.deliveryAmount?.islandsCost || 0)}
              />
            </>
          )}
          <Spacing top={'14px'} />
        </PaymentItemListContainer>
        <HorizontalDivider color={'#EAEAEA'} />
        <Spacing top={'10px'} />
        <FinalAmountContainer>
          <FinalAmountTextBox> {NumberFormat(paymentAmount?.paymentTotalAmount || 0)}</FinalAmountTextBox>
          <FinalAmountUnitTextBox>원</FinalAmountUnitTextBox>
        </FinalAmountContainer>
      </Container>
    </>
  );
};

export default OrderPaymentStatement;
OrderPaymentStatement.propTypes = {
  paymentAmount: PropTypes.object,
};

const Container = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
`;

const PaymentItemListContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
  gap: 6px;
`;

const FinalAmountContainer = styled(FlexBox)`
  width: 100%;
  justify-content: flex-end;
`;

const FinalAmountTextBox = styled(TextBox)`
  font-size: 18px;
  font-weight: 700;
  color: ${theme.color.primary};
`;
const FinalAmountUnitTextBox = styled(TextBox)`
  font-size: 18px;
  font-weight: 350;
  color: ${theme.color.primary};
`;
