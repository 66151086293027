import React, { useCallback, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RemoveScroll } from 'react-remove-scroll';

import { FloatingOverlay } from '@floating-ui/react';

import theme from '@ecp/common/src/style/theme/default';
import { zIndex } from '@ecp/common/src/style/constant';
import { FlexBoxCenter, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import PopupUnstyled from '@ecp/common/src/components/unstyled/floating/PopupUnstyled';
import { PageButton } from '@ecp/common/src/components/button/mo/Button';
import { ReactComponent as TooltipCloseIcon } from '@ecp/common/src/assets/icon/tooltip__close.svg';

const FullPagePopup = ({
  open,
  onClose,
  header,
  headerProps,
  closable,
  closeButtonType,
  useCancelButton,
  cancelButtonText,
  onCancelClick,
  cancelButtonProps,
  useConfirmButton,
  confirmButtonText,
  onConfirmClick,
  confirmButtonProps,
  buttonPosition = 'down',
  bodyPadding,
  buttonPadding,
  children,
  ...rest
}) => {
  const bottomButtonProps = {
    useCancelButton,
    cancelButtonText,
    onCancelClick,
    cancelButtonProps,
    useConfirmButton,
    confirmButtonText,
    onConfirmClick,
    confirmButtonProps,
    padding: buttonPadding,
  };

  return (
    <PopupUnstyled
      open={open}
      onClose={onClose}
      rootComponent={StyledPopupRoot}
      headerComponent={header}
      headerProps={headerProps}
      bodyComponent={typeof header === 'string' && header === '필터' ? StyledBodyFilterComponent : StyledBodyComponent}
      bodyProps={{ buttonPosition, bodyPadding }}
      closable={closable}
      closeComponent={
        closeButtonType === 'Primary' ? (
          <CloseButton type="button" aria-label={rest?.ariaLabel ? rest?.ariaLabel : '닫기'}>
            <DefaultCloseComponent />
          </CloseButton>
        ) : (
          <></>
        )
      }
      bottomButtonComponent={<StyledBottomButtonComponent {...bottomButtonProps} />}
      {...rest}
    >
      {children}
    </PopupUnstyled>
  );
};

export default FullPagePopup;

FullPagePopup.displayName = 'FullPagePopup';

FullPagePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  header: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node, PropTypes.string]),
  headerProps: PropTypes.object,
  useCancelButton: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  onCancelClick: PropTypes.func,
  cancelButtonProps: PropTypes.object,
  useConfirmButton: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  onConfirmClick: PropTypes.func,
  confirmButtonProps: PropTypes.object,
  closable: PropTypes.bool,
  closeButtonType: PropTypes.oneOf(['Primary', 'Secondary']),
  buttonPosition: PropTypes.oneOf(['up', 'down']),
  bodyPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buttonPadding: PropTypes.string,
  children: PropTypes.node,
};

FullPagePopup.defaultProps = {
  headerProps: {
    size: '18px',
    weight: '700',
    height: '18px',
    style: { padding: '20px' },
  },
  closable: true,
  closeButtonType: 'Primary',
  useCancelButton: true,
  cancelButtonProps: {
    type: 'Secondary',
    width: '156px',
  },
  cancelButtonText: '취소',
  useConfirmButton: true,
  confirmButtonProps: {
    type: 'Primary',
    width: '156px',
  },
  confirmButtonText: '확인',
};

const StyledPopupRoot = React.forwardRef(({ children, ...props }, ref) => {
  const rootRef = useRef(null);

  useImperativeHandle(ref, () => rootRef.current);

  return (
    <RemoveScroll>
      <FloatingOverlay
        style={{
          zIndex: zIndex.popup,
          display: 'grid',
          background: 'rgba(25, 25, 25, 0.8)',
          placeItems: 'center',
        }}
      >
        <PopupWrapper ref={rootRef} {...props}>
          {children}
        </PopupWrapper>
      </FloatingOverlay>
    </RemoveScroll>
  );
});

StyledPopupRoot.displayName = 'StyledPopupRoot';

StyledPopupRoot.propTypes = {
  children: PropTypes.node,
};

const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 360px;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #ffffff;
  border-radius: 8px;

  &:focus-visible {
    outline: none;
  }
`;

const StyledBodyComponent = styled(FlexBoxColumn)`
  align-items: flex-start;
  overflow-y: auto;
  margin: 20px 0 0;
  padding: ${({ bodyPadding }) => (bodyPadding ? `${bodyPadding}px` : '0 20px')};
  ${({ buttonPosition }) => {
    if (buttonPosition === 'down') {
      return { justifyContent: 'space-between', flex: 1 };
    }
  }}
`;

const StyledBodyFilterComponent = styled(FlexBoxColumn)`
  align-items: flex-start;
  overflow-y: auto;
  margin-top: 20px;
  margin-bottom: 80px;
  ${({ buttonPosition }) => {
    if (buttonPosition === 'down') {
      return { justifyContent: 'space-between', flex: 1 };
    }
  }}
`;

export const StyledBottomButtonComponent = ({
  useCancelButton,
  cancelButtonProps,
  onCancelClick,
  cancelButtonText,
  useConfirmButton,
  confirmButtonProps,
  onConfirmClick,
  confirmButtonText,
  gap = '8px',
  padding = '30px 0',
}) => {
  const handleCancelClick = useCallback(() => {
    onCancelClick();
  }, [onCancelClick]);

  return (
    <>
      {(useCancelButton || useConfirmButton) && (
        <FlexBoxCenter width={'100%'} padding={padding}>
          {useCancelButton && (
            <PageButton {...cancelButtonProps} onClick={handleCancelClick}>
              {cancelButtonText}
            </PageButton>
          )}
          {useConfirmButton && (
            <>
              {useCancelButton && <Spacing left={gap} />}
              <PageButton {...confirmButtonProps} onClick={onConfirmClick}>
                {confirmButtonText}
              </PageButton>
            </>
          )}
        </FlexBoxCenter>
      )}
    </>
  );
};

StyledBottomButtonComponent.propTypes = {
  useCancelButton: PropTypes.bool,
  cancelButtonProps: PropTypes.object,
  onCancelClick: PropTypes.func,
  cancelButtonText: PropTypes.string,
  useConfirmButton: PropTypes.bool,
  confirmButtonProps: PropTypes.object,
  onConfirmClick: PropTypes.func,
  confirmButtonText: PropTypes.string,
  gap: PropTypes.string,
  padding: PropTypes.string,
};

const DefaultCloseComponent = styled(TooltipCloseIcon)`
  border: none;
  width: 18px;
  height: 18px;
  background-color: ${theme.color.background.white};
  //position: absolute;
  //top: 20px;
  //right: 20px;
  cursor: pointer;
`;
const CloseButton = styled.button`
  border: none;
  width: 18px;
  height: 18px;
  background-color: ${theme.color.background.white};
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;
