import { useEffect, useState } from 'react';

import qs from 'qs';

import { postRequest } from '@mo-apis/axios';

export const useMobileMobilians = () => {
  const [mobilians, setMobilians] = useState(null);

  const receiveMessageFromAndroidApp = async (event) => {
    if (typeof event?.detail?.data === 'string' || event?.detail?.data) {
      const fullUrl = event.detail.data;
      const splitUrl = fullUrl.split('?');
      if (splitUrl.length > 1) {
        const data = qs.parse(splitUrl[1], { ignoreQueryPrefix: true, decoder: (s) => s });
        const { result } = await postRequest({
          url: '/api/v1/interfaces/mobilians/okUrl',
          data,
        });

        const convertedData = {
          ...result,
          name: decodeURI(result.name),
          cellNo: decodeURI(result.cellNo),
          sex: decodeURI(result.sex),
          birthDate: decodeURI(result.birthDate),
          isForeigner: decodeURI(result.isForeigner),
          tradeId: decodeURI(result.tradeId),
        };
        setMobilians(convertedData);
      }
    }
  };

  const receiveMessage = (event) => {
    if (
      (event.origin === window.location.origin || event.origin.includes('welstorymall.com')) &&
      !navigator.userAgent.toLowerCase().includes('ecp/android_webview') &&
      !navigator.userAgent.toLowerCase().includes('ecp/ios_webview')
    ) {
      if (event.data.name) {
        const convertedData = {
          ...event.data,
          name: decodeURI(event.data.name),
          cellNo: decodeURI(event.data.cellNo),
          sex: decodeURI(event.data.sex),
          birthDate: decodeURI(event.data.birthDate),
          isForeigner: decodeURI(event.data.isForeigner),
          tradeId: decodeURI(event.data.tradeId),
        };
        setMobilians(convertedData);
      }
    }
  };

  useEffect(() => {
    window.mobiliansStatus = new CustomEvent('updateMobiliansStatus');
    window.addEventListener('updateMobiliansStatus', receiveMessageFromAndroidApp);
    window.addEventListener('message', receiveMessage);
    return () => {
      window.removeEventListener('message', receiveMessage);
      window.removeEventListener('updateMobiliansStatus', receiveMessageFromAndroidApp);
    };
  }, []);

  const handleMobiliansOpen = () => {
    window.open('/interface/mobilians-information', 'mobilians');
  };

  useEffect(() => {}, [mobilians]);

  const resetMobilians = () => setMobilians(null);

  return { mobilians, resetMobilians, handleMobiliansOpen };
};
