import React from 'react';
import PropTypes from 'prop-types';

import useEventCallback from '@ecp/common/src/hooks/useEventCallback';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Input, { StyledErrorMessage } from '@ecp/common/src/components/input/mo/Input';
import { InnerButton } from '@ecp/common/src/components/button/mo/Button';
import LabeledCheckBox from '@ecp/common/src/components/checkbox/mo/LabeledCheckBox';
import PostcodeModal from '@ecp/common/src/components/postcode/PostcodeModal';

const AddressInput = ({ field }) => {
  const {
    setForm,
    base = 'false',
    addNew = false,
    postNumber = '',
    baseAddress = '',
    detailAddress = '',
    detailAddressKey = 'detailAddress',
    newPostAddressKey = 'newPostAddress',
    postNumberKey = 'postNumber',
    baseAddressKey = 'baseAddress',
    baseKey = 'base',
    newKey = 'addNew',
    errorMessage = '',
    locationCnt = 0,
    editingBase = false,
    isFromNew = false,
  } = field;
  const handleAddressInput = ({ zonecode, address, addressType }) => {
    setForm((prev) => ({
      ...prev,
      [baseAddressKey]: address,
      [postNumberKey]: zonecode,
      [newPostAddressKey]: addressType === 'R',
    }));
  };

  const changeFormValues = useEventCallback((v, data) => {
    setForm((prev) => ({ ...prev, [data.key]: v }));
  });

  const toggleBase = () => {
    setForm((prev) => ({
      ...prev,
      [baseKey]: !prev[baseKey],
    }));
  };

  const toggleAddNew = () => {
    setForm((prev) => ({
      ...prev,
      [newKey]: !prev[newKey],
    }));
  };

  return (
    <>
      <FlexBox width={'100%'}>
        <Input value={postNumber} disabled placeholder={''} width={'100%'} />
        <Spacing left={10} />
        <PostcodeModal width={'300px'} height={'380px'} innerModal onComplete={handleAddressInput}>
          <InnerButton width={'110px'} minWidth={'110px'} height={'36px'}>
            우편번호 검색
          </InnerButton>
        </PostcodeModal>
      </FlexBox>
      <Spacing top={10} />
      <Input value={baseAddress} placeholder={''} width={'100%'} disabled maxLength={70} />
      <Spacing top={10} />
      <Input
        placeholder={'상세 주소를 입력해 주세요'}
        width={'100%'}
        value={detailAddress}
        onChange={changeFormValues}
        data-key={detailAddressKey}
        maxLength={30}
      />
      {!!errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
      <Spacing top={10} />
      {isFromNew ? (
        <FlexBox>
          <LabeledCheckBox value={addNew} checked={addNew} onChange={toggleAddNew} />
          <TextBox>배송지 저장</TextBox>
        </FlexBox>
      ) : (
        <FlexBox>
          <LabeledCheckBox
            value={base}
            checked={base || locationCnt === 0}
            onChange={toggleBase}
            disabled={locationCnt === 0 || editingBase}
            labelText="기본 배송지 저장"
          />
        </FlexBox>
      )}
    </>
  );
};

export default AddressInput;

AddressInput.propTypes = {
  field: PropTypes.shape({
    base: PropTypes.bool,
    addNew: PropTypes.bool,
    postNumber: PropTypes.string,
    baseAddress: PropTypes.string,
    detailAddress: PropTypes.string,
    detailAddressKey: PropTypes.string,
    newPostAddressKey: PropTypes.string,
    postNumberKey: PropTypes.string,
    baseAddressKey: PropTypes.string,
    baseKey: PropTypes.string,
    newKey: PropTypes.string,
    setForm: PropTypes.func,
    errorMessage: PropTypes.string,
    locationCnt: PropTypes.number,
    editingBase: PropTypes.bool,
    isFromNew: PropTypes.bool,
  }),
};
