import { useMemo } from 'react';

export default function useTicketOrderPage() {
  const orderGoodType = useMemo(() => {
    return {
      directForeignGood: false,
      alcohol: false,
      ticket: true,
      pointGood: false,
      rental: false,
      giftCertificate: false,
    };
  }, []);

  return {
    orderGoodType,
  };
}
