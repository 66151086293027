import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getAccessToken, getRefreshToken } from '@ecp/common/src/utils/utils';

import { myBaseDeliveryPlace } from '@mo-recoil/order/selector';
import { myDeliveryPlaceListState } from '@mo-recoil/order/atom';
import { useAlertDialog } from '@mo-hooks/common/useModal';
import memberApi from '@mo-apis/member/memberApi';

const useMyDeliveryPlaceList = () => {
  const [deliveryPlaceList, setDeliveryPlaceList] = useRecoilState(myDeliveryPlaceListState);
  const baseDeliveryPlace = useRecoilValue(myBaseDeliveryPlace);

  const { showAlertDialog } = useAlertDialog();

  const getDeliveryPlaceList = useCallback(async () => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();

    if (accessToken && refreshToken) {
      const { result: { content } = {} } = await memberApi.getDeliveryLocations({ page: 0, size: 200 });
      setDeliveryPlaceList(content);
    }
  }, [setDeliveryPlaceList]);

  const createDeliveryPlace = useCallback(
    async (deliveryPlace) => {
      const { code: resultCode } = await memberApi.createDeliverLocation(deliveryPlace);

      if (resultCode) {
        showAlertDialog('배송지 등록에 실패했습니다.');
      } else {
        showAlertDialog(`배송지가 등록되었습니다.`);
        await getDeliveryPlaceList();
      }
    },
    [getDeliveryPlaceList, showAlertDialog]
  );

  const updateDeliveryPlace = useCallback(
    async (deliveryPlace) => {
      const { code: resultCode } = await memberApi.updateDeliverLocation(
        deliveryPlace.memberDeliverySequence,
        deliveryPlace
      );

      if (resultCode) {
        showAlertDialog('배송지 등록에 실패했습니다.');
      } else {
        showAlertDialog(`배송지가 수정되었습니다.`);
        await getDeliveryPlaceList();
      }
    },
    [getDeliveryPlaceList, showAlertDialog]
  );

  const deleteDeliveryPlace = useCallback(
    async (deliveryPlaceSequence) => {
      await memberApi.deleteDeliverLocation(deliveryPlaceSequence);
      await getDeliveryPlaceList();
    },
    [getDeliveryPlaceList]
  );

  useEffect(() => {
    (async () => {
      if (!deliveryPlaceList) {
        await getDeliveryPlaceList();
      }
    })();
  }, [deliveryPlaceList, getDeliveryPlaceList]);

  return {
    baseDeliveryPlace,
    deliveryPlaceList,
    createDeliveryPlace,
    updateDeliveryPlace,
    deleteDeliveryPlace,
  };
};

export default useMyDeliveryPlaceList;
