import React, { useCallback, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';

import { openWindow } from '@ecp/common/src/utils/utils';
import Editor from '@ecp/common/src/components/editor/Editor';

import { withMobileStatus } from '@mo-recoil/system/selector';

const EditorViewer = ({ ...props }) => {
  const wrapperRef = useRef(null);
  const { isMobileApp, agentType } = useRecoilValue(withMobileStatus);

  const handleRef = useCallback(
    (el) => {
      el.e.on(wrapperRef.current, 'click', (e) => {
        const { target: { href, target } = {} } = e;

        if (href) {
          e.preventDefault();
          openWindow(isMobileApp, href, target, agentType);
        }
      });
    },
    [agentType, isMobileApp]
  );

  return <Editor {...props} readonly ref={wrapperRef} setEditor={handleRef} />;
};

EditorViewer.propTypes = {
  html: PropTypes.string,
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
};

export default EditorViewer;
