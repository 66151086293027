import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { format } from 'date-fns';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import DropdownDevice from '@ecp/common/src/components/dropdown/mo/DropdownDevice';

const OrderGoodCouponSelection = ({ couponList, goodItem, onChange, excludeCoupon }) => {
  const couponDropdownItems = useMemo(() => {
    if (Array.isArray(goodItem?.couponList) && goodItem.couponList.length > 0) {
      const dropdownList = goodItem.couponList.map((goodCoupon) => ({
        key: goodCoupon.resourceManagementNo,
        label: goodCoupon.endDateTime
          ? `${goodCoupon.promotionName}(~${format(new Date(goodCoupon.endDateTime), 'MM.dd')})${
              goodCoupon.disabled ? ' 사용중' : ''
            }`
          : `${goodCoupon.promotionName}${goodCoupon.disabled ? ' 사용중' : ''}`,
        value: goodCoupon.resourceManagementNo,
        ...goodCoupon,
      }));
      return [
        {
          key: '',
          label: '선택하세요',
          value: '',
          goodNumber: goodItem.goodNumber,
          itemNumber: goodItem.itemNumber,
        },
        ...dropdownList,
      ];
    }
    return [];
  }, [goodItem]);

  const couponValue = useMemo(() => {
    const key = `${goodItem.goodNumber}-${goodItem.itemNumber}`;
    const selectedCoupon = couponList?.find(
      ({ isSelect, applyItemList, promotionTypeCode }) =>
        isSelect &&
        '021' === promotionTypeCode &&
        applyItemList.length > 0 &&
        `${applyItemList[0].goodNumber}-${applyItemList[0].itemNumber}` === key
    );

    if (selectedCoupon) {
      return {
        key: selectedCoupon.resourceManagementNo,
        label: selectedCoupon.endDateTime
          ? `${selectedCoupon.promotionName}(~${format(new Date(selectedCoupon.endDateTime), 'MM.dd')})`
          : `${selectedCoupon.promotionName}`,
        value: selectedCoupon.resourceManagementNo,
        ...selectedCoupon,
      };
    }
    return {
      key: '',
      label: '선택하세요',
      value: '',
      goodNumber: goodItem.goodNumber,
      itemNumber: goodItem.itemNumber,
    };
  }, [goodItem, couponList]);

  const handleSelectCouponDropdown = useCallback(
    (item) => {
      onChange({ ...item }, false);
    },
    [onChange]
  );

  return (
    <Container>
      <GoodNameTextBox>{goodItem.goodName}</GoodNameTextBox>
      <Spacing top={10} />
      <ItemNameTextBox>{goodItem.itemName}</ItemNameTextBox>
      <Spacing top={14} />
      <DropdownDevice
        width="100%"
        items={couponDropdownItems}
        disabled={!couponDropdownItems.length}
        placeholder={!couponDropdownItems.length ? '선택 가능한 쿠폰이 없습니다.' : '적용할 쿠폰을 선택하세요.'}
        onChange={handleSelectCouponDropdown}
        value={couponValue}
        title={'상품 쿠폰'}
      />
      {excludeCoupon && <WarningMessage>(쿠폰 적용 제외 상품)</WarningMessage>}
    </Container>
  );
};

export default OrderGoodCouponSelection;

OrderGoodCouponSelection.propTypes = {
  couponList: PropTypes.array,
  goodItem: PropTypes.object,
  onChange: PropTypes.func,
  excludeCoupon: PropTypes.bool,
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const GoodNameTextBox = styled(TextBox)`
  font-weight: 500;
  font-size: 14px;
`;

const ItemNameTextBox = styled(TextBox)`
  font-weight: 350;
  font-size: 13px;
  line-height: 100%;
  color: #5a5a5a;
`;

const WarningMessage = styled(TextBox).attrs(() => ({
  color: theme.color.primary,
  size: moTheme.font.size.$13,
  weight: moTheme.font.weight.demiLight,
  height: moTheme.font.lineHeight.bold,
}))`
  flex: 1;
  width: 100%;
  margin-top: 10px;
`;
