import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import clsx from 'clsx';

// import useNavigateBack from '@ecp/common/src/hooks/useNavigateBack';
import useScrollDirection from '@ecp/common/src/hooks/useScrollDirection';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxCenter } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { ReactComponent as Search } from '@ecp/common/src/assets/icon/icon__search.svg';

import { searchKeywordState } from '@mo-recoil/search/atom';
import { gnbTitleState } from '@mo-recoil/common/gnb/atom';
import Cart from '@mo-components/common/gnb/Cart';
import { gnbTypes } from '@mo-components/common/gnb/Gnb';
import GnbSubTopSearch from '@mo-components/common/gnb/GnbSubTopSearch';
import { ReactComponent as HomeIcon } from '@mo-assets/icon/icon__home.svg';
import { ReactComponent as GoBackIcon } from '@mo-assets/icon/icon__go_back.svg';

const GnbSubTop = ({ type, appLinkBanner }) => {
  const scrollDirection = useScrollDirection();
  const scrollDirectionStyle = clsx(scrollDirection);
  const title = useRecoilValue(gnbTitleState);
  const resetSearchKeyword = useResetRecoilState(searchKeywordState);
  const navigate = useNavigate();
  // const navigateBack = useNavigateBack();

  const handleGoBackClick = () => {
    // navigateBack();
    navigate(-1); //뒤로가기 버튼과 같은 기능
  };

  const handleHomeClick = () => {
    resetSearchKeyword();
    navigate('/');
  };

  const handleSearchClick = () => {
    resetSearchKeyword();
    navigate('/search');
  };

  const handleEventClick = () => {
    resetSearchKeyword();
    navigate('/event');
  };

  return (
    <>
      <Container className={scrollDirectionStyle} type={type} appLinkBanner={appLinkBanner}>
        <Spacing left={20} />
        <FlexBox>
          <button type="button" aria-label="뒤로가기">
            <GoBackIcon onClick={handleGoBackClick} data-ds-label2={'g_back'} />
          </button>
          <Spacing left={14} />
          <button type="button" aria-label="홈으로 가기">
            <HomeIcon onClick={handleHomeClick} data-ds-label2={'g_home'} />
          </button>
        </FlexBox>
        {type === gnbTypes.TITLE && (
          <>
            <Spacing left={5} />
            <FlexBoxCenter width={'calc(100% - 158px)'}>
              <TextBox size={moTheme.font.size.$16} weight={'500'} ellipsis>
                {title}
              </TextBox>
            </FlexBoxCenter>
            <Spacing left={5} />
            <FlexBox>
              <button type="button" aria-label="검색하기">
                <SearchIcon onClick={handleSearchClick} data-ds-label2={'g_search'} />
              </button>
              <Spacing right={14} />
              <Cart />
            </FlexBox>
          </>
          )}
        {type === gnbTypes.SEARCH && <GnbSubTopSearch />}
        {type === gnbTypes.EVENT && (
          <>
            <Spacing left={5} />
            <FlexBoxCenter width={'calc(100% - 158px)'}>
              <TextBox size={moTheme.font.size.$16} weight={'500'}>
                {title}
              </TextBox>
            </FlexBoxCenter>
            <Spacing left={5} />
            <FlexBox>
              <SearchIcon onClick={handleEventClick} data-ds-label2={'g_search'} />
              <Spacing right={14} />
              <Cart />
            </FlexBox>
          </>
        )}
      </Container>
      <FlexBox width={'360px'} height={'46px'} />
    </>
  );
};

GnbSubTop.propTypes = {
  type: PropTypes.string,
  appLinkBanner: PropTypes.object,
};

const Container = styled(FlexBox)`
  width: 360px;
  height: 46px;
  box-sizing: border-box;
  background-color: #ffffff;
  padding-top: ${({ type }) => (type === 'title' ? '1px' : '0')};
  border-bottom: ${({ type }) => (type === 'search' ? 'none' : '1px solid #eaeaea')};
  z-index: 1200;
  position: fixed;
  top: ${({appLinkBanner}) => appLinkBanner.height}px;

  transition: top 0.3s ease-in-out;
  left: auto;

  &.down,
  &.bottom {
    position: fixed;
    top: ${({appLinkBanner}) => appLinkBanner.height - 46}px;
  }
`;

const SearchIcon = styled(Search)`
  width: 18px;
  height: 18px;
`;

export default GnbSubTop;
