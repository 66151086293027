import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import qs from 'qs';

import { postRequest } from '@mo-apis/axios';

const MobiliansOkUrlPage = () => {
  const location = useLocation();

  useEffect(() => {
    const data = qs.parse(location.search, { ignoreQueryPrefix: true, decoder: (s) => s });
    const callRequest = async () => {
      const { result } = await postRequest({
        url: '/api/v1/interfaces/mobilians/okUrl',
        data,
      });
      window.opener.postMessage(result, '*');
      window.close();
    };

    if (
      !navigator.userAgent.toLowerCase().includes('ecp/android_webview') &&
      !navigator.userAgent.toLowerCase().includes('ecp/ios_webview')
    ) {
      callRequest();
    }
  }, [location.search]);

  return <div></div>;
};

export default MobiliansOkUrlPage;
