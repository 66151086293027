import React from 'react';

import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import App from './App';
import * as Netfunnel from '@ecp/common/src/utils/netfunnel.d.ts';
import { getExpiredLocalStorage } from '@ecp/common/src/utils/utils';

function rootRender() {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </>
  );  
}

if(getExpiredLocalStorage("netfunnel_pass")) rootRender();
else {
  Netfunnel.NetFunnel_Action({}, ()=> {
    rootRender();
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
