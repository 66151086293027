import React, { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { openWindow } from '@ecp/common/src/utils/utils';
import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn, ImageBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Accordion from '@ecp/common/src/components/accordion/mo/Accordion';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';
import { ReactComponent as IsmspSrc } from '@ecp/common/src/assets/image/image__ismsp.svg';

import { withMobileStatus } from '@mo-recoil/system/selector';
import useSiteInfo from '@mo-hooks/common/useSiteInfo';
import PaymentGuaranteePopup from './popup/PaymentGuaranteePopup';
import IsmspPopup from './popup/IsmspPopup';

const InfoTextBox = styled(TextBox)`
  font-size: ${({ size }) => size || moTheme.font.size.$12};
  line-height: ${moTheme.font.lineHeight.demiLight};
  font-weight: ${({ weight }) => weight || moTheme.font.weight.demiLight};
  color: ${({ color }) => color || theme.color.text.guide};
`;

const CompanyInfo = () => {
  const {
    systemInformation: { mobileFooterImgFilePath },
  } = useSiteInfo();
  const { isMobileApp, agentType } = useRecoilValue(withMobileStatus);

  const [paymentGuaranteeOpen, setPaymentGuaranteeOpen] = useState(false);
  const [ismspOpen, setIsmspOpen] = useState(false);

  const handleClosePaymentGuaranteePopup = useCallback(() => setPaymentGuaranteeOpen(false), []);
  const handleCloseIsmspPopup = useCallback(() => setIsmspOpen(false), []);

  return (
    <FlexBoxColumn padding={'14px 20px'} align-items={'flex-start'} width={'100%'}>
      <Accordion>
        <FlexBox width={'max-content'}>
          <Accordion.Control
            buttonPadding={'5px 0 0 0'}
            align-items={'center'}
            buttonAriaLabel={'삼성웰스토리 사업자 정보'}
          >
            <InfoTextBox size={moTheme.font.size.$13} color={theme.color.text.basic}>
              삼성웰스토리 사업자 정보
            </InfoTextBox>
            <Spacing left={5} />
          </Accordion.Control>
        </FlexBox>
        <Accordion.Body>
          <FlexBoxColumn align-items={'flex-start'} padding={'10px 0 0 0'}>
            <FlexBoxColumn align-items={'flex-start'}>
              <FlexBox>
                <InfoTextBox>대표이사 정해린</InfoTextBox>
                <Spacing left={20} />
                <InfoTextBox>경기도 용인시 용구대로 2442-1</InfoTextBox>
              </FlexBox>
              <Spacing top={3} />
              <InfoTextBox>문의전화 080-288-5252</InfoTextBox>
              <Spacing top={3} />
              <InfoTextBox>이메일 welmall.sei@samsung.com </InfoTextBox>
              <Spacing top={3} />
              <InfoTextBox>호스팅 사업자 : 삼성SDS(주)</InfoTextBox>
            </FlexBoxColumn>
            <Spacing top={10} />
            <FlexBoxColumn align-items={'flex-start'}>
              <InfoTextBox>사업자등록번호 142-85-21591</InfoTextBox>
              <TextButton
                textProps={{
                  weight: moTheme.font.weight.medium,
                  size: moTheme.font.size.$12,
                  color: theme.color.text.basic,
                  height: moTheme.font.lineHeight.demiLight,
                }}
                onClick={() => {
                  openWindow(
                    isMobileApp,
                    'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1428521591',
                    '_blank',
                    agentType
                  );
                }}
              >
                [사업자 확인]
              </TextButton>
              <InfoTextBox>통신판매업 신고번호 (제2013-용인기흥-00548호)</InfoTextBox>
              <InfoTextBox>부가통신사업자 제2-01-16-0057호</InfoTextBox>
              <InfoTextBox>건강기능식품판매업 제2004-0350118호</InfoTextBox>
            </FlexBoxColumn>
            <Spacing top={10} />
            <FlexBoxColumn align-items={'flex-start'}>
              <FlexBoxColumn align-items={'flex-start'}>
                <InfoTextBox color={theme.color.text.basic}>우리은행 채무자지급보증 안내</InfoTextBox>
                <InfoTextBox>
                  웰스토리몰은 전자상거래 법률에 따라 고객님의 현금결제금액에 대한 안전거래를 보장하고 있습니다.
                </InfoTextBox>
              </FlexBoxColumn>
              <Spacing top={4} />
              <TextButton
                textProps={{
                  weight: moTheme.font.weight.medium,
                  size: moTheme.font.size.$12,
                  color: theme.color.text.basic,
                  height: moTheme.font.lineHeight.demiLight,
                  type: 'Button',
                }}
                onClick={() => setPaymentGuaranteeOpen(true)}
              >
                [서비스 가입사실 확인]
              </TextButton>
              <Spacing top={4} />
              <FlexBox>
                <IsmspSrc width={'30px'} height={'23px'} />
                <Spacing left={6} />
                <TextButton
                  textProps={{
                    color: theme.color.text.guide,
                    size: moTheme.font.size.$12,
                    weight: moTheme.font.weight.demiLight,
                    height: moTheme.font.lineHeight.demiLight,
                    type: 'Button',
                  }}
                  onClick={() => setIsmspOpen(true)}
                >
                  정보보호및개인정보보호관리체계
                </TextButton>
              </FlexBox>
            </FlexBoxColumn>
          </FlexBoxColumn>
        </Accordion.Body>
      </Accordion>
      <Spacing top={'20px'} />
      <ImageBox
        imageSrc={mobileFooterImgFilePath}
        width={'100px'}
        height={'15px'}
        backgroundPositionY={'top'}
        background-size={'contain'}
      />
      <Spacing top={'3px'} />
      <InfoTextBox>© SAMSUNG WELSTORY INC ALL RIGHTS RESERVED</InfoTextBox>
      <PaymentGuaranteePopup open={paymentGuaranteeOpen} onClose={handleClosePaymentGuaranteePopup} />
      <IsmspPopup open={ismspOpen} onClose={handleCloseIsmspPopup} />
    </FlexBoxColumn>
  );
};

export default CompanyInfo;
