import React from 'react';
import PropTypes from 'prop-types';

import { ImageBox } from '@ecp/common/src/layouts/flex/styled';
import FullPagePopup from '@ecp/common/src/components/modal/mo/popup/FullPagePopup';

const PaymentGuaranteePopup = ({ open, onClose }) => {
  return (
    <FullPagePopup
      open={open}
      onClose={onClose}
      header={'서비스 가입 사실 확인'}
      useCancelButton={false}
      onConfirmClick={onClose}
      buttonPosition="up"
    >
      <ImageBox
        imageSrc={`${process.env.REACT_APP_CLOUDFRONT_URL}/contents/250f63f2aa774ad5bdb0676723e036ea/a5e8d3f2fccd418d8db2b4ee0f4c588a.jpg`}
        width={'100%'}
        height={'100%'}
        background-size={'contain'}
        style={{ minHeight: '423px' }}
      />
    </FullPagePopup>
  );
};

export default PaymentGuaranteePopup;

PaymentGuaranteePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
