import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import {
  FlexBox,
  FlexBoxCenter,
  FlexBoxColumn,
  HorizontalDivider,
  VerticalDivider,
} from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import FullPagePopup from '@ecp/common/src/components/modal/mo/popup/FullPagePopup';

import commonApi from '@mo-apis/common/commonApi';

const CardInterestFreeGuidePopup = ({ open, onClose }) => {
  const [cardList, setCardList] = useState([]);

  useEffect(() => {
    (async () => {
      const { result } = await commonApi.getCommonCodes('CD0001');
      setCardList(result?.codeDetails);
    })();
  }, []);

  return (
    <FullPagePopup
      header={'무이자 할부 안내'}
      open={open}
      onClose={onClose}
      onConfirmClick={onClose}
      buttonPosition={'up'}
      useCancelButton={false}
    >
      <FlexBoxColumn width={'100%'}>
        <HorizontalDivider color={theme.color.line.emphasis} />
        {cardList.map((card, index) => (
          <FlexBoxColumn key={index} width={'100%'}>
            <RowWrapper>
              <FlexBoxColor>
                <TextBox size={moTheme.font.size.$14} weight={moTheme.font.weight.medium}>
                  {card.codeName}
                </TextBox>
              </FlexBoxColor>
              <FlexBoxCenter flex={1}>
                <TextBox size={moTheme.font.size.$14} weight={moTheme.font.weight.demiLight}>
                  {card.groupCode1}
                </TextBox>
              </FlexBoxCenter>
              <VerticalDivider />
              <FlexBoxCenter flex={1}>
                <TextBox
                  size={moTheme.font.size.$14}
                  weight={moTheme.font.weight.demiLight}
                  color={theme.color.text.guide}
                >
                  {card.groupCode2}
                </TextBox>
              </FlexBoxCenter>
            </RowWrapper>
            <HorizontalDivider />
          </FlexBoxColumn>
        ))}
      </FlexBoxColumn>
    </FullPagePopup>
  );
};

const RowWrapper = styled(FlexBox)`
  width: 100%;
`;

const FlexBoxColor = styled(FlexBox)`
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 12px 14px;
  background: ${theme.color.background.basic};
`;

export default CardInterestFreeGuidePopup;

CardInterestFreeGuidePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
