import React from 'react';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

import { ReactComponent as ErrorIcon } from '@mo-assets/icon/icon__server_error.svg';

const Notice = styled.p`
  width: 320px;
  font-weight: 350;
  font-size: ${moTheme.font.size.$14};
  line-height: 160%;
  text-align: center;
  color: #141415;
`;
const ServerError = () => {
  return (
    <Container>
      <ErrorIcon width="92px" height="80px"></ErrorIcon>
      <Spacing top={30} />
      <TextBox weight={500} size={moTheme.font.size.$18}>
        이 서비스와 연결할 수 없습니다.
      </TextBox>
      <Spacing top={20} />
      <Notice>
        문제 해결을 위해 노력하고 있습니다.
        <br /> 잠시 후 다시 확인해 주세요.
        <br /> 페이지 오류가 계속 발생할 경우
        <br /> 아래 연락처로 문의 부탁 드립니다.
      </Notice>
    </Container>
  );
};

export default ServerError;

const Container = styled(FlexBoxColumn)`
  width: 100%;
`;
