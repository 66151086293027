import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import StyledImg from '@ecp/common/src/components/image/StyledImg';

import { withCategoryById } from '@mo-recoil/category/selector';
import ShareButton from '@mo-components/common/share/ShareButton';

const CategoryHeader = () => {
  const { categoryNumber } = useParams();
  const category = useRecoilValue(withCategoryById(categoryNumber));
  return (
    <Container>
      <StyledImg
        src={category?.largeCategoryMobileImageUrl}
        alt={category?.categoryName}
        width={'100%'}
        height={'120px'}
      />
      <ShareButton
        kakaoProps={{
          type: 'scrap',
          title: category?.categoryName,
        }}
        imageProps={{
          style: {
            position: 'absolute',
            top: '76px',
            right: '30px',
          },
        }}
      />
    </Container>
  );
};
export default CategoryHeader;
const Container = styled(FlexBoxColumn)`
  width: 100%;
`;
