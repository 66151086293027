import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';
import { ReactComponent as IconLeftArrow } from '@ecp/common/src/assets/icon/icon__bo__arrow--left.svg';

const SearchFilterPopupCategoryItem = ({ label, type, selected, ...props }) => {
  const itemProps = useMemo(() => {
    if (['primary', 'default'].includes(type)) {
      return { padding: '14px 0 0 0' };
    } else if (type === 'secondary') {
      return { padding: '10px 0 0 10px' };
    } else if (type === 'third') {
      return { padding: '6px 0 0 25px' };
    }
  }, [type]);

  const textProps = useMemo(() => {
    const color = () => {
      if (selected) {
        return theme.color.primary;
      }

      if (type === 'third') {
        return theme.color.text.sub;
      }

      return theme.color.text.basic;
    };

    return {
      color: color(),
      weight:
        selected || ['primary', 'secondary'].includes(type)
          ? moTheme.font.weight.medium
          : moTheme.font.weight.demiLight,
      size: moTheme.font.size.$13,
    };
  }, [selected, type]);

  return (
    <>
      {type !== 'none' && (
        <FlexBox width={'100%'} {...itemProps}>
          {type === 'primary' && (
            <>
              <IconLeftArrow />
              <Spacing left={6} />
            </>
          )}
          <TextButton {...props} textProps={textProps}>
            {label}
          </TextButton>
        </FlexBox>
      )}
    </>
  );
};

export default SearchFilterPopupCategoryItem;

SearchFilterPopupCategoryItem.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'third', 'default', 'none']),
  selected: PropTypes.bool,
};

SearchFilterPopupCategoryItem.defaultProps = {
  type: 'primary',
};
