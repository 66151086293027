import React from 'react';
import { Route, Routes } from 'react-router-dom';

import WpayMemberRegPage from '@mo-pages/interface/wpay/WpayMemberRegPage';
import WpayMyPage from '@mo-pages/interface/wpay/WpayMyPage';
import WpayPaymentAuthPage from '@mo-pages/interface/wpay/WpayPaymentAuthPage';
import WpayPaymentAuthReturn from '@mo-pages/interface/wpay/WpayPaymentAuthReturn';
import WpayMemberReturnPage from '@mo-pages/interface/wpay/WpayMemberReturnPage';
import InicisPaySamplePage from '@mo-pages/interface/inicis/InicisPaySamplePage';
import MobiliansForm from '@mo-pages/interface/mobilians/MobiliansForm';
import MobiliansClosePage from '@mo-pages/interface/mobilians/MobiliansClosePage';
import MobiliansOkUrlPage from '@mo-pages/interface/mobilians/MobiliansOkUrlPage';
import PaycoReturnPage from '@mo-pages/interface/payco/PaycoReturnPage';

const InterfaceRoutes = () => (
  <Routes>
    <Route path="wpay-member-reg" element={<WpayMemberRegPage />} />
    <Route path="wpay-my-page" element={<WpayMyPage />} />
    <Route path="wpay-payment-auth" element={<WpayPaymentAuthPage />} />
    <Route path="wpay-payment-auth-return" element={<WpayPaymentAuthReturn />} />
    <Route path="wpay-return" element={<WpayMemberReturnPage />} />
    <Route path="inicis" element={<InicisPaySamplePage />} />
    <Route path="mobilians-information" element={<MobiliansForm />} />
    <Route path="mobilians-close" element={<MobiliansClosePage />} />
    <Route path="mobilians-pay-close" element={<MobiliansClosePage />} />
    <Route path="mobilians-ok-url" element={<MobiliansOkUrlPage />} />
    <Route path="payco-return" element={<PaycoReturnPage />} />
  </Routes>
);
export default InterfaceRoutes;
