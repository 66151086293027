import React, { useLayoutEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil'
import { getIsMultimall, withMobileStatus } from '@mo-recoil/system/selector';
import { appStoreLinkBannerState } from '@mo-recoil/banner/atom';
import styled from "styled-components";
import { FlexBox, FlexBoxCenter, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { PageButton } from '@ecp/common/src/components/button/mo/Button';
import { ReactComponent as IconRight } from '@ecp/common/src/assets/icon/icon__arrow--right.svg';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { zIndex } from '@ecp/common/src/style/constant';
import theme from '@ecp/common/src/style/theme/default';
import { ReactComponent as CloseButton } from '@mo-assets/icon/icon__close.svg';
import { getSessionStorageItem, saveSessionStorageItem } from '@ecp/common/src/utils/utils';

const AppStoreLinkBanner = () => {
    const isMultimall = useRecoilValue(getIsMultimall);
    const { isMobileApp } = useRecoilValue(withMobileStatus);
    const [ appLinkBanner, setAppLinkBanner ] = useRecoilState(appStoreLinkBannerState)
    const [ closeBanner, setCloseBanner] = useState(JSON.parse(getSessionStorageItem('closeBanner') || false));
    const { pathname } = useLocation();
    
    useLayoutEffect(() => {
        const showBanner = (!isMultimall && !isMobileApp) && !closeBanner
                            && pathname != '/sign-up';
        setAppLinkBanner({
            show: showBanner,
            height: showBanner ? 80 : 0,
            })
    }, [isMultimall, isMobileApp, closeBanner, pathname]);

    const handleCloseBanner = () => {
        setCloseBanner(true);
        saveSessionStorageItem('closeBanner', true);
    }
    
    const linkToApp = () => {
        //딥링크
        window.location.href = 'https://welstorymall.onelink.me/ES3K/n38wty42?pid=my_media_source&af_force_deeplink=true&af_url=https%3A%2F%2Fecmo.welstorymall.com%2F';
    }

    return (
        appLinkBanner.show && (
            <Container height={appLinkBanner.height}>
                <AppLinkBanner>
                    <CloseButton width={16} height={16} onClick={handleCloseBanner}/>
                    <Spacing left={15} />
                    <FlexBoxColumn>
                        <Text> 삼성 임직원만의 특별한 혜택</Text>
                        <Text><TextBox weight={theme.font.weight.medium} color={theme.color.primary}>삼성웰스토리몰 앱</TextBox>으로 보기</Text>
                    </FlexBoxColumn>
                    <Spacing left={30} />
                    <FlexBoxColumn>
                        <PageButton width={'96px'} height={'46px'} style={{fontSize: '13px'}} onClick={linkToApp}>
                            {'앱열기'}
                            <Spacing left={5} />
                            <IconRight width={'5px'} height={'9px'} />
                        </PageButton>
                    </FlexBoxColumn>
                </AppLinkBanner>
            </Container>
        )
    )
}

const Container = styled(FlexBox)`
    z-index: ${zIndex.floating};
    position: sticky;
    margin: 0 auto;
    width: 360px;
    height: ${({height}) => height}px;
    top: 0px;
`
const AppLinkBanner = styled(FlexBoxCenter)`
    background-color: ${theme.color.sub_darkgray};
    margin: 0 auto;
    width: 100%;
    height: 100%;
`

const Text = styled(TextBox)`
    color: white;
    weight: ${theme.font.weight.medium};
`

export default AppStoreLinkBanner;
