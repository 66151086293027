import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Input from '@ecp/common/src/components/input/mo/Input';
import { MAX_ID_INPUT_LENGTH, MIN_ID_INPUT_LENGTH } from '@ecp/common/src/const/input';

const IdInput = ({
  value,
  dataKey = 'loginId',
  styleProps,
  placeholder = `아이디(영문,숫자 ${MIN_ID_INPUT_LENGTH}~${MAX_ID_INPUT_LENGTH}자리)`,
  onChange,
  disabled,
  title,
  id,
}) => {
  const handleChangeId = useCallback(
    (e) => {
      e.target.value = e.target.value.replace(/\s/g, '');
      onChange(e);
    },
    [onChange]
  );

  return (
    <Input
      clearable
      height={styleProps?.height}
      width={styleProps?.width || '100%'}
      minWidth={styleProps?.minWidth}
      placeholder={placeholder}
      value={value}
      onChange={handleChangeId}
      disabled={disabled}
      data-key={dataKey}
      maxLength={MAX_ID_INPUT_LENGTH}
      title={title}
      id={id}
    />
  );
};

IdInput.propTypes = {
  value: PropTypes.string,
  dataKey: PropTypes.string,
  styleProps: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
};

export default IdInput;
