import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { flexCenter } from '@ecp/common/src/style/theme/defaultStyled';
import moTheme from '@ecp/common/src/style/theme/mo';
import { zIndex } from '@ecp/common/src/style/constant';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { PageButton } from '@ecp/common/src/components/button/mo/Button';
import { placements } from '@ecp/common/src/components/unstyled/floating/useModal';
import Modal from '@ecp/common/src/components/modal/mo/Modal';

const MessageBox = styled.div`
  ${flexCenter};
  width: 100%;
  height: fit-content;
  flex: 1;
  padding: 0 0 10px 0;
  font-weight: ${moTheme.font.weight.demiLight};
  font-size: ${moTheme.font.size.$14};
  line-height: ${moTheme.font.lineHeight.demiLight};
  white-space: pre-wrap;
  word-break: keep-all;
  text-align: center;
`;

const StructurePaper = ({ width, title, content, bottom }) => {
  return (
    <FlexBoxColumn width={width}>
      {title && (
        <FlexBox height={'16px'}>
          {title}
          <button></button>
        </FlexBox>
      )}
      <Spacing top={30} />
      <MessageBox>{content}</MessageBox>
      <Spacing top={30} />
      <FlexBox justify-content={'center'}>{bottom}</FlexBox>
    </FlexBoxColumn>
  );
};

StructurePaper.displayName = 'StructurePaper';

StructurePaper.propTypes = {
  width: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  bottom: PropTypes.node,
  onClose: PropTypes.func,
  padding: PropTypes.string,
};

export const AlertDialog = ({ message, children, open, onClose, confirmButtonText = '확인' }) => {
  const handleCloseClick = () => onClose?.();

  const bottomComponent = () => (
    <PageButton width={'120px'} onClick={handleCloseClick}>
      {confirmButtonText}
    </PageButton>
  );

  const AlertComponent = ({ onClose }) => {
    const handleClose = () => onClose?.();

    return <StructurePaper width={'220px'} content={message} bottom={bottomComponent({ onClose: handleClose })} />;
  };

  return (
    <Modal placement={'center'} value={AlertComponent} closeWhenOutside={false} open={open} zIndex={zIndex.dialog}>
      {children}
    </Modal>
  );
};

AlertDialog.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  confirmButtonText: PropTypes.string,
  placement: PropTypes.oneOf(placements),
};

export const ConfirmDialog = ({
  message,
  children,
  open,
  onConfirm,
  onClose,
  confirmButtonText = '확인',
  closeButtonText = '취소',
}) => {
  const bottomComponent = () => (
    <>
      <PageButton type={'Secondary'} width={'120px'} onClick={onClose}>
        {closeButtonText}
      </PageButton>
      <Spacing left={10} />
      <PageButton width={'120px'} onClick={onConfirm}>
        {confirmButtonText}
      </PageButton>
    </>
  );

  const ConfirmComponent = ({ onClose }) => {
    const handleClose = () => {
      onClose?.();
    };
    return <StructurePaper width={'220px'} content={message} bottom={bottomComponent({ onClose: handleClose })} />;
  };

  return (
    <Modal placement={'center'} value={ConfirmComponent} closeWhenOutside={false} open={open} zIndex={zIndex.dialog}>
      {children}
    </Modal>
  );
};

ConfirmDialog.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  placement: PropTypes.oneOf(placements),
  confirmButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
};
