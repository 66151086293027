import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { zIndex } from '@ecp/common/src/style/constant';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import FloatingUnstyled from '@ecp/common/src/components/unstyled/floating/FloatingUnstyled';

const Floating = (props) => {
  const { content: TopContentComponent, buttonContent: ButtonContentComponent } = props;

  const rootStyleProps = {
    position: 'fixed',
    bottom: '0',
    left: '0',
    right: '0',
    margin: '0 auto',
    zIndex: zIndex.floating,
  };
  return (
    <FloatingUnstyled
      open
      rootComponent={StyledFloatingWrapper}
      rootProps={{ style: { ...rootStyleProps } }}
      value={
        <>
          {TopContentComponent && <TopContentComponent />}
          {ButtonContentComponent && <ButtonContentComponent />}
        </>
      }
    />
  );
};

Floating.displayName = 'Floating';

Floating.propTypes = {
  content: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node]),
  buttonContent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node]),
};

export default Floating;

const StyledFloatingWrapper = styled(FlexBoxColumn)`
  background-color: #ffffff;
  width: 100%;

  &:focus-visible {
    outline: none;
  }
`;
