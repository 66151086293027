import { atom } from 'recoil';

export const filterPopupState = atom({
  key: 'filterPopupState',
  default: {
    open: false,
    tab: 'category',
  },
});
export const filterConditionState = atom({
  key: 'filterConditionState',
  default: {
    category: {},
    hashTagList: [],
  },
});

export const exhibitionListState = atom({
  key: 'exhibitionListState',
  default: [],
});
