import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { ReactComponent as MaintenanceIcon } from '@ecp/common/src/assets/icon/icon__error-maintenance.svg';

const Container = styled(FlexBoxColumn)`
  width: 100%;
  min-height: var(--vh-100);
  margin: 0 auto;
  padding: 20px;
`;

const MessageBox = styled(FlexBoxColumn)`
  padding: 20px;
  background: ${theme.color.background.basic};
  border-radius: 8px;
`;

const Message = ({ label, content }) => {
  return (
    <FlexBoxColumn width={'100%'} align-items={'flex-start'}>
      <TextBox
        size={moTheme.font.size.$14}
        weight={moTheme.font.weight.medium}
        height={moTheme.font.lineHeight.demiLight}
      >
        {label}
      </TextBox>
      <Spacing top={3} />
      <TextBox
        size={moTheme.font.size.$14}
        weight={moTheme.font.weight.demiLight}
        height={moTheme.font.lineHeight.demiLight}
        color={theme.color.text.sub}
      >
        {content}
      </TextBox>
    </FlexBoxColumn>
  );
};

Message.propTypes = {
  label: PropTypes.string,
  content: PropTypes.string,
};

const MaintenancePage = () => {
  return (
    <Container>
      <Spacing top={190} />
      <MaintenanceIcon />
      <Spacing top={30} />
      <FlexBoxColumn width={'100%'}>
        <TextBox
          size={moTheme.font.size.$18}
          weight={moTheme.font.weight.medium}
          height={moTheme.font.lineHeight.demiLight}
        >
          삼성웰스토리몰 서버 점검 안내
        </TextBox>
        <Spacing top={20} />
        <TextBox
          size={moTheme.font.size.$14}
          weight={moTheme.font.weight.demiLight}
          align-items={'center'}
          style={{ textAlign: 'center' }}
          height={moTheme.font.lineHeight.demiLight}
        >
          불편을 끼쳐드려서 죄송합니다.
          <br />
          온라인 쇼핑몰 및 모바일 앱 시스템 안정화를 위해
          <br />
          서비스 점검 중입니다.
          <br />
          점검시간 동안 서비스 이용이 일시 중단되오니
          <br />
          고객님의 깊은 양해 부탁 드립니다.
        </TextBox>
      </FlexBoxColumn>
      <Spacing top={30} />
      <MessageBox>
        <Message label={'점검시간'} content={'2022.08.15 24:00 ~ 2022.08.16 06:00'} />
        <Spacing top={14} />
        <Message label={'고객센터'} content={'080-288-5252 / welmall.sei@samsung.com'} />
      </MessageBox>
    </Container>
  );
};

export default MaintenancePage;
