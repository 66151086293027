import { atom } from 'recoil';

export const recommendBrandListState = atom({
  key: 'recommendBrandListStateMo',
  default: [],
});

export const bestBrandListState = atom({
  key: 'bestBrandListState',
  default: [],
});

export const brandGoodListState = atom({
  key: 'brandGoodListState',
  default: [],
});

export const brandGoodListUrlState = atom({
  key: 'brandGoodListUrlState',
  default: '',
});

export const brandGoodTotalCountState = atom({
  key: 'brandGoodTotalCountState',
  default: 0,
});
