import { useMemo } from 'react';

export default function useParkPointOrderPage() {
  const orderGoodType = useMemo(() => {
    return {
      directForeignGood: false,
      alcohol: false,
      ticket: false,
      pointGood: true,
      rental: false,
      giftCertificate: false,
    };
  }, []);

  return {
    orderGoodType,
  };
}
