import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Paper from '@ecp/common/src/components/paper/Paper';
import { Radio } from '@ecp/common/src/components/radio/mo/Radio';
import { InnerButton } from '@ecp/common/src/components/button/mo/Button';

const DeliveryPlaceSelectionItem = ({
  memberDeliverySequence,
  deliveryLocationName,
  base,
  receiverName,
  cellphoneNumber1,
  cellphoneNumber2,
  cellphoneNumber3,
  telephoneNumber1,
  telephoneNumber2,
  telephoneNumber3,
  postNumber,
  baseAddress,
  detailAddress,
  selected,
  onClick,
  onModificationClick,
  onDeleteClick,
}) => {
  const handleModificationClick = useCallback(
    (e) => {
      e.stopPropagation();
      onModificationClick(memberDeliverySequence);
    },
    [memberDeliverySequence, onModificationClick]
  );

  const handleDeleteClick = useCallback(
    async (e) => {
      e.stopPropagation();
      onDeleteClick(memberDeliverySequence);
    },
    [memberDeliverySequence, onDeleteClick]
  );

  const handleClick = useCallback(() => {
    onClick(memberDeliverySequence);
  }, [memberDeliverySequence, onClick]);

  return (
    <Container className={selected ? 'active' : ''} onClick={handleClick}>
      <FlexBoxSpaceBetween width="100%">
        <LocationNameWrapper>
          <Radio value={memberDeliverySequence} />
          {base && <BadgeText>기본배송지</BadgeText>}
          <TextBox size={moTheme.font.size.$14} weight={moTheme.font.weight.bold} ellipsis>
            {deliveryLocationName}
          </TextBox>
        </LocationNameWrapper>
        <FlexBox gap="4px" justify-content="flex-end">
          <InnerButton width="44px" type="Default" onClick={handleModificationClick} ariaLabel={baseAddress + detailAddress + ', 수정'}>
            수정
          </InnerButton>
          {!base && (
            <InnerButton width="44px" type="Third" onClick={handleDeleteClick} ariaLabel={baseAddress + detailAddress + ', 삭제'}>
              삭제
            </InnerButton>
          )}
        </FlexBox>
      </FlexBoxSpaceBetween>
      <Spacing top={10} />
      <FlexBoxColumn align-items="flex-start">
        <TextBoxAddress>
          {receiverName} / {[cellphoneNumber1, cellphoneNumber2, cellphoneNumber3].filter((v) => v).join('-')} /{' '}
          {[telephoneNumber1, telephoneNumber2, telephoneNumber3].filter((v) => v).join('-')}
        </TextBoxAddress>
        <TextBoxAddress>
          [{postNumber}] {baseAddress}, {detailAddress}
        </TextBoxAddress>
      </FlexBoxColumn>
    </Container>
  );
};

DeliveryPlaceSelectionItem.propTypes = {
  memberDeliverySequence: PropTypes.string,
  deliveryLocationName: PropTypes.string,
  base: PropTypes.bool,
  receiverName: PropTypes.string,
  cellphoneNumber1: PropTypes.string,
  cellphoneNumber2: PropTypes.string,
  cellphoneNumber3: PropTypes.string,
  telephoneNumber1: PropTypes.string,
  telephoneNumber2: PropTypes.string,
  telephoneNumber3: PropTypes.string,
  postNumber: PropTypes.string,
  baseAddress: PropTypes.string,
  detailAddress: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  onModificationClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

DeliveryPlaceSelectionItem.defaultProps = {
  base: false,
};

export default DeliveryPlaceSelectionItem;

const Container = styled(Paper)`
  width: 100%;
  border-bottom: 1px solid ${theme.color.line.basic};
  padding: 14px 0;

  &:hover {
    cursor: pointer;
  }
`;

const LocationNameWrapper = styled(FlexBox).attrs(() => ({ gap: '4px' }))`
  overflow: auto;
`;

const TextBoxAddress = styled(TextBox).attrs(() => ({
  color: theme.color.text.sub,
  size: moTheme.font.size.$13,
}))`
  padding: 0 0 0 30px;
`;

const BadgeText = styled.span`
  display: inline-block;
  width: 72px;
  height: 24px;
  padding-top: 2px;
  background: #dfdfdf;
  border-radius: 4px;
  font-weight: 300;
  font-size: ${moTheme.font.size.$13};
  text-align: center;
  flex-shrink: 0;
`;
