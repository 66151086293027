import React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

import { navigationOffsetState } from '@mo-recoil/common/navigation/atom';
import { showFooterState } from '@mo-recoil/common/footer/atom';
import ContactInfo from './ContactInfo';
import CompanyInfo from './CompanyInfo';

const PageFooter = () => {
  const showFooter = useRecoilValue(showFooterState);
  const navigationOffset = useRecoilValue(navigationOffsetState);

  return (
    <>
      {showFooter && (
        <Container>
          <ContactInfo />
          <CompanyInfo />
          <Spacing top={navigationOffset} />
        </Container>
      )}
    </>
  );
};

export default PageFooter;

const Container = styled(FlexBoxColumn)`
  width: 360px;
  box-sizing: border-box;
  background-color: ${theme.color.background.white};
`;
