import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import clsx from 'clsx';

import { LabelFlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { ReactComponent as IconAdd } from '@ecp/common/src/assets/icon/icon__add.svg';

import useCheckbox from './useCheckbox';
import CheckBoxComponent from './default/CheckBoxComponent';
import FullPagePopup from '../../modal/mo/popup/FullPagePopup';
export const checkboxClassNames = {
  disabled: 'checkbox-disabled',
  checked: 'checkbox-checked',
  unchecked: 'checkbox-unchecked',
};

const HiddenCheckbox = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckboxAddUnstyled = React.forwardRef((props, ref) => {
  const {
    rootComponent,
    rootProps,
    checkboxComponent,
    label: Content,
    labelProps,
    imageProps,
    value,
    children,
    title,
    playGb,
    playGbAddTitle,
    itemList,
    handleItemClick,
    itemComponent: Item,
    itemProps,
    ...otherProps
  } = props;

  const Root = rootComponent;
  const CheckboxImage = checkboxComponent;

  const { checked, disabled, getInputProps, handleClick } = useCheckbox(otherProps);

  const stateClasses = {
    [checkboxClassNames.disabled]: disabled,
    [checkboxClassNames.checked]: checked,
    [checkboxClassNames.unchecked]: !checked,
  };

  const [open, setOpen] = useState(false);

  const handlePopupClose = () => {
    setOpen(false);
  };

  return (
    <Root {...rootProps} style={{ display: 'flex' }} onClick={handleClick}>
      <HiddenCheckbox
        ref={ref}
        className={clsx(stateClasses)}
        value={value}
        {...getInputProps()}
        title={playGb === 'Y' ? (checked ? '재생' + ' ' + playGbAddTitle : '정지' + ' ' + playGbAddTitle) : title}
      />
      <CheckboxImage className={clsx(stateClasses)} checked={checked} {...imageProps} />
      {Content &&
        (typeof Content === 'string' ? (
          <TextBox {...labelProps} title={Content}>
            {Content}
          </TextBox>
        ) : (
          <>
            <Content {...labelProps} />

            {props.btnViewAllFlag == 'Y' && (
              <>
                <FullPagePopup
                  header={'전체보기'}
                  open={open}
                  onClose={handlePopupClose}
                  useCancelButton={false}
                  useConfirmButton={false}
                >
                  <FlexBoxColumn width="100%">
                    {itemList.map((item, index) => (
                      // <ImageBox
                      //   key={index}
                      //   imageSrc={item.bannerMobileImageUrl || item.bannerCommonImageUrl}
                      //   background-color={item.bannerBackgroundColorCode}
                      //   style={{ width: '360px', height: '280px' }}
                      // />
                      <Item
                        key={'banner' + index}
                        item={item}
                        itemProps={itemProps}
                        onClick={(e) => handleItemClick(e, index, item)}
                      />
                    ))}
                  </FlexBoxColumn>
                </FullPagePopup>
                <ViewAllButton
                  title="전체보기"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <IconAdd width="7px" height="10px" strokeWidth="10px" />
                </ViewAllButton>
              </>
            )}
          </>
        ))}
      {children}
    </Root>
  );
});

CheckboxAddUnstyled.propTypes = {
  rootComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string, PropTypes.node]),
  rootProps: PropTypes.object,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  checkboxComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType, PropTypes.element]),
  labelProps: PropTypes.object,
  imageProps: PropTypes.object,
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  hide: PropTypes.bool,
  title: PropTypes.string,
  playGb: PropTypes.string,
  playGbAddTitle: PropTypes.string,
  itemList: PropTypes.array,
  btnViewAllFlag: PropTypes.string,
  handleItemClick: PropTypes.func,
  itemComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  itemProps: PropTypes.object,
};

CheckboxAddUnstyled.defaultProps = {
  rootComponent: LabelFlexBox,
  defaultChecked: false,
  checkboxComponent: CheckBoxComponent,
  labelProps: {},
  rootProps: { gap: '10px' },
  label: '',
  dataKey: 'checkbox',
  value: '',
  title: 'checkbox',
  playGb: 'N',
  playGbAddTitle: '',
  itemList: [],
  btnViewAllFlag: 'N',
  itemProps: { width: '360px', height: '280px' },
};
CheckboxAddUnstyled.displayName = 'CheckboxAddUnstyled';

export default CheckboxAddUnstyled;

const ViewAllButton = styled.button`
  outline: none;
  display: flex;
  width: 7px;
  height: 10px;
`;
