import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { zIndex } from '@ecp/common/src/style/constant';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { ReactComponent as Question } from '@ecp/common/src/assets/icon/question.svg';

export const CouponContainer = styled(FlexBox)`
  box-sizing: border-box;
  z-index: 2;
  position: absolute;
  width: ${({ width = '320px' }) => width};
  height: 100%;
  border: 1px solid ${theme.color.line.background};
  border-radius: 8px;
`;

export const CouponLeft = styled(FlexBox)`
  width: 275px;
  height: 100%;
  justify-content: center;
  background-color: ${({ color = theme.color.text.white }) => color};
  border-radius: 8px;
`;

export const CouponRight = styled(FlexBox)`
  width: 43px;
  height: 100%;
  justify-content: center;
  background-color: ${({ color }) => color};
  border-radius: 0 7px 7px 0;
`;

export const CouponBoundaryPoint = styled(FlexBox)`
  z-index: ${zIndex.floating};
  position: absolute;
  width: 12px;
  height: 12px;
  top: ${({ topPos }) => `${topPos}px`};
  right: ${({ rightPos = 36 }) => `${rightPos}px`};
  border: 1px solid ${theme.color.line.background};
  background-color: ${({ color = theme.color.background.basic }) => color};
  border-radius: 999px;
`;

export const CouponText = styled(TextBox)`
  color: ${({ rightSideColor }) =>
    rightSideColor === theme.color.text.sub ? theme.color.background.white : theme.color.text.basic};
  width: 100%;
  justify-content: center;
  font-size: 12px;
  font-weight: ${theme.font.weight.medium};
  letter-spacing: 0.2rem;
  transform: rotate(-90deg);
`;

export const BadgeText = styled(TextBox)`
  width: 80px;
  height: 20px;
  border: ${({ badgeColor }) => (badgeColor ? `1px solid ${badgeColor}` : `1px solid ${theme.color.third}`)};
  color: ${({ badgeColor }) => (badgeColor ? `${badgeColor}` : `${theme.color.third}`)};
  font-size: ${moTheme.font.size.$12};
  justify-content: center;
  align-items: center;
`;

export const HelpIcon = styled(Question)`
  min-width: 18px;
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

export const CouponNameText = styled(TextBox)`
  width: ${({ width = '100%' }) => width};
  height: 36px;
  font-size: ${theme.font.size.$14};
  line-height: 140%;
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.line.emphasis};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
`;
