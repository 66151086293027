import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { getBrandData, initialSearchCondition, toConsonantMapper } from '@ecp/common/src/utils/good/brandUtil';
import { NumberFormat } from '@ecp/common/src/utils/utils';
import useSticky from '@ecp/common/src/hooks/useSticky';
import useScrollDirection from '@ecp/common/src/hooks/useScrollDirection';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { BENEFIT_MESSAGE } from '@ecp/common/src/const/promotion';

import { employeeBenefitMessageState } from '@mo-recoil/common/navigation/atom';
import { employeeBenefitBrandCountState } from '@mo-recoil/main/atom';
import { useGnb } from '@mo-hooks/common/useGnb';
import useRecommendBrandList from '@mo-hooks/brand/useRecommendBrandList';
import useNavigateBrandDetailPage from '@mo-hooks/brand/useNavigateBrandDetailPage';
import brandApi from '@mo-apis/good/brandApi';
import couponApi from '@mo-apis/coupon/couponApi';
import { gnbTypes } from '@mo-components/common/gnb/Gnb';
import RecommendBrand from '@mo-pages/brand/components/RecommendBrand';
import BrandSearchCondition from '@mo-pages/brand/components/BrandSearchCondition';
import BrandResult from '@mo-pages/brand/components/BrandResult';

const BrandPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  useGnb(gnbTypes.MENU);
  const { ref, sticky } = useSticky();
  const scrollDirection = useScrollDirection();
  const consonantSwiperRef = useRef(null);

  const { handleNavigateToBrandDetail } = useNavigateBrandDetailPage();

  const [categorySwiperHeight, setCategorySwiperHeight] = useState(56);
  const [searchCondition, setSearchCondition] = useState(initialSearchCondition);
  const [brandCountGroup, setBrandCountGroup] = useState({});
  const [brandGroup, setBrandGroup] = useState({});
  const recommendBrandList = useRecommendBrandList();
  const setEmployeeBenefitMessageState = useSetRecoilState(employeeBenefitMessageState);
  const setEmployeeBenefitBrandCountState = useSetRecoilState(employeeBenefitBrandCountState);
  const resetEmployeeBenefitMessage = useResetRecoilState(employeeBenefitMessageState);

  useEffect(() => {
    (async () => {
      const { result: couponList } = await couponApi.getBrandBenefitSummary();
      const { brand, count } = couponList;

      if (brand && count > 0) {
        setEmployeeBenefitMessageState(BENEFIT_MESSAGE.BRAND(NumberFormat(count)));
        setEmployeeBenefitBrandCountState(NumberFormat(count));
      }
    })();
    return () => resetEmployeeBenefitMessage();
  }, [resetEmployeeBenefitMessage, setEmployeeBenefitBrandCountState, setEmployeeBenefitMessageState]);

  const getSearchData = async (data) => {
    const requestParams = {
      searchKeyword: data.searchKeyword,
      largeDisplayCategoryNumber: data.largeDisplayCategoryNumber,
      initialConsonant: toConsonantMapper(data.initialConsonant),
    };
    const { result } = await brandApi.getBrandList(requestParams);

    const { brandCountGroup, brandGroup } = getBrandData(result);
    setBrandCountGroup(brandCountGroup);
    setBrandGroup(brandGroup);
  };

  const handleSearchConditionChange = ({
    searchKeyword,
    largeDisplayCategoryNumber,
    initialConsonant,
    consonantIndex,
    tabIndex,
  }) => {
    const dataObj = {
      ...state,
    };
    if (searchKeyword !== undefined) dataObj.searchKeyword = searchKeyword;
    if (largeDisplayCategoryNumber !== undefined) dataObj.categoryNumber = largeDisplayCategoryNumber;
    if (initialConsonant !== undefined) dataObj.consonant = initialConsonant;
    if (consonantIndex !== undefined) dataObj.consonantIndex = consonantIndex;
    if (tabIndex !== undefined) dataObj.tabIndex = tabIndex;

    navigate('/brand', {
      state: dataObj,
    });
  };

  const handleSearchConditionInitialize = () => {
    navigate('/brand');
  };

  const handleCategorySwiperArrowClick = useCallback(() => {
    setTimeout(() => {
      const swiperHeight = document.getElementById('brand-search-category-swiper')?.offsetHeight || 0;
      setCategorySwiperHeight(swiperHeight);
    }, 100);
  }, []);

  const handleShowMoreBrand = useCallback(
    async (consonant) => {
      const requestParams = {
        searchKeyword: searchCondition.searchKeyword,
        largeDisplayCategoryNumber: searchCondition.largeDisplayCategoryNumber,
        consonant: toConsonantMapper(consonant),
      };
      const { result = [] } = await brandApi.getBrandListByConsonant({
        page: brandGroup[consonant].page,
        size: 20,
        ...requestParams,
      });
      setBrandGroup((prev) => ({
        ...prev,
        [consonant]: { page: prev[consonant].page + 1, brandList: [...prev[consonant].brandList, ...result] },
      }));
    },
    [brandGroup, searchCondition.largeDisplayCategoryNumber, searchCondition.searchKeyword]
  );

  useEffect(() => {
    const search = {
      ...initialSearchCondition,
    };
    if (state && state.consonant) {
      search.initialConsonant = state.consonant;
      consonantSwiperRef?.current?.swiper?.slideTo(state.consonantIndex);
    } else {
      search.initialConsonant = '';
      consonantSwiperRef?.current?.swiper?.slideTo(0);
    }
    if (state && state.categoryNumber) {
      search.largeDisplayCategoryNumber = state.categoryNumber;
    } else {
      search.largeDisplayCategoryNumber = '';
    }
    if (state && state.searchKeyword) {
      search.searchKeyword = state.searchKeyword;
    } else {
      search.searchKeyword = '';
    }
    if (state && state.tabIndex) {
      search.tabIndex = state.tabIndex;
    }
    setSearchCondition(search);
    getSearchData(search);
  }, [state]);

  return (
    <FlexBoxColumn>
      <RecommendBrand recommendBrandList={recommendBrandList} />
      <Spacing top={30} />
      <BrandSearchCondition
        ref={ref}
        sticky={sticky}
        scrollDirection={scrollDirection}
        searchCondition={searchCondition}
        handleSearchConditionChange={handleSearchConditionChange}
        handleSearchConditionInitialize={handleSearchConditionInitialize}
        handleCategorySwiperArrowClick={handleCategorySwiperArrowClick}
      />
      <BrandResult
        categorySwiperHeight={categorySwiperHeight}
        consonantSwiperRef={consonantSwiperRef}
        sticky={sticky}
        scrollDirection={scrollDirection}
        selectedConsonant={searchCondition.initialConsonant}
        brandGroup={brandGroup}
        brandCountGroup={brandCountGroup}
        handleSearchConditionChange={handleSearchConditionChange}
        handleBrandClick={handleNavigateToBrandDetail}
        handleShowMoreBrand={handleShowMoreBrand}
        tabIndex={searchCondition.tabIndex}
      />
    </FlexBoxColumn>
  );
};

export default BrandPage;
