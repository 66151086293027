import { getRequest, postRequest } from '@mo-apis/axios';

const getInicisInformation = (data) => {
  return postRequest({
    url: '/api/v1/interfaces/inicis/mobile',
    data,
  });
};

const cancelInicis = (data) => {
  return postRequest({
    url: '/api/v1/interfaces/inicis/cancel',
    data,
  });
};

const refundInicis = (data) => {
  return postRequest({
    url: '/api/v1/interfaces/inicis/refund',
    data,
  });
};

const partialRefundInicis = (data) => {
  return postRequest({
    url: '/api/v1/interfaces/inicis/partial-refund',
    data,
  });
};

const partCancelInicis = (data) => {
  return postRequest({
    url: '/api/v1/interfaces/inicis/cancel-part',
    data,
  });
};

const createVirtualAccount = (data) => {
  return postRequest({
    url: '/api/v1/interfaces/inicis/virtual-account',
    data,
  });
};

const getInicisNetCancelInformation = (data) => {
  return getRequest({
    url: '/api/v1/interfaces/inicis/net-cancel',
    params: { ...data },
  });
};

const issueReceipt = (data) =>
  postRequest({
    url: '/api/v1/interfaces/inicis/issue-receipt',
    data,
  });

const checkAccount = (accountName, accountNo, bankCode) =>
  getRequest({
    url: '/api/v1/interfaces/inicis/check-account',
    params: { accountName, accountNo, bankCode },
  });

const getInicisMobileAutoBillInformation = (data) => {
  return postRequest({
    url: '/api/v1/interfaces/inicis/mobile/get-inicis-auto-bill',
    data,
  });
};

const inicisApi = {
  getInicisInformation,
  cancelInicis,
  refundInicis,
  partialRefundInicis,
  partCancelInicis,
  createVirtualAccount,
  getInicisNetCancelInformation,
  issueReceipt,
  checkAccount,
  getInicisMobileAutoBillInformation,
};

export default inicisApi;
