import React from 'react';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox, TextBoxAlignCenter } from '@ecp/common/src/text/TextBox';
import { PageButton } from '@ecp/common/src/components/button/mo/Button';

import { ReactComponent as ErrorIcon } from '@mo-assets/icon/icon__server_error.svg';

const UnExpectedErrorPage = () => {
  return (
    <Container>
      <Spacing top={180} />
      <StyledNotFoundIcon />
      <Spacing top={30} />
      <TextBox size={moTheme.font.size.$18} weight={'500'}>
        예상하지 못한 오류가 발생했습니다.
      </TextBox>
      <Spacing top={14} />
      <TextBoxAlignCenter font-weight={350} font-size={moTheme.font.size.$14}>
        <p>
          일시적인 현상이거나 네트워크 문제일 수 있으니,
          <br />
          잠시 후 다시 시도해 주세요.
          <br />
          오류가 계속 발생하는 경우 고객센터로
          <br />
          문의 부탁 드립니다.
        </p>
      </TextBoxAlignCenter>
      <Spacing top={20} />
      <MessageBox>
        <TextBox weight={500} size={moTheme.font.size.$14} line-height={'22px'}>
          고객센터
        </TextBox>
        <TextBox weight={350} size={moTheme.font.size.$14} line-height={'22px'}>
          080-288-5252 / welmall.sei@samsung.com
        </TextBox>
      </MessageBox>
      <Spacing top={30} />
      <PageButton type={'Secondary'} width={'256px'}>
        앱 종료
      </PageButton>
    </Container>
  );
};

export default UnExpectedErrorPage;

const Container = styled(FlexBoxColumn)`
  width: 100%;
  min-height: var(--vh-100);
  margin: 0 auto;
  padding: 0 20px;
`;

const StyledNotFoundIcon = styled(ErrorIcon)`
  width: 68px;
  height: 60px;
`;

const MessageBox = styled(FlexBoxColumn)`
  width: 320px;
  background-color: #f7f7f7;
  padding: 20px;
  align-items: flex-start;
  border-radius: 8px;
`;
