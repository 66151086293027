import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import { TextBox } from '@ecp/common/src/text/TextBox';

const PointsUsageBalance = ({ balance, unit }) => {
  return (
    <>
      <PointUsageItemHeaderBalanceWrap>잔여: </PointUsageItemHeaderBalanceWrap>
      <PointUsageItemHeaderBalance>{NumberFormat(balance)}</PointUsageItemHeaderBalance>
      <PointUsageItemHeaderBalanceWrap>{unit}</PointUsageItemHeaderBalanceWrap>
    </>
  );
};

export default PointsUsageBalance;

PointsUsageBalance.propTypes = {
  balance: PropTypes.number,
  unit: PropTypes.string,
};

const PointUsageItemHeaderBalanceWrap = styled(TextBox)`
  font-size: 13px;
  font-weight: 350;
  color: #5a5a5a;
`;

const PointUsageItemHeaderBalance = styled(TextBox)`
  color: #5a5a5a;
  font-size: 13px;
  font-weight: 700;
`;
