import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import StyledImg from '@ecp/common/src/components/image/StyledImg';

import useNavigateBrandDetailPage from '@mo-hooks/brand/useNavigateBrandDetailPage';

const BrandCard = ({ brand }) => {
  const { handleNavigateToBrandDetail } = useNavigateBrandDetailPage();

  const handleBrandClick = () => {
    handleNavigateToBrandDetail({ brandNumber: brand.brandNumber });
  };

  return (
    <FlexBoxColumn width={'100%'} data-ds-content={brand.brandNumber}>
      <BrandImage src={brand.mobileImageUrl} onClick={handleBrandClick} alt={''} />
      <Spacing top={6} />
      <BrandName onClick={handleBrandClick}>{brand.brandName}</BrandName>
    </FlexBoxColumn>
  );
};

BrandCard.propTypes = {
  brand: PropTypes.object,
};

export default BrandCard;

const BrandImage = styled(StyledImg)`
  width: 100px;
  height: 90px;
  border-radius: 6px;
  border: ${`1px solid ${theme.color.line.basic}`};
  object-fit: cover;
`;

const BrandName = styled.div`
  width: 100%;
  font-size: ${moTheme.font.size.$13};
  line-height: ${moTheme.font.lineHeight.demiLight};
  font-weight: ${moTheme.font.weight.medium};
  color: ${theme.color.text.basic};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
