import React from 'react';
import PropTypes from 'prop-types';

const AccordionHeaderUnstyled = (props) => {
  const { children, rootComponent: AccordionHeader, ...otherProps } = props;

  return <AccordionHeader {...otherProps}>{children}</AccordionHeader>;
};

export default AccordionHeaderUnstyled;

export const AccordionHeaderPropTypes = {
  rootComponent: PropTypes.elementType,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

AccordionHeaderUnstyled.propTypes = AccordionHeaderPropTypes;

export const AccordionHeaderDefaultProps = {};

AccordionHeaderUnstyled.defaultProps = AccordionHeaderDefaultProps;
