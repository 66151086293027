export const POINT_USAGE_CONST = [
  {
    code: '23',
    name: 'savePoint',
    showName: '적립금',
  },
  {
    code: '22',
    name: 'welPoint',
    showName: '웰포인트',
  },
  {
    code: '24',
    name: 'welfarePoint',
    showName: '복지포인트',
  },
];
