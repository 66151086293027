import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isEmpty from 'lodash-es/isEmpty';

import { getInitialQueryString } from '@ecp/common/src/utils/good/goodSearchUtil';
import useSearchHistory from '@ecp/common/src/hooks/useSearchHistory';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import SearchInput from '@ecp/common/src/components/input/mo/SearchInput';

import { searchKeywordHistoryListState, searchKeywordHistoryOnState, searchKeywordState } from '@mo-recoil/search/atom';
import { userInfoState } from '@mo-recoil/common/user/atom';
import { useAlertDialog } from '@mo-hooks/common/useModal';

const GnbSearchInput = ({ isSearchResultPage }) => {
  const [searchKeyword, setSearchKeyword] = useRecoilState(searchKeywordState);
  const setHistoryList = useSetRecoilState(searchKeywordHistoryListState);
  const historyOn = useRecoilValue(searchKeywordHistoryOnState);
  const userInfo = useRecoilValue(userInfoState);

  const { addSearchKeyword } = useSearchHistory(userInfo?.userId);
  const { showAlertDialog } = useAlertDialog();
  const navigate = useNavigate();

  const searchRef = useRef(null);

  const handleChange = useCallback(
    (e) => {
      if (isSearchResultPage) {
        navigate('/search');
      }

      setSearchKeyword(e?.target?.value ?? '');
    },
    [isSearchResultPage, navigate, setSearchKeyword]
  );

  useEffect(() => {
    if (!isSearchResultPage && !searchKeyword) {
      searchRef.current?.focus?.();
    }
  }, [isSearchResultPage, searchKeyword]);

  useEffect(() => {
    if (isSearchResultPage && searchKeyword) {
      searchRef.current?.blur?.();
    }
  }, [isSearchResultPage, searchKeyword]);

  const handleSearch = useCallback(() => {
    const searchTerm = searchKeyword?.trim?.();
    if (isEmpty(searchTerm)) {
      showAlertDialog('검색어를 입력 해주세요.');
      return;
    }

    if (historyOn) {
      const updatedList = addSearchKeyword(searchTerm);
      setHistoryList(updatedList);
    }

    searchRef.current?.blur?.();

    navigate(
      { pathname: '/search/result', search: getInitialQueryString({ searchTerm: searchTerm, countPerPage: 20 }) },
      { replace: true }
    );
  }, [addSearchKeyword, historyOn, navigate, searchKeyword, setHistoryList, showAlertDialog]);

  const handleClick = useCallback(() => {
    if (isSearchResultPage) {
      navigate('/search');
    }
  }, [isSearchResultPage, navigate]);
  return (
    <SearchInputWrapper data-ds-label2={'g_search_bar'}>
      <SearchInput
        clearable
        autoFocus
        type={'main'}
        placeholder={'검색어를 입력해주세요'}
        ref={searchRef}
        value={searchKeyword}
        onChange={handleChange}
        onEnter={handleSearch}
        onSearchClick={handleSearch}
        onClick={handleClick}
      />
    </SearchInputWrapper>
  );
};

GnbSearchInput.propTypes = {
  isSearchResultPage: PropTypes.bool,
};

const GnbSubTopSearch = () => {
  const { pathname } = useLocation();
  const isSearchResultPage = useMemo(() => pathname === '/search/result', [pathname]);

  return (
    <>
      <Spacing right="14px" />
      <GnbSearchInput isSearchResultPage={isSearchResultPage} />
    </>
  );
};

const SearchInputWrapper = styled.div`
  width: calc(100% - 108px);
`;

export default GnbSubTopSearch;
