import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useHover from '@ecp/common/src/hooks/useHover';
import { theme } from '@ecp/common/src/style/theme/default';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { StyledIconButton } from '@ecp/common/src/components/button/IconButton';

const StyledIconButtonRoot = styled(StyledIconButton)`
  width: 30px;
  height: 30px;
  fill: ${theme.color.icon.default};
  border: 1px solid ${theme.color.line.background};
`;

export const IconButton = ({ icon: Icon, ...props }) => (
  <StyledIconButtonRoot>
    <Icon {...props} />
  </StyledIconButtonRoot>
);

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  icon: PropTypes.elementType,
};

export const StyledRoundIconButton = styled(StyledIconButton)`
  stroke: ${theme.color.line.emphasis};
  fill: ${theme.color.background.white};
`;

const StyledRoundIconButtonRoot = styled(StyledRoundIconButton).attrs(
  ({ width = '100px', height = '100px', style }) => ({ style: { ...style, width, height } })
)`
  min-width: 50px;
  min-height: 50px;
  border: 1px solid ${theme.color.secondary};
  border-radius: 8px;
`;

export const RoundIconButton = ({ icon: Icon, hoverIcon: HoverIcon, children, height, width, ...props }) => {
  const [hoverRef, isHovered] = useHover();
  return (
    <StyledRoundIconButtonRoot ref={hoverRef} height={height} width={width}>
      {HoverIcon && isHovered ? <HoverIcon {...props} /> : <Icon {...props} />}
      <Spacing left={5} />
      {children}
    </StyledRoundIconButtonRoot>
  );
};

RoundIconButton.displayName = 'RoundIconButton';

RoundIconButton.propTypes = {
  icon: PropTypes.elementType,
  hoverIcon: PropTypes.elementType,
  ...sizePropTypes,
};
