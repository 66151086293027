import { useCallback, useMemo } from 'react';

import useEventCallback from '@ecp/common/src/hooks/useEventCallback';
import { SBCOM_SDI, SDI_POINT_VENDORS } from '@ecp/common/src/const/unusualSubsidiaryCompany';

const usePointsUsageInformation = (
  pointUsage,
  handleChangePointUsage,
  goodList,
  paymentAmount,
  totalProductAmount,
  orderGoodType,
  showAlertDialog,
  handleWelPointCashReceiptIssuePoss,
  subsidiaryCompanyCode,
  sdiGoodAvailableList,
  isEventMall,
) => {
  // SDI 계열사이고, 특정 업체 상품이면 복지포인트 50% 제한없이 사용 가능
  // console.log('subsidiaryCompanyCode:%s, SBCOM_SDI:%s, SDI_POINT_VENDORS:%s',subsidiaryCompanyCode,SBCOM_SDI,SDI_POINT_VENDORS)
  // console.log('-- sdiGoodAvailableList:',sdiGoodAvailableList)
  // 복지포인트 전액사용(전용몰 or 주문자가 SDI 계열사) 여부
  const noLimitWelfare = isEventMall || (subsidiaryCompanyCode === SBCOM_SDI &&
    goodList?.some((good) => SDI_POINT_VENDORS.includes(good.vendorNumber) || sdiGoodAvailableList?.some((speGood) => speGood.goodNumber === good.goodNumber))
  );

  const welfarePointMaxAmount = useMemo(() => {
    // console.log('paymentAmount?.totalProductAmount: ',paymentAmount?.totalProductAmount);
    // console.log('paymentAmount?.deliveryAmount?.total: ',paymentAmount?.deliveryAmount?.total);
    // console.log('paymentAmount?.discountAmount?.total: ',paymentAmount?.discountAmount?.total);
    // const maxAmt = Math.floor(
    //   (paymentAmount?.totalProductAmount +
    //     (paymentAmount?.deliveryAmount?.total || 0) -
    //     (paymentAmount?.discountAmount?.total || 0)
    //   ) / (orderGoodType?.pointGood ? 1 : (noLimitWelfare ? 1 : 2))
    // );
    // console.log('maxAmt: ',maxAmt);
    // return maxAmt;
    return Math.floor(
      ( paymentAmount?.totalProductAmount +
          (paymentAmount?.deliveryAmount?.total || 0) -
          (paymentAmount?.discountAmount?.total || 0)
         ) / (orderGoodType?.pointGood ? 1 : (noLimitWelfare ? 1 : 2))
    );
  }, [orderGoodType, paymentAmount]);

  const handleBlurUseSavePoints = useEventCallback((v) => {
    if (Number(v) && Number(v) < 1000) {
      showAlertDialog('적립금은 1000원 이상부터 사용 가능합니다.');
    }
    handleChangePointUsage((prev) => ({
      ...prev,
      savePoint: { balance: prev.savePoint.balance, use: Number(v) < 1000 ? 0 : Number(v) },
    }));
  });
  const handleChangeUseSavePoints = useEventCallback((v) => {
    if (Number(v) > (pointUsage?.savePoint?.balance || 0)) return;
    if (Number(v) > (paymentAmount?.paymentTotalAmount || 0) + pointUsage?.savePoint?.use) return;
    handleChangePointUsage((prev) => ({
      ...prev,
      savePoint: { balance: prev.savePoint.balance, use: Number(v) },
    }));
  });
  const handleClickUseAllSavePoints = useCallback(() => {
    const maxAmount =
      pointUsage?.savePoint?.balance < paymentAmount?.paymentTotalAmount + pointUsage?.savePoint?.use
        ? pointUsage?.savePoint?.balance
        : paymentAmount?.paymentTotalAmount + pointUsage?.savePoint?.use;

    if (maxAmount && maxAmount < 1000) {
      showAlertDialog('적립금은 1000원 이상부터 사용 가능합니다.');
    }

    handleChangePointUsage((prev) => ({
      ...prev,
      savePoint: {
        balance: prev.savePoint.balance,
        use: maxAmount < 1000 ? 0 : maxAmount,
      },
    }));
  }, [
    handleChangePointUsage,
    paymentAmount?.paymentTotalAmount,
    pointUsage?.savePoint?.balance,
    pointUsage?.savePoint?.use,
    showAlertDialog,
  ]);

  const handleBlurUseWelPoints = useEventCallback((v) => {
    handleChangePointUsage((prev) => ({
      ...prev,
      welPoint: { balance: prev.welPoint.balance, use: Number(v) },
    }));
    handleWelPointCashReceiptIssuePoss(Number(v));
  });

  const handleChangeUseWelPoints = useEventCallback(
    (v) => {
      if (Number(v) > (pointUsage?.welPoint?.balance || 0)) return;
      if (Number(v) > (paymentAmount?.paymentTotalAmount || 0) + pointUsage?.welPoint?.use) return;
      handleChangePointUsage((prev) => ({
        ...prev,
        welPoint: { balance: prev.welPoint.balance, use: Number(v) },
      }));
      handleWelPointCashReceiptIssuePoss(Number(v));
    },
    [paymentAmount?.paymentTotalAmount]
  );
  const handleClickUseAllWelPoints = useCallback(() => {
    const maxAmount =
      pointUsage?.welPoint?.balance < paymentAmount?.paymentTotalAmount + pointUsage?.welPoint?.use
        ? pointUsage?.welPoint?.balance
        : paymentAmount?.paymentTotalAmount + pointUsage?.welPoint?.use;
    handleChangePointUsage((prev) => ({
      ...prev,
      welPoint: {
        balance: prev.welPoint.balance,
        use: maxAmount,
      },
    }));
    handleWelPointCashReceiptIssuePoss(maxAmount);
  }, [
    handleChangePointUsage,
    paymentAmount?.paymentTotalAmount,
    pointUsage?.welPoint?.balance,
    pointUsage?.welPoint?.use,
  ]);

  const handleBlurUseWelfarePoints = useEventCallback((v) => {
    handleChangePointUsage((prev) => ({
      ...prev,
      welfarePoint: { balance: prev.welfarePoint.balance, use: Number(v) },
    }));
  });

  const handleChangeUseWelfarePoints = useEventCallback(
    (v) => {
      if (Number(v) > pointUsage?.welfarePoint?.balance) return;
      if (Number(v) > welfarePointMaxAmount) return;
      if (Number(v) > paymentAmount.paymentTotalAmount + pointUsage.welfarePoint.use) return;
      handleChangePointUsage((prev) => ({
        ...prev,
        welfarePoint: { balance: prev.welfarePoint.balance, use: Number(v) },
      }));
    },
    [totalProductAmount]
  );
  const handleClickUseAllWelfarePoints = useCallback(() => {
    let maxAmount =
      pointUsage?.welfarePoint?.balance < welfarePointMaxAmount
        ? pointUsage.welfarePoint.balance
        : welfarePointMaxAmount;

    maxAmount =
      maxAmount < paymentAmount.paymentTotalAmount + pointUsage?.welfarePoint?.use
        ? maxAmount
        : paymentAmount.paymentTotalAmount + pointUsage?.welfarePoint?.use;

    console.log('maxAmount: ',maxAmount);
    handleChangePointUsage((prev) => ({
      ...prev,
      welfarePoint: {
        balance: prev?.welfarePoint?.balance,
        use: maxAmount,
      },
    }));
  }, [
    pointUsage?.welfarePoint?.balance,
    pointUsage?.welfarePoint?.use,
    welfarePointMaxAmount,
    paymentAmount?.paymentTotalAmount,
    handleChangePointUsage,
  ]);
  return {
    welfarePointMaxAmount,
    handleBlurUseSavePoints,
    handleChangeUseSavePoints,
    handleClickUseAllSavePoints,
    handleBlurUseWelPoints,
    handleChangeUseWelPoints,
    handleClickUseAllWelPoints,
    handleBlurUseWelfarePoints,
    handleChangeUseWelfarePoints,
    handleClickUseAllWelfarePoints,
  };
};

export default usePointsUsageInformation;
