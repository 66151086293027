import { decode, encode } from 'base-64';

export const OS_TYPE = { ANDROID: 'android', IOS: 'ios', ETC: 'etc' };

export const checkOs = () => {
  const varUA = navigator.userAgent.toLowerCase();

  if (varUA.indexOf('android') > -1) {
    return OS_TYPE.ANDROID;
  } else if (varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('ipod') > -1) {
    return OS_TYPE.IOS;
  } else {
    return OS_TYPE.ETC;
  }
};

export const matchOs = (inputOs) => {
  return checkOs() === inputOs;
};

// 페이지 params 세팅 2023-02-02
export const getPageQueryParams = (currentPageNumber = 1, pagePerItemCount = 10, sortOptions = []) => {
  return {
    page: currentPageNumber - 1,
    size: pagePerItemCount,
    sort: sortOptions,
  };
};

export const getBase64 = (input, type = 'enc') => {
  return type === 'enc' ? encode(input) : decode(input);
};
