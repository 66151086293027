import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';

const BenefitChipRoot = styled(FlexBox)`
  padding: 0 4px;
  height: ${({ height }) => height};
  border: 1px solid ${theme.color.sub_darkgray};
  font-size: ${({ size }) => size};
  ${({ item }) => {
    if (item === 'NEW' || item === '대량주문') {
      return {
        color: `${theme.color.background.white}`,
        backgroundColor: `${theme.color.sub_darkgray}`,
      };
    } else {
      return {
        color: `${theme.color.sub_darkgray}`,
      };
    }
  }};
`;

const GoodBenefitChip = ({ item, size = moTheme.font.size.$11, height = '18px' }) => (
  <BenefitChipRoot item={item} size={size} height={height}>
    {item}
  </BenefitChipRoot>
);

GoodBenefitChip.propTypes = {
  item: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
};

export default GoodBenefitChip;
