import { getRequest, postRequest } from '@mo-apis/axios';

const getRecommendationViewGoodList = async (
  displayCategoryNumber = 'ALL',
  limit = 9,
  channel = 'main_best_week',
  skipSpinner = true
) => {
  return await getRequest(
    {
      url: `/api/v1/display/recommendation/view/${displayCategoryNumber}`,
      params: {
        limit,
        channel,
      },
    },
    { skipSpinner }
  );
};

const getRecommendationOrderGoodList = async (
  displayCategoryNumber = 'ALL',
  limit = 9,
  channel = 'main_best_month',
  skipSpinner = true
) => {
  return await getRequest(
    {
      url: `/api/v1/display/recommendation/order/${displayCategoryNumber}`,
      params: {
        limit,
        channel,
      },
    },
    { skipSpinner }
  );
};

const getRecommendationAiBasedGoodList = async (displayCategoryNumber = 'ALL', channel = 'main_ai', limit = 9) => {
  return await getRequest(
    {
      url: `/api/v1/display/recommendation/ai-based/${displayCategoryNumber}`,
      params: {
        limit,
        channel,
      },
    },
    { skipSpinner: true }
  );
};

const getRecommendationCategoryGoodList = async (displayCategoryNumber, limit = 10, channel = 'product_ctg_hot') => {
  if (!displayCategoryNumber) return { message: '', result: [] };

  return await getRequest(
    {
      url: `/api/v1/display/recommendation/category/${displayCategoryNumber}`,
      params: {
        limit,
        channel,
      },
    },
    { skipSpinner: true }
  );
};

const getRecommendationBuyTogetherGoodList = async (goodNumber, limit = 10, channel = 'product_purchase') => {
  return await getRequest(
    {
      url: `/api/v1/display/recommendation/buy-together/${goodNumber}`,
      params: {
        limit,
        channel,
      },
    },
    { skipSpinner: true }
  );
};

const getRecommendationViewTogetherGoodList = async (goodNumber, limit = 10, channel = 'product_view') => {
  return await getRequest(
    {
      url: `/api/v1/display/recommendation/view-together/${goodNumber}`,
      params: {
        limit,
        channel,
      },
    },
    { skipSpinner: true }
  );
};

const getRecommendationCustomerGoodList = async (
  displayCategoryNumber = 'ALL',
  channel = 'order_complete',
  limit = 10
) => {
  return await getRequest(
    {
      url: `/api/v1/display/recommendation/customer/${displayCategoryNumber}`,
      params: {
        channel,
        limit,
      },
    },
    { skipSpinner: true }
  );
};

const getRecommendationRealTimeGoodList = async (
  displayCategoryNumber = 'ALL',
  channel = 'search_no_results',
  limit = 5
) => {
  return await getRequest(
    {
      url: `/api/v1/display/recommendation/real-time/${displayCategoryNumber}`,
      params: {
        channel,
        limit,
      },
    },
    { skipSpinner: true }
  );
};

const getRecommendationSearchTermGoodList = async (
  searchTerm = '',
  categoryName = '',
  brandName = '',
  channel = 'search_best',
  limit = 5
) => {
  return await postRequest(
    {
      url: `/api/v1/display/recommendation/search-terms`,
      params: {
        channel,
        limit,
      },
      data: {
        searchTerm,
        largeCategoryName: categoryName,
        brandName: brandName,
      },
    },
    { skipSpinner: true }
  );
};

const getRecommendationPurchaseRateGoodList = async (displayCategoryNumber, limit = 10, channel = 'main_ctg_best') => {
  return await getRequest(
    {
      url: `/api/v1/display/recommendation/purchase-rate/${displayCategoryNumber}`,
      params: {
        limit,
        channel,
      },
    },
    { skipSpinner: true }
  );
};

const getRecommendationGoodList = async (mainFunctionKindCode) => {
  return await getRequest(
    {
      url: `/api/v1/display/recommendation/good/${mainFunctionKindCode}`,
    },
    { skipSpinner: true }
  );
};

const displayRecommendationApi = {
  getRecommendationViewGoodList,
  getRecommendationOrderGoodList,
  getRecommendationAiBasedGoodList,
  getRecommendationCategoryGoodList,
  getRecommendationBuyTogetherGoodList,
  getRecommendationViewTogetherGoodList,
  getRecommendationCustomerGoodList,
  getRecommendationRealTimeGoodList,
  getRecommendationSearchTermGoodList,
  getRecommendationPurchaseRateGoodList,
  getRecommendationGoodList
};

export default displayRecommendationApi;
