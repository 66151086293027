import { axiosRawClient, getRequest, postRequest, putRequest } from '@mo-apis/axios';

const login = (payload) =>
  postRequest(
    {
      url: 'api/v1/auth/login',
      data: payload,
    },
    { skipError: true, skipSpinner: true }
  );

const reissue = (token) =>
  axiosRawClient({
    method: 'POST',
    url: 'api/v1/auth/reissue',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const logout = () =>
  postRequest({
    method: 'POST',
    url: 'api/v1/auth/logout',
  });

const info = () =>
  getRequest({
    url: 'api/v1/auth/info',
  });

const findIdWithCellNo = ({ username, cellNo }) => {
  return postRequest({
    url: `api/v1/auth/recovery/id?cellNo`,
    data: {
      username,
      cellNo,
    },
  });
};

const findIdWithEmail = ({ username, email }) => {
  return postRequest({
    url: `api/v1/auth/recovery/id?email`,
    data: {
      username,
      email,
    },
  });
};

const getPasswordExpiredState = () => {
  return getRequest({
    url: `/api/v1/auth/member/password-expiry?expired-state`,
  });
};

const issueTempPassword = (payload) => {
  return postRequest({
    url: `api/v1/auth/recovery/password`,
    data: payload,
  });
};

const changePasswordAfterRecovery = ({ password, newPassword, userId }) => {
  return putRequest({
    url: `api/v1/auth/recovery/password`,
    data: {
      password,
      newPassword,
      userId,
    },
  });
};

const changePasswordLater = (payload) =>
  putRequest({
    url: '/api/v1/auth/member/password-expiry?renewal',
    data: payload,
  });

const changePasswordLaterWithCertInfo = (payload) =>
  putRequest({
    url: '/api/v1/auth/member/password-expiry?renewal-with-cert-info',
    data: payload,
  });

const changePasswordLaterWithOutsiteSso = (payload) => 
  putRequest({
    url: '/api/v1/auth/member/password-expiry?renewal-with-outsite-sso',
    data: payload,
  });
  
const changePasswordLaterWithUserCache = () =>
  putRequest({
    url: '/api/v1/auth/member/password-expiry?renewal-with-user-cache',
  });

const changeExpiredToNewPassword = (payload) =>
  putRequest({
    url: '/api/v1/auth/member/password-expiry?new',
    data: payload,
  });


const mobileLogin = (payload) =>
  postRequest(
    {
      url: 'api/v1/auth/mobile',
      data: payload,
    },
    { skipError: true, skipSpinner: true }
  );

const confirmPassword = (payload) => {
  return postRequest(
    {
      url: 'api/v1/auth/confirm-password',
      data: payload,
    },
    { skipError: true, skipSpinner: true }
  );
};

const restoreDormancyMember = (payload) =>
  postRequest({
    url: '/api/v1/auth/member/restore-dormancy',
    data: payload,
  });

const validateMember = (payload) =>
  postRequest({
    url: `/api/v1/member/validate`,
    data: { ...payload },
  });

const validateMemberForSignUp = (payload) =>
  postRequest({
    url: `/api/v1/member/validate/sign-up`,
    data: { ...payload },
  });

const validateMultimallEmployee = (payload) =>
  postRequest({
    url: `/api/v1/member/validate/multimall-employee`,
    data: { ...payload },
  });

const validateEmailCertNumber = (payload) =>
  postRequest({
    url: `/api/v1/member/validate/email-cert-number`,
    data: { ...payload },
  });

const validateMemberForCiUpdate = (payload) =>
  postRequest({
    url: `/api/v1/member/validate/ci-update`,
    data: { ...payload },
  });

const validateId = (id) => getRequest({ url: `api/v1/auth/member/validate-id`, params: { id } });

const getLoginIdByCi = (payload) =>
  postRequest({
    url: '/api/v1/auth/member/password-expiry/get-login-id',
    data: { ...payload },
  });

const getLoginIdByOutsiteSsoToken = (payload) => 
  postRequest({
    url: '/api/v1/auth/member/password-expiry/get-outsite-sso-login-id',
    data: { ...payload },
  });

const signUp = (payload) =>
  postRequest({
    url: 'api/v1/auth/member/sign-up',
    data: payload,
  });

const knoxSignUp = (payload) =>
  postRequest({
    url: 'api/v1/auth/member/knox-sign-up',
    data: payload,
  });


const associateSignUp = (payload) =>
  putRequest({
    url: 'api/v1/auth/member/associate-sign-up',
    data: payload,
  });

const getAssociateUserCompanyInfo = (loginId) =>
  getRequest(
    {
      url: `/api/v1/member/associate/company-info`,
      params: { loginId },
    },
    { skipError: true }
  );

const getMultimallUserCompanyInfo = (params) =>
  getRequest(
    {
      url: `/api/v1/member/multimall/company-info`,
      params: { ...params },
    },
    { skipError: true }
  );

//외부 서비스 (웰플, 삼성생명 등) 에서 sso 로그인 요청 시
const outSiteSsoLogin = (payload) =>
  postRequest(
    {
      url: 'api/v1/auth/outsite/sso',
      data: payload,
    },
    { skipError: true, skipSpinner: true }
  );

//타 서비스 (웰플, 삼성생명 등)에서 회원 정보를 가져온다.
const getWpUserInfo = (payload) =>
  postRequest(
    {
      url: 'api/v1/auth/getWpUserInfo',
      data: payload,
    },
    { skipError: true, skipSpinner: true }
  );

//모빌리언스 인증을 거치지 않고 연계된 타서비스로 부터 ci값을 받았을때 회원가입
const ssoSimpleSignUp = (payload) =>
  postRequest({
    url: 'api/v1/auth/member/sso-simple-sign-up',
    data: payload,
  });

const authApi = {
  outSiteSsoLogin,
  login,
  logout,
  reissue,
  info,
  findIdWithCellNo,
  findIdWithEmail,
  getPasswordExpiredState,
  issueTempPassword,
  changePasswordAfterRecovery,
  changePasswordLater,
  changePasswordLaterWithCertInfo,
  changePasswordLaterWithOutsiteSso,
  changePasswordLaterWithUserCache,
  changeExpiredToNewPassword,
  mobileLogin,
  confirmPassword,
  restoreDormancyMember,
  validateMember,
  validateMemberForSignUp,
  validateMultimallEmployee,
  validateEmailCertNumber,
  validateMemberForCiUpdate,
  validateId,
  getLoginIdByCi,
  getLoginIdByOutsiteSsoToken,
  signUp,
  knoxSignUp,
  associateSignUp,
  getAssociateUserCompanyInfo,
  getMultimallUserCompanyInfo,
  getWpUserInfo,
  ssoSimpleSignUp,
};

export default authApi;
