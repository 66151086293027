import React from 'react';
import PropTypes from 'prop-types';

import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import Swiper from '@ecp/common/src/components/swiper/mo/Swiper';

import BenefitCardItem from '@mo-components/benefit/BenefitCardItem';

const BenefitCardSwiper = ({ swiperRef, ...props }) => {
  return (
    <FlexBox justify-content={'center'} width={'100%'} box-shadow={'0px 4px 10px rgba(0, 0, 0, 0.15)'}>
      <Swiper round itemComponent={BenefitCardItem} {...props} ref={swiperRef} />
    </FlexBox>
  );
};

BenefitCardSwiper.propTypes = {
  itemList: PropTypes.array.isRequired,
  swiperRef: PropTypes.object,
};

BenefitCardSwiper.displayNane = 'BenefitCardSwiper';
export default BenefitCardSwiper;
