import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn, FlexBoxButton } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { AccordionTag } from '@ecp/common/src/components/accordion/AccordionTag';
import useAccordionHeaderControl from '@ecp/common/src/components/accordion/useAccordionHeaderControl';
import { TextButton } from '@ecp/common/src/components/button/TextButton';
import StarRating from '@ecp/common/src/components/icon/StarRating';
import AccordionBodyUnstyled, {
  AccordionBodyPropTypes,
} from '@ecp/common/src/components/unstyled/accordion/AccordionBodyUnstyled';
import AccordionControlUnstyled, {
  AccordionControlDefaultProps,
  AccordionControlPropTypes,
} from '@ecp/common/src/components/unstyled/accordion/AccordionControlUnstyled';
import AccordionTitleUnstyled, {
  AccordionTitlePropTypes,
} from '@ecp/common/src/components/unstyled/accordion/AccordionTitleUnstyled';
import AccordionUnstyled, {
  AccordionDefaultProps,
  AccordionPropTypes,
} from '@ecp/common/src/components/unstyled/accordion/AccordionUnstyled';
import { useAccordionContext } from '@ecp/common/src/components/unstyled/accordion/useAccordionContext';
import { ReactComponent as ReportImage } from '@ecp/common/src/assets/icon/icon__report.svg';
import { ReactComponent as LockIcon } from '@ecp/common/src/assets/icon/icon__lock.svg';
import { ReactComponent as DeleteImage } from '@ecp/common/src/assets/icon/icon__delete.svg';
import { ReactComponent as IconOpen } from '@ecp/common/src/assets/icon/icon__arrow-fill--below.svg';
import AccordionControlQUnstyled from '@ecp/common/src/components/unstyled/accordion/AccordionControlQUnstyled';

const StyledAccordion = styled(FlexBox).attrs(({ backgroundColor = theme.color.background.white, style }) => ({
  style: {
    ...style,
    backgroundColor,
  },
}))`
  width: 100%;
  align-items: flex-start;
`;

const Accordion = ({ children, ...props }) => {
  return (
    <AccordionUnstyled rootComponent={StyledAccordion} {...props}>
      {children}
    </AccordionUnstyled>
  );
};

Accordion.propTypes = AccordionPropTypes;
Accordion.defaultProps = AccordionDefaultProps;

const StyledAccordionTitle = styled(FlexBox)`
  width: 100%;
`;

const AccordionTitle = (props) => {
  const { children } = props;

  return <AccordionTitleUnstyled rootComponent={StyledAccordionTitle}>{children}</AccordionTitleUnstyled>;
};

AccordionTitle.propTypes = AccordionTitlePropTypes;

const StyledAccordionBody = styled(FlexBox)`
  width: 100%;
`;

const AccordionBody = (props) => {
  return <AccordionBodyUnstyled rootComponent={StyledAccordionBody} {...props} />;
};

AccordionBody.propTypes = AccordionBodyPropTypes;

const StyledAccordionControl = styled(FlexBoxButton)`
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
`;
const AccordionControl = ({
  children,
  lock,
  mine,
  question,
  button: { width, height, padding, fill } = {},
  ...props
}) => (
  <AccordionControlUnstyled
    rootComponent={StyledAccordionControl}
    buttonElement={
      <>
        <Spacing value={padding} />
        <IconOpen width={width} height={height} fill={fill} />
      </>
    }
    {...props}
  >
    {question && (
      <>
        <TextBox size={theme.font.size.popup} height={'32px'} weight={theme.font.weight.medium}>
          Q
        </TextBox>
        <Spacing right={10} />
      </>
    )}
    {lock && (
      <>
        <LockIcon width={'15px'} height={'18px'} />
        <Spacing right={6} />
        {!mine && (
          <TextBox size={theme.font.size.small} height={'21px'} weight={theme.font.weight.demiLight} flex-grow={'1'}>
            비밀글 입니다.
          </TextBox>
        )}
      </>
    )}
    {children}
  </AccordionControlUnstyled>
);

AccordionControl.propTypes = {
  ...AccordionControlPropTypes,
  lock: PropTypes.bool,
  mine: PropTypes.bool,
  question: PropTypes.bool,
  button: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    padding: PropTypes.string,
    fill: PropTypes.string,
  }),
};

AccordionControl.defaultProps = {
  ...AccordionControlDefaultProps,
  lock: false,
  question: false,
  button: {
    width: '12px',
    height: '8px',
    padding: '0',
    fill: '#5A5A5A',
  },
};

const AccordionControlQ = ({
  children,
  lock,
  mine,
  question,
  button: { width, height, padding, fill } = {},
  ...props
}) => (
  <AccordionControlQUnstyled
    rootComponent={StyledAccordionControl}
    buttonElement={
      <>
        <Spacing value={padding} />
        <IconOpen width={width} height={height} fill={fill} />
      </>
    }
    {...props}
  >
    {question && (
      <>
        <TextBox size={theme.font.size.popup} height={'32px'} weight={theme.font.weight.medium} ariaHidden={'true'}>
          Q
        </TextBox>
        <div style={{ position: 'absolute', width: '1px', height: '1px', clip: 'rect(0 0 0 0)', overflow: 'hidden', margin: '-1px' }}>질문</div>
        <Spacing right={10} />
      </>
    )}
    {lock && (
      <>
        <LockIcon width={'15px'} height={'18px'} />
        <Spacing right={6} />
        {!mine && (
          <TextBox size={theme.font.size.small} height={'21px'} weight={theme.font.weight.demiLight} flex-grow={'1'}>
            비밀글 입니다.
          </TextBox>
        )}
      </>
    )}

    {children}
  </AccordionControlQUnstyled>
);

AccordionControlQ.propTypes = {
  ...AccordionControlPropTypes,
  lock: PropTypes.bool,
  mine: PropTypes.bool,
  question: PropTypes.bool,
  button: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    padding: PropTypes.string,
    fill: PropTypes.string,
  }),
};

AccordionControlQ.defaultProps = {
  ...AccordionControlDefaultProps,
  lock: false,
  question: false,
  button: {
    width: '12px',
    height: '8px',
    padding: '0',
    fill: '#5A5A5A',
  },
};

const HeaderGuideWrapper = styled(FlexBoxColumn)`
  align-items: flex-start;
`;

const StarRateWrapper = styled(FlexBox)`
  height: 21px;
`;

const AccordionHeaderGuide = ({ rate, children, ...props }) => {
  return (
    <HeaderGuideWrapper {...props}>
      <StarRateWrapper {...props}>
        <StarRating maxRate={5} rating={rate} size={12} />
        <Spacing right={10} />
        <TextBox
          size={theme.font.size.error}
          height={theme.font.lineHeight.demiLight}
          weight={theme.font.weight.medium}
        >
          {rate?.toFixed?.(1)}
        </TextBox>
      </StarRateWrapper>
      <Spacing bottom={4} />
      {children}
    </HeaderGuideWrapper>
  );
};

AccordionHeaderGuide.propTypes = {
  rate: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const HeaderControlWrapper = styled(FlexBox)`
  width: 100%;
`;

export const ReportIcon = styled(ReportImage)`
  width: 13px;
  height: 14px;
`;

export const DeleteIcon = styled(DeleteImage)`
  width: 10px;
  height: 10px;
`;

const AccordionHeaderControl = ({ children, onDeleteClick, onRequestClick }) => {
  const { handleClick, label, customIcon } = useAccordionHeaderControl({ onDeleteClick, onRequestClick });

  return (
    <HeaderControlWrapper>
      {children}
      <TextButton
        type={'custom-left'}
        textProps={{
          size: theme.font.size.error,
          weight: theme.font.weight.demiLight,
          height: theme.font.lineHeight.demiLight,
          color: theme.color.text.guide,
          hoverDecoration: 'underline',
        }}
        customIcon={customIcon}
        onClick={handleClick}
      >
        {label}
      </TextButton>
    </HeaderControlWrapper>
  );
};

AccordionHeaderControl.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onDeleteClick: PropTypes.func,
  onRequestClick: PropTypes.func,
};

const AccordionClickable = ({ rootComponent: Clickable, rootStyle: clickableStyle, children }) => {
  const { handleOpenClick } = useAccordionContext();
  return (
    <Clickable onClick={handleOpenClick} style={clickableStyle}>
      {children}
    </Clickable>
  );
};

AccordionClickable.propTypes = {
  rootComponent: PropTypes.elementType,
  rootStyle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

AccordionClickable.defaultProps = {
  rootStyle: {},
};

Accordion.Title = AccordionTitle;
Accordion.Control = AccordionControl;
Accordion.Body = AccordionBody;
Accordion.HeaderGuide = AccordionHeaderGuide;
Accordion.HeaderControl = AccordionHeaderControl;
Accordion.Tag = AccordionTag;
Accordion.Clickable = AccordionClickable;
Accordion.ControlQ = AccordionControlQ;

export default Accordion;
