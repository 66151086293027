import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import usePreventPopState from '@ecp/common/src/hooks/order/payment/usePreventPopState';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

import { mapExhibitionMOData } from '@mo-utils/exhibitionUtils';
import { withCartBadgeCount } from '@mo-recoil/order/selector';
import { useHideFooter } from '@mo-hooks/common/useFooter';
import { useGnbTitle } from '@mo-hooks/common/useGnb';
import useTopExposedExhibition from '@mo-hooks/exhibition/useTopExposedExhigition';
import orderApi from '@mo-apis/order/orderApi';
import CustomerRecommendation from '@mo-components/display/recommendation/CustomerRecommendation';
import OrderComplete from '@mo-components/order/payment/OrderComplete';
import OrderExhibitionSwiper from '@mo-components/order/payment/OrderExhibitionSwiper';
import useSiteInfo from '@mo-hooks/common/useSiteInfo';

const PaymentCompletePage = () => {
  useHideFooter();
  useGnbTitle('주문완료');
  const { isEventMall } = useSiteInfo();
  const { orderNumber } = useParams();
  const [orderResult, setOrderResult] = useState({});
  const [topExposedExhibitionList, setTopExposedExhibitionList] = useState([]);
  const { exhibitionList, getTopExposedExhibitionList } = useTopExposedExhibition();
  const setCartBadgeCount = useSetRecoilState(withCartBadgeCount);
  usePreventPopState();

  useEffect(() => {
    (async () => {
      const { result } = await orderApi.getCartCount();
      setCartBadgeCount(result);
    })();
  }, [setCartBadgeCount]);

  useEffect(() => {
    (async () => {
      const { result } = await orderApi.getOrderResult(orderNumber);
      setOrderResult(result);
    })();
    if (exhibitionList && exhibitionList.length > 0) {
      setTopExposedExhibitionList(mapExhibitionMOData(getTopExposedExhibitionList([...exhibitionList]), true));
    }
  }, [exhibitionList, getTopExposedExhibitionList, orderNumber]);

  return (
    <Container>
      <Spacing top={'20px'} />
      <OrderComplete orderResult={orderResult} />
      {!isEventMall && (
        <>
          <CustomerRecommendation
            title={'고객님을 위한 추천 상품'}
            titleStyle={{
              size: moTheme.font.size.$16,
              weight: moTheme.font.weight.bold,
              height: moTheme.font.lineHeight.bold,
            }}
          />
          <Spacing top={'40px'} />
          <OrderExhibitionSwiper exhibitionList={topExposedExhibitionList} title="현재 진행중인 기획전" />
        </>
      )}
      <Spacing bottom={'20px'} />
    </Container>
  );
};
export default PaymentCompletePage;

const Container = styled(FlexBoxColumn)`
  align-self: stretch;
  align-items: center;
`;
