import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import ChipOrigin from '@ecp/common/src/components/chip/mo/ChipOrigin';
import ButtonUnstyled from '@ecp/common/src/components/unstyled/button/ButtonUnstyled';
import { ReactComponent as IconOpen } from '@ecp/common/src/assets/icon/icon__arrow--below.svg';

import SwiperChipOrigin from '@mo-components/display/SwiperChipOrigin';

const CategorySwiperLabel = ({ swiperRef, itemList, onChipClick, onArrowClick, onChange, ...props }) => {
  const [isActive, setIsActive] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  const handleArrowClick = useCallback(() => {
    setIsActive((current) => !current);
    onArrowClick?.();
  }, [onArrowClick]);

  const showArrow = useMemo(() => isActive || (!isActive && !isLocked), [isActive, isLocked]);

  return (
    <CategoryContainer {...props}>
      {isActive ? (
        <CategoryLabelWrapper>
          {itemList?.map((category) => (
            <StyledChip key={`chip-${category?.value}`}>
              <ChipOrigin
                closable={category?.closable}
                type={category?.type}
                data-value={category?.label}
                checked={category?.checked}
                onClick={() => onChipClick(category)}
              >
                {category?.label}
              </ChipOrigin>
            </StyledChip>
          ))}
        </CategoryLabelWrapper>
      ) : (
        <FlexBox align-items={'flex-start'} height={'36px'}>
          <SwiperChipOrigin
            swiperRef={swiperRef}
            width={'320px'}
            itemProps={{ width: 'auto', onClick: onChipClick }}
            itemList={itemList}
            isLocked={isLocked}
            handleIsLocked={setIsLocked}
            onChange={onChange}
            title={'카테고리 선택'}
          />
        </FlexBox>
      )}
      {showArrow && (
        <Arrow type={isActive ? 'open' : 'close'} onClick={handleArrowClick} title={!isActive ? '펼치기' : '닫기'}>
          <IconOpen width="12px" height="8px" />
        </Arrow>
      )}
    </CategoryContainer>
  );
};
export default CategorySwiperLabel;

CategorySwiperLabel.propTypes = {
  swiperRef: PropTypes.object,
  itemList: PropTypes.array,
  onChipClick: PropTypes.func,
  onArrowClick: PropTypes.func,
  onChange: PropTypes.func,
};

const CategoryContainer = styled(FlexBoxSpaceBetween)`
  width: 360px;
  padding: 14px 20px 8px 20px;
  box-sizing: border-box;
  align-items: flex-start;
  overflow: scroll;
  background: ${theme.color.background.basic};
`;

const CategoryLabelWrapper = styled(FlexBox)`
  flex-wrap: wrap;
  width: 292px;
`;

const StyledChip = styled.div`
  padding: 0 6px 6px 0;
`;

const Arrow = styled(ButtonUnstyled)`
  position: absolute;
  width: 48px;
  height: 30px;
  right: 0;
  background: linear-gradient(270deg, ${theme.color.background.basic} 78.41%, rgba(255, 255, 255, 0) 135.23%);
  z-index: 2;
  & svg {
    transform: ${({ type }) => type === 'open' && 'rotate(180deg)'};
  }
`;
