import { useCallback, useEffect, useState, useMemo } from 'react';

import { ORDER_TYPE } from '@ecp/common/src/const/order/orderConst';

const useCarditCard = (paymentMethod, handleChangePaymentMethod, getCreditCardList, orderType) => {
  const [creditCardList, setCreditCardList] = useState([]);
  const handleChangeCard = useCallback(
    (selectedItem) => {
      handleChangePaymentMethod({ ...paymentMethod, cardCompanyCode: selectedItem.value });
    },
    [handleChangePaymentMethod, paymentMethod]
  );

  const selectedCreditCard = useMemo(() => {
    return { ...creditCardList.find((item) => item.key === paymentMethod?.cardCompanyCode) };
  }, [creditCardList, paymentMethod]);

  useEffect(() => {
    (async () => {
      const { result } = await getCreditCardList();
      if (orderType === ORDER_TYPE.PRESENT) {
        setCreditCardList(
          result
            ?.filter(({ partPurchaseCancelYn }) => 'Y' === partPurchaseCancelYn)
            .map(({ cardCompanyCode, cardName }) => ({
              key: cardCompanyCode,
              value: cardCompanyCode,
              label: cardName,
            }))
        );
      } else {
        setCreditCardList(
          result?.map(({ cardCompanyCode, cardName }) => ({
            key: cardCompanyCode,
            value: cardCompanyCode,
            label: cardName,
          }))
        );
      }
    })();
  }, [getCreditCardList, orderType]);

  return {
    creditCardList,
    handleChangeCard,
    selectedCreditCard,
  };
};

export default useCarditCard;
