import { useNavigate } from 'react-router-dom';

import { mobileNavigateWithFilterInfoTable } from '@ecp/common/src/utils/good/brandDetailSearchUtil';

const useNavigateBrandDetailPage = () => {
  const navigate = useNavigate();

  const handleNavigateToBrandDetail = ({ brandNumber }) => {
    const pathname = `/brand/${brandNumber}`;
    const search = mobileNavigateWithFilterInfoTable.getQueryString(
      mobileNavigateWithFilterInfoTable.initSearchCondition,
      mobileNavigateWithFilterInfoTable.initPageParam
    );
    navigate({ pathname, search });
  };

  return { handleNavigateToBrandDetail };
};

export default useNavigateBrandDetailPage;
