import { useCallback, useState } from 'react';

import { addHours, parse } from 'date-fns';
import isEmpty from 'lodash-es/isEmpty';

import { getPhoneNumbers } from '@ecp/common/src/utils/textUtil';
import { isValidCellPhoneWithoutDash, isValidEmail } from '@ecp/common/src/utils/InputValidateUtils';
import { PD_GOOD_TYPE } from '@ecp/common/src/const/good';
import { PAY_MEAN_CODE } from '@ecp/common/src/const/interface/payMeanCode';
import { PAYMENT_MEAN_CODE } from '@ecp/common/src/const/order/orderConst';

export default function useOrderValidation({ isAdultVerified, showAlertDialog, orderApi }) {
  const [error, setError] = useState({});

  const validateAdult = useCallback(
    (data) => {
      const isAdultGood = data?.orderDeliveryList.some(({ goodList }) =>
        goodList.some(({ adultGood, itemList }) => adultGood || itemList.some(({ adultGood }) => adultGood))
      );
      const adultVerifyRequired = isAdultGood && !isAdultVerified;
      setError((prev) => ({ ...prev, adultVerifyRequired }));
      return !adultVerifyRequired;
    },
    [isAdultVerified]
  );

  const validateStandardOrderDeliveryList = useCallback(
    (data) => {
      const { orderDeliveryList } = data;
      if (orderDeliveryList.some(({ deliveryPlace }) => !deliveryPlace || isEmpty(deliveryPlace))) {
        showAlertDialog('배송지를 선택해주세요');
        return false;
      }
      if (
        orderDeliveryList.some(
          ({ deliveryPlace }) =>
            !deliveryPlace.receiverName ||
            !deliveryPlace.baseAddress ||
            !deliveryPlace.detailAddress ||
            !deliveryPlace.cellphoneNumber1 ||
            !deliveryPlace.cellphoneNumber2 ||
            !deliveryPlace.cellphoneNumber3
        )
      ) {
        showAlertDialog('배송정보를 확인해주세요');
        return false;
      }
      return true;
    },
    [showAlertDialog]
  );

  const validateQuantity = useCallback(
    (data) => {
      const { orderDeliveryList } = data;

      const hasZeroQuantity = orderDeliveryList.some((deliveryItem) => {
        return !deliveryItem.goodList.reduce((goodAcc, goodItem) => {
          return (
            goodAcc +
            goodItem.itemList.reduce((itemAcc, item) => {
              return itemAcc + (item.deliveryQuantity || 0);
            }, 0)
          );
        }, 0);
      });

      if (orderDeliveryList.length > 1 && hasZeroQuantity) {
        showAlertDialog('상품 수량을 입력하지 않은 배송지가 있습니다.\n 상품수량을 조정해주세요.');
        return false;
      }

      const orderTotalQuantity = orderDeliveryList[0].goodList
        .map(({ itemList }) => itemList.map(({ orderQuantity }) => orderQuantity))
        .flat()
        .reduce((acc, quantity) => acc + quantity, 0);

      const deliveryTotalQuantity = orderDeliveryList
        .map(({ goodList }) =>
          goodList.map(({ itemList }) => itemList?.map(({ deliveryQuantity }) => Number(deliveryQuantity)))
        )
        .flat(2)
        .reduce((acc, quantity) => acc + quantity, 0);

      if (orderTotalQuantity !== deliveryTotalQuantity) {
        showAlertDialog('선택하신 상품수량과 총 주문수량이 일치하지 않습니다.\n 상품수량을 조정해주세요.');
        return false;
      }

      return true;
    },
    [showAlertDialog]
  );

  const validateReceiverData = useCallback(
    (data) => {
      const { orderDeliveryList } = data;

      if (orderDeliveryList.some(({ deliveryPlace }) => !deliveryPlace || isEmpty(deliveryPlace))) {
        showAlertDialog('받으시는 분 정보를 입력해주세요');
        return false;
      }
      if (
        orderDeliveryList.some(({ deliveryPlace }) => !deliveryPlace.receiverName || !deliveryPlace.cellphoneNumber)
      ) {
        showAlertDialog('받으시는 분 정보를 입력해주세요');
        return false;
      }
      if (orderDeliveryList.some(({ deliveryPlace }) => !isValidCellPhoneWithoutDash(deliveryPlace.cellphoneNumber))) {
        showAlertDialog('받는 분 전화번호를 정확히 입력해주세요');
        return false;
      }
      return true;
    },
    [showAlertDialog]
  );

  const validatePccc = useCallback(
    (data) => {
      const { orderGoodType, orderDeliveryList } = data;

      if (!orderGoodType.directForeignGood) return true;

      return orderDeliveryList.every((deliveryItem) => {
        const hasDirectForeignGood = deliveryItem?.goodList?.some((goodItem) => {
          return goodItem?.itemList?.some(
            (item) => item.deliveryQuantity && item.goodTypeCode === PD_GOOD_TYPE.OVERSEAS
          );
        });
        if (hasDirectForeignGood) {
          if (!deliveryItem.deliveryPlace.pccc) {
            showAlertDialog('개인통관고유부호를 입력해주세요');
            return false;
          }
          const pcccRegex = /^(p|P)[0-9]{12}$/;
          if (!pcccRegex.exec(deliveryItem.deliveryPlace.pccc)) {
            showAlertDialog('개인통관고유부호를 확인해주세요');
            return false;
          }
          return true;
        }
        return true;
      });
    },
    [showAlertDialog]
  );

  const validatePaymentMethod = useCallback(
    (data) => {
      const { paymentMethod } = data;
      if (data.paymentAmount.paymentTotalAmount > 0) {
        //TODO: 좀더 빡세게 해야함
        if (!paymentMethod || isEmpty(paymentMethod)) {
          showAlertDialog('결제 방법을 선택해주세요');
          return false;
        }
      }
      if (data.paymentAmount.paymentTotalAmount < 0) {
        showAlertDialog('시스템 오류');
        return false;
      }
      if (!data.paymentAmount.paymentTotalAmount) {
        if (!data.paymentAmount.discountAmount.total && !data.paymentAmount.pointAmount.total) {
          showAlertDialog('상품에 오류가 있습니다');
          return false;
        }
      }

      if (paymentMethod?.payMeanCode === PAYMENT_MEAN_CODE.DEPOSIT) {
        if (!paymentMethod?.bankCode) {
          showAlertDialog('무통장입금 은행을 선택해주세요');
          return false;
        }
        if (!paymentMethod?.depositName) {
          showAlertDialog('입금자명을 확인해주세요');
          return false;
        }
      }
      if (paymentMethod?.payMeanCode === PAYMENT_MEAN_CODE.CARD) {
        if (!paymentMethod?.cardCompanyCode) {
          showAlertDialog('카드를 선택해주세요');
          return false;
        }
      }
      // 세금계산서 결제인 경우 필수값 체크
      if (paymentMethod?.payMeanCode === PAYMENT_MEAN_CODE.TAX_INVOICE) {
        if (!data.taxInvoiceInfo?.bizName) {
          showAlertDialog('사업장명을 입력해 주세요.');
          return false;
        }
        if (!data.taxInvoiceInfo?.bizNumber) {
          showAlertDialog('사업자번호를 입력해 주세요.');
          return false;
        }
        if (data.taxInvoiceInfo?.bizNumber?.length < 10) {
          showAlertDialog('사업자번호를 확인해 주세요.\n(숫자 10자리)');
          return false;
        }
        if (!data.taxInvoiceInfo?.bizEmail) {
          showAlertDialog('수신메일을 입력해 주세요.');
          return false;
        }
        if(!isValidEmail(data.taxInvoiceInfo?.bizEmail)) {
          showAlertDialog('이메일을 정확히 입력해주세요.');
          return false;
        }
      }
      return true;
    },
    [showAlertDialog]
  );

  const validateCashReceiptInfo = useCallback(
    (data) => {
      const { cashReceiptInfo, paymentMethod } = data;

      if (![PAY_MEAN_CODE.REAL_TIME_BANK, PAY_MEAN_CODE.V_BANK].includes(paymentMethod?.payMeanCode)) return true;

      if (cashReceiptInfo.cashReceiptType === 'deduction') {
        if (cashReceiptInfo.incomeDeductionType === 'cellPhone') {
          if (
            !cashReceiptInfo.cellPhoneNumber1 ||
            !cashReceiptInfo.cellPhoneNumber2 ||
            !cashReceiptInfo.cellPhoneNumber3
          ) {
            showAlertDialog('현금 영수증 발급 번호를 입력해 주세요');
            return false;
          }
        }
        if (cashReceiptInfo.incomeDeductionType === 'incomeDeductionCard') {
          if (
            !cashReceiptInfo.cashReceiptCardNumber1 ||
            !cashReceiptInfo.cashReceiptCardNumber2 ||
            !cashReceiptInfo.cashReceiptCardNumber3 ||
            !cashReceiptInfo.cashReceiptCardNumber4
          ) {
            showAlertDialog('현금 영수증 발급 번호를 입력해 주세요');
            return false;
          }
        }
      }
      if (cashReceiptInfo.cashReceiptType === 'business') {
        if (
          !cashReceiptInfo.businessRegistrationNumber1 ||
          !cashReceiptInfo.businessRegistrationNumber2 ||
          !cashReceiptInfo.businessRegistrationNumber3
        ) {
          showAlertDialog('현금 영수증 발급 번호를 입력해 주세요');
          return false;
        }
      }
      return true;
    },
    [showAlertDialog]
  );

  const validateDeliveryHopeDate = useCallback(
    (data) => {
      const { hasDeliveryHopeDate, orderDeliveryList, orderGoodType } = data;

      if (!hasDeliveryHopeDate) return true;

      const isDeliveryDateEmpty = orderDeliveryList.some(({ deliveryPlace }) => {
        return !deliveryPlace.deliveryDate;
      });
      if (isDeliveryDateEmpty) {
        const text =
          orderGoodType.pointGood || orderGoodType.ticket
            ? '수령일'
            : orderGoodType.rental
            ? '설치희망일'
            : '배송희망일';

        showAlertDialog(`${text}을 입력해주세요`);
        return false;
      }
      return true;
    },
    [showAlertDialog]
  );

  const validateReceiverPhoneNumbers = useCallback(
    (data) => {
      const { orderDeliveryList } = data;
      if (
        orderDeliveryList.some(({ deliveryPlace }) => {
          return !deliveryPlace.cellphoneNumber;
        })
      ) {
        showAlertDialog('받는 분 전화번호를 입력해주세요');
        return false;
      }
      if (
        orderDeliveryList.some(({ deliveryPlace }) => {
          return !isValidCellPhoneWithoutDash(deliveryPlace.cellphoneNumber);
        })
      ) {
        showAlertDialog('받는 분 전화번호를 정확히 입력해주세요');
        return false;
      }
      return true;
    },
    [showAlertDialog]
  );

  const validateOrderQuantity = useCallback(
    (data) => {
      const { orderDeliveryList } = data;
      const hasZeroQuantity = orderDeliveryList.some((deliveryItem) => {
        return !deliveryItem.goodList.reduce((goodAcc, goodItem) => {
          return (
            goodAcc +
            goodItem.itemList.reduce((itemAcc, item) => {
              return itemAcc + (item.deliveryQuantity || 0);
            }, 0)
          );
        }, 0);
      });
      if (hasZeroQuantity) {
        showAlertDialog('상품 수량을 입력하지 않은 배송지가 있습니다.\n 상품수량을 조정해주세요.');
        return false;
      }

      const orderTotalQuantity = orderDeliveryList[0].goodList
        .map(({ itemList }) => itemList.map(({ orderQuantity }) => orderQuantity))
        .flat()
        .reduce((acc, quantity) => acc + quantity, 0);

      const deliveryTotalQuantity = orderDeliveryList
        .map(({ goodList }) =>
          goodList.map(({ itemList }) => itemList?.map(({ deliveryQuantity }) => Number(deliveryQuantity)))
        )
        .flat(2)
        .reduce((acc, quantity) => acc + quantity, 0);
      if (orderTotalQuantity !== deliveryTotalQuantity) {
        showAlertDialog('선택하신 상품수량과 총 주문수량이 일치하지 않습니다.\n 상품수량을 조정해주세요.');
        return false;
      }
      return true;
    },
    [showAlertDialog]
  );

  const validateGiftiShowSendTime = useCallback(
    (data) => {
      const { giftiShowInformation } = data;
      if (giftiShowInformation.reserveYn !== 'Y') {
        return true;
      }
      if (!giftiShowInformation.reserveDate || !giftiShowInformation.reserveHours) {
        showAlertDialog('예약발송시간을 입력하세요.');
        return false;
      }

      const reserveTime = parse(
        `${giftiShowInformation.reserveDate}${giftiShowInformation.reserveHours}`,
        'yyyyMMddHH',
        new Date()
      );
      if (reserveTime < addHours(new Date(), 1)) {
        showAlertDialog('예약발송시간을 현재시간보다 한시간 이후로 설정하세요.');
        return false;
      }
      return true;
    },
    [showAlertDialog]
  );

  const validateItemDelivery = useCallback(
    async (data) => {
      const deliveryAvailabilityList = data.orderDeliveryList?.reduce(
        (goods, { deliveryPlace: { postNumber }, goodList }) => [
          ...goods,
          ...goodList.reduce(
            (items, { goodNumber, itemList }) => [
              ...items,
              ...itemList.map(({ itemNumber, orderQuantity }) => ({
                postNumber,
                goodNumber,
                itemNumber,
                orderQuantity,
              })),
            ],
            []
          ),
        ],
        []
      );
      const { result, code, message } = await orderApi.checkOrderInfoValid({ deliveryAvailabilityList });
      if (code) {
        if (code === 'OD_24337') {
          setError({
            ...result,
            message,
            deliveryDisableList: result?.deliveryAvailabilityList?.map(
              ({ postNumber, goodNumber, itemNumber }) => `${postNumber}-${goodNumber}-${itemNumber}`
            ),
          });
        } else {
          showAlertDialog(message);
        }
        return false;
      }
      return true;
    },
    [orderApi, showAlertDialog]
  );

  const validateAdditionalItem = useCallback(
    async (data) => {
      const { orderDeliveryList } = data;
      const invalidList = orderDeliveryList.filter(({ goodList }) => {
        const invalidGoods = goodList.filter(({ itemList }) => {
          const isOrderAdditionalItem = itemList.find(
            ({ additionalItem, deliveryQuantity }) => additionalItem && deliveryQuantity > 0
          );
          if (isOrderAdditionalItem) {
            const isOrderItem = itemList.find(
              ({ additionalItem, deliveryQuantity }) => !additionalItem && deliveryQuantity > 0
            );
            if (!isOrderItem) return true;
          }
          return false;
        });
        if (invalidGoods && invalidGoods.length !== 0) return true;
        return false;
      });
      if (invalidList && invalidList.length !== 0) {
        showAlertDialog('추가 옵션만 구매할 수 없습니다.');
        return false;
      }
      return true;
    },
    [showAlertDialog]
  );

  const validateGiftishowItem = useCallback(
    async (data) => {
      const { orderDeliveryList } = data;
      const vendorGoodNumber = orderDeliveryList[0].goodList[0].vendorGoodNumber;
      const { result } = await orderApi.checkGiftishowOrderInfoValid(vendorGoodNumber);
      if (!result) {
        showAlertDialog('유효한 기프티쇼 상품이 아닙니다.');
      }
      return result;
    },
    [orderApi, showAlertDialog]
  );

  const standardValidate = useCallback(
    async (data) => {
      if (!data) return false;
      if (isEmpty(data)) return false;

      const validators = [
        validateStandardOrderDeliveryList,
        validateQuantity,
        validatePccc,
        validatePaymentMethod,
        validateCashReceiptInfo,
        validateItemDelivery,
        validateAdditionalItem,
      ];
      for (const validator of validators) {
        if (!(await validator(data))) {
          return false;
        }
      }
      return true;
    },
    [
      validateCashReceiptInfo,
      validateQuantity,
      validatePaymentMethod,
      validatePccc,
      validateStandardOrderDeliveryList,
      validateItemDelivery,
      validateAdditionalItem,
    ]
  );

  const presentValidate = useCallback(
    (data) => {
      if (!data) return false;
      if (isEmpty(data)) return false;
      const validators = [validateReceiverData, validatePaymentMethod, validateCashReceiptInfo];
      for (const validator of validators) {
        if (!validator(data)) {
          return false;
        }
      }
      return true;
    },
    [validateCashReceiptInfo, validatePaymentMethod, validateReceiverData]
  );

  const giftiShowValidate = useCallback(
    async (data) => {
      if (!data) return false;
      if (isEmpty(data)) return false;
      const validators = [
        validateReceiverPhoneNumbers,
        validateOrderQuantity,
        validateGiftiShowSendTime,
        validatePaymentMethod,
        validateGiftishowItem,
      ];
      for (const validator of validators) {
        if (!(await validator(data))) {
          return false;
        }
      }
      return true;
    },
    [
      validateGiftishowItem,
      validateGiftiShowSendTime,
      validateOrderQuantity,
      validatePaymentMethod,
      validateReceiverPhoneNumbers,
    ]
  );

  const ticketValidate = useCallback(
    (data) => {
      if (!data) return false;
      if (isEmpty(data)) return false;
      const validators = [validateReceiverPhoneNumbers, validateOrderQuantity, validatePaymentMethod];
      for (const validator of validators) {
        if (!validator(data)) {
          return false;
        }
      }
      return true;
    },
    [validateOrderQuantity, validatePaymentMethod, validateReceiverPhoneNumbers]
  );

  const regularDeliveryValidate = useCallback(
    (data) => {
      if (!data) return false;
      if (isEmpty(data)) return false;
      const validators = [validateStandardOrderDeliveryList];
      for (const validator of validators) {
        if (!validator(data)) {
          return false;
        }
      }
      return true;
    },
    [validateStandardOrderDeliveryList]
  );

  const parkPointValidate = useCallback(
    (data) => {
      if (!data) return false;
      if (isEmpty(data)) return false;
      const validators = [validateReceiverData, validatePaymentMethod];
      for (const validator of validators) {
        if (!validator(data)) {
          return false;
        }
      }
      return true;
    },
    [validatePaymentMethod, validateReceiverData]
  );

  const validatePayMethod = useCallback(
    (data) => {
      if (!data) return false;
      const { paymentAmount, paymentMethod } = data;
      if (paymentAmount.paymentTotalAmount > 0 && !paymentMethod.payMeanCode) {
        showAlertDialog('결제 수단을 확인해주세요.', () => ({}));
        return false;
      }
      return true;
    },
    [showAlertDialog]
  );

  const validateCartCoupon = useCallback(
    (data) => {
      if (!data) return false;
      const { couponList, paymentMethod, paymentAmount } = data;
      const { paymentTotalAmount, totalProductAmount, discountAmount, deliveryAmount } = paymentAmount;

      if (!paymentMethod) return true;

      if (discountAmount?.cartCouponDiscount) {
        const cartCoupon = couponList.find(
          ({ isSelect, promotionTypeCode }) => isSelect && '022' === promotionTypeCode
        );
        if (!cartCoupon) {
          showAlertDialog('장바구니 쿠폰이 유효하지 않습니다.', () => ({}));
          return false;
        }

        const { applyLimitAmount = 0 } = cartCoupon;
        const applyCreditCardList = cartCoupon.applyCreditCardList || [];

        if (applyLimitAmount > totalProductAmount - discountAmount.goodCouponDiscount) {
          showAlertDialog('장바구니 쿠폰 적용을 위한 결제 금액을 확인해주세요.', () => ({}));
          return false;
        }

        if (paymentMethod.payMeanCode !== '11') return true;
        if (applyCreditCardList.length === 0) return true;

        if (applyLimitAmount > paymentTotalAmount - deliveryAmount.total + discountAmount.cartCouponDiscount) {
          showAlertDialog('카드사 할인 혜택 적용을 위한 결제 금액을 확인해주세요.', () => ({}));
          return false;
        }

        /* 카드사 장바구니 할인 */
        if (!applyCreditCardList.some((cardCode) => cardCode === paymentMethod.cardCompanyCode)) {
          showAlertDialog('쿠폰 할인 금액 적용을 위한 카드사가 유효하지 않습니다.', () => ({}));
          return false;
        }
      }
      return true;
    },
    [showAlertDialog]
  );

  const validateSavePoint = useCallback(
    (data) => {
      if (data.paymentAmount.pointAmount.savePoint.use && data.paymentAmount.pointAmount.savePoint.use < 1000) {
        showAlertDialog('적립금은 1000원 이상부터 사용 가능합니다.', () => ({}));
        return false;
      }
      return true;
    },
    [showAlertDialog]
  );

  const validateOrderer = useCallback(
    ({ ordererInformation }) => {
      const { ordererName, ordererEmail, ordererCellphoneNumber } = ordererInformation;
      const [cellphoneNumber1, cellphoneNumber2, cellphoneNumber3] = getPhoneNumbers(ordererCellphoneNumber);
      if (!ordererName || !ordererEmail || !cellphoneNumber1 || !cellphoneNumber2 || !cellphoneNumber3) {
        showAlertDialog('주문자 정보를 확인해주세요.', () => ({}));
        return false;
      }
      return true;
    },
    [showAlertDialog]
  );

  //가격미노출(=신청형) 경우, 최종 결제금액이 0원일 때에만 계속 진행.
  const validatePriceOpen = useCallback(
    (data) => {
      if (data.isPriceOpen != undefined && !data.isPriceOpen && data.paymentAmount.paymentTotalAmount > 0) {
        showAlertDialog('잔여 신청권이 없습니다.');
        return false;
      }
      return true;
    },
    [showAlertDialog]
  );

  const validate = useCallback(
    async (data) => {
      if (!validatePriceOpen(data)) return false;
      if (!validateOrderer(data)) return false;
      if (!validateSavePoint(data)) return false;
      if (!validateAdult(data)) return false;
      if (data.present) {
        if (!presentValidate(data)) return false;
      } else if (data.orderTypeCode === '82') {
        if (!(await giftiShowValidate(data))) return false;
      } else if (data.orderTypeCode === '81') {
        if (!regularDeliveryValidate(data)) return false;
      } else if (data.orderGoodType?.pointGood) {
        if (!parkPointValidate(data)) return false;
      } else if (data.orderGoodType?.ticket) {
        if (!ticketValidate(data)) return false;
      } else {
        const result = await standardValidate(data);
        if (!result) return false;
      }
      if (!validateDeliveryHopeDate(data)) return false;
      if (!validatePayMethod(data)) return false;
      if (!validateCartCoupon(data)) return false;
      return true;
    },
    [
      validatePriceOpen,
      validateOrderer,
      validateSavePoint,
      validateAdult,
      validateDeliveryHopeDate,
      validatePayMethod,
      validateCartCoupon,
      presentValidate,
      giftiShowValidate,
      regularDeliveryValidate,
      parkPointValidate,
      ticketValidate,
      standardValidate,
    ]
  );

  return { validate, error, setError };
}
