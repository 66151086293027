import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useCreditCard from '@ecp/common/src/hooks/order/payment/useCreditCard';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import DropdownDevice from '@ecp/common/src/components/dropdown/mo/DropdownDevice';
import { ORDER_TYPE } from '@ecp/common/src/const/order/orderConst';

import paymentApi from '@mo-apis/order/paymentApi';

const CreditCard = ({ handleChangePaymentMethod, paymentMethod, orderType }) => {
  const { creditCardList, handleChangeCard, selectedCreditCard } = useCreditCard(
    paymentMethod,
    handleChangePaymentMethod,
    paymentApi.getCreditCardList,
    orderType
  );

  return (
    <Container>
      <TitleTextBox required>카드 선택</TitleTextBox>
      <Spacing top={10} />
      <DropdownDevice
        width="100%"
        placeholder="카드 종류를 선택해 주세요"
        items={creditCardList}
        value={selectedCreditCard}
        placement="top"
        onChange={handleChangeCard}
      />
    </Container>
  );
};

CreditCard.propTypes = {
  handleChangePaymentMethod: PropTypes.func,
  paymentMethod: PropTypes.object,
  orderType: PropTypes.oneOf(Object.values(ORDER_TYPE)),
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const TitleTextBox = styled(TextBox)`
  font-size: 14px;
  font-weight: 700;
`;

export default CreditCard;
