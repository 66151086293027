import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { fontPropTypes, sizePropTypes } from '@ecp/common/src/style/propTypes';
import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { zIndex } from '@ecp/common/src/style/constant';
import { FlexBox, FlexBoxCenter, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import DropdownUnstyled, { stateClassNames } from '@ecp/common/src/components/unstyled/dropdown/DropdownUnstyled';
import { flatBottomItem, flatTopItem } from '@ecp/common/src/components/dropdown/Dropdown';
import { ReactComponent as CollapseArrow } from '@ecp/common/src/assets/icon/collapse_arrow.svg';

const textDefaultProps = {
  'font-size': moTheme.font.size.$14,
  'font-weight': '350',
};

const DropdownLabelBorderlessStyle = css`
  border: none;
  height: 22px;
  font-weight: 500;
`;
export const DefaultLabelRoot = styled(FlexBoxSpaceBetween)`
  align-items: center;
  padding: 0 10px;
  border: 1px ${theme.color.line.disabled} solid;
  width: 100%;
  height: 36px;
  ${overrideProperties(fontPropTypes, textDefaultProps)};

  &.${stateClassNames.disabled} {
    background-color: ${theme.color.background.disabled};
  }

  &.${stateClassNames.open} {
    border-color: ${({ openBorderColor }) => (openBorderColor ? openBorderColor : theme.color.secondary)};
  }

  ${({ type }) => type === 'borderless' && `${DropdownLabelBorderlessStyle}`};
`;

export const DefaultLabelText = styled(FlexBox)`
  &.${stateClassNames.notSelected} {
    ${overrideProperties(fontPropTypes, textDefaultProps)};
    color: ${theme.color.text.placeholder};
  }

  //width: ${({ type }) => (type === 'borderless' ? 'calc(100% - 6px)' : 'calc(100% - 30px)')};
  width: ${({ type }) => (type === 'borderless' ? 'calc(100% - 6px)' : '')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

export const DefaultLabelIcon = styled(CollapseArrow)`
  ${({ type }) => (type === 'borderless' ? 'width: 8px' : 'width: 12px')};
  height: 8px;
  background-repeat: no-repeat;
  background-position: center;

  &.${stateClassNames.disabled} {
    color: ${theme.color.background.disabled};
  }

  &.${stateClassNames.open} {
    transform: rotate(180deg);
  }
`;

export const StyledDefaultLabel = ({ item, placeholder, className, type, ...props }) => {
  return (
    <DefaultLabelRoot className={className} type={type} {...props}>
      <DefaultLabelText className={className} type={type}>
        {item?.label || placeholder}
      </DefaultLabelText>
      <DefaultLabelIcon className={className} type={type} />
    </DefaultLabelRoot>
  );
};
StyledDefaultLabel.displayName = 'StyledDefaultLabel';

StyledDefaultLabel.propTypes = {
  type: PropTypes.string,
  item: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

const DefaultItem = styled(FlexBoxCenter)`
  justify-content: flex-start;
  height: 40px;
  padding: 10px;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${overrideProperties(fontPropTypes, textDefaultProps)};

  &:hover {
    background-color: ${theme.color.background.sub};
  }
`;
export const StyledDefaultItem = ({ label }) => <DefaultItem>{label}</DefaultItem>;

StyledDefaultItem.propTypes = {
  label: PropTypes.string,
};

export const StyledDropdownRoot = styled.button`
  position: relative;
  ${overrideProperties(sizePropTypes, { width: '100%', height: '36px' })};
`;

const DropdownItemsRootProps = {
  position: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
  'max-height': PropTypes.string,
};

const DropdownItemsRootDefaultProps = {
  position: 'absolute',
  top: 'o',
  left: '0',
  'max-height': '300px',
};

const DropdownItemBorderlessStyle = css`
  border: 1px solid #dfdfdf;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
`;

export const DropdownItemsRoot = styled.div`
  border: 1px solid ${({ openBorderColor }) => (openBorderColor ? openBorderColor : theme.color.secondary)};
  ${({ isFlip }) => (isFlip ? flatTopItem : flatBottomItem)}
  width: 100%;
  box-sizing: border-box;
  background-color: ${theme.color.background.white};
  ${({ type }) => type === 'borderless' && `${DropdownItemBorderlessStyle}`};
  ${({ type }) => type === 'button' && `${DropdownItemBorderlessStyle}`};
  ${overrideProperties(DropdownItemsRootProps, DropdownItemsRootDefaultProps)};
  overflow-y: auto;
  z-index: ${zIndex.dropdown};
`;

const Dropdown = React.forwardRef((props, ref) => (
  <DropdownUnstyled
    type={props?.btnType}
    ref={ref}
    dropdownRoot={StyledDropdownRoot}
    dropdownItemsRoot={DropdownItemsRoot}
    title={props?.placeholder}
    {...props}
  />
));

Dropdown.displayName = 'Dropdown';

Dropdown.propTypes = {
  ...sizePropTypes,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]),
  labelSlot: PropTypes.elementType,
  itemSlot: PropTypes.elementType,
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  itemsMaxHeight: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'borderless']),
  btnType: PropTypes.string,
};

Dropdown.defaultProps = {
  labelSlot: StyledDefaultLabel,
  itemSlot: StyledDefaultItem,
  items: [],
  placeholder: '선택하세요',
  disabled: false,
  placement: 'bottom-start',
  type: 'button',
  btnType: 'button',
};

export default Dropdown;
