import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxCenter } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { ReactComponent as Question } from '@ecp/common/src/assets/icon/icon-question.svg';

export const MainContentsTitle = styled(TextBox)`
  font-weight: ${moTheme.font.weight.bold};
  font-size: ${moTheme.font.size.$18};
  line-height: ${moTheme.font.lineHeight.demiLight};
  color: ${theme.color.text.basic};
  word-break: keep-all;
`;

export const QuestionIcon = styled(Question)`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const CircleWrapper = styled(FlexBoxCenter)`
  width: 40px;
  height: 40px;
  border: 1px solid ${theme.color.line.line02};
  border-radius: 50%;
  cursor: pointer;
  background-color: ${theme.color.background.white};
  overflow: hidden;
  position: relative;
`;
