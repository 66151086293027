import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

import SearchFilterPopupCategoryItem from '@mo-components/display/filter/components/SearchFilterPopupCategoryItem';

const initialCurrentCategory = {
  categoryNumber: {
    large: '',
    middle: '',
    small: '',
    detail: '',
  },
  depthNumber: 0,
  goodCount: 0,
};

const getCategoryLabel = ({ categoryName, goodCount }) => {
  if (!goodCount) {
    return categoryName;
  }
  return `${categoryName}(${NumberFormat(goodCount)})`;
};

const SearchFilterPopupCategory = ({
  totalCount,
  currentCategory,
  filteredLargeCategory,
  filteredMiddleCategory,
  filteredSmallCategory,
  filteredDetailCategory,
  getTypeOfTreeItem,
  getCategoryToBeChanged,
  handleCurrentCategoryChange,
  handleCategoryChange,
}) => {
  const handleCategoryClick = useCallback(
    (category) => {
      const { categoryNumber, depthNumber } = category;

      const newCategory = getCategoryToBeChanged(categoryNumber, depthNumber);
      const updatedCurrentCategory = {
        ...currentCategory,
        depthNumber,
        categoryNumber: { ...currentCategory.categoryNumber, ...newCategory },
      };
      handleCurrentCategoryChange(updatedCurrentCategory);
      handleCategoryChange(newCategory);
    },
    [currentCategory, getCategoryToBeChanged, handleCategoryChange, handleCurrentCategoryChange]
  );

  return (
    <FlexBoxColumn width={'100%'}>
      <TextBox width={'100%'} size={moTheme.font.size.$14} weight={moTheme.font.weight.bold}>
        {getCategoryLabel({ categoryName: '카테고리', goodCount: totalCount })}
      </TextBox>
      <Spacing top="10px" />
      <HorizontalDivider color={theme.color.third} height="2px" />
      <Spacing top="6px" />
      {!!currentCategory?.categoryNumber?.large && (
        <SearchFilterPopupCategoryItem
          selected={currentCategory === initialCurrentCategory}
          onClick={() => handleCategoryClick(initialCurrentCategory)}
          label={'전체카테고리'}
          type={'primary'}
        />
      )}
      {filteredLargeCategory?.map((category, index) => (
        <FlexBoxColumn width="100%" key={`large-category__${index}`}>
          <SearchFilterPopupCategoryItem
            selected={currentCategory?.categoryNumber?.current === category.categoryNumber}
            onClick={() => handleCategoryClick(category)}
            label={getCategoryLabel(category)}
            type={getTypeOfTreeItem(currentCategory?.depthNumber)?.largeCategoryType}
          />
        </FlexBoxColumn>
      ))}
      {filteredMiddleCategory?.map((category, index) => (
        <FlexBoxColumn width="100%" key={`middle-category__${index}`}>
          <SearchFilterPopupCategoryItem
            selected={currentCategory?.categoryNumber?.current === category.categoryNumber}
            onClick={() => handleCategoryClick(category)}
            label={getCategoryLabel(category)}
            type={getTypeOfTreeItem(currentCategory?.depthNumber)?.middleCategoryType}
          />
        </FlexBoxColumn>
      ))}
      {filteredSmallCategory?.map((category, index) => (
        <FlexBoxColumn width="100%" key={`small-category__${index}`}>
          <SearchFilterPopupCategoryItem
            selected={currentCategory?.categoryNumber?.current === category.categoryNumber}
            onClick={() => handleCategoryClick(category)}
            label={getCategoryLabel(category)}
            type={getTypeOfTreeItem(currentCategory?.depthNumber)?.smallCategoryType}
          />
        </FlexBoxColumn>
      ))}
      {filteredDetailCategory?.map((category, index) => (
        <FlexBoxColumn width="100%" key={`detail-category__${index}`}>
          <SearchFilterPopupCategoryItem
            selected={currentCategory?.categoryNumber?.current === category.categoryNumber}
            onClick={() => handleCategoryClick(category)}
            label={getCategoryLabel(category)}
            type={getTypeOfTreeItem(currentCategory?.depthNumber)?.detailCategoryType}
          />
        </FlexBoxColumn>
      ))}
      <Spacing top="14px" />
    </FlexBoxColumn>
  );
};

export default SearchFilterPopupCategory;

SearchFilterPopupCategory.propTypes = {
  totalCount: PropTypes.number,
  currentCategory: PropTypes.object,
  filteredLargeCategory: PropTypes.array,
  filteredMiddleCategory: PropTypes.array,
  filteredSmallCategory: PropTypes.array,
  filteredDetailCategory: PropTypes.array,
  getTypeOfTreeItem: PropTypes.func,
  getCategoryToBeChanged: PropTypes.func,
  handleCurrentCategoryChange: PropTypes.func,
  handleCategoryChange: PropTypes.func,
};
