import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import LayerPopup from '@ecp/common/src/components/modal/mo/popup/LayerPopup';
import Paper from '@ecp/common/src/components/paper/Paper';

const ThirdPartyAgreePopup = ({ open, onClose, orderGoodType }) => {
  return (
    <LayerPopup
      open={open}
      onClose={onClose}
      onConfirmClick={onClose}
      header={'개인정보 제 3자 제공동의'}
      useCancelButton={false}
    >
      <Container>
        <CollectionPersonalInformationOl>
          <TitleTextBox>
            <HighlightTextBox>제공 받는자 : {orderGoodType.alcohol ? ' 관할 세무서' : ' 관세청'}</HighlightTextBox>
          </TitleTextBox>
          <TitleTextBox>
            <HighlightTextBox>
              목적 :
              {orderGoodType.alcohol
                ? ' 국세청 고시에 따른 주류\n통신판매 주문에 대한 분기별 명세\n세무서 제공'
                : ' 해외직배송 상품 수입 신고'}
            </HighlightTextBox>
          </TitleTextBox>
          <TitleTextBox>
            항목 :
            {orderGoodType.alcohol
              ? ' 주문자 인적 사항(주소, 성명, 생년월일),\n주문일자, 상품명, 수량, 단가, 주문금액'
              : ' 수하인 이름, 수하인 연락처, 수하인\n주소, 수하인 개인통관 고유부호'}
          </TitleTextBox>
          <TitleTextBox>
            <HighlightTextBox>
              보유기간 : {orderGoodType.alcohol ? ' 국세청 고시에 따른 보존\n기간' : ' 배송 완료시까지'}
            </HighlightTextBox>
          </TitleTextBox>
        </CollectionPersonalInformationOl>

        <PaperContainer>
          <PaperTextBox>※ 귀하는 동의를 거부할 권리가 있으며, 동의 거부 시 물품배송이 불가합니다.</PaperTextBox>
        </PaperContainer>
      </Container>
    </LayerPopup>
  );
};

ThirdPartyAgreePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  orderGoodType: PropTypes.object,
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
  gap: 10px;
`;

const TitleTextBox = styled.li`
  margin-top: 10px;
  list-style: decimal;
  font-size: 14px;
  font-weight: 350;
  white-space: break-spaces;
  overflow-wrap: anywhere;

  &:first-child {
    margin-top: 0;
  }
`;

const HighlightTextBox = styled.span`
  font-size: 17px;
  font-weight: 700;
  color: ${theme.color.primary};
  white-space: break-spaces;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;

const PaperContainer = styled(Paper)`
  width: 100%;
  padding: 14px;
  background-color: #f7f7f7;
`;

const PaperTextBox = styled(TextBox)`
  font-size: 12px;
  font-weight: 350;
  color: #5a5a5a;
`;

const CollectionPersonalInformationOl = styled.ol`
  width: 100%;
  padding-inline-start: 15px;
`;

export default ThirdPartyAgreePopup;
