import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isEmpty from 'lodash-es/isEmpty';

import {
  getApplyLimit,
  getAvailableDate,
  getCouponDetailByDevice,
  getCouponDetailByType,
} from '@ecp/common/src/utils/couponUtils';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { zIndex } from '@ecp/common/src/style/constant';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox, TextBoxAlignCenter } from '@ecp/common/src/text/TextBox';
import FullPagePopup from '@ecp/common/src/components/modal/mo/popup/FullPagePopup';
import Tooltip from '@ecp/common/src/components/tooltip/mo/Tooltip';
import { COUPON_POPUP_TYPE, COUPON_TYPE_CODE } from '@ecp/common/src/const/coupon/couponConst';
import { ReactComponent as DownloadIcon } from '@ecp/common/src/assets/icon/icon__app-download.svg';
import { ReactComponent as WhiteDownloadIcon } from '@ecp/common/src/assets/icon/icon_download_white.svg';
import { ReactComponent as DownloadCompleteStampImage } from '@ecp/common/src/assets/image/image__coupon_download_complete.svg';

import { useAlertDialog, useConfirmDialog } from '@mo-hooks/common/useModal';
import couponApi from '@mo-apis/coupon/couponApi';
import {
  BadgeText,
  CouponBoundaryPoint,
  CouponContainer,
  CouponLeft,
  CouponNameText,
  CouponRight,
  HelpIcon,
} from '@mo-components/coupon/CouponComponents';
import { ERROR_CODE } from '@mo-const/errorConst';

const confirmButtonDefaultProps = {
  type: 'Default',
  width: '320px',
};

const boundaryPointColor = `${theme.color.background.basic}`;
const EmployeeBenefitCouponPopup = ({
  open,
  onClose,
  detailName = '',
  detailNumber,
  type,
  setInfoChanged = () => {},
}) => {
  const [couponList, setCouponList] = useState([]);
  const { showConfirmDialog } = useConfirmDialog();
  const { showAlertDialog } = useAlertDialog();

  const getEmployeeBenefitCouponList = useCallback(async () => {
    const applyType = type === COUPON_POPUP_TYPE.CATEGORY ? COUPON_TYPE_CODE.CATEGORY : COUPON_TYPE_CODE.BRAND;
    const { result: couponList } = await couponApi.getEmployeeBenefitCouponList(applyType, detailNumber);

    const formattedCouponList = couponList.map((coupon) => ({
      ...coupon,
      applyLimitAmount: coupon.benefitApplyMinAmount,
      maxDiscountAmount: coupon.benefitApplyMaxAmount,
      benefitValue: coupon.benefitApplyValue,
      validStartDate: coupon.validTermStartDate,
      validEndDate: coupon.validTermEndDate,
      downloadableCount: Number(coupon.pidIssueLimitQuantity) - Number(coupon.myCouponCount),
      downloadable: !(
        (coupon.totalIssueQuantityCode === '10' && coupon.possibleIssueQuantity === 0) ||
        (coupon.pidIssueQuantityCode === '10' &&
          Number(coupon.pidIssueLimitQuantity) - Number(coupon.myCouponCount) === 0)
      ),
    }));
    setCouponList([
      ...formattedCouponList.map((coupon) => ({
        ...coupon,
        ...getCouponDetailByType({ ...coupon }),
        ...getCouponDetailByDevice({ ...coupon }),
        ...getApplyLimit({ ...coupon, multiLine: true }),
        availableDate: getAvailableDate({ ...coupon }),
      })),
    ]);
  }, [detailNumber, type]);

  useEffect(() => {
    (async () => {
      if (open) {
        await getEmployeeBenefitCouponList();
      }
    })();
  }, [getEmployeeBenefitCouponList, open]);

  const handleDownloadClick = useCallback(
    async (promotionNumber) => {
      setInfoChanged(false);
      const { code } = await couponApi.downloadCoupon(promotionNumber);
      if (code === '200') {
        showAlertDialog('쿠폰이 정상적으로 발급되었습니다.');
        await getEmployeeBenefitCouponList();
        setInfoChanged(true);
      } else if (code === ERROR_CODE.COUPON_EXCESS_LIMIT_QUANTITY_ERROR.code) {
        showAlertDialog(ERROR_CODE.COUPON_EXCESS_LIMIT_QUANTITY_ERROR.message);
      } else {
        showAlertDialog('다운로드 불가한 쿠폰입니다.');
      }
    },
    [getEmployeeBenefitCouponList, setInfoChanged, showAlertDialog]
  );
  const handleDownloadAllClick = useCallback(() => {
    setInfoChanged(false);
    showConfirmDialog('모든 쿠폰을 다운로드 하시겠습니까?', async () => {
      const { code, message } = await couponApi.downloadAllCoupon(
        couponList.filter(({ downloadable }) => downloadable)?.map(({ promotionNo }) => promotionNo)
      );
      if (code === '200') {
        showAlertDialog('모든 쿠폰이 정상적으로 발급되었습니다.');
        await getEmployeeBenefitCouponList();
        setInfoChanged(true);
      } else {
        showAlertDialog(message);
      }
    });
  }, [couponList, getEmployeeBenefitCouponList, setInfoChanged, showAlertDialog, showConfirmDialog]);

  const confirmButtonProps = useMemo(
    () =>
      couponList.some(({ downloadable }) => downloadable)
        ? confirmButtonDefaultProps
        : {
            ...confirmButtonDefaultProps,
            disabled: true,
            color: '#A3A3A3 !important',
            backgroundColor: '#D3D3D3 !important',
            borderColor: '#D3D3D3 !important',
          },
    [couponList]
  );

  const availableCouponCount = useMemo(() => couponList?.length, [couponList?.length]);
  return (
    <FullPagePopup
      open={open}
      header={`${detailName && `${detailName} `}${
        type === COUPON_POPUP_TYPE.CATEGORY ? '카테고리' : '브랜드'
      } 쿠폰 목록`}
      useCancelButton={false}
      onClose={onClose}
      onConfirmClick={handleDownloadAllClick}
      confirmButtonProps={confirmButtonProps}
      confirmButtonText={
        couponList?.some(({ downloadable }) => downloadable) ? '쿠폰 전체 다운 받기' : '쿠폰 전체 받기 완료'
      }
    >
      <CouponListContainer
        padding={'20px 20px'}
        align-items={'flex-start'}
        width={'100%'}
        height={'100%'}
        background-color={boundaryPointColor}
      >
        <TextBox>사용 가능 쿠폰 : {availableCouponCount}개</TextBox>
        <Spacing bottom={10} />
        {couponList?.map((coupon, idx) => (
          <Spacing bottom={8} key={`${coupon.promotionNo}-${idx}`}>
            <CouponWrapper>
              <CouponContainer width="280px">
                <CouponBoundaryPoint topPos={-6} rightPos={32} color={boundaryPointColor} />
                <CouponBoundaryPoint topPos={142} rightPos={32} color={boundaryPointColor} />
                {!coupon.downloadable && (
                  <DimWrapper
                    width="100%"
                    height="100%"
                    background-color={'#000000'}
                    border-radius={'8px'}
                    justify-content={'flex-end'}
                    padding={10}
                    style={{ position: 'absolute', opacity: '0.65', zIndex: zIndex.appBar }}
                  >
                    <DownloadCompleteStampImage width={80} />
                  </DimWrapper>
                )}
                <CouponLeft color={`${theme.color.text.white}`}>
                  <Spacing left={20} />
                  <FlexBoxColumn width={'100%'} height={'100%'} align-items={'flex-start'}>
                    <Spacing top={14} />
                    <FlexBoxSpaceBetween width={'100%'} height={'24px'} align-items={'flex-start'}>
                      <FlexBox>
                        <TextBox
                          size={!isEmpty(coupon.benefitValueType) ? moTheme.font.size.$24 : moTheme.font.size.$22}
                          weight={moTheme.font.weight.bold}
                          color={theme.color.primary}
                        >
                          {coupon.benefitValue}
                        </TextBox>
                        <TextBox size={moTheme.font.size.$16} padding={'2px 0 0 0'} color={theme.color.primary}>
                          {coupon.benefitValueType}
                        </TextBox>
                      </FlexBox>

                      {coupon.applyDevice !== 'all' && (
                        <Spacing right={10}>
                          <BadgeText>{coupon.applyDevice}</BadgeText>
                        </Spacing>
                      )}
                    </FlexBoxSpaceBetween>

                    <Spacing top={4} />

                    <FlexBoxSpaceBetween width="100%" align-items={'flex-start'}>
                      <CouponNameText width="182px"> {coupon.promotionName} </CouponNameText>
                      {coupon.promotionNoticeMessage && (
                        <Tooltip
                          value={
                            <TextBox
                              padding={'0 14px 0 0'}
                              size={theme.font.size.error}
                              weight={'350'}
                              color={theme.color.text.sub}
                              style={{ maxWidth: '230px', overflowWrap: 'anywhere' }}
                            >
                              {coupon.promotionNoticeMessage}
                            </TextBox>
                          }
                          closeable={true}
                          openWhenClicked
                          placement={'bottom'}
                          rootProps={{ zIndex: zIndex.tooltip }}
                        >
                          <HelpIcon />
                        </Tooltip>
                      )}
                    </FlexBoxSpaceBetween>

                    <Spacing top={4} />
                    <TextBox
                      height={'21px'}
                      size={moTheme.font.size.$13}
                      weight={moTheme.font.weight.demiLight}
                      color={theme.color.text.sub}
                      lineHeight={'160%'}
                    >
                      {coupon.applyLimitText}
                    </TextBox>
                    <TextBox
                      height={'21px'}
                      size={moTheme.font.size.$13}
                      weight={moTheme.font.weight.demiLight}
                      color={theme.color.text.sub}
                      lineHeight={'160%'}
                    >
                      {coupon.maxDiscountText}
                    </TextBox>
                    <TextBox
                      height={'21px'}
                      size={moTheme.font.size.$13}
                      weight={moTheme.font.weight.demiLight}
                      color={theme.color.text.sub}
                      lineHeight={'160%'}
                    >
                      {coupon.availableDate}
                    </TextBox>
                    <Spacing botom={14} />
                  </FlexBoxColumn>
                  <Spacing left={10} />
                </CouponLeft>
                <CouponRight color={coupon.rightSideColor} onClick={() => handleDownloadClick(coupon.promotionNo)}>
                  <FlexBoxColumn>
                    {(coupon.pidIssueQuantityCode === '20' || coupon.downloadableCount > 0) &&
                      (coupon.applyDevice !== 'all' ? (
                        <WhiteDownloadIcon style={{ width: '16px', color: '#ffffff' }} />
                      ) : (
                        <DownloadIcon style={{ width: '16px', color: '#141415' }} />
                      ))}
                    {(coupon.pidIssueQuantityCode === '20' || coupon.downloadableCount > 1) && (
                      <TextBoxAlignCenter
                        weight={500}
                        size={10}
                        color={coupon.applyDevice !== 'all' ? '#ffffff' : '#141415'}
                      >
                        {coupon.pidIssueQuantityCode === '20' ? '+99' : coupon.downloadableCount}장
                      </TextBoxAlignCenter>
                    )}
                  </FlexBoxColumn>
                </CouponRight>
              </CouponContainer>
            </CouponWrapper>
          </Spacing>
        ))}
      </CouponListContainer>
    </FullPagePopup>
  );
};

export default EmployeeBenefitCouponPopup;

EmployeeBenefitCouponPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  detailName: PropTypes.string,
  detailNumber: PropTypes.string,
  type: PropTypes.string,
  setInfoChanged: PropTypes.func,
};

const CouponWrapper = styled(FlexBox)`
  box-sizing: border-box;
  width: 280px;
  height: 150px;
  justify-content: center;
  overflow: hidden;
`;
const DimWrapper = styled(FlexBox)`
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.65;
  z-index: ${zIndex.appBar};
  border-radius: 8px;
  justify-content: flex-end;
  padding: 10px;
  position: absolute;
`;
const CouponListContainer = styled(FlexBoxColumn)`
  width: 100%;
  height: 100%;
  background-color: ${({ boundaryPointColor }) => `${boundaryPointColor}`};
  padding: 15px 22px;
  overflow-x: overlay;
`;
