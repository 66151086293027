import { getRequest } from '@mo-apis/axios';

const getStoreUrl = () =>
  getRequest({
    url: '/api/v1/mobile/store/get-store-url',
    isMobile: true,
  });

const getAllStoreUrl = () =>
  getRequest({
    url: '/api/v1/mobile/store/get-all-store-url',
    isMobile: true,
  });

const mobileStoreApi = {
  getStoreUrl,
  getAllStoreUrl,
};

export default mobileStoreApi;
