import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isEmpty from 'lodash-es/isEmpty';

import useGoodUnusualList from '@ecp/common/src/hooks/good/useGoodUnusualList';
import usePointsUsageInformation from '@ecp/common/src/hooks/order/payment/usePointsUsageInformation';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import NumberInput from '@ecp/common/src/components/input/mo/NumberInput';
import { InnerButton } from '@ecp/common/src/components/button/mo/Button';
import { POINT_USAGE_CONST } from '@ecp/common/src/const/order/pointUsageConst';
import { SBCOM_SDI, SDI_POINT_VENDORS } from '@ecp/common/src/const/unusualSubsidiaryCompany';

import { useAlertDialog } from '@mo-hooks/common/useModal';
import useSiteInfo from '@mo-hooks/common/useSiteInfo';
import OrderPageItemTitleContainer from '@mo-components/order/payment/OrderPageItemTitleContainer';
import PointsUsageBalance from '@mo-components/order/payment/PointsUsageBalance';
import {userInfoState} from "@mo-recoil/common/user/atom";
import goodListApi from '@mo-apis/good/goodListApi';
import { TextBoxLabel } from '@ecp/common/src/text/TextBoxLabel';

const PointsUsageInformation = ({
  totalPayAmount,
  pointUsage,
  handleChangePointUsage,
  goodList,
  paymentAmount,
  orderGoodType,
  usablePaymentList,
  enablePointUsage,
  enableSavingsUsage,
  disablePaymentMethodPointUsage,
  handleWelPointCashReceiptIssuePoss,
}) => {
  const { isEventMall, displayPointName } = useSiteInfo();
  const { showAlertDialog } = useAlertDialog();
  const userInfo = useRecoilValue(userInfoState);

  // SDI 계열사만 복지포인트로 구매가능한 상품리스트(미전시 기획전 활용)
  const {sdiGoodAvailableList} = useGoodUnusualList({goodListApi});
  // console.log('sdiGoodAvailableList: ',sdiGoodAvailableList);
  // console.log('goodList: ',goodList);

  // SDI 계열사여부
  const isSDI= userInfo.subsidiaryCompanyCode === SBCOM_SDI;
  // SDI 복지포인 구매불가 상품이 존재하는지 여부
  const existsIncludeGoodNotSDI = isSDI &&
    goodList?.some((good) => !SDI_POINT_VENDORS.includes(good.vendorNumber) && !sdiGoodAvailableList?.some((speGood) => speGood.goodNumber === good.goodNumber));
  // 복지포인트 전액사용(전용몰 or 주문자가 SDI 계열사) 여부
  const noLimitWelfare = isEventMall || (isSDI &&
    goodList?.some((good) => SDI_POINT_VENDORS.includes(good.vendorNumber) || sdiGoodAvailableList?.some((speGood) => speGood.goodNumber === good.goodNumber))
  );
  console.log('isEventMall:%s, isSDI:%s, existsIncludeGoodNotSDI:%s, noLimitWelfare:%s',isEventMall,isSDI,existsIncludeGoodNotSDI,noLimitWelfare)

  const {
    welfarePointMaxAmount,
    handleBlurUseSavePoints,
    handleChangeUseSavePoints,
    handleClickUseAllSavePoints,
    handleBlurUseWelPoints,
    handleChangeUseWelPoints,
    handleClickUseAllWelPoints,
    handleBlurUseWelfarePoints,
    handleChangeUseWelfarePoints,
    handleClickUseAllWelfarePoints,
  } = usePointsUsageInformation(
    pointUsage,
    handleChangePointUsage,
    goodList,
    paymentAmount,
    totalPayAmount,
    orderGoodType,
    showAlertDialog,
    handleWelPointCashReceiptIssuePoss,
    userInfo.subsidiaryCompanyCode,
    sdiGoodAvailableList,
    isEventMall,
  );

  const PointUsageItems = useMemo(() => {
    if (isEmpty(pointUsage)) return <></>;
    const pointMapper = {
      savePoint: {
        code: '23',
        unit: '원',
        use: pointUsage?.savePoint?.use || 0,
        onChange: handleChangeUseSavePoints,
        onBlur: handleBlurUseSavePoints,
        onClickUseAll: handleClickUseAllSavePoints,
        disabled: !enableSavingsUsage || disablePaymentMethodPointUsage,
        btnDisabled: !pointUsage?.savePoint?.balance || !paymentAmount.paymentTotalAmount,
      },
      welPoint: {
        code: '22',
        unit: 'P',
        use: pointUsage?.welPoint?.use || 0,
        onChange: handleChangeUseWelPoints,
        onBlur: handleBlurUseWelPoints,
        onClickUseAll: handleClickUseAllWelPoints,
        disabled: !enablePointUsage || disablePaymentMethodPointUsage,
        btnDisabled: !pointUsage?.welPoint?.balance || !paymentAmount.paymentTotalAmount,
      },
      welfarePoint: {
        code: '24',
        unit: 'P',
        use: pointUsage?.welfarePoint?.use || 0,
        onChange: handleChangeUseWelfarePoints,
        onBlur: handleBlurUseWelfarePoints,
        onClickUseAll: handleClickUseAllWelfarePoints,
        disabled: !enablePointUsage || (isSDI && existsIncludeGoodNotSDI) || disablePaymentMethodPointUsage, // SDI계열사이고, 적용 업체가 아닌 상품이 포함된 경우 결제불가
        btnDisabled:
          !pointUsage?.welfarePoint?.balance ||
          !paymentAmount.paymentTotalAmount ||
          welfarePointMaxAmount === pointUsage?.welfarePoint?.use,
      },
    };
    return POINT_USAGE_CONST.filter(({ code }) => usablePaymentList?.includes(code)).map(({ code, name, showName }) => {
      const { balance } = pointUsage[name];
      const { unit, use, onChange, onBlur, onClickUseAll, disabled, btnDisabled } = pointMapper[name];
      return (
        <PointUsageItemContainer key={name}>
          <PointUsageItemHeader>
            <PointUsageItemHeaderName htmlFor={name}>
              {name === 'welfarePoint' ? displayPointName : showName}
            </PointUsageItemHeaderName>
            <Spacing left="10px" />
            <PointsUsageBalance balance={balance - use} unit={unit} />
          </PointUsageItemHeader>
          <PointInputContainer>
            <NumberInput
              clearable={false}
              suffixText={unit}
              width="246px"
              value={use}
              onChange={onChange}
              onBlur={onBlur}
              disabled={!balance || disabled}
              id={name}
            />
            <Spacing left="6px" />
            <InnerButton width="68px" onClick={onClickUseAll} height="36px" disabled={disabled || btnDisabled}>
              {name === 'welfarePoint' && !noLimitWelfare && !orderGoodType?.pointGood ? '최대사용' : '전액사용'}
            </InnerButton>
          </PointInputContainer>
          <Spacing top="6px" />
          {!noLimitWelfare && code === '24' && !orderGoodType?.pointGood && (
            <PointUseGuide>복지포인트는 주문금액의 50% 사용만 가능합니다.</PointUseGuide>
          )}
        </PointUsageItemContainer>
      );
    });
  }, [
    enablePointUsage,
    enableSavingsUsage,
    handleBlurUseSavePoints,
    handleBlurUseWelPoints,
    handleBlurUseWelfarePoints,
    handleChangeUseSavePoints,
    handleChangeUseWelPoints,
    handleChangeUseWelfarePoints,
    handleClickUseAllSavePoints,
    handleClickUseAllWelPoints,
    handleClickUseAllWelfarePoints,
    orderGoodType?.pointGood,
    pointUsage,
    usablePaymentList,
    paymentAmount,
    welfarePointMaxAmount,
  ]);

  return PointUsageItems?.length ? (
    <Container>
      <OrderPageItemTitleContainer title="포인트 사용" gapToBody="6px" />
      <PointUsageListContainer>{PointUsageItems}</PointUsageListContainer>
    </Container>
  ) : (
    <></>
  );
};

export default PointsUsageInformation;

PointsUsageInformation.propTypes = {
  totalPayAmount: PropTypes.number,
  pointUsage: PropTypes.object,
  handleChangePointUsage: PropTypes.func,
  goodList: PropTypes.array,
  paymentAmount: PropTypes.object,
  orderGoodType: PropTypes.object,
  usablePaymentList: PropTypes.array,
  enablePointUsage: PropTypes.bool,
  enableSavingsUsage: PropTypes.bool,
  handleWelPointCashReceiptIssuePoss: PropTypes.func,
  disablePaymentMethodPointUsage: PropTypes.bool,
};

const Container = styled(FlexBoxColumn)`
  align-items: flex-start;
  width: 100%;
`;

const PointUsageListContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
`;

const PointUsageItemContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
  margin-top: 14px;
`;
const PointUsageItemHeader = styled(FlexBox)`
  align-items: flex-start;
`;

const PointUsageItemHeaderName = styled(TextBoxLabel)`
  font-size: 13px;
  font-weight: 500;
`;

const PointInputContainer = styled(FlexBox)`
  align-items: flex-start;
  margin-top: 3px;
`;

const PointUseGuide = styled(FlexBox)`
  align-items: flex-start;
  font-size: 12px;
  font-weight: 350;
  color: #727272;
`;
