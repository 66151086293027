import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import { StyledShareIcon } from '@ecp/common/src/components/tooltip/mo/Tooltip';

import ShareBottomSheets from '@mo-components/common/share/ShareBottomSheets';
import styled from 'styled-components';
import ShareIcon from '@ecp/common/src/assets/icon/share.svg';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';

const ShareButton = ({ kakaoProps, imageProps }) => {
  const [shareSheetsOpen, setShareSheetsOpen] = useState(false);

  return (
    <>
      <StyledShareIcon onClick={() => setShareSheetsOpen(true)} {...imageProps} type="button" aria-label={'공유하기'} />
      <ShareBottomSheets kakaoProps={kakaoProps} open={shareSheetsOpen} onClose={setShareSheetsOpen} />
    </>
  );
};

const StyledShareIcon = styled.button`
  background: url(${ShareIcon}) no-repeat;
  ${overrideProperties(sizePropTypes, { width: '30px', height: '30px' })};
  cursor: pointer;
`;

export default ShareButton;

ShareButton.propTypes = {
  imageProps: PropTypes.object,
  kakaoProps: PropTypes.shape({
    title: PropTypes.string,
    imageUrl: PropTypes.string,
    productName: PropTypes.string,
    regularPrice: PropTypes.number,
    discountRate: PropTypes.number,
    discountPrice: PropTypes.number,
    linkUrl: PropTypes.string,
    productNumber: PropTypes.string,
  }),
};
