import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useAccordionContext } from '@ecp/common/src/components/unstyled/accordion/useAccordionContext';
import { ReactComponent as IconOpen } from '@ecp/common/src/assets/icon/icon__arrow--below.svg';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
const openToggleStyle = css`
  transform: scaleY(-1);
`;

const StyledOpen = styled(FlexBox)`
  & svg {
    ${({ open }) => open && openToggleStyle}
  }
`;

const AccordionControlQUnstyled = (props) => {
  const { children, rootComponent: AccordionControl, buttonElement, buttonless, noFold, ...otherProps } = props;
  const { open, handleOpenClick } = useAccordionContext();

  const handleAccordionOpenClick = () => {
    if (noFold) return;
    handleOpenClick();
  };

  return (
    <AccordionControl
      onClick={buttonless ? handleAccordionOpenClick : () => {}}
      style={buttonless && !noFold ? { cursor: 'pointer' } : {}}
      {...otherProps}
    >
      {children}
      {!buttonless && (
        <StyledOpen onClick={handleAccordionOpenClick} open={open}>
          {buttonElement ? buttonElement : <IconOpen />}
        </StyledOpen>
      )}
    </AccordionControl>
  );
};

export default AccordionControlQUnstyled;

export const AccordionControlPropTypes = {
  rootComponent: PropTypes.elementType,
  buttonElement: PropTypes.node,
  buttonless: PropTypes.bool,
  noFold: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

AccordionControlQUnstyled.propTypes = AccordionControlPropTypes;

export const AccordionControlDefaultProps = {
  buttonless: false,
  noFold: false,
};

AccordionControlQUnstyled.defaultProps = AccordionControlDefaultProps;
