import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getPhoneNumbers } from '@ecp/common/src/utils/textUtil';
import { textEllipsis } from '@ecp/common/src/style/theme/defaultStyled';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { InnerButton } from '@ecp/common/src/components/button/mo/Button';
import Accordion from '@ecp/common/src/components/accordion/mo/Accordion';
import { PD_GOOD_TYPE } from '@ecp/common/src/const/good';

import OrdererChangePopup from '@mo-components/order/payment/popup/OrdererChangePopup';

const OrdererInformation = ({ isPresent = false, goodList, ordererInformation, handleChangeOrdererInformation }) => {
  const [ordererChangePopupOpen, setOrdererChangePopupOpen] = useState(false);

  const cellphoneNumberWithDash = useMemo(() => {
    const [cellphoneNumber1, cellphoneNumber2, cellphoneNumber3] = getPhoneNumbers(
      ordererInformation.ordererCellphoneNumber
    );
    return `${cellphoneNumber1}-${cellphoneNumber2}-${cellphoneNumber3}`;
  }, [ordererInformation]);

  const handlePopupOpen = useCallback(() => setOrdererChangePopupOpen(true), []);
  const handlePopupClose = useCallback(() => setOrdererChangePopupOpen(false), []);

  const headerTitle = useMemo(() => {
    if (isPresent) {
      return '보내는 분';
    } else if (goodList?.some(({ goodTypeCode }) => goodTypeCode === PD_GOOD_TYPE.PARK_POINT)) {
      return '신청 임직원';
    }
    return '주문자정보';
  }, [goodList, isPresent]);

  return (
    <Container>
      <Accordion>
        <Accordion.Header>
          <HeaderTitleTextBox>{headerTitle}</HeaderTitleTextBox>
          <FlexBox>
            <HeaderOrdererInfoTextBox>
              {ordererInformation.ordererName} ({cellphoneNumberWithDash})
            </HeaderOrdererInfoTextBox>
            <Spacing left={'14px'} />
            <FlexBox>
              <Accordion.Control buttonPadding="5px 0 0 0" headerTitle={headerTitle} />
            </FlexBox>
          </FlexBox>
        </Accordion.Header>
        <Spacing top={'20px'} />
        <Accordion.Body undead>
          <BodyContainer>
            <Spacing top={'10px'} />
            <FlexBoxSpaceBetween width={'100%'}>
              <TextBox weight={500} size={'13px'}>
                {ordererInformation.ordererName}
              </TextBox>
              <InnerButton width={'44px'}>
                <TextBox weight={500} size={'13px'} onClick={handlePopupOpen}>
                  변경
                </TextBox>
              </InnerButton>
            </FlexBoxSpaceBetween>
            <Spacing top={'12px'} />
            <OrdererInfoText>
              {cellphoneNumberWithDash} / {ordererInformation.ordererEmail}
            </OrdererInfoText>
            <Spacing top={'10px'} />
          </BodyContainer>
        </Accordion.Body>
      </Accordion>
      <OrdererChangePopup
        open={ordererChangePopupOpen}
        onClose={handlePopupClose}
        ordererInformation={ordererInformation}
        handleChangeOrdererInformation={handleChangeOrdererInformation}
      />
    </Container>
  );
};

export default OrdererInformation;

OrdererInformation.propTypes = {
  isPresent: PropTypes.bool,
  goodList: PropTypes.array,
  ordererInformation: PropTypes.object,
  handleChangeOrdererInformation: PropTypes.func,
};

const Container = styled(FlexBox)`
  padding: 0 20px;
  width: 100%;
`;
const HeaderTitleTextBox = styled(TextBox)`
  font-size: 16px;
  font-weight: 700;
`;
const HeaderOrdererInfoTextBox = styled(TextBox)``;
const BodyContainer = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const OrdererInfoText = styled(TextBox)`
  font-size: 13px;
  font-weight: 350;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  width: 100%;
  display: inline-block;
  ${textEllipsis};
`;
