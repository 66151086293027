import { getRequest, postRequest } from '@mo-apis/axios';

const oldPrefix = '/api/v1/display/';
const prefix = '/api/v1/exhibition';

const getSampleToken = () =>
  getRequest({
    url: `/api/v1/sample/get-tokens`,
    data: {},
  });

const getCategoryList = async () => {
  return await getRequest({
    url: `${oldPrefix}category-list`,
    params: {},
  });
};

const getRecommendationTagList = async () => {
  return await getRequest({
    url: `${oldPrefix}recommendation-tag-list`,
    params: {},
  });
};

const getEtcExhibitionList = async () => {
  return await getRequest({
    url: `${oldPrefix}etc-exhibition-list`,
    params: {},
  });
};

const getExhibitionDetail = async (exhibitionNo) => {
  return await getRequest({
    url: `${oldPrefix}exhibition/${exhibitionNo}`,
    params: {},
  });
};

const getExhibitionGoodsGroupList = async (exhibitionNo) => {
  return await getRequest({
    url: `${oldPrefix}exhibition-goods-group-list/${exhibitionNo}`,
    params: {},
  });
};

const getRecommendHashtagList = async (exhibitionNo) => {
  return await getRequest({
    url: `${oldPrefix}exhibition-recommend-hashtag-list/${exhibitionNo}`,
    params: {},
  });
};

const getSimilarExhibitionList = async (exhibitionNo) => {
  return await getRequest({
    url: `${oldPrefix}similar-exhibition-list`,
    params: { exhibitionNo },
  });
};

const getGoodsCategoryList = async (exhibitionNo) => {
  return await getRequest({
    url: `${oldPrefix}goods-category-list/${exhibitionNo}`,
    params: {},
  });
};

const getSingleExhibitionList = async (integrationExhibitionNo) => {
  return await getRequest({
    url: `${oldPrefix}single-exhibition-list`,
    params: { integrationExhibitionNo },
  });
};

const getTopExhibitionList = async () => {
  return await getRequest({
    url: `${oldPrefix}top-exhibition-list`,
    params: {},
  });
};
const getExhibitionCategoryList = async () => {
  return await getRequest({
    url: `${oldPrefix}exhibition-category-list`,
    params: { isMobile: 'Y' },
  });
};

const getExhibitionRecommendHashTagList = async (categoryNumber) => {
  const params = {};
  if (categoryNumber) params.categoryNumber = categoryNumber;
  return await getRequest({
    url: `${oldPrefix}exhibition-recommended-hashtag-list`,
    params: params,
  });
};

const getExhibitionRecommendHashTagByCategoryList = async () => {
  return await getRequest({
    url: `${oldPrefix}exhibition-recommended-hashtag-by-category-list`,
    params: {},
  });
};

const getAllExhibitionList = async ({ categoryNumber, hashTagName }) => {
  const params = {};
  if (categoryNumber) params.categoryNumber = categoryNumber;
  if (hashTagName && typeof hashTagName === 'object' && Array.isArray(hashTagName) && hashTagName.length > 0)
    params.hashTagName = hashTagName;
  return await postRequest({
    url: `${oldPrefix}all-exhibition-list`,
    data: params,
  });
};

/**
 * MO 신규 개발/개선 API
 */
const getExhibitionList = async () => {
  return await getRequest({
    url: `${prefix}`,
  });
};

const getGoodRelationExhibitionList = (goodNumber) =>
  getRequest(
    {
      url: `/api/v1/exhibition/good-related/${goodNumber}`,
    },
    { skipSpinner: true }
  );

const getExhibition = async (exhibitionNumber) => {
  return await getRequest({
    url: `${prefix}/${exhibitionNumber}`,
    params: {},
  });
};

const getIntegrationExhibitionItemList = async (exhibitionNumber) => {
  return await getRequest({
    url: `${prefix}/${exhibitionNumber}/included-item-list`,
    params: {},
  });
};

const getDisplayCategoryRelatedExhibitionList = (displayCategoryNumber) =>
  getRequest({
    url: `/api/v1/exhibition/display-category/${displayCategoryNumber}`,
  });

const getMainExhibitionList = async () => await getRequest({ url: `${prefix}/main-exhibition-list` });

const getMainExhibitionGoodList = async (exhibitionNumber) =>
  await getRequest({ url: `${prefix}/main-exhibition-list/${exhibitionNumber}` }, { skipSpinner: true });

const getNewestExhibition = async () => await getRequest({ url: `${prefix}/newest-exhibition` });

const exhibitionApi = {
  getSampleToken,
  getCategoryList,
  getRecommendationTagList,
  getEtcExhibitionList,
  getExhibitionDetail,
  getExhibitionGoodsGroupList,
  getRecommendHashtagList,
  getSimilarExhibitionList,
  getGoodsCategoryList,
  getSingleExhibitionList,
  getTopExhibitionList,
  getExhibitionCategoryList,
  getExhibitionRecommendHashTagList,
  getExhibitionRecommendHashTagByCategoryList,
  getAllExhibitionList,
  getExhibitionList,
  getGoodRelationExhibitionList,
  getExhibition,
  getIntegrationExhibitionItemList,
  getDisplayCategoryRelatedExhibitionList,
  getMainExhibitionList,
  getMainExhibitionGoodList,
  getNewestExhibition,
};

export default exhibitionApi;
