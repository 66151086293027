import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import moTheme from '@ecp/common/src/style/theme/mo';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { StyledChipATag, StyledChipClose } from '@ecp/common/src/components/chip/Chip';
import useChip from '@ecp/common/src/components/chip/useChip';

export const StyledMoChip = styled(StyledChipATag)`
  font-size: ${moTheme.font.size.$12};
`;

export const StyledMoChipClose = styled(StyledChipClose)`
  width: 10px;
  height: 10px;
`;

const Chip = ({
  closeIcon: CloseIcon,
  closable,
  children,
  height,
  width,
  type,
  onClick,
  onClose,
  ariaLabel,
  ...props
}) => {
  const { chipProps, handleClick, handleClose } = useChip({ type, onClick, onClose, ...props });
  return (
    <StyledMoChip
      href={'#'}
      height={height}
      width={width}
      title={props.checked ? '선택됨' : ''}
      {...props}
      {...chipProps}
      onClick={handleClick}
    >
      {children}
      {closable && (
        <>
          <Spacing left={4} />
          <button type="button" aria-label={ariaLabel ? ariaLabel : '닫기'}>
            <CloseIcon {...props} onClick={handleClose} />
          </button>
        </>
      )}
    </StyledMoChip>
  );
};

Chip.propTypes = {
  type: PropTypes.oneOf(['default', 'rescan', 'category', 'brand', 'price', 'hashtag', 'brandSelect', 'title']),
  hoverIcon: PropTypes.elementType,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  checked: PropTypes.bool,
  closable: PropTypes.bool,
  ...sizePropTypes,
};

Chip.defaultProps = {
  type: 'default',
  closeIcon: StyledMoChipClose,
  onClose: () => {},
  closable: true,
};

export default Chip;
