import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useAccordionContext } from '@ecp/common/src/components/unstyled/accordion/useAccordionContext';
import ButtonUnstyled from '@ecp/common/src/components/unstyled/button/ButtonUnstyled';
import { ReactComponent as IconOpen } from '@ecp/common/src/assets/icon/icon__arrow--below.svg';

const openToggleStyle = css`
  transform: scaleY(-1);
`;

const StyledOpenButton = styled(ButtonUnstyled)`
  background: transparent;
  border: none;
  line-height: 0;

  & svg {
    ${({ open }) => open && openToggleStyle}
  }
`;

const AccordionControlUnstyled = (props) => {
  const { children, rootComponent: AccordionControl, buttonElement, buttonless, noFold, ...otherProps } = props;
  const { open, handleOpenClick } = useAccordionContext();

  const handleAccordionOpenClick = () => {
    if (noFold) return;
    handleOpenClick();
  };

  return (
    <AccordionControl
      onClick={buttonless ? handleAccordionOpenClick : () => {}}
      style={buttonless && !noFold ? { cursor: 'pointer' } : {}}
      {...otherProps}
      title={open ? (props?.headerTitle ? props?.headerTitle+', 접기' : '접기') : (props?.headerTitle ? props?.headerTitle+', 펼쳐보기' : '펼쳐보기')} 
    >
      {children}
      {!buttonless && (
        <StyledOpenButton 
          onClick={handleAccordionOpenClick}
          open={open}
          title={open ? (props?.headerTitle ? props?.headerTitle+', 접기' : '접기') : (props?.headerTitle ? props?.headerTitle+', 펼쳐보기' : '펼쳐보기')} 
          ariaLabel={open ? (props?.buttonAriaLabel && props?.buttonAriaLabel + ' 접기') : (props?.buttonAriaLabel && props?.buttonAriaLabel + ' 펼쳐보기')}
        >
          {buttonElement ? buttonElement : <IconOpen />}
        </StyledOpenButton>
      )}
    </AccordionControl>
  );
};

export default AccordionControlUnstyled;

export const AccordionControlPropTypes = {
  rootComponent: PropTypes.elementType,
  buttonElement: PropTypes.node,
  buttonless: PropTypes.bool,
  noFold: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  headerTitle: PropTypes.string,
  buttonAriaLabel: PropTypes.string,
};

AccordionControlUnstyled.propTypes = AccordionControlPropTypes;

export const AccordionControlDefaultProps = {
  buttonless: false,
  noFold: false,
  buttonAriaLabel: ''
};

AccordionControlUnstyled.defaultProps = AccordionControlDefaultProps;
