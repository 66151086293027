import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getPhoneNumbers } from '@ecp/common/src/utils/textUtil';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import FullPagePopup from '@ecp/common/src/components/modal/mo/popup/FullPagePopup';
import Input from '@ecp/common/src/components/input/mo/Input';
import {
  CUSTOM_DELIVERY_MESSAGE_WITH_VALUE,
  DEFAULT_DELIVERY_MESSAGE,
  DELIVERY_MESSAGE_WITH_CUSTOM_VALUE,
} from '@ecp/common/src/const/deliveryMessage';

import useValidate from '@mo-hooks/common/useValidate';
import { useAlertDialog } from '@mo-hooks/common/useModal';
import useMyDeliveryPlaceList from '@mo-hooks/order/useMyDeliveryPlaceList';
import FormField from '@mo-components/common/FormField';
import { formValidators, useDeliveryLocation } from '@mo-pages/myPage/deliveryLocation/useDeliveryLocation';

const EMPTY_OPTION = { key: '', value: '', label: '' };

const initForm = {
  memberDeliverySequence: 0,
  deliveryLocationName: '',
  receiverName: '',
  cellphoneNumber: '',
  telephoneNumber: '',
  entranceNumber: '',
  deliveryMessage: EMPTY_OPTION,
  newPostAddress: false,
  base: false,
  addNew: false,
};

const initError = {
  deliveryLocationName: '',
  receiverName: '',
  cellphoneNumber: '',
  entranceNumber: '',
  baseAddress: '',
  detailAddress: '',
  postNumber: '',
};

const MyPageDeliveryLocationAddPopup = ({ open, onClose, refreshData, locationCnt, editLocation, isNew }) => {
  const { showAlertDialog } = useAlertDialog();
  const { formValidate } = useValidate();

  const [form, setForm] = useState({ ...initForm });
  const [error, setError] = useState({ ...initError });
  const [customMessage, setCustomMessage] = useState('');
  const [edit, setEdit] = useState(false);
  const { createDeliveryPlace, updateDeliveryPlace } = useMyDeliveryPlaceList();

  useEffect(() => {
    if (editLocation?.memberDeliverySequence) {
      setEdit(true);
      if (!DEFAULT_DELIVERY_MESSAGE.some(({ value }) => value === editLocation.deliveryMessage))
        setCustomMessage(editLocation.deliveryMessage);

      setForm({
        ...initForm,
        ...editLocation,
        cellphoneNumber: `${editLocation.cellphoneNumber1 || ''}${editLocation.cellphoneNumber2 || ''}${
          editLocation.cellphoneNumber3 || ''
        }`,
        telephoneNumber: `${editLocation.telephoneNumber1 || ''}${editLocation.telephoneNumber2 || ''}${
          editLocation.telephoneNumber3 || ''
        }`,
        deliveryMessage: editLocation.deliveryMessage
          ? DELIVERY_MESSAGE_WITH_CUSTOM_VALUE.find(({ value }) => value && value === editLocation.deliveryMessage) ||
            CUSTOM_DELIVERY_MESSAGE_WITH_VALUE
          : EMPTY_OPTION,
      });
    }
  }, [editLocation]);

  const { fields, customMessageSelected } = useDeliveryLocation({
    form,
    setForm,
    error,
    setCustomMessage,
    locationCnt,
    editingBase: editLocation?.base,
    isFromNew: isNew,
  });

  const handleClose = () => {
    setEdit(false);
    setError({ ...initError });
    setCustomMessage('');
    setForm({ ...initForm });
    onClose();
  };

  const handleConfirmClick = async () => {
    const valid = await formValidate('all', form, setError, formValidators);
    if (valid) {
      const [cellphoneNumber1, cellphoneNumber2, cellphoneNumber3] = getPhoneNumbers(form.cellphoneNumber);
      const [telephoneNumber1, telephoneNumber2, telephoneNumber3] = getPhoneNumbers(form.telephoneNumber);
      const postData = {
        ...form,
        cellphoneNumber1: cellphoneNumber1 ?? '',
        cellphoneNumber2: cellphoneNumber2 ?? '',
        cellphoneNumber3: cellphoneNumber3 ?? '',
        telephoneNumber1: telephoneNumber1 ?? '',
        telephoneNumber2: telephoneNumber2 ?? '',
        telephoneNumber3: telephoneNumber3 ?? '',
        deliveryMessage: form.deliveryMessage?.key === 'custom' ? customMessage : form.deliveryMessage?.value,
      };
      if (!isNew || (isNew && form.addNew)) {
        if (edit) {
          await updateDeliveryPlace({ ...postData, memberDeliverySequence: editLocation.memberDeliverySequence });
        } else {
          await createDeliveryPlace(postData);
        }
        showAlertDialog(`배송지가 ${edit ? '수정' : '등록'}되었습니다.`, handleClose);
      }
      refreshData(postData);
    }
  };

  const headerText = isNew ? '배송지 입력' : edit ? '배송지 수정' : '배송지 등록';
  const confirmButtonText = isNew ? '입력' : edit ? '수정' : '등록';

  return (
    <FullPagePopup
      open={open}
      onClose={handleClose}
      header={headerText}
      onCancelClick={handleClose}
      onConfirmClick={handleConfirmClick}
      confirmButtonText={confirmButtonText}
    >
      <FlexBoxColumn width={'100%'}>
        <FlexBox justify-content={'flex-end'} width={'100%'}>
          <TextBox font-size={'12px'} required asteriskPosition={'left'}>
            필수입력 항목
          </TextBox>
        </FlexBox>
        <Spacing top={'10px'} />
        <HorizontalDivider width="100%" color={'#141415'} />
        {fields.map((field) => (
          <FormField key={field.key} field={field} size={'large'} />
        ))}
        {customMessageSelected && (
          <>
            <Spacing top={10} />
            <Input
              width={'100%'}
              maxLength={20}
              height={'46px'}
              value={customMessage}
              placeholder={'요청사항을 입력해 주세요(최대20자)'}
              onChange={({ target }) => setCustomMessage(target?.value)}
              data-key={'deliveryMessage'}
            />
          </>
        )}
      </FlexBoxColumn>
    </FullPagePopup>
  );
};

export default MyPageDeliveryLocationAddPopup;

MyPageDeliveryLocationAddPopup.propTypes = {
  editLocation: PropTypes.object,
  open: PropTypes.bool,
  isNew: PropTypes.bool,
  onClose: PropTypes.func,
  refreshData: PropTypes.func,
  locationCnt: PropTypes.number,
};
