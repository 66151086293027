import { selector, selectorFamily } from 'recoil';

import { categoryListState } from '@mo-recoil/category/atom';

export const withCanDisplayCategoryList = selector({
  key: 'withCanDisplayCategoryList',
  get: ({ get }) => {
    const categories = get(categoryListState);

    return categories.filter(({ display }) => !!display);
  },
});

export const withCategoryList = selector({
  key: 'withCategoryList',
  get: ({ get }) => {
    const categories = get(withCanDisplayCategoryList);
    return categories.reduce((categoryList, category) => {
      const temp = { ...category };
      if (!temp.children) {
        temp.children = [];
      }
      if (category.parentId) {
        const parent = categoryList.find(({ id }) => id === category.parentId);
        if (parent) {
          if (!parent.children) {
            parent.children = [];
          }
          parent.children.push(temp);
        }
      }
      categoryList.push(temp);

      return categoryList;
    }, []);
  },
});

export const withLargeCategory = selector({
  key: 'withLargeCategory',
  get: ({ get }) => {
    const categories = get(withCanDisplayCategoryList);

    return categories.filter(({ upperCategoryNumber }) => !upperCategoryNumber);
  },
});

export const withFamilySaleCategory = selector({
  key: 'withFamilySaleCategory',
  get: ({ get }) => {
    const categories = get(categoryListState);

    return categories
      .filter(({ depthNumber }) => depthNumber === 1)
      .find(({ familyDiscountTarget }) => !!familyDiscountTarget);
  },
});

export const withCanDisplayCategoryTreeList = selector({
  key: 'withCanDisplayCategoryTreeList',
  get: ({ get }) => {
    const canDisplayCategories = get(withCanDisplayCategoryList);

    return makeTree(canDisplayCategories);
  },
});

export const withMainCategoryTreeList = selector({
  key: 'withMainCategoryTreeList',
  get: ({ get }) => {
    const categories = get(withCanDisplayCategoryTreeList);

    return categories.slice(0, 18);
  },
});

const makeTree = (list, id = undefined, idNm = 'categoryNumber', parentIdNm = 'upperCategoryNumber') => {
  return list
    .filter((item) => (id ? item[parentIdNm] === id : item.depthNumber === 1))
    .map((item) => ({ ...item, subList: makeTree(list, item[idNm]) }));
};

export const withCategorySubList = selectorFamily({
  key: 'withCategorySubList',
  get:
    (categoryNumber) =>
    ({ get }) => {
      const categories = get(withCanDisplayCategoryList);

      const categorySubList = categories.filter((row) => row.upperCategoryNumber === categoryNumber);

      return categoryNumber ? categorySubList : categorySubList.slice(0, 18);
    },
});

export const withSameParentCategoryList = selectorFamily({
  key: 'withSameParentCategoryList',
  get:
    (categoryId) =>
    ({ get }) => {
      const categories = get(categoryListState);
      const current = get(withCategoryById(categoryId));

      return categories.filter((row) => row.upperCategoryNumber === current?.upperCategoryNumber);
    },
});

export const withCategoryById = selectorFamily({
  key: 'withCategoryById',
  get:
    (categoryId) =>
    ({ get }) => {
      const categories = get(categoryListState);

      return categories.find(({ categoryNumber }) => categoryNumber === categoryId);
    },
});

export const withCanDisplayCategoryById = selectorFamily({
  key: 'withCanDisplayCategoryById',
  get:
    (categoryId) =>
    ({ get }) => {
      const breadcrumb = get(withCanDisplayBreadcrumb(categoryId));

      return breadcrumb?.[breadcrumb.length - 1];
    },
});

export const withCategoryBreadcrumb = selectorFamily({
  key: 'withCategoryBreadcrumb',
  get:
    (categoryId) =>
    ({ get }) => {
      const categories = get(categoryListState);
      return makeFullBreadcrumb(categories, categoryId);
    },
});

export const withCanDisplayBreadcrumb = selectorFamily({
  key: 'withCanDisplayBreadcrumb',
  get:
    (categoryId) =>
    ({ get }) => {
      const categoryBreadcrumb = get(withCategoryBreadcrumb(categoryId));
      const canDisplayBreadcrumb = [...categoryBreadcrumb];
      const nonDisplayIndex = categoryBreadcrumb.findIndex(({ display }) => !display);
      if (nonDisplayIndex >= 0) {
        canDisplayBreadcrumb.splice(nonDisplayIndex);
      }

      return canDisplayBreadcrumb;
    },
});

const makeFullBreadcrumb = (list = [], categoryNumber, result = []) => {
  const curr = list.find((el) => el.categoryNumber === categoryNumber);
  if (curr) {
    const currCategory = { ...curr };
    result.unshift(currCategory);
    if (currCategory.categoryNumber !== currCategory.upperCategoryNumber) {
      currCategory['list'] = list.filter(
        (row) =>
          row.upperCategoryNumber === currCategory.upperCategoryNumber && row.categoryNumber !== row.upperCategoryNumber
      );
      return makeFullBreadcrumb(list, currCategory.upperCategoryNumber, result);
    }
  }
  return result;
};
