import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { usePromiseTracker } from 'react-promise-tracker';

import {
  saveMarketingPopupSetting,
  getSessionStorageItem,
  saveSessionStorageItem,
  clearAccessTokens,
  getAccessToken,
  getRefreshToken,
} from '@ecp/common/src/utils/utils';
import useRouterScrollTop from '@ecp/common/src/hooks/useRouterScrollTop';
import { MEMBER_BIZ_MSG } from '@ecp/common/src/const/bizErrMessages/member';

import { getBase64 } from '@mo-utils/commonUtils';
import { historyState } from '@mo-recoil/common/atom';
import { withMobileStatus } from '@mo-recoil/system/selector';
import { useAlertDialog } from '@mo-hooks/common/useModal';
import { useMobileStatus } from '@mo-hooks/system/useMobileStatus';
import useMobileBridge from '@mo-hooks/system/useMobileBridge';
import mobileMemberApi from '@mo-apis/member/mobileMemberApi';
import mobileVersionApi from '@mo-apis/system/mobileVersionApi';
import DialogProvider from '@mo-components/common/provider/DialogProvider';
import MobileAppUpdatePopup from '@mo-pages/mobileApp/MobileAppUpdatePopup';
import ServerErrorPage from '@mo-pages/error/ServerErrorPage';
import ChangeOldPasswordPopup from '@mo-pages/member/login/popup/ChangeOldPasswordPopup';
import AppStoreLinkBanner from '../components/banners/AppStoreLinkBanner';
import { LOGIN_TYPE } from '@mo-const/member/memberConst';
import { ANDROID, iOS, MOBILE_APP_STATUS } from '@ecp/mo/src/const/system/mobileConst';
import authApi from '@ecp/mo/src/apis/common/authApi';

export const AppUpdateLoader = async () => {
  let { result: appResult } = await mobileVersionApi.getAppVersion();
  let { result: expiredResult } = await authApi.getPasswordExpiredState();
  return { appUpdate: appResult, pwdExpired: expiredResult };
};

const RootRoute = () => {
  useMobileBridge();
  const { showAlertDialog } = useAlertDialog();
  const { appUpdate, pwdExpired } = useLoaderData();
  useMobileStatus(appUpdate);
  const [popupOpen, setPopupOpen] = useState(false);
  const [changeOldPasswordPopupOpen, setChangeOldPasswordPopupOpen] = useState(false);
  const nextUpdate = getSessionStorageItem('nextUpdate') || false;
  const oldPwdCancel = getSessionStorageItem('oldPwdCancel') || false;
  const openApp = getSessionStorageItem('openApp') || false;
  const setHistory = useSetRecoilState(historyState);
  const { pathname, search } = useLocation();

  const { promiseInProgress } = usePromiseTracker();

  const navigate = useNavigate();
  const { isMobileApp, agentType } = useRecoilValue(withMobileStatus);

  const updateAppLoginInfo = useCallback(async () => {
    if (
      !openApp &&
      (navigator.userAgent.toLowerCase().includes('ecp/android_webview') ||
        navigator.userAgent.toLowerCase().includes('ecp/ios_webview'))
    ) {
      saveSessionStorageItem('openApp', true);
      return mobileMemberApi.updateAppLoginInfo();
    }
  }, []);

  useRouterScrollTop(promiseInProgress);

  useEffect(() => {
    setHistory((state) => ({ prev: state?.current, current: pathname + search }));
  }, [pathname, search, setHistory]);

  useEffect(() => {
    if (
      appUpdate?.versionUpdateType === MOBILE_APP_STATUS.MAJOR_UPDATE ||
      appUpdate?.versionUpdateType === MOBILE_APP_STATUS.MINOR_UPDATE
    ) {
      setPopupOpen(true);
    }
  }, [appUpdate]);

  useEffect(() => {
    if (pwdExpired?.passwordExpired && !oldPwdCancel) {
      setChangeOldPasswordPopupOpen(true);
    }
  }, [oldPwdCancel, pwdExpired]);

  useEffect(() => {
    (async () => {
      if (pwdExpired?.autoLogin && isMobileApp) {
        if (agentType === ANDROID) {
          window?.Welstorymall?.getEncodedMemberNumber(getBase64(pwdExpired.memberNumber));
        } else if (agentType === iOS) {
          window?.webkit?.messageHandlers?.getEncodedMemberNumber?.postMessage(getBase64(pwdExpired.memberNumber));
        }
      }
    })();
  }, [agentType, isMobileApp, pwdExpired?.autoLogin, pwdExpired?.memberNumber]);

  useEffect(() => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    if (accessToken && refreshToken) {
      updateAppLoginInfo().then((resp) => {
        if (resp?.result === 1) {
          console.error('Fail to save app login info.');
        }
      });
    }
  }, []);

  const handleCancel = useCallback(() => {
    setPopupOpen(false);
    saveSessionStorageItem('nextUpdate', true);
  }, []);

  const handleShutdown = useCallback(() => {
    if (appUpdate?.agentType === ANDROID) {
      window.open('welstorymall://terminate');
    } else {
      window.location.href = 'welstorymall://terminate';
    }
  }, [appUpdate?.agentType]);

  const handleOldPasswordCancel = useCallback(() => {
    setChangeOldPasswordPopupOpen(false);
    saveSessionStorageItem('oldPwdCancel', true);
    saveMarketingPopupSetting();
  }, []);

  const handleChangePasswordLaterClicked = async () => {
    await authApi.changePasswordLaterWithUserCache();
    handleOldPasswordCancel();
  };

  const handleChangePasswordNowClicked = async (userId, password, newPassword) => {
    const { code } = await authApi.changeExpiredToNewPassword({
      userId,
      password,
      newPassword,
    });
    if (code) showAlertDialog(MEMBER_BIZ_MSG[code].MO);
    else {
      showAlertDialog('비밀번호가 정상적으로\n변경되었습니다.\n로그인 화면으로 이동합니다.', () => {
        handleOldPasswordCancel();
        clearAccessTokens();
        navigate('/login', { replace: true });
      });
    }
  };

  return (
    <>
      {appUpdate?.versionUpdateType === MOBILE_APP_STATUS.MAJOR_UPDATE ? (
        <MobileAppUpdatePopup open={popupOpen} onClose={handleShutdown} />
      ) : appUpdate?.versionUpdateType === MOBILE_APP_STATUS.MINOR_UPDATE && nextUpdate === false ? (
        <MobileAppUpdatePopup open={popupOpen} onClose={handleCancel} />
      ) : appUpdate?.versionUpdateType === MOBILE_APP_STATUS.UNKNOWN ? (
        <ServerErrorPage />
      ) : pwdExpired?.passwordExpired && oldPwdCancel === false ? (
        <ChangeOldPasswordPopup
          open={changeOldPasswordPopupOpen}
          onClose={handleOldPasswordCancel}
          loginType={LOGIN_TYPE.ID}
          loginId={pwdExpired?.userId}
          onChangeLaterClicked={handleChangePasswordLaterClicked}
          onChangeNowClicked={handleChangePasswordNowClicked}
        />
      ) : (
        <>
          <AppStoreLinkBanner />
          <Outlet />
        </>
      )}
      <DialogProvider />
    </>
  );
};

export default RootRoute;
