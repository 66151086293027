import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RemoveScroll } from 'react-remove-scroll';

import { FloatingOverlay } from '@floating-ui/react';

import { zIndex } from '@ecp/common/src/style/constant';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxAlignCenter, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import PopupUnstyled from '@ecp/common/src/components/unstyled/floating/PopupUnstyled';
import { ReactComponent as TooltipCloseIcon } from '@ecp/common/src/assets/icon/tooltip__close.svg';
import { ReactComponent as IconBelow } from '@ecp/common/src/assets/icon/icon__arrow_down.svg';

const BottomSheets = (props) => {
  const {
    open,
    onClose,
    onChange,
    header,
    headerType,
    headerSubType,
    headerProps,
    closable,
    closeButtonType,
    BottomSheetsButtons,
    BottomSheetsRightButtons,
    ariaLabel,
    ...rest
  } = props;

  const HeaderComponent = () => {
    return headerType === 'popup' ? (
      headerSubType === 'default' ? (
        <DefaultHeaderComponent>{header}</DefaultHeaderComponent>
      ) : (
        <StyledHeaderComponent>
          <TextBox {...headerProps}>{header}</TextBox>
        </StyledHeaderComponent>
      )
    ) : headerType === 'bottom' ? (
     ''
    ) : (
      <StyledArrowComponent arrowCenter={rest.arrowCenter}>
        <button type="button" aria-label={ariaLabel}>
          <IconBelow width={'18px'} height={'11px'} color={theme.color.secondary} onClick={onClose} />
        </button>
      </StyledArrowComponent>
    );
  };

  return (
    <PopupUnstyled
      open={open}
      onClose={onClose}
      onChange={onChange}
      rootComponent={StyledBottomSheetsRoot}
      headerComponent={header && header.propTypes === PropTypes.element ? header : HeaderComponent}
      bodyProps={{ header, headerType, headerSubType }}
      bodyComponent={StyledBodyComponent}
      closable={closable}
      closeComponent={
        closeButtonType === 'Primary' ? (
          <DefaultCloseComponent />
        ) : (
          <StyledButton title="닫기">
            <StyledCloseComponent />
          </StyledButton>
        )
      }
      bottomButtonComponent={
        BottomSheetsButtons === 'Primary' ? (
          <StyledBottomButton title="닫기">
          <StyledBottomCloseComponent>{'닫기'}</StyledBottomCloseComponent>
        </StyledBottomButton> ) : ''
      }
      bottomRightButtonComponent={
        BottomSheetsRightButtons === 'Primary' ? (
          <StyledBottomRightButton title="오늘 하루 그만보기">
          <StyledBottomRightComponent>{'오늘 하루 그만보기'}</StyledBottomRightComponent>
        </StyledBottomRightButton> ) : ''
      }
      {...rest}
    />
  );
};

export default BottomSheets;

BottomSheets.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  header: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node, PropTypes.string]),
  headerType: PropTypes.oneOf(['popup', 'accordion', 'bottom']),
  headerSubType: PropTypes.oneOf(['default', 'styled']),
  headerProps: PropTypes.object,
  closable: PropTypes.bool,
  closeButtonType: PropTypes.oneOf(['Primary', 'Secondary']),
  BottomSheetsButtons: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  BottomSheetsRightButtons: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node]),
  ariaLabel: PropTypes.string,
};

BottomSheets.defaultProps = {
  headerType: 'popup',
  headerSubType: 'styled',
  headerProps: {
    size: moTheme.font.size.$14,
    weight: moTheme.font.weight.medium,
  },
  closable: true,
  closeButtonType: 'Primary',
  ariaLabel: '닫기',
};

const StyledBottomSheetsRoot = React.forwardRef(({ children, ...props }, ref) => {
  const rootRef = useRef(null);

  useImperativeHandle(ref, () => rootRef.current);

  return (
    <RemoveScroll>
      <FloatingOverlay
        style={{
          zIndex: zIndex.bottomSheet,
          display: 'grid',
          background: 'rgba(0, 0, 0, 0.65)',
          placeItems: 'center',
        }}
      >
        <BottomSheetsWrapper ref={rootRef} {...props}>
          {children}
        </BottomSheetsWrapper>
      </FloatingOverlay>
    </RemoveScroll>
  );
});

StyledBottomSheetsRoot.displayName = 'StyledBottomSheetsRoot';

StyledBottomSheetsRoot.propTypes = {
  children: PropTypes.node,
};

const BottomSheetsWrapper = styled(FlexBoxColumn)`
  align-items: flex-start;
  position: absolute;
  width: 360px;
  height: max-content;
  background-color: ${theme.color.background.white};
  border-radius: 12px 12px 0 0;
  bottom: 0;

  &:focus-visible {
    outline: none;
  }
`;

const StyledBodyComponent = styled(FlexBox).attrs(({ headerType, headerSubType, style }) => ({
  style: {
    ...style,
    margin: headerType === 'accordion' ? '0' : headerSubType === 'styled' ? '20px 0 30px 0' : '0 0 40px 0',
  },
}))`
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  width: 360px;
`;

const DefaultCloseComponent = styled(TooltipCloseIcon)`
  border: none;
  width: 14px;
  height: 14px;
  background-color: ${theme.color.background.white};
  fill: ${theme.color.icon.default};
  position: absolute;
  top: 18px;
  right: 20px;
  cursor: pointer;
`;

const DefaultBottomCloseComponent = styled.text`
  font-weight:bold;
  border: none;
  width: 14px;
  height: 14px;
  background-color: ${theme.color.background.white};
  fill: ${theme.color.icon.default};  
  cursor: pointer;
`;

const StyledButton = styled.button`
  position: absolute;
  top: 0px;
  right: 40px;
  cursor: pointer;
`;

const StyledBottomButton = styled.button`
  position: absolute;
  top: 260px;
  right: 20px;
`;

const StyledBottomRightButton = styled.button`
  position: absolute;
  top: 260px;
  left: 20px;
`;

const StyledCloseComponent = styled(DefaultCloseComponent)`
  top: 24px;
  width: 18px;
  height: 18px;
`;

const StyledBottomCloseComponent = styled(DefaultBottomCloseComponent)`
  top: 260px;
  width: 18px;
  height: 18px;
`;

const StyledBottomRightComponent = styled(DefaultBottomCloseComponent)`
  top: 260px;
  width: 18px;
  height: 18px;
`;

const DefaultHeaderComponent = styled(FlexBoxAlignCenter)`
  height: 62px;
  margin: 0 20px;
  box-sizing: border-box;
`;

const StyledHeaderComponent = styled(DefaultHeaderComponent)`
  width: calc(100% - 40px);
  height: 50px;
  border-bottom: solid 1px ${theme.color.line.basic};
`;

const StyledArrowComponent = styled(FlexBox)`
  height: 11px;
  margin: 14px;
  width: calc(100% - 20px);
  justify-content: ${({ arrowCenter }) => (arrowCenter ? 'center' : 'flex-start')};
`;
