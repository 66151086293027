import React, { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import Drawer from '@ecp/common/src/components/modal/mo/Drawer';

import { employeeBenefitOpenState, showNavigationMenuState } from '@mo-recoil/common/navigation/atom';
import NavigationMenu from '@mo-components/common/navigation/NavigationMenu';
import SideFloatingNavigation from '@mo-components/common/navigation/SideFloatingNavigation';
import EmployeeBenefit from '@mo-components/benefit/EmployeeBenefit';

const Container = styled.div`
  position: fixed;
  height: 65px;
  bottom: 0;
  z-index: 100;
  width: 360px;
`;

const Navigation = () => {
  const showNavigationMenu = useRecoilValue(showNavigationMenuState);
  const [employeeBenefitOpen, setEmployeeBenefitOpen] = useRecoilState(employeeBenefitOpenState);

  const handleClose = useCallback(() => {
    setEmployeeBenefitOpen(false);
  }, [setEmployeeBenefitOpen]);

  return (
    <Container>
      {showNavigationMenu && <NavigationMenu />}
      <SideFloatingNavigation />
      <Drawer
        placement={'right'}
        closePosition={'left'}
        open={employeeBenefitOpen}
        onClose={handleClose}
        value={<EmployeeBenefit onClose={handleClose} />}
      />
    </Container>
  );
};

export default Navigation;
