import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const usePreventPopState = () => {
  const navigate = useNavigate();

  useEffect(() => {
    history.pushState(null, '', location.href);
    const goMain = () => navigate('/');
    window.addEventListener('popstate', goMain);
    return () => window.removeEventListener('popstate', goMain);
  }, [navigate]);
};

export default usePreventPopState;
