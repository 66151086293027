import { useCallback, useEffect, useState } from 'react';

import { isValidContents, validateEmail, validatePhoneNumber } from '@ecp/common/src/utils/InputValidateUtils';
import { getPhoneNumbers } from '@ecp/common/src/utils/textUtil';

const initialStateValue = {
  ordererName: '',
  ordererCellphoneNumber: '',
  ordererEmail: '',
};

const useOrdererChangePopup = ({ ordererInformation, open, handleChangeOrdererInformation, onClose }) => {
  const [formValue, setFormValue] = useState({
    ...initialStateValue,
  });
  const [error, setError] = useState({
    ...initialStateValue,
  });

  useEffect(() => {
    if (open) {
      setFormValue({
        ...ordererInformation,
        ordererCellphoneNumber: ordererInformation.ordererCellphoneNumber.replace(/-/gi, ''),
      });
      setError({ ...initialStateValue });
    }
  }, [open, ordererInformation]);

  const handleInputChange = useCallback(({ target: { value, name } }) => {
    setFormValue((prev) => ({ ...prev, [name]: value.trim() }));
    setError((prev) => ({ ...prev, [name]: '' }));
  }, []);

  const validateFormList = useCallback((value) => {
    const [emailId, domain] = value.ordererEmail.split('@');
    const emailValidationMessage = validateEmail(emailId, domain);
    const phoneNumberValidationMessage = validatePhoneNumber({ phoneNumber: value.ordererCellphoneNumber });
    return [
      {
        validator: () => !!value.ordererName,
        name: 'ordererName',
        message: '주문자명을 입력해주세요.',
      },
      {
        validator: () => isValidContents(value.ordererName, 1, 10),
        name: 'ordererName',
        message: '주문자명을 정확하게 입력해주세요.',
      },
      {
        validator: () => !!value.ordererCellphoneNumber,
        name: 'ordererCellphoneNumber',
        message: '휴대전화번호를 입력해주세요.',
      },
      {
        validator: () => !(phoneNumberValidationMessage?.length > 0),
        name: 'ordererCellphoneNumber',
        message: phoneNumberValidationMessage,
      },
      {
        validator: () => !!value.ordererEmail,
        name: 'ordererEmail',
        message: '이메일을 입력해주세요.',
      },
      {
        validator: () => !(emailValidationMessage.length > 0),
        name: 'ordererEmail',
        message: emailValidationMessage,
      },
    ];
  }, []);

  const validateForm = useCallback(
    (value) => {
      return validateFormList(value).reduce((valid, { validator, name, message }) => {
        if (!validator?.()) {
          setError((prev) => ({ ...prev, [name]: message }));
          return false;
        } else {
          return valid && true;
        }
      }, true);
    },
    [validateFormList]
  );

  const handleClickConfirm = useCallback(() => {
    if (validateForm(formValue)) {
      const phoneNumbers = getPhoneNumbers(formValue.ordererCellphoneNumber);
      formValue.ordererCellphoneNumber1 = phoneNumbers[0];
      formValue.ordererCellphoneNumber2 = phoneNumbers[1];
      formValue.ordererCellphoneNumber3 = phoneNumbers[2];
      formValue.ordererCellphoneNumber = phoneNumbers.join('');

      handleChangeOrdererInformation(formValue);

      onClose();
    } else {
      console.log(error);
    }
  }, [error, formValue, handleChangeOrdererInformation, onClose, validateForm]);

  return {
    formValue,
    error,
    handleInputChange,
    handleClickConfirm,
  };
};

export default useOrdererChangePopup;
