import { useState } from 'react';

import { onCopyToClipboard } from '@ecp/common/src/utils/utils';

const onClipboard = (setCopiedText) => async (text) => await onCopyToClipboard(text, setCopiedText);

const useClipboard = () => {
  const [copiedText, setCopiedText] = useState(null);
  const onCopy = onClipboard(setCopiedText);

  return { copiedText, onCopy };
};

export default useClipboard;
