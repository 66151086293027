import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

import { mainFunctionCustomState } from '@mo-recoil/main/selector';
import AiBasedRecommendation from '@mo-components/display/recommendation/AiBasedRecommendation';

const AiRecommend = () => {
  const { categoryNumber } = useParams();
  const { mainFunctionTitle } = useRecoilValue(mainFunctionCustomState);
  const [visible, setVisible] = useState(true);
  return (
    <>
      {visible && (
        <>
          <Spacing top={50} />
          <AiRecommendWrapper>
            <AiBasedRecommendation
              title={mainFunctionTitle}
              displayCategoryNumber={categoryNumber}
              channel={'product_ctg_ai'}
              handleVisibility={setVisible}
            />
          </AiRecommendWrapper>
        </>
      )}
    </>
  );
};
export default AiRecommend;

const AiRecommendWrapper = styled(FlexBoxColumn)`
  width: 100%;
  background-color: ${theme.color.background.sub};
  padding: 40px 0;
  gap: 14px;
  position: relative;
`;
