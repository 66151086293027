import React from 'react';
import PropTypes from 'prop-types';

import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

const HeaderTitle = ({ title }) => (
  <FlexBox width={'100%'} justify-content={'flex-start'}>
    <TextBox size={moTheme.font.size.$18} weight={moTheme.font.weight.bold}>
      {title}
    </TextBox>
    <Spacing top={10} />
  </FlexBox>
);

HeaderTitle.propTypes = {
  title: PropTypes.string,
};

export default HeaderTitle;
