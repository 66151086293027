import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import {
  getBrandCategoryFilterList,
  getQueryString,
  mobileInitialPageParam,
  mobileNavigateWithFilterInfoTable,
} from '@ecp/common/src/utils/good/brandDetailSearchUtil';
import useNavigateWithFilter from '@ecp/common/src/hooks/search/useNavigateWithFilter';
import useScrollToPreviousGood from '@ecp/common/src/hooks/search/useScrollToPreviousGood';
import { FlexBoxColumn, ImageBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { BENEFIT_MESSAGE } from '@ecp/common/src/const/promotion';
import { GOOD_PRICE_FILTER_LIST } from '@ecp/common/src/const/good';

import { getPageQueryParams } from '@mo-utils/commonUtils';
import { gnbTitleState } from '@mo-recoil/common/gnb/atom';
import { employeeBenefitMessageState } from '@mo-recoil/common/navigation/atom';
import { withCanDisplayCategoryList } from '@mo-recoil/category/selector';
import { employeeBenefitBrandNameState } from '@mo-recoil/main/atom';
import { brandGoodListState, brandGoodListUrlState, brandGoodTotalCountState } from '@mo-recoil/brand/atom';
import { historyState } from '@mo-recoil/common/atom';
import displayCategoryApi from '@mo-apis/display/displayCategoryApi';
import brandApi from '@mo-apis/good/brandApi';
import couponApi from '@mo-apis/coupon/couponApi';
import GoodTableCardList from '@mo-components/good/GoodTableCardList';
import SearchFilterBar from '@mo-components/display/filter/SearchFilterBar';
import NoResultFoundList from '@mo-components/common/NoResultFoundList';
import { FILTER_TYPE } from '@mo-pages/search/result/searchOption';

const BrandDetailPage = () => {
  const { brandNumber } = useParams();
  const setGnbTitle = useSetRecoilState(gnbTitleState);
  const history = useRecoilValue(historyState);
  const [storedGoodList, setStoredGoodList] = useRecoilState(brandGoodListState);
  const [storedUrl, setStoredUrl] = useRecoilState(brandGoodListUrlState);
  const [storedTotalCount, setStoredTotalCount] = useRecoilState(brandGoodTotalCountState);

  const [brand, setBrand] = useState({
    name: '',
    imageUrl: '',
  });
  const [goodList, setGoodList] = useState(null);
  const [totalCount, setTotalCount] = useState(0);

  const [brandCategoryList, setBrandCategoryList] = useState([]);

  const {
    searchCondition,
    pageParam,
    setPageParam,
    handleRefresh,
    handlePageChange,
    handleSortChange,
    handleChangeFilter,
    handleApplyFilter,
    handleViewTypeChange,
  } = useNavigateWithFilter(mobileNavigateWithFilterInfoTable);

  const { storedGoodCardRef } = useScrollToPreviousGood({
    pathVariable: brandNumber,
    history,
    mobileInitialPageParam,
    goodList,
    storedGoodList,
    storedUrl,
    storedTotalCount,
    setGoodList,
    setTotalCount,
    setPageParam,
    setStoredGoodList,
  });

  const totalDisplayCategoryList = useRecoilValue(withCanDisplayCategoryList);

  const setEmployeeBenefitMessageState = useSetRecoilState(employeeBenefitMessageState);
  const resetEmployeeBenefitMessage = useResetRecoilState(employeeBenefitMessageState);
  const setEmployeeBenefitBrandNameState = useSetRecoilState(employeeBenefitBrandNameState);
  const resetEmployeeBenefitBrandNameState = useResetRecoilState(employeeBenefitBrandNameState);

  useEffect(() => {
    (async () => {
      const { result: couponList } = await couponApi.getBrandBenefitSummary(brandNumber);
      const { brand, count } = couponList;

      if (brand && count > 0) {
        setEmployeeBenefitMessageState(BENEFIT_MESSAGE.BRAND_DETAIL);
      }
    })();
    return () => resetEmployeeBenefitMessage();
  }, [brandNumber, resetEmployeeBenefitMessage, setEmployeeBenefitMessageState]);

  const brandCategoryFilterList = useMemo(() => {
    return getBrandCategoryFilterList(brandCategoryList, totalDisplayCategoryList);
  }, [brandCategoryList, totalDisplayCategoryList]);

  const fetchBrand = useCallback(async () => {
    const { result } = await brandApi.getBrand(brandNumber);
    const { brandName = '', mobileImageUrl = '' } = result;
    setBrand({
      name: brandName,
      imageUrl: mobileImageUrl,
    });
    setGnbTitle(brandName);
    setEmployeeBenefitBrandNameState(brandName);
  }, [brandNumber, setEmployeeBenefitBrandNameState, setGnbTitle]);

  const fetchBrandGoodList = useCallback(async () => {
    const url = `${window.location.pathname}?${getQueryString(searchCondition, pageParam)}`;
    if (url === storedUrl) {
      return;
    }

    const requestBody = {
      brandList: [brandNumber],
      minPrice: searchCondition.price.min,
      maxPrice: searchCondition.price.max,
      categoryNumber: searchCondition.categoryNumber.current,
      depthNumber: searchCondition.depthNumber,
    };
    const pageParams = getPageQueryParams(pageParam?.page, pageParam?.countPerPage, pageParam?.sort?.value);

    const {
      result: { content = [], totalElements },
    } = await displayCategoryApi.getGoodListByDisplayCategory(requestBody, pageParams);
    if (pageParam?.page === 1) {
      setGoodList(content);
      setStoredGoodList(content);
    } else {
      setGoodList((prev) => (prev ? [...prev, ...content] : content));
      setStoredGoodList((prev) => (prev ? [...prev, ...content] : content));
    }
    setStoredUrl(url);
    setTotalCount(totalElements);
    setStoredTotalCount(totalElements);
  }, [brandNumber, pageParam, searchCondition, setStoredGoodList, setStoredTotalCount, setStoredUrl, storedUrl]);

  const fetchBrandCategoryList = useCallback(async () => {
    const { result } = await brandApi.getBrandDisplayCategoryList(brandNumber);
    setBrandCategoryList(result);
  }, [brandNumber]);

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([fetchBrand(), fetchBrandCategoryList()]);
      } catch (e) {
        console.error(e);
      }
    })();
    resetEmployeeBenefitBrandNameState();
  }, [fetchBrand, fetchBrandCategoryList, resetEmployeeBenefitBrandNameState]);

  useEffect(() => {
    (async () => {
      await fetchBrandGoodList();
    })();
  }, [fetchBrandGoodList]);

  const fetchNextPage = useCallback(() => {
    if (totalCount > pageParam?.page * pageParam?.countPerPage) {
      handlePageChange(pageParam?.page + 1);
    }
  }, [handlePageChange, pageParam?.countPerPage, pageParam?.page, totalCount]);

  const cardType = useMemo(() => (pageParam.listView ? 'horizontalMedium' : 'medium'), [pageParam.listView]);

  return (
    <FlexBoxColumn width={'100%'}>
      {brand.imageUrl && <ImageBox width={'360px'} height={'320px'} imageSrc={brand.imageUrl} />}
      <SearchFilterBar
        filterOptions={[
          { key: FILTER_TYPE.CATEGORY, label: '카테고리', 'data-ds-label2': 'ctg_filter' },
          { key: FILTER_TYPE.PRICE, label: '가격', 'data-ds-label2': 'price_filter' },
        ]}
        searchCondition={searchCondition}
        priceList={GOOD_PRICE_FILTER_LIST}
        largeCategoryList={brandCategoryFilterList.largeCategoryList}
        middleCategoryList={brandCategoryFilterList.middleCategoryList}
        smallCategoryList={brandCategoryFilterList.smallCategoryList}
        detailCategoryList={brandCategoryFilterList.detailCategoryList}
        handleChangeFilter={handleChangeFilter}
        handleApplyFilter={handleApplyFilter}
        handleRefreshFilter={handleRefresh}
      />
      {goodList?.length > 0 ? (
        <Spacing top={22} left={20} right={20} width={'100%'}>
          <GoodTableCardList
            storedGoodCardRef={storedGoodCardRef}
            goodList={goodList}
            pageProps={{ totalCount, sort: pageParam?.sort }}
            cardType={cardType}
            titleType={'secondary'}
            onViewChange={handleViewTypeChange}
            onSortChange={handleSortChange}
            fetchNextPage={fetchNextPage}
          />
        </Spacing>
      ) : (
        <Spacing top={22} left={20} right={20} width={'100%'}>
          <NoResultFoundList message={'조회된 상품이 없습니다.'} />
        </Spacing>
      )}
    </FlexBoxColumn>
  );
};

export default BrandDetailPage;
