import React from 'react';
import PropTypes from 'prop-types';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { GOOD_SORT_TYPE } from '@ecp/common/src/const/good';

import GoodSort from '@mo-components/good/GoodSort';

const NewGoodHeader = ({ totalCount, sort, handleSortChange }) => {
  return (
    <FlexBoxSpaceBetween width={'100%'} align-items={'flex-end'}>
      <TextBox>
        <TextBox weight={moTheme.font.weight.bold} color={theme.color.text.selected}>
          {totalCount}
        </TextBox>
        개 상품을 찾았습니다
      </TextBox>
      <GoodSort sortLabel={sort?.label} itemList={GOOD_SORT_TYPE} handleSortChange={handleSortChange} />
    </FlexBoxSpaceBetween>
  );
};

NewGoodHeader.propTypes = {
  totalCount: PropTypes.number,
  sort: PropTypes.object,
  handleSortChange: PropTypes.func.isRequired,
};

export default NewGoodHeader;
