import React from 'react';
import PropTypes from 'prop-types';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

import PresentReceiverContent from '@mo-components/order/payment/present/PresentReceiverContent';

function PresentSingleReceiverContent({ ordererInformation, handleChangeDeliveryInfo }) {
  return (
    <FlexBoxColumn padding-top="14px" width="100%">
      <Spacing top={14} />
      <PresentReceiverContent
        ordererInformation={ordererInformation}
        handleChangeDeliveryInfo={handleChangeDeliveryInfo}
        index={0}
      />
    </FlexBoxColumn>
  );
}

PresentSingleReceiverContent.propTypes = {
  ordererInformation: PropTypes.object,
  handleChangeDeliveryInfo: PropTypes.func,
};

export default PresentSingleReceiverContent;
