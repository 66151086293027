import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextButton } from '@ecp/common/src/components/button/mo/TextButton';

import exhibitionApi from '@mo-apis/exhibition/exhibitionApi';
import RelatedExhibition from '@mo-components/display/RelatedExhibition';

const SpecialExhibition = () => {
  const navigate = useNavigate();
  const { categoryNumber } = useParams();
  const [relationExhibitionList, setRelationExhibitionList] = useState([]);
  useEffect(() => {
    if (!categoryNumber) {
      return;
    }
    (async () => {
      const { result = [] } = await exhibitionApi.getDisplayCategoryRelatedExhibitionList(categoryNumber);
      setRelationExhibitionList(result);
    })();
  }, [categoryNumber]);
  return (
    <>
      {relationExhibitionList.length > 0 && (
        <Container>
          <Spacing top={50} />
          <RelatedExhibition relationExhibitionList={relationExhibitionList} title={'관련 기획전'} />
          <Spacing top={20} />
          <HorizontalDivider />
          <Spacing top={10} />
          <TextButton type={'move'} onClick={() => navigate('/exhibition')}>
            기획전 전체 보기
          </TextButton>
          <Spacing top={50} />
        </Container>
      )}
    </>
  );
};

export default SpecialExhibition;

const Container = styled(FlexBoxColumn)`
  width: 100%;
  padding: 0 20px;
`;
