import React from 'react';
import PropTypes from 'prop-types';

import { useAccordionContext } from '@ecp/common/src/components/unstyled/accordion/useAccordionContext';

export const AccordionBodyUnstyled = (props) => {
  const { children, rootComponent: AccordionBody, undead } = props;

  const { open } = useAccordionContext();

  return (
    <>
      {(undead || open) && (
        <AccordionBody
          style={{
            display: open ? 'flex' : 'none',
          }}
        >
          {children}
        </AccordionBody>
      )}
    </>
  );
};

export default AccordionBodyUnstyled;

export const AccordionBodyPropTypes = {
  rootComponent: PropTypes.elementType,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  undead: PropTypes.bool,
};

AccordionBodyUnstyled.propTypes = AccordionBodyPropTypes;

AccordionBodyUnstyled.defaultProps = {};
