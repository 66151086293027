import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import { FlexBox, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import useSiteInfo from '@mo-hooks/common/useSiteInfo';

const OrderSummary = ({ paymentAmount, orderItemCount }) => {
  const { isPriceOpen } = useSiteInfo();

  return (
    <Container>
      <QuantityContainer>
        <QuantityDescriptionTextBox>총 </QuantityDescriptionTextBox>
        <QuantityTextBox>{orderItemCount}</QuantityTextBox>
        <QuantityDescriptionTextBox>건</QuantityDescriptionTextBox>
      </QuantityContainer>
      {isPriceOpen && (
        <PriceContainer>
          <PriceTextBox>{NumberFormat(paymentAmount)}</PriceTextBox>
          <PriceUnitTextBox>원</PriceUnitTextBox>
        </PriceContainer>
      )}
    </Container>
  );
};

export default OrderSummary;

OrderSummary.propTypes = {
  paymentAmount: PropTypes.number,
  orderItemCount: PropTypes.number,
};

const Container = styled(FlexBoxSpaceBetween)`
  padding: 15px 20px;
  width: 100%;
`;

const QuantityContainer = styled(FlexBox)``;

const QuantityDescriptionTextBox = styled(TextBox)`
  font-weight: 350;
  font-size: 14px;
`;
const QuantityTextBox = styled(TextBox)`
  font-weight: 500;
  font-size: 14px;
`;
const PriceTextBox = styled(TextBox)`
  font-weight: 700;
  font-size: 16px;
`;
const PriceUnitTextBox = styled(TextBox)`
  font-weight: 350;
  font-size: 12px;
`;

const PriceContainer = styled(FlexBox)``;
