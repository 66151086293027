import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { BENEFIT_MESSAGE } from '@ecp/common/src/const/promotion';
import { GOOD_SORT_TYPE } from '@ecp/common/src/const/good';

import { withCategoryById } from '@mo-recoil/category/selector';
import { employeeBenefitMessageState } from '@mo-recoil/common/navigation/atom';
import {
  categoryNumberState,
  categoryPageNewGoodCategoryNumberState,
  categoryPageNewGoodPagePropsState,
} from '@mo-recoil/category/atom';
import { useGnbTitle } from '@mo-hooks/common/useGnb';
import couponApi from '@mo-apis/coupon/couponApi';
import SpecialExhibition from '@mo-pages/category/main/SpecialExhibition';
import AiRecommend from '@mo-pages/category/main/AiRecommend';
import CategoryHeader from '@mo-pages/category/main/CategoryHeader';
import CategoryShortcuts from '@mo-pages/category/main/CategoryShortcuts';
import BestGood from '@mo-pages/category/main/BestGood';
import NewGoodList from '@mo-pages/newGood/NewGoodList';
import BestBrand from '@mo-pages/category/main/BestBrand';

const CategoryPage = () => {
  const { categoryNumber } = useParams();
  const categoryDetail = useRecoilValue(withCategoryById(categoryNumber));
  const hasNewGood = useMemo(() => !!categoryDetail?.hasNewGood, [categoryDetail?.hasNewGood]);
  const setCategoryNumberState = useSetRecoilState(categoryNumberState);
  const resetCategoryNumberState = useResetRecoilState(categoryNumberState);
  const setEmployeeBenefitMessageState = useSetRecoilState(employeeBenefitMessageState);
  const resetEmployeeBenefitMessage = useResetRecoilState(employeeBenefitMessageState);
  useGnbTitle(categoryDetail?.categoryName);

  const [newGoodCategoryNumber, setNewGoodCategoryNumber] = useRecoilState(categoryPageNewGoodCategoryNumberState);
  const [newGoodPageProps, setNewGoodPageProps] = useRecoilState(categoryPageNewGoodPagePropsState);

  useEffect(() => {
    (async () => {
      if (categoryNumber) {
        const { result: couponList } = await couponApi.getCategoryBenefitSummary(categoryNumber);
        const { category } = couponList || {};

        if (category) {
          setEmployeeBenefitMessageState(BENEFIT_MESSAGE.CATEGORY);
        }
      }
    })();
    return () => resetEmployeeBenefitMessage();
  }, [resetEmployeeBenefitMessage, setEmployeeBenefitMessageState, categoryNumber]);

  useEffect(() => {
    if (categoryNumber) {
      setCategoryNumberState(categoryNumber);
    }
    return () => resetCategoryNumberState();
  }, [categoryNumber, resetCategoryNumberState, setCategoryNumberState]);

  useEffect(() => {
    if (categoryNumber === newGoodCategoryNumber) {
      return;
    }

    setNewGoodCategoryNumber(categoryNumber);
    setNewGoodPageProps({ page: 1, sort: GOOD_SORT_TYPE[0] });
  }, [categoryNumber, setNewGoodCategoryNumber, setNewGoodPageProps]);

  return (
    <>
      <CategoryHeader />
      <CategoryShortcuts />
      <BestBrand />
      <HorizontalDivider />
      <BestGood />
      <AiRecommend />
      <SpecialExhibition />
      {hasNewGood && (
        <NewGoodList
          showHeaderTitle
          categoryNumber={categoryNumber}
          pageProps={newGoodPageProps}
          setPageProps={setNewGoodPageProps}
        />
      )}
    </>
  );
};

export default CategoryPage;
