// 모바일용
export const INI_PAYMENT = {
  CARD: 'CARD',
  PAYCO: 'CARD',
  SAMSUNG_PAY: 'CARD',
  LPAY: 'CARD',
  SSG_PAY: 'CARD',
  KAKAO_PAY: 'CARD',
  TOSS_PAY: 'CARD',
  NAVER_PAY: 'CARD',
  V_BANK: 'VBANK',
  REAL_TIME_BANK: 'BANK',
  HPP: 'MOBILE',
};
