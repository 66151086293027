const DEFAULT_RESERVED = 'twotrs_isp=Y&twotrs_isp_noti=N&apprun_check=Y&block_isp=Y&cp_yn=Y&below1000=Y&centerCd=Y';

export const P_RESERVED = {
  K_PAY: '&d_kpay=Y',
  SAMSUNG_PAY: DEFAULT_RESERVED + '&d_samsungpay=Y',
  SSG_PAY: DEFAULT_RESERVED + '&d_ssgpay=Y',
  KAKAO_PAY: DEFAULT_RESERVED + '&d_kakaopay=Y',
  TOSS_PAY: DEFAULT_RESERVED + '&d_tosspay=Y',
  L_PAY: DEFAULT_RESERVED + '&d_lpay=Y',
  NAVER_PAY: DEFAULT_RESERVED + '&d_npay=Y',
  SAMSUNG_WALLET: '&d_swallet=Y',
  CARD: DEFAULT_RESERVED + '&noeasypay=Y',
};
