import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

import SwiperExhibition from '@mo-components/exhibition/SwiperExhibition';

const ExhibitionSwiper = ({ exhibitionList, title }) => {
  return (
    <FlexBoxColumn width={'100%'}>
      <FlexBoxColumn width={'100%'} align-items={'flex-start'}>
        <Spacing left={20}>
          <ContentsTitle>{title}</ContentsTitle>
        </Spacing>
      </FlexBoxColumn>
      <Spacing top={14} />
      {exhibitionList?.length > 0 && <SwiperExhibition itemList={exhibitionList} />}
    </FlexBoxColumn>
  );
};

export default ExhibitionSwiper;
ExhibitionSwiper.propTypes = {
  exhibitionList: PropTypes.array.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export const ContentsTitle = styled(TextBox)`
  font-weight: ${moTheme.font.weight.bold};
  font-size: ${moTheme.font.size.$16};
  line-height: ${moTheme.font.lineHeight.demiLight};
  color: ${theme.color.text.basic};
  word-break: keep-all;
`;
