import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';

const OrderPageItemTitleContainer = ({ title, gapToBody }) => {
  return (
    <>
      <HeaderTitleTextBox>{title}</HeaderTitleTextBox>
      <Spacing top={'10px'} />
      <HorizontalDivider color={'#141415'} />
      <Spacing top={gapToBody} />
    </>
  );
};

export default OrderPageItemTitleContainer;
OrderPageItemTitleContainer.propTypes = {
  title: PropTypes.string,
  gapToBody: PropTypes.string,
};

const HeaderTitleTextBox = styled(TextBox)`
  font-size: 16px;
  font-weight: 700;
`;
