import React, { useEffect, useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import PropTypes from 'prop-types';

import { format, intervalToDuration, parse } from 'date-fns';
import { color } from '@ecp/common/src/style/theme/default';

import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import LayerPopup from '@ecp/common/src/components/modal/mo/popup/LayerPopup';
import { ReactComponent as AdulCetiImg } from '@ecp/common/src/assets/icon/icon__adult-certification.svg';

import { userInfoState } from '@mo-recoil/common/user/atom';
import { withAdultCertification } from '@mo-recoil/common/user/selector';
import { useAlertDialog } from '@mo-hooks/common/useModal';
import { useMobileMobilians } from '@mo-hooks/interfaces/useMobileMobilians';
import memberMyInfoApi from '@mo-apis/member/memberMyInfoApi';

const InformationLimitPop = ({ open, onClose, onConfirm }) => {
  const { showAlertDialog } = useAlertDialog();
  const { mobilians, resetMobilians, handleMobiliansOpen } = useMobileMobilians(320, 464);
  const isAdultVerified = useRecoilValue(withAdultCertification);
  const setMemberInfo = useSetRecoilState(userInfoState);

  const isOver19 = useCallback((birthDateStr) => {
    const { years } = intervalToDuration({ start: parse(birthDateStr, 'yyyyMMdd', new Date()), end: new Date() });
    return years >= 19;
  }, []);

  const handleClose = useCallback(() => {
    resetMobilians();
    onClose?.();
  }, [onClose, resetMobilians]);
  const handleConfirm = () => {
    handleMobiliansOpen();
  };

  useEffect(() => {
    if (mobilians && !isAdultVerified) {
      (async () => {
        const { birthDate } = mobilians;
        if (isOver19(birthDate)) {
          const { code } = await memberMyInfoApi.updateAdultCertificationDate(mobilians);
          if (!code) {
            setMemberInfo((prev) => ({ ...prev, adultCertificationDate: format(new Date(), 'yyyy-MM-dd') }));
            onConfirm?.();
            resetMobilians();
          } else {
            showAlertDialog('성인 인증에 실패하였습니다.', handleClose);
          }
        } else {
          showAlertDialog('성인 인증에 실패하였습니다.', handleClose);
        }
      })();
    }
  }, [handleClose, isAdultVerified, isOver19, mobilians, onConfirm, resetMobilians, setMemberInfo, showAlertDialog]);

  return (
    <LayerPopup
      open={open && !isAdultVerified}
      onClose={handleClose}
      onCancelClick={handleClose}
      onConfirmClick={handleConfirm}
      header={'정보접근 제한'}
      confirmButtonText={'본인인증'}
    >
      <FlexBoxColumn width={'320px'}>
        <FlexBox width={'100%'} justify-content={'center'}>
          <AdulCetiImg width={'90px'} height={'90px'} />
        </FlexBox>
        <Spacing top={14} />
        <TextBox size={'16px'} weight={'500'} color={'#F22214'}>
          19세 미만의 청소년
        </TextBox>
        <TextBox size={'16px'} weight={'500'} color={'#141415'}>
          <TextBox size={'16px'} weight={'500'} color={'#F22214'}>
            정보접근이 제한
          </TextBox>
          된 정보로
        </TextBox>
        <TextBox size={'16px'} weight={'500'} color={'#141415'}>
          <TextBox size={'16px'} weight={'500'} color={color.primary}>
            본인인증
          </TextBox>
          이 필요합니다.
        </TextBox>
        <Spacing top={20} />
        <TextBox width="280px" justify-content="center" style={{ textAlign: 'center', wordBreak: 'keep-all' }}>
          본 정보는 청소년 유해 매체물로써
        </TextBox>
        <TextBox width="280px" justify-content="center" style={{ textAlign: 'center', wordBreak: 'keep-all' }}>
          정보통신망 이용 촉진 및 정보보호등에 관한
        </TextBox>
        <TextBox width="280px" justify-content="center" style={{ textAlign: 'center', wordBreak: 'keep-all' }}>
          법률 및 청소년 보호법의 규정에 의하여
        </TextBox>
        <TextBox width="280px" justify-content="center" style={{ textAlign: 'center', wordBreak: 'keep-all' }}>
          본인인증 이후 사용하실 수 있습니다.
        </TextBox>
      </FlexBoxColumn>
    </LayerPopup>
  );
};

InformationLimitPop.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default InformationLimitPop;
