import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';

import { PAY_MEAN_CODE } from '@ecp/common/src/const/interface/payMeanCode';
import { INICIS_PAY_TYPE_MOBILE } from '@ecp/common/src/const/interface/inicisPayType';
import { CARD_COMPANY_CODE } from '@ecp/common/src/const/interface/cardCompanyCode';
import { NO_INTEREST_DIVISION_CODE } from '@ecp/common/src/const/interface/noInterestDivisionCode';
import { PAYCO_PAY_TYPE } from '@ecp/common/src/const/interface/paycoPayType';

import { checkOs, OS_TYPE } from '@mo-utils/commonUtils';
import { withMobileStatus } from '@mo-recoil/system/selector';
import inicisApi from '@mo-apis/interface/inicisApi';
import paycoApi from '@mo-apis/interface/paycoApi';

const MobilePayForm = ({ data, payType = {}, onComplete }) => {
  const [payData, setPayData] = useState({});
  const { isMobileApp } = useRecoilValue(withMobileStatus);

  useEffect(() => {
    if (Object.values(INICIS_PAY_TYPE_MOBILE).includes(payType)) {
      (async () => {
        const { result } = await inicisApi.getInicisInformation({ ...data, cardType: payType.cardType });
        setPayData(result);
      })();
    } else if (PAYCO_PAY_TYPE === payType) {
      (async () => {
        data.inAppYn = isMobileApp ? 'Y' : 'N';
        data.orderChannel = 'MOBILE';
        const { result } = await paycoApi.createPaycoOrderReservation(data);
        onComplete?.();
        window.location.href = result.orderSheetUrl;
      })();
    } else if (payType) {
      (async () => {
        const { result } = await inicisApi.getInicisInformation(data);
        setPayData(result);
      })();
    }
  }, [data, isMobileApp, payType, onComplete]);

  useEffect(() => {
    if (payData.mid) {
      const inicisForm = document.getElementById('inicis-mobile-form');
      inicisForm.setAttribute('target', '_self');
      inicisForm.setAttribute('accept-charset', ' euc-kr');
      onComplete?.();
      inicisForm.submit();
    }
  }, [payData.mid, onComplete]);

  const convertedQuotaBase = useMemo(
    () => (data.cardCompanyNo === '12' ? '02:03:04:05:06:07:08:09:10:11:12:18:24' : '02:03:04:05:06:07:08:09:10:11:12'),
    [data]
  );

  const cardCompanyNo = useMemo(() => {
    if (data.cardCompanyNo === CARD_COMPANY_CODE.MILITARY_MUTUAL_AID_ASSOCIATION_KB_CARD) {
      return CARD_COMPANY_CODE.KB_CARD;
    }

    if (data.cardCompanyNo === CARD_COMPANY_CODE.MILITARY_MUTUAL_AID_ASSOCIATION_SHINHAN_CARD) {
      return cardCompanyNo.MILITARY_MUTUAL_AID_ASSOCIATION_SHINHAN_CARD;
    }

    return cardCompanyNo;
  }, [data.cardCompanyNo]);

  const noInterestMonth = useMemo(
    () =>
      data.noInterestDivisionCode === NO_INTEREST_DIVISION_CODE.NO_INTEREST
        ? '&merc_noint=Y&noint_quota=' + data.noInterestMonth
        : '',
    [data.noInterestDivisionCode, data.noInterestMonth]
  );

  const pReserved = useMemo(() => {
    if (payType && checkOs() === OS_TYPE.IOS) {
      return payType.pReserved + '&ismart_use_sign=Y&mall_app_name=WSMAPP://?paymethod;' + noInterestMonth;
    }
    return payType?.pReserved;
  }, [payType, noInterestMonth]);

  const isInicis = useMemo(() => {
    return Object.values(INICIS_PAY_TYPE_MOBILE).includes(payType);
  }, [payType]);
  return (
    <div className="inicis-mobile">
      {isInicis && (
        <form id="inicis-mobile-form" name="" method="Post" action={payData.actionUrl} acceptCharset={'euc-kr'}>
          <input type="hidden" readOnly name="P_INI_PAYMENT" value={payType.iniPayment} />
          <input type="hidden" readOnly name="P_MID" value={payData.mid || ''} />
          <input type="hidden" readOnly name="P_OID" value={payData.orderNo || ''} />
          <input type="hidden" readOnly name="P_AMT" value={payData.price || ''} />
          <input type="hidden" readOnly name="P_GOODS" value={payData.goodName || ''} />
          <input type="hidden" readOnly name="P_UNAME" value={payData.buyerName || ''} />
          <input type="hidden" readOnly name="P_NEXT_URL" value={payData.nextUrl || ''} />
          {/*<input type="hidden" readOnly name="P_NOTI_URL" value={payType} /> /!* 가상계좌 입금통보 URL *!/*/}
          <input type="hidden" readOnly name="P_HPP_METHOD" value={payData.hppMethod || ''} />
          <input type="hidden" readOnly name="P_QUOTABASE" value={convertedQuotaBase} />
          {/*<input type="hidden" readOnly name="P_CARD_OPTION" value={'selcode=' + payData.signature || ''} />*/}
          <input type="hidden" readOnly name="P_ONLY_CARDCODE" value={data.cardCompanyNo || ''} />
          <input type="hidden" readOnly name="P_VBANK_DT" value={''} />
          <input type="hidden" readOnly name="P_TAX" value={''} />
          <input type="hidden" readOnly name="P_TAXFREE" value={''} />
          <input type="hidden" readOnly name="P_MOBILE" value={data.buyerTel || ''} />
          <input type="hidden" readOnly name="P_EMAIL" value={payData.buyerEmail || ''} />
          <input type="hidden" readOnly name="P_MNAME" value={'welstorymall'} />
          {/*<input type="hidden" readOnly name="P_LOGO_URL" value={payData.signature || ''} />*/}
          <input type="hidden" readOnly name="P_NOTI" value={payData.noti || ''} />
          <input type="hidden" readOnly name="P_RESERVED" value={pReserved || ''} />
          <input type="hidden" readOnly name="P_CHARSET" value={'utf8'} />
        </form>
      )}
    </div>
  );
};

export const inicisData = PropTypes.shape({
  goodName: PropTypes.string, //상품명
  buyerName: PropTypes.string, //구매자명
  buyerTel: PropTypes.string, // 구매자핸드폰번호
  buyerEmail: PropTypes.string, //구매자 이메일
  price: PropTypes.number, // 금액
  orderNo: PropTypes.string, // 주문번호
  payMeanCode: PropTypes.oneOf([Object.values(PAY_MEAN_CODE)]), //지불수단코드
  cardPointUseYn: PropTypes.string, // 카드포인트사용여부
  cardCompanyNo: PropTypes.oneOf([...Object.values(CARD_COMPANY_CODE)]), //카드회사번호
  noInterestDivisionCode: PropTypes.string, //무이자구분코드
  escrowYn: PropTypes.oneOf(['Y', 'N']), //에스크로여부
  scheduledDeliveryYn: PropTypes.oneOf(['Y', 'N']), //정기배송여부
  noInterestMonth: PropTypes.string,
  noInterest: PropTypes.oneOf([...Object.values(NO_INTEREST_DIVISION_CODE)]),
  quotaBase: PropTypes.string,
  payNo: PropTypes.string,
  tempOrderNo: PropTypes.string,
  cartDiviCd: PropTypes.string, // AS-IS에서 넘기는 값이던데...
  cartNoList: PropTypes.array, // AS-IS에서 넘기는 값이던데...
  orderTypeCode: PropTypes.string, // 주문유형
});

export const paycoData = PropTypes.shape({
  tempOrderNumber: PropTypes.string,
  orderNumber: PropTypes.string,
  paymentNumber: PropTypes.string,
  orderChannel: PropTypes.string,
  inAppYn: PropTypes.string,
  memberId: PropTypes.string,
  cartDiviCd: PropTypes.string, // AS-IS에서 넘기는 값이던데...
  cartNoList: PropTypes.array, // AS-IS에서 넘기는 값이던데...
  orderTypeCode: PropTypes.string, // 주문유형
});
MobilePayForm.propTypes = {
  data: PropTypes.oneOfType([inicisData, paycoData]),
  payType: PropTypes.oneOf([...Object.values(INICIS_PAY_TYPE_MOBILE), PAYCO_PAY_TYPE]),
  onComplete: PropTypes.func,
};

export default MobilePayForm;
