import { getRequest, postRequest, putRequest } from '@mo-apis/axios';

const getStockCheck = (goodNumber) => {
  return getRequest({
    url: `/api/v1/good/${goodNumber}/getStockCheck`,
  });
};

const getGoodDetail = (goodNumber) => {
  return getRequest({
    url: `/api/v1/good/${goodNumber}/detail`,
  });
};

const getGoodBrief = (goodNumber) => {
  return getRequest({
    url: `/api/v1/good/${goodNumber}/brief`,
  });
};

const getMainBestReview = () =>
  getRequest(
    {
      url: `/api/v1/good/main-best-review`,
    },
    { skipSpinner: true }
  );

const getGoodReview = (goodNumber, pageParams) =>
  getRequest(
    {
      url: `/api/v1/good/${goodNumber}/review`,
      params: {
        ...pageParams,
      },
    },
    { skipSpinner: true }
  );
const getGoodPhotoReview = (goodNumber) =>
  getRequest({
    url: `/api/v1/good/${goodNumber}/review/detail`,
  });
const getGoodBestReviewDetails = (goodNumber, pageParams) =>
  getRequest({
    url: `/api/v1/mypage/good-best-review-list`,
    params: { goodNumber, ...pageParams },
  });
const getGoodPhotoReviewDetails = (goodNumber, pageParams) =>
  getRequest({
    url: `/api/v1/mypage/good-photo-review-list`,
    params: { goodNumber, ...pageParams },
  });
const reportGoodEvaluation = (goodEvaluationNumber) =>
  putRequest({
    url: `/api/v1/good-review/${goodEvaluationNumber}/report`,
  });

const createGoodQuestion = (param) =>
  postRequest({
    url: `/api/v1/good/${param.goodNumber}/good-qna`,
    data: param,
  });

const getGoodQuestionList = (goodNumber, myQuestYn, pageParams) =>
  getRequest(
    {
      url: `/api/v1/good/${goodNumber}/good-qna`,
      params: {
        ...pageParams,
        myQuestYn: myQuestYn,
      },
    },
    { skipSpinner: true }
  );

const getGoodQuestionListsTotal = async (goodNumber) =>
  await getRequest(
    {
      url: `/api/v1/good/${goodNumber}/good-qna/count`,
    },
    { skipSpinner: true }
  );

const getCouponList = (goodNumber) => {
  return getRequest({
    url: `/api/v1/coupon/good-coupon-list/${goodNumber}`,
  });
};

const checkOrderAvailable = async (orderList) =>
  await postRequest({
    url: '/api/v1/good/check-availability',
    data: orderList,
  });

const getDeliverySavingGoods = (params, goodNumber, skipSpinner = true, signal = null) =>
  getRequest(
    {
      url: `/api/v1/good/${goodNumber}/delivery-saving`,
      params,
      signal,
    },
    { skipSpinner }
  );

const getValidGoodPromotionList = async (promotionNumberList) => {
  const mappedPromotionNumberList = promotionNumberList.join(',');
  return await getRequest({
    url: `/api/v1/good/valid-promotion`,
    params: { promotionNumberList: mappedPromotionNumberList },
  });
};

const updateGoodInquiryNumber = (goodNumber) =>
  postRequest(
    {
      url: `/api/v1/good/${goodNumber}/good-inquiry-number`,
    },
    { skipError: true }
  );

export default {
  getStockCheck,
  getGoodDetail,
  getGoodBrief,
  getMainBestReview,
  getGoodReview,
  getGoodPhotoReview,
  getGoodBestReviewDetails,
  getGoodPhotoReviewDetails,
  reportGoodEvaluation,
  createGoodQuestion,
  getGoodQuestionList,
  getGoodQuestionListsTotal,
  getCouponList,
  checkOrderAvailable,
  getDeliverySavingGoods,
  getValidGoodPromotionList,
  updateGoodInquiryNumber,
};
