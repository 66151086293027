import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { zIndex } from '@ecp/common/src/style/constant';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import Dropdown from '@ecp/common/src/components/dropdown/mo/Dropdown';
import { stateClassNames } from '@ecp/common/src/components/unstyled/dropdown/DropdownUnstyled';
import { ReactComponent as DropdownArrow } from '@ecp/common/src/assets/icon/icon__dropdown-arrow.svg';

import { PREFIX_HISTORY } from '@mo-components/system/const';

export const LabelRoot = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: ${moTheme.font.size.$14};
  font-weight: ${moTheme.font.weight.medium};

  &.${stateClassNames.disabled} {
    background-color: ${theme.color.background.disabled};
  }
`;

const LabelText = styled(FlexBox)`
  &.${stateClassNames.notSelected} {
    font-size: ${moTheme.font.size.$14};
    font-weight: ${moTheme.font.weight.demiLight};
    color: ${theme.color.text.placeholder};
  }
  height: 22px;
  white-space: nowrap;
  display: block;
`;

const LabelIcon = styled(DropdownArrow)`
  width: 8px;
  height: 5px;
  margin-left: 6px;
  overflow: visible;

  &.${stateClassNames.disabled} {
    color: ${theme.color.text.disabled};
  }

  &.${stateClassNames.open} {
    transform: rotate(180deg);
  }
`;

const StyledLabel = ({ item, placeholder, className }) => {
  return (
    <LabelRoot className={className}>
      <LabelText className={className}>{item?.label ? `${PREFIX_HISTORY}${item?.label}` : placeholder}</LabelText>
      <LabelIcon className={className} />
    </LabelRoot>
  );
};
StyledLabel.propTypes = {
  item: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

const ItemText = styled.div`
  display: flex;
  align-items: center;
  height: inherit;
`;

const Item = styled.div`
  height: 40px;
  padding: 0 14px;
  font-size: ${moTheme.font.size.$14};
  font-weight: 350;
`;

const ItemsRoot = styled.div`
  border: 1px solid ${theme.color.line.disabled};
  border-radius: 6px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
  padding: 4px 0;
  width: 118px;
  box-sizing: border-box;
  background-color: ${theme.color.background.white};

  position: absolute;
  top: 0;
  left: 0;
  max-height: 600px;
  margin-top: 6px;

  overflow-y: auto;
  z-index: ${zIndex.dropdown};

  & > div:not(:first-child) ${ItemText} {
    border-top: 1px solid ${theme.color.line.basic};
  }
`;

const StyledItem = ({ label }) => {
  return (
    <Item>
      <ItemText>{label}</ItemText>
    </Item>
  );
};
StyledItem.propTypes = {
  label: PropTypes.string,
};

const HistoryDropdown = (props) => {
  return (
    <Dropdown
      width={'max-content'}
      height={'22px'}
      placement="bottom-end"
      labelSlot={StyledLabel}
      itemSlot={StyledItem}
      dropdownItemsRoot={ItemsRoot}
      {...props}
    />
  );
};

export default HistoryDropdown;
