import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import SILVER from '@ecp/common/src/assets/image/mypage/image__membership-silver--main.svg';
import GOLD from '@ecp/common/src/assets/image/mypage/image__membership-gold--main.svg';
import PLATINUM from '@ecp/common/src/assets/image/mypage/image__membership-platinum--main.svg';
import SVIP from '@ecp/common/src/assets/image/mypage/image__membership-svip--main.svg';
import SILVER_SMALL from '@ecp/common/src/assets/image/mypage/image__membership-silver--front.svg';
import GOLD_SMALL from '@ecp/common/src/assets/image/mypage/image__membership-gold--front.svg';
import PLATINUM_SMALL from '@ecp/common/src/assets/image/mypage/image__membership-platinum--front.svg';
import SVIP_SMALL from '@ecp/common/src/assets/image/mypage/image__membership-svip--front.svg';

const BADGES = {
  SILVER,
  GOLD,
  PLATINUM,
  SVIP,
  SILVER_SMALL,
  GOLD_SMALL,
  PLATINUM_SMALL,
  SVIP_SMALL,
};

const MyPageMembershipBadge = ({ gradeName = '', isSmall = false }) => {
  return isSmall ? <SmallContainer gradeName={gradeName}></SmallContainer> : <Container gradeName={gradeName} />;
};

MyPageMembershipBadge.propTypes = {
  gradeName: PropTypes.string,
  isSmall: PropTypes.bool,
};

const Container = styled(FlexBox)`
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;

  background-image: url(${({ gradeName }) => (gradeName ? BADGES[gradeName] : '')});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const SmallContainer = styled(FlexBox)`
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;

  background-image: url(${({ gradeName }) => (gradeName ? BADGES[gradeName + '_SMALL'] : '')});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export default MyPageMembershipBadge;
