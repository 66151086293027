import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import clsx from 'clsx';

import { getConsonantBrandTotalPage } from '@ecp/common/src/utils/good/brandUtil';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { TextBoxButton } from '@ecp/common/src/text/TextBoxButton';
import Swiper from '@ecp/common/src/components/swiper/mo/Swiper';

import SeeMoreButton from '@mo-components/customerService/faq/SeeMoreButton';
import BrandNoResult from '@mo-pages/brand/components/BrandNoResult';

const ConsonantSwiperItem = ({ item, onClick }) => {
  return (
    <SwiperItemBox onClick={onClick} selected={item.selected}>
      <TextBoxButton
        size={moTheme.font.size.$14}
        weight={moTheme.font.weight.demiLight}
        color={item.selected ? theme.color.text.basic : theme.color.text.guide}
        title={item.selected ? '선택됨' : ''}
      >
        {item.key}
      </TextBoxButton>
    </SwiperItemBox>
  );
};

const SwiperItemBox = styled(FlexBox)`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: ${({ selected }) => (selected ? theme.color.background.white : theme.color.background.basic)};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ selected }) => (selected ? theme.color.text.basic : 'transparent')};
  align-items: center;
  justify-content: center;
`;

ConsonantSwiperItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
};

const PER_PAGE_SIZE = 20;

const ResultList = ({ filteredConsonantList, brandGroup, brandCountGroup, handleBrandClick, handleShowMoreBrand }) => {
  const totalPage = useCallback(
    (consonant) => {
      return getConsonantBrandTotalPage(brandCountGroup, consonant, PER_PAGE_SIZE);
    },
    [brandCountGroup]
  );

  const showMoreButton = useCallback(
    (consonant) => {
      return brandGroup[consonant].page < totalPage(consonant);
    },
    [brandGroup, totalPage]
  );

  return (
    <>
      {filteredConsonantList.map((key) => (
        <BrandResultItemWrap key={key}>
          <TextBox size={moTheme.font.size.$16} weight={moTheme.font.weight.bold}>
            {key}
          </TextBox>
          <Spacing top={20} />
          {brandGroup[key]?.brandList?.map((brand) => (
            <FlexBoxColumn key={brand.brandNumber}>
              <TextBox
                size={moTheme.font.size.$13}
                weight={moTheme.font.weight.demiLight}
                color={theme.color.text.sub}
                onClick={() => handleBrandClick({ brandNumber: brand.brandNumber })}
              >
                {brand.brandName}
              </TextBox>
              <Spacing top={14} />
            </FlexBoxColumn>
          ))}
          {showMoreButton(key) && (
            <SeeMoreButton
              padding={'0 0 14px 0'}
              page={brandGroup[key].page}
              totalPage={totalPage(key)}
              ariaLabel={key + '으로 시작하는 브랜드'}
              handleClickSeeMoreSearch={() => handleShowMoreBrand(key)}
            />
          )}
          <Spacing top={6} />
          <HorizontalDivider />
          <Spacing top={20} />
        </BrandResultItemWrap>
      ))}
    </>
  );
};

ResultList.propTypes = {
  filteredConsonantList: PropTypes.array,
  brandGroup: PropTypes.object,
  brandCountGroup: PropTypes.object,
  handleBrandClick: PropTypes.func,
  handleShowMoreBrand: PropTypes.func,
};

const BrandResultList = ({
  consonantSwiperRef,
  sticky,
  consonantSwiperStickyTop,
  selectedConsonant,
  consonantList,
  filteredConsonantList,
  brandGroup,
  brandCountGroup,
  handleSearchConditionChange,
  handleBrandClick,
  handleShowMoreBrand,
  title,
}) => {
  const mappedConsonantList = useMemo(() => {
    return consonantList.map((it) => ({ key: it, selected: it === selectedConsonant }));
  }, [consonantList, selectedConsonant]);

  const handleClickConsonant = useCallback(
    (index) => {
      const initialConsonant = mappedConsonantList[index].key;
      handleSearchConditionChange({ initialConsonant, consonantIndex: index });
    },
    [handleSearchConditionChange, mappedConsonantList]
  );

  return (
    <FlexBoxColumn>
      <SwiperWrapper className={clsx({ sticky })} scrollTop={consonantSwiperStickyTop}>
        <Swiper
          ref={consonantSwiperRef}
          itemList={mappedConsonantList}
          itemComponent={ConsonantSwiperItem}
          itemProps={{ width: 'fit-content' }}
          carouselProps={{
            slidesPerView: 'auto',
            spaceBetween: 12,
            navigation: false,
            loop: false,
          }}
          width={'320px'}
          onClick={(e, index) => handleClickConsonant(index)}
          title={title}
        />
      </SwiperWrapper>
      <Spacing top={30} />
      {filteredConsonantList.length > 0 ? (
        <ResultList
          filteredConsonantList={filteredConsonantList}
          brandGroup={brandGroup}
          brandCountGroup={brandCountGroup}
          handleBrandClick={handleBrandClick}
          handleShowMoreBrand={handleShowMoreBrand}
        />
      ) : (
        <BrandNoResult />
      )}
    </FlexBoxColumn>
  );
};

const SwiperWrapper = styled(FlexBox)`
  z-index: 9;
  background-color: ${theme.color.background.white};
  padding-top: 20px;

  &.sticky {
    position: sticky;
    top: ${({ scrollTop }) => scrollTop};
    transition: top 0.3s ease-in-out;
  }
`;

const BrandResultItemWrap = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

export default BrandResultList;

BrandResultList.propTypes = {
  consonantSwiperRef: PropTypes.object,
  sticky: PropTypes.bool,
  consonantSwiperStickyTop: PropTypes.string,
  selectedConsonant: PropTypes.string,
  consonantList: PropTypes.array,
  filteredConsonantList: PropTypes.array,
  brandGroup: PropTypes.object,
  brandCountGroup: PropTypes.object,
  handleSearchConditionChange: PropTypes.func,
  handleBrandClick: PropTypes.func,
  handleShowMoreBrand: PropTypes.func,
  title: PropTypes.string,
};
