import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import TabGroup from '@ecp/common/src/components/tab/mo/TabGroup';
import { TabPanel } from '@ecp/common/src/components/tab/mo/TabPanel';
import { radioClassNames } from '@ecp/common/src/components/unstyled/input/RadioUnstyled';

import { userInfoState } from '@mo-recoil/common/user/atom';
import memberWishApi from '@mo-apis/member/memberWishApi';
import goodListApi from '@mo-apis/good/goodListApi';
import RecentGoodList from '@mo-components/common/navigation/RecentGoodList';

const TabSlot = (props) => {
  return <TabSlotWrapper {...props} />;
};

const TabSlotWrapper = styled(FlexBox)`
  font-size: ${moTheme.font.size.$14};
  color: ${theme.color.text.guide};
  line-height: ${moTheme.font.lineHeight.demiLight};
  width: max-content;
  height: 12px;
  border-right: ${({ lastChild }) => (lastChild ? 'none' : `1px solid ${theme.color.line.line02}`)};
  padding: ${({ firstChild }) => (firstChild ? '0 10px 0 0' : '0 10px')};

  &.${radioClassNames.checked} {
    font-weight: ${moTheme.font.weight.bold};
    color: ${theme.color.text.basic};
  }
`;

const RecentGood = ({ recentlyViewedGoodList, onClose }) => {
  const userInfo = useRecoilValue(userInfoState);

  const [orderOftenGoodList, setOrderOftenGoodList] = useState([]);
  const [recentlyGoodList, setRecentlyGoodList] = useState(recentlyViewedGoodList);

  const tabItems = useMemo(() => {
    return [
      { tabName: `최근 본 상품(${recentlyViewedGoodList.length})` },
      { tabName: `자주 구매한 상품(${orderOftenGoodList.length})` },
    ];
  }, [orderOftenGoodList.length, recentlyViewedGoodList.length]);

  useEffect(() => {
    (async () => {
      const { result = [] } = await goodListApi.getGoodOrderOftenList();
      const goodList = result.map(({ goodNumber, thumbnailImageUrl03, orderTimesSum, wish, adultGood }) => ({
        goodNumber,
        thumbnailImageUrl03,
        wish,
        adultGood,
        itemDecorators: [{ type: 'RECENT', badgeContent: orderTimesSum > 99 ? '99+' : `${orderTimesSum}` }],
      }));
      setOrderOftenGoodList(goodList);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const goodWishList = recentlyViewedGoodList.map(({ goodNumber }) => goodNumber).join();
      const { result = [] } = await memberWishApi.getGoodWishList(goodWishList);

      const wishGoodMap = result.reduce((goodMap, good) => {
        goodMap[good.goodNumber] = good.wish;
        return goodMap;
      }, {});

      const goodList = recentlyViewedGoodList.map(({ goodNumber, ...good }) => ({
        goodNumber,
        ...good,
        wish: !!wishGoodMap[goodNumber],
      }));
      setRecentlyGoodList(goodList);
    })();
  }, [recentlyViewedGoodList]);

  return (
    <Wrapper>
      <Spacing top={30} />
      <FlexBox>
        <Title>‘</Title>
        <UserName>{userInfo?.userName}</UserName>
        <Title>’ 님</Title>
      </FlexBox>
      <Title>쇼핑 히스토리를 남겨두었어요.</Title>
      <Spacing top={20} />
      <TabGroup tabItems={tabItems} tabSlot={TabSlot}>
        <TabPanel>
          <RecentGoodList list={recentlyGoodList} onClose={onClose} emptyListText={'최근 본 상품이 없습니다.'} />
        </TabPanel>
        <TabPanel>
          <RecentGoodList list={orderOftenGoodList} onClose={onClose} emptyListText={'자주 구매한 상품이 없습니다.'} />
        </TabPanel>
      </TabGroup>
    </Wrapper>
  );
};

RecentGood.propTypes = {
  recentlyViewedGoodList: PropTypes.array,
  onClose: PropTypes.func,
};
export default RecentGood;

const Wrapper = styled(FlexBoxColumn)`
  width: 260px;
  padding: 20px 0;
  align-items: flex-start;
`;

const Title = styled(TextBox)`
  font-size: ${moTheme.font.size.$16};
  font-weight: ${moTheme.font.weight.bold};
  line-height: ${moTheme.font.lineHeight.demiLight};
`;

const UserName = styled(Title)`
  color: ${theme.color.primary};
`;
