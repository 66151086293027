import { atom } from 'recoil';

import { GOOD_SORT_TYPE } from '@ecp/common/src/const/good';

export const categoryListState = atom({
  key: 'categoryListState',
  default: [],
});

export const categoryNumberState = atom({
  key: 'categoryNumberState',
  default: '',
});

export const categoryPageNewGoodCategoryNumberState = atom({
  key: 'categoryPageNewGoodCategoryNumberState',
  default: '',
});

export const categoryPageNewGoodPagePropsState = atom({
  key: '',
  default: { page: 1, sort: GOOD_SORT_TYPE[0] },
});

export const subCategoryGoodListState = atom({
  key: 'subCategoryGoodListState',
  default: [],
});

export const subCategoryGoodListUrlState = atom({
  key: 'subCategoryGoodListUrlState',
  default: '',
});

export const subCategoryGoodTotalCountState = atom({
  key: 'subCategoryGoodTotalCountState',
  default: 0,
});
