import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { PageButton } from '@ecp/common/src/components/button/mo/Button';
import { SwiperShareItem } from '@ecp/common/src/components/swiper/mo/component/SwiperItem';
import Swiper from '@ecp/common/src/components/swiper/mo/Swiper';

import recopickApi from '@mo-apis/interface/recopickApi';
import ShareBottomSheets from '@mo-components/common/share/ShareBottomSheets';
import { ReactComponent as IconShopping } from '@mo-assets/icon/icon__shopping.svg';
import theme from '@ecp/common/src/style/theme/default';

const OrderComplete = ({ orderResult }) => {
  const navigate = useNavigate();
  const [itemList, setItemList] = useState([]);
  const [shareSheetsOpen, setShareSheetsOpen] = useState(false);
  const [shareItem, setShareItem] = useState({});

  const handleGoToOrderDetailPageClick = useCallback(() => {
    if (['82', '84'].includes(orderResult?.orderType) || orderResult?.parkPoint) {
      navigate(`/my-page/giftishow/${orderResult?.orderNumber}`);
    } else if (orderResult?.bulkpOrder) {
      navigate(`/my-page/bulkp/${orderResult?.orderNumber}`);
    } else {
      navigate(`/my-page/order/${orderResult?.orderNumber}`);
    }
  }, [navigate, orderResult?.orderNumber, orderResult?.orderType, orderResult?.parkPoint]);

  const handleGoToHomePageClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  useEffect(() => {
    if (orderResult?.itemList) {
      setItemList([...orderResult.itemList]);
    }
  }, [orderResult?.itemList]);

  const handleShareIconClick = useCallback((item) => {
    setShareItem(item);
    setShareSheetsOpen(true);
  }, []);

  useEffect(() => {
    if (orderResult?.orderNumber) {
      (async () => {
        await recopickApi.createRecommendationOrderLog(
          'order',
          {
            orderNumber: orderResult?.orderNumber,
            itemList: orderResult?.itemList,
          },
          '/order',
          window.location.pathname
        );
      })();
    }
  }, [orderResult?.orderNumber, orderResult?.itemList]);

  const presentInputDueDate = useMemo(
    () => new Date(orderResult?.presentInputTmlDatetime),
    [orderResult?.presentInputTmlDatetime]
  );

  const refinedItemList = useMemo(
    () =>
      Object.values(
        itemList.reduce((refined, item) => {
          refined[item.goodNumber] = item;
          return refined;
        }, {})
      ),
    [itemList]
  );

  return (
    <>
      <FlexBoxColumn width={'100%'}>
        <Spacing width={'100%'} top={20} bottom={30} left={20} right={20}>
          <FlexBox width={'100%'} justify-content={'center'}>
            <FlexBox>
              <IconShopping />
            </FlexBox>
            <Spacing left={20} />
            <FlexBoxColumn width="100%">
              <TextBox
                width="100%"
                line-height="20px"
                size="20px"
                weight={moTheme.font.weight.demiLight}
                color="#141415"
              >
                주문이 완료되었습니다.
              </TextBox>
              <Spacing top={12} />
              <TextBox width="100%" size="14px" weight={moTheme.font.weight.medium} color="#141415">
                주문번호 :{' '}
                <TextBox size="14px" weight={moTheme.font.weight.medium} color={theme.color.primary}>
                  {orderResult?.orderNumber}
                </TextBox>
              </TextBox>
            </FlexBoxColumn>
          </FlexBox>
          <Spacing top={30} />
          <FlexBox width={'100%'}>
            <PageButton width={'100%'} type="Default" onClick={handleGoToOrderDetailPageClick}>
              주문상세보기
            </PageButton>
            <Spacing left={8} />
            <PageButton width={'100%'} type="Third" onClick={handleGoToHomePageClick}>
              계속 쇼핑하기
            </PageButton>
          </FlexBox>
          {orderResult?.present && (
            <Spacing top="20px" width="320px">
              <PresentGuideText>
                선물 받는 분의 휴대전화번호로 선물 메시지와 함께 정보 입력을 위한 링크(URL)가 문자로 발송됩니다. <br />
              </PresentGuideText>
              <PresentGuideText>
                선물 받는 분이 배송정보를 입력해 주시면 상품 준비 및 배송이 시작됩니다. <br />
              </PresentGuideText>
              <PresentGuideText>
                <TextHighlight>
                  {presentInputDueDate.getMonth() + 1}월 {presentInputDueDate.getDate()}일
                </TextHighlight>
                까지 배송 정보를 입력하지 않으실 경우 선물 주문은 자동 취소됩니다.
              </PresentGuideText>
            </Spacing>
          )}
        </Spacing>

        <HorizontalDivider height="10px" color="#F7F7F7" />
        <Spacing width={'100%'} top={30} bottom={40} left={0} right={0}>
          <FlexBoxColumn width={'100%'} align-items={'flex-start'}>
            <Spacing left={20}>
              <TextBox width="100%" line-height="26px" size="16px" weight={moTheme.font.weight.bold} color="#141415">
                주문상품 공유
              </TextBox>
            </Spacing>
            <Spacing top={14} />
            <Swiper
              width={'100%'}
              itemList={refinedItemList}
              itemProps={{ width: '100px', height: '100px', radius: '6px', onShareIconClick: handleShareIconClick }}
              itemComponent={SwiperShareItem}
              carouselProps={{
                slidesPerView: 'auto',
                loop: false,
                spaceBetween: 10,
                slidesOffsetBefore: 20,
                slidesOffsetAfter: 20,
              }}
            />
          </FlexBoxColumn>
        </Spacing>
        <ShareBottomSheets
          kakaoProps={{
            type: 'commerce',
            title: shareItem?.goodName,
            imageUrl: shareItem?.src ? shareItem?.src : '',
            productName: shareItem?.goodName,
            regularPrice: shareItem?.salePrice || 0,
            discountRate: shareItem?.discountRate || 0,
            discountPrice: shareItem?.discountPrice || 0,
            linkUrl: shareItem?.urlLink,
            productNumber: shareItem?.goodNumber,
          }}
          open={shareSheetsOpen}
          onClose={setShareSheetsOpen}
          urlLink={shareItem?.urlLink}
        />
      </FlexBoxColumn>
    </>
  );
};

export default OrderComplete;

OrderComplete.propTypes = {
  orderResult: PropTypes.object,
};

const PresentGuideText = styled(TextBox)`
  font-weight: 350;
  font-size: 14px;
  line-height: 22px;
  display: inline;
`;
const TextHighlight = styled.span`
  color: ${theme.color.primary};
  font-weight: 700;
`;
