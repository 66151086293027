import { useEffect, useRef, useCallback } from 'react';

import throttle from 'lodash-es/throttle';

const useIntersection = ({ fetchNext, rootMargin = '0px', threshold = 0, hasNext }) => {
  const setNextRef = useRef(null);
  const interactionObserver = useRef();

  const handlePopstate = useCallback(() => {
    interactionObserver.current?.disconnect();
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate);

    return () => window.removeEventListener('popstate', handlePopstate);
  }, [handlePopstate]);

  useEffect(() => {
    if (!setNextRef.current) return;

    const callback = throttle(async ([entry], observer) => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target);
        await fetchNext();
        await observer.observe(entry.target);
      }
    }, 1000);

    interactionObserver.current = new IntersectionObserver(callback, { rootMargin, threshold });
    interactionObserver.current.observe(setNextRef.current);

    return () => interactionObserver.current.disconnect();
  }, [rootMargin, threshold, fetchNext, setNextRef, hasNext]);

  return { setNextRef };
};

export default useIntersection;
