import { insertOrderLog } from '@ecp/common/src/utils/order/orderLoggingUtils';

import { getRequest, postRequest } from '@mo-apis/axios';

const getOrderUser = () =>
  getRequest({
    url: `/api/v1/order/user`,
    data: {},
  });

const createTempOrder = (payload) =>
  postRequest({
    url: `/api/v1/order/create-temp-order`,
    data: { ...payload },
  });

const createOrder = (payload) =>
  postRequest(
    {
      url: `/api/v1/order/mobile/create-order`,
      data: payload,
    },
    { skipSpinner: true }
  ).then((data) => {
    insertOrderLog(data.result);
    return data;
  });

const getBankList = (siteNumber) =>
  getRequest({
    url: '/api/v1/payment/get-bank-list',
    params: { siteNumber },
  });

const getRefundBankList = (siteNumber) =>
  getRequest({
    url: '/api/v1/payment/get-refund-bank-list',
    params: { siteNumber },
  });

const getRefundMobiliansBankList = (siteNumber) =>
  getRequest({
    url: '/api/v1/payment/get-hp-refund-bank-list',
    params: { siteNumber },
  });

const getCreditCardList = (siteNumber) =>
  getRequest({
    url: '/api/v1/payment/get-credit-card-list',
    params: { siteNumber },
  });

const createOrderByVirtualAccount = (payload) =>
  postRequest(
    {
      url: `/api/v1/order/mobile/create-order-by-virtual-account`,
      data: { ...payload },
    },
    { skipSpinner: true }
  ).then((data) => {
    insertOrderLog(data.result);
    return data;
  });

const paymentApi = {
  getOrderUser,
  createTempOrder,
  createOrder,
  getBankList,
  getRefundBankList,
  getRefundMobiliansBankList,
  getCreditCardList,
  createOrderByVirtualAccount,
};

export default paymentApi;
