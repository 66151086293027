import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';

import { getFilePath } from '@ecp/common/src/utils/fileUtil';
import useEmployeeBenefitCoupon from '@ecp/common/src/hooks/coupon/useEmployeeBenefitCoupon';
import { FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import BenefitCouponCard from '@ecp/common/src/components/coupon/BenefitCouponCard';
import { COUPON_POPUP_TYPE, COUPON_TYPE } from '@ecp/common/src/const/coupon/couponConst';

import { withCategoryById } from '@mo-recoil/category/selector';
import { employeeBenefitBrandCountState, employeeBenefitBrandNameState } from '@mo-recoil/main/atom';
import { categoryNumberState } from '@mo-recoil/category/atom';
import { useAlertDialog } from '@mo-hooks/common/useModal';
import couponApi from '@mo-apis/coupon/couponApi';
import myReviewApi from '@mo-apis/myPage/myReviewApi';
import myMembershipApi from '@mo-apis/myPage/myMembershipApi';
import exhibitionApi from '@mo-apis/exhibition/exhibitionApi';
import eventApi from '@mo-apis/event/eventApi';
import Exhibition from '@mo-components/exhibition/Exhibition';
import { EventCard } from '@mo-components/event/EventCard';
import EmployeeBenefitCouponPopup from '@mo-components/benefit/popup/EmployeeBenefitCouponPopup';

const AvailableBenefitTab = ({ gradeInfo, handleDrawerClose, setInfoChanged }) => {
  const { pathname } = useLocation();
  const { brandNumber } = useParams();
  const navigate = useNavigate();
  const { showAlertDialog } = useAlertDialog();

  const [newestExhibition, setNewestExhibition] = useState(null);
  const [newestEvent, setNewestEvent] = useState(null);
  const [selectedBenefitCardType, setSelectedBenefitCardType] = useState(COUPON_POPUP_TYPE.BRAND);
  const [couponPopupOpen, setCouponPopupOpen] = useState(false);

  const categoryNumber = useRecoilValue(categoryNumberState);
  const categoryState = useRecoilValue(withCategoryById(categoryNumber));
  const brandCountState = useRecoilValue(employeeBenefitBrandCountState);
  const brandNameState = useRecoilValue(employeeBenefitBrandNameState);

  const {
    downloadableCouponCount,
    myNoReviewCount,
    maxReviewSaveMoney,
    brandCouponCount,
    brandCount,
    brandListCouponCount,
    brandName,
    categoryCouponCount,
    showBrandListCoupon,
    showBrandCoupon,
    showCategoryCoupon,
    showBirthdayCoupon,
    showFirstOrderCoupon,
    showMembershipCoupon,
    showDownloadCoupon,
    showReviewCoupon,
    handleBirthDayCouponDownloadClick,
    handleFirstPurCouponDownloadClick,
    handleNavigateButtonClick,
  } = useEmployeeBenefitCoupon({
    pathname,
    categoryNumber,
    brandNumber,
    brandCountState,
    brandNameState,
    couponApi,
    myReviewApi,
    myMembershipApi,
    showAlertDialog,
    handleDrawerClose,
    setInfoChanged,
  });

  const categoryName = useMemo(() => {
    return categoryState?.categoryName || '';
  }, [categoryState?.categoryName]);

  const couponDetailName = useMemo(() => {
    if (selectedBenefitCardType === COUPON_POPUP_TYPE.BRAND) {
      return brandName;
    } else if (selectedBenefitCardType === COUPON_POPUP_TYPE.CATEGORY) {
      return categoryName;
    } else {
      return '';
    }
  }, [brandName, categoryName, selectedBenefitCardType]);

  const getNewestExhibition = useCallback(async () => {
    const { result } = await exhibitionApi.getNewestExhibition();
    if (result) {
      setNewestExhibition({
        ...result,
        title: result.name,
        subTitle: result.subName,
        src: getFilePath(result.mobileImageFilePath),
      });
    }
  }, []);

  const getNewestEvent = useCallback(async () => {
    const { result } = await eventApi.getNewestEvent();
    if (result) {
      setNewestEvent(result);
    }
  }, []);

  const handleCouponPopupOpen = useCallback((popupType) => {
    setSelectedBenefitCardType(popupType);
    setCouponPopupOpen(true);
  }, []);

  const handleCouponPopupClose = useCallback(() => {
    setCouponPopupOpen(false);
    setInfoChanged(false);
  }, [setInfoChanged]);

  const noBenefit = useMemo(
    () =>
      !showBrandListCoupon &&
      !showBrandCoupon &&
      !showCategoryCoupon &&
      !showFirstOrderCoupon &&
      !showBirthdayCoupon &&
      !showMembershipCoupon &&
      !showDownloadCoupon &&
      !showReviewCoupon,
    [
      showBirthdayCoupon,
      showBrandCoupon,
      showBrandListCoupon,
      showCategoryCoupon,
      showDownloadCoupon,
      showFirstOrderCoupon,
      showMembershipCoupon,
      showReviewCoupon,
    ]
  );

  useEffect(() => {
    (async () => {
      await Promise.all([getNewestExhibition(), getNewestEvent()]);
    })();
  }, [getNewestEvent, getNewestExhibition]);

  return (
    <>
      <FlexBoxColumn width={'100%'} data-ds-label2={'navi_benefit_p'}>
        <Spacing top={20} />
        <FlexBoxColumn gap={'20px'}>
          {showBrandListCoupon && (
            <BenefitCouponCard
              mobile
              couponType={COUPON_TYPE.BRAND_LIST}
              messageValue={brandCount}
              countValue={brandListCouponCount}
              handleButtonClick={() => handleCouponPopupOpen(COUPON_POPUP_TYPE.BRAND_LIST)}
            />
          )}
          {showBrandCoupon && (
            <BenefitCouponCard
              mobile
              couponType={COUPON_TYPE.BRAND}
              messageValue={brandName}
              countValue={brandCouponCount}
              handleButtonClick={() => handleCouponPopupOpen(COUPON_POPUP_TYPE.BRAND)}
            />
          )}
          {showCategoryCoupon && (
            <BenefitCouponCard
              mobile
              couponType={COUPON_TYPE.CATEGORY}
              messageValue={categoryName}
              countValue={categoryCouponCount}
              handleButtonClick={() => handleCouponPopupOpen(COUPON_POPUP_TYPE.CATEGORY)}
            />
          )}
          {showFirstOrderCoupon && (
            <BenefitCouponCard
              mobile
              couponType={COUPON_TYPE.FIRST_ORDER}
              handleButtonClick={handleFirstPurCouponDownloadClick}
            />
          )}
          {showBirthdayCoupon && (
            <BenefitCouponCard
              mobile
              couponType={COUPON_TYPE.BIRTHDAY}
              handleButtonClick={handleBirthDayCouponDownloadClick}
            />
          )}
          {showMembershipCoupon && (
            <BenefitCouponCard
              mobile
              couponType={COUPON_TYPE.MEMBERSHIP}
              messageValue={gradeInfo?.memberGradeName}
              handleButtonClick={() => handleNavigateButtonClick(COUPON_TYPE.MEMBERSHIP)}
            />
          )}
          {showDownloadCoupon && (
            <BenefitCouponCard
              mobile
              couponType={COUPON_TYPE.DOWNLOAD}
              countValue={downloadableCouponCount}
              handleButtonClick={() => handleNavigateButtonClick(COUPON_TYPE.DOWNLOAD)}
            />
          )}
          {showReviewCoupon && (
            <BenefitCouponCard
              mobile
              couponType={COUPON_TYPE.REVIEW}
              countValue={myNoReviewCount}
              pointValue={maxReviewSaveMoney}
              handleButtonClick={() => handleNavigateButtonClick(COUPON_TYPE.REVIEW)}
            />
          )}
        </FlexBoxColumn>
        {!noBenefit && (
          <>
            <Spacing top={20} />
            <HorizontalDivider />
          </>
        )}
        <FlexBoxColumn>
          {newestExhibition && (
            <FlexBoxColumn>
              {!noBenefit && <Spacing top={20} />}
              <Exhibition
                width={'260px'}
                height={'200px'}
                itemProps={{
                  exhibitionNumber: newestExhibition.exhibitionNumber,
                  width: '260px',
                  height: '150px',
                  radius: '8px',
                  isSwiperSize: true,
                }}
                item={{ ...newestExhibition }}
                handleDrawerClose={handleDrawerClose}
              />
            </FlexBoxColumn>
          )}
          {newestEvent && (
            <FlexBoxColumn>
              <Spacing top={20} />
              <EventCard
                number={newestEvent.eventNumber}
                name={newestEvent.eventName}
                subName={newestEvent.eventSubName}
                imgPath={newestEvent.mobileBannerImageUrl}
                handleEventCardClick={() => {
                  navigate(`/event/${newestEvent.eventNumber}`);
                  handleDrawerClose();
                }}
                startDate={newestEvent.displayStartDate}
                endDate={newestEvent.displayEndDate}
                cardProps={{ width: '260px', height: '200px' }}
                imageProps={{ width: '260px', height: '150px' }}
                dateHidden
              />
            </FlexBoxColumn>
          )}
        </FlexBoxColumn>
      </FlexBoxColumn>
      <EmployeeBenefitCouponPopup
        open={couponPopupOpen}
        type={selectedBenefitCardType}
        detailNumber={brandNumber || categoryNumber || ''}
        detailName={couponDetailName}
        setInfoChanged={setInfoChanged}
        onClose={handleCouponPopupClose}
      />
    </>
  );
};

export default React.memo(AvailableBenefitTab);

AvailableBenefitTab.propTypes = {
  gradeInfo: PropTypes.object,
  handleDrawerClose: PropTypes.func,
  setInfoChanged: PropTypes.func,
};
