import { atom } from 'recoil';

export const cartBadgeCountAtom = atom({
  key: 'cartBadgeCountAtomMo',
  default: {
    count: 0,
  },
});

export const cartPaymentSummaryState = atom({
  key: 'cartPaymentSummaryState',
  default: {},
});

export const myDeliveryPlaceListState = atom({
  key: 'myDeliveryPlaceListState',
  default: undefined,
});
