import React from 'react';
import PropTypes from 'prop-types';

import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { IconButton } from '@ecp/common/src/components/button/mo/IconButton';
import { ReactComponent as CardViewIcon } from '@ecp/common/src/assets/icon/icon__card-view.svg';
import { ReactComponent as ListViewIcon } from '@ecp/common/src/assets/icon/icon__list-view.svg';

const GoodViewType = ({ isCardType, isListType, onClick }) => {
  return (
    <>
      {isCardType && (
        <>
          <Spacing left={14} />
          <FlexBox onClick={onClick}>
            <IconButton icon={CardViewIcon} width={'14'} height={'14'} title={'격자형 목록보기'} />
          </FlexBox>
        </>
      )}
      {isListType && (
        <>
          <Spacing left={14} />
          <FlexBox onClick={onClick}>
            <IconButton icon={ListViewIcon} width={'14'} height={'14'} title={'리스트형 목록보기'} />
          </FlexBox>
        </>
      )}
    </>
  );
};

export default GoodViewType;

GoodViewType.propTypes = {
  isCardType: PropTypes.bool,
  isListType: PropTypes.bool,
  onClick: PropTypes.func,
};
