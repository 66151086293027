import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { format } from 'date-fns';

import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox, TextBoxAlignLeft } from '@ecp/common/src/text/TextBox';
import StyledImg from '@ecp/common/src/components/image/StyledImg';

import EventEndDimPopupWrap from '@mo-components/event/EventEndDimPopupWrap';

export const EventCard = ({
  eventNumber,
  name,
  subName,
  imgPath,
  startDate,
  endDate,
  state,
  handleEventCardClick,
  style,
  dateHidden,
  cardProps,
  imageProps,
}) => {
  return (
    <FlexBoxColumn style={style} data-ds-contents={eventNumber}>
      <Spacing top={10} />
      <FlexBoxColumn
        width={cardProps.width}
        height={cardProps.height}
        border-radius={'8px'}
        onClick={handleEventCardClick}
        style={{ cursor: 'pointer' }}
      >
        <FlexBox width={imageProps.width} height={imageProps.height}>
          {'end' === state && (
            <EventEndDimPopupWrap
              text={`종료된 
        이벤트입니다.`}
            />
          )}
          <StyledImg src={imgPath} radius={'6px'} width={imageProps.width} height={imageProps.height} />
        </FlexBox>
        <Spacing top={10} />
        <FlexBoxColumn width={'100%'} height={'40px'} justify-content={'flex-start'} align-items={'center'}>
          <EventNameTextBox>{name}</EventNameTextBox>
          <EventNameTextBox>{subName}</EventNameTextBox>
        </FlexBoxColumn>
        <Spacing top={4} />
        {!dateHidden && (
          <TextBox size={13} width={'100%'} color={'#727272'} weight={350}>
            {`${format(new Date(startDate), 'yyyy. MM. dd')} ~ ${format(new Date(endDate), 'yyyy. MM. dd')}`}
          </TextBox>
        )}
      </FlexBoxColumn>
      <Spacing top={10} />
    </FlexBoxColumn>
  );
};

EventCard.propTypes = {
  style: PropTypes.object,
  name: PropTypes.string,
  eventNumber: PropTypes.string,
  subName: PropTypes.string,
  imgPath: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  state: PropTypes.string,
  handleEventCardClick: PropTypes.func,
  dateHidden: PropTypes.bool,
  cardProps: PropTypes.object,
  imageProps: PropTypes.object,
};

EventCard.defaultProps = {
  dateHidden: false,
  cardProps: { width: '156px', height: '165px' },
  imageProps: { width: '156px', height: '90px' },
};

const EventNameTextBox = styled(TextBoxAlignLeft)`
  width: 100%;
  font-weight: ${moTheme.font.weight.medium};
  font-size: ${moTheme.font.size.$14};
  line-height: ${moTheme.font.lineHeight.medium};
`;
