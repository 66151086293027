export const DROPDOWN_INCOME_DEDUCTION_TYPE = [
  {
    label: '휴대전화',
    key: 'cellPhone',
    value: 'cellPhone',
  },
  {
    label: '현금영수증카드번호',
    key: 'incomeDeductionCard',
    value: 'incomeDeductionCard',
  },
];

export const DROPDOWN_FIRST_PHONE_NUMBER = [
  {
    label: '010',
    key: '010',
    value: '010',
  },
  {
    label: '011',
    key: '011',
    value: '011',
  },
  {
    label: '016',
    key: '016',
    value: '016',
  },
  {
    label: '017',
    key: '017',
    value: '017',
  },
  {
    label: '018',
    key: '018',
    value: '018',
  },
  {
    label: '019',
    key: '019',
    value: '019',
  },
];

// 현금영수증사용구분코드 OD0012
export const CASH_RECEIPT_USE_DIVISION_CODE = {
  DEDUCTION: '10', // 소득공제용
  EXPENSE: '20', // 지출증빙용
};

// 현금영수증발급코드 OD0033
export const CASH_RECEIPT_ISSUE_CODE = {
  RESIDENT: '10', // 주민등록번호
  PERMIT: '20', // 사업자등록번호
  PHONE: '30', // 핸드폰번호
  CARD: '40', // 신용카드번호
  CASH: '50', // 현금영수증카드번호
};

// 현금영수증상태코드 OD0011
export const CASH_STATUS_CODE = {
  REQ: '20', // 요청
};
