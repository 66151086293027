import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import FullPagePopup from '@ecp/common/src/components/modal/mo/popup/FullPagePopup';
import { PageButton } from '@ecp/common/src/components/button/mo/Button';

import { useAlertDialog, useConfirmDialog } from '@mo-hooks/common/useModal';
import useMyDeliveryPlaceList from '@mo-hooks/order/useMyDeliveryPlaceList';
import AddressCardRadioGroup from '@mo-components/address/AddressCardRadioGroup';
import DeliveryPlaceSelectionItem from '@mo-components/order/payment/DeliveryPlaceSelectionItem';
import MyPageDeliveryLocationAddPopup from '@mo-pages/myPage/deliveryLocation/MyPageDeliveryLocationAddPopup';
import { ReactComponent as EmptyDeliveryLocationIcon } from '@mo-assets/image/mypage/image__empty_delivery_location.svg';

const DeliveryPlaceSelectionPopup = ({ open, onClose, onConfirm, currentValue: defaultSequence }) => {
  const { baseDeliveryPlace, deliveryPlaceList, deleteDeliveryPlace } = useMyDeliveryPlaceList();
  const [selectedSequence, setSelectedSequence] = useState('');
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupDeliveryPlace, setPopupDeliveryPlace] = useState();

  // 지정 배송지가 배송지 목록에 존재하는지 여부
  // * 지정 배송지: 해당 화면을 호출한 부모 화면에 표시되고 있는 배송지
  const [includeDefaultPlace, setIncludeDefaultPlace] = useState(false);

  const { showConfirmDialog } = useConfirmDialog();
  const { showAlertDialog } = useAlertDialog();

  useEffect(() => {
    if (open) {
      setSelectedSequence(
        (prev) => prev || (defaultSequence ? defaultSequence : baseDeliveryPlace?.memberDeliverySequence)
      );
      // 호출한 화면에서 지정된 배송지가 삭제된 경우 --> 지정 배송지 존재 여부
      const existsDefault = deliveryPlaceList?.some(({ memberDeliverySequence }) => memberDeliverySequence === defaultSequence)
      setIncludeDefaultPlace(existsDefault);
      // 지정 배송지 존재하지 않으면 기본 배송지로 강제 선택
      if (!existsDefault) setSelectedSequence(baseDeliveryPlace?.memberDeliverySequence);
    }
  }, [defaultSequence, deliveryPlaceList, open]);

  const hasDeliveryPlace = useMemo(() => deliveryPlaceList?.length > 0, [deliveryPlaceList?.length]);

  const handleDeliveryPlaceItemClick = useCallback((sequence) => {
    setSelectedSequence(sequence);
  }, []);

  const handleModificationClick = useCallback(
    (sequence) => {
      setPopupOpen(true);
      setPopupDeliveryPlace(
        deliveryPlaceList?.find(({ memberDeliverySequence }) => memberDeliverySequence === sequence)
      );
    },
    [deliveryPlaceList]
  );

  const handleDeleteClick = useCallback(
    async (sequence) => {
      showConfirmDialog('삭제하시겠습니까?', async () => {
        await deleteDeliveryPlace(sequence);
        showAlertDialog('삭제 되었습니다.');
      });
    },
    [deleteDeliveryPlace, showAlertDialog, showConfirmDialog]
  );

  const handleConfirmClick = useCallback(() => {
    onConfirm(deliveryPlaceList?.find(({ memberDeliverySequence }) => memberDeliverySequence === selectedSequence));
    onClose();
  }, [deliveryPlaceList, onClose, onConfirm, selectedSequence]);

  const handleRegistrationClick = useCallback(() => {
    setPopupOpen(true);
    setPopupDeliveryPlace(undefined);
  }, []);

  const handlePopupClose = useCallback(() => {
    setPopupOpen(false);
    setPopupDeliveryPlace(undefined);
  }, []);

  const handlePopupConfirm = useCallback(async () => {
    setPopupDeliveryPlace(undefined);
  }, []);

  const handleClose = useCallback(() => {
    setSelectedSequence('');
    onClose?.();
  }, [onClose]);

  return (
    <FullPagePopup
      header="배송지 선택"
      open={open}
      onClose={handleClose}
      confirmButtonText="배송지 선택"
      onConfirmClick={handleConfirmClick}
      onCancelClick={handleClose}
      closable={!(defaultSequence && !includeDefaultPlace)} //기본 배송지가 있고 목록에 없으면 강제선택해야 하므로 취소버튼 비활성화
      useCancelButton={!(defaultSequence && !includeDefaultPlace)} //기본 배송지가 있고 목록에 없으면 강제선택해야 하므로 취소버튼 비활성화
    >
      <Container justify-content="flex-start">
        <FlexBox width="100%">
          <TextBox size={moTheme.font.size.$12} height={moTheme.font.lineHeight.demiLight}>
            *최대 200개까지 보여지며, 마이페이지 내 전체 조회 가능합니다.
          </TextBox>
        </FlexBox>
        <Spacing top={10} />
        <AddNewButton onClick={handleRegistrationClick}>+ 새 배송지 등록</AddNewButton>
        {hasDeliveryPlace ? (
          <>
            <FlexBox width={'100%'} justify-content="space-between"></FlexBox>
            <Spacing top={10} />
            {(defaultSequence && !includeDefaultPlace) && <StyledAlert>※ 배송지가 삭제되어 기본 배송지로 선택되었습니다.</StyledAlert>}
            <StyledAddressCardRadioGroup value={selectedSequence}>
              {deliveryPlaceList?.map(({ memberDeliverySequence, ...item }) => (
                <DeliveryPlaceSelectionItem
                  key={memberDeliverySequence}
                  selected={selectedSequence === memberDeliverySequence}
                  onClick={handleDeliveryPlaceItemClick}
                  onModificationClick={handleModificationClick}
                  onDeleteClick={handleDeleteClick}
                  {...{ ...item, memberDeliverySequence }}
                />
              ))}
            </StyledAddressCardRadioGroup>
          </>
        ) : (
          <>
            <Spacing top={100} />
            <FlexBoxColumn gap={'20px'}>
              <EmptyDeliveryLocationIcon />
              <TextBox size={moTheme.font.size.$14}>배송지를 등록해 주세요.</TextBox>
            </FlexBoxColumn>
          </>
        )}
      </Container>
      <MyPageDeliveryLocationAddPopup
        editLocation={popupDeliveryPlace}
        open={popupOpen}
        onClose={handlePopupClose}
        refreshData={handlePopupConfirm}
        locationCnt={deliveryPlaceList?.length}
      />
    </FullPagePopup>
  );
};

DeliveryPlaceSelectionPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  currentValue: PropTypes.string,
};

const Container = styled(FlexBoxColumn)`
  align-self: stretch;
  align-items: center;
`;

const AddNewButton = styled(PageButton).attrs(() => ({ type: 'Default', width: '100%' }))`
  border-color: ${theme.color.line.sub};
`;

const StyledAddressCardRadioGroup = styled(AddressCardRadioGroup)`
  width: 100%;
  overflow: auto;
`;

const StyledAlert = styled.div`
  height: 15px;
  width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  font-weight: ${moTheme.font.weight.medium};
  font-size: ${moTheme.font.size.$12};
  line-height: 15px;
  color: ${theme.color.text.error};
`;
export default DeliveryPlaceSelectionPopup;
