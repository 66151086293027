import React from 'react';

import moTheme from '@ecp/common/src/style/theme/mo';
import theme from '@ecp/common/src/style/theme/default';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';

import { ReactComponent as NoResultIcon } from '@mo-assets/icon/icon__search-noresult.svg';

const BrandNoResult = () => {
  return (
    <FlexBoxColumn width="100%" justify-content="center">
      <NoResultIcon />
      <Spacing top="30px" />
      <TextBox size={moTheme.font.size.$16} weight={moTheme.font.weight.medium}>
        찾으시는 브랜드가 없습니다.
      </TextBox>
      <Spacing top="14px" />
      <TextBox size={moTheme.font.size.$14} weight={moTheme.font.weight.demiLight} color={theme.color.text.sub}>
        브랜드명을 다시 입력하시거나
      </TextBox>
      <TextBox size={moTheme.font.size.$14} weight={moTheme.font.weight.demiLight} color={theme.color.text.sub}>
        철자와 띄어쓰기를 확인해보세요.
      </TextBox>
      <Spacing bottom="50px" />
    </FlexBoxColumn>
  );
};

export default BrandNoResult;
