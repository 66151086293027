import React from 'react';
import styled from 'styled-components';

import { flexCenter } from '@ecp/common/src/style/theme/defaultStyled';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { positionPropTypes, sizePropTypes } from '@ecp/common/src/style/propTypes';
import theme from '@ecp/common/src/style/theme/default';
import moTheme from '@ecp/common/src/style/theme/mo';
import BadgeUnstyled from '@ecp/common/src/components/unstyled/badge/BadgeUnstyled';
import { BadgePropTypes } from '@ecp/common/src/components/badge/Badge';

const DefaultBadgeRoot = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

const DefaultBadge = styled.span`
  ${flexCenter};
  position: absolute;
  border-radius: 50%;
  background-color: ${theme.color.primary};
  color: ${theme.color.text.white};
  font-size: ${moTheme.font.size.$10};
  font-weight: ${moTheme.font.weight.medium};

  ${overrideProperties(positionPropTypes, {})};
  ${overrideProperties(sizePropTypes, {})};
`;

const Badge = React.forwardRef(({ position, size, ...props }, ref) => {
  return <BadgeUnstyled {...props} badgeProps={{ ...position, ...size }} ref={ref} />;
});

Badge.propTypes = BadgePropTypes;

Badge.defaultProps = {
  rootComponent: DefaultBadgeRoot,
  badgeComponent: DefaultBadge,
};

Badge.displayName = 'Badge';

export default Badge;
