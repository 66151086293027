import { useEffect } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { gnbTitleState, gnbTypeState } from '@mo-recoil/common/gnb/atom';

export const useGnb = (type) => {
  const gnbType = useSetRecoilState(gnbTypeState);
  const resetGnbType = useResetRecoilState(gnbTypeState);

  useEffect(() => {
    gnbType(type);
    return () => resetGnbType();
  }, [type, gnbType, resetGnbType]);
};

export const useGnbTitle = (title) => {
  const gnbTitle = useSetRecoilState(gnbTitleState);
  const resetGnbTitle = useResetRecoilState(gnbTitleState);

  useEffect(() => {
    gnbTitle(title);
    return () => resetGnbTitle();
  }, [title, gnbTitle, resetGnbTitle]);
};
