import React from 'react';
import PropTypes from 'prop-types';

import moTheme from '@ecp/common/src/style/theme/mo';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

const RecommendationHeader = ({ title, titleStyle }) => {
  return (
    <FlexBoxColumn width={'100%'} align-items={'flex-start'}>
      {!!title && (
        <Spacing width={'100%'} left={20} bottom={20}>
          <TextBox {...titleStyle}>{title}</TextBox>
        </Spacing>
      )}
    </FlexBoxColumn>
  );
};

export default RecommendationHeader;

RecommendationHeader.propTypes = {
  title: PropTypes.string,
  titleStyle: PropTypes.object,
};

RecommendationHeader.defaultProps = {
  title: '',
  titleStyle: {
    size: moTheme.font.size.$18,
    weight: moTheme.font.weight.bold,
    height: moTheme.font.lineHeight.bold,
  },
};
