import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from '@ecp/common/src/style/theme/default';
import { flexCenter } from '@ecp/common/src/style/theme/defaultStyled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { ReactComponent as IconClose } from '@ecp/common/src/assets/icon/icon__close-x.svg';

export const StyledRoundButton = styled.button.attrs(({ inversion, style }) => ({
  style: {
    ...style,
    background: inversion
      ? `linear-gradient(90.65deg, ${theme.color.primary} 3.04%, ${theme.color.background.gradient} 89.56%)`
      : theme.color.background.white,
    color: inversion ? theme.color.text.white : theme.color.primary,
  },
}))`
  cursor: pointer;
  ${flexCenter};
  gap: 6px;
  height: 34px;
  border: 1px solid ${theme.color.primary};
  border-radius: 34px;
  padding: 0 14px;
  font-weight: ${theme.font.weight.medium};
  font-size: ${theme.font.size.basic};
  line-height: 20px;
`;

export const RoundButton = ({ iconType: Icon, inversion, closeable, children, onClose, ...props }) => {
  const color = inversion ? theme.color.text.white : theme.color.primary;

  const handleClose = (e) => {
    e.stopPropagation();
    onClose?.(e);
  };

  return (
    <StyledRoundButton inversion={inversion} {...props}>
      {Icon && <Icon width={'24px'} height={'24px'} />}
      {children}
      {closeable && (
        <>
          <Spacing left={8} />
          <a
            href={'#'}
            aria-label={'닫기'}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <IconClose width={'12px'} height={'12px'} fill={color} onClick={handleClose} />
          </a>
        </>
      )}
    </StyledRoundButton>
  );
};

RoundButton.displayName = 'RoundButton';

RoundButton.propTypes = {
  iconType: PropTypes.elementType,
  children: PropTypes.node,
  inversion: PropTypes.bool,
  closeable: PropTypes.bool,
  onClose: PropTypes.func,
};
