import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Paper from '@ecp/common/src/components/paper/Paper';
import LayerPopup from '@ecp/common/src/components/modal/mo/popup/LayerPopup';
import { ORDER_TYPE } from '@ecp/common/src/const/order/orderConst';

const PersonalInfoAgreePopup = ({ open, onClose, orderGoodType, orderType }) => {
  const retentionPeriod = useMemo(() => {
    if (orderGoodType.directForeignGood || orderGoodType.alcohol) return '법정 의무 보유 기간까지';
    if ([ORDER_TYPE.GIFTISHOW, ORDER_TYPE.PARKPOINT, ORDER_TYPE.TICKET].includes(orderType))
      return '모바일 상품권 사용시까지';
    return '배송완료 시까지';
  }, [orderGoodType.alcohol, orderGoodType.directForeignGood, orderType]);

  const receiverInfo = useMemo(() => {
    if ([ORDER_TYPE.GIFTISHOW, ORDER_TYPE.TICKET].includes(orderType)) return '연락처';
    if ([ORDER_TYPE.PARKPOINT, ORDER_TYPE.PRESENT].includes(orderType)) return '이름, 연락처';
      else return '이름, 연락처, 주소';
  }, [orderType]);

  return (
    <LayerPopup
      open={open}
      onClose={onClose}
      onConfirmClick={onClose}
      header={'개인정보 수집 이용동의'}
      useCancelButton={false}
    >
      <Container>
        <TermsCotainer>
          <TitleTextBox>1. 개인정보 수집 항목</TitleTextBox>
          <Spacing top={'6px'} />
          <DetailTextBox>
            [주문인] (필수) 이름, 연락처, 이메일
            {orderGoodType.alcohol && ', 생년월일'}
          </DetailTextBox>
          <DetailTextBox>[수취인] (필수) {receiverInfo}
          {orderGoodType.directForeignGood && (<>, <br />개인통관 고유부호</>)}
          </DetailTextBox>
        </TermsCotainer>
        <Spacing top={'10px'} />
        <TitleTextBox>
          2. 개인정보 수집 목적 :
          {[ORDER_TYPE.GIFTISHOW, ORDER_TYPE.PARKPOINT, ORDER_TYPE.TICKET].includes(orderType)
            ? ' 모바일 상품권 발송'
            : ' 물품 배송'}
        </TitleTextBox>
        <Spacing top={'10px'} />
        <FlexBox flex-wrap={'wrap'}>
          <TitleTextBox>3. 개인정보 보유 기한 : </TitleTextBox>
          <FlexBox>
            {([ORDER_TYPE.GIFTISHOW, ORDER_TYPE.PARKPOINT, ORDER_TYPE.TICKET].includes(orderType) ||
              orderGoodType.directForeignGood ||
              orderGoodType.alcohol) && <Spacing left={'12px'} />}
            <HighlightTextBox>{retentionPeriod}</HighlightTextBox>
          </FlexBox>
        </FlexBox>

        <Spacing top={'14px'} />
        <PaperContainer>
          <PaperTextBox>※ 귀하는 동의를 거부할 권리가 있으며, 동의 거부 시 물품배송이 불가합니다.</PaperTextBox>
        </PaperContainer>
      </Container>
    </LayerPopup>
  );
};

PersonalInfoAgreePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  orderGoodType: PropTypes.object,
  orderType: PropTypes.string,
};

const Container = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const TermsCotainer = styled(FlexBoxColumn)`
  width: 100%;
  align-items: flex-start;
`;

const TitleTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 350;
`;

const DetailTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 350;
  color: #5a5a5a;
`;

const PaperContainer = styled(Paper)`
  width: 100%;
  padding: 14px;
  background-color: #f7f7f7;
`;

const PaperTextBox = styled(TextBox)`
  font-size: 12px;
  font-weight: 350;
  color: #5a5a5a;
`;

const HighlightTextBox = styled(TextBox)`
  font-size: 17px;
  font-weight: 700;
  color: ${theme.color.primary};
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
`;

export default PersonalInfoAgreePopup;
